import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import faq from '../../assets/img/mainFaq.png'
import axios from '../../services/axiosInstance';
import DOMPurify from "dompurify";
import notice from "../../assets/img/adminNotice.png";
import {BiArrowBack} from "react-icons/bi";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";

function AdminFaqDtPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const moveToModifyPg = (() => {
        navigate(`/btcorpad/adminfaq-modify/${seqNum}`)
    })

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [faqDtData, setFaqDtData] = useState([]);
    const [photoList, setPhotoList] = useState([]);


    const selectFaqDtData = () =>
        axios({
            method: 'get',
            url: '/faq/selectFaqDtData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setFaqDtData(response.data)
                setPhotoList(response.data.faqDtPhotoDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectFaqDtData();
        }
    }, [seqNum])

    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(faqDtData?.content);

    const deleteFaqDtMsg1 = "해당 FAQ 게시글을 삭제하시겠습니까?"
    const deleteFaqDtMsg2 = "삭제 후 복구가 불가합니다"
    const [deleteFaqDtMsgShow, setDeleteFaqDtMsgShow] = useState(false);
    const closeDeleteFaqDtMsgShow = (() => {
        setDeleteFaqDtMsgShow(false);
    })

    const deleteFaqDt = (() => {
        axios({
            method: 'post',
            url: '/faq/deleteFaqDt',
            params: {
                faqDtSeqNum: seqNum,
            },
        })
            .then(() => {
                closeDeleteFaqDtMsgShow();
                navigate("/btcorpad/adminfaq");
            })
            .catch(() => {
                console.log("Faq Dt 삭제실패")
            })
    })

    return(
        <div className="AdminNoticePg">
            <div className="admin-work-title">
                <h5>FAQ상세</h5>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        {/*복붙*/}
                        <div style={{
                            border: "solid",
                            width: "65%",
                            margin: "auto",
                            borderWidth: "thin 1px",
                            borderColor: "#EEEEEE",
                            borderRadius: "4px",
                            padding: "20px"
                        }}>
                            {/*헤더 컨테이너*/}
                            <div style={{height: "80px", display: "flex", marginBottom:"-20px"}}>
                                <div style={{width: "10%"}}>
                                    <div style={{borderRadius: "100%", height: "100%", width: "90%"}}><img alt="profileImg" style={{
                                        width:"90%",
                                        height:"90%",
                                        opacity:"0.6"
                                    }} src={faq}/></div>
                                </div>

                                <div style={{width: "90%", height: "100%"}}>
                                    <div style={{height: "40%", display: "flex", color: "dimgrey", alignItems:"center"}}><span
                                        style={{paddingRight: "10px", fontSize: "15px"}}>{faqDtData?.regNm}</span>
                                        <span style={{
                                            paddingRight: "10px",
                                            color: "dimgrey",
                                            fontSize: "14px",
                                            marginLeft:"10px",
                                        }}>조회수 {faqDtData?.view}</span> <span
                                            style={{marginLeft: "auto"}}>

                                <Link to={"/btcorpad/adminfaq"}>
                                <i style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"25px", cursor:"pointer"}}><BiArrowBack/></i>
                                    </Link>
                                    </span></div>
                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", height:"40px"}}>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <p style={{margin:"0", fontSize:"18px", color:"darkgray"}}>[{faqDtData?.faqMstDTO?.title}]</p>
                                            <p style={{margin:"0", fontSize:"18px", marginLeft:"5px"}}>{faqDtData?.title}</p>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center", marginTop:"5px"}}>
                                            <p onClick={moveToModifyPg} style={{margin:"0", fontSize:"15px", color:"#7C9EDC", cursor:"pointer"}}>수정</p>
                                            <p onClick={()=> setDeleteFaqDtMsgShow(true)} style={{margin:"0", fontSize:"15px", marginLeft:"10px", color:"#EB939D", cursor:"pointer"}}>삭제</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*컨텐츠 컨테이너*/}
                            <div style={{borderTop: "solid", marginTop:"30px", borderWidth:"thin 1px", borderColor:"lightgray"}}>
                                <div style={{minHeight:"80px", display:"flex", alignItems:"center", padding:"20px 0"}}>
                                    <p style={{margin:"0", fontSize:"16px", height:"100%", fontWeight:"lighter"}} dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                                </div>

                                <div style={{display:"flex", flexWrap:"wrap"}}>
                                    {photoList && photoList.map((photo, index) => (
                                        <div key={index} style={{width: "100%", boxSizing: "border-box", padding:"5px"}}>
                                            <img src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm} alt="photoImg"
                                                 style={{width:"100%", height:"100%", borderRadius:"4px"}}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { deleteFaqDtMsgShow ?
                <MsgSelectModal deleteFaqDtMsgShow={deleteFaqDtMsgShow} deleteFaqDtMsg1={deleteFaqDtMsg1} deleteFaqDtMsg2={deleteFaqDtMsg2}
                                closeDeleteFaqDtMsgShow={closeDeleteFaqDtMsgShow} deleteFaqDt={deleteFaqDt}/>
                :
                null
            }
        </div>
    )
}

export default AdminFaqDtPg
