import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';

const withAuth = (Component) => {
    return (props) => {
        // LocalStorage에서 토큰을 조회
        const localToken = localStorage.getItem('AToken');
        const sesstionToken = sessionStorage.getItem('AToken');
        const location = useLocation();

        if (!localToken && !sesstionToken) {
            // 현재 페이지를 세션스토리지에 저장(로그인 후 기존페이지로 이동시키기 위한 목적)
            sessionStorage.setItem("redirectAfterLogin", location.pathname);
            return <Navigate to="/UserLoginPg" replace />;
        }

        // 토큰이 있다면 원래의 컴포넌트를 그대로 렌더링
        return <Component {...props} />;
    };
};

export default withAuth;
