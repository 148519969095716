import React, {useEffect, useRef, useState} from "react";
import warnIcon from '../../../assets/img/warnIcon.png'
import adminMasterPlus from '../../../assets/img/adminMsterPlus.png'
import commonClose from '../../../assets/img/commonClose.png'

import {BsFillPersonCheckFill} from "react-icons/bs";
import axios from '../../../services/axiosInstance';
import {BiCaretDown} from "react-icons/bi";

function AdminMasterAdd({masterAddClose, selectAutoResList, selectMacroList, masterAddMacroPopup}) {

    const LCAT = localStorage.getItem('adminToken');

    // 여기서부터 커스텀 셀렉트
    const selectLang = [
        {title: "한국어", value: 0},
        {title: "영어", value: 1},
        {title: "일본어", value: 2},
        {title: "중국어(간체)", value: 3},
        {title: "인도네시아어", value: 4},
    ];
    const [selectedLangShow, setSelectedLangShow] = useState(false);
    const langRef = useRef(null);
    const [selectedLangTitle, setSelectedLangTitle] = useState(null);
    const [selectedLangValue, setSelectedLangValue] = useState(null);

    const toggleLangShow = () => {
        setSelectedLangShow(!selectedLangShow);
    };

    const handleLangChange = (lang) => {
        setSelectedLangTitle(lang.title);
        setSelectedLangValue(lang.value)
        setSelectedLangShow(false);
        setArLangErr(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setSelectedLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트


    const [title, setTitle] = useState('');
    const handleTitle = ((e) => {
        setTitle(e.target.value);
        setArTitleErr(false);
    })

    const [arLangErr, setArLangErr] = useState(false);
    const [arTitleErr, setArTitleErr] = useState(false);

    const arValidation = () => {
        let isValid = true;
        if (masterAddMacroPopup === undefined && selectedLangValue == null) {
            setArLangErr(true);
            isValid = false;
        }
        if (title === '') {
            setArTitleErr(true);
            isValid = false;
        }

        return isValid;
    };

    const valiAndAutoresMstSave = async () => {
        const isValid = arValidation();
        if (isValid) {
            if (masterAddMacroPopup) {
                await saveMacroMaster()
            } else {
                await saveMaster();
            }
        }
    };

    const saveMaster = (() => {
        axios({
            method: 'post',
            url: '/member/saveMaster/',
            headers: {
                Authorization: LCAT
            },
            params: {
                lang: selectedLangValue,
                title: title
            }
        })
            .then(() => {
                masterAddClose(false);
                selectAutoResList();
            })
    })

    const saveMacroMaster = () => axios({
        method: 'post',
        url: '/member/saveMacroMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            title: title
        }
    })
        .then(() => {
            masterAddClose(false);
            selectMacroList();
        })

    return (
        <div style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%",
            background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center"
        }}>
            <div style={{
                width: "350px",
                background: "white",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                borderRadius: "6px"
            }}>
                <div style={{
                    borderBottom: "solid",
                    height: "60px",
                    borderColor: "lightgray",
                    borderWidth: "thin 1px",
                    display: "flex",
                    padding: "10px"
                }}>
                    <div style={{
                        height: "100%",
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img alt='' src={adminMasterPlus} style={{width: "25px", height: "25px"}}/>
                    </div>
                    <div style={{height: "100%", width: "70%", display: "flex", alignItems: "center"}}>
                        <p style={{margin: "0", fontSize: "16px"}}>Master Add</p>
                    </div>
                    <div onClick={() => masterAddClose(false)} style={{
                        height: "100%",
                        width: "10%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer"
                    }}>
                        <img alt='' src={commonClose} style={{width: "30px", height: "30px", opacity: "0.6"}}/>
                    </div>
                </div>
                <div style={{padding: "20px"}}>
                    {!masterAddMacroPopup ?
                        // Lang
                        <div style={{width: "100%", height: "50px", position: "relative"}}>
                            {/*여기서부터 커스텀 Lang 셀렉트*/}
                            <div ref={langRef} style={{height: "100%", width: "100%"}}>
                                <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                        onClick={toggleLangShow}>
                                    <p style={{margin: "0"}}>{!selectedLangTitle ? "언어선택" : selectedLangTitle}</p>
                                    <span style={{
                                        position: "absolute",
                                        left: "80%",
                                        top: "0",
                                        height: "100%",
                                        width: "20%"
                                    }}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                                </button>
                                {selectedLangShow ?
                                    <div style={{
                                        position: 'absolute',
                                        width: "100%",
                                        border: "solid",
                                        height: "auto",
                                        maxHeight: "300px",
                                        borderWidth: "thin 1px",
                                        borderColor: "lightgray",
                                        zIndex: 1000,
                                        marginTop: "5px",
                                        backgroundColor: "white",
                                        overflow: "auto",
                                    }}>
                                        {selectLang.map((lang, index) => (
                                            <div className="selectChildBt" key={index}
                                                 onClick={() => handleLangChange(lang)} style={{
                                                display: "flex",
                                                padding: "10px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                fontSize: "14px",
                                                borderRadius: "4px",
                                                zIndex: 99,
                                            }}>{lang.title}</div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*여기까지 커스텀 Lang 셀렉트*/}
                    <div style={{
                        height: "30%",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "5px"
                    }}>
                        <input
                            style={{
                                width: "100%",
                                height: "50px",
                                outline: "none",
                                border: "solid",
                                borderWidth: "thin 1px",
                                borderColor: "lightgrey",
                                padding: "10px",
                                borderRadius: "4px",
                                fontSize: "14px"
                            }}
                            maxLength={50}
                            placeholder='Title'
                            onFocus={(e) => e.target.style.border = "2px solid ##FFAF34"}
                            onBlur={(e) => e.target.style.border = "1px solid lightgrey"}
                            value={title}
                            onChange={handleTitle}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    valiAndAutoresMstSave();
                                }
                            }}
                        />
                    </div>
                    <div style={{padding:"10px 0"}}>
                        { arLangErr ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg" style={{color:"#FF8339"}}>언어를 선택해주세요</p>
                            </div>
                            :
                            null
                        }
                        { arTitleErr ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg" style={{color:"#FF8339"}}>Title을 입력해주세요</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div style={{
                    height: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px"
                }}>
                        <button onClick={valiAndAutoresMstSave} className="orderItemHandleBt"
                                style={{
                                    width: "80px",
                                    height: "40px",
                                    fontSize: "14px",
                                    margin: "0",
                                    borderRadius: "4px",
                                    marginLeft: "8px"
                                }}>Complete
                        </button>
                </div>
            </div>
        </div>
    )
}

export default AdminMasterAdd
