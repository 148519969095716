import {useNavigate} from "react-router-dom";
import '../../css/web/UserModiPg.css';
import HeaderLogo from "../../assets/img/HeaderLogo.png";
import profileImage from "../../assets/img/profileDefaultImg.png";
import commonClose from "../../assets/img/commonClose.png";
import changePw from "../../assets/img/changePw.png";
import {GoMail} from "react-icons/go";
import {BiCaretDown, BiX} from "react-icons/bi";
import {MdLanguage, MdOutlineDriveFileRenameOutline} from "react-icons/md";
import {AiFillLock} from "react-icons/ai";
import React, {useState, useCallback, useEffect, useRef} from "react";
import axios from '../../services/axiosInstance';
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import UserAddrBook from "../../components/web/modal/UserAddrBook";
import withAuth from '../../auth/UserRouteAuth'
import {useTranslation} from "react-i18next";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";

function UserModiPg() {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [nowPassword, setNowPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [lang, setLang] = useState("");

    const [nowPasswordError, setNowPasswordError] = useState(false);
    const [nowPasswordMatchError, setNowPasswordMatchError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
    const [langError, setLangError] = useState(false);
    const [changePwFl, setChangePwFl] = useState(false);

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const onChangeNowPassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const nowPasswordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (nowPasswordRegex.test(e.target.value)))) setNowPasswordError(false);
        else setNowPasswordError(true);
        setNowPassword(e.target.value);
    };

    const onChangeNewPassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const newPasswordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (newPasswordRegex.test(e.target.value)))) setNewPasswordError(false);
        else setNewPasswordError(true);

        if (e.target.value === nowPassword) setSamePasswordError(true);
        else setSamePasswordError(false);

        if (!confirmNewPassword || e.target.value === confirmNewPassword) setConfirmNewPasswordError(false);
        else setConfirmNewPasswordError(true);
        setNewPassword(e.target.value);
    };

    const onChangeConfirmNewPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (newPassword === e.target.value) setConfirmNewPasswordError(false);
        else setConfirmNewPasswordError(true);
        setConfirmNewPassword(e.target.value);
    };

    const onChangeLang = (e) => {
        const langRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 또는 한글 2~25자*/
        if ((!e.target.value || (langRegex.test(e.target.value)))) setLangError(false);
        else setLangError(true);
        setLang(e.target.value);
    };

    const validation = () => {
        let isValue = true;
        if (joinType === '0' && changePwFl) {
            if (!nowPassword && nowPasswordMatchError) {
                setNowPasswordMatchError(true);
                isValue = false;
            }
            if (!newPassword && newPasswordError) {
                setNewPasswordError(true);
                isValue = false;
            }
            if (!confirmNewPassword || (confirmNewPassword !== newPassword) || confirmNewPasswordError) {
                setConfirmNewPasswordError(true);
                isValue = false;
            }
            if (nowPasswordMatchError || newPasswordError || confirmNewPasswordError || langError) {
                isValue = false;
            }
        }
        if (!langError && (!lang || lang === "none")) {
            setLangError(true);
            isValue = false;
        }
        return isValue;
    };

    const errorAndSave = (e) => {
        if (validation()) {
            userInfoModify(e);
        }
    }

    // 회원가입 소통언어 직접입력 인풋 Start
    // 소통언어 양식 셀렉트&인풋 전환 기능
    const langOptions = [
        {value: 'none', label: 'Language'},
        {value: 'English', label: 'English'},
        {value: 'Japanese', label: 'Japanese'},
        {value: 'Chinese', label: 'Chinese'},
        {value: 'Indonesian', label: 'Indonesian'},
        {value: 'Korean', label: 'Korean'},
        {value: '', label: t(`mdSfIp`)},
    ];

    const [langChoiceReturn, setLangChoiceReturn] = useState(true);
    const LangHandle = useCallback(() => {
        setLangChoiceReturn(true);
        setLangSelectedOption(langOptions[0]);
    }, []);

    const [langSelectedOption, setLangSelectedOption] = useState(langOptions[0]);
    const [langIsOpen, setLangIsOpen] = useState(false);
    const [langFocusedIndex, setLangFocusedIndex] = useState(0);
    const langDropdownRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (langDropdownRef.current && !langDropdownRef.current.contains(e.target)) {
                setLangIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const langHandleSelect = (option, index) => {
        if (option.value !== '' || option.value !== "none") {
            setLangError(false);
        }
        setLangSelectedOption(option);
        setLangFocusedIndex(index);
        setLangIsOpen(false);
        setLang(option.value);
        if (option.value === '') setLangChoiceReturn(false);
        else setLangChoiceReturn(true);
    };

    const langHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + langOptions.length) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            langHandleSelect(langOptions[langFocusedIndex], langFocusedIndex);
        } else if (e.key === 'Escape') {
            setLangIsOpen(false);
        }
    };

    const langHandleOnFocus = () => {
        if (langFocusedIndex === -1) {
            setLangFocusedIndex(langOptions.findIndex((option) => option.value === langSelectedOption.value));
        }
    };

    const [modiPgId, setModiPgId] = useState('')
    const [modiPgUsername, setModiPgUsername] = useState("")

    const [modiPgUseTermsAgree, setModiPgUseTermsAgree] = useState("");
    const [modiPgInfoGatAgree, setModiPgInfoGatAgree] = useState("");
    const [modiPgMarkUseAgree, setModiPgMarkUseAgree] = useState("")
    const [modiPgMailRecFl, setModiPgMailRecFl] = useState("")

    const [modiPgMbNum, setModiPgMbNum] = useState("")
    const [modiPgSeqNum, setModiPgSeqNum] = useState("")
    const [modiPgUserOutFl, setModiPgUserOutFl] = useState("");
    const [modiPgImgFile, setModiPgImgFile] = useState("");

    const [joinType, setJoinType] = useState('');

    useEffect(() => {
        setTimeout(() => {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                }
            })
                .then((response) => {
                    setLangSelectedOption({value: response.data[0].lang, label: response.data[0].lang});
                    setModiPgId(response.data[0].id)
                    setModiPgUsername(response.data[0].userName)
                    setLang(response.data[0].lang)
                    setModiPgUseTermsAgree(response.data[0].useTermsAgree)
                    setModiPgInfoGatAgree(response.data[0].infoGatAgree)
                    setModiPgMarkUseAgree(response.data[0].markUseAgree)
                    setModiPgMailRecFl(response.data[0].mailRecFl)
                    setModiPgSeqNum(response.data[0].seqNum)
                    setModiPgMbNum(response.data[0].mbNum)
                    setModiPgUserOutFl(response.data[0].userOutFl)
                    setModiPgImgFile(response.data[0].profileDTO)
                    setJoinType(response.data[0].joinType);

                    if (response.data[0].profileDTO === null) {
                        setProfilePhotoLine(profileImage)
                    } else {
                        setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                    }
                })
        },);
    }, [])


    const toggleUseTermsChecked = ((e) => {
        if (modiPgUseTermsAgree === "Y") {
            setModiPgUseTermsAgree("N");
        } else if (modiPgUseTermsAgree === "N") {
            setModiPgUseTermsAgree("Y")
        }
    })

    const toggleInfoGatChecked = ((e) => {
        if (modiPgInfoGatAgree === "Y") {
            setModiPgInfoGatAgree("N");
        } else if (modiPgInfoGatAgree === "N") {
            setModiPgInfoGatAgree("Y")
        }
    })

    const toggleMarkUseChecked = ((e) => {
        if (modiPgMarkUseAgree === "Y") {
            setModiPgMarkUseAgree("N");
        } else if (modiPgMarkUseAgree === "N") {
            setModiPgMarkUseAgree("Y")
        }
    })

    const toggleMailRecChecked = ((e) => {
        if (modiPgMailRecFl === "Y") {
            setModiPgMailRecFl("N");
        } else if (modiPgMailRecFl === "N") {
            setModiPgMailRecFl("Y")
        }
    })

    const modiUserDelete = () => axios({
        method: 'post',
        url: '/member/userOut',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            modiPgMbNum: modiPgMbNum
        }
    })
        .then(() => {
            setAlertShow(false);
            setConfirmShow1(true);
        })

    const confirm = (() => {
        navigate("/UserLoginPg")
        if (SSAT) {
            sessionStorage.removeItem('AToken');
            sessionStorage.removeItem('oneAtTime');
            sessionStorage.removeItem('twoAtTime');
        } else {
            localStorage.removeItem('AToken');
            localStorage.removeItem('oneAtTime');
            localStorage.removeItem('twoAtTime');
        }
    })

    const userInfoModify = () => axios({
        method: 'post',
        url: '/member/infoModify',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            nowPassword: nowPassword
        },
        data: {
            id: modiPgId,
            userName: modiPgUsername,
            password: newPassword,
            passwordConfirm: confirmNewPassword,
            lang: lang,
            infoGatAgree: modiPgInfoGatAgree,
            useTermsAgree: modiPgUseTermsAgree,
            markUseAgree: modiPgMarkUseAgree,
            mailRecFl: modiPgMailRecFl,
            seqNum: modiPgSeqNum,
            mbNum: modiPgMbNum,
            userOutFl: modiPgUserOutFl,
            changePwFl: changePwFl,
            joinType: joinType
        }
    })
        .then(() => {
            setConfirmShow2(true);
        })
        .catch(() => {
            setNowPasswordError(true);
        })

    // 모달관련 스테이트
    const [alertShow, setAlertShow] = useState(false);
    const [confirmShow1, setConfirmShow1] = useState(false);
    const [confirmShow2, setConfirmShow2] = useState(false);
    const [confirmShow3, setConfirmShow3] = useState(false);
    const [confirmShow4, setConfirmShow4] = useState(false);
    const [photoDeleteSelectSt, setPhotoDeleteSelectSt] = useState(false);
    const [photoDeleteConfirmSt, setPhotoDeleteConfirmSt] = useState(false);
    const [deliAddrBookSt, setDeliAddrBookSt] = useState(false);

    const userOutSelect = (() => {
        setAlertShow(true);
    })

    const modimodalCancel = useCallback(() => {
        setAlertShow(false);
    })

    const photoDeleteCancelFn = (() => {
        setPhotoDeleteSelectSt(false);
    })

    const photoDeleteConfirmFn = (() => {
        setPhotoDeleteConfirmSt(false);
    })

    const deliAddrBookFn = (() => {
        setDeliAddrBookSt(true);
    })

    const addrBookCloseFn = (() => {
        setDeliAddrBookSt(false);
    })

    const msgConfirmData1 = t("mdModal1");
    const msgConfirmData2 = t("mdModal2");
    const msgConfirmData3 = t("mdModal3");
    const msgConfirmData4 = t("mdModal4");
    const MsgConfirmData5 = t("mdModal5");
    const MsgConfirmData6 = t("mdModal6");
    const MsgConfirmData7 = t("mdModal7");

    const confirm2 = (() => {
        setConfirmShow2(false);
    })
    const confirm3 = (() => {
        setConfirmShow3(false);
    })
    const confirm4 = (() => {
        setConfirmShow4(false);
        window.location.reload();
    })

    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);

    const photoDeleteButton = (() => {
        setPhotoDeleteSelectSt(true);
    })

    const profileDelete = () => axios({
        method: 'post',
        url: '/member/profileDelete',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            id: modiPgImgFile.seqnum
        },
    })
        .then(() => {
            setPhotoDeleteSelectSt(false);
            setProfilePhotoLine(profileImage);
            setPhotoDeleteConfirmSt(true);
        })
        .catch(() => {
            console.log("실패")
        })

    const formData = new FormData();
    const PhotoChangeHandler = (e) => {
        const file = e.target.files[0];
        formData.append('file', file);
        setProfilePhotoLine(URL.createObjectURL(e.target.files[0]))
    }

    const profileSave = async () => await axios({
        method: 'post',
        url: '/member/profileSave',
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            email: modiPgId
        },
        data: formData
    })
        .then(() => {
            // 등록 후 바로 사진삭제할 수도 있어서 여기에 ProfileVO도 데이터 업뎃해줘야함
            setConfirmShow4(true);
        })
        .catch(() => {
            console.log("실패")
        })

    const photoChangeAndSave = (e) => {
        PhotoChangeHandler(e);
        profileSave(e).then();
    }

    const selectFile = useRef(null);

    return (
        <div className="UserModiContainer">
            <div className="modiMain">
                <div className="modiFrame font-t-cont">
                    <div className="AccPgLogo user-center">
                        <img alt="" src={HeaderLogo} style={{width: "200px", height: "46px", cursor: "pointer"}}
                             onClick={() => navigate("/")}/>
                    </div>
                    <div className="modiProfileContainer">
                        <div className="modiProfileFrame" style={{textAlign: "center"}}>
                            <img style={{width: "100%", height: "100%"}} alt="profilePhoto" src={profilePhotoLine}/>
                        </div>
                    </div>
                    <div className="text-center m-t-20">
                        <button className="bt-btn-D-B ht-40 wd-90 font-s-13 m-r-10"
                                onClick={photoDeleteButton}>{t(`mdBt1`)}</button>
                        {/*사진제거 버튼*/}
                        <input style={{display: "none", height: "30px"}} type="file" name="file" accept="image/*"
                               ref={selectFile}
                               onChange={photoChangeAndSave}/>
                        <button className="bt-btn-B ht-40 wd-90 font-s-13"
                                onClick={() => selectFile.current.click()}>{t(`mdBt2`)}</button>
                        {/*사진등록 버튼*/}
                    </div>
                    <div className="display-flex flex-column just-cont-center m-t-80">
                        <div className="modiFormContainer" style={{display: "flex"}}>
                            <div style={{display: "flex", alignItems: "center", width: "30%", paddingLeft: "30px"}}>
                                <p style={{fontSize: "15px", color: "darkgray"}}>{t(`mdSmallMenu1`)}</p> {/*아이디(이메일)*/}
                            </div>
                            <div className="joinMailForm acc-icon" style={{width: "350px"}}>
                                <div className="acc-lg-icon" style={{marginLeft: "15px"}}><i><GoMail/></i></div>
                                <div className="acc-input-form" style={{width: "305px"}}>
                                    {/*// 여기에 서버에서 받아온 이메일(입력X) 고정시키면 됨*/}
                                    <input className="JoinInput wd-100pc" value={modiPgId} style={{backgroundColor: "white"}} disabled type="text" placeholder=" "/>
                                </div>
                            </div>
                        </div>
                        <div className="modiFormContainer" style={{display: "flex"}}>
                            <div style={{display: "flex", alignItems: "center", width: "30%", paddingLeft: "30px"}}>
                                <p style={{fontSize: "15px", color: "darkgray"}}>{t(`mdSmallMenu2`)}</p>
                            </div>
                            <div className="JoinForm acc-icon" style={{width: "350px"}}>
                                <div className="acc-lg-icon" style={{marginLeft: "15px"}}>
                                    <i><MdOutlineDriveFileRenameOutline/></i></div>
                                <div className="acc-input-form" style={{width: "200px"}}>
                                    {/*// 아래에 서버에서 받아온 고객 성함 넣어야 됨*/}
                                    <input className="JoinInput" type="text" disabled placeholder=" "
                                           value={modiPgUsername} style={{backgroundColor: "white"}}/>
                                    <label className="JoinLabel"></label>
                                </div>
                            </div>
                        </div>
                        {joinType === '0' ?
                            <div className="modiFormContainer" style={{display: "flex"}}>
                                <div style={{display: "flex", alignItems: "center", width: "30%", paddingLeft: "30px"}}>
                                    <p style={{
                                        fontSize: "15px",
                                        color: "darkgray"
                                    }}>{t(`mdSmallMenu3`)}</p> {/*비밀번호변경*/}
                                </div>
                                <div style={{width: "70%", margin: "10px 0"}}>
                                    {changePwFl ?
                                        // 비밀번호 변경상태일 때
                                        <div style={{
                                            border: "solid",
                                            width: "350px",
                                            padding: "20px",
                                            borderWidth: "thin 1px",
                                            borderColor: "#EAEAEA",
                                            borderRadius: "4px"
                                        }}>
                                            <div style={{height: "40px", display: "flex", justifyContent: "flex-end"}}>
                                                <img style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    cursor: "pointer",
                                                    opacity: "0.6"
                                                }} onClick={() => setChangePwFl(false)} alt="close" src={commonClose}/>
                                            </div>
                                            <div className="JoinForm acc-icon" style={{width: "100%"}}>
                                                <div className="acc-lg-icon" style={{marginLeft: "15px"}}>
                                                    <i><AiFillLock/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="JoinInput" type="password" placeholder=" "
                                                           style={{width: "98%"}}
                                                           value={nowPassword} onChange={onChangeNowPassword}
                                                           minLength="8" maxLength="20"/>
                                                    <label className="JoinLabel">{t(`mdLabel1`)}</label>
                                                </div>
                                            </div>
                                            {nowPasswordError ?
                                                <div className="JoinErrorMsg">
                                                    <p className="JoinErrMsg">{t(`mdErr1`)}</p>
                                                </div>
                                                :
                                                nowPasswordMatchError ?
                                                    <div className="JoinErrorMsg">
                                                        <p className="JoinErrMsg">{t(`mdErr2`)}</p>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="JoinForm acc-icon" style={{width: "100%"}}>
                                                <div className="acc-lg-icon" style={{marginLeft: "15px"}}>
                                                    <i><AiFillLock/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="JoinInput" type="password" placeholder=" "
                                                           style={{width: "98%"}}
                                                           value={newPassword} onChange={onChangeNewPassword}
                                                           minLength="8" maxLength="20"/>
                                                    <label className="JoinLabel">{t(`mdLabel2`)}</label>
                                                </div>
                                            </div>
                                            {newPasswordError ?
                                                <div className="JoinErrorMsg">
                                                    <p className="JoinErrMsg">{t(`mdErr3`)}</p>
                                                </div>
                                                :
                                                null
                                            }
                                            {samePasswordError ?
                                                <div className="JoinErrorMsg">
                                                    <p className="JoinErrMsg">{t(`mdErr3-2`)}</p>
                                                </div>
                                                :
                                                null
                                            }
                                            <div className="JoinForm acc-icon" style={{width: "100%"}}>
                                                <div className="acc-lg-icon" style={{marginLeft: "15px"}}>
                                                    <i><AiFillLock/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="JoinInput" type="password" placeholder=" "
                                                           style={{width: "98%"}}
                                                           value={confirmNewPassword}
                                                           onChange={onChangeConfirmNewPassword}
                                                           minLength="8" maxLength="20"/>
                                                    <label className="JoinLabel">{t(`mdLabel3`)}</label>
                                                </div>
                                            </div>
                                            {confirmNewPasswordError ?
                                                <div className="JoinErrorMsg">
                                                    <p className="JoinErrMsg">{t(`mdErr4`)}</p>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        // 비밀번호 변경상태가 아닐 때
                                        <div>
                                            <div onClick={() => setChangePwFl(true)} className="user-center" style={{
                                                width: "350px",
                                                height: "55px",
                                                border: "solid",
                                                borderWidth: "thin 1px",
                                                borderColor: "#EAEAEA",
                                                borderRadius: "6px",
                                                cursor: "pointer",
                                            }}>
                                                <img style={{width: "25px", height: "25px"}} alt="changePw"
                                                     src={changePw}/>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "14px",
                                                    fontWeight: "lighter",
                                                    marginLeft: "5px"
                                                }}>{t('mdSmallMenu3')}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="modiFormContainer" style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <div style={{display: "flex", alignItems: "center", width: "30%", paddingLeft: "30px"}}>
                                <p style={{margin: "0", fontSize: "15px", color: "darkgray"}}>{t(`mdSmallMenu4`)}</p>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", width: "30%"}}>
                                {langChoiceReturn && langSelectedOption.value !== "" ? (
                                        // 셀렉트
                                        <div className="JoinSelectForm acc-icon" style={{width: "350px"}}>
                                            <div className="acc-lg-icon" style={{width: "23%", padding: "0"}}>
                                                <i className="user-center"
                                                   style={{width: "100%", height: "100%"}}><MdLanguage/></i></div>
                                            <div className="JoinSelectInputForm" style={{width: "75%"}}>
                                                <div
                                                    ref={langDropdownRef}
                                                    className="dropdown"
                                                    tabIndex="0"
                                                    onKeyDown={langHandleKeyDown}
                                                    onFocus={langHandleOnFocus}
                                                    style={{height: "100%", border: "none", outline: "none"}}
                                                >
                                                    <div className="dropdown-selected"
                                                         onClick={() => setLangIsOpen(!langIsOpen)}
                                                         style={{
                                                             height: "100%",
                                                             border: "none",
                                                             display: "flex",
                                                             alignItems: "center",
                                                             paddingLeft: "-8px",
                                                             color: "darkgray",
                                                             zIndex: 3,
                                                             position: "relative",
                                                         }}>
                                                        {langSelectedOption.label}
                                                    </div>
                                                    {langIsOpen && (
                                                        <ul className="dropdown-options" style={{
                                                            width: "350px",
                                                            height: "auto",
                                                            border: "none",
                                                            boxShadow: "0px 0px 10px lightgray",
                                                            padding: "0",
                                                            position: "absolute",
                                                            right: "-7px",
                                                            top: "70px",
                                                            zIndex: 3,
                                                            backgroundColor: "white",
                                                            borderRadius: "6px",
                                                            overflow: "hidden" // 추가된 부분
                                                        }} onChange={onChangeLang}>
                                                            {langOptions.map((option, index) => (
                                                                <li
                                                                    key={option.value}
                                                                    onClick={() => langHandleSelect(option, index)}
                                                                    className={index === langFocusedIndex ? 'focused' : '' && "mypgFilterBt"}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "50px",
                                                                        border: "none",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                                <div style={{position: "absolute", right: "15px", top: "15px"}}><i
                                                    style={{fontSize: "18px", color: "darkgray"}}><BiCaretDown/></i></div>
                                            </div>
                                        </div>
                                    ) :
                                    // 인풋
                                    <div className="JoinLangForm acc-icon" style={{margin: "0", width: "350px"}}>
                                        <div className="JoinSelectIcon user-center"
                                             style={{width: "23%", paddingRight: "6px", marginRight: "-11px"}}>
                                            <i className="user-center"
                                               style={{width: "100%", height: "100%"}}><MdLanguage/></i></div>
                                        <div className="LangInputForm"
                                             style={{display: "flex", margin: "0", padding: "0"}}>
                                            <input className="JoinInput user-input"
                                                   type="text"
                                                   placeholder=" "
                                                   value={lang}
                                                   maxLength="8"
                                                   onChange={onChangeLang}
                                            />
                                            <label className="JoinLabel">{t(`mdLabel4`)}</label>
                                            <div className="SwitchToSelectIcon acc-lg-icon"
                                                 style={{position: "absolute", right: "-2px", top: "2px"}}>
                                                <a onClick={LangHandle}><i><BiX/></i></a></div>
                                        </div>
                                    </div>
                                }
                                {langError ?
                                    <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                        <p className="JoinErrMsg">{t(`mdErr5`)}</p>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="modiFormContainer" style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", height: "80px", marginTop: "10px"}}>
                            <div style={{display: "flex", width: "30%", paddingLeft: "30px"}}>
                                <p style={{margin: "0", fontSize: "15px", color: "darkgray"}}>{t(`mdSmallMenu5`)}</p>
                            </div>
                            <div style={{width: "70%", height: "50px", display: "flex", alignItems: "center"}}>
                                <p style={{
                                    fontWeight: "lighter",
                                    fontSize: "15px",
                                    color: "orangered",
                                    cursor: "pointer",
                                    minWidth: "60px"
                                }} onClick={deliAddrBookFn}>[{t(`mdSmallMenu5`)}]</p>
                                <p style={{
                                    fontWeight: "lighter",
                                    fontSize: "15px",
                                    marginLeft: "2px",
                                    color: "dimgray"
                                }}>{t(`mdCont2`)}</p>
                            </div>
                        </div>

                        {/* region*/}

                        {/*/!*비토리지이용약관*!/*/}
                        {/*<div*/}
                        {/*    className="modiFormContainer"*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "row",*/}
                        {/*        width: "100%",*/}
                        {/*        alignItems: "center",*/}
                        {/*        height: "80px",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center",*/}
                        {/*            width: "30%",*/}
                        {/*            paddingLeft: "30px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <p style={{margin: "0", fontSize: "15px", color: "darkgray"}}>*/}
                        {/*            {t(`joinAg1`)}*/}
                        {/*        </p>*/}
                        {/*    </div>*/}

                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            width: "70%",*/}
                        {/*            display: "flex",*/}
                        {/*            fontSize: "16px",*/}
                        {/*            alignItems: "center",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div style={{display: "flex", alignItems: "center"}}>*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                id="toggle1"*/}
                        {/*                hidden*/}
                        {/*                checked={modiPgUseTermsAgree === "Y"}*/}
                        {/*                value={modiPgUseTermsAgree}*/}
                        {/*                onClick={toggleUseTermsChecked}*/}
                        {/*            />*/}
                        {/*            <label htmlFor="toggle1" className="toggleSwitch">*/}
                        {/*                <span className="toggleButton"></span>*/}
                        {/*            </label>*/}
                        {/*        </div>*/}

                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                margin: "5px 0 0 20px",*/}
                        {/*                fontSize: "14px",*/}
                        {/*                color: "#4F4C4A",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <p style={{margin: "0", fontSize: "14px", fontWeight: "lighter"}}>*/}
                        {/*                {modiPgUseTermsAgree === "Y" ? t(`agree`) : t(`disagree`)}*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*/!*개인정보수집동의*!/*/}
                        {/*<div*/}
                        {/*    className="modiFormContainer"*/}
                        {/*    style={{*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "row",*/}
                        {/*        width: "100%",*/}
                        {/*        alignItems: "center",*/}
                        {/*        height: "80px",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            alignItems: "center",*/}
                        {/*            width: "30%",*/}
                        {/*            paddingLeft: "30px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <p style={{margin: "0", fontSize: "15px", color: "darkgray"}}>*/}
                        {/*            {t(`joinAg2`)}*/}
                        {/*        </p>*/}
                        {/*    </div>*/}

                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            width: "70%",*/}
                        {/*            display: "flex",*/}
                        {/*            fontSize: "16px",*/}
                        {/*            alignItems: "center",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div style={{display: "flex", alignItems: "center"}}>*/}
                        {/*            <input*/}
                        {/*                type="checkbox"*/}
                        {/*                id="toggle2"*/}
                        {/*                hidden*/}
                        {/*                checked={modiPgInfoGatAgree === "Y"}*/}
                        {/*                value={modiPgInfoGatAgree}*/}
                        {/*                onClick={toggleInfoGatChecked}*/}
                        {/*            />*/}
                        {/*            <label htmlFor="toggle2" className="toggleSwitch">*/}
                        {/*                <span className="toggleButton"></span>*/}
                        {/*            </label>*/}
                        {/*        </div>*/}

                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                margin: "5px 0 0 20px",*/}
                        {/*                fontSize: "14px",*/}
                        {/*                color: "#4F4C4A",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <p style={{margin: "0", fontSize: "14px", fontWeight: "lighter"}}>*/}
                        {/*                {modiPgInfoGatAgree === "Y" ? t(`agree`) : t(`disagree`)}*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*마케팅활용동의*/}

                        {/*endregion*/}

                        <div className="modiFormContainer" style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", height: "80px",}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "30%",
                                    paddingLeft: "30px",
                                }}
                            >
                                <p style={{margin: "0", fontSize: "15px", color: "darkgray"}}>
                                    {t(`joinAg4`)}
                                </p>
                            </div>
                            <div
                                style={{
                                    width: "70%",
                                    display: "flex",
                                    fontSize: "16px",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input
                                        type="checkbox"
                                        id="toggle3"
                                        hidden
                                        checked={modiPgMarkUseAgree === "Y"}
                                        value={modiPgMarkUseAgree}
                                        onChange={toggleMarkUseChecked}
                                    />
                                    <label htmlFor="toggle3" className="toggleSwitch">
                                        <span className="toggleButton"></span>
                                    </label>
                                </div>
                                <div
                                    style={{
                                        margin: "5px 0 0 20px",
                                        fontSize: "14px",
                                        color: "#4F4C4A",
                                    }}
                                >
                                    <p style={{margin: "0", fontSize: "14px", fontWeight: "lighter"}}>
                                        {modiPgMarkUseAgree === "Y" ? t(`agree`) : t(`disagree`)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*메일수신여부*/}
                        <div className="modiFormContainer" style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", height: "80px",}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "30%",
                                    paddingLeft: "30px",
                                }}
                            >
                                <p style={{margin: "0", fontSize: "15px", color: "darkgray"}}>
                                    {t(`mdSmallMenu6`)}
                                </p>
                            </div>
                            <div
                                style={{
                                    width: "70%",
                                    display: "flex",
                                    fontSize: "16px",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input
                                        type="checkbox"
                                        id="toggle4"
                                        hidden
                                        checked={modiPgMailRecFl === "Y"}
                                        value={modiPgMailRecFl}
                                        onChange={toggleMailRecChecked}
                                    />
                                    <label htmlFor="toggle4" className="toggleSwitch">
                                        <span className="toggleButton"></span>
                                    </label>
                                </div>
                                <div style={{margin: "5px 0 0 20px", fontSize: "14px", color: "#4F4C4A",}}>
                                    <p style={{margin: "0", fontSize: "14px", fontWeight: "lighter"}}>
                                        {modiPgMailRecFl === "Y" ? t(`mdCont3`) : t(`mdCont4`)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="user-center" style={{marginTop: "30px"}}>
                            <button className="bt-btn-B ht-45 wd-120 font-s-15" onClick={errorAndSave}>{t(`mdBt`)}</button>{/*저장 버튼*/}
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "50px", minWidth: "100px",}}>
                            <p className="font-s-14 c-def font-c-default" onClick={userOutSelect}>{t(`mdOut`)}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*●● 모달창 규칙*/}
            {/*전달변수={위 스테이트} / 전달변수={위 함수} / 전달변수={위 메시지변수}*/}
            {alertShow ?
                <MsgSelectModal alertShow={alertShow} eventHandle1={modimodalCancel} eventHandle2={modiUserDelete}
                                msgData1={msgConfirmData1}
                                msgData2={msgConfirmData2}/>
                :
                null
            }
            {confirmShow1 ?
                <MsgCheckModal confirm={confirm} msgData3={msgConfirmData3} confirmShow1={confirmShow1}/>
                :
                null
            }
            {confirmShow2 ?
                <MsgCheckModal confirm2={confirm2} msgData4={msgConfirmData4} confirmShow2={confirmShow2}/>
                :
                null
            }
            {confirmShow3 ?
                <MsgCheckModal confirm3={confirm3} msgData5={MsgConfirmData5} confirmShow3={confirmShow3}/>
                :
                null
            }
            {confirmShow4 ?
                // 컨펌4는 컨펌쇼4를 false로 만들어서 앨럿창 사라지게 / data5는 문구 / confirmshow4는 state임
                <MsgCheckModal confirm4={confirm4} msgData6={MsgConfirmData5} confirmShow4={confirmShow4}/>
                :
                null
            }
            {photoDeleteSelectSt ?
                <MsgSelectModal photoDeleteSelectSt={photoDeleteSelectSt} photoDeleteCancelFn={photoDeleteCancelFn}
                                photoDeleteExecution={profileDelete} msgData7={MsgConfirmData6}
                />
                :
                null
            }
            {photoDeleteConfirmSt ?
                <MsgCheckModal photoDeleteConfirmSt={photoDeleteConfirmSt} photoDeleteConfirmFn={photoDeleteConfirmFn}
                                 MsgConfirmData7={MsgConfirmData7}/>
                :
                null
            }
            {deliAddrBookSt ?
                <UserAddrBook addrBookCloseFn={addrBookCloseFn} deliAddrBookSt={deliAddrBookSt}/>
                :
                null
            }
        </div>
    )
}

export default withAuth(UserModiPg)
