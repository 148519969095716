import React, {useEffect, useState} from 'react';
import '../../css/web/AdminInflowStatusPg.css';
import axios from '../../services/axiosInstance';
import maCateImg from "../../assets/img/m-a-cate.png";
import ApexCharts from "react-apexcharts";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";

function AdminInflowStatusPg() {

    /** region DataTable */

    const [searchData, setSearchData] = useState([]);
    const [socialData, setSocialData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => { setStartDate(e.target.value); };
    const endHandle = (e) => { setEndDate(e.target.value); };

    useEffect(() => {
        const today = new Date();
        const sixMonthsAgo = new Date(today);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(sixMonthsAgo));
        setEndDate(formatDateString(today));
    }, []);

    const formattedStartDate = startDate.replace(/-/g, '');
    const formattedEndDate = endDate.replace(/-/g, '');

    const [stateSearch, setStateSearch] = useState({
        series: [{
            name: "Search",
            data: searchData
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: true
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '60%',
                    colors: {
                        ranges: [
                            { from: 0, to: 1000, color: '#ffea7e' },
                            { from: 1001, to: 3000, color: '#ffaf4b' },
                            { from: 3001, to: 1000000, color: '#ff7c22' }
                        ],
                        backgroundBarColors: ['#eeeeee'],
                        backgroundBarOpacity: 0.6,
                    }
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
            },
            xaxis: {
                categories: ['Naver', 'Google', 'Yahoo', 'Bing', 'Etc'],
                labels: {
                    style: {
                        colors: ['#333'],
                        fontSize: '14px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: ['#333'],
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: (value) => `${value} users`,
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '16px',
                labels: {
                    colors: '#333',
                    useSeriesColors: false
                },
            },
            grid: {
                show: true,
                borderColor: '#e0e0e0',
            },
            responsive: [{
                breakpoint: 768,
                options: {
                    chart: {
                        width: '100%',
                        height: 'auto'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    });

    const [stateSocial, setStateSocial] = useState({
        series: [{
            name: "Social",
            data: socialData
        }],
        options: {
            chart: {
                type: 'bar',
                // height: 350,
                toolbar: {
                    show: true
                },
                // background: '#f4f4f4', // 차트 배경색
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '40%',
                    colors: {
                        ranges: [
                            { from: 0, to: 1000, color: '#ffea7e' },
                            { from: 1001, to: 3000, color: '#ffaf4b' },
                            { from: 3001, to: 1000000, color: '#ff7c22' }
                        ],
                        backgroundBarColors: ['#eeeeee'],
                        backgroundBarOpacity: 0.6,
                    }
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
            },
            xaxis: {
                categories: ['YouTube', 'Instagram', 'TikTok', 'Twitter', 'Threads', 'FaceBook'],
                labels: {
                    style: {
                        colors: ['#333'],
                        fontSize: '14px',
                    },
                },
                // title: {
                //     text: 'Platforms',
                //     style: {
                //         color: '#333',
                //         fontSize: '14px',
                //         fontWeight: 'bold',
                //     },
                // }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: ['#333'],
                        fontSize: '14px',
                        fontWeight: 'bold',
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: (value) => `${value} users`,
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '16px',
                labels: {
                    colors: '#333',
                    useSeriesColors: false
                },
            },
            grid: {
                show: true,
                borderColor: '#e0e0e0',
            },
            // colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#F86624'],
            responsive: [{
                breakpoint: 768,
                options: {
                    chart: {
                        width: '100%',
                        height: 'auto'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    const [stateDevice, setStateDevice] = useState({
        series: [{
            name: "Device",
            data: deviceData
        }],
        options: {
            chart: {
                type: 'pie',
            },
            labels: ['PC', 'Mobile', 'Application'],
            stroke: {
                width: 3,
                colors: ['#fff'],
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: (value) => `${value} users`,
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
                dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 1,
                    blur: 1,
                    opacity: 0.5,
                },
            },
            legend: {
                position: 'bottom',
                fontSize: '16px',
            },
            colors: ['#ffea7e', '#ffaf4b', '#ff7c22'],
            responsive: [{
                breakpoint: 768,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    /** endregion */

    /** region Event */

    useEffect(() => {
        if (startDate && endDate) {
            searchSelect();
            socialSelect();
            deviceSelect();
        }
    }, [startDate, endDate])

    const searchSelect = () => {
        axios.get('/socialInit/socialData', { headers: { Authorization: LCAT }, params: { startDate: formattedStartDate, endDate: formattedEndDate } })
            .then(response => {
                const searchCounts = response.data.reduce((acc, log) => {
                    acc[log.platform] = (acc[log.platform] || 0) + 1;
                    return acc;
                }, {});

                const platforms = ['Naver', 'Google', 'Yahoo', 'Bing', 'Etc'];
                const data = platforms.map(platform => searchCounts[platform] || 0);

                setSearchData(data);

                setStateSearch(prevState => ({
                    ...prevState,
                    series: [{
                        ...prevState.series[0],
                        data: data,
                    }]
                }));
            })
            .catch(error => console.error("err123: ", error));
    };

    const socialSelect = () => {
        axios.get('/socialInit/socialData', { headers: { Authorization: LCAT }, params: { startDate: formattedStartDate, endDate: formattedEndDate } })
            .then(response => {
                const socialCounts = response.data.reduce((acc, log) => {
                    acc[log.platform] = (acc[log.platform] || 0) + 1;
                    return acc;
                }, {});

                const platforms = ['YouTube', 'Instagram', 'TikTok', 'Twitter', 'Threads', 'FaceBook'];
                const data = platforms.map(platform => socialCounts[platform] || 0);

                setSocialData(data);

                setStateSocial(prevState => ({
                    ...prevState,
                    series: [{
                        ...prevState.series[0],
                        data: data,
                    }]
                }));
            })
            .catch(error => console.error("err123: ", error));
    };

    const deviceSelect = () => {
        axios.get('/init/deviceInit', {headers: { Authorization: LCAT }, params: { startDate: formattedStartDate, endDate: formattedEndDate }})
            .then(response => {
                const deviceCounts = response.data;

                setDeviceData(deviceCounts);

                setStateDevice(prevState => ({
                    ...prevState,
                    series: deviceCounts
                }));
            })
            .catch(error => console.error("err123: ", error));
    };

    /** endregion */

    return (
        <div className="AdminInflowStatusPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">트래픽현황</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card display-flex flex-column">
                    <div className="p-b-10 m-b-5">
                        <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                    </div>
                    <div className="traffic-chart-frame display-flex flex-row just-cont-sb p-t-15 p-b-15 font-t-cont ht-450 m-b-40">
                        <div className="wd-30pc ht-auto">
                            <div className="m-b-10"><p className="font-t-title font-s-22">Search</p></div>
                            <ApexCharts options={stateSearch.options} series={stateSearch.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                        <div className="wd-30pc ht-auto">
                            <div className="m-b-10"><p className="font-t-title font-s-22">Social</p></div>
                            <ApexCharts options={stateSocial.options} series={stateSocial.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                        <div className="wd-30pc ht-auto">
                            <div className="m-b-10"><p className="font-t-title font-s-22">Device</p></div>
                            <ApexCharts options={stateDevice.options} series={stateDevice.series} type="pie" width={"100%"} height={"100%"} />
                        </div>
                    </div>
                    <div className="traffic-count-frame display-flex flex-row just-cont-sb p-b-15 font-t-cont m-t-30">
                        <div className="social-frame display-flex flex-column wd-30pc ht-450">
                            <div className="social-total-count display-flex flex-row just-cont-sb ht-10pc">
                                <p className="font-t-title font-s-19">Search Total</p>
                                <p className="font-t-title font-s-19">{searchData.reduce((total, count) => total + count, 0)}</p>
                            </div>
                            <div className="social-gbn"></div>
                            <div className="social-cont-count display-flex flex-row just-cont-sb ht-90pc">
                                <div>
                                    <p>Naver</p>
                                    <p>Google</p>
                                    <p>Yahoo</p>
                                    <p>Bing</p>
                                    <p>Etc</p>
                                </div>
                                <div>
                                    <p>{searchData[0]}</p>
                                    <p>{searchData[1]}</p>
                                    <p>{searchData[2]}</p>
                                    <p>{searchData[3]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="social-frame display-flex flex-column wd-30pc ht-400">
                            <div className="social-total-count display-flex flex-row just-cont-sb ht-10pc">
                                <p className="font-t-title font-s-19">Social Total</p>
                                <p className="font-t-title font-s-19">{socialData.reduce((total, count) => total + count, 0)}</p>
                            </div>
                            <div className="social-gbn"></div>
                            <div className="social-cont-count display-flex flex-row just-cont-sb ht-90pc">
                                <div>
                                    <p>YouTube</p>
                                    <p>Instagram</p>
                                    <p>TikTok</p>
                                    <p>Twitter</p>
                                    <p>Threads</p>
                                    <p>FaceBook</p>
                                    <p>Etc</p>
                                </div>
                                <div>
                                    <p>{socialData[0]}</p>
                                    <p>{socialData[1]}</p>
                                    <p>{socialData[2]}</p>
                                    <p>{socialData[3]}</p>
                                    <p>{socialData[4]}</p>
                                    <p>{socialData[5]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="social-frame display-flex flex-column wd-30pc ht-400">
                            <div className="social-total-count display-flex flex-row just-cont-sb ht-10pc">
                                <p className="font-t-title font-s-19">Device Total</p>
                                <p className="font-t-title font-s-19">{deviceData.reduce((total, count) => total + count, 0)}</p>
                            </div>
                            <div className="social-gbn"></div>
                            <div className="social-cont-count display-flex flex-row just-cont-sb ht-90pc">
                                <div>
                                    <p>PC</p>
                                    <p>Mobile</p>
                                    <p>Application</p>
                                </div>
                                <div>
                                    <p>{deviceData[0]}</p>
                                    <p>{deviceData[1]}</p>
                                    <p>{deviceData[2]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminInflowStatusPg
