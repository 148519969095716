import React, {useEffect, useRef, useState} from 'react';
import {Table} from "react-bootstrap";
import {BsSquareFill} from "react-icons/bs";
import {useLocation} from "react-router-dom";
import axios from '../../../services/axiosInstance';
import MsgSelectModal from "./MsgSelectModal";
import {connect} from "../../../services/WebsocketService";
import {useWebSocket} from "../../../services/WebsocketContext";

function AdminMergeModal() {

    const LCAT = localStorage.getItem('adminToken');

    const [isLoading, setIsLoading] = useState(false);
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const cData = JSON.parse(localStorage.getItem('orderData'));
    const closeMergeModal = (() => {
        window.close();
    })

    // 기준주문서 상품총수량
    const totalQuantity = cData.productsDTO.reduce((sum, item) => {
        if (item.prodSt !== "IS12") {
            return sum + item.quantity;
        }
        return sum;
    }, 0);
    // 통합주문서 상품총수량
    const listTotalQuantity = (prod) => {
        return prod.reduce((sum, item) => {
            if (item.prodSt !== "IS12") {
                return sum + item.quantity;
            }
            return sum;
        }, 0);
    }

    const [orderdtList, setOrderdtList] = useState([])
    const [filteredOrderdtList, setFilteredOrderdtList] = useState([]);
    const [mergeOrderList, setMergeOrderList] = useState([]);

    const selectMergeList = (() => {
        axios({
            method: 'get',
            url: '/order/selectMergeList',
            params: {
                mbNum: cData.memberDTO.mbNum
            }
        })
            .then((response) => {
                let sortedOrderList = response.data.sort((a, b) => {
                    let dateA = new Date(a.modiTime);
                    let dateB = new Date(b.modiTime);
                    return dateB - dateA;
                });
                setOrderdtList(sortedOrderList);
            })
            .catch(() => {})
    })

    useEffect(() => {
        const filteredData = orderdtList.filter(order => order.orderNumber !== cData.orderNumber
            && order.recinfoDTO.countryDTO.countryNm === cData.recinfoDTO.countryDTO.countryNm
            && order.orderStatus !== "OS12" && order.orderStatus !== "OS14")
        setFilteredOrderdtList(filteredData);
    }, [orderdtList]);

    useEffect(()=> {
        if (cData) {
            selectMergeList();
        }
    }, [])

    const [isAllChecked, setIsAllChecked] = useState(false);

    const handleAllCheck = (event) => {
        if (event.target.checked) {
            const allSeqNums = filteredOrderdtList.map(order => order.seqnum);
            setMergeOrderList(allSeqNums);
        } else {
            setMergeOrderList([]);
        }
        setIsAllChecked(event.target.checked);
    };

    const toggleOrderSelection = (seqnum) => {
        setMergeOrderList(prev => {
            const newOrderList = prev.includes(seqnum) ? prev.filter(num => num !== seqnum) : [...prev, seqnum];
            setIsAllChecked(newOrderList.length === filteredOrderdtList.length);
            return newOrderList;
        });
    };

    const orderMerge = (() => {
        if (isLoading) return;

        setIsLoading(true);

        let mergeProdQueryString = mergeOrderList.map(item => 'mergeOrder=' + encodeURIComponent(item)).join('&');
        axios({
            method: 'post',
            url: `/order/orderMerge?standardOrder=${encodeURIComponent(cData.seqnum)}&${mergeProdQueryString}`,
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                // 웹소켓 전송하여 사용자 마이페이지도 order 업뎃 ㄱ
                await stompClient.send("/app/orderDivideAndMerge", {}, JSON.stringify({userMbNum:orderdtList[0].memberDTO.mbNum}));
                // 추후 여기에 AdminDashBoardPg.selectAllData() 조회함수 실행코드 추가
                closeMergeModal();
                setIsLoading(false);
            });
    });

    const [integModalSt, setIntegModalSt] = useState(false);
    const openIntegModalShow = (() => {
        if (mergeOrderList.length > 0) {
            setIntegModalSt(true);
        }
    })
    const closeIntegModalShow = (() => {
        setIntegModalSt(false);
    })
    const integModalMsg1 = "통합을 진행하시겠습니까?"
    const integModalMsg2 = "진행 후 되돌릴 수 없습니다"

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: { color: "#98B0D4", label: "입고대기" },
            OS01: { color: "#98B0D4", label: "결제대기(상품)" },
            OS02: { color: "#98B0D4", label: "결제중(상품)" },
            OS03: { color: "#FFB378", label: "결제완료(상품)" },
            OS04: { color: "#98B0D4", label: "구매중" },
            OS05: { color: "#FFB378", label: "구매완료" },
            OS06: { color: "#98B0D4", label: "입고중" },
            OS07: { color: "#6EDA60", label: "센터도착" },
            OS08: { color: "#98B0D4", label: "패킹중" },
            OS09: { color: "#98B0D4", label: "결제대기(배송)" },
            OS10: { color: "#98B0D4", label: "결제중(배송)" },
            OS11: { color: "#FFB378", label: "결제완료(배송)" },
            OS12: { color: "#979696", label: "출고완료" },
            OS13: { color: "#FF8F62", label: "반송중" },
            OS14: { color: "#FF8F62", label: "신청취소" },
        };

        const style = orderStStyles[orderSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "12px"}}>{style.label}</p>;
    };

    return (
        <div className="AdminMergeModal admin-modal-default">
            <div className="openModal regModal">
                    <section>
                        <header className="admin-work-title">
                            <b className="font-c-fff">주문서 통합</b>
                        </header>
                        <main>
                            <div className="admin-btn-wrap-l"><BsSquareFill className="admin-tb-header-notice"/><a className="font-t-cont font-s-15">통합 기준 주문서</a></div>
                            <div className="div-tb m-b-30">
                                <div className="div-tb-head ht-60">
                                    <div className="wd-200"><p>주문번호</p></div>
                                    <div className="wd-150"><p>계정명</p></div>
                                    <div className="wd-120"><p>수취인명</p></div>
                                    <div className="wd-100"><p>국가</p></div>
                                    <div className="wd-40"><p>수량</p></div>
                                    <div className="wd-130"><p>상태구분</p></div>
                                </div>
                                <div className="div-tb-body ht-70">
                                    <div className="wd-200"><p className="font-b">{cData.orderNumber}</p></div>
                                    <div className="wd-150"><p className="font-b">{cData.memberDTO.userName}</p></div>
                                    <div className="wd-120"><p>{cData.recinfoDTO.recname}</p></div>
                                    <div className="wd-100"><p>{cData.recinfoDTO.countryDTO.countryNm}</p></div>
                                    <div className="wd-40"><p>{listTotalQuantity(cData.productsDTO)}</p></div>
                                    <div className="wd-130"><p>{orderStPrint(cData.orderStatus)}</p></div>
                                </div>
                            </div>
                            <div className="admin-btn-wrap-l"><BsSquareFill className="admin-tb-header-notice"/><a className="font-t-cont font-s-15">통합 주문서</a></div>
                            <div className="div-tb m-b-30">
                                <div className="div-tb-head ht-60">
                                    <div className="wd-40">
                                        <input className="admin-input-chkBox m-0-a" type="checkbox" checked={isAllChecked} onChange={handleAllCheck}/>
                                    </div>
                                    <div className="wd-200"><p>주문번호</p></div>
                                    <div className="wd-150"><p>계정명</p></div>
                                    <div className="wd-120"><p>수취인명</p></div>
                                    <div className="wd-100"><p>국가</p></div>
                                    <div className="wd-40"><p>수량</p></div>
                                    <div className="wd-130"><p>상태구분</p></div>
                                </div>
                                {filteredOrderdtList && filteredOrderdtList.map((order, i) => (
                                <div key={i} className="div-tb-body ht-70">
                                    <div className="wd-40">
                                        <input className="admin-input-chkBox m-0-a" onChange={() => toggleOrderSelection(order.seqnum)} type="checkbox"
                                               checked={mergeOrderList.includes(order.seqnum)}/>
                                    </div>
                                    <div className="wd-200"><p className="font-b">{order.orderNumber}</p></div>
                                    <div className="wd-150"><p className="font-b">{order.memberDTO.userName}</p></div>
                                    <div className="wd-120"><p>{order.recinfoDTO.recname}</p></div>
                                    <div className="wd-100"><p>{order.recinfoDTO.countryDTO.countryNm}</p></div>
                                    <div className="wd-40"><p>{listTotalQuantity(order && order.productsDTO)}</p></div>
                                    <div className="wd-130"><p>{orderStPrint(order.orderStatus)}</p></div>
                                </div>
                                ))}
                            </div>
                        </main>
                        <footer>
                            <button onClick={closeMergeModal} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" style={{marginRight: "30px"}}>취소</button>
                            <button onClick={openIntegModalShow} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">통합</button>
                        </footer>
                    </section>
            </div>
            { integModalSt ?
            <MsgSelectModal integModalSt={integModalSt} orderMerge={orderMerge} closeIntegModalShow={closeIntegModalShow}
                            integModalMsg1={integModalMsg1} integModalMsg2={integModalMsg2}/>
                :
                null
            }
        </div>
    );
}

export default AdminMergeModal
