import React, {useEffect, useRef} from 'react';

function PaypalPaymentBt({ total, processAfterPaypal, currency }) {

    const paypalRef = useRef();

    const generateMsgNum = (() => {
        const today = new Date();
        const formatter = new Intl.DateTimeFormat('ko-KR', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });
        const dateString = formatter.format(today).replace(/[^0-9]/g, ''); // yyMMdd
        const randomLong = Math.floor(Math.random() * 1_000_000_000_0); // 10자리 랜덤 숫자
        const randomNumber = randomLong.toString().padStart(10, '0');

        return dateString + randomNumber;
    })

    useEffect(() => {
        const initializePaypalButton = async () => {
            const paypalOrderId = await generateMsgNum();

            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: currency,
                                value: total
                            },
                            reference_id: paypalOrderId
                        }]
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log("order 반환값 : ", order)
                    if (order && order.status === "COMPLETED") {
                        processAfterPaypal("COMPLETED", paypalOrderId);
                    } else if (order && order.status === "PENDING") {
                        processAfterPaypal("PENDING", paypalOrderId);
                    } else {
                        processAfterPaypal("FAILED", paypalOrderId);
                    }
                },
                onError: (err) => {
                    console.error("Payment Error!", err);
                }
            }).render(paypalRef.current);
        };

        initializePaypalButton(); // 비동기 함수 호출
    }, [total]);

    return (
        <div>
            <div ref={paypalRef}></div>
        </div>
    );
}

export default PaypalPaymentBt;
