import '../../css/mobile/MCompareShipPg.css';
import weight from "../../assets/img/weight.png";
import MHeader from "../../components/mobile/MHeader";
import DeliveryFee from "../../assets/img/DeliveryFee.png";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import axios from '../../services/axiosInstance';
import withAuth from "../../auth/UserRouteAuth";
import {BiCaretDown} from "react-icons/bi";

const MCompareShipPg = () => {
    const {t} = useTranslation();

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({ value: '', label: t(`ordCrLabel`) });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm}(${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    const [realWeight, setRealWeight] = useState('');
    const [wid, setWid] = useState('');
    const [len, setLen] = useState('');
    const [hei, setHei] = useState('');

    const [fedexAppWeight, setFedExAppWeight] = useState('');
    const [fedexEcoAppWeight, setFedExEcoAppWeight] = useState('');
    const [dhlAppWeight, setDhlAppWeight] = useState('');
    const [emsAppWeight, setEmsAppWeight] = useState('');
    const [emsPreAppWeight, setEmsPreAppWeight] = useState('');
    const [upsAppWeight, setUpsAppWeight] = useState('');
    const [upsEcoAppWeight, setUpsEcoAppWeight] = useState('');
    const [kpackAppWeight, setKpackAppWeight] = useState('');
    const [smallPackAppWeight, setSmallPackAppWeight] = useState('');
    const [seaAppWeight, setSeaAppWeight] = useState('');

    const [fedexPrice, setFedexPrice] = useState('');
    const [fedexEcoPrice, setFedexEcoPrice] = useState('');
    const [dhlPrice, setDhlPrice] = useState('');
    const [emsPrice, setEmsPrice] = useState('');
    const [emsPrePrice, setEmsPrePrice] = useState('');
    const [upsPrice, setUpsPrice] = useState('');
    const [upsEcoPrice, setUpsEcoPrice] = useState('');
    const [kpackPrice, setKpackPrice] = useState('');
    const [smallPackPrice, setSmallPackPrice] = useState('');
    const [seaPrice, setSeaPrice] = useState('');

    const initWeight = (() => {
        setRealWeight('');
        setWid('');
        setLen('');
        setHei('');
    })

    const findPrice = (weight, priceList) => {
        const weightInGrams = Math.round(weight * 1000); // kg를 그램으로 변환, 반올림 처리
        const weightKeys = Object.keys(priceList).map(key => parseInt(key.substring(1))); // "w500" -> 500
        const sortedWeights = weightKeys.sort((a, b) => a - b);

        for (let i = 0; i < sortedWeights.length; i++) {
            if (weightInGrams <= sortedWeights[i]) {
                return priceList[`w${sortedWeights[i]}`]; // 해당 무게에 맞는 가격 반환
            }
        }
        return t('cspWtNoSurch'); // 무게구간에 해당하지 않을 경우 '별도문의' 반환
    };

    const roundUp = (value, unit) => {
        return Math.ceil(value / unit) * unit;
    };

    const apyWeightCalculation = (dvType, wid, len, hei, realWeight) => {
        const width = parseFloat(wid);
        const height = parseFloat(hei);
        const length = parseFloat(len);
        const netWeight = parseFloat(realWeight);

        if (isNaN(width) || isNaN(height) || isNaN(length) || isNaN(netWeight)) return 0;

        let volWeight, baseWeight, finalWeight;

        switch (dvType) {
            // FEDEX, FEDEX-ECO, UPS, UPS-ECO, DHL, 국내
            case "D01": case "D16": case "D11": case "D17": case "D04":
                volWeight = (width * length * height) / 5000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = baseWeight <= 21
                    ? roundUp(baseWeight, 0.5)
                    : roundUp(baseWeight, 1);
                break;
            case "D02": // EMS
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                const gram = baseWeight * 1000;
                finalWeight = gram <= 2000
                    ? roundUp(gram, 250) / 1000
                    : roundUp(gram, 500) / 1000;
                break;
            case "D15": // EMS 프리미엄
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = baseWeight <= 20
                    ? roundUp(baseWeight, 0.5)
                    : roundUp(baseWeight, 1);
                break;
            case "D13": // K-Packet
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = roundUp(baseWeight * 1000, 100) / 1000;
                break;
            case "D14": // Air Small
                finalWeight = roundUp(netWeight, 0.1);
                break;
            case "D03": // SEA
                const weightGram = netWeight * 1000;
                finalWeight = weightGram <= 1000
                    ? roundUp(weightGram, 1000) / 1000
                    : roundUp(weightGram, 2000) / 1000;
                break;
            case "D00": // 국내배송
                volWeight = (width * length * height) / 5000;
                baseWeight = Math.max(netWeight, volWeight);
                if (baseWeight <= 3) {
                    finalWeight = 3;
                } else if (baseWeight <= 5) {
                    finalWeight = 5;
                } else if (baseWeight <= 10) {
                    finalWeight = 10;
                } else if (baseWeight <= 20) {
                    finalWeight = 20;
                } else {
                    finalWeight = 25;
                }
                break;
            default:
                finalWeight = 0;
        }

        return parseFloat(finalWeight.toFixed(2));
    };

    // 실무게&가로&세로&높이 모두 입력될때만 DB에 조회쿼리
    useEffect(() => {
        if (selectedCountry.value !== '' && realWeight !== '' && wid !== '' && len !== '' && hei !== '') {
            selectedShipFee(selectedCountry.value);
        }
    }, [selectedCountry, realWeight, wid, len, hei])

    const selectedShipFee = ((crCd) => {
        axios({
            method: 'get',
            url: '/shipinfo/selectedShipFee',
            params: {
                crCd: crCd
            }
        })
            .then((response) => {
                const data = response.data;

                // 각 배송사별 절상된 무게단위로 계산
                const formattedFedexAppWeight = apyWeightCalculation("D01", wid, len, hei, realWeight);
                const formattedFedexEcoAppWeight = apyWeightCalculation("D16", wid, len, hei, realWeight);
                const formattedUpsAppWeight = apyWeightCalculation("D11", wid, len, hei, realWeight);
                const formattedUpsEcoAppWeight = apyWeightCalculation("D17", wid, len, hei, realWeight);
                const formattedEmsAppWeight = apyWeightCalculation("D02", wid, len, hei, realWeight);
                const formattedEmsPreAppWeight = apyWeightCalculation("D15", wid, len, hei, realWeight);
                const formattedKpackAppWeight = apyWeightCalculation("D13", wid, len, hei, realWeight);
                const formattedSmallPackAppWeight = apyWeightCalculation("D14", wid, len, hei, realWeight);
                const formattedSeaAppWeight = apyWeightCalculation("D03", wid, len, hei, realWeight);
                const formattedDhlAppWeight = apyWeightCalculation("D04", wid, len, hei, realWeight);

                setFedExAppWeight(formattedFedexAppWeight);
                setFedExEcoAppWeight(formattedFedexEcoAppWeight);
                setUpsAppWeight(formattedUpsAppWeight);
                setUpsEcoAppWeight(formattedUpsEcoAppWeight);
                setEmsAppWeight(formattedEmsAppWeight);
                setEmsPreAppWeight(formattedEmsPreAppWeight);
                setKpackAppWeight(formattedKpackAppWeight);
                setSmallPackAppWeight(formattedSmallPackAppWeight)
                setSeaAppWeight(formattedSeaAppWeight)
                setDhlAppWeight(formattedDhlAppWeight);

                // 가격찾고 서비스하지 않는 국가로 인해 null이면 '별도문의 반환'
                let fedexPrice = data.exrInfoFEDEXDTO ? findPrice(formattedFedexAppWeight, data.exrInfoFEDEXDTO) : t('cspWtNoSurch');
                let fedexEcoPrice = data.exrInfoFEDEXECODTO ? findPrice(formattedFedexEcoAppWeight, data.exrInfoFEDEXECODTO) : t('cspWtNoSurch');
                let upsPrice = data.exrInfoUPSDTO ? findPrice(formattedUpsAppWeight, data.exrInfoUPSDTO) : t('cspWtNoSurch');
                let upsEcoPrice = data.exrInfoUPSECODTO ? findPrice(formattedUpsEcoAppWeight, data.exrInfoUPSECODTO) : t('cspWtNoSurch');
                let dhlPrice = data.exrInfoDHLDTO ? findPrice(formattedDhlAppWeight, data.exrInfoDHLDTO) : t('cspWtNoSurch');
                let emsPrice = data.exrInfoEMSDTO ? findPrice(formattedEmsAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let emsPrePrice = data.exrInfoEMSPREDTO ? findPrice(formattedEmsPreAppWeight, data.exrInfoEMSPREDTO) : t('cspWtNoSurch');
                let kpackPrice = data.exrInfoKPACKDTO ? findPrice(formattedKpackAppWeight, data.exrInfoKPACKDTO) : t('cspWtNoSurch');
                let smallPackPrice = data.exrInfoAIRSDTO ? findPrice(realWeight, data.exrInfoAIRSDTO) : t('cspWtNoSurch');
                let seaPrice = data.exrInfoSHIPDTO ? findPrice(realWeight, data.exrInfoSHIPDTO) : t('cspWtNoSurch');

                // fedex 유류할증료 합산
                if (typeof fedexPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexPrice = Math.round(fedexPrice / 10) * 10;
                }
                // fedex-e 운송수수료 합산
                if (typeof fedexEcoPrice === "number" && data.countryDTO.fedexFee > 0) {
                    const additionalFedexEcoFee = formattedFedexEcoAppWeight * 10 * data.countryDTO.fedexFee;
                    fedexEcoPrice += additionalFedexEcoFee;
                }

                if (typeof fedexEcoPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexEcoPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexEcoPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexEcoPrice = Math.round(fedexEcoPrice / 10) * 10;
                }
                // fedex-e 운송수수료 합산
                if (typeof fedexEcoPrice === "number" && data.countryDTO.fedexEcoFee > 0) {
                    const additionalFedexEcoFee = formattedFedexEcoAppWeight * 10 * data.countryDTO.fedexEcoFee;
                    fedexEcoPrice += additionalFedexEcoFee;
                }

                // ups 유류할증료 합산
                if (typeof upsPrice === "number" && data.countryDTO && data.countryDTO.upsFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.upsFuelSurcharge / 100;
                    const fuelSurcharge = upsPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    upsPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    upsPrice = Math.round(upsPrice / 10) * 10;
                }
                // ups 운송수수료 합산
                if (typeof upsPrice === "number" && data.countryDTO.upsFee > 0) {
                    const additionalUpsFee = formattedUpsAppWeight * 10 * data.countryDTO.upsFee;
                    upsPrice += additionalUpsFee;
                }

                if (typeof upsEcoPrice === "number" && data.countryDTO && data.countryDTO.upsFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.upsFuelSurcharge / 100;
                    const fuelSurcharge = upsEcoPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    upsEcoPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    upsEcoPrice = Math.round(upsEcoPrice / 10) * 10;
                }
                // ups 운송수수료 합산
                if (typeof upsEcoPrice === "number" && data.countryDTO.upsEcoFee > 0) {
                    const additionalUpsEcoFee = formattedUpsEcoAppWeight * 10 * data.countryDTO.upsEcoFee;
                    upsEcoPrice += additionalUpsEcoFee;
                }

                // dhl 유류할증료 합산
                if (typeof dhlPrice === "number" && data.countryDTO && data.countryDTO.dhlFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 dhlPrice에서 dhlFuelSurcharge값만큼 dhlPrice에 +=
                    const surchargeRate = data.countryDTO.dhlFuelSurcharge / 100;
                    const fuelSurcharge = dhlPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    dhlPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    dhlPrice = Math.round(dhlPrice / 10) * 10;
                }
                // dhl 운송수수료 합산
                if (typeof dhlPrice === "number" && data.countryDTO.dhlFee > 0) {
                    const additionalDhlFee = formattedFedexAppWeight * 10 * data.countryDTO.dhlFee;
                    dhlPrice += additionalDhlFee;
                }

                // ems 운송수수료 합산
                if (typeof emsPrice === "number" && data.countryDTO.emsFee > 0) {
                    const additionalEmsFee = formattedEmsAppWeight * 10 * data.countryDTO.emsFee;
                    emsPrice += additionalEmsFee;
                }

                // emsp 운송수수료 합산
                if (typeof emsPrePrice === "number" && data.countryDTO.emsPreFee > 0) {
                    const additionalEmsPreFee = formattedEmsPreAppWeight * 10 * data.countryDTO.emsPreFee;
                    emsPrePrice += additionalEmsPreFee;
                }

                // K-Packet 운송수수료 합산
                if (typeof kpackPrice === "number" && data.countryDTO.kpackFee > 0) {
                    const additionalKpackFee = formattedKpackAppWeight * 10 * data.countryDTO.kpackFee;
                    kpackPrice += additionalKpackFee;
                }

                // Small-Packet 운송수수료 합산
                if (typeof smallPackPrice === "number" && data.countryDTO.airSFee > 0) {
                    const additionalSmallPackFee = formattedSmallPackAppWeight * 10 * data.countryDTO.airSFee;
                    smallPackPrice += additionalSmallPackFee;
                }

                // sea 운송수수료 합산
                if (typeof seaPrice === "number" && (data.countryDTO.countryCode === "US" || data.countryDTO.countryCode === "CA")) {
                    const additionalSeaFee = formattedSeaAppWeight * 10 * data.countryDTO.seaFee;
                    seaPrice += additionalSeaFee;
                }

                setFedexPrice(fedexPrice);
                setFedexEcoPrice(fedexEcoPrice);
                setUpsPrice(upsPrice);
                setUpsEcoPrice(upsEcoPrice);
                setDhlPrice(dhlPrice);
                setEmsPrice(emsPrice);
                setEmsPrePrice(emsPrePrice);
                setKpackPrice(kpackPrice);
                setSmallPackPrice(smallPackPrice);
                setSeaPrice(seaPrice);
            })
            .catch((error) => {
                console.log("배송사별 요금조회 실패 : ", error)
            })
    })

    const handleNumericInput = (e, setter, allowDecimal = false) => {
        let value = e.target.value;
        let regex = allowDecimal ? /^[0-9]*\.?[0-9]*$/ : /^[0-9]*$/;

        if (regex.test(value)) {
            setter(value);
        }
    };

    return (
        <div className="m-a-compare-ship-pg-container font-t-cont">
            <MHeader title={t(`cspHeader`)} url={"/"}/> {/*배송료비교*/}
            <div className='m-a-compare-ship-sub-title'>
                <img className='m-a-compare-ship-sub-title-icon' src={weight} alt='header'/>
                <p className="font-t-title font-s-15 p-t-5"> {t(`cspSmallHeader1`)}</p> {/*무게정보*/}
            </div>
            <div className='m-a-compare-ship-divider'/>
            <div className="display-flex flex-column gap10">
                <div className='display-flex flex-row just-cont-sb wd-100pc gap8'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`modSmallMenu3`)}</div> {/*국가*/}
                    <div className="ht-45 wd-70pc position-relative" ref={countryRef} onClick={toggleCountryShow}>
                        <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                            {selectedCountry.value ? (
                                <div className="wd-15pc text-center p-l-8 p-t-10">
                                    <div className={`font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                </div>
                            ) : (
                                <div className="wd-20pc text-center p-l-8 p-t-10">
                                    <div className="wd-29 ht-22 text-center p-t-1 font-s-12 country-select-flag">?</div>
                                </div>
                            )}
                            {/*국가명 입력 또는 선택*/}
                            <input className="country-select-in-input wd-75pc font-s-12 p-l-13"
                                   type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="nope"
                                   value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                   onChange={handleCountrySearch} />
                            <i className="wd-13pc text-center align-center-c font-s-18 p-b-2 font-c-darkgrey">
                                <BiCaretDown />
                            </i>
                        </div>
                        {countryShow && (
                            <div className="country-select-data wd-100pc position-absolute m-t-5">
                                <div className="country-select-data-child">
                                {filteredCountryList.map((country, index) => (
                                    <div className="selectChildBt display-flex p-t-10 p-b-10 p-l-20" key={index}
                                         onClick={(event) => handleCountryChange(country, event)} style={{zIndex: 99 }}>
                                        <div className={`font-s-15 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                        <p className="font-s-13">{country.label}</p>
                                    </div>
                                ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='display-flex flex-row just-cont-sb wd-100pc gap8'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`cspRw`)}(kg)</div> {/*무게*/}
                    <div className="wd-70pc">
                        <input type="text" className="border-bt ht-45 wd-100pc p-l-5 border-rd4 font-s-14 font-b text-center" inputMode="numeric" pattern="\d*"
                               placeholder="0.00"  value={realWeight} maxLength="5"
                               onChange={(e) => handleNumericInput(e, setRealWeight, true)}/>
                    </div>
                </div>
                <div className='display-flex flex-row just-cont-sb wd-100pc gap8'>
                    <div className='m-a-compare-ship-weight-frame-grid-label'>{t(`cspV`)}(kg)</div> {/*부피무게*/}
                    <div className="wd-70pc display-flex flex-row just-cont-sb">
                        <div className="wd-28pc">
                            <input type="tel" className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-14 font-b" inputMode="numeric" pattern="\d*"
                                   placeholder="0" value={wid} minLength="1" maxLength="3" onChange={(e) => handleNumericInput(e, setWid)}/>
                        </div>
                        <div className="align-center"><p className="font-s-20 p-t-10">*</p></div>
                        <div className="wd-28pc">
                            <input type="tel" className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-14 font-b" inputMode="numeric" pattern="\d*"
                                   placeholder="0" value={len} maxLength="3" onChange={(e) => handleNumericInput(e, setLen)}/>
                        </div>
                        <div className="align-center"><p className="font-s-20 p-t-10">*</p></div>
                        <div className="wd-28pc">
                            <input type="tel" className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-14 font-b" inputMode="numeric" pattern="\d*"
                                   placeholder="0" value={hei} maxLength="3" onChange={(e) => handleNumericInput(e, setHei)}/>
                        </div>
                    </div>
                </div>
                <div className="display-flex flex-row just-cont-sb align-center gap8">
                    <div className="m-a-compare-ship-weight-frame-grid-label"><p className="font-s-13">{t(`cspVw`)}(cm)</p> {/*부피무게*/}</div>
                    {emsAppWeight && fedexAppWeight && (
                        <div className="wd-70pc display-flex flex-row just-cont-fstart font-s-13" style={{color: "dimgray"}}>
                            <p>{emsAppWeight}kg</p><p>&nbsp;~&nbsp;</p><p>{fedexAppWeight}kg</p>
                        </div>
                    )}
                </div>
                <div className="m-b-40 text-center">
                    <button className="bt-btn-A wd-40pc ht-45 font-s-14" onClick={initWeight}>{t(`cspBt`)} {/*초기화 버튼*/}</button>
                </div>
            </div>
            <div className='m-a-compare-ship-sub-title'>
                <img className='m-a-compare-ship-sub-title-icon' src={DeliveryFee} alt=''/>
                <div className='m-a-compare-ship-sub-title-text'>{t(`cspSmallHeader2`)}</div>
            </div>
            <div className='m-a-compare-ship-divider'/>
            <div className='m-a-compare-ship-delivery-fee-frame'>
                {/*페덱스*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>FedEx</div>
                        {fedexAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                (${t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{fedexAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!fedexPrice && 'normal'}`}>
                        &#8361;  {fedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*페덱스-E*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>FedEx-E</div>
                        {fedexEcoAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                (${t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{fedexEcoAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!fedexEcoPrice && 'normal'}`}>
                        &#8361;  {fedexEcoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*DHL*/}
                {/*<div className='m-a-compare-ship-delivery-fee-row'>*/}
                {/*    <div className='m-a-compare-ship-delivery-fee-row-left'>*/}
                {/*        <div className='m-a-compare-ship-delivery-fee-agency'>DHL</div>*/}
                {/*        {dhlAppWeight &&*/}
                {/*            <div className='m-a-compare-ship-delivery-fee-weight'>*/}
                {/*                ({t(`cspAw`)}*/}
                {/*                <div className='strong'>{dhlAppWeight}</div>*/}
                {/*                kg)*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div className={`m-a-compare-ship-delivery-fee-row-right ${!dhlPrice && 'normal'}`}>*/}
                {/*        &#8361;  {dhlPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*UPS*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>UPS</div>
                        {upsAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{upsAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!upsPrice && 'normal'}`}>
                        &#8361;  {upsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*UPS-E*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>UPS-E</div>
                        {upsEcoAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{upsEcoAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!upsEcoPrice && 'normal'}`}>
                        &#8361;  {upsEcoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*EMS*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>EMS</div>
                        {emsAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{emsAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!emsPrice && 'normal'}`}>
                        &#8361;  {emsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*EMSP*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>EMSP</div>
                        {emsPreAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{emsPreAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!emsPrePrice && 'normal'}`}>
                        &#8361;  {emsPrePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*K-Packet*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>K-Packet</div>
                        {kpackAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{kpackAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!kpackPrice && 'normal'}`}>
                        &#8361;  {kpackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*Small-Packet*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'>Small-Packet</div>
                        {smallPackAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{smallPackAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!smallPackPrice && 'normal'}`}>
                        &#8361;  {smallPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
                {/*선편*/}
                <div className='m-a-compare-ship-delivery-fee-row'>
                    <div className='m-a-compare-ship-delivery-fee-row-left'>
                        <div className='m-a-compare-ship-delivery-fee-agency'> Sea Parcel</div>
                        {seaAppWeight &&
                            <div className='m-a-compare-ship-delivery-fee-weight'>
                                ({t(`cspAw`)}
                                <div className='strong font-c-darkorange'>{seaAppWeight}</div>
                                kg)
                            </div>
                        }
                    </div>
                    <div className={`m-a-compare-ship-delivery-fee-row-right ${!seaPrice && 'normal'}`}>
                        &#8361;  {seaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                    </div>
                </div>
            </div>
            <div className='m-a-compare-ship-description'>
                <p>※ {t('cspNt1')}</p>
                <p style={{color:"#4B89DC"}}>※ {t('cspNt2')}</p>
            </div>
        </div>
    )
}

export default MCompareShipPg;
