import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

// 토큰검사를 하지 않는 페이지들 리스트
const PUBLIC_ROUTES = [
    "/",
    "/UserLoginPg",
    "/UserJoinPg",
    "/UserIdPwFindPg",
    "/UserReviewPg",
    /^\/UserReviewDetailPg\/\d+$/,
    "/UserNoticePg",
    /^\/UserNoticeDtPg\/\d+$/,
    "/UserFaqPg",
    /^\/UserFaqDtPg\/\d+$/,
    "/UserCustomInfoPg",
    "/UserCompareShipPg",
    "/NonUserChatPg",
    "/UserMailInquiryPg",
    "/UserMailSendComplPg",
    "/UserUsageGuidePg",
    "/public",

    "/rendered/*",
    /^\/rendered\/.*/,
    /^\/rendered\/.+$/,

    "/btcorpad-login",
    "/btcorpad-join",
    "/btcorpad-pwFind",
];

function AppWrapper({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const adminLCAT = localStorage.getItem('adminToken');
    const token = SSAT || LCAT || adminLCAT

    const isPublicRoute = PUBLIC_ROUTES.some(route =>
        typeof route === 'string' ? location.pathname === route : route.test(location.pathname)
    );

    useEffect(() => {
        if (!token && !isPublicRoute) {
            // 현재 페이지를 세션스토리지에 저장(로그인 후 기존페이지로 이동시키기 위한 목적)
            sessionStorage.setItem("redirectAfterLogin", location.pathname);

            // 로그인 페이지로 이동
            if (location.pathname.startsWith('/btcorpad')) {
                navigate("/btcorpad-login");
            } else {
                navigate("/UserLoginPg");
            }
        }
    }, [location, token, navigate]);

    return children;
}

export default AppWrapper
