import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from '../../services/axiosInstance';
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import ApexCharts from "react-apexcharts";

function MAdminUserStatusPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    /** endregion */

    /** region Event */

    const [chartData, setChartData] = useState({ series: [], options: {} });

    useEffect(() => {
        const selectOutsalesMember = async () => {
            try {
                const today = new Date();
                const oneYearAgo = new Date(today);
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                const formatDateString = (date) => date.toISOString().split('T')[0];
                const formattedStartDate = formatDateString(oneYearAgo);
                const formattedEndDate = formatDateString(today);

                const response = await axios.get('/outsales/selectOutsalesMember', {
                    params: { startDate: formattedStartDate.replace(/-/g, ''), endDate: formattedEndDate.replace(/-/g, '') }
                });

                const processedData = response.data.map(item => ({
                    ...item,
                    totalAmount: item.finalPrice
                }));

                const sortedData = processedData.sort((a, b) => b.totalAmount - a.totalAmount);
                const top10 = sortedData.slice(0, 10);

                const seriesData = top10.map(item => item.totalAmount);
                const categories = top10.map(item => item.memberDTO.userName);

                setData(top10);
                setChartData({
                    series: [{ name: '총 금액', data: seriesData }],
                    options: {
                        chart: { type: 'bar' },
                        plotOptions: {
                            bar: { borderRadius: 0, horizontal: true, barHeight: '70%' }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: (val, opt) => {
                                return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val.toLocaleString()}`;
                            }
                        },
                        title: { text: 'TOP 10', align: 'middle' },
                        tooltip: {
                            enabled: true,
                            theme: 'dark',
                            y: {
                                formatter: (val) => val.toLocaleString()
                            }
                        },
                        xaxis: {
                            categories: categories,
                            labels: {
                                formatter: (val) => val.toLocaleString()
                            },
                            tickAmount: 3,
                        },
                        colors: ['#ff9345'],
                        legend: { show: false },
                    }
                });
            } catch (error) {
                console.log('데이터 조회 실패:', error);
            }
        };

        selectOutsalesMember();
    }, []);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    useEffect(() => {
        const today = new Date();
        // const oneMonthAgo = new Date(today);
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        setStartDate(formatDateString(oneYearAgo));
        setEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsalesMember();
        }
    }, [startDate, endDate])

    const selectOutsalesMember = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios({
            method: 'get',
            url: '/outsales/selectOutsalesMember',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.finalPrice - a.finalPrice);
                setData(sortedData);
            })
            .catch((error) => {
                console.log("고객별 매출데이터 조회실패 : ", error)
            })
    })

    /** endregion */

    return (
        <div id="m-a-user-service-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">고객별이용현황</p>
            </div>
            <div className="m-a-card m-b-15">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event-combo just-cont-sb">
                        <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        <label className="font-s-12 font-c-default p-t-5">today</label>
                    </div>
                </div>
            </div>
            <div className="m-a-card ht-600">
                <div className="wd-100pc ht-100pc">
                    <ApexCharts options={chartData.options} series={chartData.series} type="bar" width={"100%"} height={"100%"}/>
                </div>
            </div>
        </div>
    );
}

export default MAdminUserStatusPg
