import React, {useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdminOrderChatPg.css'
import axios from '../../services/axiosInstance';
import profileDefaultImg from "../../assets/img/profileDefaultImg.png";
import ordChatListImg from "../../assets/img/ordChatListImg.png";
import mainChatPeople from "../../assets/img/mainChatPeople.png";
import trash from "../../assets/img/mainChatTrash.png";
import mainChatUpload from "../../assets/img/mainChatUpload.png";
import mainChatEmoji from "../../assets/img/mainChatEmoji.png";
import mainChatSend from "../../assets/img/mainChatSend.png";
import commonClose from "../../assets/img/commonClose.png";

import Picker from "@emoji-mart/react";
import MMsgSelectModal from "../../components/mobile/MMSgSelectModal";
import MChatFileUploadModal from "../../components/mobile/MChatFileUploadModal";
import MChatFileDownModal from "../../components/mobile/MChatFileDownModal";
import MChatModifyModal from "../../components/mobile/MChatModifyModal";
import chatFileImg from "../../assets/img/chatFolderImg.png";
import commonMacro from "../../assets/img/commonMacro.png";
import DOMPurify from "dompurify";
import Linkify from "react-linkify";
import {useWebSocket} from "../../services/WebsocketContext";
import MMsgCheckModal from "../../components/mobile/MMsgCheckModal";
import MChatFileUpload from "../../components/mobile/MChatFileUpload";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";

function MAdminOrderChatPg({setNoApply}) {

    const [memberInfo, setMemberInfo] = useState([{}]);
    const [adminInfo, setAdminInfo] = useState([]);
    const LCAT = localStorage.getItem('adminToken');

    useEffect(() => {
        // 페이지 진입 시 부모의 padding을 제거
        setNoApply(true);

        // 페이지 나갈 때 원상 복구
        return () => setNoApply(false);
    }, [setNoApply]);

    /** endregion */

    /** region Function */

    const [showEmoji, setShowEmoji] = useState(false);
    const [macroOpen, setMacroOpen] = useState(false);
    const [favFn, setFavFn] = useState(false);

    const toggleMenu = () => {
        setFavFn(!favFn);
    };

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };
    const stringStartDate = startDate.replace(/-/g, '');
    const stringEndDate = endDate.replace(/-/g, '');

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 180); //6개월

    const defaultMonth = () => {
        const stringStartDate = formatDateChat(twoWeeksAgo);
        const stringEndDate = formatDateChat(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDateChat = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    //endregion

    const [selectedUserName, setSelectedUserName] = useState(null);
    const [selectedUserOrderNumbers, setSelectedUserOrderNumbers] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [focusedOrderIndex, setFocusedOrderIndex] = useState(null);
    const [chatStarted, setChatStarted] = useState(false);
    const [mstMbNum, setMstMbNum] = useState(null);
    const [mstUserNm, setMstUserNm] = useState(null);

    const handleUserNameClick = (data, index) => {
        if (focusedIndex === index) {
            setFocusedIndex(null);
        } else {
            selectedOrderNumbers(data.mstMbNum);
            setSelectedUserName(data.memberDTO.userName);
            setFocusedIndex(index);
            setFocusedOrderIndex(null);
        }
    }

    const selectedOrderNumbers = ((mstMbNum) => {
        axios({
            method: 'get',
            url: '/order/orderNumbers/',
            params: {
                mstMbNum: mstMbNum,
                startDate: stringStartDate,
                endDate: stringEndDate
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const sortData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setSelectedUserOrderNumbers(sortData);
            })
    })

    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);

    const handleOrderNumberlick = (orderNumber, index) => {
        handleChatMessages(orderNumber)
        setFocusedOrderIndex(index);
        setMstMbNum(orderNumber.mstMbNum);
        setMstUserNm(orderNumber.mstUserNm);
        setSelectedOrderNumber(orderNumber.chatOrdNum);
        setChatStarted(true);
    };

    /** endregion */

    /** region Event */

    function macroViewOpen() {
        setMacroOpen(!macroOpen);
    }

    //region Chatting

    const findAdmin = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const [lcOrdChatCount, setLcOrdChatCount] = useState(0);
    const [lcNUserChatCount, setLcNUserChatCount] = useState(0);
    const selectChatCount = (() => {
        axios({
            method: 'get',
            url: '/order/selectChatCount',
        })
            .then((response) => {
                // 각 채팅총개수 합산
                // const totalChats = response.data.userChat + response.data.nonUserChat;
                // setLcChatData(totalChats);

                // 총채팅개수 각각 산출
                console.log("response.data : ", response.data)
                setLcOrdChatCount(response.data.ordChat > 999 ? 999 : response.data.ordChat);
                setLcNUserChatCount(response.data.nonUserChat > 999 ? 999 : response.data.nonUserChat);
            })
            .catch((error) => console.error(error))
    })

    // 주문서채팅 시작
    const [memberList, setMemberList] = useState([]);
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showChatFile, setShowChatFile] = useState(false);

    const fileInputRef = useRef();

    useEffect(() => {
        findAdmin();
        selectChatCount();
    }, []);

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                // 주문서채팅
                subscribe(`/topic/userOrdMessage`, (message) => {
                    onMessageReceived(message);
                    selectChatCount();
                });
                subscribe(`/topic/userOrdChatRead`, (message) => {
                    onReadMessageReceived(message);
                    selectChatCount();
                });
                subscribe(`/topic/adminChatUpdate`, (message) => {
                    onChatAdminUpdate(message);
                    selectChatCount();
                });

                // 비회원채팅
                subscribe(`/topic/nonUserMessage`, (message) => {
                    nonOnMessageReceived(message);
                    selectChatCount();
                });
                subscribe(`/topic/nonUserChatRead`, (message) => {
                    nonOnReadMessageReceived(message);
                    selectChatCount();
                });
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                subscribe(`/topic/userOrdMessage`);
                subscribe(`/topic/userOrdChatRead`);
                subscribe(`/topic/adminChatUpdate`);
                subscribe(`/topic/nonUserMessage`);
                subscribe(`/topic/nonUserChatRead`);
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const selectedOrderNumberRef = useRef(selectedOrderNumber);
    const chatStartedRef = useRef(chatStarted);
    const mstUserNmRef = useRef(mstUserNm);
    const selectedOrderNumbersRef = useRef(selectedOrderNumbers);

    // 함수 내 비동기로 인해 미리 값 세팅
    useEffect(() => {
        selectedOrderNumberRef.current = selectedOrderNumber;
        chatStartedRef.current = chatStarted;
        mstUserNmRef.current = mstUserNm;
        selectedOrderNumbersRef.current = selectedOrderNumber
    }, [selectedOrderNumber, chatStarted, mstUserNm, selectedOrderNumber]);

    const onMessageReceived = (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }
        // new 메시지로 챗리스트 전체 업뎃
        if (newMessage.memberDTO.role === "0") { // 관리자공유로 인해 중복수신 없도록
            setChatMessages(chatMessages => [...chatMessages, newMessage]);
            const isDuplicate = chatMessages.some(message => message.seqnum === newMessage.seqnum);
            if (isDuplicate) return chatMessages; // 이미 메시지가 존재하면 추가하지 않음
        }

        // fetchChatMessages(newMessage.chatOrdNum)

        // memberList 업데이트
        setMemberList(prevMembers => {
            let existingMemberIndex = prevMembers.findIndex(member => String(member.mbNum) === String(newMessage.mbNum));
            const isUserRole = newMessage.memberDTO && newMessage.memberDTO.role === '0';
            // 첫메시지일 경우에는 새롭게 memberList 출력업뎃
            if (existingMemberIndex === -1) {
                const newMember = {
                    mstMbNum: newMessage.mstMbNum,
                    mstUserNm: newMessage.mstUserNm,
                    seqnum: newMessage.seqnum,
                    unreadCount: isUserRole ? 1 : 0,
                    chatKey: newMessage.chatKey,
                    regTime: newMessage.regTime,
                    memberDTO: newMessage.memberDTO
                };

                return [...prevMembers, newMember].sort((a, b) => b.seqnum - a.seqnum);
            } else {
                // 기존 멤버리스트의 추가메시지만 업뎃
                return prevMembers.map((member, index) => {
                    if (index === existingMemberIndex) {
                        return {
                            ...member,
                            mstMbNum: newMessage.mstMbNum,
                            mstUserNm: newMessage.mstUserNm,
                            seqnum: newMessage.seqnum,
                            unreadCount: isUserRole ? member.unreadCount + 1 : member.unreadCount,
                            chatKey: newMessage.chatKey,
                            regTime: newMessage.regTime,
                            memberDTO: newMessage.memberDTO
                        };
                    }
                    return member;
                }).sort((a, b) => b.seqnum - a.seqnum);
            }
        });

        // 기존 오더리스트 메시지에서 new 오더리스트 메시지만 추가업뎃
        setSelectedUserOrderNumbers(prevOrderNumbers => {
            const updatedOrderNumbers = prevOrderNumbers.map(orderNumber => {
                if (orderNumber.chatOrdNum === newMessage.chatOrdNum) {
                    const isOrderRole = newMessage.memberDTO.role === '0';
                    return {
                        ...orderNumber,
                        seqnum: newMessage.seqnum,
                        unreadCount: isOrderRole ? orderNumber.unreadCount + 1 : orderNumber.unreadCount,
                        chatKey: newMessage.chatKey
                    };
                }
                return orderNumber;
            });
            return updatedOrderNumbers.sort((a, b) => b.seqnum - a.seqnum);
        });

        // 새로 등록된 메시지의 chatOrdNum과 관리자가 선택한 chatDt.주문번호가 동일 && chatStarted가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.chatOrdNum === selectedOrderNumberRef.current && chatStartedRef.current) {
            handleChatMessages(newMessage);
            stompAdminRead(newMessage.chatOrdNum, newMessage.mstUserNm, newMessage.mstMbNum);
        }
    }

    const onChatAdminUpdate = ((payload) => {
        let adminUpdateData = JSON.parse(payload.body);
        if (adminUpdateData !== null) {
            fetchChatMessages(adminUpdateData);
        }
    })

    const formatDate = (dateString) => {
        let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const isImage = (fileName) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(fileExtension);
    }

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(extension);
    };

    const handleChatMessages = async (chat) => {
        try {
            await axios({
                method: 'post',
                url: `/order/chat/unread/`,
                params: {
                    chatOrdNum: chat.chatOrdNum,
                    userFl: false
                },
                headers: {
                    Authorization: LCAT
                }
            })
                .then(async () => {
                    await stompClient.send("/app/chat/totalCount", {}, JSON.stringify({sendFl: true}));
                    // chatDt 클릭 후 멤버상태변경
                    setMemberList((prevMembers) =>
                        prevMembers.map((member) => {
                            if (member.mstMbNum === chat.mstMbNum) {
                                return {
                                    ...member,
                                    unreadCount: member.unreadCount - chat.unreadCount,
                                };
                            }
                            return member;
                        })
                    );

                    // chatDt.unreadCount를 리셋
                    setSelectedUserOrderNumbers((prevChats) =>
                        prevChats.map((chatDt) => {
                            if (chatDt.seqnum === chat.seqnum) {
                                stompAdminRead(chatDt.chatOrdNum, chatDt.mstUserNm, chatDt.mstMbNum);
                                return {
                                    ...chatDt,
                                    unreadCount: 0,
                                };
                            }
                            return chatDt;
                        })
                    );
                })
        } catch (error) {
            console.error(error);
        }
    };

    const oneMonthFromNow = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    function isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    function isFirstMessageOfDay(current, previous) {
        if (!previous) return true;
        const isSame = isSameDay(new Date(current.regTime), new Date(previous.regTime));
        return !isSame;
    }

    const formatTime = (timeString) => {
        return new Date(timeString).toTimeString().split(' ')[0];
    }

    const fetchChatMessages = async (chatOrdNum) => {
        if (!chatOrdNum) {
            return;
        }
        try {
            const response = await axios({
                method: 'get',
                url: '/order/chatFetch',
                headers: {
                    Authorization: LCAT
                },
                params: {
                    chatOrdNum: chatOrdNum
                }
            });
            if (!response || response.data === false) {
                console.log('데이터없음');
                return;
            }
            const data = response.data;

            for (let message of data) {
                if (message.chatKey.startsWith('chatFile')) {
                    const filename = message.chatKey.split('/').pop();
                    if (isVideo(filename)) {
                        message.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/" + message.chatKey;
                        message.isVideo = true;
                    } else if (isImage(filename)) {
                        message.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/" + message.chatKey;
                        message.isImage = true;
                    } else {
                        message.fileUrl = "file";
                    }
                }
            }
            setChatMessages(data);
            return data;
        } catch (error) {
            console.log("채팅페치오류 : ", error)
        }
    }

    useEffect(() => {
        fetchChatMessages(selectedOrderNumber && selectedOrderNumber)
            .then(data => setChatMessages(data))
            .catch(err => console.error(err));
    }, [selectedOrderNumber]);

    // 사용자 -> 관리자 읽음처리 수신
    const onReadMessageReceived = (payload) => {
        // 사용자가 읽었을 경우 관리자채팅 안읽은메시지수 0으로 초기화
        let userReadData = JSON.parse(payload.body);
        if (userReadData.readerRole === "USER" && chatStartedRef.current) {
            setChatMessages(currentChatMessages => {
                const updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let message of updatedMessages) {
                    if (message.chatOrdNum === String(userReadData.chatOrdNum) && message.unreadCount > 0) {
                        message.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const stompAdminRead = (async (chatOrdNum, mstUserNm, mstMbNum) => {
        if (!stompClient) {
            return;
        }
        // 관리자 -> 사용자 읽음전송
        if (chatStartedRef.current && chatOrdNum && mstUserNm) {
            const readChat = {
                chatOrdNum: chatOrdNum,
                mstMbNum: mstMbNum,
                mstUserNm: mstUserNm,
                readerRole: "ADMIN",
            };
            await stompClient.send("/app/chat/adminRead", {}, JSON.stringify(readChat));
        }
    });

    const sendMessage = async (printFilename, originalFilename) => {
        if (stompClient && printFilename.trim() !== '') {
            if (selectedOrderNumber === null) {
                console.log("주문서가 선택되지 않았습니다.");
                return;
            }
            const chatMessage = {
                chatKey: printFilename,
                fileNm: originalFilename,
                chatOrdNum: selectedOrderNumber,
                mbNum: adminInfo.mbNum,
                mstMbNum: mstMbNum,
                mstUserNm: mstUserNm,
                memberDTO: adminInfo,
                sendMailFl: true,
                autoChatFl: false,
            };
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));

            const newMessagePromise = new Promise((resolve) => {
                stompClient.subscribe(`/user/${mstMbNum}/queue/adminMessage`, (response) => {
                    const newMessage = JSON.parse(response.body);
                    resolve(newMessage);
                }, {id: `sub-${mstMbNum}`});
            });

            // const newMessage = await newMessagePromise;
            // await new Promise(resolve => setTimeout(resolve, 500));
            // fetchChatMessages(chatMessage.chatOrdNum);
            // updateMemberAndOrderNumber(newMessage)

            const newMessage = await newMessagePromise;
            updateMemberAndOrderNumber(newMessage)

        } else {
            console.log("메시지전송 실패");
        }
    };

    const updateMemberAndOrderNumber = ((newMessage) => {
        // new 메시지로 챗리스트 전체 업뎃
        setChatMessages(chatMessages => [...chatMessages, newMessage]);

        // 기존 멤버리스트 메시지에서 new 멤버리스트 메시지만 추가업뎃
        setMemberList(prevMembers => {
            const updatedMembers = prevMembers.map(member => {
                if (member.mstMbNum === newMessage.mstMbNum) {
                    return {
                        ...member,
                        seqnum: newMessage.seqnum,
                        unreadCount: member.unreadCount,
                        chatKey: newMessage.chatKey,
                        regTime: newMessage.regTime,
                        memberDTO: newMessage.memberDTO
                    };
                }
                return member;
            });
            return updatedMembers.sort((a, b) => b.seqnum - a.seqnum);
        });

        // 기존 오더리스트 메시지에서 new 오더리스트 메시지만 추가업뎃
        setSelectedUserOrderNumbers(prevOrderNumbers => {
            const updatedOrderNumbers = prevOrderNumbers.map(orderNumber => {
                if (orderNumber.chatOrdNum === newMessage.chatOrdNum) {
                    return {
                        ...orderNumber,
                        seqnum: newMessage.seqnum,
                        unreadCount: orderNumber.unreadCount,
                        chatKey: newMessage.chatKey
                    };
                }
                return orderNumber;
            });
            return updatedOrderNumbers.sort((a, b) => b.seqnum - a.seqnum);
        });
    })

    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const messagesEndRef = useRef(null);

    const chatScrollBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    const chatInputRef = React.useRef(null);
    const ordChatMaxTextAreaHeight = 400; // 최대 높이

    const resizeTextArea = () => {
        const textArea = chatInputRef.current;
        const chatBody = messagesEndRef.current;

        // 초기 높이 설정
        const initialChatBodyHeight = "81.5%";
        const initialTextAreaHeight = 42;

        textArea.style.height = `${initialTextAreaHeight}px`; // 초기 높이로 설정
        const newHeight = Math.min(textArea.scrollHeight, ordChatMaxTextAreaHeight); // 최대 높이 제한

        textArea.style.height = `${newHeight}px`; // 높이 설정

        // 높이 계산
        chatBody.style.height = `calc(${initialChatBodyHeight} - ${newHeight - initialTextAreaHeight}px)`;
        chatScrollBottom();
    };

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })

    const handleChatInput = (e) => {
        setMessage(e.target.value);
        resizeTextArea();
    };

    useEffect(() => {
        if (message) {
            resizeTextArea();
        }
    }, [message]);

    useEffect(() => {
        chatScrollBottom();
    }, [chatMessages]);


    const saveChatFile = async () => {
        if (!selectedFile || !selectedFile.size) {
            setShowChatFile(false);
            return;
        }
        const MAX_FILE_SIZE = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowChatFile(false);
            resetFileInput();
            setFileSizeOverSt(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios({
            method: 'post',
            url: '/order/upload',
            headers: {
                Authorization: LCAT
            },
            data: formData
        })
            .then(async (response) => {
                const fileKeys = response.data;
                await sendMessage(fileKeys.printFilename, fileKeys.originalFilename);
                setChatStarted(true);
                setShowChatFile(false);
                resetFileInput();
            })
    };

    const downloadFile = async (filename, originalFilename, type) => {
        const response = await axios({
            method: 'get',
            url: `/order/chatFileDownload/${filename}`,
            params: {
                type: type
            },
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setSelectedClickSt(false);
        openDownloadFileCompl();
    };

    const [downloadFileComplSt, setDownloadFileComplSt] = useState(false);
    const openDownloadFileCompl = (() => {
        setDownloadFileComplSt(true);
    })
    const closeDownloadFileCompl = (() => {
        setDownloadFileComplSt(false);
    })

    const showChatFileClose = (() => {
        setShowChatFile(false);
        resetFileInput();
    })

    const triggerFileInput = () => {
        fileInputRef.current.click();
        setShowChatFile(true);
    };

    const resetFileInput = () => {
        fileInputRef.current.value = "";
        setSelectedFile(null);
        setSelectedFileUrl(null);
    };

    const handleEmojiClick = (emoji) => {
        const newMessage = message + emoji.native;
        setMessage(newMessage);
        setShowEmoji(false);
    };

    const handleUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
    };

    const [chatModifySt, setChatModifySt] = useState(false)
    const [chatFileModifySt, setChatFileModifySt] = useState(false)
    const [messageToModify, setMessageToModify] = useState(null);

    const chatModifyFalseFn = (() => {
        setChatModifySt(false);
    })
    const chatFileModifyFalseFn = (() => {
        setChatFileModifySt(false);
    })

    const openModifyModal = (message) => {
        setMessageToModify(message);
        if (message.chatKey.startsWith("chatFile")) {
            setChatFileModifySt(true);
        } else {
            setChatModifySt(true);
        }
    };

    const updateChatMessage = (modifiedMessage) => {
        const index = chatMessages.findIndex(msg => msg.seqnum === modifiedMessage.seqnum);
        const updatedChatMessages = [...chatMessages];
        updatedChatMessages[index] = modifiedMessage;
        setChatMessages(updatedChatMessages);
    };

    const chatFileModify = (changeFile, changeData) => {

        if (changeData.chatKey.startsWith('chatFile')) {
            // Handle the case where changeFile is a file
            const formData = new FormData();
            formData.append('changeFile', changeFile);
            formData.append('seqnum', changeData.seqnum);
            axios({
                method: 'post',
                url: '/order/chatFileModify/',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        userMbNum: data.mstMbNum,
                        userName: data.userName,
                        keyNumber: data.chatOrdNum,
                        sendFl: true
                    };
                    await updateChatMessage(response.data)
                    await onModifyMessageUpdate(response.data)
                    await stompClient.send("/app/chat/modifyMessage", {}, JSON.stringify(userInfo));
                    setChatFileModifySt(false);
                })
        } else {
            axios({
                method: 'post',
                url: '/order/chatFileModify/',
                params: {
                    seqnum: changeData.seqnum
                },

                data: changeFile,

                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        userMbNum: data.mstMbNum,
                        userName: data.userName,
                        keyNumber: data.chatOrdNum,
                        sendFl: true
                    };
                    await updateChatMessage(response.data)
                    await onModifyMessageUpdate(response.data)
                    await stompClient.send("/app/chat/modifyMessage", {}, JSON.stringify(userInfo));
                    setChatModifySt(false);
                })
        }
    };

    const onModifyMessageUpdate = ((modifyMessage) => {
        // 멤버리스트 채팅데이터 업뎃
        setMemberList(prevMembers => prevMembers.map(member => {
            if (member.mstMbNum === modifyMessage.mstMbNum) {
                return {
                    ...member,
                    ...modifyMessage,
                };
            }
            return member;
        }));

        // 오더리스트 채팅데이터 업뎃
        setSelectedUserOrderNumbers(prevOrderNumbers => prevOrderNumbers.map(orderNumber => {
            if (orderNumber.chatOrdNum === modifyMessage.chatOrdNum) {
                return {
                    ...orderNumber,
                    ...modifyMessage,
                };
            }
            return orderNumber;
        }));
    })

    const [chatDeleteSt, setChatDeleteSt] = useState(false);
    const chatDeleteFalseFn = (() => {
        setChatDeleteSt(false);
    })
    const chatDeleteMsg1 = "해당 채팅메시지를 삭제하시겠습니까?"
    const chatDeleteMsg2 = "삭제 시 복구가 불가능합니다"

    const openDeleteModal = ((chatData) => {
        if (chatData !== null) {
            setMessageToModify(chatData);
        }
        setChatDeleteSt(true);
    })

    const deleteChat = ((deleteMessage) => {
        axios({
            method: 'post',
            url: '/order/deleteChat/',
            params: {
                seqnum: deleteMessage.seqnum
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                const userInfo = {
                    userMbNum: deleteMessage.mstMbNum,
                    userName: deleteMessage.userName,
                    keyNumber: deleteMessage.chatOrdNum,
                    sendFl: true
                };
                await setChatMessages((prevChatMessages) => prevChatMessages.filter(message => message.seqnum !== deleteMessage.seqnum));
                await selectAllChatList();
                await selectedOrderNumbers(deleteMessage.mstMbNum);
                await stompClient.send("/app/chat/deleteMessage", {}, JSON.stringify(userInfo));
                setChatDeleteSt(false);
            })
    })

    const [selectedClickFile, setSelectedClickFile] = useState(null);
    const [selectedClickSt, setSelectedClickSt] = useState(false);
    const clickFileDetail = ((file) => {
        setSelectedClickFile(file);
        setSelectedClickSt(true);
    })
    const clickFileClose = (() => {
        setSelectedClickSt(false);
    })

    //endregion

    useEffect(() => {
        const timer = setTimeout(() => {
            defaultMonth();
            // selectAllData();
            selectAllChatList();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [endDate])

    const selectAllChatList = (() => {
        axios({
            method: 'get',
            url: '/order/selectAllChatList',
            headers: {
                Authorization: LCAT
            },
            params: {
                startDate: stringStartDate,
                endDate: stringEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setMemberList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const [ordChat, setOrdChat] = useState(true);
    const [nonMember, setNonMember] = useState(false);
    const [autoRes, setAutoRes] = useState(false);
    const [macro, setMacro] = useState(false);

    const handleTab = ((state) => {
        if (state === 'ordChat') {
            setOrdChat(true);
            setNonMember(false);
            setChatStarted(false);
            setNonChatShow(false);
        } else if (state === 'nonMember') {
            setOrdChat(false);
            setNonMember(true);
            setChatStarted(false);
            setNonChatShow(false);
        }
    })

    // 비회원채팅 시작
    const [selectNonMemberDate, setSelectNonMemberDate] = useState("1개월");

    const [showNonEmoji, setShowNonEmoji] = useState(false);
    const [nonMessage, setNonMessage] = useState('');
    const [nonChatShow, setNonChatShow] = useState(false);
    const [nonMemberList, setNonMemberList] = useState([]);
    const [nonChatMessages, setNonChatMessages] = useState([]);
    const [nonSelectedFileUrl, setNonSelectedFileUrl] = useState(null);
    const [nonSelectedFile, setNonSelectedFile] = useState(null);
    const [nonShowChatFile, setNonShowChatFile] = useState(false);

    const [nonSelectedMacroMstIndex, setNonSelectedMacroMstIndex] = useState(null);

    const nonFileInputRef = useRef();

    const [sendMsgNum, setSendMsgNum] = useState('');

    const selectNonMemberMsg = (() => {
        axios({
            method: 'get',
            url: '/member/selectNonMemberMsg',
            headers: {
                Authorization: LCAT
            },
            params: {
                dateParam: selectNonMemberDate
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setNonMemberList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const selectNonMacroList = () => axios({
        method: 'get',
        url: '/member/selectMacroList/',
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setMacroMstList(response.data)
        })

    useEffect(() => {
        if (nonMember || ordChat) {
            selectNonMacroList();
        }
    }, [nonMember, ordChat]);

    const fetchNonMacroDtList = ((index) => {
        if (nonSelectedMacroMstIndex === index) {
            setNonSelectedMacroMstIndex(null);
            setMacroDtList([]);
        } else {
            setNonSelectedMacroMstIndex(index);
            setMacroDtList(macroMstList[index].macroDt);
        }
    })

    const sendNonMacroMessage = (content) => {
        if (nonChatShow) {
            setNonMessage(content)
            setIsMacroPopupVisible(false);
        }
    }

    const sendOrdMacroMessage = (content) => {
        if (chatStarted) {
            setMessage(content)
            setIsMacroPopupVisible(false);
        }
    }

    // 관리자 비회원채팅 응대
    const nonSelectedOrderNumberRef = useRef(sendMsgNum);
    const nonChatShowRef = useRef(nonChatShow);
    const nonMemberListRef = useRef(nonMemberList);
    const nonChatMessagesRef = useRef(nonChatMessages);

    useEffect(() => {
        nonSelectedOrderNumberRef.current = sendMsgNum;
        nonChatShowRef.current = nonChatShow;
        nonMemberListRef.current = nonMemberList
        nonChatMessagesRef.current = nonChatMessages
    }, [sendMsgNum, nonChatShow, nonMemberListRef, nonChatMessages]);

    const nonOnMessageReceived = (payload) => {

        console.log("비회원 수신바듬@@")
        let newMessage = JSON.parse(payload.body);

        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }

        // 새로 등록된 메시지의 msgNum과 관리자가 선택한 chatDt.msgNum이 동일 && nonChatShow가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.msgNum === nonSelectedOrderNumberRef.current && nonChatShowRef.current) {
            handleNonChatMessages(newMessage);
            setNonChatMessages(chatMessages => {
                const newChatMessages = [...chatMessages, newMessage];
                newChatMessages.sort((a, b) => a.seqnum - b.seqnum);
                return newChatMessages;
            })

            // 현재 선택된 좌측 nonMemberList 채팅메시지를 newMessage로 업데이트
            setNonMemberList(currentList => {
                const matchingIndex = currentList.findIndex(item => item.msgNum === newMessage.msgNum);
                if (matchingIndex !== -1) {
                    const updatedMessage = {...newMessage, unreadCount: 0};
                    const updatedList = [...currentList];
                    updatedList[matchingIndex] = updatedMessage;
                    return updatedList;
                }
                return currentList;
            });
        } else {
            // 현재 선택되지 않은 좌측 nonMemberList 채팅메시지를 newMessage로 업데이트
            setNonMemberList(currentList => {
                const matchingIndex = currentList.findIndex(item => item.msgNum === newMessage.msgNum);
                if (matchingIndex !== -1) {
                    const updatedMessage = {...newMessage, unreadCount: newMessage.unreadCount};
                    const updatedList = [...currentList];
                    updatedList[matchingIndex] = updatedMessage;
                    return updatedList;
                } else {
                    selectNonMemberMsg();
                    return currentList;
                }
            });
        }
    }

    useEffect(() => {
        selectNonMemberMsg();
    }, [selectNonMemberDate])

    const nonDownloadFile = async (filename, originalFilename) => {
        const response = await axios({
            method: 'get',
            url: `/member/chatFileDownload/${filename}`,
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setNonSelectedClickSt(false);
        openDownloadFileCompl();
    };

    const handleNonChatMessages = async (chat) => {
        setSendMsgNum(chat.msgNum)
        setNonChatShow(true);
        if (chat.unreadCount > 0 && chat.role === "1") {
            await axios({
                method: 'post',
                url: `/member/chat/unread/`,
                params: {
                    msgNum: chat.msgNum,
                },
                headers: {
                    Authorization: LCAT
                }
            })
                .then(async () => {
                    await stompClient.send("/app/chat/totalCount", {}, JSON.stringify({sendFl: true}));
                    setNonMemberList(prevNonMemberList => {
                        return prevNonMemberList.map(item => {
                            if (chat.msgNum === item.msgNum) {
                                nonStompAdminRead(chat.msgNum);
                                return {...item, unreadCount: 0};
                            }
                            return item;
                        });
                    });
                })
                .catch(error => console.error(error));
        }
    }

    const nonFetchChatMessages = async (msgNum, sendFl) => {
        if (!msgNum) {
            return;
        }
        try {
            const response = await axios({
                method: 'get',
                url: '/member/chatFetch',
                params: {
                    msgNum: msgNum
                }
            });
            if (!response || response.data === false) {
                console.log('데이터없음');
                return;
            }
            const data = response.data;
            // data.sort((a, b) => b.seqnum - a.seqnum);

            for (let nonMessage of data) {
                if (nonMessage.chatKey.startsWith('chatFile')) {
                    const filename = nonMessage.chatKey.split('/').pop();
                    if (isVideo(filename)) {
                        nonMessage.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/" + nonMessage.chatKey;
                        nonMessage.isVideo = true;
                    } else if (isImage(filename)) {
                        nonMessage.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/" + nonMessage.chatKey;
                        nonMessage.isImage = true;
                    } else {
                        nonMessage.fileUrl = "file";
                    }
                }
            }

            // 현재 선택된 좌측 nonMemberList 채팅메시지를 newMessage로 업데이트
            setNonMemberList(currentList => {
                const matchingIndex = currentList.findIndex(item => item.msgNum === msgNum);
                if (matchingIndex !== -1) {
                    const updatedMessage = {...data[data.length - 1], unreadCount: 0};
                    const updatedList = [...currentList];
                    updatedList[matchingIndex] = updatedMessage;
                    updatedList.sort((a, b) => b.seqnum - a.seqnum);
                    return updatedList;
                }
                return currentList;
            });

            setNonChatMessages(data);
            return data;
        } catch (error) {
            console.log("채팅페치오류 : ", error)
        }
    }

    useEffect(() => {
        if (sendMsgNum) {
            nonFetchChatMessages(sendMsgNum && sendMsgNum)
                .then(data => setNonChatMessages(data))
                .catch(err => console.error(err));
        }
    }, [sendMsgNum]);

    // 사용자 -> 관리자 읽음수신 코드
    const nonOnReadMessageReceived = (payload) => {
        // 사용자가 읽었을 경우 관리자채팅 안읽은메시지 수 '0'으로 초기화
        let userReadData = JSON.parse(payload.body);
        if (userReadData && userReadData.readerRole === "USER") {
            setNonChatMessages(currentChatMessages => {
                const updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let nonMessage of updatedMessages) {
                    if (nonMessage.msgNum === String(userReadData.msgNum) && nonMessage.unreadCount > 0) {
                        nonMessage.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const nonSendMessage = async (printFilename, originalFilename, msgNum) => {
        if (stompClient && printFilename.trim() !== '') {
            const nonChatMessage = {
                chatKey: printFilename,
                fileNm: originalFilename,
                msgNum: msgNum,
                role: '0',
                autoFl: false
            };
            await stompClient.send("/app/nonUserChat/adminMessage", {}, JSON.stringify(nonChatMessage));
            await new Promise(resolve => setTimeout(resolve, 500));
            nonFetchChatMessages(msgNum, true);
        } else {
            console.log("메시지 전송실패")
        }
    };

    // 기한초과된 모든 비회원 채팅메세지 삭제
    const [nUserRemoveSt, setNUserRemoveSt] = useState(false);
    const nUserRemoveMsgTitle = "기한 초과된 모든 메세지를 삭제하시겠습니까?";
    const nUserRemoveMsgContent = "삭제 후 복구가 불가합니다";
    const nUserAllChatRemove = async () => {
        axios({
            method: 'post',
            url: '/member/nUserAllChatRemove',
        })
            .then(() => {
                // 비회원채팅 리렌더링
                selectNonMemberMsg();
                closeNUserRemoveSt();
                setNonChatShow(false);
            })
    };
    const closeNUserRemoveSt = () => {
        setNUserRemoveSt(false);
    }

    // 관리자 -> 사용자 읽음 전송
    const nonStompAdminRead = async (msgNum) => {
        if (stompClient) {
            const readChat = {
                msgNum: msgNum,
                readerRole: "ADMIN",
            };
            await stompClient.send("/app/nonUserChat/adminRead", {}, JSON.stringify(readChat));
        } else {
        }
    };

    const nonMessagesEndRef = useRef(null);

    const nonChatScrollBottom = () => {
        if (nonMessagesEndRef.current) {
            nonMessagesEndRef.current.scrollTo({
                top: nonMessagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    useEffect(nonChatScrollBottom, [nonChatMessages]);

    const nonChatInputRef = React.useRef(null);
    const nonChatMaxTextAreaHeight = 400; // 최대 높이

    const nonResizeTextArea = () => {
        const textArea = nonChatInputRef.current;
        const chatBody = nonMessagesEndRef.current;

        // 초기 높이 설정
        const initialChatBodyHeight = "81.5%";
        const initialTextAreaHeight = 42;

        textArea.style.height = `${initialTextAreaHeight}px`; // 초기 높이로 설정
        const newHeight = Math.min(textArea.scrollHeight, nonChatMaxTextAreaHeight); // 최대 높이 제한

        textArea.style.height = `${newHeight}px`; // 높이 설정
        // 높이 계산
        chatBody.style.height = `calc(${initialChatBodyHeight} - ${newHeight - initialTextAreaHeight}px)`;
    };

    const nonHandleChatInput = (e) => {
        setNonMessage(e.target.value);
        nonResizeTextArea();
    };

    useEffect(() => {
        if (nonMessage) {
            nonResizeTextArea();
        }
    }, [nonMessage]);

    const saveNonChatFile = async () => {
        if (!nonSelectedFile) {
            return;
        }
        const formData = new FormData();
        formData.append('file', nonSelectedFile);
        const response = await axios.post('/member/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (!response.ok) {
            return;
        }
        const fileKeys = await response.json();
        await nonSendMessage(fileKeys.printFilename, fileKeys.originalFilename, sendMsgNum);
        setNonShowChatFile(false);
    };

    const nonTriggerFileInput = () => {
        nonFileInputRef.current.click();
        setNonShowChatFile(true);
    };

    const nonShowChatFileClose = (() => {
        nonResetFileInput();
        setNonShowChatFile(false);
    })

    const nonResetFileInput = () => {
        nonFileInputRef.current.value = "";
        setNonSelectedFile(null);
        setNonSelectedFileUrl(null);
    };

    const nonHandleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setNonSelectedFile(file);
            setNonSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const nonHandleEmojiClick = (emoji) => {
        const newMessage = nonMessage + emoji.native;
        setNonMessage(newMessage);
        setShowNonEmoji(false);
    };

    const [nonChatModifySt, setNonChatModifySt] = useState(false)
    const [nonChatFileModifySt, setNonChatFileModifySt] = useState(false)
    const [nonMessageToModify, setNonMessageToModify] = useState(null);

    const nonChatModifyFalseFn = (() => {
        setNonChatModifySt(false);
    })
    const nonChatFileModifyFalseFn = (() => {
        setNonChatFileModifySt(false);
    })

    const openNonModifyModal = (message) => {
        setNonMessageToModify(message);
        if (message.chatKey.startsWith("chatFile")) {
            setNonChatFileModifySt(true);
        } else {
            setNonChatModifySt(true);
        }
    };

    const updateNonChatMessage = (modifiedMessage) => {
        const index = nonChatMessages.findIndex(msg => msg.seqnum === modifiedMessage.seqnum);
        const updatedChatMessages = [...nonChatMessages];
        updatedChatMessages[index] = modifiedMessage;
        setNonChatMessages(updatedChatMessages);
    };

    const nonChatFileModify = (changeFile, changeData) => {

        if (changeData.chatKey.startsWith('chatFile')) {
            // Handle the case where changeFile is a file
            const formData = new FormData();
            formData.append('changeFile', changeFile);
            formData.append('seqnum', changeData.seqnum);
            axios({
                method: 'post',
                url: '/member/chatFileModify/',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        keyNumber: data.msgNum,
                        sendFl: true
                    };
                    await updateNonChatMessage(response.data)
                    await stompClient.send("/app/nonUserChat/modifyMessage", {}, JSON.stringify(userInfo));
                    setNonChatFileModifySt(false);
                })
        } else {
            axios({
                method: 'post',
                url: '/member/chatFileModify/',
                params: {
                    seqnum: changeData.seqnum
                },

                data: changeFile,

                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        keyNumber: data.msgNum,
                        sendFl: true
                    };
                    await updateNonChatMessage(response.data)
                    await stompClient.send("/app/nonUserChat/modifyMessage", {}, JSON.stringify(userInfo));
                    setNonChatModifySt(false);
                })
        }
    };

    const [nonChatDeleteSt, setNonChatDeleteSt] = useState(false);
    const nonChatDeleteFalseFn = (() => {
        setNonChatDeleteSt(false);
    })

    const openNonDeleteModal = ((chatData) => {
        if (chatData !== null) {
            setNonMessageToModify(chatData);
        }
        setNonChatDeleteSt(true);
    })

    const deleteNonChat = ((deleteChat) => {
        axios({
            method: 'post',
            url: '/member/deleteChat/',
            params: {
                seqnum: deleteChat.seqnum
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                const userInfo = {
                    keyNumber: deleteChat.msgNum,
                    sendFl: true
                };
                await setNonChatMessages((prevChatMessages) => prevChatMessages.filter(message => message.seqnum !== deleteChat.seqnum));
                await selectNonMemberMsg();
                await stompClient.send("/app/nonUserChat/deleteMessage", {}, JSON.stringify(userInfo));
                nonChatDeleteFalseFn();
            })
    })

    const [nonSelectedClickFile, setNonSelectedClickFile] = useState(null);
    const [nonSelectedClickSt, setNonSelectedClickSt] = useState(false);

    const nonClickFileDetail = ((file) => {
        setNonSelectedClickFile(file);
        setNonSelectedClickSt(true);
    })
    const nonClickFileClose = (() => {
        setNonSelectedClickSt(false);
    })

    const convertToNonMemberDate = ((regDate) => {
        const regTime = new Date(regDate);
        const currentTime = new Date();
        const timeDifference = currentTime - regTime;

        // 날짜 차이를 분으로 변환
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        // 날짜 차이가 1일 미만일 때
        if (daysDiff < 1) {
            if (minutesDiff < 1) return "방금";
            if (minutesDiff < 60) return `${minutesDiff}분 전`;
            return `${hoursDiff}시간 전`;
        } else if (daysDiff === 1) {
            return "어제";
        } else if (daysDiff === 2) {
            return "2일 전";
        } else if (daysDiff < 7) {
            return `${daysDiff}일 전`;
        } else {
            // 7일 이상 차이날 때는 월-일 형식으로 반환
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    })

    // 자동응답시작
    useEffect(() => {
        if (autoRes) {
            selectAutoResList();
        }
    }, [autoRes])


    const selectAutoResList = () => axios({
        method: 'get',
        url: '/member/selectAutoResList/',
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
        })


    // 매크로시작

    useEffect(() => {
        if (macro) {
            selectMacroList();
        }
    }, [macro])

    const [macroMstList, setMacroMstList] = useState([]);
    const [macroDtList, setMacroDtList] = useState([]);

    const selectMacroList = () => axios({
        method: 'get',
        url: '/member/selectMacroList/',
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setMacroMstList(response.data)
        })


    const [isMacroPopupVisible, setIsMacroPopupVisible] = useState(false);
    const toggleMacroPopup = () => {
        setIsMacroPopupVisible(true);
    };

    return (
        <div id='m-a-order-chat-pg-container'>
            {chatStarted ?
                <div className="font-t-cont">
                    <div className="m-a-chatMsg-container">
                        <div className="m-a-chat-blank-space"></div>
                        <div className="m-a-chat-header-container">
                            <div className="m-a-chat-header-frame">
                                <img className="m-a-chat-header-img" src={mainChatPeople} alt="consultant"/>
                                <div className="m-a-chat-header-text font-t-title font-s-16">주문서채팅</div>
                            </div>
                            <img onClick={() => setChatStarted(false)} className="m-a-chat-header-close-img" src={commonClose} alt="close"/>
                        </div>
                        <div className="m-a-chat-body-container" ref={messagesEndRef}>
                            {chatMessages && chatMessages.map((message, index) => (
                                <div key={index}>
                                    {(isFirstMessageOfDay(message, chatMessages[index - 1]) &&
                                        <div className="m-a-chat-first-time-frame">
                                            <div className="m-a-chat-first-time font-t-cont">{formatDate(new Date(message.regTime))}</div>
                                        </div>
                                    )}
                                    {message.memberDTO && message.memberDTO.role === '0' ?
                                        <div className="m-a-chat-userProfile-frame1" style={{
                                            display: "flex",
                                            justifyContent: message.memberDTO && message.memberDTO.role === '0' ? 'flex-start' : 'flex-end'
                                        }}>
                                            <div className="m-a-chat-userProfile-frame2">
                                                <div className="wd-50 ht-50" style={{borderRadius: "50%"}}>
                                                    {message.memberDTO.profileDTO ?
                                                        <img className="display-block wd-100pc ht-100pc" style={{borderRadius: "50%", objectFit: "cover"}} alt="profile-img"
                                                             src={
                                                                 "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + message.memberDTO.profileDTO.filename
                                                             }/>
                                                        :
                                                        <img className="display-block wd-100pc ht-100pc" style={{borderRadius: "50%", objectFit: "cover"}} src={profileDefaultImg} alt="profile-img"/>
                                                    }
                                                </div>
                                                <div className="m-a-chat-nameCont-frame">
                                                    <div className="text-left font-s-15 font-t-title">{message.mstUserNm}</div>
                                                    <div className="m-a-chat-content-time-frame">
                                                        {message.chatKey.startsWith("chatFile") ?
                                                            <div onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                className="m-a-chatKey-file-container">
                                                                {/*사용자이미지*/}
                                                                {isImage(message.chatKey) ?
                                                                    <div className="m-a-chatKey-img-frame">
                                                                        {!message.removeFl ?
                                                                            <img className="m-a-chatKey-img" alt="chatImg" src={message.fileUrl}/>
                                                                            :
                                                                            <div className="m-a-chatKey-file-expiration-img-container">
                                                                                <img className="m-a-chatKey-file-expiration-img" alt="expiration" src={trash}/>
                                                                                <div className="m-a-chatKey-file-expiration-text">(기한만료)</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    // 사용자동영상
                                                                    isVideo(message.chatKey) ?
                                                                        <div className="m-a-chatKey-video-frame"
                                                                             style={{pointerEvents: "none"}}>
                                                                            {!message.removeFl ?
                                                                                <video width="250" height="250"
                                                                                       controls>
                                                                                    <source src={message.fileUrl}
                                                                                            type="video/mp4"
                                                                                            style={{pointerEvents: "auto"}}/>
                                                                                </video>
                                                                                :
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-img-container">
                                                                                    <img
                                                                                        className="m-a-chatKey-file-expiration-img"
                                                                                        alt="expiration" src={trash}/>
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        // 사용자파일
                                                                        <div className="m-a-chatKey-file-frame1">
                                                                            {!message.removeFl ?
                                                                                <div
                                                                                    className="m-a-chatKey-file-frame2">
                                                                                    <div
                                                                                        className="m-a-chatKey-file-img-frame user-center">
                                                                                        <img
                                                                                            className="m-a-chatKey-file-img"
                                                                                            alt="fileImg"
                                                                                            src={chatFileImg}/>
                                                                                    </div>
                                                                                    <div
                                                                                        className="m-a-chatKey-fileNm-text-frame">
                                                                                        <span
                                                                                            className="m-a-chatKey-fileNm-text font-t-title">{message.fileNm}</span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="m-a-chatKey-expiration-date font-t-cont"> ~ {oneMonthFromNow(message.deadline)}까지
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-img-container">
                                                                                    <img
                                                                                        className="m-a-chatKey-file-expiration-img"
                                                                                        alt="expiration" src={trash}/>
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            // 사용자텍스트
                                                            <div className="m-a-chat-msg-content-frame">
                                                                <Linkify
                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                        <a style={{color: "orangered"}} target="blank"
                                                                           href={decoratedHref} key={key}>
                                                                            {decoratedText}
                                                                        </a>
                                                                    )}
                                                                >
                                                                    <div
                                                                        className="m-a-chat-msg-content font-t-cont font-s-14">{DOMPurify.sanitize(message.chatKey)}
                                                                    </div>
                                                                </Linkify>
                                                            </div>
                                                        }
                                                        <div className="m-a-chat-msg-time-frame">
                                                            {!message.removeFl ?
                                                                <div
                                                                    className="m-a-chat-msg-time font-t-cont font-s-11">{formatTime(message.regTime)}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        // 주문서 관리자채팅
                                        <div className="m-a-chat-adminProfile-frame1">
                                            <div className="m-a-chat-adminProfile-frame2"
                                                 style={{
                                                     display: "flex",
                                                     justifyContent: message.memberDTO && message.memberDTO.role === '0' ? 'flex-start' : 'flex-end'
                                                 }}>
                                                {!message.removeFl ?
                                                    <div className="m-a-chat-edit-remove-time-frame wd-30pc">
                                                        <div className="m-a-chat-edit-remove-frame gap7">
                                                            {message && message.unreadCount > 0 ?
                                                                <div className="m-a-chat-admin-unreadCount-frame user-center font-s-13">
                                                                    <div className="m-a-chat-admin-unreadCount"></div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div onClick={() => openModifyModal(message)} className="m-a-chat-edit font-s-12">수정</div>
                                                            <div onClick={() => openDeleteModal(message)} className="m-a-chat-remove font-s-12">삭제</div>
                                                        </div>
                                                        {!message.removeFl ?
                                                            <div className="m-a-chat-msg-time font-t-cont font-s-11 m-l-15">{formatTime(message.regTime)}</div> : null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {/*관리자 챗메시지 프레임*/}
                                                <div>
                                                    {message.chatKey.startsWith("chatFile") ?
                                                        <div onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                            className="m-a-chatKey-file-container m-l-5">
                                                            {/*관리자이미지*/}
                                                            {isImage(message.chatKey) ?
                                                                <div className="m-a-chatKey-img-frame">
                                                                    {!message.removeFl ?
                                                                        <img className="m-a-chatKey-img"
                                                                             alt="chatImg" src={message.fileUrl}/>
                                                                        :
                                                                        <div className="m-a-chatKey-file-expiration-img-container" style={{textAlign: "center"}}>
                                                                            <img className="m-a-chatKey-file-expiration-img" alt="expiration" src={trash}/>
                                                                            <div className="m-a-chatKey-file-expiration-text">(기한만료)</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                // 관리자동영상
                                                                isVideo(message.chatKey) ?
                                                                    <div className="m-a-chatKey-video-frame"
                                                                         style={{pointerEvents: "none"}}>
                                                                        {!message.removeFl ?
                                                                            <video width="220" height="220" controls>
                                                                                <source src={message.fileUrl}
                                                                                        type="video/mp4"
                                                                                        style={{pointerEvents: "auto"}}/>
                                                                            </video>
                                                                            :
                                                                            <div
                                                                                className="m-a-chatKey-file-expiration-img-container">
                                                                                <img
                                                                                    className="m-a-chatKey-file-expiration-img"
                                                                                    alt="expiration" src={trash}/>
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    // 관리자파일
                                                                    <div className="m-a-chatKey-file-frame1">
                                                                        {!message.removeFl ?
                                                                            <div
                                                                                className="m-a-chatKey-file-frame2">
                                                                                <div
                                                                                    className="m-a-chatKey-file-img-frame user-center">
                                                                                    <img
                                                                                        className="m-a-chatKey-file-img"
                                                                                        alt="fileImg"
                                                                                        src={chatFileImg}/>
                                                                                </div>
                                                                                <div
                                                                                    className="m-a-chatKey-fileNm-text-frame">
                                                                                        <span
                                                                                            className="m-a-chatKey-fileNm-text font-t-title">{message.fileNm}</span>
                                                                                </div>
                                                                                <div
                                                                                    className="m-a-chatKey-expiration-date font-t-cont"> ~ {oneMonthFromNow(message.deadline)}까지
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div
                                                                                className="m-a-chatKey-file-expiration-img-container">
                                                                                <img
                                                                                    className="m-a-chatKey-file-expiration-img"
                                                                                    alt="expiration" src={trash}/>
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                            }ㄴ
                                                        </div>
                                                        :
                                                        // 관리자텍스트
                                                        <div className="m-a-chat-msg-admin-content-frame">
                                                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                    <a className="font-c-btorage" target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
                                                                )}
                                                            >
                                                                <div className="m-a-chat-msg-admin-content font-t-cont font-s-14">{DOMPurify.sanitize(message.chatKey)}</div>
                                                            </Linkify>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="m-a-chat-msgInput-container">
                            {/*주문서 채팅인풋 프레임*/}
                            <div className="m-a-chat-send-frame">
                                {/*emoji & upload & remove*/}
                                <div className="m-a-chat-emoji-photo-frame m-l-10">
                                    <div onClick={() => setShowEmoji(!showEmoji)}
                                         className="m-a-chat-show-emoji">
                                        <img alt='' className="m-a-chat-show-emoji-icon"
                                             src={mainChatEmoji}/>
                                    </div>
                                    {/*이모티콘*/}
                                    {showEmoji ?
                                        <div className="m-a-chat-show-emoji-popup-frame1">
                                            <div className="m-a-chat-show-emoji-popup-frame2">
                                                <Picker
                                                    onEmojiSelect={handleEmojiClick}
                                                    previewPosition="none"
                                                    searchPosition="none"
                                                    skinTonePosition="none"
                                                    locale="kr"
                                                />
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/*파일업로드*/}
                                    <div className="m-a-chat-fileUpload-frame">
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{display: "none"}}
                                            onChange={handleFileUpload}
                                        />
                                        <button className="m-a-chat-fileUpload-button"
                                                onClick={triggerFileInput}>
                                            <img alt='fileUploadImg' className="wd-25 ht-25"
                                                 src={mainChatUpload}/>
                                        </button>
                                    </div>
                                    {/*주문서 관리자매크로*/}
                                    <div className="m-a-chat-fileUpload-frame" style={{marginLeft: "-3px"}}>
                                        <button onClick={toggleMacroPopup} className="m-a-chat-fileUpload-button"
                                        >
                                            <img alt='' className="wd-25 ht-25 m-a-opc-06"
                                                 src={commonMacro}/>
                                        </button>
                                    </div>
                                </div>
                                {/*채팅 textarea 프레임*/}
                                <div className="m-a-chat-textarea-frame">
                                    {/*채팅 textarea*/}
                                    <textarea ref={chatInputRef} placeholder=""
                                              spellCheck="false" value={message}
                                              className="m-a-textarea font-s-14 m-a-chat-textarea wd-100pc"
                                              onChange={handleChatInput}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter' && !e.shiftKey) {
                                                      e.preventDefault();
                                                      sendMessage(message); // 추후 msgNum -> 변경
                                                      setMessage('');
                                                      chatInputRef.current.style.height = '42px';
                                                  }
                                              }}
                                    />
                                </div>
                                <div onClick={() => {
                                    sendMessage(message, null, sendMsgNum); // 추후 msgNum -> 변경
                                    setMessage('');
                                }} className="m-a-chat-send-bt-frame">
                                    <img alt='' className="m-a-chat-send-bt-img"
                                         style={{opacity: message.length > 0 ? "1" : "0.6"}}
                                         src={mainChatSend}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                // 비회원채팅 오픈
                nonChatShow ?
                    <div className="">
                        <div className="m-a-chatMsg-container">
                            <div className="m-a-chat-blank-space"></div>
                            {/*비회원 채팅헤더*/}
                            <div className="m-a-chat-header-container">
                                <div className="m-a-chat-header-frame">
                                    <img className="m-a-chat-header-img" src={mainChatPeople} alt="consultant"/>
                                    <div className="m-a-chat-header-text font-t-title font-s-16">비회원채팅</div>
                                </div>
                                <img onClick={() => setNonChatShow(false)} className="m-a-chat-header-close-img"
                                     src={commonClose} alt="close"/>
                            </div>
                            <div className="m-a-chat-body-container" ref={nonMessagesEndRef}>
                                {/*비회원 채팅바디*/}
                                {nonChatMessages.map((message, index) => (
                                    <div key={index}>
                                        {(isFirstMessageOfDay(message, nonChatMessages[index - 1]) &&
                                            <div className="m-a-chat-first-time-frame">
                                                <div
                                                    className="m-a-chat-first-time font-t-cont">{formatDate(new Date(message.regTime))}</div>
                                            </div>
                                        )}
                                        {message && message.role === '1' ?
                                            // 비회원 사용자프로필
                                            <div className="m-a-chat-userProfile-frame1" style={{
                                                display: "flex",
                                                justifyContent: message && message.role === '1' ? "flex-start" : "flex-end"
                                            }}>
                                                <div className="m-a-chat-userProfile-frame2">
                                                    <div className="m-a-chat-userProfile wd-50 ht-50">
                                                        <img className="wd-100pc ht-100pc" style={{objectFit: "cover"}} src={profileDefaultImg} alt="profile-img"/>
                                                    </div>
                                                    <div className="m-a-chat-nameCont-frame">
                                                        <div
                                                            className="m-a-chat-userNm font-s-15 font-t-title">{message.mstUserNm}</div>
                                                        <div className="m-a-chat-content-time-frame">
                                                            {/*비회원 챗메시지 프레임*/}
                                                            {message.chatKey.startsWith("chatFile") ?
                                                                <div
                                                                    onClick={!message.removeFl ? () => nonClickFileDetail(message) : null}
                                                                    className="m-a-chatKey-file-container">
                                                                    {/*비회원 사용자이미지*/}
                                                                    {isImage(message.chatKey) ?
                                                                        <div className="m-a-chatKey-img-frame">
                                                                            {!message.removeFl ?
                                                                                <img className="m-a-chatKey-img"
                                                                                     alt="chatImg"
                                                                                     src={message.fileUrl}/>
                                                                                :
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-img-container">
                                                                                    <img
                                                                                        className="m-a-chatKey-file-expiration-img"
                                                                                        alt="expiration" src={trash}/>
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        // 비회원 사용자동영상
                                                                        isVideo(message.chatKey) ?
                                                                            <div className="m-a-chatKey-video-frame"
                                                                                 style={{pointerEvents: "none"}}>
                                                                                {!message.removeFl ?
                                                                                    <video width="250" height="250"
                                                                                           controls>
                                                                                        <source src={message.fileUrl}
                                                                                                type="video/mp4"
                                                                                                style={{pointerEvents: "auto"}}/>
                                                                                    </video>
                                                                                    :
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-img-container">
                                                                                        <img
                                                                                            className="m-a-chatKey-file-expiration-img"
                                                                                            alt="expiration"
                                                                                            src={trash}/>
                                                                                        <div
                                                                                            className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            // 비회원 사용자파일
                                                                            <div className="m-a-chatKey-file-frame1">
                                                                                {!message.removeFl ?
                                                                                    <div
                                                                                        className="m-a-chatKey-file-frame2">
                                                                                        <div
                                                                                            className="m-a-chatKey-file-img-frame user-center">
                                                                                            <img
                                                                                                className="m-a-chatKey-file-img"
                                                                                                alt="fileImg"
                                                                                                src={chatFileImg}/>
                                                                                        </div>
                                                                                        <div
                                                                                            className="m-a-chatKey-fileNm-text-frame">
                                                                                            <span
                                                                                                className="m-a-chatKey-fileNm-text font-t-title">{message.fileNm}</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="m-a-chatKey-expiration-date font-t-cont"> ~ {oneMonthFromNow(message.deadline)}까지
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-img-container">
                                                                                        <img
                                                                                            className="m-a-chatKey-file-expiration-img"
                                                                                            alt="expiration"
                                                                                            src={trash}/>
                                                                                        <div
                                                                                            className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </div>
                                                                :
                                                                // 비회원 사용자텍스트
                                                                <div className="m-a-chat-msg-content-frame">
                                                                    <Linkify
                                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                            <a style={{color: "orangered"}}
                                                                               target="blank"
                                                                               href={decoratedHref} key={key}>
                                                                                {decoratedText}
                                                                            </a>
                                                                        )}
                                                                    >
                                                                        <div
                                                                            className="m-a-chat-msg-content font-t-cont font-s-14">{DOMPurify.sanitize(message.chatKey)}
                                                                        </div>
                                                                    </Linkify>
                                                                </div>
                                                            }
                                                            <div className="m-a-chat-msg-time-frame">
                                                                {!message.removeFl ?
                                                                    <div
                                                                        className="m-a-chat-msg-time font-t-cont font-s-11">{formatTime(message.regTime)}
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            // 비회원 관리자채팅
                                            <div className="m-a-chat-adminProfile-frame1">
                                                <div className="m-a-chat-adminProfile-frame2"
                                                     style={{
                                                         display: "flex",
                                                         justifyContent: message.memberDTO && message.memberDTO.role === '0' ? 'flex-start' : 'flex-end'
                                                     }}>
                                                    {!message.removeFl && !message.autoFl ?
                                                        <div className="m-a-chat-edit-remove-time-frame">
                                                            <div
                                                                className="m-a-chat-edit-remove-frame">
                                                                {message && message.unreadCount > 0 ?
                                                                    <div
                                                                        className="m-a-chat-admin-unreadCount-frame user-center font-s-13">
                                                                        <div
                                                                            className="m-a-chat-admin-unreadCount">1
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                <div
                                                                    onClick={() => openNonModifyModal(message)}
                                                                    className="m-a-chat-edit font-t-cont font-s-13 m-l-5">수정
                                                                </div>
                                                                <div
                                                                    onClick={() => openNonDeleteModal(message)}
                                                                    className="m-a-chat-remove font-t-cont font-s-13 m-l-5">삭제
                                                                </div>
                                                            </div>
                                                            {!message.removeFl ?
                                                                <div
                                                                    className="m-a-chat-msg-time font-t-cont font-s-11 m-l-10">{formatTime(message.regTime)}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {/*비회원 관리자 챗메시지 프레임*/}
                                                    <div>
                                                        {message.chatKey.startsWith("chatFile") ?
                                                            <div
                                                                onClick={!message.removeFl ? () => nonClickFileDetail(message) : null}
                                                                className="m-a-chatKey-file-container m-l-5">
                                                                {/*관리자이미지*/}
                                                                {isImage(message.chatKey) ?
                                                                    <div className="m-a-chatKey-img-frame">
                                                                        {!message.removeFl ?
                                                                            <img className="m-a-chatKey-img"
                                                                                 alt="chatImg" src={message.fileUrl}/>
                                                                            :
                                                                            <div
                                                                                className="m-a-chatKey-file-expiration-img-container"
                                                                                style={{textAlign: "center"}}>
                                                                                <img
                                                                                    className="m-a-chatKey-file-expiration-img"
                                                                                    alt="expiration" src={trash}/>
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    // 비회원 관리자동영상
                                                                    isVideo(message.chatKey) ?
                                                                        <div className="m-a-chatKey-video-frame">
                                                                            {!message.removeFl ?
                                                                                <video width="220" height="220"
                                                                                       controls>
                                                                                    <source src={message.fileUrl}
                                                                                            type="video/mp4"/>
                                                                                </video>
                                                                                :
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-img-container">
                                                                                    <img
                                                                                        className="m-a-chatKey-file-expiration-img"
                                                                                        alt="expiration" src={trash}/>
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        // 비회원 관리자파일
                                                                        <div className="m-a-chatKey-file-frame1">
                                                                            {!message.removeFl ?
                                                                                <div
                                                                                    className="m-a-chatKey-file-frame2">
                                                                                    <div
                                                                                        className="m-a-chatKey-file-img-frame user-center">
                                                                                        <img
                                                                                            className="m-a-chatKey-file-img"
                                                                                            alt="fileImg"
                                                                                            src={chatFileImg}/>
                                                                                    </div>
                                                                                    <div
                                                                                        className="m-a-chatKey-fileNm-text-frame">
                                                                                        <span
                                                                                            className="m-a-chatKey-fileNm-text font-t-title">{message.fileNm}</span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="m-a-chatKey-expiration-date font-t-cont"> ~ {oneMonthFromNow(message.deadline)}까지
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div
                                                                                    className="m-a-chatKey-file-expiration-img-container">
                                                                                    <img
                                                                                        className="m-a-chatKey-file-expiration-img"
                                                                                        alt="expiration" src={trash}/>
                                                                                    <div
                                                                                        className="m-a-chatKey-file-expiration-text">(기한만료)
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            // 비회원 관리자텍스트
                                                            <div className="m-a-chat-msg-admin-content-frame">
                                                                <Linkify
                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                        <a style={{color: "orangered"}} target="blank"
                                                                           href={decoratedHref} key={key}>
                                                                            {decoratedText}
                                                                        </a>
                                                                    )}
                                                                >
                                                                    <div
                                                                        className="m-a-chat-msg-admin-content font-t-cont font-s-14">{DOMPurify.sanitize(message.chatKey)}</div>
                                                                </Linkify>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="m-a-chat-msgInput-container">
                                {/*비회원 채팅인풋 프레임*/}
                                <div className="m-a-chat-send-frame">
                                    {/*emoji & upload & remove*/}
                                    <div className="m-a-chat-emoji-photo-frame m-l-10">
                                        <div onClick={() => setShowNonEmoji(!showNonEmoji)}
                                             className="m-a-chat-show-emoji">
                                            <img alt='' className="m-a-chat-show-emoji-icon"
                                                 src={mainChatEmoji}/>
                                        </div>
                                        {/*비회원 이모티콘*/}
                                        {showNonEmoji ?
                                            <div className="m-a-chat-show-emoji-popup-frame1">
                                                <div className="m-a-chat-show-emoji-popup-frame2">
                                                    <Picker
                                                        onEmojiSelect={nonHandleEmojiClick}
                                                        previewPosition="none"
                                                        searchPosition="none"
                                                        skinTonePosition="none"
                                                        locale="kr"
                                                    />
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                        {/*비회원 파일업로드*/}
                                        <div className="m-a-chat-fileUpload-frame">
                                            <input
                                                type="file"
                                                ref={nonFileInputRef}
                                                style={{display: "none"}}
                                                onChange={nonHandleFileUpload}
                                            />
                                            <button className="m-a-chat-fileUpload-button"
                                                    onClick={nonTriggerFileInput}>
                                                <img alt='fileUploadImg' className="wd-25 ht-25"
                                                     src={mainChatUpload}/>
                                            </button>
                                        </div>
                                        {/*비회원 관리자매크로*/}
                                        <div className="m-a-chat-fileUpload-frame" style={{marginLeft: "-3px"}}>
                                            <button onClick={toggleMacroPopup} className="m-a-chat-fileUpload-button">
                                                <img alt='' className="wd-25 ht-25 m-a-opc-06"
                                                     src={commonMacro}/>
                                            </button>
                                        </div>
                                    </div>
                                    {/*비회원채팅 textarea 프레임*/}
                                    <div className="m-a-chat-textarea-frame">
                                        {/*비회원채팅 textarea*/}
                                        <textarea ref={nonChatInputRef} placeholder=""
                                                  spellCheck="false" value={nonMessage}
                                                  className="m-a-textarea font-s-14 m-a-chat-textarea wd-100pc"
                                                  onChange={nonHandleChatInput}
                                                  onKeyDown={(e) => {
                                                      if (e.key === 'Enter' && !e.shiftKey) {
                                                          e.preventDefault();
                                                          nonSendMessage(nonMessage, null, sendMsgNum);
                                                          setNonMessage('');
                                                          nonChatInputRef.current.style.height = '42px';
                                                      }
                                                  }}
                                        />
                                    </div>
                                    <div onClick={() => {
                                        nonSendMessage(nonMessage, null, sendMsgNum);
                                        setNonMessage('');
                                    }} className="m-a-chat-send-bt-frame">
                                        <img alt='' className="m-a-chat-send-bt-img"
                                             style={{opacity: message.length > 0 ? "1" : "0.6"}}
                                             src={mainChatSend}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="ht-100pc">
                        {ordChat ?
                            <div className="m-a-body-container">
                                <div className="m-a-chatTab-container font-t-title">
                                    <div className="user-center">
                                        {/*주문서채팅탭*/}
                                        <div onClick={() => handleTab("ordChat")}
                                             className={ordChat ? "m-a-headerFocusTab" : "m-a-headerTab"}>
                                            <div className="font-s-14">주문서</div>
                                            <div className="font-t-cont chatTtCt m-l-3"
                                                 style={{color: !ordChat ? "#FF6324" : "white"}}>{lcOrdChatCount}</div>
                                        </div>
                                        {/*비회원채팅탭*/}
                                        <div onClick={() => handleTab("nonMember")}
                                             className={nonMember ? "m-a-headerFocusTab m-l-10" : "m-a-headerTab m-l-10"}>
                                            <div className="font-s-14">비회원</div>
                                            <div className="font-t-cont chatTtCt m-l-3"
                                                 style={{color: !nonMember ? "#FF6324" : "white"}}>{lcNUserChatCount}</div>
                                        </div>
                                    </div>
                                    {/*검색*/}
                                    <div className="m-a-card-shadow m-a-input-frame ht-45 m-a-chat-search-container">
                                        <input type="text" className="m-a-input font-t-cont" placeholder="Search"
                                               style={{borderRadius: "10px"}}/>
                                    </div>
                                </div>
                                {/*주문서채팅 프로필 컨테이너*/}
                                {memberList && memberList.map((member, i) => (
                                    <div key={i} onClick={() => handleUserNameClick(member, i)}>
                                        <div className="m-a-card m-t-15">
                                            <div className="m-a-chat-container">
                                                <div className="m-a-profile-frame">
                                                    {member.memberDTO.profileDTO ?
                                                        <img className="m-a-profile-frame-img"
                                                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + member.memberDTO.profileDTO.filename}
                                                             alt="profile"/>
                                                        :
                                                        <img className="m-a-profile-frame-img"
                                                             src={profileDefaultImg}
                                                             alt="profile"/>
                                                    }
                                                </div>
                                                <div className="m-a-chat-content-frame">
                                                    <div className="m-a-chat-content">
                                                        <div
                                                            className="m-a-chat-userNm font-t-title">{member && member.mstUserNm}</div>
                                                        {member && member.unreadCount > 0 ?
                                                            <div
                                                                className="m-a-chat-unreadCount">{member.unreadCount}</div>
                                                            : null}
                                                    </div>
                                                    <div className="m-a-chat-content">
                                                        {(member && member.chatKey.endsWith("png") ||
                                                            member.chatKey.endsWith("PNG") ||
                                                            member && member.chatKey.endsWith("jpg") ||
                                                            member.chatKey.endsWith("JPG")) ||
                                                        member.chatKey.endsWith("jpeg") ||
                                                        member.chatKey.endsWith("JPEG") ?
                                                            <div
                                                                className="m-a-chat-chatKey font-t-cont">[사진]</div>
                                                            : member.chatKey.startsWith("chatFile") ?
                                                                <div
                                                                    className="m-a-chat-chatKey font-t-cont">[파일]</div>
                                                                : <div className="m-a-chat-chatKey-text-frame">
                                        <span
                                            className="m-a-chat-chatKey-text font-t-cont">{member.chatKey}</span>
                                                                </div>
                                                        }
                                                        <div
                                                            className="m-a-chat-commingTime font-t-cont">{convertToNonMemberDate(member && member.regTime)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*주문서채팅 리스트 컨테이너*/}
                                        {focusedIndex === i && (
                                            <div
                                                className="m-a-card m-t-5 m-a-chat-ordList-container">
                                                {selectedUserOrderNumbers.filter(chatDt => chatDt.mstMbNum === member.mstMbNum).map((chatDt, i) => (
                                                    <div key={i}
                                                         className="m-a-chat-ordList-frame"
                                                         onClick={() => handleOrderNumberlick(chatDt, i)}>
                                                        <div
                                                            className="m-a-profile-ordList-frame">
                                                            <img
                                                                className="m-a-profile-ordList-frame-img"
                                                                src={ordChatListImg}
                                                                alt="profile"/>
                                                        </div>
                                                        <div
                                                            className="m-a-chat-ordList-content-frame">
                                                            <div
                                                                className="m-a-chat-ordList-content">
                                                                <div
                                                                    className="m-a-chat-ordNum font-t-title">{chatDt.chatOrdNum}</div>
                                                                {chatDt.unreadCount > 0 && chatDt.memberDTO.role === '0' ?
                                                                    <div
                                                                        className="m-a-chat-ordList-unreadCount">{chatDt.unreadCount}</div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            <div
                                                                className="m-a-chat-ordList-content">
                                                                {(chatDt && chatDt.chatKey.endsWith("png") ||
                                                                    chatDt.chatKey.endsWith("PNG") ||
                                                                    chatDt && chatDt.chatKey.endsWith("jpg") ||
                                                                    chatDt.chatKey.endsWith("JPG")) ||
                                                                chatDt.chatKey.endsWith("jpeg") ||
                                                                chatDt.chatKey.endsWith("JPEG") ?
                                                                    <div
                                                                        className="m-a-chat-ordList-chatKey font-t-cont">[사진]</div>
                                                                    : chatDt.chatKey.startsWith("chatFile") ?
                                                                        <div
                                                                            className="m-a-chat-ordList-chatKey font-t-cont">[파일]</div>
                                                                        :
                                                                        <div className="m-a-chat-chatKey-text-frame">
                                                                            <span className="m-a-chat-chatKey-text font-t-cont">{chatDt.chatKey}</span>
                                                                        </div>
                                                                }
                                                                <div
                                                                    className="m-a-chat-ordList-commingTime font-t-cont">{convertToNonMemberDate(chatDt && chatDt.regTime)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            :
                            nonMember ?
                                <div className={(chatStarted || nonChatShow ? '챗목록' : 'm-a-body-container')}>
                                    <div className="m-a-chatTab-container font-t-title">
                                        <div className="user-center">
                                            {/*주문서채팅탭*/}
                                            <div onClick={() => handleTab("ordChat")}
                                                 className={ordChat ? "m-a-headerFocusTab" : "m-a-headerTab"}>
                                                <div className="font-s-14">주문서</div>
                                                <div className="chatTtCt m-l-3"
                                                     style={{color: !ordChat ? "#FF6324" : null}}>{lcOrdChatCount}</div>
                                            </div>
                                            {/*비회원채팅탭*/}
                                            <div onClick={() => handleTab("nonMember")}
                                                 className={nonMember ? "m-a-headerFocusTab m-l-10" : "m-a-headerTab m-l-10"}>
                                                <div className="font-s-14">비회원</div>
                                                <div className="chatTtCt m-l-3"
                                                     style={{color: !nonMember ? "#FF6324" : null}}>{lcNUserChatCount}</div>
                                            </div>
                                        </div>
                                        {/*검색*/}
                                        <div className="m-a-card-shadow m-a-input-frame ht-45 m-a-chat-search-container">
                                            <input type="text" className="m-a-input font-t-cont" placeholder="Search"
                                                   style={{borderRadius: "10px"}}/>
                                        </div>
                                    </div>
                                    {/*비회원채팅 프로필 컨테이너*/}
                                    <div>
                                        {nonMemberList.map((list, index) => (
                                            <div key={index} className="m-a-card m-t-15"
                                                 onClick={() => handleNonChatMessages(list && list, index)}>
                                                <div className="m-a-chat-container">
                                                    <div className="m-a-profile-frame">
                                                        <img className="m-a-profile-frame-img"
                                                             src={profileDefaultImg}
                                                             alt="profile"/>
                                                    </div>
                                                    <div className="m-a-chat-content-frame">
                                                        <div className="m-a-chat-content">
                                                            <div
                                                                className="m-a-chat-userNm font-t-title"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>Non
                                                                User
                                                            </div>
                                                            {nonMemberList[index].unreadCount > 0 && nonMemberList[index].role === '1' ?
                                                                <div
                                                                    className="m-a-chat-unreadCount">{nonMemberList[index].unreadCount}</div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="m-a-chat-content">
                                                            {(list && list.chatKey.endsWith("png") ||
                                                                list.chatKey.endsWith("PNG") ||
                                                                list.chatKey.endsWith("jpg") ||
                                                                list.chatKey.endsWith("JPG") ||
                                                                list.chatKey.endsWith("jpeg") ||
                                                                list.chatKey.endsWith("JPEG")) ?
                                                                <div
                                                                    className="m-a-chat-chatKey font-t-cont">[사진]</div>
                                                                : list.chatKey.startsWith("chatFile") ?
                                                                    <div
                                                                        className="m-a-chat-chatKey font-t-cont">[파일]</div>
                                                                    :
                                                                    <div className="m-a-chat-chatKey-text-frame">
                                        <span
                                            className="m-a-chat-chatKey-text font-t-cont">{list.chatKey}</span>
                                                                    </div>
                                                            }
                                                            <div
                                                                className="m-a-chat-commingTime font-t-cont">{list && convertToNonMemberDate(list.regTime)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div style={{marginTop:"30px"}}>
                                            <img onClick={()=> setNUserRemoveSt(true)} className="wd-30 ht-30" alt="All Clean"
                                                 src={trash}/>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
            }
            {nonShowChatFile ?
                <MChatFileUpload nonShowChatFileClose={nonShowChatFileClose}
                                      nonSelectedFile={nonSelectedFile} nonShowChatFile={nonShowChatFile}
                                      nonSelectedFileUrl={nonSelectedFileUrl}
                                      saveNonChatFile={saveNonChatFile}/>
                :
                nonSelectedClickSt ?
                    <MChatFileDownModal nonSelectedClickSt={nonSelectedClickSt}
                                        nonClickFileClose={nonClickFileClose}
                                        nonSelectedClickFile={nonSelectedClickFile}
                                        nonDownloadFile={nonDownloadFile}/>
                    :
                    nonChatFileModifySt ?
                        <MChatFileUpload nonChatFileModifySt={nonChatFileModifySt}
                                              nonChatFileModifyFalseFn={nonChatFileModifyFalseFn}
                                              nonChatFileModify={nonChatFileModify}
                                              nonMessageToModify={nonMessageToModify}/>
                        :
                        nonChatModifySt ?
                            <MChatModifyModal nonChatModifyFalseFn={nonChatModifyFalseFn}
                                              nonChatModifySt={nonChatModifySt}
                                              nonMessageToModify={nonMessageToModify}
                                              nonChatFileModify={nonChatFileModify}/>
                            :
                            nonChatDeleteSt ?
                                <MMsgSelectModal nonChatDeleteSt={nonChatDeleteSt}
                                                 nonChatDeleteFalseFn={nonChatDeleteFalseFn}
                                                 chatDeleteMsg1={chatDeleteMsg1} chatDeleteMsg2={chatDeleteMsg2}
                                                 nonMessageToModify={nonMessageToModify} deleteNonChat={deleteNonChat}/>
                                :
                                showChatFile ?
                                    <MChatFileUpload showChatFileClose={showChatFileClose}
                                                          selectedFile={selectedFile}
                                                          selectedFileUrl={selectedFileUrl}
                                                          saveChatFile={saveChatFile}/>
                                    :
                                    chatFileModifySt ?
                                        <MChatFileUpload chatFileModifySt={chatFileModifySt}
                                                              chatFileModifyFalseFn={chatFileModifyFalseFn}
                                                              chatFileModify={chatFileModify}
                                                              messageToModify={messageToModify}/>
                                        :
                                        chatModifySt ?
                                            <MChatModifyModal chatModifyFalseFn={chatModifyFalseFn}
                                                              messageToModify={messageToModify}
                                                              chatFileModify={chatFileModify}/>
                                            :
                                            chatDeleteSt ?
                                                <MMsgSelectModal chatDeleteSt={chatDeleteSt}
                                                                 chatDeleteFalseFn={chatDeleteFalseFn}
                                                                 chatDeleteMsg1={chatDeleteMsg1}
                                                                 chatDeleteMsg2={chatDeleteMsg2}
                                                                 messageToModify={messageToModify}
                                                                 deleteChat={deleteChat}/>
                                                :
                                                selectedClickSt ?
                                                    <MChatFileDownModal selectedClickSt={selectedClickSt}
                                                                        clickFileClose={clickFileClose}
                                                                        selectedClickFile={selectedClickFile}
                                                                        downloadFile={downloadFile}/>
                                                    :
                                                    fileSizeOverSt ?
                                                        <MMsgCheckModal
                                                            fileSizeOverSt={fileSizeOverSt}
                                                            closeFileSizeOverSt={closeFileSizeOverSt}/>
                                                        :
                                                        downloadFileComplSt ?
                                                            <MMsgCheckModal downloadFileComplSt={downloadFileComplSt}
                                                                            closeDownloadFileCompl={closeDownloadFileCompl}/>
                                                            :
                                                            nUserRemoveSt ?
                                                                <MMsgSelectModal
                                                                    nUserRemoveSt={nUserRemoveSt}
                                                                    nUserRemoveMsgTitle={nUserRemoveMsgTitle}
                                                                    nUserRemoveMsgContent={nUserRemoveMsgContent}
                                                                    closeNUserRemoveSt={closeNUserRemoveSt}
                                                                    nUserAllChatRemove={nUserAllChatRemove}/>
                                                                :
                                                            null
            }
            {isMacroPopupVisible && (
                <div className="m-a-chat-admin-macro-popup-frame" onClick={toggleMacroPopup}>
                    <div className="m-a-chat-admin-macro-popup" onClick={(e) => e.stopPropagation()}>
                        <div className="m-a-chat-admin-macro-header-frame user-center">
                            <div className="m-a-chat-admin-macro-header-text font-t-head font-s-17">매크로</div>
                        </div>
                        <div className="m-a-chat-admin-macro-body-frame1">
                            {macroMstList.map((mst, mstIdx) => (
                                <div key={mstIdx} onClick={() => fetchNonMacroDtList(mstIdx)}
                                     className="m-a-chat-admin-macro-body-frame2">
                                    <div
                                        className="m-a-chat-admin-macro-body-parent font-t-title user-center">{mst && mst.title}</div>
                                    {nonSelectedMacroMstIndex === mstIdx ?
                                        <div className="m-a-chat-admin-macro-body-child-frame">
                                            {nonSelectedMacroMstIndex === mstIdx && macroDtList && macroDtList.map((dt, dtIdx) => (
                                                <div key={dtIdx} tabIndex='0'
                                                     onClick={chatStarted ? () => sendOrdMacroMessage(dt.content) : () => sendNonMacroMessage(dt.content)}
                                                     className="m-a-chat-admin-macro-body-child font-t-cont user-center">{dt && dt.title}</div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="m-a-chat-admin-macro-footer-frame user-center">
                            <button onClick={() => setIsMacroPopupVisible(false)}
                                    className="m-a-colorBt ht-40 m-b-5 wd-80">
                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MAdminOrderChatPg
