import '../../css/mobile/MFooterSection.css';
import React, {useEffect, useRef, useState} from "react";

import Home from '../../assets/img/mainHomeIcon.svg';
import Lang from '../../assets/img/mainLangIcon.svg';
import Order from '../../assets/img/mainHomeOrder.svg';
import Mypage from '../../assets/img/mainHomeUser.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";
import axios from '../../services/axiosInstance';
import {useWebSocket} from "../../services/WebsocketContext";
// import MSelectLangModal from "./MSelectLangModal";
import SelectLangModal from "../web/modal/SelectLangModal";

const MFooterSection = () => {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [memberDto, setMemberDto] = useState([]);

    const selectMember = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberDto(response.data[0])
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })
    useEffect(() => {
        if (SSAT || LCAT) {
            selectMember();
        }
    }, [SSAT, LCAT]);


    useEffect(() => {
        if (memberDto && memberDto.mbNum) {
            selectTotalUnreadCount(memberDto.mbNum);
        }
    }, [memberDto]);

    const [totalUnreadCount, setTotalUnreadCount] = useState(0);
    const selectTotalUnreadCount = (mbNum) => {
        axios.get(`/order/selectTotalUnreadCount/${mbNum}`, {
            headers: {
                Authorization: SSAT ? SSAT : LCAT
            }
        })
            .then(response => {
                setTotalUnreadCount(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberDto.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(memberDto.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/user/${memberDto.mbNum}/queue/adminSubMessage`, onMessageReceived);
                subscribe(`/user/${memberDto.mbNum}/queue/adminRead`, onReadMessageReceived);
                subscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`, onChatAdminUpdate);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminSubMessage`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminRead`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`)
                isSubscribedRef.current = false;
            }
        };

    }, [memberDto.mbNum, isConnected, stompClient]);







    const onMessageReceived = async (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (memberDto && memberDto.mbNum && String(memberDto.mbNum) === newMessage.mstMbNum && newMessage.memberDTO.role === '1') {
            selectTotalUnreadCount(memberDto.mbNum);
        }
    }

    const onReadMessageReceived = async (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (memberDto && memberDto.mbNum && String(memberDto.mbNum) === newMessage.mstMbNum && newMessage.readerRole === "ADMIN") {
            selectTotalUnreadCount(memberDto.mbNum);
        }
    }

    const onChatAdminUpdate = ((payload) => {
        let newMessage = JSON.parse(payload.body);
        if (memberDto && memberDto.mbNum) {
            selectTotalUnreadCount(memberDto.mbNum);
        }
    })

    const [openLanguagePopUp, setOpenLanguagePopUp] = useState(false);
    const [openOrderPopUp, setOpenOrderPopUp] = useState(false);
    const msgNum = localStorage.getItem('msgNum');

    const location = useLocation();
    const navigate = useNavigate();

    const selectLangTrueFn = (() => {
        setOpenLanguagePopUp(true);
    })
    const selectLangFalseFn = (() => {
        setOpenLanguagePopUp(false);
    })
    const handleSelectLangChange = (lang) => {
        setOpenLanguagePopUp(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }
    return (
        <div className='m-a-bottom-navigation-bar-container'>
            <div className='m-a-bottom-navigation-bar-item'
                 onClick={() => {
                     navigate("/")
                     setOpenLanguagePopUp(false)
                     setOpenOrderPopUp(false)
                 }}>
                {(location.pathname === '/' && !openLanguagePopUp && !openOrderPopUp) ? (
                    <div className='m-a-bottom-navigation-bar-mask home'/>
                ) : (
                    <img className='m-a-bottom-navigation-bar-icon' src={Home} alt=''/>
                )}
                <div className={`m-a-bottom-navigation-bar-text
                        ${location.pathname === '/' && !openLanguagePopUp && !openOrderPopUp && 'selected'}`}>Home
                </div>
            </div>
            <div className='m-a-bottom-navigation-bar-item' onClick={() => {setOpenLanguagePopUp(!openLanguagePopUp)}}>
                {openLanguagePopUp ? (
                    <div className='m-a-bottom-navigation-bar-mask lang'/>
                ) : (
                    <img className='m-a-bottom-navigation-bar-icon' src={Lang} alt=''/>
                )}
                <div className={`m-a-bottom-navigation-bar-text ${openLanguagePopUp && 'selected'}`}>Language</div>
            </div>
            <div className='m-a-bottom-navigation-bar-item-new' onClick={() => {setOpenOrderPopUp(!openOrderPopUp)}}>
                {openOrderPopUp ? (
                    <div className='m-a-bottom-navigation-bar-mask order'/>
                ) : (
                    <img className='m-a-bottom-navigation-bar-icon' src={Order} alt=''/>
                )}
                <div className={`m-a-bottom-navigation-bar-text
                        ${openOrderPopUp && 'selected'}`}>
                    Order
                </div>
            </div>
            { LCAT || SSAT ?
                <div className='m-a-bottom-navigation-bar-item'
                     onClick={() => {
                         navigate("/UserMyPg")
                         setOpenLanguagePopUp(false)
                         setOpenOrderPopUp(false)
                     }}>
                    {(location.pathname === `/UserMyPg` && !openLanguagePopUp && !openOrderPopUp) ? (
                        <div className='m-a-bottom-navigation-bar-mask mypage'/>
                    ) : (
                        <img className='m-a-bottom-navigation-bar-icon' src={Mypage} alt=''/>
                    )}
                    <div className={`display-flex flex-row m-a-bottom-navigation-bar-text ${location.pathname === `/UserMyPg` && !openLanguagePopUp && !openOrderPopUp && 'selected'} `}>
                        <p className="m-r-3">MyPage</p>
                        {totalUnreadCount && totalUnreadCount > 0 ?
                            <div className="mYpg-Alarm text-center">{totalUnreadCount}</div>
                            :
                            null
                        }
                    </div>
                </div>
                    :
                <div className='m-a-bottom-navigation-bar-item'
                     onClick={() => {
                         navigate("/UserLoginPg")
                         setOpenLanguagePopUp(false)
                         setOpenOrderPopUp(false)
                     }}>
                    {(location.pathname === `/UserLoginPg` && !openLanguagePopUp && !openOrderPopUp) ? (
                        <div className='m-a-bottom-navigation-bar-mask mypage'/>
                    ) : (
                        <img className='m-a-bottom-navigation-bar-icon' src={Mypage} alt=''/>
                    )}
                    <div className={`m-a-bottom-navigation-bar-text ${location.pathname === `/UserMyPg` && !openLanguagePopUp && !openOrderPopUp && 'selected'} `}>
                        Login
                    </div>
                </div>
            }
            {openLanguagePopUp && (
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn}
                                 handleSelectLangChange={handleSelectLangChange}/>
            )}
            {openOrderPopUp && (
                <div className='m-a-bottom-navigation-pop-up-background' onClick={(e) => {e.stopPropagation()}}>
                    <div className='m-a-bottom-navigation-pop-up-container font-t-cont'>
                        <div className='m-a-bottom-navigation-pop-up-title'><p className="font-t-title">Order Type</p>
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-item raw'
                             onClick={() => navigate('/UserOrderPg', {state: {cartState: true}})}>
                            {t(`cartTitle`)}{t(`dcDoneBt`)}
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-item raw'
                             onClick={() => navigate('/UserOrderPg', {state: {orderState: true}})}>
                            {t(`hdSMenu6`)}
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-item raw'
                             onClick={() => navigate('/UserOrderPg', {state: {offlineState: true}})}>
                            {t(`hdSMenu8`)}
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-item raw'
                             onClick={() => navigate('/UserOrderPg', {state: {transferState: true}})}>
                            {t(`hdSMenu7`)}
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-item raw'
                             onClick={() => navigate('/UserOrderPg', {state: {domesticState: true}})}>
                            {t(`hdSMenu9`)}
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-item raw'
                             onClick={() => navigate('/UserOrderPg', {state: {deliveryState: true}})}>
                            {t(`deliveryTitle`)}{t(`dcDoneBt`)}
                        </div>
                        <div className='m-a-bottom-navigation-pop-up-button'
                             onClick={() => setOpenOrderPopUp(false)}>
                            Close
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MFooterSection;
