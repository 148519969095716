import React, {useEffect, useState} from 'react';
import axios from '../../services/axiosInstance';
import maCateImg from "../../assets/img/m-a-cate.png";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";

function AdminCountryZipcodePg() {

    /**region DataTable */

    const [stateData, setStateData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("2");
    const [inputZipCode, setInputZipCode] = useState("");
    const [inputStateCode, setInputStateCode] = useState("");

    const LCAT = localStorage.getItem('adminToken');

    /** endregion */

    /** region Function */

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = stateData.filter((cData) => {
        return (
            cData?.zipCode?.toLowerCase().includes(searchInput?.toLowerCase())
        );
    });

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [stateData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (stateData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(15);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (dataItem) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.some(item => item.seqNum === dataItem.seqNum)) {
                return prevSelectedData.filter((item) => item.seqNum !== dataItem.seqNum);
            } else {
                return [...prevSelectedData, dataItem];
            }
        });
    };

    const allCheckBoxChange = (e) => {
        if (e.target.checked) {
            setChecked(stateData);
        } else {
            setChecked([]);
        }
    };

    //endregion

    const handleZipCodeChange = (e) => {
        const value = e.target.value;
        if (selectedCountry === "2") {
            const usaRegex = /^[0-9]*$/;
            if (usaRegex.test(value) && value.length <= 5) {
                setInputZipCode(value);
            }
        } else if (selectedCountry === "3") {
            const canadaRegex = /^[A-Za-z0-9]*$/;
            if (canadaRegex.test(value) && value.length <= 6) {
                setInputZipCode(value);
            }
        }
    };

    const handleStateCodeChange = (e) => {
        const value = e.target.value;
        const stateCodeRegex = /^[A-Za-z]*$/;
        if (stateCodeRegex.test(value) && value.length <= 2) {
            setInputStateCode(value);
        }
    };

    /** endregion */

    /** region Event  */

    useEffect(() => {
        zipCodeStateSelect();
    }, []);

    const zipCodeStateSelect = () => {
        axios.get('/zipcode/stateAllSelect')
            .then((response) => {
                const data = response.data;
                setStateData(data);
            })
            .catch((error) => {
                console.error("데이터조회 실패", error);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedCountry === "2") {
            const usaRegex = /^[0-9]{5}$/;
            if (!usaRegex.test(inputZipCode)) {
                return;
            }
        } else if (selectedCountry === "3") {
            const canadaRegex = /^[A-Za-z0-9]{6}$/;
            if (!canadaRegex.test(inputZipCode)) {
                return;
            }
        }

        const stateCodeRegex = /^[A-Za-z]{2}$/;
        if (!stateCodeRegex.test(inputStateCode)) {
            return;
        }

        const duplicate = stateData.some(
            (item) => item.zipCode.toLowerCase() === inputZipCode.toLowerCase()
        );
        if (duplicate) {
            console.log("이미 존재하는 우편번호")
            return;
        }
        try {
            const newZipState = {
                zipCode: inputZipCode,
                stateCode: inputStateCode,
                countryDTO: {
                    seqNum: Number(selectedCountry)
                }
            };
            const response = await axios.post('/zipcode/stateAdd', newZipState);
            setStateData(prevData => [...prevData, response.data]);
            setSelectedCountry("2");
            setInputZipCode("");
            setInputStateCode("");
        } catch (error) {
            console.error('우편번호 state 추가 실패:', error);
        }
    };

    /** endregion */

    return (
        <div className="AdminEmployeeInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">우편번호STATE관리(미국/캐나다)전용</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap font-t-cont">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="gap10">
                            <form onSubmit={handleSubmit} className="display-flex gap10">
                                <select className="admin-combo wd-80 ht-30" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
                                    <option value="2">미국</option>
                                    <option value="3">캐나다</option>
                                </select>
                                <input className="admin-input-line wd-120 ht-30 font-s-13 p-l-5" type="text" placeholder="우편번호"
                                       minLength={1} maxLength={6} value={inputZipCode} onChange={handleZipCodeChange} required/>
                                <input className="admin-input-line wd-120 ht-30 font-s-13 p-l-5" placeholder="State(2자리)"
                                       minLength={2} maxLength={2} value={inputStateCode} onChange={handleStateCodeChange} required/>
                                <button className="bt-btn-A ht-30 wd-100 font-s-13" type="submit">저장</button>
                            </form>
                        </div>
                    </div>
                    <div className="div-tb m-b-20">
                        <div className="div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === stateData.length} onChange={allCheckBoxChange}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>국가명</p></div>
                            <div className="wd-110"><p>우편번호</p></div>
                            <div className="wd-110"><p>주(state)코드</p></div>
                        </div>
                        {stateData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div className="wd-40">
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.some(item => item.seqNum === cData.seqNum)}
                                           onChange={() => checkBoxChange(cData)}/>
                                </div>
                                <div className="wd-40"><p>{i + 1}</p></div>
                                <div className="font-b wd-150"><p>{cData.countryDTO.countryNm}</p></div>
                                <div className="wd-110"><p>{cData.zipCode}</p></div>
                                <div className="wd-110"><p>{cData.stateCode}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && stateData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminCountryZipcodePg
