import React, {useEffect} from "react";
import btorageLogo from '../../assets/img/BtorageLogo.png'

function UserIosRedirectPg() {

    useEffect(() => {
        // 앱 설치되어 있으면 앱 열림
        // 설치 안 되어 있으면 fallback으로 앱스토어 이동
        setTimeout(() => {
            window.location.href = 'https://apps.apple.com/app/id6738265560';
        }, 2000);
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.animation}> {/* 애니메이션 스타일 적용 */}
                <img src={btorageLogo} alt="Btorage Logo" style={styles.logo} />
            </div>
            <p style={styles.text}>Loading ...</p>
        </div>
    );
}

export default UserIosRedirectPg;


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
        textAlign: 'center'
    },
    logo: {
        width: '80px',
        height: 'auto',
        animation: 'rotate 2s linear infinite',
    },
    animation: {
        '@keyframes rotate': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
        },
    },
    text: {
        marginTop: '30px',
        fontSize: '1.2rem',
        color: 'black',
    },
};