import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from "react";
import {createClient} from './WebsocketService';

const WebSocketContext = createContext(null);

// 웹소켓 구조 조건
// 1. 연결 공유하고 있는 모든 컴포넌트가 언마운트되면 구독해제
// 2. 도중에 스프링부트가 실행 중지되거나 네트워크 연결이 끊기면 실시간 감지 후 5초마다 재연결 시도
// 3. 웹소켓 연결함수가 있는 컴포넌트끼리 이동 시 구독해제 후 재연결(HeaderMain.js/SideQuickMenu.js 제외)

export const WebSocketProvider = ({children}) => {
    const stompClientRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const [subscriptions, setSubscriptions] = useState({});
    const [reconnectInterval, setReconnectInterval] = useState(null);
    const [userKey, setUserKey] = useState(null);

    // 모든 구독 해제
    const unsubscribeAll = () => {
        Object.keys(subscriptions).forEach(destination => {
            subscriptions[destination]?.unsubscribe();
        });
        setSubscriptions({});
    };

    // 연결끊김 감지 후 재연결 처리
    useEffect(() => {
        if (userKey) {
            const handleDisconnect = () => {
                setIsConnected(false);
                stompClientRef.current = null;
                // 스프링부트 중지 및 네트워크끊김 시 모든 컴포넌트가 구독해제 후 재연결해야함
                // 안 그러면 해당 컴포넌트가 렌더링 및 트리거되지 않아서 구독된 상태에서도 수신을 못함
                unsubscribeAll();
                handleReconnect(userKey);
            };

            const handleReconnect = (userKey) => {
                if (!reconnectInterval) {
                    const interval = setInterval(() => {
                        if (!stompClientRef.current || !isConnected) {  // 연결이 안 된 경우
                            // console.log("재연결 시도 중...");
                            websocketConnect(userKey)
                                .then(() => {
                                    // console.log("WebSocket 재연결 성공");
                                    if (reconnectInterval) {
                                        clearInterval(reconnectInterval);
                                        setReconnectInterval(null);
                                    }
                                })
                                .catch((error) => {
                                    console.error("재연결 실패:", error);
                                });
                        } else if (isConnected) {  // 이미 연결된 상태라면 타이머 해제
                            if (reconnectInterval) {
                                clearInterval(reconnectInterval);
                                setReconnectInterval(null);
                            }
                        }
                    }, 5000);
                    setReconnectInterval(interval);
                }
            };

            // 연결끊김감지 후 재연결함수 실행
            if (stompClientRef.current && stompClientRef.current.webSocket) {
                stompClientRef.current.webSocket.onclose = handleDisconnect;
            }

            // 언마운트 시 재연결 및 타이머종료
            return () => {
                if (stompClientRef.current && stompClientRef.current.webSocket) {
                    stompClientRef.current.webSocket.onclose = null;
                }
                if (reconnectInterval) {
                    clearInterval(reconnectInterval);
                    setReconnectInterval(null);
                }
            }
        }
    }, [subscriptions, reconnectInterval]);

    // 새로운 웹소켓 연결 함수
    const websocketConnect = async (key) => {
        if (!stompClientRef.current) {
            return new Promise((resolve, reject) => {
                const client = createClient(); // 새로운 stomp client 생성
                client.connect({key}, () => {
                    setUserKey(key);
                    stompClientRef.current = client;
                    setIsConnected(true);
                    // console.log("WebSocket 연결 성공");
                    clearInterval(reconnectInterval); // 연결 성공 시 타이머 해제
                    setReconnectInterval(null); // 재연결 시도 타이머 초기화
                    resolve(client);
                }, (error) => {
                    console.error("WebSocket 연결 오류:", error);
                    setIsConnected(false);
                    reject(error);
                });
            });
        }
    };

    // 구독 함수
    const subscribe = (destination, callback) => {
        if (!stompClientRef.current) return;
        const subscription = stompClientRef.current.subscribe(destination, callback);
        setSubscriptions((prev) => ({...prev, [destination]: subscription}));
    };

    // 특정 구독 해제 함수
    const unsubscribe = (destination) => {
        if (subscriptions[destination]) {
            subscriptions[destination].unsubscribe();
            setSubscriptions((prev) => {
                const updatedSubs = {...prev};
                delete updatedSubs[destination];
                return updatedSubs;
            });
        }
    };

    return (
        <WebSocketContext.Provider
            value={{stompClient: stompClientRef.current, isConnected, websocketConnect, subscribe, unsubscribe}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
