import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from '../../services/axiosInstance';
import profileImage from "../../assets/img/profileDefaultImg.png";
import DOMPurify from "dompurify";
import {BiArrowBack} from "react-icons/bi";
import reviewComment from "../../assets/img/reviewComment.png";
import defaultImg from "../../assets/img/profileDefaultImg.png";
import warnIcon from "../../assets/img/warnIcon.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminReviewCommentModifyPg from "../../components/web/modal/AdminReviewCommentModifyPg";
import async from "async";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminReviewDetailPg() {

    const {seqNum} = useParams();
    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])


    const [reviewData, setReviewData] = useState([]);
    const [reviewProfileImg, setReviewProfileImg] = useState(profileImage)
    const [reviewList, setReviewList] = useState([]);
    const [reviewCommentList, setReviewCommentList] = useState([]);

    const selectReviewData = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setReviewData(response.data)
                // setReviewProfileImg("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data.memberDTO.profileDTO.filename)
                const isDefaultImg = response && response.data && response.data.memberDTO && response.data.memberDTO.profileDTO && response.data.memberDTO.profileDTO.filename;
                setReviewProfileImg(
                    isDefaultImg ?
                        "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data.memberDTO.profileDTO.filename
                        :
                        profileImage
                );
                setReviewList(response.data.reviewPhotoDTO)
                setReviewCommentList(response.data.reviewCommentDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectReviewData();
        }
    }, [seqNum])



    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(reviewData?.content);

    const textareaRef = useRef(null);
    const [reviewCommentText, setReviewCommentText] = useState('');

    const handleCommentTextChange = (e) => {
        setReviewCommentText(e.target.value);
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
        }
    }, [reviewCommentText])

    const saveReviewComment = () =>
        axios({
            method: 'post',
            url: '/review/saveReviewComment',
            params: {
                reviewSeqNum: reviewData.seqNum,
                memberMbNum: adminInfo.mbNum,
                content: reviewCommentText
            }
        })
            .then((response) => {
                const newComment = response.data;
                const updatedComments = [...reviewCommentList, newComment];
                setReviewCommentList(updatedComments);
                setReviewCommentText('');
            })
            .catch((response) => {
                console.log("코멘트 등록실패")
            })

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const moveToModify = (() => {
        navigate(`/btcorpad/adminreview-modify/${seqNum}`);
    })

    const [deleteReviewShow, setDeleteReviewShow] = useState(false);
    const deleteReviewShowMsg1 = "이용후기 게시글을 삭제하시겠습니까?";
    const deleteReviewShowMsg2 = "삭제 후 복구할 수 없습니다"
    const closeDeleteReviewShow = (() => {
        setDeleteReviewShow(false);
    })
    const deleteReview = () =>
        axios({
            method: 'post',
            url: '/review/deleteReview',
            params: {
                reviewSeqNum: seqNum
            }
        })
            .then((response) => {
                moveToReview();
            })
            .catch((response) => {
                console.log("코멘트 등록실패")
            })

    const moveToReview = (() => {
        navigate('/btcorpad/adminreview');
    })

    const [deleteReviewCommentShow, setDeleteReviewCommentShow] = useState(false);
    const [deleteReviewCommentSeqNum, setDeleteReviewCommentSeqNum] = useState('');
    const deleteReviewCommentShowMsg1 = "선택하신 댓글을 삭제하시겠습니까?";
    const deleteReviewCommentShowMsg2 = "삭제 후 복구할 수 없습니다"
    const closeDeleteReviewCommentShow = (() => {
        setDeleteReviewCommentShow(false);
    })
    const openDeleteReviewCommentShow = ((commentSeqNum) => {
        setDeleteReviewCommentSeqNum(commentSeqNum);
        setDeleteReviewCommentShow(true);
    })

    const [modifyCommentShow, setModifyCommentShow] = useState(false);
    const [modifyCommentSeqNum, setModifyCommentSeqNum] = useState('');
    const [modifyCommentContent, setModifyCommentContent] = useState('');

    const openModifyCommentShow = ((seqNum, content) => {
        setModifyCommentSeqNum(seqNum);
        setModifyCommentContent(content);
        setModifyCommentShow(true);
    })

    const closeModifyCommentShow = (() => {
        setModifyCommentShow(false);
    })

    const [blockCommentShow, setBlockCommentShow] = useState(false);
    const blockCommentShowMsg1 = "선택하신 댓글을 block 처리하시겠습니까?";
    const blockCommentShowMsg2 = "진행 후 복구할 수 없습니다"

    const openBlockCommentShow = ((seqNum) => {
        setModifyCommentSeqNum(seqNum);
        setBlockCommentShow(true);
    })

    const closeBlockCommentShow = (() => {
        setBlockCommentShow(false);
    })

    const blockComment = (() => {
        const blockMsg = "관리자에 의해 댓글이 삭제되었습니다"
        modifyReviewComment(blockMsg);
    })

    const modifyReviewComment = ((commentContent) => {
        axios({
            method: 'post',
            url: '/review/modifyReviewComment',
            params: {
                commentSeqNum: modifyCommentSeqNum,
                commentContent: commentContent
            }
        })
            .then((response) => {
                const updatedComment = response.data;
                setReviewCommentList(prevComments =>
                    prevComments.map(comment =>
                        comment.seqNum === updatedComment.seqNum ? updatedComment : comment
                    )
                );
                if (modifyCommentShow) {
                    closeModifyCommentShow();
                }
                if (blockCommentShow) {
                    closeBlockCommentShow();
                }
            })
            .catch((response) => {
                console.log("코멘트 수정실패")
            })
    })

    const deleteReviewComment = () =>
        axios({
            method: 'post',
            url: '/review/deleteReviewComment',
            params: {
                commentSeqNum: deleteReviewCommentSeqNum
            }
        })
            .then((response) => {
                const deletedCommentSeqNum = response.data.seqNum;
                setReviewCommentList(prevComments =>
                    prevComments.filter(comment => comment.seqNum !== deletedCommentSeqNum)
                );
                closeDeleteReviewCommentShow();
            })
            .catch((response) => {
                console.log("코멘트 삭제실패")
            })

    return(
        <div className="AdminReviewPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">후기상세</p>
            </div>
            {/*<div className="admin-work-title">*/}
            {/*    <h5>후기상세</h5>*/}
            {/*</div>*/}
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card" style={{padding:"20px"}}>
                    {/*복붙*/}
                    <div style={{
                        border: "solid",
                        width: "65%",
                        margin: "auto",
                        borderWidth: "thin 1px",
                        borderColor: "#EEEEEE",
                        borderRadius: "4px",
                        padding: "20px"
                    }}>
                        {/*헤더 컨테이너*/}
                        <div style={{height: "80px", display: "flex", marginBottom:"-20px"}}>
                            <div style={{width: "10%"}}>
                                <div style={{borderRadius: "100%", height: "100%", width: "90%"}}><img alt="profileImg" style={{
                                    width: "90%",
                                    height: "90%",
                                    borderRadius: "100%"
                                }} src={reviewProfileImg}/></div>
                            </div>
                            <div style={{width: "90%", height: "100%"}}>
                                <div style={{height: "40%", display: "flex", color: "dimgrey", alignItems:"center"}}><span
                                    style={{paddingRight: "10px", fontSize: "15px"}}>{reviewData?.memberDTO?.userName}</span>
                                    <span style={{
                                        paddingRight: "10px",
                                        color: "dimgrey",
                                        fontSize: "14px",
                                        marginLeft:"10px",
                                    }}>조회수 {reviewData?.views}</span> <span
                                        style={{marginLeft: "auto"}}>

                                <Link to={"/btcorpad/adminreview"}>
                                <i style={{display:"flex", justifyContent:"center", alignItems:"center", fontSize:"25px", cursor:"pointer"}}><BiArrowBack/></i>
                                    </Link>
                        </span></div>

                                <div style={{height: "60%", display: "flex", justifyContent: "space-between", paddingTop:"5px"}}>
                                    <p style={{fontSize: "16px"}}>[{reviewData?.country}-{reviewData?.shipco}]<span style={{marginLeft:"5px"}}>{reviewData?.title}</span></p>
                                    <div style={{display:"flex", marginTop:"3px"}}>
                                        <p onClick={moveToModify} style={{margin:"0", fontSize:"15px", color:"#7C9EDC", cursor:"pointer"}}>수정</p>
                                        <p onClick={()=> setDeleteReviewShow(true)} style={{margin:"0", fontSize:"15px", marginLeft:"10px", color:"#EB939D", cursor:"pointer"}}>삭제</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*컨텐츠 컨테이너*/}
                        <div style={{borderTop: "solid", marginTop:"30px", borderWidth:"thin 1px", borderColor:"lightgray"}}>
                            <div style={{minHeight:"80px", display:"flex", alignItems:"center", padding:"20px 0"}}>
                                <p style={{margin:"0", fontSize:"14px", height:"100%", fontWeight:"lighter"}} dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                            </div>
                            <div style={{display:"flex", flexWrap:"wrap"}}>
                                {reviewList.map((photo, index) => (
                                    <div key={index} style={{width: "calc(52% - 20px)", height:"380px", boxSizing: "border-box", padding:"5px"}}>
                                        <img src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + photo.fileNm} alt="reviewImg"
                                             style={{width:"100%", height:"100%", borderRadius:"4px"}}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{marginTop:"50px"}}>
                            <div style={{height:"60px", display:"flex", alignItems:"center"}}>
                                <img style={{width:"25px", height:"25px", marginRight:"5px", opacity:"0.7"}} alt="CommentImage" src={reviewComment}/>
                                <p style={{margin:"0", fontSize:"16px", fontWeight:"bold"}}>Comment</p>
                                <p style={{margin:"0", color:"darkorange", fontSize:"18px", fontWeight:"bold", marginLeft:"5px"}}>{reviewCommentList.length}</p>
                            </div>
                            {reviewCommentList.map((comment, i) => (
                                <div key={i} style={{borderTop:"solid", borderWidth:"thin 1px", borderColor:"#EEEEEE"}}>
                                    <div style={{width:"100%", height:"70px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <img style={{width:"35px",height:"35px", borderRadius:"100%"}}
                                                 src={comment?.memberDTO?.profileDTO?.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/"
                                                     + comment?.memberDTO?.profileDTO?.filename : defaultImg} alt="reviewImg"/>
                                            <p style={{margin:"0", marginLeft:"10px"}}>{comment?.memberDTO?.userName}</p>
                                        </div>
                                        <div className="user-center">
                                            <div className="user-center" style={{marginRight:"10px"}}>
                                                { comment.memberDTO.role === '0' ?
                                                    <img onClick={() => {
                                                        openBlockCommentShow(comment.seqNum)
                                                    }} style={{
                                                        width: "25px",
                                                        height: "25px",
                                                        opacity: "0.8",
                                                        cursor: "pointer",
                                                        marginRight: "5px"
                                                    }} alt="blockImg" src={warnIcon}/>
                                                    :
                                                    null
                                                }
                                                <p onClick={()=> openModifyCommentShow(comment.seqNum, comment.content)} style={{margin:"0", fontSize:"13px", color:"#7C9EDC", cursor:"pointer"}}>수정</p>
                                                <p onClick={()=> openDeleteReviewCommentShow(comment.seqNum)} style={{margin:"0", fontSize:"13px", marginLeft:"5px", color:"#EB939D", cursor:"pointer"}}>삭제</p>
                                            </div>
                                            <p style={{margin:"0", fontSize:"12px"}}>{formatDate(comment.regDate)}</p>
                                        </div>
                                    </div>
                                    <div style={{width:"100%", minHeight:"40px", display:"flex", alignItems:"center", padding:"5px", marginBottom:"5px"}}>
                                        <p style={{margin:"0", fontSize:"13px"}} dangerouslySetInnerHTML={{ __html: sanitizeHTML(comment?.content) }}></p>
                                    </div>
                                </div>
                            ))}
                            {LCAT ?
                                <div style={{
                                    marginTop: "30px",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                        <textarea
                            ref={textareaRef}
                            spellCheck="false"
                            value={reviewCommentText}
                            maxLength={5000}
                            style={{
                                minHeight: "30px",
                                maxHeight: "200px",
                                borderRadius: "6px",
                                width: "89%",
                                borderWidth: "thin 1px",
                                borderColor: "#EEEEEE",
                                resize: "none",
                                overflow: "auto",
                                outline: "none",
                                color: "dimgrey",
                                padding: "10px",
                                fontSize: "14px",
                                ':focus': {
                                    borderColor: "#EEEEEE"
                                }
                            }} onChange={handleCommentTextChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault(); // To prevent the default action of the Enter key
                                    saveReviewComment();
                                }
                            }}/>
                                    <div style={{width: "10%", display: "flex", alignItems: "flex-start"}}>
                                        <button onClick={saveReviewComment} className="colorFullBt" style={{
                                            width: "100%",
                                            height: "60px",
                                            textAlign: "center",
                                            fontSize: "15px",
                                            opacity: "0.6"
                                        }}>등록
                                        </button>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            { deleteReviewShow ?
            <MsgSelectModal deleteReviewShow={deleteReviewShow} deleteReviewShowMsg1={deleteReviewShowMsg1} deleteReviewShowMsg2={deleteReviewShowMsg2}
                            closeDeleteReviewShow={closeDeleteReviewShow} deleteReview={deleteReview}/>
                :
                deleteReviewCommentShow ?
                    <MsgSelectModal deleteReviewCommentShow={deleteReviewCommentShow} deleteReviewCommentShowMsg1={deleteReviewCommentShowMsg1}
                                    deleteReviewCommentShowMsg2={deleteReviewCommentShowMsg2} closeDeleteReviewCommentShow={closeDeleteReviewCommentShow}
                                    deleteReviewComment={deleteReviewComment} deleteReviewCommentSeqNum={deleteReviewCommentSeqNum}/>
                    :
                    blockCommentShow ?
                        <MsgSelectModal blockCommentShow={blockCommentShow} blockCommentShowMsg1={blockCommentShowMsg1}
                                        blockCommentShowMsg2={blockCommentShowMsg2} blockComment={blockComment} closeBlockCommentShow={closeBlockCommentShow}/>
                        :
                    modifyCommentShow ?
                        <AdminReviewCommentModifyPg modifyCommentContent={modifyCommentContent}
                                                    closeModifyCommentShow={closeModifyCommentShow} modifyReviewComment={modifyReviewComment}/>
                        :
                null
            }


        </div>
    )
}

export default AdminReviewDetailPg
