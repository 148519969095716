import "../../css/mobile/MLoginPg.css";
import Logo from "../../assets/img/HeaderLogo.png";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from '../../services/axiosInstance';
import i18n from "../../i18n";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import SelectLangModal from "../../components/web/modal/SelectLangModal";
import MCommonTermsPopup from "../../components/mobile/MCommonTermsPopup";
import BtorageLogo from "../../assets/img/BtorageLogo.png";
import appleLogo from "../../assets/img/appleLogo.png";
import naverLogo from "../../assets/img/naver_join_logo.png";
import googleLogo from "../../assets/img/googleLogo.png";
import kakaoLogo from '../../assets/img/kakakoSymbol.png'
import facebookLogo from '../../assets/img/facebookLogo.png'
import MMsgCheckModal from "../../components/mobile/MMsgCheckModal";
import EmailInputPopup from "../../components/web/modal/EmailInputPopup";
import enMotionBanner from "../../assets/gif/AppInducingBannerEn.gif";
import jaMotionBanner from "../../assets/gif/AppInducingBannerJa.gif";
import zhMotionBanner from "../../assets/gif/AppInducingBannerZh.gif";
import idMotionBanner from "../../assets/gif/AppInducingBannerId.gif";
import krMotionBanner from "../../assets/gif/AppInducingBannerKr.gif";

const MLoginPg = () => {

    const {t} = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();

    const {state} = useLocation();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const [userOutErrorMsg, setUserOutErrorMsg] = useState(false);
    const [mailDuplErrorMsg, setMailDuplErrorMsg] = useState(false);
    const [accountTypeErrorMsg, setAccountTypeErrorMsg] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState(false);

    const [autoLogin, setAutoLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNaverLoading, setIsNaverLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isAppleLoading, setIsAppleLoading] = useState(false);
    const [isKakaoLoading, setIsKakaoLoading] = useState(false);
    const [isFacebookLoading, setIsFacebookLoading] = useState(false);


    const handleChange_email = (e) => {
        setId(e.target.value);
        setLoginErrorMsg(false);
    }
    const handleChange_password = (e) => {
        setPassword(e.target.value);
        setLoginErrorMsg(false);
    }

    const closeLoginErrorMsg = () => {
        setLoginErrorMsg(false);
    }
    const closeMailDuplErrorMsg = () => {
        setMailDuplErrorMsg(false);
    }
    const closeUserOutErrorMsg = () => {
        setUserOutErrorMsg(false);
    }
    const closeAccountTypeErrorMsg = () => {
        setAccountTypeErrorMsg(false);
    }

    // 체크하면 LCAT도 받고 로그인 유지
    const handleAutoLogin = (e) => {
        if (e.target.checked)
            setAutoLogin(true)
        else setAutoLogin(false)
    }

    // 로그인 비밀번호 양식에서 엔터누르면 로그인 버튼 클릭
    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            LoginCheck().then();
        }
    };

    // "2022-12-28T21:15:07.109Z"  // 좌측이 몇번째 자리까지 가져올건지, 우측이 시작위치
    // 앞에서 네글자 가져오기 이런식으로 년/월/일 가져오고, 중간에 하이픈 및 : 없애기
    const dateToString = JSON.stringify(new Date())
    const year = dateToString.substring(1, 5) * 60 * 60 * 24 * 365; // 2022년
    const month = dateToString.substring(8, 6) * 60 * 60 * 24 * 30; // 12월
    const day = dateToString.substring(11, 9) * 60 * 60 * 24; // 29일
    const hours = dateToString.substring(14, 12) * 60 * 60; // 07시
    const minutes = dateToString.substring(17, 15) * 60; // 39분
    const seconds = dateToString.substring(20, 18) * 1; // 20초
    const atTime = year + month + day + hours + minutes + seconds; // 2022-12-29 // 07:39:20

    // ■■■■■■■■■■■■■■ 비토리지 로그인
    async function LoginCheck() {
        if (id === "" || password === "") {
            setLoginErrorMsg(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get("/member/userOutFl/" + id, {
                params: {
                    id: id,
                    password: password
                }
            });
            login(response.data);
        } catch (error) {
            setIsLoading(false);
            setLoginErrorMsg(true);
        }
    }

    const sendAccessInfo = async (deviceType, id) => {
        try {
            const response = await axios.post('/init/accessInfo', {
                userId: id,
                accessTime: new Date().toISOString(),
                machFl: deviceType
            });
        } catch (error) {
            console.error(error);
        }
    };

    const determineMachFl = () => {
        if (window.navigator.userAgent.includes('ReactNative')) {
            return 'A'; //App
        } else if (window.navigator.userAgent.includes('Mobi')) {
            return 'M'; //웹앱
        } else {
            return 'P'; //PC
        }
    };

    const login = async (res) => {
        if (res === "Y") {
            setIsLoading(false);
            console.log('이용이 정지된 계정입니다');
            setUserOutErrorMsg(true);
        } else if (res === "N") {
            try {
                const response = await axios.post("/member/login", {
                    id: id,
                    password: password,
                    loginPath: location.pathname,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                const data = response.data;
                if (data.loginErrorType === '0') {
                    setIsLoading(false);
                    console.log('이용이 정지된 계정입니다');
                    setUserOutErrorMsg(true);
                    return;
                } else if (data.loginErrorType === '1') {
                    setIsLoading(false);
                    console.log('다른 수단으로 가입된 계정입니다');
                    setMailDuplErrorMsg(true);
                    return;
                } else if (data.loginErrorType === '2') {
                    setIsLoading(false);
                    console.log('계정 유형이 다릅니다');
                    setAccountTypeErrorMsg(true);
                    return;
                }

                const deviceType = determineMachFl();
                await sendAccessInfo(deviceType, id);
                const currentTime = new Date();
                localStorage.setItem('AToken', response.headers.get('Authorization'));
                localStorage.setItem('lastLoginTime', currentTime);
                // 로그인 전 페이지로 이동
                let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                sessionStorage.removeItem("redirectAfterLogin");
                navigate(redirectTo);
            } catch (error) {
                setIsLoading(false);
                setLoginErrorMsg(true);
            }
        }
    }

    // 언어선택
    const [selectLangSt, setSelectLangSt] = useState(false);
    const selectLangTrueFn = (() => {
        setSelectLangSt(true);
    })
    const selectLangFalseFn = (() => {
        setSelectLangSt(false);
    })
    const handleSelectLangChange = (lang) => {
        setSelectLangSt(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    useEffect(() => {
        selectedGoogleId();
        selectedNaverId();
        selectedAppleId();
        selectedKakaoId();
        selectedFacebookId();
    }, [])


    // const getPlatform = () => {
    //     if (typeof navigator !== "undefined") {
    //         // 📌 웹앱(PWA) 감지
    //         const isStandalone = window.matchMedia("(display-mode: standalone)").matches || navigator.standalone;
    //
    //         // 📌 최신 브라우저에서 userAgentData 사용
    //         if (navigator.userAgentData) {
    //             const platform = navigator.userAgentData.platform.toLowerCase();
    //             if (platform.includes("android")) return isStandalone ? "android" : "web";
    //             if (platform.includes("ios") || platform.includes("mac")) return isStandalone ? "ios" : "web";
    //         }
    //
    //         // 📌 userAgent 기반 대체 방법
    //         const userAgent = navigator.userAgent.toLowerCase();
    //         if (userAgent.includes("android")) return isStandalone ? "android" : "web";
    //         if (/iphone|ipad|ipod/.test(userAgent)) return isStandalone ? "ios" : "web";
    //     }
    //     return "web";
    // };

    const getPlatform = () => {
        return "web";
    };

    // ■■■■■■■■■■■■■■ 구글로그인
    const [googleId, setGoogleId] = useState(null);
    const generateGoogleState = async () => {
        await resetSosialState();
        const platform = 'web';
        const stateValue = generateState();
        const channel = 'goo';
        await sessionStorage.setItem('googleState', stateValue + channel)
        return platform + stateValue + channel;
    };

    const openGoogleLoginPopup = async () => {
        if (!googleId) {
            console.error("Selected Google Id Error");
            return;
        }
        try {
            setIsGoogleLoading(true);
            const state = await generateGoogleState();
            const redirectUri = `https://btorage.com/api/externalApi/googleRedirect`;
            window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${googleId}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20email%20profile&state=${state}`;
        } catch (error) {
            console.log("구글링크 팝업 오류 : ", error)
            setIsGoogleLoading(false);
            setLoginErrorMsg(true);
        }
    };

    const selectedGoogleId = async () => {
        try {
            const response = await axios.get('/credentials/secretManager/googleId');
            setGoogleId(response.data);
        } catch (error) {
            console.error('Failed to fetch Google ID:', error);
        }
    };

    const googleLogin = async (authorizationCode, state) => {
        try {
            const response = await axios.post('/externalApi/googleLogin', {
                authorizationCode:authorizationCode,
                state:state,
            });
            const data = response.data;
            if (data.loginErrorType === '0') {
                setIsGoogleLoading(false);
                console.log('이용이 정지된 계정입니다');
                setUserOutErrorMsg(true);
                return;
            } else if (data.loginErrorType === '1') {
                setIsGoogleLoading(false);
                console.log('다른 수단으로 가입된 계정입니다');
                setMailDuplErrorMsg(true);
                return;
            }

            const currentTime = new Date();
            localStorage.setItem('AToken', response.headers['authorization']);
            localStorage.setItem('lastLoginTime', currentTime);
            const userEmail = data?.email;
            const deviceType = determineMachFl();
            await sendAccessInfo(deviceType, userEmail);

            const googleEmailPrivateFl = userEmail.endsWith('@privaterelay.googleid.com'); // 구글임의메일
            if (googleEmailPrivateFl) {
                // 메일입력양식 팝업띄우기
                openMailInputPopup(data?.mbNum, data?.email);
            } else {
                // 로그인 전 페이지로 이동
                let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                sessionStorage.removeItem("redirectAfterLogin");
                navigate(redirectTo);
            }
        } catch (error) {
            console.error('Google Login Error:', error);
            setLoginErrorMsg(true);
            setIsGoogleLoading(false);
        } finally {
            setIsGoogleLoading(false);
        }
    };

    // ■■■■■■■■■■■■■■ 네이버로그인
    const [naverId, setNaverId] = useState(null);
    const generateNaverState = async () => {
        await resetSosialState();
        const platform = 'web';
        const stateValue = generateState();
        const channel = 'nav';
        await sessionStorage.setItem('naverState', stateValue + channel)
        return platform + stateValue + channel;
    };

    const openNaverLoginPopup = async () => {
        if (!naverId) {
            console.error("Selected Naver Id Error");
            return;
        }
        try {
            setIsNaverLoading(true);
            const state = await generateNaverState();
            const redirectUri = `https://btorage.com/api/externalApi/naverRedirect`;
            window.location.href = `https://nid.naver.com/oauth2.0/authorize?client_id=${naverId}&redirect_uri=${redirectUri}&response_type=code&state=${state}`;
        } catch (error) {
            console.log("네이버링크 팝업 오류 : ", error)
            setIsNaverLoading(false);
            setLoginErrorMsg(true);
        }
    };

    const selectedNaverId = async () => {
        try {
            const response = await axios.get('/credentials/secretManager/naverId');
            setNaverId(response.data);
        } catch (error) {
            console.error('Failed to fetch Apple ID:', error);
        }
    };

    const naverLogin = async (authorizationCode, state) => {
        try {
            const response = await axios.post('/externalApi/naverLogin', {
                authorizationCode:authorizationCode,
                state:state,
            });
            const data = response.data;
            if (data.loginErrorType === '0') {
                setIsNaverLoading(false);
                console.log('이용이 정지된 계정입니다');
                setUserOutErrorMsg(true);
                return;
            } else if (data.loginErrorType === '1') {
                setIsNaverLoading(false);
                console.log('다른 수단으로 가입된 계정입니다');
                setMailDuplErrorMsg(true);
                return;
            }

            const currentTime = new Date();
            localStorage.setItem('AToken', response.headers['authorization']);
            localStorage.setItem('lastLoginTime', currentTime);
            const userEmail = data?.email;
            const deviceType = determineMachFl();
            await sendAccessInfo(deviceType, userEmail);

            const naverEmailPrivateFl = userEmail.endsWith('@privaterelay.naverid.com'); // 네이버임의메일
            if (naverEmailPrivateFl) {
                // 메일입력양식 팝업띄우기
                openMailInputPopup(data?.mbNum, data?.email);
            } else {
                // 로그인 전 페이지로 이동
                let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                sessionStorage.removeItem("redirectAfterLogin");
                navigate(redirectTo);
            }
        } catch (error) {
            console.error('Naver Login Error:', error);
            setLoginErrorMsg(true);
            setIsNaverLoading(false);
        } finally {
            setIsNaverLoading(false);
        }
    };


    // 공통 상태코드 생성기
    const generateState = () => {
        const today = new Date();
        const year = today.getFullYear().toString().slice(2); // 연도 마지막 2자리
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 월 (2자리)
        const day = today.getDate().toString().padStart(2, '0'); // 일 (2자리)

        const dateString = `${year}${month}${day}`; // yyMMdd 형식
        const randomLong = Math.floor(Math.random() * 10_000); // 4자리 랜덤 숫자
        const randomNumber = randomLong.toString().padStart(10, '0');
        return dateString + randomNumber;
    }

    // 공통 리다이렉트 리스너 수신감지
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const authorizationCode = queryParams.get('code');
        const returnedState = queryParams.get('state');

        try {
            const appleState = sessionStorage.getItem('appleState');
            const googleState = sessionStorage.getItem('googleState');
            const facebookState = sessionStorage.getItem('facebookState');
            const naverState = sessionStorage.getItem('naverState');
            const kakaoState = sessionStorage.getItem('kakaoState');

            // 페북같은 Oauth 예전 버전들은 state 반환할 때 뒤에 특수문자가 붙을 수도 있음
            const cleanReturnedState = (returnedState || '').match(/\d{16}(fac|goo|app|nav|kak)/)?.[0];

            if (authorizationCode && cleanReturnedState) {
                if (appleState && appleState.endsWith('app')) {
                    if (cleanReturnedState === appleState) {
                        appleLogin(authorizationCode, appleState);
                    } else {
                        console.error('Apple Status Mismatch Error');
                        setLoginErrorMsg(true);
                        setIsAppleLoading(false);
                    }
                } else if (googleState && googleState.endsWith('goo')) {
                    if (cleanReturnedState === googleState) {
                        googleLogin(authorizationCode, googleState);
                    } else {
                        console.error('Google Status Mismatch Error');
                        setLoginErrorMsg(true);
                        setIsGoogleLoading(false);
                    }
                } else if (facebookState && facebookState.endsWith('fac')) {
                    if (cleanReturnedState === facebookState) {
                        facebookLogin(authorizationCode, facebookState);
                    } else {
                        console.error('Facebook Status Mismatch Error');
                        setLoginErrorMsg(true);
                        setIsFacebookLoading(false);
                    }
                } else if (naverState && naverState.endsWith('nav')) {
                    if (cleanReturnedState === naverState) {
                        naverLogin(authorizationCode, naverState);
                    } else {
                        console.error('Naver Status Mismatch Error');
                        setLoginErrorMsg(true);
                        setIsNaverLoading(false);
                    }
                } else if (kakaoState && kakaoState.endsWith('kak')) {
                    if (cleanReturnedState === kakaoState) {
                        kakaoLogin(authorizationCode, kakaoState);
                    } else {
                        console.error('Kakao Status Mismatch Error');
                        setLoginErrorMsg(true);
                        setIsKakaoLoading(false);
                    }
                }
            }
        } catch (error) {
            console.error('Error handling deep link:', error);
            setLoginErrorMsg(true);
            setIsAppleLoading(false);
            setIsGoogleLoading(false);
            setIsFacebookLoading(false);
            setIsNaverLoading(false);
            setIsKakaoLoading(false);
        }
    }, []);

    // 공통 snsState 스토리지 정리
    const resetSosialState = async () => {
        await sessionStorage.removeItem('appleState');
        await sessionStorage.removeItem('googleState');
        await sessionStorage.removeItem('facebookState');
        await sessionStorage.removeItem('naverState');
        await sessionStorage.removeItem('kakaoState');
    }



    // ■■■■■■■■■■■■■■ 애플로그인
    const [appleId, setAppleId] = useState(null);
    const generateAppleState = async () => {
        await resetSosialState();
        const platform = 'web';
        const stateValue = generateState();
        const channel = 'app';
        await sessionStorage.setItem('appleState', stateValue + channel)
        return platform + stateValue + channel;
    };

    const openAppleLoginPopup = async () => {
        if (!appleId) {
            console.error("Apple ID not loaded yet.");
            return;
        }
        try {
            setIsAppleLoading(true);
            const state = await generateAppleState();
            window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${appleId}&response_type=code&scope=email%20name&redirect_uri=https://btorage.com/api/externalApi/appleRedirect&state=${state}&response_mode=form_post`;
        } catch (error) {
            console.log("애플링크 팝업 오류 : ", error)
            setIsAppleLoading(false);
            setLoginErrorMsg(true);
        }
    };

    const selectedAppleId = async () => {
        try {
            const response = await axios.get('/credentials/secretManager/appleId');
            setAppleId(response.data);
        } catch (error) {
            console.error('Failed to fetch Apple ID:', error);
        }
    };

    const [mailInputPopupFl, setMailInputPopupFl] = useState(false);
    const [mbNum, setMbNum] = useState(null);
    const [joinMail, setJoinMail] = useState(null);
    const openMailInputPopup = (mbNum, mail) => {
        setMbNum(mbNum);
        setJoinMail(mail);
        setMailInputPopupFl(true);
    }
    const closeMailInputPopup = async () => {
        setMailInputPopupFl(false);
        await closeAllSocialLoading();
        // 로그인 전 페이지로 이동
        let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
        sessionStorage.removeItem("redirectAfterLogin");
        navigate(redirectTo);
    }
    const closeAllSocialLoading = () => {
        setIsGoogleLoading(false);
        setIsNaverLoading(false);
        setIsAppleLoading(false);
        setIsKakaoLoading(false);
        setIsFacebookLoading(false);
    }

    const appleLogin = async (authorizationCode, state) => {
        try {
            const response = await axios.post('/externalApi/appleLogin', {
                authorizationCode:authorizationCode,
                state:state,
            });

            const data = response.data;
            if (data.loginErrorType === '0') {
                setIsAppleLoading(false);
                console.log('이용이 정지된 계정입니다');
                setUserOutErrorMsg(true);
                return;
            } else if (data.loginErrorType === '1') {
                setIsAppleLoading(false);
                console.log('다른 수단으로 가입된 계정입니다');
                setMailDuplErrorMsg(true);
                return;
            }

            const currentTime = new Date();
            localStorage.setItem('AToken', response.headers['authorization']);
            localStorage.setItem('lastLoginTime', currentTime);
            const userEmail = data?.email;
            const appleEmailPrivateFl = userEmail.endsWith('@privaterelay.appleid.com'); // 애플임의메일
            const deviceType = determineMachFl();
            await sendAccessInfo(deviceType, userEmail);

            if (appleEmailPrivateFl) {
                // 메일입력양식 팝업띄우기
                openMailInputPopup(data?.mbNum, data?.email);
            } else {
                // 로그인 전 페이지로 이동
                let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                sessionStorage.removeItem("redirectAfterLogin");
                navigate(redirectTo);
            }

        } catch (error) {
            console.error('Apple Login Error:', error);
            setLoginErrorMsg(true);
            setIsAppleLoading(false);
        } finally {
            setIsAppleLoading(false);
        }
    };

    // ■■■■■■■■■■■■■■ 카카오로그인
    const [kakaoId, setKakaoId] = useState(null);
    const generateKakaoState = async () => {
        await resetSosialState();
        const platform = 'web';
        const stateValue = generateState();
        const channel = 'kak';
        await sessionStorage.setItem('kakaoState', stateValue + channel)
        return platform + stateValue + channel;
    };

    const openKakaoLoginPopup = async () => {
        if (!kakaoId) {
            console.error("Selected Kakao Id Error");
            return;
        }
        try {
            setIsKakaoLoading(true);
            const state = await generateKakaoState();
            const redirectUri = `https://btorage.com/api/externalApi/kakaoRedirect`;
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoId}&redirect_uri=${redirectUri}&response_type=code&state=${state}`;
        } catch (error) {
            console.log("카카오링크 팝업 오류 : ", error)
            setIsKakaoLoading(false);
            setLoginErrorMsg(true);
        }
    };

    const selectedKakaoId = async () => {
        try {
            const response = await axios.get('/credentials/secretManager/kakaoId');
            setKakaoId(response.data);
        } catch (error) {
            console.error('Failed to fetch Apple ID:', error);
        }
    };

    const kakaoLogin = async (authorizationCode, state) => {
        try {
            const response = await axios.post('/externalApi/kakaoLogin', {
                authorizationCode:authorizationCode,
                state:state,
            });

            const data = response.data;
            if (data.loginErrorType === '0') {
                setIsKakaoLoading(false);
                console.log('이용이 정지된 계정입니다');
                setUserOutErrorMsg(true);
                return;
            } else if (data.loginErrorType === '1') {
                setIsKakaoLoading(false);
                console.log('다른 수단으로 가입된 계정입니다');
                setMailDuplErrorMsg(true);
                return;
            }

            const currentTime = new Date();
            localStorage.setItem('AToken', response.headers['authorization']);
            localStorage.setItem('lastLoginTime', currentTime);
            const userEmail = data?.email;
            const deviceType = determineMachFl();
            await sendAccessInfo(deviceType, userEmail);

            const kakaoEmailPrivateFl = userEmail.endsWith('@privaterelay.kakaoid.com'); // 카카오임의메일
            if (kakaoEmailPrivateFl) {
                // 메일입력양식 팝업띄우기
                openMailInputPopup(data?.mbNum, data?.email);
            } else {
                // 로그인 전 페이지로 이동
                let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                sessionStorage.removeItem("redirectAfterLogin");
                navigate(redirectTo);
            }
        } catch (error) {
            console.error('Kakao Login Error:', error);
            setLoginErrorMsg(true);
            setIsKakaoLoading(false);
        } finally {
            setIsKakaoLoading(false);
        }
    };

    // ■■■■■■■■■■■■■■ 페이스북로그인
    const [facebookId, setFacebookId] = useState(null);
    const generateFacebookState = async () => {
        await resetSosialState();
        const platform = 'web';
        const stateValue = generateState();
        const channel = 'fac';
        await sessionStorage.setItem('facebookState', stateValue + channel)
        return platform + stateValue + channel;
    };

    const openFacebookLoginPopup = async () => {
        if (!facebookId) {
            console.error("Selected Facebook Id Error");
            return;
        }
        try {
            setIsFacebookLoading(true);
            const state = await generateFacebookState();
            const redirectUri = `https://btorage.com/api/externalApi/facebookRedirect`;
            window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${facebookId}&redirect_uri=${redirectUri}&state=${state}&scope=email,public_profile`;
        } catch (error) {
            console.log("페북링크 팝업 오류 : ", error)
            setIsFacebookLoading(false);
            setLoginErrorMsg(true);
        }
    };

    const selectedFacebookId = async () => {
        try {
            const response = await axios.get('/credentials/secretManager/facebookId');
            setFacebookId(response.data);
        } catch (error) {
            console.error('Failed to fetch Facebook ID:', error);
        }
    };

    const facebookLogin = async (authorizationCode, state) => {
        try {
            const response = await axios.post('/externalApi/facebookLogin', {
                authorizationCode:authorizationCode,
                state:state,
            });

            const data = response.data;
            if (data.loginErrorType === '0') {
                setIsFacebookLoading(false);
                console.log('이용이 정지된 계정입니다');
                setUserOutErrorMsg(true);
                return;
            } else if (data.loginErrorType === '1') {
                setIsFacebookLoading(false);
                console.log('다른 수단으로 가입된 계정입니다');
                setMailDuplErrorMsg(true);
                return;
            }

            const currentTime = new Date();
            localStorage.setItem('AToken', response.headers['authorization']);
            localStorage.setItem('lastLoginTime', currentTime);
            const userEmail = data?.email;
            const deviceType = determineMachFl();
            await sendAccessInfo(deviceType, userEmail);

            const facebookEmailPrivateFl = userEmail.endsWith('@privaterelay.facebookid.com'); // 페이스북 메일비공개
            if (facebookEmailPrivateFl) {
                // 메일입력양식 팝업띄우기
                openMailInputPopup(data?.mbNum, data?.email);
            } else {
                // 로그인 전 페이지로 이동
                let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                sessionStorage.removeItem("redirectAfterLogin");
                navigate(redirectTo);
            }

        } catch (error) {
            console.error('Facebook Login Error:', error);
            setLoginErrorMsg(true);
            setIsFacebookLoading(false);
        } finally {
            setIsFacebookLoading(false);
        }
    };



    const moveToIdPwFindPg = (() => {
        navigate("/UserIdPwFindPg");
    })

    const moveToJoinPg = (() => {
        navigate("/UserJoinPg");
    })

    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const closeTermsOfUsePopup = (() => {
        setTermsOfUsePopup(false);
    })

    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);
    const closePrivacyPolicyPopup = (() => {
        setPrivacyPolicyPopup(false);
    })

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsExpanded((prev) => !prev);
        }, 1300);
        return () => clearInterval(interval);
    }, []);

    const [currentLang, setCurrentLang] = useState(null);
    const currentLangUpdate = (() => {
        setCurrentLang(i18n.language || 'ko')
    })

    useEffect(() => {
        currentLangUpdate();
    }, [i18n.language]);

    const ua = navigator.userAgent;
    const isIOS = /iPhone|iPad|iPod/.test(ua) || (ua.includes('Macintosh') && 'ontouchend' in document);
    const isAndroid = !isIOS && /Android/i.test(ua);
    const androidScheme = 'intent://MainPg#Intent;scheme=btorage;package=com.toelmobile;end';
    const iosDeepLink = 'btorage://MainPg';
    const iosUniversalLink = 'https://btorage.com/UserIosRedirectPg';
    const iosStoreUrl = 'https://apps.apple.com/app/id6738265560';

    const handleAppLaunch = () => {
        if (isIOS) {
            const now = Date.now();
            // 사용자 이벤트로 트리거된 <a> 태그 사용
            const a = document.createElement('a');
            a.href = iosDeepLink;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();

            // fallback to App Store
            setTimeout(() => {
                const hidden = document.hidden;
                if (!hidden && Date.now() - now < 2000) {
                    window.location.href = iosStoreUrl;
                }
            }, 1500);
        } else if (isAndroid) {
            window.location.href = androidScheme;
        }
    };

    useEffect(() => {
        const setVhHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVhHeight();
        window.addEventListener('resize', setVhHeight);
        return () => window.removeEventListener('resize', setVhHeight);
    }, []);

    return (
        <div className="m-a-login-pg-container font-t-cont">
            <div className="wd-100pc text-center m-b-20">
                <img className="m-a-login-pg-logo m-b-20" src={Logo} alt="BTORAGE" onClick={() => navigate("/")}/>
            </div>

            <input className="bt-basic-input wd-100pc ht-45 m-b-5 font-s-13 m-b-10" autoComplete="nope" type={'text'}
                   placeholder={t(`loginLabel1`)} minLength="4" maxLength="36"
                   onChange={handleChange_email} onKeyDown={handleOnKeyPress}/>
            <input className="bt-basic-input wd-100pc ht-45 m-b-5 font-s-13" autoComplete="nope"
                   placeholder={t(`loginLabel2`)} type="password" minLength="8" maxLength="20"
                   onChange={handleChange_password} onKeyDown={handleOnKeyPress}/>
            {loginErrorMsg ?
                <div className="m-a-login-pg-error-message">{t(`defaultResType`)}</div> : null
            }
            {/*{userOutErrorMsg ?*/}
            {/*    <div className="m-a-login-pg-error-message">{t(`resType0`)}</div> : null*/}
            {/*}*/}
            {/*{mailDuplErrorMsg ?*/}
            {/*    <div className="m-a-login-pg-error-message">{t(`resType1`)}</div> : null*/}
            {/*}*/}
            {/*{accountTypeErrorMsg ?*/}
            {/*    <div className="m-a-login-pg-error-message">{t(`resType2`)}</div> : null*/}
            {/*}*/}
            <div className="m-a-login-pg-text-button-frame m-t-20 m-b-10 font-c-default">
                <div className='m-a-login-pg-text-button' onClick={moveToIdPwFindPg}>{t(`loginRePw`)}</div>
                <div className='m-a-login-pg-vertical-divider'/>
                <div className='m-a-login-pg-text-button' onClick={moveToJoinPg}>{t(`loginJoin`)}</div>
            </div>
            {/*■■■■■■■■■■■■■■ 비토리지로그인*/}
            <button className="bt-btn-B-B wd-100pc ht-45 font-s-16 m-b-5" onClick={LoginCheck}>
                {isLoading ? (
                    <div className="spinner m-0-a"></div>
                ) : (
                    <p>Login</p> /*로그인*/
                )}
            </button>

            <div className="wd-100pc display-flex flex-row just-cont-center gap25 m-t-25">
                <div className="wd-100pc m-t-20">
                    <div style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#555',
                        position: 'relative',
                        marginBottom: '20px'
                    }}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                display: 'inline-block',
                                padding: '5px 20px',
                                zIndex: 2,
                                position: 'relative',
                            }}
                        >
                            <p style={{margin: 0, fontSize: '16px', fontWeight: "lighter"}}>{t('loginMethod')}</p>
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '0',
                            right: '0',
                            height: '1px',
                            backgroundColor: '#ddd',
                            zIndex: 1,
                        }}></div>
                    </div>

                    <div className="display-flex flex-row just-cont-center gap30">
                        {/* 애플 로그인 */}
                        {isAppleLoading ? (
                            <div className="wd-45 ht-45 user-center border-rd8 border-thin">
                                <div className="orangeSpinner"></div>
                            </div>
                        ) : (
                            <button className="bt-join-btn-A wd-45 ht-45 position-relative"
                                    onClick={openAppleLoginPopup}>
                                <img className="wd-100pc ht-100pc" src={appleLogo} alt="Apple Logo"/>
                            </button>
                        )}

                        {/* 구글 로그인 */}
                        {isGoogleLoading ? (
                            <div className="wd-45 ht-45 user-center border-rd8 border-thin">
                                <div className="orangeSpinner"></div>
                            </div>
                        ) : (
                            <button className="bt-join-btn-B wd-45 ht-45 position-relative"
                                    onClick={openGoogleLoginPopup}>
                                <img className="wd-21 ht-21" src={googleLogo} alt="Google Logo"/>
                            </button>
                        )}

                        {/* 페이스북 로그인 */}
                        {isFacebookLoading ? (
                            <div className="wd-45 ht-45 user-center border-rd8 border-thin">
                                <div className="orangeSpinner"></div>
                            </div>
                        ) : (
                            <button className="bt-join-btn-A wd-45 ht-45 position-relative"
                                    onClick={openFacebookLoginPopup}
                            >
                                <img className="wd-100pc ht-100pc" src={facebookLogo} alt="Facebook Logo"/>
                            </button>
                        )}

                        {/* 네이버 로그인 */}
                        {isNaverLoading ? (
                            <div className="wd-45 ht-45 user-center border-rd8 border-thin">
                                <div className="orangeSpinner"></div>
                            </div>
                        ) : (
                            <button className="bt-join-btn-A wd-45 ht-45 position-relative"
                                    onClick={openNaverLoginPopup}>
                                <img className="wd-100pc ht-100pc" src={naverLogo} alt="Naver Logo"/>
                            </button>
                        )}

                        {/* 카카오 로그인 */}
                        {isKakaoLoading ? (
                            <div className="wd-45 ht-45 user-center border-rd8 border-thin">
                                <div className="orangeSpinner"></div>
                            </div>
                        ) : (
                            <button className="bt-join-btn-A wd-45 ht-45 position-relative"
                                    onClick={openKakaoLoginPopup} style={{backgroundColor: "#FEE500"}}
                            >
                                <img className="wd-21 ht-21" src={kakaoLogo} alt="Kakao Logo"/>
                            </button>
                        )}
                    </div>
                </div>

            </div>

            {userOutErrorMsg &&
                <MMsgCheckModal userOutErrorMsg={userOutErrorMsg} closeUserOutErrorMsg={closeUserOutErrorMsg}/>
            }
            {mailDuplErrorMsg &&
                <MMsgCheckModal mailDuplErrorMsg={mailDuplErrorMsg} closeMailDuplErrorMsg={closeMailDuplErrorMsg}/>
            }
            {accountTypeErrorMsg &&
                <MMsgCheckModal accountTypeErrorMsg={accountTypeErrorMsg}
                                closeAccountTypeErrorMsg={closeAccountTypeErrorMsg}/>
            }

            {/*앱유도 모션배너*/}
            <div className="wd-100pc display-flex flex-row just-cont-center gap25 m-t-50" onClick={handleAppLaunch}>
                <img style={{width: "100%", height: "100%"}} src={
                    currentLang === "en" ? enMotionBanner :
                        currentLang === "ja" ? jaMotionBanner :
                            currentLang === "zh" ? zhMotionBanner :
                                currentLang === "id" ? idMotionBanner : krMotionBanner
                } alt="Motion Banner"/>
            </div>

            <div className="m-a-login-pg-footer font-c-default">
                <div className='m-a-login-pg-footer-text' onClick={selectLangTrueFn}>{t(`loginLang`)}</div>
                <div className='m-a-login-pg-footer-text' onClick={() => setTermsOfUsePopup(true)}>{t(`UseInfo`)}</div>
                <div className='m-a-login-pg-footer-text'
                     onClick={() => setPrivacyPolicyPopup(true)}>{t(`infoPolicy`)}</div>
            </div>
            {selectLangSt ?
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn}
                                 handleSelectLangChange={handleSelectLangChange}/>
                :
                termsOfUsePopup ?
                    <MCommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
                    :
                    privacyPolicyPopup ?
                        <MCommonTermsPopup privacyPolicyPopup={privacyPolicyPopup}
                                           closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
                        :
                        mailInputPopupFl ?
                            <EmailInputPopup mbNum={mbNum} joinMail={joinMail}
                                             closeMailInputPopup={closeMailInputPopup}/>
                            :
                            null
            }
        </div>
    )
}

export default MLoginPg;
