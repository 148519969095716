import React, {useState, useEffect, useRef} from 'react'
import '../../../css/web/UserAddrBook.css';
import '../../../css/web/UserAddrBookModi.css';
import { HiReceiptTax } from "react-icons/hi";
import {BsArrowLeft, BsFillPersonCheckFill, BsMailbox2} from "react-icons/bs";
import {TbAddressBook} from "react-icons/tb";
import {MdPhoneIphone} from "react-icons/md";
import axios from '../../../services/axiosInstance';
import {BiCaretDown} from "react-icons/bi";
import {useTranslation} from "react-i18next";

function UserAddrBookModi({addrBookModifyBackFn, addrBookModifySt, addrBookModifyBackFn2,  addrBookAddSt, book, getBookListFn, selectedShip}) {

    const { t } = useTranslation();

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    const [taxidError, setTaxidError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");
    const [defaultCkboxSt, setDefaultCkboxSt] = useState(false);

    const address1Length = selectedShip === "EMS" || selectedShip === "EMSP" ? 200 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 200 :
            selectedShip === "Small-Packet" ? 200 :
                selectedShip === "K-Packet" ? 200 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 35 :
                            selectedShip === "SEA" ? 200 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address2Length = selectedShip === "EMS" || selectedShip === "EMSP" ? 50 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 35 :
                            selectedShip === "SEA" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address3Length = selectedShip === "EMS" || selectedShip === "EMSP" ? 50 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 50 :
                            selectedShip === "SEA" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const cityLength = selectedShip === "EMS" || selectedShip === "EMSP" ? 50 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 50 :
                            selectedShip === "SEA" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e)=> {
        const taxidRegex = /^[a-zA-Z0-9\s\-.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e)=> {
        const recnameRegex = /^[a-zA-Z\s]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e)=> {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e)=> {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e)=> {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e)=> {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e)=> {
        const zipcodeRegex = /^[a-zA-Z\d-\s]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e)=> {
        const telnumRegex = /^[\d-\s]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            if (selectedIndex < filteredCountryList.length - 1) {
                setSelectedIndex(selectedIndex + 1);
            }
        } else if (event.key === "ArrowUp") {
            if (selectedIndex > 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        } else if (event.key === "Enter") {
            if (selectedIndex >= 0) {
                handleCountryChange(filteredCountryList[selectedIndex], event);
            }
        }
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({ value: '', label: t(`ordCrLabel`) });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (countryRef.current) {
            const itemElements = countryRef.current.querySelectorAll('.selectChildBt');
            const selectedItem = itemElements[selectedIndex];

            if (selectedItem) {
                const listContainer = countryRef.current.querySelector('.country-select-data');
                const containerHeight = listContainer.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemHeight = selectedItem.clientHeight;

                if (itemTop < 0) {
                    listContainer.scrollTop = itemTop;
                } else if (itemTop + itemHeight > containerHeight) {
                    listContainer.scrollTop = itemTop + itemHeight - containerHeight;
                }
            }
        }
    }, [selectedIndex]);

    const validation = () => {
        let isValue = true;
        if (!selectedCountry.value || selectedCountry.value === t(`ordCrLabel`)) {
            setCountryError(true);
            isValue = false;
        }
        if (selectedCountry.value === "ID" && selectedShip !== 'EMS' && !taxid) {
            setTaxidError(true);
            isValue = false;
        }
        if (!recname) {
            setRecnameError(true);
            isValue = false;
        }
        if (!address1) {
            setAddress1Error(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            isValue = false;
        }
        if (!telnum) {
            setTelnumError(true);
            isValue = false;
        }
        return isValue;
    }

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };
    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };
    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };
    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };
    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };
    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };
    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const getMaxLengthByTelnumCountry = () => {
        if (selectedCountry.value === "US") return 10;
        return 15;
    };

    const currentMaxLengthTelnumDisplay = `/${getMaxLengthByTelnumCountry()}`;

    const telnumValid = (e) => {
        const { value } = e.target;

        //미국 숫자만 10자리
        if (selectedCountry.value === "US") {
            if (/^\d{0,10}$/.test(value)) {
                validTextTelnum(e);
                telnumInputLengthCheck(e);
                telnumInputRTCheck(e);
            }
            return;
        }

        if (value.length <= 15) {
            validTextTelnum(e)
            telnumInputLengthCheck(e);
            telnumInputRTCheck(e)
        }
    }

    const getMaxLengthByZipcode = () => {
        if (selectedCountry.value === "US") return 5; // 미국
        if (selectedCountry.value === "CA") return 6; // 캐나다
        return 10;
    };

    const currentMaxLengthZipcodeDisplay = `/${getMaxLengthByZipcode()}`;

    const zipcodeValid = (e) => {
        const { value } = e.target;

        //미국 숫자만 5자리
        if (selectedCountry.value === "US") {
            if (/^\d{0,5}$/.test(value)) {
                validTextZipcode(e);
                zipcodeInputLengthCheck(e);
                zipcodeInputRTCheck(e);
            }
            return;
        }

        //캐나다 알파벳&숫자 6자리
        if (selectedCountry.value === "CA") {
            if (/^[a-zA-Z0-9]{0,6}$/.test(value)) {
                validTextZipcode(e);
                zipcodeInputLengthCheck(e);
                zipcodeInputRTCheck(e);
            }
            return;
        }

        if (value.length <= 10) {
            validTextZipcode(e);
            zipcodeInputLengthCheck(e);
            zipcodeInputRTCheck(e);
        }
    };

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }
    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }
    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }
    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    const errorAndSave = (seqnum) => {
        if (validation()) {
            if (seqnum !== undefined) {
                addrBookModify()
            }
            else {
                addrBookSave();
            }
        }
    }

    const defaultCkbox = (e) => {
        if(e.target.checked) {
            setDefaultCkboxSt(true);
        }
        else (
            setDefaultCkboxSt(false)
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (addrBookModifySt === true) {
                setTaxid(book.taxid)
                setRecname(book.recname);
                const countryData = {
                    value: book.countryDTO.countryCode,
                    label: book.countryDTO.countryNm + '(' + book.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData);
                setAddress1(book.address1);
                setAddress2(book.address2);
                setAddress3(book.address3);
                setCity(book.city);
                setZipcode(book.zipcode);
                setTelnum(book.telnum);
                setDefaultCkboxSt(book.defaultAddrFl)
            }
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    const addrBookSave = (()=> {
        axios({
            method: 'post',
            url: '/member/addrBookSave',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                addrBookAddSt: addrBookAddSt,
            },
            data: {
                crCode: selectedCountry.value,
                taxid: taxid,
                recname: recname,
                address1 : address1,
                address2 : address2,
                address3 : address3,
                city: city,
                zipcode: zipcode,
                telnum: telnum,
                defaultAddrFl: defaultCkboxSt,
            },
        })
            .then(()=> {
                addrBookModifyBackFn2()
                getBookListFn()
            })
    })

    const addrBookModify = (()=> {
        axios({
            method: 'post',
            url: '/member/addrBookSave',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                bookSeqnum: book.seqnum
            },
            data: {
                crCode: selectedCountry.value,
                taxid: taxid,
                recname: recname,
                address1 : address1,
                address2 : address2,
                address3 : address3,
                city: city,
                zipcode: zipcode,
                telnum: telnum,
                defaultAddrFl: defaultCkboxSt,
            },
        })
            .then(()=> {
                addrBookModifyBackFn()
                getBookListFn()
            })
    })

    return(
        <div className="bookContainer">
            <div className="bookFrame">
                {
                    addrBookModifySt === true ?
                        <div className="bookFrameHeader font-t-title">
                            <div className="headerGoBack" onClick={addrBookModifyBackFn}><i><BsArrowLeft/></i></div>
                            <div className="headerText">{t(`abModiBt`)}</div> {/*배송지수정*/}
                        </div>
                        :
                        addrBookAddSt === true ?
                            <div className="bookFrameHeader">
                                <div className="headerGoBack" onClick={addrBookModifyBackFn2}><i><BsArrowLeft/></i></div>
                                <div className="headerText font-t-title">{t(`abAddBt`)}</div> {/*배송지추가*/}
                            </div>
                            :
                            null
                }
                <div className="bookFrameBody font-t-cont">
                    <div className="modiCardFrame" style={{height: selectedCountry.value === "KR" ? "100%" : null}}>
                        {/*국가선택*/}
                        <div ref={countryRef} className="ht-50 wd-100pc c-point position-relative m-b-20" onClick={toggleCountryShow}>
                            <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                {selectedCountry.value ? (
                                    <div className="wd-15pc text-center align-center-c">
                                        <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                    </div>
                                ) : (
                                    <div className="wd-15pc text-center align-center-c p-l-5">
                                        <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                    </div>
                                )}
                                <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                       type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="nope"
                                       value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                       onChange={handleCountrySearch}
                                       onKeyDown={handleKeyDown} />
                                <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                    <BiCaretDown />
                                </i>
                            </div>
                            {countryShow && (
                                <div className="country-select-data wd-100pc position-absolute m-t-5">
                                    <div className="country-select-data-child">
                                    {filteredCountryList.map((country, index) => (
                                        <div key={index} className={`selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20 ${
                                            index === selectedIndex ? "arrow-selected" : ""
                                        }`} onClick={(event) => handleCountryChange(country, event)}>
                                            <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                            <p className="font-s-15">{country.label}</p>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        {selectedCountry.value === "ID" ?
                            <div className="modiCardName" style={{marginBottom: "10px"}}>
                                <div className="acc-input-container acc-input-icon" style={{height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 5px"}}><HiReceiptTax/></i>
                                    </div>
                                    <div className="acc-input-form">
                                        <input className="acc-input-inform" type="text" placeholder=" "
                                               style={{width: "98%", height: "100%", paddingLeft: "10px"}} value={taxid}
                                               onChange={validTextTaxid} maxLength={50}/>
                                        <label className="inputLabel"><span
                                            style={{color: "red", fontSize: "18px"}}>*</span> Tax ID</label>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {taxidError ?
                            <div className="JoinErrorMsg font-t-cont">
                                <p className="JoinErrMsg font-s-14">{t(`recTaxIdErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardName" style={{marginBottom:"10px"}}>
                            <div className="acc-input-container acc-input-icon" style={{height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 5px"}}><BsFillPersonCheckFill/></i>
                                </div>
                                <div className="acc-input-form">
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{width:"98%", height:"100%", paddingLeft:"10px"}} value={recname} onChange={nameValid} maxLength={25}/>
                                    <label className="inputLabel"><span style={{color:"red", fontSize:"18px"}}>*</span> {t(`joinLabel5`)}</label>
                                    { selectedCountry.value !== "KR" ?
                                        <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{nameInputCount}</p>
                                                <p style={{fontSize: "13px"}}>/25</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        {selectedCountry.value !== "KR" && recnameError ?
                            <div className="JoinErrorMsg font-t-cont">
                                <p className="JoinErrMsg font-s-14">{t(`recNmErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardTelnum" style={{marginBottom:"10px"}}>
                            <div className="acc-input-container acc-input-icon" style={{height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><MdPhoneIphone/></i>
                                </div>
                                <div className="acc-input-form" style={{marginTop:"5px"}}>
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{width:"98%", height:"100%", paddingLeft:"10px"}} value={telnum} onChange={telnumValid} maxLength={15}/>
                                    <label className="inputLabel"><span style={{color:"red", fontSize:"18px"}}>*</span> {t(`modNbInfo5`)}</label> {/*연락처*/}
                                    { selectedCountry.value !== "KR" ?
                                        <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{fontSize: "13px", color: "darkorange"}}>{telnumInputCount}</p>
                                                <p style={{fontSize: "13px"}}>{currentMaxLengthTelnumDisplay}</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        {selectedCountry.value !== "KR" && telnumError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{t(`recTellErr`)}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardZipcode" style={{marginBottom: "10px"}}>
                                <div className="acc-input-container acc-input-icon" style={{height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><BsMailbox2/></i>
                                    </div>
                                    <div className="acc-input-form">
                                        <input className="acc-input-inform" type="text" placeholder=" "
                                               style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                               value={zipcode} onChange={zipcodeValid} maxLength={10}/>
                                        <label className="inputLabel"><span style={{color: "red", fontSize: "18px"}}>*</span> {t(`modNbInfo8`)}</label> {/*우편번호*/}
                                        { selectedCountry.value !== "KR" ?
                                            <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                                <div className="user-center">
                                                    <p style={{fontSize: "13px", color: "darkorange"}}>{zipcodeInputCount}</p>
                                                    <p style={{fontSize: "13px"}}>{currentMaxLengthZipcodeDisplay}</p>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {zipcodeError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{t(`recZipErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardAddr1" style={{marginBottom:"10px"}}>
                            <div className="acc-input-container acc-input-icon" style={{height:"100%"}}>
                                <div className="acc-input-lg-icon" style={{width:"15%"}}>
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><TbAddressBook/></i>
                                </div>
                                <div className="acc-input-form">
                                    <input className="acc-input-inform" type="text" placeholder=" " style={{
                                        width:"84%", height:"100%", paddingLeft:"10px"}} value={address1} onChange={address1Valid} maxLength={selectedCountry.value !== "KR" ? address1Length : null}/>
                                    <label className="inputLabel"><span style={{color:"red", fontSize:"18px"}}>*</span>
                                        {selectedCountry.value === "KR" ? t(`modSmallMenu14`) : t(`modSmallMenu7`)}
                                    </label>
                                    { selectedCountry.value !== "KR" ?
                                        <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{address1InputCount}</p>
                                                <p style={{fontSize: "13px"}}>{`/${address1Length}`}</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        {selectedCountry.value !== "KR" && address1Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardAddr2" style={{marginBottom: "10px"}}>
                                <div className="acc-input-container acc-input-icon" style={{height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form">
                                        <input className="acc-input-inform" type="text" placeholder=" " style={{
                                            width: "84%", height: "100%", paddingLeft: "10px"
                                        }} value={address2} onChange={address2Valid} maxLength={address2Length}/>
                                        <label className="inputLabel">{t(`modSmallMenu8`)}</label>
                                        <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{address2InputCount}</p>
                                                <p style={{fontSize: "13px"}}>{`/${address2Length}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {address2Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardAddr3" style={{marginBottom: "10px"}}>
                                <div className="acc-input-container acc-input-icon" style={{height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form">
                                        <input className="acc-input-inform" type="text" placeholder=" " style={{
                                            width: "84%", height: "100%", paddingLeft: "10px"
                                        }} value={address3} onChange={address3Valid} maxLength="50"/>
                                        <label className="inputLabel">{t(`modSmallMenu9`)}</label>
                                        <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                            <div className="user-center">
                                                <p style={{
                                                    fontSize: "13px",
                                                    color: "darkorange"
                                                }}>{address3InputCount}</p>
                                                <p style={{fontSize: "13px"}}>{`/${address3Length}`}</p>
                                                {/*<p style={{fontSize: "13px"}}>/50</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {address3Error ?
                            <div className="JoinErrorMsg">
                                {/*<p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}</p>*/}
                                <p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~50${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardCity" style={{marginBottom: "10px"}}>
                                <div className="acc-input-container acc-input-icon" style={{height: "100%"}}>
                                    <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form">
                                        <input className="acc-input-inform" type="text" placeholder=" " style={{
                                            width: "84%", height: "100%", paddingLeft: "10px"
                                        }} value={city} onChange={cityValid} maxLength="50"/>
                                        <label className="inputLabel"><span
                                            style={{color: "red", fontSize: "18px"}}>*</span> {t(`modSmallMenu10`)}
                                        </label>
                                        { selectedCountry.value !== "KR" ?
                                            <div style={{position: "absolute", right: "5px", top: "30%"}}>
                                                <div className="user-center">
                                                    <p style={{
                                                        fontSize: "13px",
                                                        color: "darkorange"
                                                    }}>{cityInputCount}</p>
                                                    <p style={{margin: "0", fontSize: "13px"}}>{`/${cityLength}`}</p>
                                                    {/*<p style={{margin: "0", fontSize: "13px"}}>/50</p>*/}
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {cityError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        <div className="defaultAddr" style={{fontSize:"14px"}}>
                            <div className="acc-ckbox-frame wd-100pc" style={{justifyContent:"flex-end"}}>
                                <input id="defaultAddrCkbox" type="checkbox" className="m-a-admin-input-chkBox" checked={defaultCkboxSt} onChange={defaultCkbox}/>
                                <div style={{display:"flex"}}>
                                    <label htmlFor="defaultAddrCkbox" style={{marginLeft:"6px"}}>{t(`ordDfAddr`)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bookFrameFooter font-t-cont" style={{display:"flex", justifyContent:"center", marginTop:"3%"}}>
                    <button className="colorFullBt" style={{width:"90%", height:"40px", textAlign:"center", fontSize:"15px"}}
                            onClick={()=> addrBookAddSt === true ? errorAndSave() : errorAndSave(book.seqnum)}>{t(`saveBt`)}</button>
                    {/*저장*/}
                </div>
            </div>
        </div>
    )
}

export default UserAddrBookModi
