import React, {useState, useEffect, useRef, useCallback} from 'react';
import '../../css/mobile/MAdmin.css';
import HeaderLogo from "../../assets/img/btorage_textlogo_w.png";
import AdminProfileBase from "../../assets/img/AdminProfileBase_grey.png";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import ErrorPathPg from "../web/ErrorPathPg";
import MAdminOrderChatPg from "./MAdminOrderChatPg";
import {AiOutlineMessage} from "@react-icons/all-files/ai/AiOutlineMessage";
import MAdminDashBoardPg from "./MAdminDashBoardPg";
import axios from '../../services/axiosInstance';
import MAdminPaymentsListPg from "./MAdminPaymentsListPg";
import MAdminPayWaitPg from "./MAdminPayWaitPg";
import MAdminInflowStatusPg from "./MAdminInflowStatusPg";
import MAdminUserStatusPg from "./MAdminUserStatusPg";
import MAdminSalesStatusPg from "./MAdminSalesStatusPg";
import MAdminEmployeeInfoPg from "./MAdminEmployeeInfoPg";
import MAdminDepositSetPg from "./MAdminDepositSetPg";
import MAdminUserInfoPg from "./MAdminUserInfoPg";
import MAdminFAQPg from "./MAdminFAQPg";
import MAdminNoticePg from "./MAdminNoticePg";
import MAdminReviewPg from "./MAdminReviewPg";
import MAdminCancelItemPg from "./MAdminCancelItemPg";
import MAdminBuyItemPg from "./MAdminBuyItemPg";
import MAdminDepositWaitPg from "./MAdminDepositWaitPg";
import {BiLogOut, BiPencil} from "react-icons/bi";
import {BsFillSunFill, BsMoonStarsFill} from "react-icons/bs";
import MAdminModiPg from "./MAdminModiPg";
import MAdminReviewDtPg from "./MAdminReviewDtPg";
import MAdminNoticeDtPg from "./MAdminNoticeDtPg";
import MAdminNoticeModifyPg from "./MAdminNoticeModifyPg";
import MAdminFaqDtPg from "./MAdminFaqDtPg";
import MAdminOrderDetailPg from "./MAdminOrderDetailPg";
import {useWebSocket} from "../../services/WebsocketContext";
import MAdminAlarmListPg from "./MAdminAlarmListPg";
import MAdminNoticeWritePg from "./MAdminNoticeWritePg";
import MAdminFaqWritePg from "./MAdminFaqWritePg";
import MAdminFaqModifyPg from "./MAdminFaqModifyPg";

function ResetBodyClass() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.body.classList.remove("menu-open");
        document.body.style.overflow = '';
    }, [pathname]);

    return null;
}

function MAdminMainPg() {

    /** region DataTable */

    const navigate = useNavigate();

    const [userCountTotalData, setUserCountTotalData] = useState(0);
    const [userCountFlwData, setUserCountFlwData] = useState(0);
    const [userCountLossData, setUserCountLossData] = useState(0);
    const [userCountTodayData, setUserCountTodayData] = useState(0);
    const [profilePhotoLine, setProfilePhotoLine] = useState(AdminProfileBase);
    const [userProfileImg, setUserProfileImg] = useState("");
    const [userType, setUserType] = useState("");
    const [userNicName, setUserNicName] = useState("");

    /** endregion DataTable*/

    /** region Function */

    //region 다크모드

    const [theme, setTheme] = useState(() => {
            return localStorage.getItem("theme") || "light";
        });

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        localStorage.setItem("theme", theme);
    }, [theme]);

    const themeToggler = () => {
        setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    //endregion

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "업무상태를 변경하시겠습니까?";
    const msgConfirmData2 = "ON/OFF";
    const alert = (()=> {setAlertShow(true)})
    const modalCancel = useCallback (() => {setAlertShow(false);})

    const [isOpen, setIsOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('MsgBell');
    const [modalOpen, setModalOpen] = useState(false);

    const LCAT = localStorage.getItem('adminToken');

    const convertCount = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(1) + 'm';
        } else if (count >= 1000) {
            return (count / 1000).toFixed(1) + 'k';
        } else {
            return count.toString();
        }
    };

    const date = new Date();
    const week = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
    const dayWeek = week[date.getDay()];
    const DateViewValue = '' + dayWeek;

    function getUserRole(userType) {
        const roles = {
            1: "Staff",
            2: "Snr Staff",
            3: "Assistant",
            4: "Manager",
            5: "Owner",
        };

        return roles[userType] || "Unknown";
    }

    /** endregion */

    /** region Event */


    // websocket
    const [adminInfo, setAdminInfo] = useState([]);
    const selectedAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log("Admin 조회실패 : ", error)
            })
    })

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/topic/addTotalCount`, chatDataReceived);
                subscribe(`/topic/totalCount`, updatedTotalCount);
                subscribe(`/topic/followCount`, updatedFollowCount);
                subscribe(`/topic/lossCount`, updatedLossCount);
                subscribe(`/topic/todayCount`, updatedTodayCount);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/topic/addTotalCount`);
                unsubscribe(`/topic/totalCount`);
                unsubscribe(`/topic/followCount`);
                unsubscribe(`/topic/lossCount`);
                unsubscribe(`/topic/todayCount`);
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const updatedTotalCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountTotalData(message);
    })

    const updatedFollowCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountFlwData(message);
    })

    const updatedLossCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountLossData(message);
    })

    const updatedTodayCount = ((payload) => {
        const message = JSON.parse(payload.body);
        setUserCountTodayData(message);
    })

    const mainReflash = () => {
        navigate("/btcorpad");
    };

    useEffect(() => {
        userCountTotalSelect();
        userCountFlwSelect();
        userCountLossSelect();
        userCountTodaySelect();
    }, [])

    const userCountTotalSelect = (() => {
        axios({
            method: 'get',
            url: '/member/usertotalcount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountTotalData(response.data);
            })
            .catch(() => {})
    })

    const userCountFlwSelect = (() => {
        axios({
            method: 'get',
            url: '/member/userflwcount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountFlwData(response.data);
            })
            .catch(() => {})
    })

    const userCountLossSelect = (() => {
        axios({
            method: 'get',
            url: '/member/userlosscount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountLossData(response.data);
            })
            .catch(() => {})
    })
    const userCountTodaySelect = (() => {
        axios({
            method: 'get',
            url: '/init/usertodaycount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountTodayData(response.data);
            })
            .catch(() => {})
    })

    useEffect(() => {
        if (LCAT) {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT
                }
            })
                .then((response) => {
                    setUserType(response.data[0].userType)
                    setUserNicName(response.data[0].nicName)
                    setUserProfileImg(response.data[0].profileDTO)

                    if (response.data[0].profileDTO === null) {
                        setProfilePhotoLine(AdminProfileBase)
                    } else {
                        setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                    }
                })
        }
    }, [])

    const logout = () => axios({
        method: 'post',
        url: '/member/logout',
        headers: {
            Authorization: LCAT
        }
    })
        .then(() => {
            localStorage.removeItem('adminToken')
            localStorage.removeItem('adminLastLoginTime')
            navigate("/btcorpad-login")
        })
        .catch(() => {
            localStorage.removeItem('adminToken')
            localStorage.removeItem('adminLastLoginTime')
            navigate("/btcorpad-login")
        })

    const [lcChatData, setLcChatData] = useState(0);
    const selectChatCount = () => {
        axios({
            method: 'get',
            url: '/order/selectChatCount',
        })
            .then((response) => {
                alert("함수실행 : ", response.data)
                const totalChats = response.data.ordChat + response.data.nonUserChat;
                setLcChatData(totalChats > 999 ? 999 : totalChats);
                // 총채팅개수 각각 산출
                // setLcUserChatCount(response.data.userChat);
                // setLcNUserChatCount(response.data.nonUserChat);
            })
            .catch((error) => console.error(error))
    };

    useEffect(() => {
        if (LCAT) {
        selectChatCount()
        selectedAdminInfo();
        }
    }, [LCAT])

    const chatDataReceived = ((payload) => {
        let chatData = JSON.parse(payload.body);
        selectChatCount();
    })

    const [burgerActive, setBurgerActive] = useState(false);
    const [ctgMenuOpen, setCtgMenuOpen] = useState(false);

    const [profileActive, setProfileActive] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isActive, setIsActive] = useState(false);

    // const burgerClick = () => {
    //     setBurgerActive(!burgerActive);
    //     setCtgMenuOpen(!ctgMenuOpen);
    // };
    const burgerClick = () => {
        setBurgerActive(prev => {
            const newState = !prev;
            if (newState) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
            return newState;
        });

        setCtgMenuOpen(prev => !prev);
    };

    const profileClick = (e) => {
        e.stopPropagation();
        setProfileActive(!profileActive);
        profileSideMenu();
    };

    const burgerSideMenu = () => {
        const body = document.body;
        if (!isActive) {
            body.classList.add('menu-open');
        } else {
            body.classList.remove('menu-open');
        }
        setIsActive(!isActive);
    };

    const profileSideMenu = () => {
        if (!isMenuOpen) {
            document.body.classList.add('menu-open', 'no-scroll');
        } else {
            document.body.classList.remove('menu-open', 'no-scroll');
        }
        setIsMenuOpen(!isMenuOpen);
    };

    const [noApply, setNoApply] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/btcorpad/adminorderchat') {
            setNoApply(true);
        } else {
            setNoApply(false);
        }
    }, [location]);

    /** endregion */

    return (
        <div id="m-a-main-pg-container" data-theme={theme}>
            <div className="m-a-header-container">
                <div className="m-a-header-in">
                    <div className="m-a-header-in-logo p-t-8">
                        <div className="wd-130" onClick={mainReflash}>
                            {/*<Link onClick={mainReflash} to="#">*/}
                            <h1><img className="m-a-header-logo m-b-4" alt="mainLogo" src={HeaderLogo}/></h1>
                            {/*</Link>*/}
                        </div>
                    </div>
                    <div className="m-a-header-profile">
                        <div className="p-t-10 p-r-4 m-a-total-chat-frame">
                            <div onClick={()=> navigate("/btcorpad/adminorderchat")}>
                                <AiOutlineMessage className="font-s-30 font-c-fff"/>
                                {lcChatData > 0 ?
                                    <div className="m-a-total-chat-count font-t-cont">{lcChatData || 0}</div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    {/*햄버거*/}
                    <div className="m-a-header-burger p-t-15">
                        <div className={`wd-40 ht-30 toggle ${burgerActive ? 'active' : ''}`} onClick={burgerClick}>
                            <span className="wd-26 ht-2"></span>
                            <span className="wd-26 ht-2"></span>
                            <span className="wd-26 ht-2"></span>
                        </div>
                    </div>
                    <div className={`m-a-slider-ctg-container ${ctgMenuOpen ? 'open' : 'close'}`} onClick={burgerSideMenu}>
                        <div className="p-t-20">
                            <div className="m-a-s-profile1 text-center m-0-a p-b-10 font-t-title">
                                <div className={`${profileActive ? 'active m-a-s-p-profile' : 'm-a-s-p-profile'}`} onClick={profileClick}>
                                    <div className="m-a-header-profile-box m-r-10">
                                        <img className="m-a-header-profile-box-img" src={profilePhotoLine} alt=""/>
                                    </div>
                                    <a className="m-a-s-p-f3-1 font-s-24 font-c-btorage-w">{`${userNicName} : ${getUserRole(userType)}`}</a>
                                </div>
                            </div>
                            <div className="m-a-s-profile2 text-center m-0-a">
                                <div className="m-a-s-p-f3-2 font-s-18 text-active font-t-cont font-c-fff">
                                    <div>
                                        <BiPencil  className="m-b-2 m-r-3"/>
                                        <Link className="font-c-fff" to="/adminInfoModi" element={<MAdminModiPg/>}>프로필수정</Link>
                                    </div>
                                </div>
                                <div className="m-a-s-p-f3-3 font-s-18 text-active font-t-cont">
                                    <div>
                                        <BiLogOut className="m-b-4 m-r-3 font-c-fff"/>
                                        <a className="font-c-fff" onClick={logout}>로그아웃</a>
                                    </div>
                                </div>
                            </div>
                            <div className="m-a-s-profile3 m-0-a font-s-30">
                                <div className="p-r-5">
                                    <a className="font-c-fff font-s-24">{DateViewValue}&nbsp;
                                        {DateViewValue === 'Monday'}
                                        {DateViewValue === 'Tuesday'}
                                        {DateViewValue === 'Wednesday'}
                                        {DateViewValue === 'Thursday'}
                                        {DateViewValue === 'Friday'}
                                    </a>
                                </div>
                                <div className="p-l-5">
                                    <a className="" onClick={themeToggler}>
                                        {theme === "light" ?
                                            <BsMoonStarsFill style={{color: "#ffc200"}}/> : <BsFillSunFill style={{color: "#ff5c35"}}/>
                                        }
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="m-a-slider-flex-row m-t-60">
                            {(userType !== '1') && (
                                <div className="display-flex flex-row ht-100pc wd-100pc">
                                    <div className="wd-100pc" style={{paddingLeft: "35%"}}>
                                        <div className="font-t-head font-s-22 m-b-7">메인메뉴</div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff"  to="/btcorpad/pamentslist" element={<MAdminPaymentsListPg/>} onClick={burgerClick}>결제내역</Link>
                                        </div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff"  to="/btcorpad/paywait" element={<MAdminPayWaitPg/>} onClick={burgerClick}>입금대기</Link>
                                        </div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff"  to="/btcorpad/depositwait" element={<MAdminDepositWaitPg/>} onClick={burgerClick}>예치금대기</Link>
                                        </div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff"  to="/btcorpad/buyitem" element={<MAdminBuyItemPg/>} onClick={burgerClick}>구매상품관리</Link>
                                        </div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff"  to="/btcorpad/cancelitem" element={<MAdminCancelItemPg/>} onClick={burgerClick}>취소상품관리</Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="display-flex flex-row ht-100pc wd-100pc">
                                <div className="wd-100pc" style={{paddingLeft: "30%"}}>
                                    <div className="font-t-head font-s-22 m-b-7">게시글</div>
                                    <div className="font-t-title text-active font-s-18">
                                        <Link className="font-c-fff" to="/btcorpad/adminreview" element={<MAdminReviewPg/>} onClick={burgerClick}>이용후기</Link>
                                    </div>
                                    {(userType === '3' || userType === '4' || userType === '5') && (
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff" to="/btcorpad/adminnotice" element={<MAdminNoticePg/>} onClick={burgerClick}>공지사항</Link>
                                        </div>
                                    )}
                                    {(userType === '3' || userType === '4' || userType === '5') && (
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff" to="/btcorpad/adminfaq" element={<MAdminFAQPg/>} onClick={burgerClick}>FAQ</Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="m-a-slider-flex-row m-t-30">
                            {(userType !== '1') && (
                                <div className="display-flex flex-row ht-100pc wd-100pc">
                                    <div className="wd-100pc" style={{paddingLeft: "35%"}}>
                                        <div className="font-t-head font-s-22 m-b-7">회원관리</div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff" to="/btcorpad/aduserinfo" element={<MAdminUserInfoPg/>} onClick={burgerClick}>회원정보</Link>
                                        </div>
                                        <div className="font-t-title text-active font-s-18">
                                            <Link className="font-c-fff" to="/btcorpad/depositset" element={<MAdminDepositSetPg/>} onClick={burgerClick}>예적쿠관리</Link>
                                        </div>
                                        {(userType === '3' || userType === '4' || userType === '5') && (
                                            <div className="font-t-title text-active font-s-18">
                                                <Link className="font-c-fff" to="/btcorpad/employeeinfo" element={<MAdminEmployeeInfoPg/>} onClick={burgerClick}>사원정보</Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="display-flex flex-row ht-100pc wd-100pc">
                                <div className="wd-100pc" style={{paddingLeft: "30%"}}>
                                    <div className="font-t-head font-s-22 m-b-7">통계</div>
                                    <div className="font-t-title text-active font-s-18">
                                        <Link className="font-c-fff" to="/btcorpad/salesstatus" element={<MAdminSalesStatusPg/>} onClick={burgerClick}>매출현황</Link>
                                    </div>
                                    <div className="font-t-title text-active font-s-18">
                                        <Link className="font-c-fff" to="/btcorpad/userstatus" element={<MAdminUserStatusPg/>} onClick={burgerClick}>고객별이용현황</Link>
                                    </div>
                                    <div className="font-t-title text-active font-s-18">
                                        <Link className="font-c-fff" to="/btcorpad/inflowstatus" element={<MAdminInflowStatusPg/>} onClick={burgerClick}>트래픽현황</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={noApply ? 'm-a-body-container-noPadding' : 'm-a-body-container'}>
                <ResetBodyClass />
                <Routes>
                    <Route path="/" element={<MAdminDashBoardPg/>}/> {/*Main*/}

                    <Route path="/pamentslist" element={<MAdminPaymentsListPg/>}/> {/*결제내역*/}
                    <Route path="/paywait" element={<MAdminPayWaitPg/>}/> {/*입금대기*/}
                    <Route path="/depositwait" element={<MAdminDepositWaitPg/>}/> {/*예치금대기*/}
                    <Route path="/buyitem" element={<MAdminBuyItemPg/>}/> {/*구매상품관리*/}
                    <Route path="/cancelitem" element={<MAdminCancelItemPg/>}/> {/*취소상품관리*/}

                    <Route path="/adminreview" element={<MAdminReviewPg/>}/> {/*이용후기*/}
                    <Route path="/adminreview-detail/:seqNum" element={<MAdminReviewDtPg/>}/>{/*이용후기상세*/}
                    <Route path="/adminnotice" element={<MAdminNoticePg/>}/> {/*공지사항*/}
                    <Route path="/adminnotice-detail/:seqNum" element={<MAdminNoticeDtPg/>}/> {/*공지사항상세*/}
                    <Route path="/adminnotice-write" element={<MAdminNoticeWritePg/>}/> {/*공지사항등록*/}
                    <Route path="/adminnotice-modify/:seqNum" element={<MAdminNoticeModifyPg/>}/> {/*공지사항수정*/}

                    <Route path="/adminfaq" element={<MAdminFAQPg/>}/> {/*FAQ*/}
                    <Route path="/adminfaq-detail/:seqNum" element={<MAdminFaqDtPg/>}/> {/*FAQ상세*/}
                    <Route path="/adminfaq-write" element={<MAdminFaqWritePg/>}/> {/*FAQ작성*/}
                    <Route path="/adminfaq-modify/:seqNum" element={<MAdminFaqModifyPg/>}/> {/*FAQ수정*/}


                    <Route path="/aduserinfo" element={<MAdminUserInfoPg/>}/> {/*회원정보*/}
                    <Route path="/depositset" element={<MAdminDepositSetPg/>}/> {/*예적금관리*/}
                    <Route path="/employeeinfo" element={<MAdminEmployeeInfoPg/>}/> {/*사원정보*/}

                    <Route path="/salesstatus" element={<MAdminSalesStatusPg/>}/> {/*매출통계*/}
                    <Route path="/userstatus" element={<MAdminUserStatusPg/>}/> {/*고객별이용현황*/}
                    <Route path="/inflowstatus" element={<MAdminInflowStatusPg/>}/> {/*트래픽현황*/}

                    <Route path="/adminorderchat" element={<MAdminOrderChatPg setNoApply={setNoApply}/>}/> {/*주문서채팅*/}
                    <Route path="/adminorderchat" element={<MAdminOrderDetailPg />}/> {/*주문서채팅*/}
                    <Route path="/alarmlist/:type" element={<MAdminAlarmListPg/>}/> {/*대쉬보드 알람리스트*/}

                    <Route path="*" element={<ErrorPathPg/>}/> {/*경로오류페이지*/}
                </Routes>
            </div>
            {!noApply ?
            <div className="m-a-footer-container p-t-3 p-l-15">
                <a className="font-t-cont font-s-13">ⓒ Btorage Crop. All rights reserved.</a>
            </div>
                :
                null
            }
        </div>
    );
}

export default MAdminMainPg
