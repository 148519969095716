import React, {useEffect, useRef, useState} from "react";
import {AiOutlineClose, AiOutlineDoubleRight} from "react-icons/ai";
import axios from '../../../services/axiosInstance';
import {useTranslation} from "react-i18next";

function ChatModifyModal({chatModifyFalseFn, messageToModify, chatFileModify, nonChatModifySt, nonChatModifyFalseFn, nonMessageToModify, nonChatFileModify}) {

    const { t } = useTranslation();

    const [modifyMessageText, setModifyMessageText] = useState(messageToModify ? messageToModify.chatKey : '');
    const [nonModifyMessageText, setNonModifyMessageText] = useState(nonMessageToModify ? nonMessageToModify.chatKey : '');
    const textareaRef = useRef(null);

    const handleChange = (e) => {
        if(nonChatModifySt) {
            setNonModifyMessageText(e.target.value);
        } else {
            setModifyMessageText(e.target.value);
        }
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 500) + 'px';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 500) + 'px';
    }, [messageToModify, nonMessageToModify])


    return (
        <div className="bookContainer" style={{display: "flex", zIndex:"10"}}>


            <div className="bookFrame" style={{
                minWidth:"600px",
                width: "auto",
                height: "auto",
                top: "calc(25vh - 120px)",
                left: "calc(50vw - 200px)",
                borderRadius: "5px"
            }}>

                <div style={{width: "100%", height: "100%"}}>
                    {/*상세헤더*/}
                    <div style={{height: "40px"}}>
                        <div style={{
                            height: "100%",
                            fontSize: "23px",
                            border: "solid",
                            borderWidth: "thin 1px",
                            color: "lightgray",
                            display: "flex"
                        }}>

                            <div style={{width: "92%", textAlign: "center", marginLeft: "55px"}}>
                                <p style={{
                                    color: "dimgrey",
                                    fontSize: "16px",
                                    width: "100%",
                                    marginTop: "7px"
                                }}>{t(`cmmHeader`)}</p>
                            </div>
                            <div style={{width: "10%", textAlign: "center"}}>
                                <i onClick={nonChatModifySt ? nonChatModifyFalseFn : chatModifyFalseFn}><AiOutlineClose
                                    style={{color: "dimgrey", height: "20px"}}/></i>
                            </div>

                        </div>
                    </div>

                    {/*상세바디*/}
                    {/*<div style={{height: "auto", overflowY: "scroll"}}>*/}
                    <div style={{height: "auto", overflow: "auto"}}>
                    <div className="ordChatInputContainer" style={{
                            // width:"68%",
                            backgroundColor:"white",
                            height: "auto",
                            minHeight:"50%",
                            padding: "30px",
                            opacity:"0.7"
                        }}>
                            <textarea
                                ref={textareaRef}
                                placeholder={t(`cmmPlaceHd`)}
                                spellCheck="false"
                                value={nonChatModifySt ? nonModifyMessageText : modifyMessageText}
                                style={{
                                    // height: "100px", // Initial height
                                    minHeight: "20%",
                                    maxHeight: "300px",
                                    borderRadius: "6px",
                                    width: "100%",
                                    borderWidth: "thin 1px",
                                    borderColor: "lightgray",
                                    resize: "none",
                                    overflow: "auto",
                                    outline: "none",
                                    color: "dimgrey",
                                    padding: "10px",
                                    fontSize: "14px",
                                    ':focus': {
                                        borderColor: "#FFAF34"
                                    }
                                }} onChange={handleChange}/>
                        </div>
                        <div style={{height:"70px", display:"flex", justifyContent:"center"}}>
                            { nonChatModifySt ?
                            <button className="orderItemHandleBt"
                                    style={{
                                        width: "60px",
                                        height: "35px",
                                        fontSize: "13px"
                                    }} onClick={()=> {nonChatFileModify(nonModifyMessageText, nonMessageToModify)}}>{t(`modRegModiBt`)}</button>
                                :
                                <button className="orderItemHandleBt"
                                        style={{
                                            width: "60px",
                                            height: "35px",
                                            fontSize: "13px"
                                        }} onClick={()=> {chatFileModify(modifyMessageText, messageToModify)}}>{t(`modRegModiBt`)}</button>
                            }
                        </div>
                    </div>





                </div>
            </div>
        </div>
    )
}

export default ChatModifyModal
