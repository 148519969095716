import React, {useEffect, useRef, useState} from "react";
import { HiReceiptTax } from "react-icons/hi";
import {BsArrowLeft, BsFillPersonCheckFill, BsMailbox2} from "react-icons/bs";
import {TbAddressBook} from "react-icons/tb";
import {MdPhoneIphone} from "react-icons/md";
import axios from '../../../services/axiosInstance';
import {BiCaretDown} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import MAddrBookPg from "../../../page/mobile/MAddrBookPg";

function RecmodiModal({recmodiFalseFn, recinfoSeqnum, setAddressUpdated, setUpdatedRecipientInfo, recmodiCloseFn, selectedShip}) {
    const { t } = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [country, setCountry] = useState("");
    const [countryLabel, setCountryLabel] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const [countryError, setCountryError] = useState("");
    const [taxidError, setTaxidError] = useState("");
    const [recnameError, setRecnameError] = useState("");
    const [address1Error, setAddress1Error] = useState("");
    const [address2Error, setAddress2Error] = useState("");
    const [address3Error, setAddress3Error] = useState("");
    const [cityError, setCityError] = useState("");
    const [zipcodeError, setZipcodeError] = useState("");
    const [telnumError, setTelnumError] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({ value: '', label: t(`ordCrLabel`) });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error fetching countries:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])


    const address1Length = selectedShip === "EMS" || selectedShip === "EMSP" ? 200 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 200 :
            selectedShip === "Small-Packet" ? 200 :
                selectedShip === "K-Packet" ? 200 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 35 :
                            selectedShip === "SEA" ? 200 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address2Length = selectedShip === "EMS" || selectedShip === "EMSP" ? 50 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 35 :
                            selectedShip === "SEA" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const address3Length = selectedShip === "EMS" || selectedShip === "EMSP" ? 50 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 50 :
                            selectedShip === "SEA" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;
    const cityLength = selectedShip === "EMS" || selectedShip === "EMSP" ? 50 :
        selectedShip === "UPS" || selectedShip === "UPS-E" ? 50 :
            selectedShip === "Small-Packet" ? 50 :
                selectedShip === "K-Packet" ? 50 :
                    selectedShip === "DHL" ? 35 :
                        selectedShip === "FedEx" || selectedShip === "FedEx-E" ? 50 :
                            selectedShip === "SEA" ? 50 :
                                selectedShip === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e)=> {
        const taxidRegex = /^[a-zA-Z0-9\s\-\.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e)=> {
        const recnameRegex = /^[a-zA-Z\s]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e)=> {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e)=> {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e)=> {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e)=> {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e)=> {
        const zipcodeRegex = /^[a-zA-Z\d-\s]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e)=> {
        const telnumRegex = /^[\d-\s]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };
    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };
    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };
    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };
    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };
    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };
    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };
    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const getMaxLengthByTelnumCountry = () => {
        if (selectedCountry.value === "US") return 10;
        return 15;
    };

    const currentMaxLengthTelnumDisplay = `/${getMaxLengthByTelnumCountry()}`;

    const telnumValid = (e) => {
        const { value } = e.target;

        //미국 숫자만 10자리
        if (selectedCountry.value === "US") {
            if (/^\d{0,10}$/.test(value)) {
                validTextTelnum(e);
                telnumInputLengthCheck(e);
                telnumInputRTCheck(e);
            }
            return;
        }

        if (value.length <= 15) {
            validTextTelnum(e)
            telnumInputLengthCheck(e);
            telnumInputRTCheck(e)
        }
    }

    const getMaxLengthByZipcode = () => {
        if (selectedCountry.value === "US") return 5; // 미국
        if (selectedCountry.value === "CA") return 6; // 캐나다
        return 10;
    };

    const currentMaxLengthZipcodeDisplay = `/${getMaxLengthByZipcode()}`;

    const zipcodeValid = (e) => {
        const { value } = e.target;

        //미국 숫자만 5자리
        if (selectedCountry.value === "US") {
            if (/^\d{0,5}$/.test(value)) {
                validTextZipcode(e);
                zipcodeInputLengthCheck(e);
                zipcodeInputRTCheck(e);
            }
            return;
        }

        //캐나다 알파벳&숫자 6자리
        if (selectedCountry.value === "CA") {
            if (/^[a-zA-Z0-9]{0,6}$/.test(value)) {
                validTextZipcode(e);
                zipcodeInputLengthCheck(e);
                zipcodeInputRTCheck(e);
            }
            return;
        }

        if (value.length <= 10) {
            validTextZipcode(e);
            zipcodeInputLengthCheck(e);
            zipcodeInputRTCheck(e);
        }
    };

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }
    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }
    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }
    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            selectRecinfoData();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const selectRecinfoData = (() => {
        axios({
            method: 'get',
            url: '/order/selectRecinfoData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                recinfoSeqnum: recinfoSeqnum
            },
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData);
                setTaxid(response.data.taxid);
                setRecname(response.data.recname);
                setAddress1(response.data.address1);
                setAddress2(response.data.address2);
                setAddress3(response.data.address3);
                setCity(response.data.city);
                setZipcode(response.data.zipcode);
                setTelnum(response.data.telnum);
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
            })
    })

    const [myordBookSt, setMyordBookSt] = useState(false);
    const myordBookListFn = (() => {
        setMyordBookSt(true);
    })
    const myordBookFalseFn = (() => {
        setMyordBookSt(false);
    })

    const addrChoiceData = ((addrSeqnum) => {
        axios({
            method: 'get',
            url: '/member/choiceAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: addrSeqnum
            }
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData);
                setTaxid(response.data.taxid)
                setRecname(response.data.recname)
                setAddress1(response.data.address1)
                setAddress2(response.data.address2)
                setAddress3(response.data.address3)
                setCity(response.data.city)
                setZipcode(response.data.zipcode)
                setTelnum(response.data.telnum)
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
                myordBookFalseFn();
            })
    })

    const modifyRecinfoData = (() => {
        axios({
            method: 'post',
            url: '/order/modifyRecinfoData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                recinfoSeqnum: recinfoSeqnum
            },
            data: {
                crCode: selectedCountry.value,
                taxid: taxid,
                recname: recname,
                telnum: telnum,
                zipcode: zipcode,
                address1: address1,
                address2: address2,
                address3: address3,
                city: city
            },
        })
            .then((response) => {
                const countryData = response.data;
                const updatedInfo = {
                    country: countryData.countryCode,
                    countryDTO: countryData,
                    taxid: taxid,
                    recname: recname,
                    telnum: telnum,
                    zipcode: zipcode,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    city: city,
                };
                recmodiFalseFn();
                setUpdatedRecipientInfo(updatedInfo);
            })
    })


    const validation = () => {
        let isValue = true;

        if (selectedCountry.value === "ID" && selectedShip !== 'EMS' && !taxid) {
            setTaxidError(true);
            isValue = false;
        }
        if (!recname) {
            setRecnameError(true);
            isValue = false;
        }
        if (!address1) {
            setAddress1Error(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            isValue = false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            isValue = false;
        }
        if (!telnum) {
            setTelnumError(true);
            isValue = false;
        }
        if (selectedCountry.value === "" || !selectedCountry.value) {
            setCountryError(true);
            isValue = false;
        }
        return isValue;
    }
    const finalModify = () => {
        if(validation()) {
            setAddressUpdated(prevState => !prevState);
            modifyRecinfoData();
        }
    }

    return(
        <div className="bookContainer">
            <div className="bookFrame-new-m">
                <div className="bookFrameHeader">
                    <div className="headerGoBack" onClick={recmodiCloseFn}><i><BsArrowLeft/></i></div>
                    <p className="font-t-title">{t(`rmmHeader`)}</p> {/*주소지변경*/}
                </div>
                <div className="bookFrameBody font-t-cont">
                    <div className="modiCardFrame" style={{height: selectedCountry.value === "KR" ? "100%" : null}}>
                        <div className="display-flex flex-row wd-100pc m-b-20 gap10">
                            {/*여기서부터 커스텀 국가셀렉트*/}
                            <div ref={countryRef} className="ht-45 wd-80pc c-point position-relative m-b-15" onClick={toggleCountryShow}>
                                <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                    {selectedCountry.value ? (
                                        <div className="wd-15pc text-center p-l-5 p-t-10">
                                            <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                        </div>
                                    ) : (
                                        <div className="wd-15pc text-center p-l-5 p-t-10">
                                            <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                        </div>
                                    )}
                                    <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-12 p-l-15"
                                           type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="nope"
                                           value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                           onChange={handleCountrySearch} />
                                    <i className="wd-15pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                        <BiCaretDown />
                                    </i>
                                </div>
                                {countryShow && (
                                    <div className="country-select-data wd-100pc position-absolute m-t-5">
                                        <div className="country-select-data-child">
                                        {filteredCountryList.map((country, index) => (
                                            <div className="selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20" key={index}
                                                 onClick={(event) => handleCountryChange(country, event)}>
                                                <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                <p className="font-s-14">{country.label}</p>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button className="admin-btn-A wd-20pc ht-45 font-s-13" onClick={myordBookListFn}>{t(`rmmBookBt`)}</button> {/*주소록*/}
                        </div>
                        {countryError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-t-cont m-b-4">{t(`recCrErr`)}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value === "ID" ?
                            <div className="modiCardName display-flex m-b-10">
                                <div className="acc-input-container acc-input-icon wd-100pc ht-100pc p-l-5">
                                    <div className="acc-input-lg-icon wd-15pc">
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><HiReceiptTax/></i>
                                    </div>
                                    <div className="acc-input-form wd-85pc">
                                        <input className="acc-input-inform wd-100pc ht-100pc p-l-5 p-r-5" type="text" placeholder=""
                                               value={taxid} onChange={validTextTaxid} maxLength="20"/>
                                        <label className="inputLabel font-s-18"><span style={{color: "red"}}>*</span> Tax ID</label>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { taxidError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-t-cont m-b-4">{t(`recTaxIdErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardName display-flex m-b-10">
                            <div className="acc-input-container acc-input-icon wd-100pc ht-100pc p-l-5">
                                <div className="acc-input-lg-icon wd-15pc">
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><BsFillPersonCheckFill/></i>
                                </div>
                                <div className="acc-input-form wd-85pc">
                                    <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="text" placeholder="" value={recname} onChange={nameValid} maxLength="25"
                                           style={{paddingRight:"25%"}}/>
                                    <label className="inputLabel font-s-18"><span style={{color:"red"}}>*</span> {t(`joinLabel5`)}</label>
                                    {selectedCountry.value !== "KR" ?
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{nameInputCount}</p>
                                                <p className="font-s-13">/25</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        {selectedCountry.value !== "KR" && recnameError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t(`recNmErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardName display-flex m-b-10">
                            <div className="acc-input-container acc-input-icon wd-100pc ht-100pc">
                                <div className="acc-input-lg-icon wd-15pc">
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><MdPhoneIphone/></i>
                                </div>
                                <div className="acc-input-form wd-85pc">
                                    <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="tel" placeholder=""
                                           style={{paddingRight:"28%"}} value={telnum} onChange={telnumValid} maxLength={15}/>
                                    <label className="inputLabel font-s-18"><span style={{color:"red"}}>*</span> {t(`modNbInfo5`)}</label>
                                    { selectedCountry.value !== "KR" ?
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{telnumInputCount}</p>
                                                <p className="font-s-13">{currentMaxLengthTelnumDisplay}</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        { selectedCountry.value !== "KR" && telnumError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-t-cont m-b-5">{t(`recTellErr`)}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardName display-flex m-b-10">
                                <div className="acc-input-container acc-input-icon wd-100pc ht-100pc">
                                    <div className="acc-input-lg-icon wd-15pc p-l-1">
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><BsMailbox2/></i>
                                    </div>
                                    <div className="acc-input-form wd-85pc">
                                        <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="text" placeholder=" "
                                               style={{paddingRight:"28%"}} value={zipcode} onChange={zipcodeValid} maxLength={10}/>
                                        <label className="inputLabel font-s-18"><span style={{color:"red"}}>*</span> {t(`modNbInfo8`)}</label>
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{zipcodeInputCount}</p>
                                                <p className="font-s-13">{currentMaxLengthZipcodeDisplay}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { zipcodeError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{t(`recZipErr`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="modiCardName display-flex m-b-10">
                            <div className="acc-input-container acc-input-icon wd-100pc ht-100pc">
                                <div className="acc-input-lg-icon wd-15pc">
                                    <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><TbAddressBook/></i>
                                </div>
                                <div className="acc-input-form wd-85pc">
                                    <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="text" placeholder=""
                                           value={address1} onChange={address1Valid} maxLength={address1Length} style={{paddingRight:"28%"}}/>
                                    <label className="inputLabel font-s-18"><span style={{color:"red"}}>*</span> {t(`modSmallMenu7`)}</label>
                                    { selectedCountry.value !== "KR" ?
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{address1InputCount}</p>
                                                <p className="font-s-13">{`/${address1Length}`}</p>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        { selectedCountry.value !== "KR" && address1Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14">{`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        { selectedCountry.value !== "KR" ?
                            <div className="modiCardName display-flex m-b-10">
                                <div className="acc-input-container acc-input-icon wd-100pc ht-100pc">
                                    <div className="acc-input-lg-icon wd-15pc">
                                        <i style={{fontSize:"28px", margin:"0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form wd-85pc">
                                        <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="text" placeholder="" style={{paddingRight:"25%"}}
                                               value={address2} onChange={address2Valid} maxLength={address2Length}/>
                                        <label className="inputLabel font-s-18">{t(`modSmallMenu8`)}</label>
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{address2InputCount}</p>
                                                <p className="font-s-13">{`/${address2Length}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { address2Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardName display-flex m-b-10">
                                <div className="acc-input-container acc-input-icon wd-100pc ht-100pc">
                                    <div className="acc-input-lg-icon wd-15pc">
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form wd-85pc">
                                        <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="text" placeholder=""
                                               style={{paddingRight:"28%"}} value={address3} onChange={address3Valid} maxLength={address3Length}/>
                                        <label className="inputLabel font-s-18">{t(`modSmallMenu9`)}</label>
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{address3InputCount}</p>
                                                <p className="font-s-13">{`/${address3Length}`}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { address3Error ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                        {selectedCountry.value !== "KR" ?
                            <div className="modiCardName display-flex m-b-10">
                                <div className="acc-input-container acc-input-icon wd-100pc ht-100pc">
                                    <div className="acc-input-lg-icon wd-15pc">
                                        <i style={{fontSize: "28px", margin: "0px 0px 5px 0px"}}><TbAddressBook/></i>
                                    </div>
                                    <div className="acc-input-form wd-85pc">
                                        <input className="acc-input-inform wd-100pc ht-100pc p-l-5" type="text" placeholder=""
                                               style={{paddingRight:"28%"}} value={city} onChange={cityValid} maxLength={cityLength}/>
                                        <label className="inputLabel font-s-18"><span style={{color: "red"}}>*</span> {t(`modSmallMenu10`)}
                                        </label>
                                        <div className="position-absolute" style={{right: "3%", top: "30%"}}>
                                            <div className="display-flex">
                                                <p className="font-s-13 font-c-btorage">{cityInputCount}</p>
                                                <p className="font-s-13">{`/${cityLength}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        { cityError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg font-s-14 m-b-5">{`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="bookFrameFooter ht-10pc p-l-10 p-r-10 align-center" style={{display:"flex", justifyContent:"center"}}>
                    <button className="colorFullBt ht-35 font-s-15 wd-100pc" onClick={finalModify}>{t(`mdBt`)}</button> {/*저장*/}
                </div>
            </div>
            {myordBookSt ?
                <MAddrBookPg myordBookSt={myordBookSt} myordBookFalseFn={myordBookFalseFn} addrChoiceData={addrChoiceData}/>
                :
                null
            }
        </div>
    )
}

export default RecmodiModal
