import React from "react";
import '../../css/mobile/MMsgSelectModal.css'
import warnIcon from "../../assets/img/warnIcon.png";

function MMsgSelectModal({
                             alertShow,
                             eventHandle1,
                             eventHandle2,
                             msgData1,
                             msgData2,
                             photoDeleteCancelFn,
                             photoDeleteExecution,
                             msgData7,
                             photoDeleteSelectSt,
                             deleteSelectSt,
                             deleteHandleFn,
                             deleteSelectMsg1,
                             deleteSelectMsg2,
                             deleteBookCancelFn,
                             deleteOrderModalShowSt,
                             deleteOrderFalseFn,
                             deleteOrder,
                             deleteOrderMsg1,
                             deleteOrderMsg2,
                             deletePaySt,
                             deletePayMsgFalseFn,
                             deletePayMsg,
                             deletePay,
                             chatDeleteSt,
                             chatDeleteFalseFn,
                             chatDeleteMsg1,
                             chatDeleteMsg2,
                             messageToModify,
                             deleteChat,
                             nonChatDeleteSt,
                             nonChatDeleteFalseFn,
                             nonMessageToModify,
                             deleteNonChat,
                             deliveryDetailPayMove,
                             setDeliveryDetailPayMove,
                             moveDeliveryPay,
                             deliveryDetailPayMsg1,
                             deliveryDetailPayMsg2,
                             integModalSt,
                             integModalMsg1,
                             integModalMsg2,
                             closeIntegModalShow,
                             orderMerge,
                             divideModalSt,
                             divideModalMsg1,
                             divideModalMsg2,
                             closeDivideModalShow,
                             orderDivide,
                             deleteCpShowSt,
                             deleteCpMsg1,
                             deleteCpMsg2,
                             deleteCoupon,
                             closeDeleteCpShow,
                             minusBatchCpShowSt,
                             minusBatchCpMsg1,
                             minusBatchCpMsg2,
                             closeMinusBatchCpShow,
                             saveBatchCp,
                             plusBatchCpShowSt,
                             plusBatchCpMsg1,
                             plusBatchCpMsg2,
                             closePlusBatchCpShow,
                             cancelMsgShow,
                             cancelDepHistoryMsg1,
                             cancelDepHistoryMsg2,
                             closeCancelMsgShow,
                             cancelDepHistory,
                             cancelDepHistorySeqNum,
                             deleteReviewShow,
                             deleteReviewShowMsg1,
                             deleteReviewShowMsg2,
                             closeDeleteReviewShow,
                             deleteReview,
                             deleteReviewCommentSeqNum,
                             deleteReviewCommentShow,
                             deleteReviewCommentShowMsg1,
                             deleteReviewCommentShowMsg2,
                             closeDeleteReviewCommentShow,
                             deleteReviewComment,
                             blockCommentShow,
                             blockCommentShowMsg1,
                             blockCommentShowMsg2,
                             closeBlockCommentShow,
                             blockComment,
                             deleteNoticeMsgShow,
                             deleteNoticeMsg1,
                             deleteNoticeMsg2,
                             closeDeleteNoticeMsgShow,
                             deleteNotice,
                             mstDeleteMsgShow,
                             mstDeleteMsg1,
                             mstDeleteMsg2,
                             closeMstDeleteMsg,
                             mstDeleteSeqNum,
                             deleteFaqMst,
                             mstDeleteContent,
                             deleteFaqDtMsgShow,
                             deleteFaqDtMsg1,
                             deleteFaqDtMsg2,
                             deleteFaqDt,
                             closeDeleteFaqDtMsgShow,
                             depConfirmComplShow,
                             depConfirmComplMsg1,
                             depConfirmComplMsg2,
                             closeDepConfirmCompl,
                             modifyDepConfirmCompl,
                             paypalExDeleteShow,
                             paypalExDeleteMsg1,
                             paypalExDeleteMsg2,
                             paypalExDeleteChecked,
                             closePaypalExDeleteShow,
                             deleteData,
                             allProdStChangeMsg1,
                             allProdStChangeMsg2,
                             modifyProdSt,
                             selectedItems,
                             selectedFormTy,
                             selectedCdataIndex,
                             allCartProdStChangeShow,
                             closeAllCartProdStChangeShow,
                             selectedAllCartProdSt,
                             allOrderProdStChangeShow,
                             closeAllOrderProdStChangeShow,
                             selectedAllOrderProdSt,
                             allOfflineProdStChangeShow,
                             closeAllOfflineProdStChangeShow,
                             selectedAllOfflineProdSt,
                             allTransferProdStChangeShow,
                             closeAllTransferProdStChangeShow,
                             selectedAllTransferProdSt,
                             allDomesticProdStChangeShow,
                             closeAllDomesticProdStChangeShow,
                             selectedAllDomesticProdSt,
                             allDeliveryProdStChangeShow,
                             closeAllDeliveryProdStChangeShow,
                             selectedAllDeliveryProdSt,
                             orderdtDepositPaySt,
                             closeOrderdtDepositPaySt,
                             orderdtDepositPayMsg1,
                             orderdtDepositPayMsg2,
                             orderdtAdminDpPay,
                             orderdtPointPaySt,
                             closeOrderdtPointPaySt,
                             orderdtPointPayMsg1,
                             orderdtPointPayMsg2,
                             payinfoDepositPaySt,
                             closePayinfoDepositPaySt,
                             payinfoDepositPayMsg1,
                             payinfoDepositPayMsg2,
                             payinfoAdminDpPay,
                             payinfoPointPaySt,
                             closePayinfoPointPaySt,
                             payinfoPointPayMsg1,
                             payinfoPointPayMsg2,
                             adminOsReturnSt,
                             closeOsReturnSt,
                             osReturnMsg1,
                             osReturnMsg2,
                             deliveryReturn,
                             adminDsReturnSt,
                             closeDsReturnSt,
                             dsReturnMsg1,
                             dsReturnMsg2,
                             confirmPaySt,
                             confirmPayMsg1,
                             confirmPayMsg2,
                             closeConfirmPaySt,
                             payDataSucc,
                             selectedSeqNum,
                             cancelPaySt,
                             cancelPayMsg1,
                             cancelPayMsg2,
                             closeCancelPaySt,
                             payDataCancel,
                             allUpdateProdSt,
                             allUpdateProdStMsg1,
                             allUpdateProdStMsg2,
                             closeAllUpdateProdSt,
                             updateProdArrival,
                             checkedOrd,
                             updateProdSt,
                             updateProdStMsg1,
                             updateProdStMsg2,
                             closeUpdateProdSt,
                             nUserRemoveSt,
                             nUserRemoveMsgTitle,
                             nUserRemoveMsgContent,
                             nUserAllChatRemove,
                             closeNUserRemoveSt
                         }) {

    const handleAdminAllProdStChange = () => {
        let selectedProdSt = null;
        if (selectedFormTy === 'cart') {
            selectedProdSt = selectedAllCartProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'order') {
            selectedProdSt = selectedAllOrderProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'offline') {
            selectedProdSt = selectedAllOfflineProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'transfer') {
            selectedProdSt = selectedAllTransferProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'domestic') {
            selectedProdSt = selectedAllDomesticProdSt[selectedCdataIndex];
        } else if (selectedFormTy === 'delivery') {
            selectedProdSt = selectedAllDeliveryProdSt[selectedCdataIndex];
        }
        const seqNums = selectedItems[selectedFormTy][selectedCdataIndex];
        if (seqNums && seqNums.length > 0) {
            modifyProdSt(selectedProdSt, seqNums);
            if (selectedFormTy === 'cart') {
                closeAllCartProdStChangeShow();
            } else if (selectedFormTy === 'order') {
                closeAllOrderProdStChangeShow();
            } else if (selectedFormTy === 'offline') {
                closeAllOfflineProdStChangeShow();
            } else if (selectedFormTy === 'transfer') {
                closeAllTransferProdStChangeShow();
            } else if (selectedFormTy === 'domestic') {
                closeAllDomesticProdStChangeShow();
            } else if (selectedFormTy === 'delivery') {
                closeAllDeliveryProdStChangeShow();
            }
        }
    };

    return (
        <div className="m-a-msgck-modal-container">
            <div className="m-a-msgck-modal-frame">
                <div className="m-a-msgck-modal-hd-frame">
                    <div className="m-a-msgck-icon-container">
                        <img alt='' src={warnIcon} className="m-a-msgck-icon"/>
                    </div>
                    <div className="m-a-msgck-head-text font-t-title">
                        <div className="font-t-title font-s-18">Select Message</div>
                    </div>
                </div>
                <div className="m-a-msgck-head-content font-t-cont p-l-29">
                    {alertShow === true ?
                        <div className="font-t-title font-s-16">{msgData1}</div>
                        :
                        photoDeleteSelectSt ?
                            <div className="font-t-title font-s-16">{msgData7}</div>
                            :
                            deleteSelectSt ?
                                <div className="font-t-title font-s-16">{deleteSelectMsg1}</div>
                                :
                                deleteOrderModalShowSt ?
                                    <div className="font-t-title font-s-16">{deleteOrderMsg1}</div>
                                    :
                                    deletePaySt ?
                                        <div className="font-t-title font-s-16">{deletePayMsg}</div>
                                        :
                                        chatDeleteSt ?
                                            <div className="font-t-title font-s-16">{chatDeleteMsg1}</div>
                                            :
                                            nonChatDeleteSt ?
                                                <div className="font-t-title font-s-16">{chatDeleteMsg1}</div>
                                                :
                                                deliveryDetailPayMove ?
                                                    <div
                                                        className="font-t-title font-s-16">{deliveryDetailPayMsg1}</div>
                                                    :
                                                    integModalSt ?
                                                        <div className="font-t-title font-s-16">{integModalMsg1}</div>
                                                        :
                                                        divideModalSt ?
                                                            <div
                                                                className="font-t-title font-s-16">{divideModalMsg1}</div>
                                                            :
                                                            deleteCpShowSt ?
                                                                <div
                                                                    className="font-t-title font-s-16">{deleteCpMsg1}</div>
                                                                :
                                                                minusBatchCpShowSt ?
                                                                    <div
                                                                        className="font-t-title font-s-16">{minusBatchCpMsg1}</div>
                                                                    :
                                                                    plusBatchCpShowSt ?
                                                                        <div
                                                                            className="font-t-title font-s-16">{plusBatchCpMsg1}</div>
                                                                        :
                                                                        cancelMsgShow ?
                                                                            <div
                                                                                className="font-t-title font-s-16">{cancelDepHistoryMsg1}</div>
                                                                            :
                                                                            deleteReviewShow ?
                                                                                <div
                                                                                    className="font-t-title font-s-16">{deleteReviewShowMsg1}</div>
                                                                                :
                                                                                deleteReviewCommentShow ?
                                                                                    <div
                                                                                        className="font-t-title font-s-16">{deleteReviewCommentShowMsg1}</div>
                                                                                    :
                                                                                    blockCommentShow ?
                                                                                        <div
                                                                                            className="font-t-title font-s-16">{blockCommentShowMsg1}</div>
                                                                                        :
                                                                                        deleteNoticeMsgShow ?
                                                                                            <div
                                                                                                className="font-t-title font-s-16">{deleteNoticeMsg1}</div>
                                                                                            :
                                                                                            mstDeleteMsgShow ?
                                                                                                <div
                                                                                                    className="font-t-title font-s-16">{mstDeleteMsg1}</div>
                                                                                                :
                                                                                                deleteFaqDtMsgShow ?
                                                                                                    <div
                                                                                                        className="font-t-title font-s-16">{deleteFaqDtMsg1}</div>
                                                                                                    :
                                                                                                    depConfirmComplShow ?
                                                                                                        <div
                                                                                                            className="font-t-title font-s-16">{depConfirmComplMsg1}</div>
                                                                                                        :
                                                                                                        paypalExDeleteShow ?
                                                                                                            <div
                                                                                                                className="font-t-title font-s-16">{paypalExDeleteMsg1}</div>
                                                                                                            :
                                                                                                            (allCartProdStChangeShow || allOrderProdStChangeShow || allOfflineProdStChangeShow ||
                                                                                                                allTransferProdStChangeShow || allDomesticProdStChangeShow || allDeliveryProdStChangeShow) ?
                                                                                                                <div
                                                                                                                    className="font-t-title font-s-16">{allProdStChangeMsg1}</div>
                                                                                                                :
                                                                                                                orderdtDepositPaySt ?
                                                                                                                    <div
                                                                                                                        className="font-t-title font-s-16">{orderdtDepositPayMsg1}</div>
                                                                                                                    :
                                                                                                                    orderdtPointPaySt ?
                                                                                                                        <div
                                                                                                                            className="font-t-title font-s-16-s-18">{orderdtPointPayMsg1}</div>
                                                                                                                        :
                                                                                                                        payinfoDepositPaySt ?
                                                                                                                            <div
                                                                                                                                className="font-t-title font-s-16">{payinfoDepositPayMsg1}</div>
                                                                                                                            :
                                                                                                                            payinfoPointPaySt ?
                                                                                                                                <div
                                                                                                                                    className="font-t-title font-s-16">{payinfoPointPayMsg1}</div>
                                                                                                                                :
                                                                                                                                adminOsReturnSt ?
                                                                                                                                    <div
                                                                                                                                        className="font-t-title font-s-16">{osReturnMsg1}</div>
                                                                                                                                    :
                                                                                                                                    adminDsReturnSt ?
                                                                                                                                        <div
                                                                                                                                            className="font-t-title font-s-16">{dsReturnMsg1}</div>
                                                                                                                                        :
                                                                                                                                        confirmPaySt ?
                                                                                                                                            <div
                                                                                                                                                className="font-t-title font-s-16">{confirmPayMsg1}</div>
                                                                                                                                            :
                                                                                                                                            cancelPaySt ?
                                                                                                                                                <div
                                                                                                                                                    className="font-t-title font-s-16">{cancelPayMsg1}</div>
                                                                                                                                                :
                                                                                                                                                allUpdateProdSt ?
                                                                                                                                                    <div
                                                                                                                                                        className="font-t-title font-s-16">{allUpdateProdStMsg1}</div>
                                                                                                                                                    :
                                                                                                                                                    updateProdSt ?
                                                                                                                                                        <div
                                                                                                                                                            className="font-t-title font-s-16">{updateProdStMsg1}</div>
                                                                                                                                                        :
                                                                                                                                                        nUserRemoveSt ?
                                                                                                                                                            <div
                                                                                                                                                                className="font-t-title font-s-16">{nUserRemoveMsgContent}</div>
                                                                                                                                                            :
                                                                                                                                                        null
                    }
                </div>
                {/*버튼 조건부렌더링*/}
                {alertShow === true ?
                    <div className="m-a-msgck-bt-container">
                        <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                            <div className="m-a-gr-Bt-text font-t-cont" onClick={eventHandle1}>No</div>
                        </button>
                        <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                            <div className="m-a-colorBt-text font-t-cont" onClick={eventHandle2}>Yes</div>
                        </button>
                    </div>
                    :
                    photoDeleteSelectSt ?
                        <div className="m-a-msgck-bt-container">
                            <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                <div className="m-a-gr-Bt-text font-t-cont" onClick={photoDeleteCancelFn}>No</div>
                            </button>
                            <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                <div className="m-a-colorBt-text font-t-cont" onClick={photoDeleteExecution}>Yes</div>
                            </button>
                        </div>
                        :
                        deleteSelectSt ?
                            <div className="m-a-msgck-bt-container">
                                <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                    <div className="m-a-gr-Bt-text font-t-cont" onClick={deleteBookCancelFn}>No</div>
                                </button>
                                <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                    <div className="m-a-colorBt-text font-t-cont" onClick={deleteHandleFn}>Yes</div>
                                </button>
                            </div>
                            :
                            deleteOrderModalShowSt ?
                                <div className="m-a-msgck-bt-container">
                                    <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                        <div className="m-a-gr-Bt-text font-t-cont" onClick={deleteOrderFalseFn}>No
                                        </div>
                                    </button>
                                    <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                        <div className="m-a-colorBt-text font-t-cont" onClick={deleteOrder}>Yes</div>
                                    </button>
                                </div>
                                :
                                deletePaySt ?
                                    <div className="m-a-msgck-bt-container">
                                        <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                            <div className="m-a-gr-Bt-text font-t-cont"
                                                 onClick={deletePayMsgFalseFn}>No
                                            </div>
                                        </button>
                                        <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                            <div className="m-a-colorBt-text font-t-cont" onClick={deletePay}>Yes</div>
                                        </button>
                                    </div>
                                    :
                                    chatDeleteSt ?
                                        <div className="m-a-msgck-bt-container">
                                            <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                <div className="m-a-gr-Bt-text font-t-cont"
                                                     onClick={chatDeleteFalseFn}>No
                                                </div>
                                            </button>
                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                <div className="m-a-colorBt-text font-t-cont"
                                                     onClick={() => deleteChat(messageToModify)}>Yes
                                                </div>
                                            </button>
                                        </div>
                                        :
                                        nonChatDeleteSt ?
                                            <div className="m-a-msgck-bt-container">
                                                <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                    <div className="m-a-gr-Bt-text font-t-cont"
                                                         onClick={nonChatDeleteFalseFn}>No
                                                    </div>
                                                </button>
                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                    <div className="m-a-colorBt-text font-t-cont"
                                                         onClick={() => deleteNonChat(nonMessageToModify)}>Yes
                                                    </div>
                                                </button>
                                            </div>
                                            :
                                            deliveryDetailPayMove ?
                                                <div className="m-a-msgck-bt-container">
                                                    <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                        <div className="m-a-gr-Bt-text font-t-cont"
                                                             onClick={() => setDeliveryDetailPayMove(false)}>No
                                                        </div>
                                                    </button>
                                                    <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                        <div className="m-a-colorBt-text font-t-cont"
                                                             onClick={moveDeliveryPay}>Yes
                                                        </div>
                                                    </button>
                                                </div>
                                                :
                                                integModalSt ?
                                                    <div className="m-a-msgck-bt-container">
                                                        <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                            <div className="m-a-gr-Bt-text font-t-cont"
                                                                 onClick={closeIntegModalShow}>No
                                                            </div>
                                                        </button>
                                                        <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                            <div className="m-a-colorBt-text font-t-cont"
                                                                 onClick={orderMerge}>Yes
                                                            </div>
                                                        </button>
                                                    </div>
                                                    :
                                                    divideModalSt ?
                                                        <div className="m-a-msgck-bt-container">
                                                            <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                <div className="m-a-gr-Bt-text font-t-cont"
                                                                     onClick={closeDivideModalShow}>No
                                                                </div>
                                                            </button>
                                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                <div className="m-a-colorBt-text font-t-cont"
                                                                     onClick={orderDivide}>Yes
                                                                </div>
                                                            </button>
                                                        </div>
                                                        :
                                                        deleteCpShowSt ?
                                                            <div className="m-a-msgck-bt-container">
                                                                <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                    <div className="m-a-gr-Bt-text font-t-cont"
                                                                         onClick={closeDeleteCpShow}>No
                                                                    </div>
                                                                </button>
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                    <div className="m-a-colorBt-text font-t-cont"
                                                                         onClick={deleteCoupon}>Yes
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            :
                                                            minusBatchCpShowSt ?
                                                                <div className="m-a-msgck-bt-container">
                                                                    <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                        <div className="m-a-gr-Bt-text font-t-cont"
                                                                             onClick={closeMinusBatchCpShow}>No
                                                                        </div>
                                                                    </button>
                                                                    <button
                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                        <div className="m-a-colorBt-text font-t-cont"
                                                                             onClick={() => saveBatchCp(false)}>Yes
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                                :
                                                                plusBatchCpShowSt ?
                                                                    <div className="m-a-msgck-bt-container">
                                                                        <button className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                            <div className="m-a-gr-Bt-text font-t-cont"
                                                                                 onClick={closePlusBatchCpShow}>No
                                                                            </div>
                                                                        </button>
                                                                        <button
                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                            <div
                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                onClick={() => saveBatchCp(true)}>Yes
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    cancelMsgShow ?
                                                                        <div className="m-a-msgck-bt-container">
                                                                            <button
                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                <div
                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                    onClick={closeCancelMsgShow}>No
                                                                                </div>
                                                                            </button>
                                                                            <button
                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                <div
                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                    onClick={() => cancelDepHistory(cancelDepHistorySeqNum)}>Yes
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        deleteReviewShow ?
                                                                            <div className="m-a-msgck-bt-container">
                                                                                <button
                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                    <div
                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                        onClick={closeDeleteReviewShow}>No
                                                                                    </div>
                                                                                </button>
                                                                                <button
                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                    <div
                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                        onClick={deleteReview}>Yes
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            deleteReviewCommentShow ?
                                                                                <div className="m-a-msgck-bt-container">
                                                                                    <button
                                                                                        className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                        <div
                                                                                            className="m-a-gr-Bt-text font-t-cont"
                                                                                            onClick={closeDeleteReviewCommentShow}>No
                                                                                        </div>
                                                                                    </button>
                                                                                    <button
                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                        <div
                                                                                            className="m-a-colorBt-text font-t-cont"
                                                                                            onClick={() => deleteReviewComment(deleteReviewCommentSeqNum)}>Yes
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                                :
                                                                                blockCommentShow ?
                                                                                    <div
                                                                                        className="m-a-msgck-bt-container">
                                                                                        <button
                                                                                            className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                            <div
                                                                                                className="m-a-gr-Bt-text font-t-cont"
                                                                                                onClick={closeBlockCommentShow}>No
                                                                                            </div>
                                                                                        </button>
                                                                                        <button
                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                            <div
                                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                                onClick={blockComment}>Yes
                                                                                            </div>
                                                                                        </button>
                                                                                    </div>
                                                                                    :
                                                                                    deleteNoticeMsgShow ?
                                                                                        <div
                                                                                            className="m-a-msgck-bt-container">
                                                                                            <button
                                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                <div
                                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                                    onClick={closeDeleteNoticeMsgShow}>No
                                                                                                </div>
                                                                                            </button>
                                                                                            <button
                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                <div
                                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                                    onClick={deleteNotice}>Yes
                                                                                                </div>
                                                                                            </button>
                                                                                        </div>
                                                                                        :
                                                                                        mstDeleteMsgShow ?
                                                                                            <div
                                                                                                className="m-a-msgck-bt-container">
                                                                                                <button
                                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                    <div
                                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                                        onClick={closeMstDeleteMsg}>No
                                                                                                    </div>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                    <div
                                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                                        onClick={() => deleteFaqMst(mstDeleteSeqNum, mstDeleteContent)}>Yes
                                                                                                    </div>
                                                                                                </button>
                                                                                            </div>
                                                                                            :
                                                                                            deleteFaqDtMsgShow ?
                                                                                                <div
                                                                                                    className="m-a-msgck-bt-container">
                                                                                                    <button
                                                                                                        className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                        <div
                                                                                                            className="m-a-gr-Bt-text font-t-cont"
                                                                                                            onClick={closeDeleteFaqDtMsgShow}>No
                                                                                                        </div>
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                        <div
                                                                                                            className="m-a-colorBt-text font-t-cont"
                                                                                                            onClick={deleteFaqDt}>Yes
                                                                                                        </div>
                                                                                                    </button>
                                                                                                </div>
                                                                                                :
                                                                                                depConfirmComplShow ?
                                                                                                    <div
                                                                                                        className="m-a-msgck-bt-container">
                                                                                                        <button
                                                                                                            className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                            <div
                                                                                                                className="m-a-gr-Bt-text font-t-cont"
                                                                                                                onClick={closeDepConfirmCompl}>No
                                                                                                            </div>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                            <div
                                                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                                                onClick={modifyDepConfirmCompl}>Yes
                                                                                                            </div>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    :
                                                                                                    paypalExDeleteShow ?
                                                                                                        <div
                                                                                                            className="m-a-msgck-bt-container">
                                                                                                            <button
                                                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                <div
                                                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                                                    onClick={closePaypalExDeleteShow}>No
                                                                                                                </div>
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                <div
                                                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                                                    onClick={() => deleteData(paypalExDeleteChecked)}>Yes
                                                                                                                </div>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                        :
                                                                                                        allCartProdStChangeShow ?
                                                                                                            <div
                                                                                                                className="m-a-msgck-bt-container">
                                                                                                                <button
                                                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                    <div
                                                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                                                        onClick={closeAllCartProdStChangeShow}>No
                                                                                                                    </div>
                                                                                                                </button>
                                                                                                                <button
                                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                    <div
                                                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                                                        onClick={handleAdminAllProdStChange}>Yes
                                                                                                                    </div>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            :
                                                                                                            allOrderProdStChangeShow ?
                                                                                                                <div
                                                                                                                    className="m-a-msgck-bt-container">
                                                                                                                    <button
                                                                                                                        className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                        <div
                                                                                                                            className="m-a-gr-Bt-text font-t-cont"
                                                                                                                            onClick={closeAllOrderProdStChangeShow}>No
                                                                                                                        </div>
                                                                                                                    </button>
                                                                                                                    <button
                                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                        <div
                                                                                                                            className="m-a-colorBt-text font-t-cont"
                                                                                                                            onClick={handleAdminAllProdStChange}>Yes
                                                                                                                        </div>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                                :
                                                                                                                allOfflineProdStChangeShow ?
                                                                                                                    <div
                                                                                                                        className="m-a-msgck-bt-container">
                                                                                                                        <button
                                                                                                                            className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                            <div
                                                                                                                                className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                onClick={closeAllOfflineProdStChangeShow}>No
                                                                                                                            </div>
                                                                                                                        </button>
                                                                                                                        <button
                                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                            <div
                                                                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                                                                onClick={handleAdminAllProdStChange}>Yes
                                                                                                                            </div>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    allTransferProdStChangeShow ?
                                                                                                                        <div
                                                                                                                            className="m-a-msgck-bt-container">
                                                                                                                            <button
                                                                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                <div
                                                                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                    onClick={closeAllTransferProdStChangeShow}>No
                                                                                                                                </div>
                                                                                                                            </button>
                                                                                                                            <button
                                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                <div
                                                                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                                                                    onClick={handleAdminAllProdStChange}>Yes
                                                                                                                                </div>
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        allDomesticProdStChangeShow ?
                                                                                                                            <div
                                                                                                                                className="m-a-msgck-bt-container">
                                                                                                                                <button
                                                                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                    <div
                                                                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                        onClick={closeAllDomesticProdStChangeShow}>No
                                                                                                                                    </div>
                                                                                                                                </button>
                                                                                                                                <button
                                                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                    <div
                                                                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                                                                        onClick={handleAdminAllProdStChange}>Yes
                                                                                                                                    </div>
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            allDeliveryProdStChangeShow ?
                                                                                                                                <div
                                                                                                                                    className="m-a-msgck-bt-container">
                                                                                                                                    <button
                                                                                                                                        className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                        <div
                                                                                                                                            className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                            onClick={closeAllDeliveryProdStChangeShow}>No
                                                                                                                                        </div>
                                                                                                                                    </button>
                                                                                                                                    <button
                                                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                        <div
                                                                                                                                            className="m-a-colorBt-text font-t-cont"
                                                                                                                                            onClick={handleAdminAllProdStChange}>Yes
                                                                                                                                        </div>
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                orderdtDepositPaySt ?
                                                                                                                                    <div
                                                                                                                                        className="m-a-msgck-bt-container">
                                                                                                                                        <button
                                                                                                                                            className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                            <div
                                                                                                                                                className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                onClick={closeOrderdtDepositPaySt}>No
                                                                                                                                            </div>
                                                                                                                                        </button>
                                                                                                                                        <button
                                                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                            <div
                                                                                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                                                                                onClick={() => orderdtAdminDpPay("deposit")}>Yes
                                                                                                                                            </div>
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    orderdtPointPaySt ?
                                                                                                                                        <div
                                                                                                                                            className="m-a-msgck-bt-container">
                                                                                                                                            <button
                                                                                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                <div
                                                                                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                    onClick={closeOrderdtPointPaySt}>No
                                                                                                                                                </div>
                                                                                                                                            </button>
                                                                                                                                            <button
                                                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                <div
                                                                                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                                                                                    onClick={() => orderdtAdminDpPay("point")}>Yes
                                                                                                                                                </div>
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        payinfoDepositPaySt ?
                                                                                                                                            <div
                                                                                                                                                className="m-a-msgck-bt-container">
                                                                                                                                                <button
                                                                                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                    <div
                                                                                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                        onClick={closePayinfoDepositPaySt}>No
                                                                                                                                                    </div>
                                                                                                                                                </button>
                                                                                                                                                <button
                                                                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                    <div
                                                                                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                                                                                        onClick={() => payinfoAdminDpPay("deposit")}>Yes
                                                                                                                                                    </div>
                                                                                                                                                </button>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            payinfoPointPaySt ?
                                                                                                                                                <div
                                                                                                                                                    className="m-a-msgck-bt-container">
                                                                                                                                                    <button
                                                                                                                                                        className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                        <div
                                                                                                                                                            className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                            onClick={closePayinfoPointPaySt}>No
                                                                                                                                                        </div>
                                                                                                                                                    </button>
                                                                                                                                                    <button
                                                                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                        <div
                                                                                                                                                            className="m-a-colorBt-text font-t-cont"
                                                                                                                                                            onClick={() => payinfoAdminDpPay("point")}>Yes
                                                                                                                                                        </div>
                                                                                                                                                    </button>
                                                                                                                                                </div>
                                                                                                                                                :
                                                                                                                                                adminOsReturnSt ?
                                                                                                                                                    <div
                                                                                                                                                        className="m-a-msgck-bt-container">
                                                                                                                                                        <button
                                                                                                                                                            className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                            <div
                                                                                                                                                                className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                onClick={closeOsReturnSt}>No
                                                                                                                                                            </div>
                                                                                                                                                        </button>
                                                                                                                                                        <button
                                                                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                            <div
                                                                                                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                onClick={() => deliveryReturn("overseas")}>Yes
                                                                                                                                                            </div>
                                                                                                                                                        </button>
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    adminDsReturnSt ?
                                                                                                                                                        <div
                                                                                                                                                            className="m-a-msgck-bt-container">
                                                                                                                                                            <button
                                                                                                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                                <div
                                                                                                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                    onClick={closeDsReturnSt}>No
                                                                                                                                                                </div>
                                                                                                                                                            </button>
                                                                                                                                                            <button
                                                                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                                <div
                                                                                                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                    onClick={() => deliveryReturn("domestic")}>Yes
                                                                                                                                                                </div>
                                                                                                                                                            </button>
                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        confirmPaySt ?
                                                                                                                                                            <div
                                                                                                                                                                className="m-a-msgck-bt-container">
                                                                                                                                                                <button
                                                                                                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                                    <div
                                                                                                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                        onClick={closeConfirmPaySt}>No
                                                                                                                                                                    </div>
                                                                                                                                                                </button>
                                                                                                                                                                <button
                                                                                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                                    <div
                                                                                                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                        onClick={() => payDataSucc(selectedSeqNum)}>Yes
                                                                                                                                                                    </div>
                                                                                                                                                                </button>
                                                                                                                                                            </div>
                                                                                                                                                            :
                                                                                                                                                            cancelPaySt ?
                                                                                                                                                                <div
                                                                                                                                                                    className="m-a-msgck-bt-container">
                                                                                                                                                                    <button
                                                                                                                                                                        className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                                        <div
                                                                                                                                                                            className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                            onClick={closeCancelPaySt}>Cancel>No
                                                                                                                                                                        </div>
                                                                                                                                                                    </button>
                                                                                                                                                                    <button
                                                                                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                                        <div
                                                                                                                                                                            className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                            onClick={() => payDataCancel(selectedSeqNum)}>Yes
                                                                                                                                                                        </div>
                                                                                                                                                                    </button>
                                                                                                                                                                </div>
                                                                                                                                                                :
                                                                                                                                                                allUpdateProdSt ?
                                                                                                                                                                    <div
                                                                                                                                                                        className="m-a-msgck-bt-container">
                                                                                                                                                                        <button
                                                                                                                                                                            className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                                            <div
                                                                                                                                                                                className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                                onClick={closeAllUpdateProdSt}>No
                                                                                                                                                                            </div>
                                                                                                                                                                        </button>
                                                                                                                                                                        <button
                                                                                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                                            <div
                                                                                                                                                                                className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                                onClick={() => updateProdArrival(checkedOrd)}>Yes
                                                                                                                                                                            </div>
                                                                                                                                                                        </button>
                                                                                                                                                                    </div>
                                                                                                                                                                    :
                                                                                                                                                                    updateProdSt ?
                                                                                                                                                                        <div
                                                                                                                                                                            className="m-a-msgck-bt-container">
                                                                                                                                                                            <button
                                                                                                                                                                                className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                                                <div
                                                                                                                                                                                    className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                                    onClick={closeUpdateProdSt}>No
                                                                                                                                                                                </div>
                                                                                                                                                                            </button>
                                                                                                                                                                            <button
                                                                                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                                                <div
                                                                                                                                                                                    className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                                    onClick={() => updateProdArrival(checkedOrd)}>Yes
                                                                                                                                                                                </div>
                                                                                                                                                                            </button>
                                                                                                                                                                        </div>
                                                                                                                                                                        :
                                                                                                                                                                        nUserRemoveSt ?
                                                                                                                                                                            <div
                                                                                                                                                                                className="m-a-msgck-bt-container">
                                                                                                                                                                                <button
                                                                                                                                                                                    className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                                                                                                                                                    <div
                                                                                                                                                                                        className="m-a-gr-Bt-text font-t-cont"
                                                                                                                                                                                        onClick={closeNUserRemoveSt}>No
                                                                                                                                                                                    </div>
                                                                                                                                                                                </button>
                                                                                                                                                                                <button
                                                                                                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                                                                                                                                                    <div
                                                                                                                                                                                        className="m-a-colorBt-text font-t-cont"
                                                                                                                                                                                        onClick={nUserAllChatRemove}>Yes
                                                                                                                                                                                    </div>
                                                                                                                                                                                </button>
                                                                                                                                                                            </div>
                                                                                                                                                                            :
                                                                                                                                                                        null
                }


            </div>
        </div>
    )
}

export default MMsgSelectModal
