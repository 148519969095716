import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../../css/web/AdminRegModal.css';
import {AiOutlineMinusSquare, AiOutlinePlusSquare} from "react-icons/ai";
import axios from '../../../services/axiosInstance';
import MsgSelectModal from "./MsgSelectModal";
import {useWebSocket} from "../../../services/WebsocketContext";
import CommonLoading from "./CommonLoading";
import maCateImg from "../../../assets/img/m-a-cate.png";
import orderIMG from "../../../assets/img/mypgPayinfo.png";
import {IoIosArrowDropdown} from "@react-icons/all-files/io/IoIosArrowDropdown";
import {IoIosArrowDropright} from "@react-icons/all-files/io/IoIosArrowDropright";
import {BiCaretDown} from "react-icons/bi";

function AdminRegModal() {

    /** region DataTable */

    const storedOrderData = localStorage.getItem('orderData');
    const initialOrderData = storedOrderData ? JSON.parse(storedOrderData) : null;
    const [orderData, setOrderData] = useState(initialOrderData);
    const [inputType, setInputType] = useState(orderData.dataType === 'payinfo' ? 'shipInfoDTO' : 'box');
    const LCAT = localStorage.getItem('adminToken');
    const [shipcoData, setShipcoData] = useState([]);
    const [selectedShipCompany, setSelectedShipCompany] = useState(orderData.shipco);
    const [selectedDataType, setSelectedDataType] = useState(orderData.dataType);
    const [fedexExData, setFedexExData] = useState([]);
    const [fedexEcoExData, setFedexEcoExData] = useState([]);
    const [emsExData, setEmsExData] = useState([]);
    const [emsPreExData, setEmsPreExData] = useState([]);
    const [shipExData, setShipExData] = useState([]);
    const [upsExData, setUpsExData] = useState([]);
    const [upsEcoExData, setUpsEcoExData] = useState([]);
    const [kpExData, setKpExData] = useState([]);
    const [asExData, setAsExData] = useState([]);
    const [domeExData, setDomeExData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /** endregion DataTable*/

    /** region Function */

    const [tables, setTables] = useState([]);
    const [tableCount, setTableCount] = useState(1);
    const [showOrdInputs, setShowOrdInputs] = useState(true);
    const [showCostInputs, setShowCostInputs] = useState(false);
    const [showItemInputs, setShowItemInputs] = useState(false);
    const [showBoxInputs, setShowBoxInputs] = useState(true);
    const [showBoxDtPrice, setShowBoxDtPrice] = useState(Array(tableCount).fill(false));
    const handleToggle = (index) => {
        setShowBoxDtPrice(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const [buyValue, setBuyValue] = useState(""); //구매비
    const [codValue, setCodValue] = useState(""); //착불비
    const [domeValue, setDomeValue] = useState(""); //국내반송비
    const [deliveryFeeValue, setDeliveryFeeValue] = useState(""); //배송대행수수료
    const [repackValue, setRePackValue] = useState(""); //재포장수수료
    const [agenciesValue, setAgenciesValue] = useState(""); //대행수수료
    const [vacPackPrice, setVacPackPrice] = useState(""); //압축팩수수료
    const [etcValue, setEtcValue] = useState(""); //기타비용
    const [etcNmValue, setEtcNmValue] = useState(""); //기타비용 내용
    const [etcFeeValue, setEtcFeeValue] = useState(""); //기타수수료
    const [etcFeeNmValue, setEtcFeeNmValue] = useState(""); //기타수수료 내용
    const [fedexFuelValue, setFedexFuelValue] = useState(() => {
        if (orderData && orderData.dataType === 'orderdt') {
            return orderData.recinfoDTO?.countryDTO?.fedexFuelSurcharge || '';
        } else if (orderData && orderData.dataType === 'payinfo') {
            return orderData.orderdtDTO?.recinfoDTO?.countryDTO?.fedexFuelSurcharge || '';
        }
        return '';
    });
    const [upsFuelValue, setUpsFuelValue] = useState(() => {
        if (orderData && orderData.dataType === 'orderdt') {
            return orderData.recinfoDTO?.countryDTO?.upsFuelSurcharge || '';
        } else if (orderData && orderData.dataType === 'payinfo') {
            return orderData.orderdtDTO?.recinfoDTO?.countryDTO?.upsFuelSurcharge || '';
        }
        return '';
    });

    //fedex 유류할증료
    // const [ffeeetcValue, setFfeeEtcValue] = useState(orderData.payInfoDTO?.[0]?.outOfArea || ""); //fedex 서비스 외 지역 비용
    // const [payTypeValue, setPayTypeValue] = useState([]); // PT0, PT2

    const [packSize, setPackSize] = useState('선택');
    const [vacPackPopup, setVacPackPopup] = useState(false);
    // const [vacuumPack, setVacuumPack] = useState(!!orderData.payInfoDTO?.[0]?.comprFee);
    // const [buyCheckbox, setBuyCheckbox] = useState(false);
    // const [codCheckbox, setCodCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.courierPay || false);
    // const [domeCheckbox, setDomeCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.returnFee);
    // const [deliveryFeeCheckbox, setDeliveryFeeCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.deliveryFee);
    // const [repackCheckbox, setRePackCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.repackFee);
    // const [agenciesCheckbox, setAgenciesCheckbox] = useState(!!orderData.payInfoDTO?.[0]?.agencyFee);

    const [vacuumPack, setVacuumPack] = useState(false);
    const [buyCheckbox, setBuyCheckbox] = useState(false);
    const [codCheckbox, setCodCheckbox] = useState(false);
    const [domeCheckbox, setDomeCheckbox] = useState(false);
    const [deliveryFeeCheckbox, setDeliveryFeeCheckbox] = useState(false);
    useEffect(() => {
        setDeliveryFeeCheckbox(deliveryFeeValue !== "" && parseInt(deliveryFeeValue) !== 0);
    }, [deliveryFeeValue]);
    const [repackCheckbox, setRePackCheckbox] = useState(false);
    const [agenciesCheckbox, setAgenciesCheckbox] = useState(false);
    const [etcCheckbox, setEtcCheckbox] = useState(false);
    const [etcFeeCheckbox, setEtcFeeCheckbox] = useState(false);
    const [fpackCheckbox, setFpackCheckbox] = useState(false);
    const [ffeeCheckbox, setFfeeCheckbox] = useState(false);
    const [efeeCheckbox, setEfeeCheckbox] = useState(false);
    const [emsAmount, setEmsAmount] = useState(false);
    const fpackValue = fpackCheckbox ? "Y" : "N";
    const [appliedWeights] = useState({E: "", F: "", S: ""});

    const [boxData, setBoxData] = useState(
        Array.from({ length: tableCount }, () => ({
            width: "",
            height: "",
            length: "",
            netWeight: "",
            appliedWeights: { E: "", F: "", S: "" },
            products: [] // 초기값을 빈 배열로 설정
        }))
    );

    const [appliedWeightsD] = useState({D: ""});
    const [boxDomeData, setBoxDomeData] = useState([{
        width: "",
        height: "",
        length: "",
        netWeight: "",
        appliedWeightsD: {D: ""}
    },]);

    useEffect(() => {
        const productsDTO =
            orderData.dataType === 'payinfo'
                ? (orderData.packListDTO?.productsDTO || [])
                : (orderData.productsDTO || []);

        const initialCheckedItems = productsDTO
            .filter((item) => item.prodSt === "IS05" || item.prodSt === "IS06")
            .map((item) => item.seqnum);

        setCheckedItems(initialCheckedItems);
        setIsAllChecked(initialCheckedItems.length === productsDTO.length);

        setBoxData((prev) =>
            prev.map((box, index) => ({
                ...box,
                products: initialCheckedItems.filter((seqnum) => seqnum % tableCount === index)
            }))
        );
    }, [orderData, tableCount]);

    const [disableOtherShipping, setDisableOtherShipping] = useState(false);
    const [disableFedex, setDisableFedex] = useState(false);

    const [fedexPayChk, setFedexPayChk] = useState(true);
    const [fedexEcoPayChk, setFedexEcoPayChk] = useState(true);
    const [emsPayChk, setEmsPayChk] = useState(true);
    const [emsPrePayChk, setEmsPrePayChk] = useState(true);
    const [seaPayChk, setSeaPayChk] = useState(true);
    const [upsPayChk, setUpsPayChk] = useState(true);
    const [upsEcoPayChk, setUpsEcoPayChk] = useState(true);
    const [kpPayChk, setKpPayChk] = useState(true);
    const [asPayChk, setAsPayChk] = useState(true);

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: {color: "#98B0D4", label: "입고대기"},
            IS01: {color: "#98B0D4", label: "결제대기(상품)"},
            IS02: {color: "#98B0D4", label: "결제중(상품)"},
            IS03: {color: "#FFB378", label: "결제완료(상품)"},
            IS04: {color: "#FF8600", label: "구매완료"},
            IS05: {color: "#6EDA60", label: "센터도착"},
            IS06: {color: "#98B0D4", label: "패킹중"},
            IS07: {color: "#98B0D4", label: "결제대기(배송)"},
            IS08: {color: "#98B0D4", label: "결제중(배송)"},
            IS09: {color: "#FFB378", label: "결제완료(배송)"},
            IS10: {color: "#979696", label: "출고완료"},
            IS11: {color: "#E05E6D", label: "반송중"},
            IS12: {color: "#E05E6D", label: "신청취소"},
            IS13: {color: "#98B0D4", label: "구매중"},
            IS14: {color: "#98B0D4", label: "입고중"}
        };

        const style = prodStStyles[prodSt] || {color: "black", label: null};
        return <p style={{color: style.color, fontSize: "14px"}}>{style.label}</p>;
    };

    const [userNm, setUserNm] = useState(orderData.dataType === 'payinfo' ? orderData.orderdtDTO.memberDTO.userName : orderData.memberDTO.userName);
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data[0])
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    function roundToTen(value) {
        const remainder = value % 10;
        if (remainder <= 5) {
            return value - remainder;
        } else {
            return value + (10 - remainder);
        }
    }

    useEffect(() => {
        selectAdminInfo();
    }, [])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        setTimeout(() => {
            const initializeConnection = async () => {
                if (!adminInfo.mbNum || isSubscribedRef.current) return;

                if (!stompClient && !isConnected) {
                    await websocketConnect(adminInfo.mbNum);
                }
                if (isConnected && stompClient && !isSubscribedRef.current) {
                    isSubscribedRef.current = true;
                }
            }

            initializeConnection();

            return () => {
                if (isSubscribedRef.current) {
                    isSubscribedRef.current = false;
                }
            };
        }, 3000)

    }, [adminInfo.mbNum, isConnected, stompClient]);

    //region CheckBox

    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);

    useEffect(() => {
        const productsDTO =
            orderData.dataType === 'payinfo'
                ? (orderData.packListDTO?.productsDTO || [])
                : (orderData.productsDTO || []);

        const initialCheckedItems = productsDTO
            .filter((item) => item.prodSt === "IS05" || item.prodSt === "IS06")
            .map((item) => item.seqnum);
        setCheckedItems(initialCheckedItems);
        setIsAllChecked(initialCheckedItems.length === productsDTO.length);

        setBoxData((prev) =>
            prev.map((box, index) => ({
                ...box,
                products: initialCheckedItems.filter((seqnum) => seqnum % tableCount === index)
            }))
        );
    }, [orderData, tableCount]);

    const handleAllCheck = (e) => {
        const { checked } = e.target;
        setIsAllChecked(checked);

        const productsDTO =
            orderData.dataType === 'payinfo'
                ? (orderData.packListDTO?.productsDTO || [])
                : (orderData.productsDTO || []);

        if (checked) {
            const allProductSeqnums = productsDTO
                .filter((item) => item.prodSt === "IS05" || item.prodSt === "IS06")
                .map((item) => item.seqnum);
            setCheckedItems(allProductSeqnums);

            setBoxData((prev) =>
                prev.map((box, index) => ({
                    ...box,
                    products: allProductSeqnums.filter((seqnum) => seqnum % tableCount === index)
                }))
            );
        } else {
            setCheckedItems([]);
            setBoxData((prev) => prev.map((box) => ({ ...box, products: [] })));
        }
    };

    const handleSingleCheck = (seqnum, isChecked, prodSt) => {
        if (prodSt === "IS05" || prodSt === "IS06") {
            setCheckedItems((prev) => {
                const newCheckedItems = isChecked
                    ? [...prev, seqnum]
                    : prev.filter((item) => item !== seqnum);

                setBoxData((prevBoxes) =>
                    prevBoxes.map((box, index) => ({
                        ...box,
                        products: newCheckedItems.filter((num) => num % tableCount === index)
                    }))
                );

                return newCheckedItems;
            });
        }
    };

    useEffect(() => {
        const productsDTO =
            orderData.dataType === 'payinfo'
                ? (orderData.packListDTO?.productsDTO || [])
                : (orderData.productsDTO || []);

        const allItems = productsDTO
            .filter((item) => item.prodSt === "IS05" || item.prodSt === "IS06")
            .map((item) => item.seqnum);

        setIsAllChecked(checkedItems.length === allItems.length);
    }, [checkedItems, orderData]);

    //endregion

    const handleFpackCheckboxChange = (index) => {
        const updatedBoxData = [...boxData];

        updatedBoxData[index]["fpackCheckbox"] = !updatedBoxData[index]["fpackCheckbox"];
        const isChecked = updatedBoxData[index]["fpackCheckbox"];

        if (isChecked) {
            setFedexPayChk(true);
            setUpsPayChk(false);
            setEmsPayChk(false);
            setEmsPrePayChk(false);
            setSeaPayChk(false);
            setKpPayChk(false);
            setAsPayChk(false);
            setDisableOtherShipping(true);
        } else {
            setFedexPayChk(true);
            setUpsPayChk(true);
            setEmsPayChk(true);
            setEmsPrePayChk(true);
            setSeaPayChk(true);
            setKpPayChk(true);
            setAsPayChk(true);
            setDisableOtherShipping(false);
        }

        setBoxData(updatedBoxData);
    };

    const handleBoxChange = (index, field, value) => {
        const updatedBoxData = [...boxData];
        const updatedBoxDomeData = [...boxDomeData];

        if (selectedShipCompany === '국내배송') {
            updatedBoxDomeData[index][field] = value;
            setBoxDomeData(updatedBoxDomeData);
        } else {
            if (field === "fpackCheckbox") {
                updatedBoxData[index][field] = !updatedBoxData[index][field];
            } else {
                updatedBoxData[index][field] = value;
            }

            if (field === "netWeight" && value !== "") {
                if (!updatedBoxData[index]["width"]) updatedBoxData[index]["width"] = "0";
                if (!updatedBoxData[index]["height"]) updatedBoxData[index]["height"] = "0";
                if (!updatedBoxData[index]["length"]) updatedBoxData[index]["length"] = "0";
            }

            const isAllZero =
                updatedBoxData[index]["width"] === "0" &&
                updatedBoxData[index]["height"] === "0" &&
                updatedBoxData[index]["length"] === "0";

            if (isAllZero) {
                setFedexPayChk(true);
                setSeaPayChk(true);
                setUpsPayChk(false);
                setEmsPayChk(false);
                setEmsPrePayChk(false);
                setKpPayChk(false);
                setAsPayChk(false);
                setDisableOtherShipping(true);
            } else {
                setDisableOtherShipping(false);
            }

            if (isAllZero && updatedBoxData[index]["netWeight"] > 1.5) {
                setFedexPayChk(false);
                setDisableFedex(true);
            } else {
                setDisableFedex(false);
            }

            setBoxData(updatedBoxData);
        }
    };

    const handleVacPackSizeChange = (event) => {
        const selectedSize = event.target.value;
        setPackSize(selectedSize);

        if (selectedSize === '소형') {
            setVacuumPack(true);
            setVacPackPrice(10000);
        } else if (selectedSize === '대형') {
            setVacuumPack(true);
            setVacPackPrice(20000);
        } else {
            setVacuumPack(false);
            setVacPackPrice(0);
        }
    };

    const handleFedexValueChange = (index, value) => {
        setBoxData(prevBoxes => {
            const newBoxes = [...prevBoxes];
            newBoxes[index].fedexValue = value === "" ? "" : parseFloat(value);
            return newBoxes;
        });
    };

    const handlePlusClick = () => {
        setTables([...tables, tableCount]);
        setTableCount(tableCount + 1);
        if (selectedShipCompany === '국내배송') {
            const newBoxDomeData = [...boxDomeData, {
                width: "",
                height: "",
                length: "",
                netWeight: "",
                appliedWeightsD: {D: ""}
            }];
            setBoxDomeData(newBoxDomeData);
        } else {
            setBoxData(prevBoxData => [...prevBoxData, {
                width: "",
                height: "",
                length: "",
                netWeight: "",
                appliedWeights: {E: "", F: "", S: "", D: "", P: "", K: "", A: ""},
                products: []
            }]);
        }
    };

    const handleMinusClick = () => {
        if (tables.length > 0) {
            setTables(tables.slice(0, -1));
            setTableCount(tableCount - 1);
            if (selectedShipCompany === '국내배송') {
                setBoxDomeData(boxDomeData.slice(0, -1));
            } else {
                // setBoxData(boxData.slice(0, -1));
                setBoxData(prevBoxData => prevBoxData.slice(0, -1));
            }
        }
    };

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow3, setAlertShow3] = useState(false);
    const msgConfirmData1 = "비용정보를 등록 하시겠습니까?";
    const msgConfirmData2 = "입력한 정보를 다시한번 확인해주세요.";
    const msgConfirmData5 = "입력된 정보를 저장 하시겠습니까?";
    const msgConfirmData6 = "수정된 정보를 다시한번 확인해주세요.";
    const alert = (() => {
        setAlertShow(true)
    })
    const alert3 = (() => {
        setAlertShow3(true)
    })
    const modalCancel1 = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel3 = useCallback(() => {
        setAlertShow3(false);
    })

    // endregion

    // region 여기서부터 국가 커스텀셀렉트

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: '수취국가선택'});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const countryRef = useRef(null);

    useEffect(() => {
        let defaultCountry = null;
        if (orderData) {
            if (orderData.recinfoDTO && orderData.recinfoDTO.countryDTO) {
                defaultCountry = {
                    value: orderData.recinfoDTO.countryDTO.countryCode,
                    label: `${orderData.recinfoDTO.countryDTO.countryNm}[${orderData.recinfoDTO.countryDTO.countryCode}]`,
                };
            } else if (orderData.appRecDTO && orderData.appRecDTO.countryDTO) {
                defaultCountry = {
                    value: orderData.appRecDTO.countryDTO.countryCode,
                    label: `${orderData.appRecDTO.countryDTO.countryNm}[${orderData.appRecDTO.countryDTO.countryCode}]`,
                };
            }
        }
        if (defaultCountry) {
            setSelectedCountry(defaultCountry);
            setSearchKeyword(defaultCountry.label);
        }
    }, [orderData]);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            if (selectedIndex < filteredCountryList.length - 1) {
                setSelectedIndex(selectedIndex + 1);
            }
        } else if (event.key === "ArrowUp") {
            if (selectedIndex > 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        } else if (event.key === "Enter") {
            if (selectedIndex >= 0) {
                handleCountryChange(filteredCountryList[selectedIndex], event);
            }
        }
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({ value: '', label: '수취국가선택'});
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (countryRef.current) {
            const itemElements = countryRef.current.querySelectorAll('.selectChildBt');
            const selectedItem = itemElements[selectedIndex];

            if (selectedItem) {
                const listContainer = countryRef.current.querySelector('.country-select-data');
                const containerHeight = listContainer.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemHeight = selectedItem.clientHeight;

                if (itemTop < 0) {
                    listContainer.scrollTop = itemTop;
                } else if (itemTop + itemHeight > containerHeight) {
                    listContainer.scrollTop = itemTop + itemHeight - containerHeight;
                }
            }
        }
    }, [selectedIndex]);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm}[${country.countryCode}]`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error fetching countries:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, []);

    // endregion 여기까지 커스텀 셀렉트

    // region 부피중량 / 적용중량 계산

    const [boxEmsResultFees, setBoxEmsResultFees] = useState(Array(boxData.length).fill(0));
    const [boxEmsPreResultFees, setBoxEmsPreResultFees] = useState(Array(boxData.length).fill(0));
    const [boxFedexResultFees, setBoxFedexResultFees] = useState(Array(boxData.length).fill(0));
    const [boxFedexEcoResultFees, setBoxFedexEcoResultFees] = useState(Array(boxData.length).fill(0));
    const [boxSeaResultFees, setBoxSeaResultFees] = useState(Array(boxData.length).fill(0));
    // const [boxDhlResultFees, setBoxDhlResultFees] = useState(Array(boxData.length).fill(0));
    const [boxUpsResultFees, setBoxUpsResultFees] = useState(Array(boxData.length).fill(0));
    const [boxUpsEcoResultFees, setBoxUpsEcoResultFees] = useState(Array(boxData.length).fill(0));
    const [boxKpResultFees, setBoxKpResultFees] = useState(Array(boxData.length).fill(0));
    const [boxAsResultFees, setBoxAsResultFees] = useState(Array(boxData.length).fill(0));

    const [selectedCountryFee, setSelectedCountryFee] = useState(null);

    useEffect(() => {
        if (selectedCountry.value) {
            axios.get('/country/selectFixCountry', { params: { countryCode: selectedCountry.value } })
                .then((res) => {
                    setSelectedCountryFee(res.data);
                })
                .catch((error) => {
                    console.error("수수료에러: ", error);
                });
        }
    }, [selectedCountry.value]);

    const getShippingFees = () => {
        const countryDTO = selectedCountryFee
            ? selectedCountryFee
            : (orderData.recinfoDTO && orderData.recinfoDTO.countryDTO
                ? orderData.recinfoDTO.countryDTO
                : (orderData.appRecDTO && orderData.appRecDTO.countryDTO
                    ? orderData.appRecDTO.countryDTO
                    : null));

        return {
            emsFee: countryDTO ? parseFloat(countryDTO.emsFee || 0) : 0,
            emsPreFee: countryDTO ? parseFloat(countryDTO.emsPreFee || 0) : 0,
            fedexFee: countryDTO ? parseFloat(countryDTO.fedexFee || 0) : 0,
            fedexEcoFee: countryDTO ? parseFloat(countryDTO.fedexEcoFee || 0) : 0,
            seaFee: countryDTO ? parseFloat(countryDTO.seaFee || 0) : 0,
            upsFee: countryDTO ? parseFloat(countryDTO.upsFee || 0) : 0,
            upsEcoFee: countryDTO ? parseFloat(countryDTO.upsEcoFee || 0) : 0,
            kpFee: countryDTO ? parseFloat(countryDTO.kpackFee || 0) : 0,
            asFee: countryDTO ? parseFloat(countryDTO.airSFee || 0) : 0
        };
    };

    const {emsFee, emsPreFee, fedexFee, fedexEcoFee, seaFee, upsFee, upsEcoFee, kpFee, asFee} = getShippingFees();

    const [emsFeeState, setEmsFee] = useState(emsFee);
    const [emsPreFeeState, setEmsPreFee] = useState(emsPreFee);
    const [fedexFeeState, setFedexFee] = useState(fedexFee);
    const [fedexEcoFeeState, setFedexEcoFee] = useState(fedexEcoFee);
    const [seaFeeState, setSeaFee] = useState(seaFee);
    // const [dhlFeeState, setDhlFee] = useState(dhlFee);
    const [upsFeeState, setUpsFee] = useState(upsFee);
    const [upsEcoFeeState, setUpsEcoFee] = useState(upsEcoFee);
    const [kpFeeState, setKpFee] = useState(kpFee);
    const [asFeeState, setAsFee] = useState(asFee);

    const appliedWeightE = parseFloat(appliedWeights.E);
    const [emsResultFee, setEmsResultFee] = useState(emsFee * appliedWeightE * 10);

    const appliedWeightF = parseFloat(appliedWeights.F);
    const [fedexResultFee, setFedexResultFee] = useState(fedexFee * appliedWeightF * 10);

    const appliedWeightS = parseFloat(appliedWeights.S);
    const [seaResultFee, setSeaResultFee] = useState(seaFee * appliedWeightS * 10);

    // const appliedWeightD = parseFloat(appliedWeights.D);
    // const [dhlResultFee, setDhlResultFee] = useState(dhlFee * appliedWeightD * 10);

    const appliedWeightP = parseFloat(appliedWeights.P);
    const [upsResultFee, setUpsResultFee] = useState(upsFee * appliedWeightP * 10);

    const appliedWeightK = parseFloat(appliedWeights.K);
    const [kpResultFee, setKpResultFee] = useState(kpFee * appliedWeightK * 10);

    const appliedWeightA = parseFloat(appliedWeights.A);
    const [asResultFee, setAsResultFee] = useState(asFee * appliedWeightA * 10);

    const getVolumeWeightFedex = (width, height, length) => {
        return ((width * height * length) / 5000).toFixed(2);
    };

    const getVolumeWeightEms = (width, height, length) => {
        return ((width * height * length) / 6000).toFixed(2);
    };

    // const getVolumeWeightDhl = (width, height, length) => {
    //     return ((width * height * length) / 5000).toFixed(2);
    // };

    const getVolumeWeightUps = (width, height, length) => {
        return ((width * height * length) / 5000).toFixed(2);
    };

    const getVolumeWeightDome = (width, height, length) => {
        return ((width + height + length) / 5000).toFixed(1);
    };

    const fedexThresholds = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5,
        16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
        61, 62, 63, 64, 65, 66, 67, 68, 68, 70];

    const emsThresholds = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14,
        14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5,
        28, 28.5, 29, 29.5, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
        61, 62, 63, 64, 65, 66, 67, 68, 68, 70];

    const upsThresholds = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14,
        14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 21, 22, 23, 24, 25, 26, 27,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
        61, 62, 63, 64, 65, 66, 67, 68, 68, 70];

    const seaThresholds = [1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20];
    const kpAsThresholds = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2];

    const domeThresholds = [3, 5, 10, 20, 25];

    const applyWeightBands = (volumeWeight, thresholds) => {
        return thresholds.find(threshold => volumeWeight <= threshold) || thresholds[thresholds.length - 1];
    };

    const applyWeightDomeBands = (volumeWeight, thresholds) => {
        return thresholds.find(threshold => volumeWeight <= threshold) || thresholds[thresholds.length - 1];
    };

    const getAmountForWeight = (appliedWeight, data) => {
        const weightColumn = `w${Math.round(appliedWeight * 1000)}`;
        const dataObject = data.find(item => item[weightColumn] !== undefined);
        return dataObject ? dataObject[weightColumn] : 0;
    };

    const getAmountForDomeWeight = (appliedWeight, data) => {
        const weightColumn = `w${Math.round(appliedWeight * 1000)}`;
        const dataObject = data.find(item => item[weightColumn] !== undefined);
        return dataObject ? dataObject[weightColumn] : 0;
    };

    const roundUp = (value, unit) => {
        return Math.ceil(value / unit) * unit;
    };

    const apyWeightCalculation = (dvType, wid, len, hei, realWeight) => {
        const width = parseFloat(wid);
        const height = parseFloat(hei);
        const length = parseFloat(len);
        const netWeight = parseFloat(realWeight);

        if (isNaN(width) || isNaN(height) || isNaN(length) || isNaN(netWeight)) return 0;

        let volWeight, baseWeight, finalWeight;

        switch (dvType) {
            // FEDEX, FEDEX-ECO, UPS, UPS-ECO, DHL, 국내
            case "D01": case "D16": case "D11": case "D17": case "D04":
                volWeight = (width * length * height) / 5000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = baseWeight <= 21
                    ? roundUp(baseWeight, 0.5)
                    : roundUp(baseWeight, 1);
                break;
            case "D02": // EMS
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                const gram = baseWeight * 1000;
                finalWeight = gram <= 2000
                    ? roundUp(gram, 250) / 1000
                    : roundUp(gram, 500) / 1000;
                break;
            case "D15": // EMS 프리미엄
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = baseWeight <= 20
                    ? roundUp(baseWeight, 0.5)
                    : roundUp(baseWeight, 1);
                break;
            case "D13": // K-Packet
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = roundUp(baseWeight * 1000, 100) / 1000;
                break;
            case "D14": // Air Small
                finalWeight = roundUp(netWeight, 0.1);
                break;
            case "D03": // SEA
                const weightGram = netWeight * 1000;
                finalWeight = weightGram <= 1000
                    ? roundUp(weightGram, 1000) / 1000
                    : roundUp(weightGram, 2000) / 1000;
                break;
            case "D00": // 국내배송
                volWeight = (width * length * height) / 5000;
                baseWeight = Math.max(netWeight, volWeight);
                if (baseWeight <= 3) {
                    finalWeight = 3;
                } else if (baseWeight <= 5) {
                    finalWeight = 5;
                } else if (baseWeight <= 10) {
                    finalWeight = 10;
                } else if (baseWeight <= 20) {
                    finalWeight = 20;
                } else {
                    finalWeight = 25;
                }
                break;
            default:
                finalWeight = 0;
        }

        return parseFloat(finalWeight.toFixed(2));
    };

    useEffect(() => {
        const { emsFee, emsPreFee, fedexFee, fedexEcoFee, seaFee, upsFee, upsEcoFee, kpFee, asFee } = getShippingFees();
        const updatedBoxData = [...boxData];
        let totalEmsFees = Array(updatedBoxData.length).fill(0);
        let totalEmsPreFees = Array(updatedBoxData.length).fill(0);
        let totalFedexFees = Array(updatedBoxData.length).fill(0);
        let totalFedexEcoFees = Array(updatedBoxData.length).fill(0);
        let totalSeaFees = Array(updatedBoxData.length).fill(0);
        // let totalDhlFees = Array(updatedBoxData.length).fill(0);
        let totalUpsFees = Array(updatedBoxData.length).fill(0);
        let totalUpsEcoFees = Array(updatedBoxData.length).fill(0);
        let totalKpFees = Array(updatedBoxData.length).fill(0);
        let totalAsFees = Array(updatedBoxData.length).fill(0);

        for (let i = 0; i < updatedBoxData.length; i++) {
            const width = parseFloat(updatedBoxData[i].width);
            const height = parseFloat(updatedBoxData[i].height);
            const length = parseFloat(updatedBoxData[i].length);
            const netWeight = parseFloat(updatedBoxData[i].netWeight);

            if (!isNaN(width) && !isNaN(height) && !isNaN(length)) {
                const apyWeightFedex = apyWeightCalculation("D01", width, length, height, netWeight);
                const apyWeightUps = apyWeightCalculation("D11", width, length, height, netWeight);
                const apyWeightEms = apyWeightCalculation("D02", width, length, height, netWeight);
                const apyWeightKp = apyWeightCalculation("D13", width, length, height, netWeight);
                const apyWeightAs = apyWeightCalculation("D14", width, length, height, netWeight);
                const apyWeightSea = apyWeightCalculation("D03", width, length, height, netWeight);

                console.log("apyWeightFedex : ", apyWeightFedex);
                console.log("apyWeightEms : ", apyWeightEms);
                console.log("apyWeightSea : ", apyWeightSea);

                updatedBoxData[i].appliedWeights = {
                    F: apyWeightFedex,
                    P: apyWeightUps,
                    E: apyWeightEms,
                    K: apyWeightKp,
                    A: apyWeightAs,
                    S: apyWeightSea
                };
                totalFedexFees[i] = fedexFee * apyWeightFedex * 10;
                totalFedexEcoFees[i] = fedexEcoFee * apyWeightFedex * 10;
                totalUpsFees[i] = upsFee * apyWeightUps * 10;
                totalUpsEcoFees[i] = upsEcoFee * apyWeightUps * 10;
                totalEmsFees[i] = emsFee * apyWeightEms * 10;
                totalEmsPreFees[i] = emsPreFee * apyWeightEms * 10;
                totalKpFees[i] = kpFee * apyWeightKp * 10;
                totalAsFees[i] = asFee * apyWeightAs * 10;
                totalSeaFees[i] = seaFee * apyWeightSea * 10;
            }
        }
        setBoxFedexResultFees(totalFedexFees);
        setBoxFedexEcoResultFees(totalFedexEcoFees);
        setBoxUpsResultFees(totalUpsFees);
        setBoxUpsEcoResultFees(totalUpsEcoFees);
        setBoxEmsResultFees(totalEmsFees);
        setBoxEmsPreResultFees(totalEmsPreFees);
        // setBoxDhlResultFees(totalDhlFees);
        setBoxKpResultFees(totalKpFees);
        setBoxAsResultFees(totalAsFees);
        setBoxSeaResultFees(totalSeaFees);

        if (JSON.stringify(updatedBoxData) !== JSON.stringify(boxData)) {
            setBoxData(updatedBoxData);
        }
    }, [boxData, selectedCountryFee]);

    useEffect(() => {
        const updatedBoxDomeData = [...boxDomeData];

        for (let i = 0; i < updatedBoxDomeData.length; i++) {
            const width = parseFloat(updatedBoxDomeData[i].width);
            const height = parseFloat(updatedBoxDomeData[i].height);
            const length = parseFloat(updatedBoxDomeData[i].length);
            const netWeight = parseFloat(updatedBoxDomeData[i].netWeight);


            if (!isNaN(width) && !isNaN(height) && !isNaN(length)) {
                const weightDom = apyWeightCalculation("D00", width, length, height, netWeight);

                updatedBoxDomeData[i].appliedWeightsD = {D: weightDom};
            }
        }
        if (JSON.stringify(updatedBoxDomeData) !== JSON.stringify(boxDomeData)) {
            setBoxDomeData(updatedBoxDomeData);
        }
    }, [boxDomeData]);

    // endregion

    const [isOutArea, setIsOutArea] = useState(false);

    useEffect(() => {
        if (
            orderData &&
            orderData.recinfoDTO &&
            orderData.recinfoDTO.countryDTO &&
            orderData.recinfoDTO.zipcode
        ) {
            const orderCountry = orderData.recinfoDTO.countryDTO.countryCode;
            const orderZip = orderData.recinfoDTO.zipcode;
            getOutAreaStatus(orderCountry, orderZip).then((exists) => {
                setIsOutArea(exists);
            });
        }}, [orderData]);

    const outAreaCharge = isOutArea ? 30000 : 0;

    const calculateTotalFEDEXCost = () => {
        let totalCost = 0;
        let totalFuelFees = 0;
        let totalOutAreaChar = 0;

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData));
                totalFuelFees += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100));
                totalOutAreaChar += outAreaCharge;
            } else {
                totalCost += Math.round(parseFloat(box.fedexValue || 0));
            }
        });

        const totalFees = boxFedexResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        return (totalCost + totalFees + totalFuelFees + totalOutAreaChar).toLocaleString();
    };

    const calculateTotalFEDEXECost = () => {
        let totalCost = 0;
        let totalFuelFees = 0;

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexEcoExData));
                totalFuelFees += roundToTen(getAmountForWeight(box.appliedWeights.F, fedexEcoExData) * (fedexFuelValue / 100));
            } else {
                totalCost += Math.round(parseFloat(box.fedexValue || 0));
            }
        });

        const totalFees = boxFedexEcoResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        return (totalCost + totalFees + totalFuelFees).toLocaleString();
    };

    //페덱스 30kg 초과시 ㄱ
    const calculateFedexPrice = (weight, groupCode) => {
        if (weight > 30) {
            if (groupCode === 'U') { //뉴질랜드, 호주
                if (groupCode === 'U' && orderData.recinfoDTO.countryDTO.countryCode === 'NZ') {
                    const roundedWeight = Math.ceil(weight);
                    return roundedWeight * 14900;
                } else if (groupCode === 'U' && orderData.recinfoDTO.countryDTO.countryCode === 'AU') {
                    return weight * 7900;
                }
            }
            if (groupCode === 'M') { //독일
                if (groupCode === 'M' && orderData.recinfoDTO.countryDTO.countryCode === 'DE') {
                    const roundedWeight = Math.ceil(weight);
                    return roundedWeight * 14200;
                }
            }
            if (groupCode === 'F') { //미국, 캐나다, 멕시코
                const roundedWeight = Math.ceil(weight);
                return roundedWeight * 11900;
            }
            if (groupCode === 'P') { //일본
                const roundedWeight = Math.ceil(weight);
                return roundedWeight * 8200;
            }
        }
        return 0;
    };

    const getFedexCalculatedValue = (box, fedexExData) => {
        const groupCode = fedexExData && fedexExData[0] ? fedexExData[0].appGroup : null;
        const weight = box.appliedWeights && box.appliedWeights.F ? box.appliedWeights.F : 0;
        return calculateFedexPrice(weight, groupCode);
    };

    const calculateTotalUPSCost = () => {
        let totalCost = 0;
        let totalFees = boxUpsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 70) {
                totalCost += getAmountForWeight(box.appliedWeights.P, upsExData);
            } else {
                totalFees -= boxUpsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalUPSECost = () => {
        let totalCost = 0;
        let totalFees = boxUpsEcoResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 70) {
                totalCost += getAmountForWeight(box.appliedWeights.P, upsEcoExData);
            } else {
                totalFees -= boxUpsEcoResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalEMSCost = () => {
        let totalCost = 0;
        let totalFees = boxEmsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.E, emsExData);
            } else {
                totalFees -= boxEmsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalEMSPCost = () => {
        let totalCost = 0;
        let totalFees = boxEmsPreResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 70) {
                totalCost += getAmountForWeight(box.appliedWeights.E, emsPreExData);
            } else {
                totalFees -= boxEmsPreResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalSHIPCost = () => {
        let totalCost = 0;
        let totalFees = boxSeaResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 20) {
                totalCost += getAmountForWeight(box.appliedWeights.S, shipExData);
            } else {
                totalFees -= boxSeaResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalKPACKCost = () => {
        let totalCost = 0;
        let totalFees = boxKpResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.K, kpExData);
            } else {
                totalFees -= boxKpResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalAIRSCost = () => {
        let totalCost = 0;
        let totalFees = boxAsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);

        boxData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForWeight(box.appliedWeights.A, asExData);
            } else {
                totalFees -= boxAsResultFees.reduce((totalFee, fee) => totalFee + parseInt(fee, 10), 0);
            }
        });

        return (totalCost + totalFees).toLocaleString();
    };

    const calculateTotalDOMECost = () => {
        let totalCost = 0;
        boxDomeData.forEach(box => {
            if (parseFloat(box.netWeight) <= 30) {
                totalCost += getAmountForDomeWeight(box.appliedWeightsD.D, domeExData);
            }
        });
        return (totalCost).toLocaleString();
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        if (selectedCountry.value) {
            shipCompanySelect();
            fedexExrSelect();
            fedexEcoExrSelect();
            upsExrSelect();
            upsEcoExrSelect();
            emsExrSelect();
            emsPreExrSelect();
            shipExrSelect();
            kpackExrSelect();
            airSExrSelect();
        }
    }, [selectedCountry.value]);

    const selectPackSize = (price) => {
        if (price === 10000) {
            setPackSize('소형');
        } else if (price === 20000) {
            setPackSize('대형');
        } else {
            setPackSize('Select');
        }
    };

    useEffect(() => {
        if (vacPackPrice !== '') {
            selectPackSize(parseInt(vacPackPrice));
        }
    }, [vacPackPrice]);

    const shipCompanySelect = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + '1',
    })
        .then((response) => {
            setShipcoData(response.data)
        })

    const fedexExrSelect = () => {
        axios.get('/shipinfo/fedexexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const fCountryCode = response.data.filter(cData => {
                    return cData.appGroup === countryCode ||
                        (cData.appGroup === 'A' && countryCode === 'MO') ||
                        (cData.appGroup === 'D' && ['KH', 'LA', 'MN', 'BN', 'GU', 'RO', 'TI'].includes(countryCode)) ||
                        (cData.appGroup === 'F' && ['CA', 'US', 'MX', 'PR'].includes(countryCode)) ||
                        (cData.appGroup === 'G' && ['AT', 'CZ', 'DK', 'FI', 'GR', 'HU', 'IE', 'NO', 'PL', 'PT', 'SE', 'CH', 'CZ', 'FO', 'GL', 'LI', 'LU', 'MC', 'SK'].includes(countryCode)) ||
                        (cData.appGroup === 'H' && ['IL', 'TR', 'RU', 'UA', 'UZ', 'AL', 'AD', 'AM', 'AZ', 'BY', 'BA', 'BG', 'HR', 'CY', 'EE', 'GI', 'GE', 'IS', 'KG', 'KZ', 'LV', 'LT', 'MK', 'MT', 'MD', 'ME', 'RO', 'RS', 'SI'].includes(countryCode)) ||
                        (cData.appGroup === 'I' && ['AR', 'BR', 'CL', 'PE', 'AS', 'AI', 'AG', 'AW', 'BS', 'BB', 'BB', 'BZ', 'BM', 'BO', 'BQ', 'VG', 'KY', 'CO', 'CK', 'CR', 'CW', 'DM', 'BL', 'KN', 'SR', 'PF', 'TO', 'VG', 'TT', 'VC', 'VU', 'VE', 'MH', 'MQ', 'FM', 'MS', 'KN', 'NC', 'NI', 'VG', 'MP', 'PW', 'PG', 'PY', 'BQ', 'MP', 'WS', 'DO', 'TL', 'EC', 'SV', 'FJ', 'GD', 'GP', 'GY', 'HT', 'HN', 'JM'].includes(countryCode)) ||
                        (cData.appGroup === 'J' && ['BD', 'MA', 'QA', 'AE', 'JO', 'SA', 'AF', 'DZ', 'AO', 'BH', 'BJ', 'BT', 'BF', 'BI', 'CV', 'CM', 'BW', 'TD', 'CG', 'CD', 'DJ', 'ZW', 'ZM', 'YE', 'TZ', 'TN', 'UG', 'SZ', 'LK', 'ZA', 'SY', 'TG', 'SN', 'SC', 'RW', 'RE', 'QA', 'PS', 'PK', 'OM', 'NG', 'NE', 'NP', 'MZ', 'NA', 'MU', 'MR', 'ML', 'MV', 'MW', 'MG', 'EG', 'ER', 'ET', 'GA', 'GM', 'GH', 'GN', 'IQ', 'CI', 'KE', 'KW', 'LB', 'LS', 'LR', 'LY'].includes(countryCode)) ||
                        (cData.appGroup === 'M' && ['BE', 'DE', 'IT', 'NL', 'ES', 'GB', 'FR', 'IC', 'JE', 'VA', 'SM'].includes(countryCode)) ||
                        (cData.appGroup === 'N' && countryCode === 'VN') ||
                        (cData.appGroup === 'O' && countryCode === 'IN') ||
                        (cData.appGroup === 'P' && countryCode === 'JP') ||
                        (cData.appGroup === 'Q' && countryCode === 'MY') ||
                        (cData.appGroup === 'R' && countryCode === 'TH') ||
                        (cData.appGroup === 'S' && countryCode === 'PH') ||
                        (cData.appGroup === 'T' && countryCode === 'ID') ||
                        (cData.appGroup === 'U' && ['AU', 'NZ', 'NF'].includes(countryCode)) ||
                        (cData.appGroup === 'V' && countryCode === 'HK') ||
                        (cData.appGroup === 'W' && countryCode === 'CN') ||
                        (cData.appGroup === 'X' && countryCode === 'TW') ||
                        (cData.appGroup === 'Y' && countryCode === 'SG');
                });
                setFedexExData(fCountryCode);
            })
            .catch((error) => {
                console.error("err:", error);
            });
    }

    const fedexEcoExrSelect = () => {
        axios.get('/shipinfo/fedexEcoexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const fCountryCode = response.data.filter(cData => {
                    return cData.appGroup === countryCode ||
                        (cData.appGroup === 'A' && countryCode === 'MO') ||
                        (cData.appGroup === 'D' && ['KH', 'LA', 'MN', 'BN', 'GU', 'RO', 'TI'].includes(countryCode)) ||
                        (cData.appGroup === 'F' && ['CA', 'US', 'MX', 'PR'].includes(countryCode)) ||
                        (cData.appGroup === 'G' && ['AT', 'CZ', 'DK', 'FI', 'GR', 'HU', 'IE', 'NO', 'PL', 'PT', 'SE', 'CH', 'CZ', 'FO', 'GL', 'LI', 'LU', 'MC', 'SK'].includes(countryCode)) ||
                        (cData.appGroup === 'H' && ['IL', 'TR', 'RU', 'UA', 'UZ', 'AL', 'AD', 'AM', 'AZ', 'BY', 'BA', 'BG', 'HR', 'CY', 'EE', 'GI', 'GE', 'IS', 'KG', 'KZ', 'LV', 'LT', 'MK', 'MT', 'MD', 'ME', 'RO', 'RS', 'SI'].includes(countryCode)) ||
                        (cData.appGroup === 'I' && ['AR', 'BR', 'CL', 'PE', 'AS', 'AI', 'AG', 'AW', 'BS', 'BB', 'BB', 'BZ', 'BM', 'BO', 'BQ', 'VG', 'KY', 'CO', 'CK', 'CR', 'CW', 'DM', 'BL', 'KN', 'SR', 'PF', 'TO', 'VG', 'TT', 'VC', 'VU', 'VE', 'MH', 'MQ', 'FM', 'MS', 'KN', 'NC', 'NI', 'VG', 'MP', 'PW', 'PG', 'PY', 'BQ', 'MP', 'WS', 'DO', 'TL', 'EC', 'SV', 'FJ', 'GD', 'GP', 'GY', 'HT', 'HN', 'JM'].includes(countryCode)) ||
                        (cData.appGroup === 'J' && ['BD', 'MA', 'QA', 'AE', 'JO', 'SA', 'AF', 'DZ', 'AO', 'BH', 'BJ', 'BT', 'BF', 'BI', 'CV', 'CM', 'BW', 'TD', 'CG', 'CD', 'DJ', 'ZW', 'ZM', 'YE', 'TZ', 'TN', 'UG', 'SZ', 'LK', 'ZA', 'SY', 'TG', 'SN', 'SC', 'RW', 'RE', 'QA', 'PS', 'PK', 'OM', 'NG', 'NE', 'NP', 'MZ', 'NA', 'MU', 'MR', 'ML', 'MV', 'MW', 'MG', 'EG', 'ER', 'ET', 'GA', 'GM', 'GH', 'GN', 'IQ', 'CI', 'KE', 'KW', 'LB', 'LS', 'LR', 'LY'].includes(countryCode)) ||
                        (cData.appGroup === 'M' && ['BE', 'DE', 'IT', 'NL', 'ES', 'GB', 'FR', 'IC', 'JE', 'VA', 'SM'].includes(countryCode)) ||
                        (cData.appGroup === 'N' && countryCode === 'VN') ||
                        (cData.appGroup === 'O' && countryCode === 'IN') ||
                        (cData.appGroup === 'P' && countryCode === 'JP') ||
                        (cData.appGroup === 'Q' && countryCode === 'MY') ||
                        (cData.appGroup === 'R' && countryCode === 'TH') ||
                        (cData.appGroup === 'S' && countryCode === 'PH') ||
                        (cData.appGroup === 'T' && countryCode === 'ID') ||
                        (cData.appGroup === 'U' && ['AU', 'NZ', 'NF'].includes(countryCode)) ||
                        (cData.appGroup === 'V' && countryCode === 'HK') ||
                        (cData.appGroup === 'W' && countryCode === 'CN') ||
                        (cData.appGroup === 'X' && countryCode === 'TW') ||
                        (cData.appGroup === 'Y' && countryCode === 'SG');
                });
                setFedexEcoExData(fCountryCode);
            })
            .catch((error) => {
                console.error("err:", error);
            });
    }

    const upsExrSelect = () => {
        axios.get('/shipinfo/upsexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const countryGroups = {
                    Z1: ["SG", "TW", "MO"],
                    Z2: ["JP", "VN"],
                    Z3: ["ID", "TH", "MY", "PH"],
                    Z4: ["NZ", "AU", "NF", "IN"],
                    Z5: ["US", "CA", "MX"],
                    Z6: ["GB", "FR", "DE", "ES", "NL", "PL", "LU", "LI", "MC", "VA", "BE", "SM", "SE", "CH", "SK", "AD", "IT", "CZ"],
                    Z7: ["AT", "IE", "GR", "NO", "DK", "PT", "FI"],
                    Z8: ["TR", "RU", "LA", "CL", "AE", "KH", "BD", "SA", "PE", "GP", "GT", "GU", "GD", "GF", "ZA", "NC", "NI", "DO", "DM", "TL", "LV", "RO", "LT", "MQ", "MH", "MS", "MV", "MT", "MM", "FM", "VU", "BH", "BB", "BS", "VI", "VG", "VE", "BZ", "BW", "BO", "BT", "BG", "BR", "WS", "AS", "CY", "LC", "VC", "KN", "SB", "SR", "LK", "SI", "AW", "AR", "IS", "SV", "AZ", "AG", "AI", "ER", "EC", "OM", "HN", "WF", "UY", "UA", "EG", "JM", "GI", "QA", "CR", "CO", "KW", "HR", "TO", "PA", "PY", "PK", "PG", "PW", "FJ", "HU"],
                    Z9: ["IL", "MN", "GH", "GA", "GY", "GM", "GL", "GN", "GW", "NA", "NG", "NP", "NE", "LR", "LS", "RE", "RW", "MG", "YT", "MW", "ML", "MA", "MU", "MR", "MZ", "ME", "MD", "BM", "BJ", "BY", "BA", "BI", "BF", "MK", "BW", "MP", "SN", "RS", "SC", "SL", "AM", "AF", "AL", "DZ", "AO", "SZ", "EE", "ET", "JO", "UG", "UZ", "IQ", "ZM", "CF", "DJ", "ZW", "TD", "CM", "CV", "KZ", "KE", "CI", "CG", "TZ", "TG", "TN"],
                    Z0: ["CN", "HK"]
                };

                const sCountryCode = response.data.filter(cData => {
                    return cData.appUpsCode === countryCode ||
                        Object.entries(countryGroups).some(([upsCode, countries]) =>
                            countries.includes(countryCode) && cData.appUpsCode === upsCode
                        );
                });

                setUpsExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const upsEcoExrSelect = () => {
        axios.get('/shipinfo/upsEcoexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const countryGroups = {
                    Z1: ["SG", "TW", "MO"],
                    Z2: ["JP", "VN"],
                    Z3: ["ID", "TH", "MY", "PH"],
                    Z4: ["NZ", "AU", "NF", "IN"],
                    Z5: ["US", "CA", "MX"],
                    Z6: ["GB", "FR", "DE", "ES", "NL", "PL", "LU", "LI", "MC", "VA", "BE", "SM", "SE", "CH", "SK", "AD", "IT", "CZ"],
                    Z7: ["AT", "IE", "GR", "NO", "DK", "PT", "FI"],
                    Z8: ["TR", "RU", "LA", "CL", "AE", "KH", "BD", "SA", "PE", "GP", "GT", "GU", "GD", "GF", "ZA", "NC", "NI", "DO", "DM", "TL", "LV", "RO", "LT", "MQ", "MH", "MS", "MV", "MT", "MM", "FM", "VU", "BH", "BB", "BS", "VI", "VG", "VE", "BZ", "BW", "BO", "BT", "BG", "BR", "WS", "AS", "CY", "LC", "VC", "KN", "SB", "SR", "LK", "SI", "AW", "AR", "IS", "SV", "AZ", "AG", "AI", "ER", "EC", "OM", "HN", "WF", "UY", "UA", "EG", "JM", "GI", "QA", "CR", "CO", "KW", "HR", "TO", "PA", "PY", "PK", "PG", "PW", "FJ", "HU"],
                    Z9: ["IL", "MN", "GH", "GA", "GY", "GM", "GL", "GN", "GW", "NA", "NG", "NP", "NE", "LR", "LS", "RE", "RW", "MG", "YT", "MW", "ML", "MA", "MU", "MR", "MZ", "ME", "MD", "BM", "BJ", "BY", "BA", "BI", "BF", "MK", "BW", "MP", "SN", "RS", "SC", "SL", "AM", "AF", "AL", "DZ", "AO", "SZ", "EE", "ET", "JO", "UG", "UZ", "IQ", "ZM", "CF", "DJ", "ZW", "TD", "CM", "CV", "KZ", "KE", "CI", "CG", "TZ", "TG", "TN"],
                    Z0: ["CN", "HK"]
                };

                const sCountryCode = response.data.filter(cData => {
                    return cData.appUpsEcoCode === countryCode ||
                        Object.entries(countryGroups).some(([upsEcoCode, countries]) =>
                            countries.includes(countryCode) && cData.appUpsEcoCode === upsEcoCode
                        );
                });

                setUpsEcoExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const emsExrSelect = () => {
        axios.get('/shipinfo/emsexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt' ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo' ? orderData.appRecDTO.countryDTO.countryCode : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const eCountryCode = response.data.filter(cData => {
                    return cData.countryDTO.countryCode === countryCode ||
                        ((countryCode === 'LA' || countryCode === 'MO') && cData.seqNum === 21) ||
                        (countryCode === 'BD' && cData.seqNum === 22) ||
                        (['IE', 'NL', 'PL', 'CH', 'IL', 'AE'].includes(countryCode) && cData.seqNum === 23) ||
                        (['PE', 'CL', 'MX'].includes(countryCode) && cData.seqNum === 24);
                });

                setEmsExData(eCountryCode);
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }

    const emsPreExrSelect = () => {
        axios.get('/shipinfo/emsPreexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appEmsPreCode === countryCode ||
                        (["CN", "HK", "MO", "TW"].includes(countryCode) && cData.appEmsPreCode === 'Z1') ||

                        (["BN", "KH", "JP", "LA", "MY", "SG", "TH", "VN"].includes(countryCode) && cData.appEmsPreCode === 'Z2') ||

                        (["AL", "AM", "AU", "AT", "AZ", "BH", "BE", "BG", "BA", "CA", "HR", "CZ", "DK", "GR",
                            "HU", "IS", "IN", "ID", "IE", "IT", "LV", "LU", "MV", "MD", "MN", "NL", "NZ", "MK", "NO",
                            "PG", "PH", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "CH", "TR", "US", "AE", "GB", "BY", "BT"
                        ].includes(countryCode) && cData.appEmsPreCode === 'Z3') ||

                        (["DZ", "AO", "BS", "BB", "BJ", "BM", "BO", "BW", "BF", "CM", "CV", "TD", "CL", "CO",
                            "CR", "CI", "DJ", "EG", "ER", "ET", "FJ", "GT", "GY", "HT", "HN", "SV", "IL", "JM", "JO",
                            "KW", "LS", "LR", "ML", "MX", "MZ", "NI", "NE", "NG", "OM", "PK", "PA", "PY", "QA", "RW", "TZ", "TG", "ZM"
                        ].includes(countryCode) && cData.appEmsPreCode === 'Z4') ||

                        (["RU"].includes(countryCode) && cData.appEmsPreCode === 'ZR') ||

                        (["AF", "AD", "AG", "AR", "AW", "BD", "BZ", "BR", "BI", "CF", "CG", "DM", "DO", "TL", "GA",
                            "GM", "GH", "GI", "GL", "GD", "GP", "GU", "GF", "GN", "GW", "IQ", "KZ", "KE", "SZ", "KG",
                            "LB", "LI", "LT", "MG", "MW", "MT", "MP", "MQ", "MR", "MU", "YT", "FM", "MC", "MS", "MA",
                            "NA", "NP", "NC", "NF", "PW", "PE", "ZA", "RE", "ME", "KN", "LC", "VC", "AS", "SM", "SA",
                            "SN", "SC", "SL", "LK", "SR", "RS", "TO", "TN", "UG", "UA", "UY", "UZ", "VU", "VE", "VI", "VG", "WF", "WS", "ZW"
                        ].includes(countryCode) && cData.appEmsPreCode === 'Z5');
                });
                setEmsPreExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const shipExrSelect = () => {
        axios.get('/shipinfo/shipexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const sCountryCode = response.data.filter(cData => {
                    return cData.appShipCode === countryCode ||
                        (['CN', 'HK', 'JP', 'TW'].includes(countryCode) && cData.appShipCode === 'Z1') ||
                        (['BD', 'ID', 'MY', 'PH', 'SG', 'TH', 'VN'].includes(countryCode) && cData.appShipCode === 'Z2') ||
                        (['AU', 'CA', 'FL', 'FR', 'DE', 'IN', 'IE', 'NL', 'NO', 'PL', 'RU', 'ES', 'SE', 'US', 'GB', 'IE', 'NZ'].includes(countryCode) && cData.appShipCode === 'Z3') ||
                        (['BZ', 'PE', 'CL', 'MX'].includes(countryCode) && cData.appShipCode === 'Z4');
                });
                setShipExData(sCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const kpackExrSelect = () => {
        axios.get('/shipinfo/kpexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const kCountryCode = response.data.filter(cData => {
                    return cData.countryDTO.countryCode === countryCode;
                });

                setKpExData(kCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const airSExrSelect = () => {
        axios.get('/shipinfo/airsexrdata')
            .then((response) => {
                // const countryCode = orderData.type === 'orderdt'
                //     ? orderData.recinfoDTO.countryDTO.countryCode
                //     : orderData.type === 'payinfo'
                //         ? orderData.appRecDTO.countryDTO.countryCode
                //         : null;
                const countryCode = selectedCountry.value;
                if (!countryCode) return;

                const aCountryCode = response.data.filter(cData => {
                    return cData.appAsCode === countryCode ||
                        (["CN", "HK", "JP", "MO", "TW"].includes(countryCode) && cData.appAsCode === 'Z1') ||
                        (["BD", "BN", "KH", "TL", "ID", "LA", "MY", "MN", "MM", "PH", "SG", "TW", "VN"].includes(countryCode) && cData.appAsCode === 'Z2') ||
                        (["AF", "AL", "AD", "AM", "AU", "AT", "AZ", "BH", "BY", "BE", "BT", "BG", "BA",
                            "CA", "CX", "CC", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "GE", "DE", "GI", "GR",
                            "GL", "GU", "HU", "IS", "IL", "IT", "JO", "KZ", "KW", "KG", "LV", "LB", "LI", "LT",
                            "LU", "MV", "MT", "MP", "MD", "MC", "NP", "NL", "NZ", "NU", "NF", "MK", "NO", "OM",
                            "PK", "PG", "PL", "PT", "QA", "RO", "RU", "ME", "SM", "SA", "SK", "SI", "ES", "LK",
                            "SE", "CH", "RS", "TR", "US", "UA", "AE", "GB", "UZ", "VA"
                        ].includes(countryCode) && cData.appAsCode === 'Z3') ||
                        (["DZ", "AO", "AG", "AR", "AW", "BS", "BB", "BZ", "BJ", "BM", "BW", "BR", "BF", "BI",
                            "CM", "CV", "CF", "TD", "CL", "CO", "CG", "CR", "CI", "CU", "DJ", "DM", "DO", "CG", "EG",
                            "GQ", "ER", "ET", "FJ", "FR", "GA", "GM", "GH", "GD", "GP", "GT", "GF", "GN", "GW", "GY",
                            "HN", "JM", "KE", "SZ", "LS", "LR", "MG", "MW", "ML", "MQ", "MR", "MU", "YT", "MX", "FM",
                            "MS", "MA", "MZ", "NA", "NR", "NC", "NI", "NE", "NG", "PW", "PE", "ZA", "RE", "RW", "KN",
                            "AS", "ST", "SN", "SC", "SB", "SO", "SD", "SR", "TZ", "TG", "TO", "TN", "UG", "UY", "VU",
                            "VE", "VI", "VG", "WF", "WS", "ZM", "ZW",
                        ].includes(countryCode) && cData.appAsCode === 'Z4');
                });
                setAsExData(aCountryCode);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    useEffect(() => {
        const fetchDomeExData = async () => {
            try {
                const response = await axios.get("/shipinfo/domeexrdata");
                const volumeRanges = {
                    "V08": {min: 1, max: 80},
                    "V10": {min: 81, max: 100},
                    "V12": {min: 101, max: 120},
                    "V14": {min: 121, max: 140},
                    "V16": {min: 141, max: 160},
                    "V20": {min: 161, max: 200},
                };
                const totalVolume = parseFloat(boxDomeData[0].width) + parseFloat(boxDomeData[0].height) + parseFloat(boxDomeData[0].length);

                const filteredData = response.data.filter(item => {
                    const range = volumeRanges[item.volumes];
                    return totalVolume >= range.min && totalVolume <= range.max;
                });

                setDomeExData(filteredData);
            } catch (error) {
                console.error("Error: ", error);
            }
        };
        fetchDomeExData();
    }, [boxDomeData]);

    const boxesData = boxData.map((box, index) => ({
        wid: parseFloat(box.width || 0),
        ver: parseFloat(box.height || 0),
        hei: parseFloat(box.length || 0),
        weight: parseFloat(box.netWeight || 0),
        fVweight: parseFloat(getVolumeWeightFedex(box.width, box.height, box.length || 0)),
        fApyWeight: parseFloat(box.appliedWeights.F || 0),
        fedexPack: box.fpackCheckbox ? '1' : '0',
        eVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length || 0)),
        eApyWeight: parseFloat(box.appliedWeights.E || 0),
        sApyWeight: parseFloat(box.netWeight || 0),
        // dhVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length  || 0)),
        // dhApyWeight: parseFloat(box.appliedWeights.D || 0),
        pVweight: parseFloat(getVolumeWeightEms(box.width, box.height, box.length || 0)),
        pApyWeight: parseFloat(box.appliedWeights.P || 0),
        kApyWeight: parseFloat(box.netWeight || 0),
        aApyWeight: parseFloat(box.netWeight || 0),
        dVweight: 0,
        dApyWeight: 0,
    }));

    const boxesDomeData = boxDomeData.map((box, index) => ({
        wid: parseFloat(box.width || 0),
        ver: parseFloat(box.height || 0),
        hei: parseFloat(box.length || 0),
        weight: parseFloat(box.netWeight || 0),
        fVweight: 0,
        fApyWeight: 0,
        fedexPack: '0',
        eVweight: 0,
        eApyWeight: 0,
        sApyWeight: 0,
        dhVweight: 0,
        dhApyWeight: 0,
        pVweight: 0,
        pApyWeight: 0,
        kApyWeight: 0,
        aApyWeight: 0,
        dVweight: (!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) + (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) + (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0),
        dApyWeight: parseFloat(box.appliedWeightsD.D || 0),
    }));

    const shipModiInfoData = {
        seqNum: (orderData.dataType === 'payinfo') ? orderData.shipInfoDTO.seqNum : undefined,
    };

    const shipInfoData = boxesData.map((box, index) => ({
        boxNum: `B${(index + 1).toString().padStart(4, '0')}`,
        wid: box.wid || 0,
        ver: box.ver || 0,
        hei: box.hei || 0,
        weight: box.weight,

        fedexWeight: fedexPayChk ? box.fVweight : 0,
        fedexApyWeight: fedexPayChk ? box.fApyWeight : 0,
        fedexEcoWeight: fedexPayChk ? box.fVweight : 0,
        fedexEcoApyWeight: fedexPayChk ? box.fApyWeight : 0,
        fedexPack: fedexPayChk ? box.fedexPack : 0,

        emsWeight: emsPayChk ? box.eVweight : 0,
        emsApyWeight: emsPayChk ? box.eApyWeight : 0,

        emsPreWeight: emsPrePayChk ? box.eVweight : 0,
        emsPreApyWeight: emsPrePayChk ? box.eApyWeight : 0,

        seaApyWeight: seaPayChk ? box.sApyWeight : 0,

        dhlWeight: 0,
        dhlApyWeight: 0,

        upsWeight: upsPayChk ? box.pVweight : 0,
        upsApyWeight: upsPayChk ? box.pApyWeight : 0,
        upsEcoWeight: upsPayChk ? box.pVweight : 0,
        upsEcoApyWeight: upsPayChk ? box.pApyWeight : 0,

        kpackApyWeight: kpPayChk ? box.kApyWeight : 0,

        airSApyWeight: asPayChk ? box.aApyWeight : 0
    }));

    const shipInfoDomeData = boxesDomeData.map((box, index) => ({
        boxNum: `B${(index + 1).toString().padStart(4, '0')}`,
        wid: box.wid || 0,
        ver: box.ver || 0,
        hei: box.hei || 0,
        weight: box.weight,
        fedexWeight: box.fVweight,
        fedexApyWeight: box.fApyWeight,
        fedexEcoWeight: box.fVweight,
        fedexEcoApyWeight: box.fApyWeight,
        fedexPack: box.fedexPack,
        emsWeight: box.eVweight,
        emsApyWeight: box.eApyWeight,
        emsPreWeight: box.eVweight,
        emsPreApyWeight: box.eApyWeight,
        seaApyWeight: box.sApyWeight,
        dhlWeight: 0,
        dhlApyWeight: 0,
        upsWeight: box.pVweight,
        upsApyWeight: box.pApyWeight,
        upsEcoWeight: box.pVweight,
        upsEcoApyWeight: box.pApyWeight,
        kpackApyWeight: box.kApyWeight,
        airSApyWeight: box.aApyWeight,
        domeWeight: box.dVweight,
        domeApyWeight: box.dApyWeight,
    }));

    const orderDtData = {
        seqnum: (orderData.dataType === 'orderdt') ? orderData.seqnum : orderData.orderdtDTO.seqnum,
        orderStatus: 'OS02'
    };

    const payInfoBuyData = {
        ttProdPrice: buyValue,
    };

    const tEp = (Number(etcValue) || 0) + (Number(codValue) || 0) + (Number(domeValue) || 0);
    const tEf = (Number(etcFeeValue) || 0) + (Number(deliveryFeeValue) || 0) + (Number(repackValue) || 0) + (Number(agenciesValue) || 0) + (Number(vacPackPrice) || 0);

    const payInfoEtcData = {
        courierPay: codValue ? codValue : 0,//착불비
        returnFee: domeValue ? domeValue : 0, //인천반송비
        deliveryFee: deliveryFeeValue ? deliveryFeeValue : 0, //배송대행수수료
        repackFee: repackValue ? repackValue : 0, //재포장수수료
        agencyFee: agenciesValue ? agenciesValue : 0, //대행수수료
        comprFee: vacPackPrice ? vacPackPrice : 0, //압축팩수수료
        etcPrice: etcValue ? etcValue : 0, //기타비용
        etcPriceNm: etcNmValue ? etcNmValue : '', //기타비용명
        ttEtcPrice: tEp, //총기타비용
        etcFee: etcFeeValue ? etcFeeValue : 0, //기타수수료
        etcFeeNm: etcFeeNmValue ? etcFeeNmValue : '', //기타수수료명
        ttEtcFee: tEf, //총기타수수료
        lastPrice: tEp + tEf
    };

    const [initialPayInfoBoxData, setInitialPayInfoBoxData] = useState();

    const getPayInfoBoxData = (selectedShipCompany, boxData) => {
        return boxData.map((box, index) => {
            const fedexPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.F > 30
                ? getFedexCalculatedValue(box, fedexExData)
                : roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData));
            const fedexFuelSurch = parseFloat(box.netWeight) > 30 || box.appliedWeights.F > 30 ? 0 : Math.round((fedexPrice * (fedexFuelValue / 100)) / 10) * 10;
            const fedexAddFee = parseInt(boxFedexResultFees[index]);
            const fedexOutOfArea = outAreaCharge;
            const ttFedexPrice = fedexPrice + fedexFuelSurch + fedexAddFee + outAreaCharge;

            const fedexEcoPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.F > 30
                ? getFedexCalculatedValue(box, fedexEcoExData)
                : roundToTen(getAmountForWeight(box.appliedWeights.F, fedexEcoExData));
            const fedexEcoFuelSurch = parseFloat(box.netWeight) > 30 || box.appliedWeights.F > 30 ? 0 : Math.round((fedexEcoPrice * (fedexFuelValue / 100)) / 10) * 10;
            const ttFedexEcoPrice = fedexEcoPrice + fedexEcoFuelSurch + fedexAddFee;

            const upsPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : getAmountForWeight(box.appliedWeights.P, upsExData);
            const upsEcoPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : getAmountForWeight(box.appliedWeights.P, upsEcoExData);
            const upsFuelSurch = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : Math.round((upsPrice * (upsFuelValue / 100)) / 10) * 10;
            const upsEcoFuelSurch = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : Math.round((upsEcoPrice * (upsFuelValue / 100)) / 10) * 10;
            const upsAddFee = parseFloat(box.netWeight) > 30 || box.appliedWeights.P > 30 ? 0 : parseInt(boxUpsResultFees[index]);
            const ttUpsPrice = upsPrice + upsFuelSurch + upsAddFee;
            const ttUpsEcoPrice = upsEcoPrice + upsEcoFuelSurch + upsAddFee;

            const emsPrice = parseFloat(box.netWeight) > 30 || box.appliedWeights.E > 30 ? 0 : getAmountForWeight(box.appliedWeights.E, emsExData);
            const emsPrePrice = parseFloat(box.netWeight) > 70 || box.appliedWeights.E > 70 ? 0 : getAmountForWeight(box.appliedWeights.E, emsPreExData);
            const emsAddFee = parseFloat(box.netWeight) > 30 || box.appliedWeights.E > 30 ? 0 : parseInt(boxEmsResultFees[index]);
            const emsPreAddFee = parseFloat(box.netWeight) > 70 || box.appliedWeights.E > 70 ? 0 : parseInt(boxEmsPreResultFees[index]);
            const ttEmsPrice = emsPrice + emsAddFee;
            const ttEmsPrePrice = emsPrePrice + emsPreAddFee;

            const shipPrice = parseFloat(box.netWeight) > 20 ? 0 : getAmountForWeight(box.appliedWeights.S, shipExData);
            const seaAddFee = parseFloat(box.netWeight) > 20 ? 0 : parseInt(boxSeaResultFees[index]);
            const ttShipPrice = shipPrice + seaAddFee;

            const kPackPrice = parseFloat(box.netWeight) > 2 && box.appliedWeights.E > 2 ? 0 : getAmountForWeight(box.appliedWeights.K, kpExData);
            const kpAddFee = parseFloat(box.netWeight) > 2 ? 0 : parseInt(boxKpResultFees[index]);
            const ttKPackPrice = kPackPrice + kpAddFee;

            const asPrice = parseFloat(box.netWeight) > 2 && box.appliedWeights.E > 2 ? 0 : getAmountForWeight(box.appliedWeights.A, asExData);
            const asAddFee = parseFloat(box.netWeight) > 2 ? 0 : parseInt(boxAsResultFees[index]);
            const ttAirSPrice = asPrice + asAddFee;

            const ttDomPrice = 0;
            let lastPrice;

            const finalFedexPrice = fedexPayChk ? ttFedexPrice : 0;
            const finalFedexEcoPrice = fedexPayChk ? ttFedexEcoPrice : 0;
            const finalUpsPrice = upsPayChk ? ttUpsPrice : 0;
            const finalUpsEcoPrice = upsPayChk ? ttUpsEcoPrice : 0;
            const finalEmsPrice = emsPayChk ? ttEmsPrice : 0;
            const finalEmsPrePrice = emsPrePayChk ? ttEmsPrePrice : 0;
            const finalShipPrice = seaPayChk ? ttShipPrice : 0;
            const finalKPackPrice = kpPayChk ? ttKPackPrice : 0;
            const finalAirSPrice = asPayChk ? ttAirSPrice : 0;

            const defFedexPrice = fedexPayChk ? fedexPrice : 0;
            const defFedexEcoPrice = fedexPayChk ? fedexEcoPrice : 0;
            const defUpsPrice = upsPayChk ? upsPrice : 0;
            const defUpsEcoPrice = upsPayChk ? upsEcoPrice : 0;
            const defEmsPrice = emsPayChk ? emsPrice : 0;
            const defEmsPrePrice = emsPrePayChk ? emsPrePrice : 0;
            const defShipPrice = seaPayChk ? shipPrice : 0;
            const defKPackPrice = kpPayChk ? kPackPrice : 0;
            const defAirSPrice = asPayChk ? asPrice : 0;

            const defFedexFuelSurch = fedexPayChk ? fedexFuelSurch : 0;
            const defFedexEcoFuelSurch = fedexPayChk ? fedexEcoFuelSurch : 0;
            const defUpsFuelSurch = upsPayChk ? upsFuelSurch : 0;
            const defUpsEcoFuelSurch = upsPayChk ? upsEcoFuelSurch : 0;
            const defFedexAddFee = fedexPayChk ? fedexAddFee : 0;
            const defFedexEcoAddFee  = fedexPayChk ? fedexAddFee : 0;
            const defUpsAddFee  = upsPayChk ? upsAddFee : 0;
            const defUpsEcoAddFee  = upsPayChk ? upsAddFee : 0;
            const defEmsAddFee  = emsPayChk ? emsAddFee : 0;
            const defEmsPreAddFee  = emsPrePayChk ? emsPreAddFee : 0;
            const defShipAddFee  = seaPayChk ? seaAddFee : 0;
            const defKPackAddFee = kpPayChk ? kpAddFee : 0;
            const defAirSAddFee  = asPayChk ? asAddFee : 0;

            let shipCompanyCode;
            switch (selectedShipCompany) {
                case "FedEx":
                    shipCompanyCode = "D01";
                    break;
                case "FedEx-E":
                    shipCompanyCode = "D16";
                    break;
                case "UPS":
                    shipCompanyCode = "D11";
                    break;
                case "UPS-E":
                    shipCompanyCode = "D17";
                    break;
                case "EMS":
                    shipCompanyCode = "D02";
                    break;
                case "EMSP":
                    shipCompanyCode = "D15";
                    break;
                case "K-Packet":
                    shipCompanyCode = "D13";
                    break;
                case "Small-Packet":
                    shipCompanyCode = "D14";
                    break;
                case "SEA":
                    shipCompanyCode = "D03";
                    break;
                case "국내배송":
                    shipCompanyCode = "D00";
                    break;
                default:
                    shipCompanyCode = "";
            }

            switch (selectedShipCompany) {
                case "FedEx":
                    lastPrice = finalFedexPrice;
                    break;
                case "FedEx-E":
                    lastPrice = finalFedexEcoPrice;
                    break;
                case "UPS":
                    lastPrice = finalUpsPrice;
                    break;
                case "UPS-E":
                    lastPrice = finalUpsEcoPrice;
                    break;
                case "EMS":
                    lastPrice = finalEmsPrice;
                    break;
                case "EMSP":
                    lastPrice = finalEmsPrePrice;
                    break;
                case "K-Packet":
                    lastPrice = finalKPackPrice;
                    break;
                case "Small-Packet":
                    lastPrice = finalAirSPrice;
                    break;
                case "SEA":
                    lastPrice = finalShipPrice;
                    break;
                default:
                    lastPrice = 0;
            }

            return {
                fedexPrice: defFedexPrice,
                fedexEcoPrice: defFedexEcoPrice,
                upsPrice: defUpsPrice,
                upsEcoPrice: defUpsEcoPrice,
                emsPrice: defEmsPrice,
                emsPrePrice: defEmsPrePrice,
                seaPrice: defShipPrice,
                dhlPrice: 0,
                kpackPrice: defKPackPrice,
                airSPrice: defAirSPrice,
                fedexFuelSurch: defFedexFuelSurch,
                fedexOutOfArea: fedexOutOfArea,
                fedexEcoFuelSurch: defFedexEcoFuelSurch,
                ttFedexPrice: finalFedexPrice,
                ttFedexEcoPrice: finalFedexEcoPrice,
                ttUpsPrice: finalUpsPrice,
                ttUpsEcoPrice: finalUpsEcoPrice,
                ttEmsPrice: finalEmsPrice,
                ttEmsPrePrice: finalEmsPrePrice,
                ttSeaPrice: finalShipPrice,
                ttDhlPrice: 0,
                dhlFuelSurch: 0,
                upsFuelSurch: defUpsFuelSurch,
                upsEcoFuelSurch: defUpsEcoFuelSurch,
                ttKPackPrice: finalKPackPrice,
                ttAirSPrice: finalAirSPrice,
                fedexAddFee: defFedexAddFee,
                fedexEcoAddFee: defFedexEcoAddFee,
                emsAddFee: defEmsAddFee,
                emsPreAddFee: defEmsPreAddFee,
                seaAddFee: defShipAddFee,
                dhlAddFee: 0,
                upsAddFee: defUpsAddFee,
                upsEcoAddFee: defUpsEcoAddFee,
                kpackAddFee: defKPackAddFee,
                airSAddFee: defAirSAddFee,
                ttDomPrice: ttDomPrice,
                lastPrice: lastPrice,
                payStatus: 'PS0',
                dvType: shipCompanyCode,
            };
        });
    };

    const getPayInfoBoxDomeData = (boxDomeData) => {
        return boxDomeData.map((box, index) => {
            const fedexPrice = 0;
            const fedexEcoPrice = 0;
            const emsPrice = 0;
            const emsPrePrice = 0;
            const shipPrice = 0;
            const dhlPrice = 0;
            const upsPrice = 0;
            const upsEcoPrice = 0;
            const kPackPrice = 0;
            const asPrice = 0;
            const fedexFuelSurch = 0;
            const fedexOutOfArea = 0;
            const fedexEcoFuelSurch = 0;
            const upsFuelSurch = 0;
            const upsEcoFuelSurch = 0;
            const ttFedexPrice = 0;
            const ttEmsPrice = 0;
            const ttDhlPrice = 0;
            const ttUpsPrice = 0;
            const ttKPackPrice = 0;
            const ttAirSPrice = 0;
            const emsAddFee = 0;
            const fedexAddFee = 0;
            const seaAddFee = 0;
            const upsAddFee = 0;
            const kPackAddFee = 0;
            const airSAddFee = 0;
            const ttDomPrice = parseFloat(box.netWeight) > 30 ? 0 : getAmountForDomeWeight(box.appliedWeightsD.D, domeExData);

            return {
                fedexPrice: fedexPrice,
                fedexEcoPrice: fedexPrice,
                emsPrice: emsPrice,
                emsPrePrice: emsPrice,
                seaPrice: shipPrice,
                dhlPrice: dhlPrice,
                upsPrice: upsPrice,
                upsEcoPrice: upsPrice,
                kpackPrice: kPackPrice,
                airSPrice: asPrice,
                fedexFuelSurch: fedexFuelSurch,
                fedexOutOfArea: fedexOutOfArea,
                fedexEcoFuelSurch: fedexEcoFuelSurch,
                ttFedexPrice: ttFedexPrice,
                ttFedexEcoPrice: ttFedexPrice,
                ttEmsPrice: ttEmsPrice,
                ttEmsPrePrice: ttEmsPrice,
                ttSeaPrice: shipPrice,
                ttDhlPrice: ttDhlPrice,
                dhlFuelSurch: 0,
                upsFuelSurch: upsFuelSurch,
                upsEcoFuelSurch: upsEcoFuelSurch,
                ttUpsPrice: ttUpsPrice,
                ttUpsEcoPrice: ttUpsPrice,
                ttKPackPrice: ttKPackPrice,
                ttAirSPrice: ttAirSPrice,
                fedexAddFee: fedexAddFee,
                fedexEcoAddFee: fedexAddFee,
                emsAddFee: emsAddFee,
                emsPreAddFee: emsAddFee,
                seaAddFee: seaAddFee,
                dhlAddFee: 0,
                upsAddFee: upsAddFee,
                upsEcoAddFee: upsAddFee,
                kpackAddFee: kPackAddFee,
                airSAddFee: airSAddFee,
                ttDomPrice: ttDomPrice,
                lastPrice: ttDomPrice,
                payStatus: 'PS2',
                dvType: 'D00'
            };
        });
    };

    const appReqData = {
        seqnum: (orderData.reqmnDTO ? orderData.reqmnDTO.seqnum : (orderData.appReqDTO ? orderData.appReqDTO.seqnum : null))
    };

    const appRecData = {
        seqnum: orderData.recinfoDTO
            ? orderData.recinfoDTO.seqnum
            : orderData.appRecDTO
                ? orderData.appRecDTO.seqnum
                : null,
        selectedCountry: selectedCountry.value
    };

    const expRegComplete = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            const selectedProductData = boxData.flatMap(box => box.products);
            const selectedProducts = (orderData.productsDTO || orderData.packListDTO?.productsDTO || [])
                .filter(item =>
                    ['IS05', 'IS06'].includes(item.prodSt) && selectedProductData.includes(item.seqnum)
                );

            const productData = selectedProducts.map(product => ({
                seqnum: product.seqnum,
                prodSt: 'IS07'
            }));

            // console.log("국내배송 금액 전체콘솔: ", getPayInfoBoxDomeData(boxDomeData));
            // console.log("해외배송 금액 전체콘솔: ", getPayInfoBoxData(selectedShipCompany, boxData));
            // console.log("박스무게: ", shipInfoData)

            const productInfoMData = orderData.packListDTO ?
                orderData.packListDTO.productsDTO.map(product => ({
                    seqnum: product.seqnum,
                    prodSt: 'IS07'
                })) : [];

            const selectedData = selectedShipCompany === "국내배송" ? shipInfoDomeData : shipInfoData;
            const productInfoData = selectedDataType === "orderdt" ? productData : productInfoMData;
            const formData = new FormData();

            const dataToAppend = {
                orderDtData,
                payInfoBuyData,
                payInfoEtcData,
                payInfoBoxData: selectedShipCompany === "국내배송" ? getPayInfoBoxDomeData(boxDomeData) : getPayInfoBoxData(selectedShipCompany, boxData),
                shipInfoData: selectedData,
                productInfoData,
                appReqData,
                appRecData,
                shipModiInfoData,
                dataType: orderData.dataType
            };

            Object.entries(dataToAppend).forEach(([key, value]) => {
                formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
            });

            const response = await axios.post('/ordPay/expreginsert', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                }
            });

            if (selectedDataType === "orderdt" && stompClient && (Number(boxData[0].netWeight) > 0 || Number(boxesDomeData[0].weight) > 0)) {
                await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: orderData.memberDTO.mbNum}));
                sendMessage();
            }

            setInitialPayInfoBoxData(selectedShipCompany === "국내배송" ? getPayInfoBoxDomeData(boxDomeData) : getPayInfoBoxData(selectedShipCompany, boxData));

            if (window.opener) {
                window.opener.location.reload();
                window.close();
            }
        } catch (error) {
            console.error("Error r:", error.response);
            console.error("Error m:", error.message);
            console.error("Error c:", error.config);
        } finally {
            setIsLoading(false);
        }
    };

    const getOutAreaStatus = async (countryCode, zipcode) => {
        let processedZip = zipcode;
        if (countryCode === 'CA' && zipcode && zipcode.length >= 3) {
            processedZip = zipcode.substring(0, 3);
        }
        try {
            const response = await axios.get('/zipcode/checkOutArea', {
                params: {
                    countryCode: countryCode,
                    zipCode: processedZip
                },
                headers: {
                    Authorization: LCAT
                }
            });
            return response.data;
        } catch (error) {
            console.error("서비스 외 지역 우편번호 조회 에러:", error);
            return false;
        }
    };

    const myPgLink = `https://btorage.com/UserMyPg`;
    // <a href="${myPgLink}">마이페이지 바로가기</a>

    const domesticChatKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*\n포장완료 후 주문서 상세페이지의 결제목록에 국내배송료가 등록되었습니다.\n\n결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서\n최종 한국 수취인정보를 한 번 더 확인해주세요 :)\n\n저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`.replace(/\n/g, '');
    const domesticChatEnMsg = `Hello ${orderData.memberDTO?.userName}^^*\nThe domestic shipping fee has been added to the payment list on the order details page after packaging is complete.\n\nBefore making payment, please double-check the final recipient information in Korea through the pop-up window that opens by clicking the 'Details' button in the payment list :)\n\nThank you sincerely for trusting and using Btorage!`;
    const domesticChatJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*\n梱包完了後、注文書詳細ページの決済リストに国内送料が登録されました。\n\n決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で\n最終受取人情報をもう一度ご確認ください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
    const domesticChatZhMsg = `您好，${orderData.memberDTO?.userName}^^*\n打包完成后，订单详情页面的支付列表中已登记国内运费。\n\n在支付之前，请通过支付列表中的“详情”按钮打开的弹窗\n再次确认最终的韩国收件人信息 :)\n\n感谢您信任并使用Btorage！`;
    const domesticChatIdMsg = `Halo ${orderData.memberDTO?.userName}^^*\nSetelah pengemasan selesai, biaya pengiriman domestik telah terdaftar di daftar pembayaran pada halaman detail pesanan.\n\nSebelum melakukan pembayaran, pastikan untuk memeriksa kembali informasi penerima terakhir di Korea melalui jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;

    const overseasChatKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*\n포장완료 후 주문서 상세페이지의 결제목록에 해외배송료가 등록되었습니다.\n\n결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서\n반드시 최종 수취인정보/세관정보를 확인하신 후 배송사를 선택/결제해주세요 :)\n\n저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const overseasChatEnMsg = `Hello ${orderData.memberDTO?.userName}^^*\nThe international shipping fee has been added to the payment list on the order details page after packaging is complete.\n\nBefore making payment, please be sure to confirm the final recipient and customs information through the pop-up window that opens by clicking the 'Details' button in the payment list, then select the shipping company and proceed with payment :)\n\nThank you sincerely for trusting and using Btorage!`;
    const overseasChatJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*\n梱包完了後、注文書詳細ページの決済リストに海外送料が登録されました。\n\n決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で\n必ず最終受取人情報/通関情報をご確認の上、配送業者を選択し決済してください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
    const overseasChatZhMsg = `您好，${orderData.memberDTO?.userName}^^*\n打包完成后，订单详情页面的支付列表中已登记国际运费。\n\n在支付之前，请务必通过支付列表中的“详情”按钮打开的弹窗\n确认最终收件人信息/海关信息后再选择配送公司并支付 :)\n\n感谢您信任并使用Btorage！`;
    const overseasChatIdMsg = `Halo ${orderData.memberDTO?.userName}^^*\nSetelah pengemasan selesai, biaya pengiriman internasional telah terdaftar di daftar pembayaran pada halaman detail pesanan.\n\nSebelum melakukan pembayaran, pastikan untuk memeriksa informasi penerima terakhir/informasi bea cukai di jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran,\nlalu pilih perusahaan pengiriman dan lanjutkan dengan pembayaran :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;

    const domesticEmailKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*<br>포장완료 후 주문서 상세페이지의 결제목록에 국내배송료가 등록되었습니다.<br><br>결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서<br>최종 한국 수취인정보를 한 번 더 확인해주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const domesticEmailEnMsg = `Hello ${orderData.memberDTO?.userName}^^*<br>The domestic shipping fee has been added to the payment list on the order details page after packaging is complete.<br><br>Before making payment, please double-check the final recipient information in Korea through the pop-up window that opens by clicking the 'Details' button in the payment list :)<br><br>Thank you sincerely for trusting and using Btorage!`;
    const domesticEmailJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*<br>梱包完了後、注文書詳細ページの決済リストに国内送料が登録されました。<br><br>決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で<br>最終受取人情報をもう一度ご確認ください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
    const domesticEmailZhMsg = `您好，${orderData.memberDTO?.userName}^^*<br>打包完成后，订单详情页面的支付列表中已登记国内运费。<br><br>在支付之前，请通过支付列表中的“详情”按钮打开的弹窗<br>再次确认最终的韩国收件人信息 :)<br><br>感谢您信任并使用Btorage！`;
    const domesticEmailIdMsg = `Halo ${orderData.memberDTO?.userName}^^*<br>Setelah pengemasan selesai, biaya pengiriman domestik telah terdaftar di daftar pembayaran pada halaman detail pesanan.<br><br>Sebelum melakukan pembayaran, pastikan untuk memeriksa kembali informasi penerima terakhir di Korea melalui jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;

    const overseasEmailKoMsg = `안녕하세요 ${orderData.memberDTO?.userName}님^^*<br>포장완료 후 주문서 상세페이지의 결제목록에 해외배송료가 등록되었습니다.<br><br>결제 전까지 결제목록의 '상세'버튼을 통해 열리는 팝업창에서<br>반드시 최종 수취인정보/세관정보를 확인하신 후 배송사를 선택/결제해주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
    const overseasEmailEnMsg = `Hello ${orderData.memberDTO?.userName}^^*<br>The international shipping fee has been added to the payment list on the order details page after packaging is complete.<br><br>Before making payment, please be sure to confirm the final recipient and customs information through the pop-up window that opens by clicking the 'Details' button in the payment list, then select the shipping company and proceed with payment :)<br><br>Thank you sincerely for trusting and using Btorage!`;
    const overseasEmailJaMsg = `こんにちは、${orderData.memberDTO?.userName}さん^^*<br>梱包完了後、注文書詳細ページの決済リストに海外送料が登録されました。<br><br>決済前に、決済リストの「詳細」ボタンから開くポップアップ画面で<br>必ず最終受取人情報/通関情報をご確認の上、配送業者を選択し決済してください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
    const overseasEmailZhMsg = `您好，${orderData.memberDTO?.userName}^^*<br>打包完成后，订单详情页面的支付列表中已登记国际运费。<br><br>在支付之前，请务必通过支付列表中的“详情”按钮打开的弹窗<br>确认最终收件人信息/海关信息后再选择配送公司并支付 :)<br><br>感谢您信任并使用Btorage！`;
    const overseasEmailIdMsg = `Halo ${orderData.memberDTO?.userName}^^*<br>Setelah pengemasan selesai, biaya pengiriman internasional telah terdaftar di daftar pembayaran pada halaman detail pesanan.<br><br>Sebelum melakukan pembayaran, pastikan untuk memeriksa informasi penerima terakhir/informasi bea cukai di jendela pop-up yang terbuka saat Anda mengklik tombol 'Detail' di daftar pembayaran,<br>lalu pilih perusahaan pengiriman dan lanjutkan dengan pembayaran :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;

    const domesticChatSendLang = orderData.memberDTO?.lang === "Korean" ? domesticChatKoMsg : orderData.memberDTO?.lang === "Chinese" ? domesticChatZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? domesticChatJaMsg : orderData.memberDTO?.lang === "Indonesian" ? domesticChatIdMsg : domesticChatEnMsg;
    const overseasChatSendLang = orderData.memberDTO?.lang === "Korean" ? overseasChatKoMsg : orderData.memberDTO?.lang === "Chinese" ? overseasChatZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? overseasChatJaMsg : orderData.memberDTO?.lang === "Indonesian" ? overseasChatIdMsg : overseasChatEnMsg;

    const domesticEmailSendLang = orderData.memberDTO?.lang === "Korean" ? domesticEmailKoMsg : orderData.memberDTO?.lang === "Chinese" ? domesticEmailZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? domesticEmailJaMsg : orderData.memberDTO?.lang === "Indonesian" ? domesticEmailIdMsg : domesticEmailEnMsg;
    const overseasEmailSendLang = orderData.memberDTO?.lang === "Korean" ? overseasEmailKoMsg : orderData.memberDTO?.lang === "Chinese" ? overseasEmailZhMsg :
        orderData.memberDTO?.lang === "Japanese" ? overseasEmailJaMsg : orderData.memberDTO?.lang === "Indonesian" ? overseasEmailIdMsg : overseasEmailEnMsg;

    const sendMessage = async () => {
        if (!stompClient) {
            console.log("메시지 전송실패: STOMP 클라이언트가 없습니다.");
            return;
        }

        if (!orderData) {
            console.error("orderData가 null입니다. 메시지를 보낼 수 없습니다.");
            return;
        }

        if (stompClient) {
            const mstMbNum = orderData.dataType === "payinfo" ? orderData.orderdtDTO?.memberDTO?.mbNum || null : orderData.memberDTO?.mbNum || null;

            const mstUserNm = orderData.dataType === "payinfo" ? orderData.orderdtDTO.memberDTO.userName : orderData.memberDTO.userName;

            const email = orderData.dataType === "payinfo" ? orderData.orderdtDTO?.memberDTO?.id || null : orderData.memberDTO?.id || null;

            const lang = orderData.dataType === "payinfo" ? orderData.orderdtDTO.memberDTO.lang : orderData.memberDTO.lang;

            const memberDTO = orderData.dataType === "payinfo" ? orderData.orderdtDTO?.memberDTO : orderData.memberDTO;

            if (!mstMbNum || !email) {
                console.error("mstMbNum 또는 email이 null입니다. 메시지를 보낼 수 없습니다.");
                return;
            }

            const chatMessage = {
                chatKey: selectedShipCompany === "국내배송" ? domesticChatSendLang : overseasChatSendLang,
                fileNm: null,
                chatOrdNum: Number(orderData.orderNumber),
                mbNum: adminInfo.mbNum,
                mstMbNum: mstMbNum,
                mstUserNm: mstUserNm,
                email: email,
                sendMailFl: true,
                autoChatFl: true,
                lang: lang,
                process: "PS0",
                emailKey: selectedShipCompany === "국내배송" ? domesticEmailSendLang : overseasEmailSendLang,
                memberDTO: memberDTO
            };
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));
        } else {
            console.log("메시지 전송실패");
        }
    };

    const regExit = () => {
        window.close();
    }

    // 1. orderData.productsDTO 순회하면서 prodTy === "P05"만 추출
    // 2. 1번에서 추출된 데이터 개수를 deliveryQy에 +해야하지만 그 전에 inWaynum 필드값을 모두 비교해서 값이 완벽하게 동일한 데이터들은 중복없이 deliveryQy에 +1만 count되어야 함
    // 3. 모두 추출+계산되어 값이 업데이트된 deliveryQy에 800을 곱하여 계산된 값을 setDeliveryFeeValue에 업데이트
    useEffect(() => {
        if (
            orderData &&
            orderData.productsDTO &&
            orderData.productsDTO.length > 0 &&
            !orderData.memberDTO.delFeeEmpFl
        ) {
            let deliveryQy = 0;
            const filteredProducts = orderData.productsDTO.filter(
                (product) =>
                    product.prodTy === "P05" && ["IS01", "IS05", "IS06"].includes(product.prodSt)
            );
            const uniqueInWaynumSet = new Set();
            filteredProducts.forEach((product) => {
                const normalizedWaynum = product.inWaynum?.replace(/[-\s]/g, "");
                if (!normalizedWaynum) {
                    deliveryQy += 1;
                } else if (!uniqueInWaynumSet.has(normalizedWaynum)) {
                    uniqueInWaynumSet.add(normalizedWaynum);
                    deliveryQy += 1;
                }
            });
            const calculatedFee = deliveryQy * 800;
            setDeliveryFeeValue(calculatedFee);
        } else {
            setDeliveryFeeValue(0);
        }
    }, [orderData]);

    /** endregion */

    console.log("boxData : ", boxData)

    return (
        <div className="AdminNewRegModal font-t-cont">
            <div className="an-header-frame">
                <div className="an-header-title display-flex flex-row just-cont-fstart c-def align-center p-l-17">
                    <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                    <p className="font-t-title font-s-18 p-t-4 m-l-2" style={{color: "#FFF"}}>비용등록</p>
                </div>
                <div className="an-ord-info-frame c-def">
                    <div className="ht-100pc display-flex flex-column just-cont-sb">
                        <div className="display-flex flex-row just-cont-fstart m-b-5">
                            <img className="wd-18 ht-18 m-t-2 m-r-5" alt="" src={orderIMG}/>
                            <p className="font-b font-s-16">{orderData.orderNumber ? orderData.orderNumber : (orderData.orderdtDTO ? orderData.orderdtDTO.orderNumber : '')}</p>
                        </div>
                        <div className="display-flex flex-row just-cont-fstart">
                            <div className="display-flex flex-column wd-120 font-s-13" style={{backgroundColor: "#f3f3f3"}}>
                                <div className="pd12"><p className="m-l-8">고객명</p></div>
                                <div className="pd12"><p className="m-l-8">수취인명</p></div>
                                <div className="pd12"><p className="m-l-8">국가</p></div>
                                {/*<div className="pd7"><p className="m-l-7">배송사</p></div>*/}
                            </div>
                            <div className="display-flex flex-column wd-220 font-s-13 m-l-8">
                                <div className="pd12"><p>{orderData.memberDTO ? orderData.memberDTO.userName : (orderData.orderdtDTO ? orderData.orderdtDTO.memberDTO.userName : '')}</p></div>
                                <div className="pd12"><p className="font-b">{orderData.memberDTO ? orderData.recinfoDTO.recname : (orderData.orderdtDTO ? orderData.orderdtDTO.recinfoDTO.recname : '')}</p></div>
                                <div className="pd3">
                                    {/*국가 커스텀 셀렉트new*/}
                                    <div ref={countryRef} className="ht-37 c-point position-relative" onClick={toggleCountryShow}>
                                        <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                            {selectedCountry.value ? (
                                                <div className="wd-15pc text-center align-center-c">
                                                    <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                                </div>
                                            ) : (
                                                <div className="wd-15pc text-center align-center-c p-l-5">
                                                    <div className="wd-27 ht-20 text-center font-s-12 m-0-a country-select-flag">?</div>
                                                </div>
                                            )}
                                            <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-13 p-l-15"
                                                   type="text" placeholder={"국가명 입력 또는 선택"} autoComplete="nope"
                                                   value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                                   onChange={handleCountrySearch}
                                                   onKeyDown={handleKeyDown} />
                                            <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                                <BiCaretDown />
                                            </i>
                                        </div>
                                        {countryShow && (
                                            <div className="country-select-data wd-100pc position-absolute m-t-5">
                                                <div className="country-select-data-child">
                                                {filteredCountryList.map((country, index) => (
                                                    <div key={index} className={`selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20 ${
                                                        index === selectedIndex ? "arrow-selected" : ""
                                                    }`} onClick={(event) => handleCountryChange(country, event)}>
                                                        <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                        <p className="font-s-15">{country.label}</p>
                                                    </div>
                                                ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/*여기까지 커스텀 국가셀렉트*/}
                                </div>
                                {/*<div className="pd10">*/}
                                {/*    <p className="font-b">*/}
                                {/*        {orderData.recinfoDTO && orderData.recinfoDTO.countryDTO*/}
                                {/*            ? orderData.recinfoDTO.countryDTO.countryNm + "[" + orderData.recinfoDTO.countryDTO.countryCode + "]"*/}
                                {/*            : (orderData.appRecDTO && orderData.appRecDTO.countryDTO*/}
                                {/*                ? orderData.appRecDTO.countryDTO.countryNm + "[" + orderData.appRecDTO.countryDTO.countryCode + "]"*/}
                                {/*                : '')*/}
                                {/*        }*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                {/*<div className="p-l-7 p-t-3">*/}
                                {/*    <select className="admin-combo wd-110 ht-30 font-s-13" value={selectedShipCompany}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                setSelectedShipCompany(e.target.value);*/}
                                {/*                let shipCompanyCode;*/}
                                {/*                switch (e.target.value) {*/}
                                {/*                    case "FedEx":*/}
                                {/*                        shipCompanyCode = "D01";*/}
                                {/*                        break;*/}
                                {/*                    case "FedEx-E":*/}
                                {/*                        shipCompanyCode = "D16";*/}
                                {/*                        break;*/}
                                {/*                    case "UPS":*/}
                                {/*                        shipCompanyCode = "D11";*/}
                                {/*                        break;*/}
                                {/*                    case "UPS-E":*/}
                                {/*                        shipCompanyCode = "D17";*/}
                                {/*                        break;*/}
                                {/*                    case "EMS":*/}
                                {/*                        shipCompanyCode = "D02";*/}
                                {/*                        break;*/}
                                {/*                    case "EMSP":*/}
                                {/*                        shipCompanyCode = "D15";*/}
                                {/*                        break;*/}
                                {/*                    case "K-Packet":*/}
                                {/*                        shipCompanyCode = "D13";*/}
                                {/*                        break;*/}
                                {/*                    case "Small-Packet":*/}
                                {/*                        shipCompanyCode = "D14";*/}
                                {/*                        break;*/}
                                {/*                    case "SEA":*/}
                                {/*                        shipCompanyCode = "D03";*/}
                                {/*                        break;*/}
                                {/*                    case "국내배송":*/}
                                {/*                        shipCompanyCode = "D00";*/}
                                {/*                        break;*/}
                                {/*                    default:*/}
                                {/*                        shipCompanyCode = "";*/}
                                {/*                }*/}
                                {/*            }}>*/}
                                {/*        {shipcoData.map((cData) => (*/}
                                {/*            <option value={cData.courierCompanyName} key={cData.seqnum}>*/}
                                {/*                {cData.courierCompanyName}*/}
                                {/*            </option>*/}
                                {/*        ))}*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="an-cont-frame pop-scroll">
                <div className="m-a-open-bar m-a-open-bar-pc p-l-20 p-r-20 c-point" onClick={() => setShowCostInputs(!showCostInputs)}>
                    <p className="font-t-title font-s-14">비용입력</p>
                    {showCostInputs ?
                        <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                        <i className="m-a-ord-accordion-icon"></i>
                    }
                </div>
                {showCostInputs &&
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc font-t-cont">
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${codCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">택배착불비</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={codCheckbox ? parseInt(codValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setCodValue(newValue);
                                               setCodCheckbox(newValue !== '' && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div
                                className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${domeCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">국내반송비</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={domeCheckbox ? parseInt(domeValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setDomeValue(newValue);
                                               setDomeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${deliveryFeeCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">배송대행수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={deliveryFeeValue ? parseInt(deliveryFeeValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setDeliveryFeeValue(newValue);
                                               setDeliveryFeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 ${repackCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">재포장수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="7" inputMode="numeric" pattern="\d*"
                                       value={repackCheckbox ? parseInt(repackValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setRePackValue(newValue);
                                               setRePackCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${agenciesCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">대행수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="8" inputMode="numeric" pattern="\d*"
                                       value={agenciesCheckbox ? parseInt(agenciesValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setAgenciesValue(newValue);
                                               setAgenciesCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${vacuumPack ? 'm-a-text-colored' : ''}`}>
                                <p className="">압축팩수수료</p></div>
                            <div className="wd-26pc font-s-14 font-c-default font-t-cont" style={{marginLeft: "6%"}}>
                                <select className="admin-combo wd-100pc ht-40" value={packSize} onChange={handleVacPackSizeChange}>
                                    <option value="선택">선택</option>
                                    <option value="소형">소형</option>
                                    <option value="대형">대형</option>
                                </select>
                            </div>
                            <div className="m-a-input-frame ht-40 wd-30pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       disabled={true}
                                       value={vacuumPack ? parseInt(vacPackPrice).toLocaleString() : ""}/>
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">기타비용</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="8" inputMode="numeric" pattern="\d*"
                                       value={etcCheckbox ? parseInt(etcValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setEtcValue(newValue);
                                               setEtcCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcNmValue ? 'm-a-text-colored' : ''}`}>
                                <p className="">기타비용내용</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 font-s-14" type={"text"} maxLength="25"
                                       placeholder="비용 입력시 활성화"
                                       value={etcCheckbox ? etcNmValue : ""} disabled={etcCheckbox === false}
                                       onChange={(e) => {
                                           setEtcNmValue(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc m-b-13">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcFeeCheckbox ? 'm-a-text-colored' : ''}`}>
                                <p className="">기타수수료</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 text-right p-r-5" type="text" placeholder="0"
                                       maxLength="8" inputMode="numeric" pattern="\d*"
                                       value={etcFeeCheckbox ? parseInt(etcFeeValue).toLocaleString() : ""}
                                       onChange={(e) => {
                                           const newValue = e.target.value.replace(/,/g, '');
                                           if (/^\d*$/.test(newValue)) {
                                               setEtcFeeValue(newValue);
                                               setEtcFeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                           }
                                       }}
                                />
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb wd-100pc">
                            <div className={`wd-30pc ht-40 font-s-15 p-t-8 c-def ${etcFeeNmValue ? 'm-a-text-colored' : ''}`}>
                                <p className="">기타수수료내용</p></div>
                            <div className="m-a-input-frame ht-40 wd-60pc">
                                <input className="m-a-input wd-100pc p-l-5 font-s-14" type={"text"} maxLength="25"
                                       placeholder="수수료 입력시 활성화"
                                       value={etcFeeCheckbox ? etcFeeNmValue : ""} disabled={etcFeeCheckbox === false}
                                       onChange={(e) => {
                                           setEtcFeeNmValue(e.target.value)
                                       }}/>
                            </div>
                        </div>
                    </div>
                }
                <div className="m-a-open-bar m-a-open-bar-pc p-l-20 p-r-20 m-t-20 c-point" onClick={() => setShowItemInputs(!showItemInputs)}>
                    <p className="font-t-title font-s-14">상품할당</p>
                    {showItemInputs ?
                        <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/> :
                        <i className="m-a-ord-accordion-icon"></i>
                    }
                </div>
                {showItemInputs && (
                    <div className="m-a-top-border-card wd-100pc font-t-cont">
                        <div className="m-a-tb-frame-b ht-350">
                            <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head-b ht-35 font-s-13">
                                    <div className="wd-10pc">
                                        {/*<input className="admin-input-chkBox-s m-0-a"*/}
                                        {/*       type="checkbox"*/}
                                        {/*       checked={*/}
                                        {/*           (orderData.dataType === "orderdt"*/}
                                        {/*                   ? orderData.productsDTO*/}
                                        {/*                   : orderData.packListDTO.productsDTO*/}
                                        {/*           )?.some(item => item.prodSt === "IS05" || item.prodSt === "IS06") && checked*/}
                                        {/*       }*/}
                                        {/*       onChange={allcheckBoxChange}/>*/}
                                        <input className="admin-input-chkBox-s m-0-a"
                                               type="checkbox"
                                               onChange={handleAllCheck}
                                               checked={isAllChecked}
                                        />
                                    </div>
                                    <div className="wd-15pc"><p>번호</p></div>
                                    <div className="wd-35pc"><p>품명&운송장</p></div>
                                    <div className="wd-20pc"><p>상태구분</p></div>
                                    <div className="wd-20pc"><p>박스번호</p></div>
                                </div>
                                {(orderData.dataType === "orderdt" ? orderData.productsDTO : orderData.packListDTO.productsDTO)?.sort((a, b) => a.seqnum - b.seqnum).map((item, i) => (
                                    <div key={i} className="m-a-div-tb-body-b ht-45">
                                        <div className="wd-10pc">
                                            {/*<input className="admin-input-chkBox-s m-0-a"*/}
                                            {/*       type="checkbox"*/}
                                            {/*       name={item.seqnum}*/}
                                            {/*       checked={(item?.prodSt === "IS05" || item?.prodSt === "IS06") && boxData[selectedBoxIndex[i]]?.products.includes(item.seqnum)}*/}
                                            {/*       onChange={() => handleBoxSelection(i, selectedBoxIndex[i])}*/}
                                            {/*       disabled={item?.prodSt !== "IS05" && item?.prodSt !== "IS06"}/>*/}
                                            <input className="admin-input-chkBox-s m-0-a" type="checkbox" name={item.seqnum}
                                                   checked={checkedItems.includes(item.seqnum)}
                                                   onChange={(e) =>
                                                       handleSingleCheck(item.seqnum, e.target.checked, item.prodSt)
                                                   }
                                                   disabled={item.prodSt !== "IS05" && item.prodSt !== "IS06"}
                                            />
                                        </div>
                                        <div className="wd-15pc font-s-14"><p>{i + 1}</p></div>
                                        <div className="wd-35pc font-s-12 text-center"><p className="m-a-text-cut">{item?.name}</p></div>
                                        <div className="wd-20pc"><a className="font-b font-s-13">{prodStPrint(item?.prodSt)}</a></div>
                                        <div className="wd-20pc align-center">
                                            {/*{(item?.prodSt === "IS05" || item?.prodSt === "IS06") && (*/}
                                            {/*    <select className="admin-combo wd-80 font-s-13 ht-25 font-b" value={selectedBoxIndex[i]}*/}
                                            {/*            onChange={e => handleBoxSelection(i, parseInt(e.target.value))}>*/}
                                            {/*        {boxData.map((box, j) => (*/}
                                            {/*            <option key={j} value={j}>*/}
                                            {/*                Box {j + 1}*/}
                                            {/*            </option>*/}
                                            {/*        ))}*/}
                                            {/*    </select>*/}
                                            {/*)}*/}
                                            {(item?.prodSt === "IS05" || item?.prodSt === "IS06") && (
                                                <select className="admin-combo wd-80 font-s-13 ht-25 font-b" value={setCheckedItems[i]}
                                                        onChange={e => handleSingleCheck(i, parseInt(e.target.value))}>
                                                    {boxData.map((box, j) => (
                                                        <option key={j} value={j}>
                                                            Box {j + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="m-a-open-bar p-l-20 p-r-20 m-t-20">
                    <p className="font-t-title font-s-14 c-def">박스정보</p>
                    <div>
                        <AiOutlineMinusSquare className="font-c-default font-s-28 c-point btorage-hover-exp btorage-hover m-r-5" onClick={handleMinusClick}/>
                        <AiOutlinePlusSquare className="font-c-default font-s-28 c-point btorage-hover-exp btorage-hover" onClick={handlePlusClick}/>
                    </div>
                </div>
                {/*해외배송*/}
                {selectedShipCompany !== "국내배송" && showBoxInputs && (
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                        {boxData.map((box, index) => (
                            <div key={index} className="wd-100pc">
                                <div className="display-flex flex-row just-cont-sb font-s-14 wd-100pc text-center gap7">
                                    <p className="wd-20pc c-def">가로(cm)</p>
                                    <p className="wd-20pc c-def">세로(cm)</p>
                                    <p className="wd-20pc c-def">높이(cm)</p>
                                    <p className="wd-20pc c-def">실무게(kg)</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "width", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, wid: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "width", newValue);
                                               }
                                               setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "height", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, ver: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "height", newValue);
                                               }
                                               setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "length", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, hei: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "length", newValue);
                                               }
                                               setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="5" type="text " placeholder="0.00" style={{ color: box.netWeight > 30 ? "red" : ""}}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight || ""}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "netWeight", newValue);
                                                   if (!box.width && !box.height && !box.length) {
                                                       handleBoxChange(index, "width", "0");
                                                       handleBoxChange(index, "height", "0");
                                                       handleBoxChange(index, "length", "0");
                                                   }
                                                   if (e.target.value !== "") {
                                                       setEfeeCheckbox(true);
                                                       setFfeeCheckbox(true);
                                                   } else {
                                                       setEfeeCheckbox(false);
                                                       setFfeeCheckbox(false);
                                                   }
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, weight: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "netWeight", newValue);
                                                   if (!box.width && !box.height && !box.length) {
                                                       handleBoxChange(index, "width", "0");
                                                       handleBoxChange(index, "height", "0");
                                                       handleBoxChange(index, "length", "0");
                                                   }
                                                   if (e.target.value !== "") {
                                                       setEfeeCheckbox(true);
                                                       setFfeeCheckbox(true);
                                                   } else {
                                                       setEfeeCheckbox(false);
                                                       setFfeeCheckbox(false);
                                                   }
                                               }
                                               setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                </div>
                                <div className="display-flex flex-column m-t-5 m-b-25">
                                    <div className="display-flex flex-row just-cont-sb">
                                        <div><p className="font-s-14 c-def">Box {index + 1}</p></div>
                                        <div className="display-flex flex-row btorage-hover c-point" onClick={() => handleToggle(index)}>
                                            <p className="font-s-14 m-r-2">자세히</p>
                                            {showBoxDtPrice[index] ?
                                                <IoIosArrowDropdown className="font-s-17 font-c-default m-t-2" style={{ color: "inherit" }}/> :
                                                <IoIosArrowDropright className="font-s-17 font-c-default m-t-2" style={{ color: "inherit" }}/>
                                            }
                                        </div>
                                    </div>
                                    {showBoxDtPrice[index] && (
                                        <div className="c-def">
                                            {/*FedEx*/}
                                            <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-a-reg-box-price-top m-t-10 p-t-5 p-b-5">
                                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                    <div className="display-flex flex-row align-center">
                                                        <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>FedEx</p>
                                                        <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                    </div>
                                                    <div className="display-flex flex-row font-s-15">
                                                        <p>{getVolumeWeightFedex(box.width, box.height, box.length) + "kg"}</p>
                                                        <p className="m-l-4 m-r-5">/</p>
                                                        <p className="font-b">{box.appliedWeights.F + "kg"}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <div className="display-flex flex-row align-center">
                                                        <p className="m-r-10">페덱스팩여부</p>
                                                        <input className="m-a-admin-input-chkBox m-0-a m-r-5"
                                                               type="checkbox"
                                                               checked={parseFloat(box.netWeight) > 1.5 ? false : box.fpackCheckbox}
                                                               disabled={parseFloat(box.netWeight) > 1.5}
                                                               onChange={() => handleFpackCheckboxChange(index)}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row">
                                                        <p className="font-b">{parseFloat(box.netWeight) > 1.5 ? " N" : box.fpackCheckbox ? " Y" : " N"}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <div className="display-flex flex-row align-center">
                                                        <p className="m-r-10">유류할증료</p>
                                                        <div className="m-a-input-frame ht-28 wd-65 m-l-10">
                                                            <input className="m-a-input text-center wd-65 ht-28 font-c-default" type={"text"} value={fedexFuelValue} maxLength="4"
                                                                   onChange={(e) => setFedexFuelValue(e.target.value)} inputMode="numeric" pattern="\d*"
                                                                   disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30}
                                                            />
                                                        </div>
                                                        <p className="m-t-3"> %</p>
                                                    </div>
                                                    <div className="display-flex flex-row">
                                                        <input className="wd-90 ht-25 text-right font-c-default m-a-all-none" type={"text"} disabled placeholder="0"
                                                               value={parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (fedexFuelValue / 100)).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                                {isOutArea && (
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="font-c-cancel">서비스 외 지역 수수료</p>
                                                        <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={`\u20A9 ${outAreaCharge.toLocaleString()}`}
                                                        />
                                                    </div>
                                                )}
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">수수료</p>
                                                    <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                           value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-" : "\u20A9 " + parseInt(boxFedexResultFees[index]).toLocaleString()}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/,/g, '');
                                                               if (/^\d*$/.test(newValue)) {
                                                                   const updatedBoxFedexResultFees = [...boxFedexResultFees];
                                                                   updatedBoxFedexResultFees[index] = newValue * appliedWeights.F * 10;
                                                                   setBoxFedexResultFees(updatedBoxFedexResultFees);
                                                                   setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">배송료</p>
                                                    <p className="">{parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.F, fedexExData).toLocaleString()}</p>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                    <p className="">총배송비용</p>
                                                    <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text"
                                                           disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)}
                                                           value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-"
                                                               : (parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)
                                                                   ? "\u20A9 " + (getFedexCalculatedValue(box, fedexExData) + parseInt(boxFedexResultFees[index]) + outAreaCharge).toLocaleString()
                                                                   : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (1 + fedexFuelValue / 100) + parseInt(boxFedexResultFees[index]) + outAreaCharge).toLocaleString()}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/[^0-9.]/g, '');
                                                               if (newValue === "" || !isNaN(newValue)) {
                                                                   handleFedexValueChange(index, newValue);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            {/*FedEx-E*/}
                                            <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                    <div className="display-flex flex-row align-center">
                                                        <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>FedEx-E</p>
                                                        <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                    </div>
                                                    <div className="display-flex flex-row font-s-15">
                                                        <p>{getVolumeWeightFedex(box.width, box.height, box.length) + "kg"}</p>
                                                        <p className="m-l-4 m-r-5">/</p>
                                                        <p className="font-b">{box.appliedWeights.F + "kg"}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <div className="display-flex flex-row align-center">
                                                        <p className="m-r-10">페덱스팩여부</p>
                                                        <input className="m-a-admin-input-chkBox m-0-a m-r-5" type="checkbox"
                                                               checked={parseFloat(box.netWeight) > 1.5 ? false : box.fpackCheckbox} disabled={parseFloat(box.netWeight) > 1.5 || selectedShipCompany !== 'FedEx-E'}
                                                               onChange={() => handleBoxChange(index, "fpackCheckbox")}/>
                                                        {/*<p className="">FedEx Pack</p>*/}
                                                    </div>
                                                    <div className="display-flex flex-row">
                                                        <p className="font-b">{parseFloat(box.netWeight) > 1.5 ? " N" : box.fpackCheckbox ? " Y" : " N"}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <div className="display-flex flex-row align-center">
                                                        <p className="m-r-10">유류할증료</p>
                                                        <div className="m-a-input-frame ht-28 wd-65 m-l-10">
                                                            <input className="m-a-input text-center wd-65 ht-28 font-c-default" type={"text"} value={fedexFuelValue} maxLength="4"
                                                                   onChange={(e) => setFedexFuelValue(e.target.value)} inputMode="numeric" pattern="\d*"
                                                                   disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30}
                                                            />
                                                        </div>
                                                        <p className="m-t-3"> %</p>
                                                    </div>
                                                    <div className="display-flex flex-row">
                                                        <input className="wd-90 ht-25 text-right font-c-default m-a-all-none" type={"text"} disabled placeholder="0"
                                                               value={parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexEcoExData) * (fedexFuelValue / 100)).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">수수료</p>
                                                    <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                           value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-" : "\u20A9 " + parseInt(boxFedexEcoResultFees[index]).toLocaleString()}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/,/g, '');
                                                               if (/^\d*$/.test(newValue)) {
                                                                   const updatedBoxFedexEcoResultFees = [...boxFedexEcoResultFees];
                                                                   updatedBoxFedexEcoResultFees[index] = newValue * appliedWeights.F * 10;
                                                                   setBoxFedexEcoResultFees(updatedBoxFedexEcoResultFees);
                                                                   setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">배송료</p>
                                                    <p className="">{parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.F, fedexEcoExData).toLocaleString()}</p>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                    <p className="">총배송비용</p>
                                                    <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text"
                                                           disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)}
                                                           value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-"
                                                               : (parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)
                                                                   ? "\u20A9 " + (getFedexCalculatedValue(box, fedexEcoExData) + parseInt(boxFedexEcoResultFees[index])).toLocaleString()
                                                                   : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexEcoExData) * (1 + fedexFuelValue / 100) + parseInt(boxFedexEcoResultFees[index])).toLocaleString()}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/[^0-9.]/g, '');
                                                               if (newValue === "" || !isNaN(newValue)) {
                                                                   handleFedexValueChange(index, newValue);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            {/*UPS*/}
                                            <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                    <div className="display-flex flex-row align-center">
                                                        <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>UPS</p>
                                                        <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                    </div>
                                                    <div className="display-flex flex-row font-s-15">
                                                        <p>{getVolumeWeightUps(box.width, box.height, box.length) + "kg"}</p>
                                                        <p className="m-l-4 m-r-5">/</p>
                                                        <p className="font-b">{box.appliedWeights.P + "kg"}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <div className="display-flex flex-row align-center">
                                                        <p className="m-r-10">유류할증료</p>
                                                        <div className="m-a-input-frame ht-28 wd-65 m-l-10">
                                                            <input className="m-a-input text-center wd-65 ht-28 font-c-default" type={"text"} value={upsFuelValue} maxLength="4"
                                                                   onChange={(e) => setUpsFuelValue(e.target.value)} inputMode="numeric" pattern="\d*"
                                                                   disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30}
                                                            />
                                                        </div>
                                                        <p className="m-t-3"> %</p>
                                                    </div>
                                                    <div className="display-flex flex-row">
                                                        <input className="wd-90 ht-25 text-right font-c-default m-a-all-none" type={"text"} disabled placeholder="0"
                                                               value={parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.P, upsExData) * (upsFuelValue / 100)).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">수수료</p>
                                                    <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                           value={parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxUpsResultFees[index]).toLocaleString() : "-"}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/,/g, '');
                                                               if (/^\d*$/.test(newValue)) {
                                                                   const updatedBoxUpsResultFees = [...boxUpsResultFees];
                                                                   updatedBoxUpsResultFees[index] = newValue * appliedWeights.P * 10;
                                                                   setBoxUpsResultFees(updatedBoxUpsResultFees);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">배송료</p>
                                                    <p>{parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.P, upsExData).toLocaleString()}</p>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                    <p className="">총배송비용</p>
                                                    <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text"
                                                           disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30)}
                                                           value={(parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70) ? "-"
                                                               : (parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30)
                                                                   ? "\u20A9 " + (getFedexCalculatedValue(box, upsExData) + parseInt(boxUpsResultFees[index])).toLocaleString()
                                                                   : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.P, upsExData) * (1 + upsFuelValue / 100) + parseInt(boxUpsResultFees[index])).toLocaleString()}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/[^0-9.]/g, '');
                                                               if (newValue === "" || !isNaN(newValue)) {
                                                                   handleFedexValueChange(index, newValue);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            {/*UPS-E*/}
                                            <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                    <div className="display-flex flex-row align-center">
                                                        <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>UPS-E</p>
                                                        <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                    </div>
                                                    <div className="display-flex flex-row font-s-15">
                                                        <p>{getVolumeWeightUps(box.width, box.height, box.length) + "kg"}</p>
                                                        <p className="m-l-4 m-r-5">/</p>
                                                        <p className="font-b">{box.appliedWeights.P + "kg"}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <div className="display-flex flex-row align-center">
                                                        <p className="m-r-10">유류할증료</p>
                                                        <div className="m-a-input-frame ht-28 wd-65 m-l-10">
                                                            <input className="m-a-input text-center wd-65 ht-28 font-c-default" type={"text"} value={upsFuelValue} maxLength="4"
                                                                   onChange={(e) => setUpsFuelValue(e.target.value)} inputMode="numeric" pattern="\d*"
                                                                   disabled={parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30}
                                                            />
                                                        </div>
                                                        <p className="m-t-3"> %</p>
                                                    </div>
                                                    <div className="display-flex flex-row">
                                                        <input className="wd-90 ht-25 text-right font-c-default m-a-all-none" type={"text"} disabled placeholder="0"
                                                               value={parseFloat(box.netWeight) > 30 ? "-" : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.P, upsEcoExData) * (upsFuelValue / 100)).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p className="">수수료</p>
                                                    <input className="wd-30pc ht-25 text-right font-c-default m-a-all-none" type={"text"} placeholder="0" disabled
                                                           value={parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxUpsEcoResultFees[index]).toLocaleString() : "-"}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/,/g, '');
                                                               if (/^\d*$/.test(newValue)) {
                                                                   const updatedBoxUpsEcoResultFees = [...boxUpsEcoResultFees];
                                                                   updatedBoxUpsEcoResultFees[index] = newValue * appliedWeights.P * 10;
                                                                   setBoxUpsEcoResultFees(updatedBoxUpsEcoResultFees);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                    <p>배송료</p>
                                                    <p>{parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.P, upsEcoExData).toLocaleString()}</p>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                    <p className="">총배송비용</p>
                                                    <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text"
                                                           disabled={!(parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30)}
                                                           value={(parseFloat(box.netWeight) > 70 || getVolumeWeightUps(box.width, box.height, box.length) > 70) ? "-"
                                                               : (parseFloat(box.netWeight) > 30 || getVolumeWeightUps(box.width, box.height, box.length) > 30)
                                                                   ? "\u20A9 " + (getFedexCalculatedValue(box, upsEcoExData) + parseInt(boxUpsEcoResultFees[index])).toLocaleString()
                                                                   : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.P, upsEcoExData) * (1 + upsFuelValue / 100) + parseInt(boxUpsEcoResultFees[index])).toLocaleString()}
                                                           onChange={(e) => {
                                                               const newValue = e.target.value.replace(/[^0-9.]/g, '');
                                                               if (newValue === "" || !isNaN(newValue)) {
                                                                   handleFedexValueChange(index, newValue);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            {/*EMS*/}
                                            {parseFloat(box.netWeight) <= 30 && getVolumeWeightEms(box.width, box.height, box.length) <= 30 && (
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>EMS</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="">{getVolumeWeightEms(box.width, box.height, box.length) + "kg"}</p>
                                                            <p className="m-l-4 m-r-5">/</p>
                                                            <p className="font-b">{box.appliedWeights.E + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxEmsResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxEmsResultFees = [...boxEmsResultFees];
                                                                       updatedBoxEmsResultFees[index] = newValue * appliedWeights.E * 10;
                                                                       setBoxEmsResultFees(updatedBoxEmsResultFees);
                                                                       setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                       if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                           setEmsAmount(true);
                                                                       }
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 30  || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.E, emsExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 30 || getVolumeWeightEms(box.width, box.height, box.length) > 30 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.E, emsExData) + parseInt(boxEmsResultFees[index])).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {/*EMSP*/}
                                            {parseFloat(box.netWeight) <= 70 && getVolumeWeightEms(box.width, box.height, box.length) <= 70 && (
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>EMSP</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="">{getVolumeWeightEms(box.width, box.height, box.length) + "kg"}</p>
                                                            <p className="m-l-4 m-r-5">/</p>
                                                            <p className="font-b">{box.appliedWeights.E + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 70 || getVolumeWeightEms(box.width, box.height, box.length) > 70 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxEmsPreResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxEmsResultFees = [...boxEmsPreResultFees];
                                                                       updatedBoxEmsResultFees[index] = newValue * appliedWeights.E * 10;
                                                                       setBoxEmsPreResultFees(updatedBoxEmsResultFees);
                                                                       setEfeeCheckbox(newValue !== "" && parseInt(newValue) !== 0);
                                                                       if (newValue !== "" && parseInt(newValue) !== 0) {
                                                                           setEmsAmount(true);
                                                                       }
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 70  || getVolumeWeightEms(box.width, box.height, box.length) > 70 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.E, emsPreExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 70 || getVolumeWeightEms(box.width, box.height, box.length) > 70 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.E, emsPreExData) + parseInt(boxEmsPreResultFees[index])).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {/*K-Packet*/}
                                            {parseFloat(box.netWeight) <= 2 && (
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>K-Packet</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="font-b">{box.appliedWeights.K + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 2 || getVolumeWeightEms(box.width, box.height, box.length) > 11.5 ? "-" : box.netWeight ? "\u20A9 " + parseInt(boxKpResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxKpResultFees = [...boxKpResultFees];
                                                                       updatedBoxKpResultFees[index] = newValue * appliedWeights.K * 10
                                                                       setBoxKpResultFees(updatedBoxKpResultFees);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.K, kpExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.K, kpExData) + parseInt(boxKpResultFees[index])).toLocaleString()}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {/*항공소형*/}
                                            {parseFloat(box.netWeight) <= 2 && (
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>Small-Packet</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15">
                                                            <p className="font-b">{box.appliedWeights.A + "kg"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-30pc ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 2 ? "-" : boxAsResultFees[index] > 0 ? "\u20A9 " + parseInt(boxAsResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxAsResultFees = [...boxAsResultFees];
                                                                       updatedBoxAsResultFees[index] = newValue * appliedWeights.A * 10;
                                                                       setBoxAsResultFees(updatedBoxAsResultFees);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.A, asExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 2 || box.appliedWeights.E > 2 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.A, asExData) + parseInt(boxAsResultFees[index])).toLocaleString()}/>
                                                    </div>
                                                </div>
                                            )}
                                            {/*Sea*/}
                                            {parseFloat(box.netWeight) <= 20 && (
                                                <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-10 p-t-5 p-b-5">
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                        <div className="display-flex flex-row align-center">
                                                            <span style={{width: "15px", marginRight: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                            <p className="font-t-title font-s-16" style={{color: "#FFAF34", fontStyle: "italic"}}>SEA</p>
                                                            <span style={{width: "15px", marginLeft: "4px", height: "1px", backgroundColor: "#d5d5d5"}}></span>
                                                        </div>
                                                        <div className="display-flex flex-row font-s-15"><p className="font-b">{box.appliedWeights.S + "kg"}</p></div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">수수료</p>
                                                        <input className="wd-100 ht-25 text-right m-a-all-none" type={"text"} placeholder="0" disabled
                                                               value={parseFloat(box.netWeight) > 20 ? "-" : boxSeaResultFees[index] > 0 ? "\u20A9 " + parseInt(boxSeaResultFees[index]).toLocaleString() : "-"}
                                                               onChange={(e) => {
                                                                   const newValue = e.target.value.replace(/,/g, '');
                                                                   if (/^\d*$/.test(newValue)) {
                                                                       const updatedBoxSeaResultFees = [...boxSeaResultFees];
                                                                       updatedBoxSeaResultFees[index] = newValue * appliedWeights.S * 10;
                                                                       setBoxSeaResultFees(updatedBoxSeaResultFees);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-cont font-s-13">
                                                        <p className="">배송료</p>
                                                        <p>{parseFloat(box.netWeight) > 20 ? "-" : "\u20A9 " + getAmountForWeight(box.appliedWeights.S, shipExData).toLocaleString()}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                        <p className="">총배송비용</p>
                                                        <input className="wd-30pc ht-25 text-right font-b font-c-btorage m-a-all-none" type="text" disabled
                                                               value={parseFloat(box.netWeight) > 20 ? "-"
                                                                   : "\u20A9 " + (getAmountForWeight(box.appliedWeights.S, shipExData) + parseInt(boxSeaResultFees[index])).toLocaleString()}/>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {/*국내배송*/}
                {selectedShipCompany === "국내배송" && (
                    <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                        {boxDomeData.map((box, index) => (
                            <div key={index} className="wd-100pc">
                                <div className="display-flex flex-row just-cont-sb font-s-14 wd-100pc text-center gap7">
                                    <p className="wd-20pc c-def">가로(cm)</p>
                                    <p className="wd-20pc c-def">세로(cm)</p>
                                    <p className="wd-20pc c-def">높이(cm)</p>
                                    <p className="wd-20pc c-def">실무게(kg)</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc gap7">
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3"  type="text" placeholder="0"
                                           disabled={fpackValue === 'Y'} value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.wid : box.width}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "width", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, wid: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "width", newValue);
                                               }
                                               setInputType(newValue === box.width ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.ver : box.height}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "height", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, ver: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "height", newValue);
                                               }
                                               setInputType(newValue === box.height ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="3" type="text" placeholder="0" disabled={fpackValue === 'Y'}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.hei : box.length}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "length", newValue);
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, hei: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "length", newValue);
                                               }
                                               setInputType(newValue === box.length ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                    <input className="admin-input-line font-b font-c-default wd-20pc ht-40 text-center font-s-16 com-input" inputMode="numeric" pattern="\d*"
                                           minLength="1" maxLength="5" type="text " placeholder="0.00" style={{ color: box.netWeight > 30 ? "red" : ""}}
                                           value={inputType === 'shipInfoDTO' ? orderData.shipInfoDTO.weight : box.netWeight}
                                           onChange={(e) => {
                                               const newValue = e.target.value;
                                               if (orderData.dataType === 'payinfo') {
                                                   handleBoxChange(index, "netWeight", newValue);
                                                   if (!box.width && !box.height && !box.length) {
                                                       handleBoxChange(index, "width", "0");
                                                       handleBoxChange(index, "height", "0");
                                                       handleBoxChange(index, "length", "0");
                                                   }
                                                   if (e.target.value !== "") {
                                                       setEfeeCheckbox(true);
                                                       setFfeeCheckbox(true);
                                                   } else {
                                                       setEfeeCheckbox(false);
                                                       setFfeeCheckbox(false);
                                                   }
                                                   const updatedShipInfoDTO = { ...orderData.shipInfoDTO, weight: newValue };
                                                   setOrderData({ ...orderData, shipInfoDTO: updatedShipInfoDTO });
                                               } else {
                                                   handleBoxChange(index, "netWeight", newValue);
                                                   if (!box.width && !box.height && !box.length) {
                                                       handleBoxChange(index, "width", "0");
                                                       handleBoxChange(index, "height", "0");
                                                       handleBoxChange(index, "length", "0");
                                                   }
                                                   if (e.target.value !== "") {
                                                       setEfeeCheckbox(true);
                                                       setFfeeCheckbox(true);
                                                   } else {
                                                       setEfeeCheckbox(false);
                                                       setFfeeCheckbox(false);
                                                   }
                                               }
                                               setInputType(newValue === box.netWeight ? 'box' : 'shipInfoDTO');
                                           }}
                                    />
                                </div>
                                <div className="display-flex flex-column m-t-5 m-b-25">
                                    <div className="display-flex flex-row just-cont-sb">
                                        <div><p className="font-s-14 font-c-btorage">Box {index + 1}</p></div>
                                        <div className="display-flex flex-row text-active c-point" onClick={() => handleToggle(index)}>
                                            <p className="font-s-14">자세히</p>
                                            {showBoxDtPrice[index] ?
                                                <IoIosArrowDropdown className="font-s-18 font-c-default"/> :
                                                <IoIosArrowDropright className="font-s-18 font-c-default"/>
                                            }
                                        </div>
                                    </div>
                                    {showBoxDtPrice[index] && (
                                        <div>
                                            <div className="display-flex flex-column wd-100pc m-a-reg-box-price m-t-15">
                                                <div className="display-flex flex-row just-cont-sb wd-100pc">
                                                    <p className="font-t-title">국내배송</p>
                                                    <div className="display-flex flex-row font-s-15">
                                                        <p className="" style={{ color: (parseFloat(box.width) + parseFloat(box.height) + parseFloat(box.length)) > 200 ? "red" : "" }}>
                                                            {(!isNaN(parseFloat(box.width)) ? parseFloat(box.width) : 0) +
                                                                (!isNaN(parseFloat(box.height)) ? parseFloat(box.height) : 0) +
                                                                (!isNaN(parseFloat(box.length)) ? parseFloat(box.length) : 0) + "cm"}
                                                        </p>
                                                        <p className="m-l-4 m-r-5">/</p>
                                                        <a className="font-b" style={{ color: parseFloat(box.netWeight) > 30 ? "red" : "" }}>
                                                            {box.netWeight + " kg"}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title">
                                                    <p className="">총배송비용</p>
                                                    <input className="wd-30pc ht-25 text-right font-b font-c-progress m-a-all-none" type="text" disabled
                                                        // value={(parseFloat(box.netWeight) > 70 || getVolumeWeightFedex(box.width, box.height, box.length) > 70) ? "-"
                                                        //     : (parseFloat(box.netWeight) > 30 || getVolumeWeightFedex(box.width, box.height, box.length) > 30)
                                                        //         ? "\u20A9 " + (getFedexCalculatedValue(box, fedexExData) + parseInt(boxFedexResultFees[index]) + outAreaCharge).toLocaleString()
                                                        //         : "\u20A9 " + roundToTen(getAmountForWeight(box.appliedWeights.F, fedexExData) * (1 + fedexFuelValue / 100) + parseInt(boxFedexResultFees[index]) + outAreaCharge).toLocaleString()}


                                                           value={parseFloat(box.netWeight) > 30 ? 0 : (box.netWeight === "" ? "\u20A9 " +  0
                                                               : (domeExData.length > 0 ? getAmountForDomeWeight(box.appliedWeightsD.D, domeExData).toLocaleString() : "N/A"))} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {selectedShipCompany === "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (
                    <div className="display-flex flex-column font-c-default m-t-20">
                        <div className="m-a-open-bar p-l-20 p-r-20">
                            <p className="font-t-title font-s-14">총배송비용[전체박스]</p>
                            <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                        </div>
                        <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                            <div className="display-flex flex-column wd-100pc">
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">국내배송</p>
                                    <p className="font-c-btorage">{calculateTotalDOMECost()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectedShipCompany !== "국내배송" && tables.length > 0 && tables.length === tableCount - 1 && (
                    <div className="display-flex flex-column font-c-default m-t-20">
                        <div className="m-a-open-bar p-l-20 p-r-20">
                            <p className="font-t-title font-s-14">총배송비용[전체박스]</p>
                            <i className="m-a-ord-accordion-icon" style={{transform: "rotate(90deg)"}}/>
                        </div>
                        <div className="m-a-top-border-card display-flex flex-column wd-100pc">
                            <div className="display-flex flex-column wd-100pc">
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">FedEx</p>
                                    <p className="font-c-btorage">{calculateTotalFEDEXCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">FedEx-E</p>
                                    <p className="font-c-btorage">{calculateTotalFEDEXECost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">UPS</p>
                                    <p className="font-c-btorage">{calculateTotalUPSCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">UPS-E</p>
                                    <p className="font-c-btorage">{calculateTotalUPSECost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">EMS</p>
                                    <p className="font-c-btorage">{calculateTotalEMSCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">EMSP</p>
                                    <p className="font-c-btorage">{calculateTotalEMSPCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">SEA</p>
                                    <p className="font-c-btorage">{calculateTotalSHIPCost()}</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">Small-Packet</p>
                                </div>
                                <div className="display-flex flex-row just-cont-sb wd-100pc font-t-title p-t-3 p-b-3">
                                    <p className="">K-Packet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {selectedShipCompany !== "국내배송" && (
                <div className="display-flex flex-row just-cont-sb wd-100pc p-l-20 p-r-20 p-t-5 p-b-5 font-s-14">
                    <div className="display-flex flex-row"><p>배송료제외</p></div>
                    <div className="display-flex flex-row gap15">
                        <div className="display-flex flex-row">
                            <p>FedEx</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   checked={fedexPayChk} onChange={() => setFedexPayChk(!fedexPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>UPS</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   disabled={disableOtherShipping}
                                   checked={upsPayChk} onChange={() => setUpsPayChk(!upsPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>EMS</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   disabled={disableOtherShipping}
                                   checked={emsPayChk} onChange={() => setEmsPayChk(!emsPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>EMSP</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   disabled={disableOtherShipping}
                                   checked={emsPrePayChk} onChange={() => setEmsPrePayChk(!emsPrePayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>SEA</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   disabled={disableOtherShipping}
                                   checked={seaPayChk} onChange={() => setSeaPayChk(!seaPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>K-Pack</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   disabled={disableOtherShipping}
                                   checked={kpPayChk} onChange={() => setKpPayChk(!kpPayChk)}/>
                        </div>
                        <div className="display-flex flex-row">
                            <p>Small-Packet</p>
                            <input className="admin-input-chkBox m-t-3 m-l-2" type="checkbox"
                                   disabled={disableOtherShipping}
                                   checked={asPayChk} onChange={() => setAsPayChk(!asPayChk)}/>
                        </div>
                    </div>
                </div>
            )}
            <div className="an-footer-frame display-flex flex-row just-cont-center align-center">
                {orderData.type === 'orderdt' && (
                    <div>
                        <button className="admin-btn-A wd-100 ht-35 font-s-13 btn-click-event m-r-8" onClick={regExit}>닫기</button>
                        <button className="admin-btn-C wd-100 ht-35 font-s-13 btn-click-event" id="expRegComplete" disabled={isLoading} onClick={alert}>등록완료</button>
                    </div>
                )}
                {orderData.type === 'payinfo' && (
                    <div>
                        <button className="admin-btn-A wd-100 ht-35 font-s-13 btn-click-event m-r-8" onClick={regExit}>닫기</button>
                        <button className="admin-btn-C wd-100 ht-35 font-s-13 btn-click-event" disabled={isLoading} onClick={alert3}>저장</button>
                    </div>
                )}
            </div>
            {isLoading && <CommonLoading/>}
            {alertShow === true ?
                <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel1} eventHandle2={expRegComplete}
                                msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow3 === true ?
                <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={expRegComplete}
                                msgData1={msgConfirmData5} msgData2={msgConfirmData6}/> : null}
        </div>
    );
}

export default AdminRegModal
