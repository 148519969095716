import '../../css/mobile/MUserReviewWritePg.css';
import MHeader from "../../components/mobile/MHeader";
import commonHeader from "../../assets/img/commonHeader.png";
import Delete from "../../assets/img/deleteIcon.svg";
import Upload from "../../assets/img/uploadPhotoIcon.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from '../../services/axiosInstance';
import {connect} from "../../services/WebsocketService";
import reviewUpload from "../../assets/img/reviewUpload.png";
import Expand from "../../assets/img/commonBottom.png";
import {useWebSocket} from "../../services/WebsocketContext";
import withAuth from "../../auth/UserRouteAuth";
import {BiCaretDown} from "react-icons/bi";
import receivedComplete from "../../assets/img/receivedComplete.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";

const MUserReviewWritePg = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [ordNumErrorMsg, setOrdNumErrorMsg] = useState(false);
    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [countryErrorMsg, setCountryErrorMsg] = useState(false);
    const [shipcoErrorMsg, setShipcoErrorMsg] = useState(false);
    const [reviewErrorMsg, setReviewErrorMsg] = useState(false);

    // 여기서부터 커스텀 셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
        setCountryErrorMsg(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({ value: '', label: t(`ordCrLabel`) });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data)
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
            selectAllCountry();
            bringToShipCo();
            selectedReviewPayOrder();
        }
    }, [SSAT, LCAT])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm}(${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const [ordNumList, setOrdNumList] = useState([]);
    const [selectedOrdNum, setSelectedOrdNum] = useState('');
    const selectedReviewPayOrder = () => {
        axios({
            method: 'get',
            url: '/review/selectedReviewPayOrder',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setOrdNumList(response.data);
            })
    }

    // 여기서부터 커스텀 셀렉트
    const [ordNumShow, setOrdNumShow] = useState(false);
    const ordNumRef = useRef(null);

    const toggleOrdNumShow = () => {
        setOrdNumShow(!ordNumShow);
    };

    const handleOrdNumChange = (ordNum) => {
        setSelectedOrdNum(ordNum);
        setOrdNumShow(false);
        setOrdNumErrorMsg(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ordNumRef.current && !ordNumRef.current.contains(event.target)) {
                setOrdNumShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [shipcoList, setShipcoList] = useState([]); // DB데이터
    const [selectedShipco, setSelectedShipco] = useState('');
    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setShipcoList(response.data)
        })

    // 여기서부터 커스텀 셀렉트
    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
        setShipcoErrorMsg(false)
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipcoRef.current && !shipcoRef.current.contains(event.target)) {
                setShipcoShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트


    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
        setTitleErrorMsg(false);
    };

    const [reviewText, setReviewText] = useState('');
    const handleChangeReviewText = (e) => {
        setReviewText(e.target.value);
        setReviewErrorMsg(false);
    };


    const [reviewContainers, setReviewContainers] = useState([{
        reviewImg: reviewUpload,
        reviewImgSt: false,
        reviewImgError: false
    }]);
    const reviewImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setReviewContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImg: URL.createObjectURL(file),
                        reviewImgSt: true,
                        reviewImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const reviewImgInit = (idx) => () => {
        setReviewContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.reviewImgSt) {
                newContainers.push({reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false});
            }

            return newContainers;
        });
        // formData.delete('reviewImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.reviewImgSt) {
            return;
        }

        newContainers.push({reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false});
    };

    const validation = () => {
        if (selectedOrdNum === '' || !selectedOrdNum) setOrdNumErrorMsg(true);
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedCountry.value === '' || !selectedCountry) setCountryErrorMsg(true);
        if (selectedShipco === '' || !selectedShipco) setShipcoErrorMsg(true);
        if (reviewText === '' || !reviewText) setReviewErrorMsg(true);
        return !!(selectedOrdNum && titleText && selectedCountry && selectedShipco && reviewText)
    }

    const [isSaveBtLoading, setIsSaveBtLoading] = useState(false);
    const [pointPayCompleteMsg, setPointPayCompleteMsg] = useState(false);
    const [pointPayErrorMsg, setPointPayErrorMsg] = useState(false);
    const [reviewDtPgSeqNum, setReviewDtPgSeqNum] = useState(false);

    const reviewSave = () => {
        setIsSaveBtLoading(true);

        const newFormData = new FormData();
        const crEngNm = selectedCountry.label.match(/\(([^)]+)\)/);
        newFormData.append('orderNumber', selectedOrdNum);
        newFormData.append('title', titleText);
        newFormData.append('country', crEngNm[1]);
        newFormData.append('shipco', selectedShipco);
        newFormData.append('reviewText', reviewText);

        reviewContainers.forEach((container, idx) => {
            if (container.file) {
                newFormData.append('reviewImg', container.file);
            }
        });

        return axios({
            method: 'post',
            url: '/review/reviewSave',
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then(async (response) => {
                const data = response.data
                console.log("포인트적립 ?  : ", data)
                setReviewDtPgSeqNum(data.seqNum);
                await saveReviewAlarmData(data);
                if (data.pointPayFl) {
                    setPointPayCompleteMsg(true);
                } else {
                    setPointPayErrorMsg(true);
                }
            })
            .catch((error) => {
                console.log("후기저장오류 : ", error)
                setIsSaveBtLoading(false);
            })
            .finally(() => {
                setIsSaveBtLoading(false);
            })
    }

    const saveReviewAlarmData = ((reviewData) => {
        axios({
            method: 'post',
            url: '/order/saveReviewData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: reviewData
        })
            .then(async () => {
                await stompClient.send("/app/reviewAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    const moveToReviewDt = (seqNum) => {
        navigate(`/UserReviewDetailPg/${seqNum}`);
    }

    const valiAndSave = () => {
        if (validation()) {
            reviewSave();
        } else {
            setIsSaveBtLoading(false);
        }
    }

    const [unableToWriteMsg, setUnableToWriteMsg] = useState(false);
    const closeUnableToWriteMsg = () => {
        setUnableToWriteMsg(false);
    }



    return (
        <div className='m-a-user-review-write-pg-container font-t-cont'>
            <MHeader title={t(`rvWtHeader`)} url={"/UserReviewPg"}/> {/*후기등록*/}
            <div className='m-a-user-review-write-sub-title'>
                <img className='m-a-user-review-write-sub-title-icon' src={commonHeader} alt=''/>
                <div className='m-a-user-review-write-sub-title-text'>{t(`rvWtHeader`)}</div>
                {/*이용후기작성*/}
            </div>
            <div className='m-a-user-review-write-divider'/>
            <div className='m-a-user-review-write-input-frame col-2'>

                {/*국가*/}
                <div className="ht-40 wd-100pc position-relative" ref={countryRef} onClick={toggleCountryShow}>
                    <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                        {selectedCountry.value ? (
                            <div className="wd-15pc text-center align-center-c">
                                <div className={`m-l-6 font-s-12 fi fi-${selectedCountry.value.toLowerCase()}`}/>
                            </div>
                        ) : (
                            <div className="wd-15pc text-center align-center-c p-l-5">
                                <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                            </div>
                        )}
                        <input className="country-select-in-input wd-75pc font-s-12 p-l-8"
                               type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="nope"
                               value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                               onChange={handleCountrySearch}/>
                        <i className="wd-15pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                            <BiCaretDown/>
                        </i>
                    </div>
                    {countryShow && (
                        <div className="country-select-data wd-100pc position-absolute m-t-5">
                            <div className="country-select-data-child">
                            {filteredCountryList.map((country, index) => (
                                <div className="selectChildBt display-flex p-t-10 p-b-10 p-l-10" key={index}
                                     onClick={(event) => handleCountryChange(country, event)} style={{zIndex: 99}}>
                                    <div className={`font-s-15 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                    <p className="font-s-13">{country.label}</p>
                                </div>
                            ))}
                            </div>
                        </div>
                    )}
                </div>

                {/*이용배송사*/}
                <div className="ht-40 wd-100pc position-relative" tabIndex="0" ref={shipcoRef}
                     onClick={(e) => {
                         toggleShipcoShow()
                         e.stopPropagation()
                     }}
                >
                    <button className="selectParentBt wd-100pc ht-100pc">
                        <p className="font-s-13 font-c-lightgrey">{selectedShipco === "" ? t(`rvWtShip`) : selectedShipco}</p>
                        <span className="position-absolute ht-100pc wd-40" style={{right: "0", top: "0"}}>
                                                    <i className="display-flex just-cont-center align-center ht-100pc font-s-20"
                                                       style={{color: "darkgray"}}>
                                                        <BiCaretDown/>
                                                    </i>
                                                </span>
                    </button>
                    {shipcoShow ?
                        <div
                            className="wd-100pc position-absolute m-t-5"
                            style={{
                                width: "100%",
                                border: "1px solid lightgray",
                                boxSizing: "border-box",
                                position: "relative",
                                marginTop: "5px",
                                zIndex: 1000,
                            }}>
                            <div style={{maxHeight: "200px", overflowY: "auto",}}>
                                {shipcoList.filter((shipco) => shipco !== "국내배송").map((shipcoItem, index) => (
                                        <div className="selectChildBt display-flex justify-content-center align-center c-point font-s-14"
                                            key={index} value={shipcoItem} onClick={() => {handleShipcoChange(shipcoItem);}} style={{padding: "10px"}}>
                                            <p className="font-s-14">{shipcoItem}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>

            {countryErrorMsg &&
                <div className={'m-a-user-review-write-error'}>{t(`recCrErr`)}</div>
            }

            {shipcoErrorMsg &&
                <div className={'m-a-user-review-write-error'}>{t(`recNoSelectOsdvErr`)}</div>
            }

            {/*작성 가능한 주문서리스트*/}
            <div className="ht-40 min-ht-40 wd-100pc position-relative" tabIndex="0" ref={ordNumRef}
                 onClick={(e) => {
                     if (ordNumList.length > 0) {
                         toggleOrdNumShow()
                         e.stopPropagation()
                     } else {
                         setUnableToWriteMsg(true);
                     }
                 }}>
                <button className="selectParentBt wd-100pc ht-100pc">
                    <p className="font-s-13 font-c-lightgrey">{selectedOrdNum === "" ? t(`modNum`) : selectedOrdNum}</p>
                    <span className="position-absolute ht-100pc wd-40" style={{right: "0", top: "0"}}>
                                                    <i className="display-flex just-cont-center align-center ht-100pc font-s-20"
                                                       style={{color: "darkgray"}}>
                                                        <BiCaretDown/>
                                                    </i>
                                                </span>
                </button>
                {ordNumShow ?
                    <div
                        className="wd-100pc position-absolute m-t-5"
                        style={{
                            width: "100%",
                            border: "1px solid lightgray",
                            boxSizing: "border-box",
                            position: "relative",
                            marginTop: "5px",
                            zIndex: 1000,
                        }}>
                        <div style={{maxHeight: "200px", overflowY: "auto",}}>
                            {ordNumList.map((order, index) => (
                                <div
                                    className="selectChildColorBt display-flex justify-content-center align-center c-point font-s-14 pd10"
                                    key={index} value={order} onClick={() => {
                                    handleOrdNumChange(order);
                                }}>
                                    <img className="wd-25 ht-25 m-r-10" src={receivedComplete} alt="Order"/>
                                    <p className="font-s-14">{order}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    null
                }
            </div>

            {ordNumErrorMsg &&
                <div className={'m-a-user-review-write-error'}>{t(`rvWtErr4`)}</div>
            }

            <div className="m-a-user-review-write-input-frame">
                <input className='m-a-user-review-write-input' placeholder={t(`rvWtTp`)}
                       value={titleText} maxLength={35} type="text"
                       onChange={handleChangeTitleText}/>
                {titleErrorMsg &&
                    <div className={'m-a-user-review-write-error'}>{t(`rvWtErr3`)}</div>
                }
                <textarea className='m-a-user-review-write-input content'
                          placeholder={t(`myCont`)} spellCheck="false"
                          value={reviewText} maxLength={10000}
                          onChange={handleChangeReviewText}/>
                {reviewErrorMsg &&
                    <div className={'m-a-user-review-write-error'}>{t(`rvWtErr1`)}</div>
                }
                {reviewContainers.map((container, index) => (
                    <div className={'m-a-user-review-img-container'} key={index}>
                        {container.reviewImgSt ? (
                            <div className='m-a-user-review-write-img-frame'>
                                <img className='m-a-user-review-write-uploaded-img'
                                     src={container.reviewImg} alt='reviewImg'/>
                                <div className='m-a-user-review-write-uploaded-img-info'>
                                    <div className='m-a-user-review-write-uploaded-img-delete'
                                         onClick={reviewImgInit(index)}>
                                        <img className='m-a-user-review-write-uploaded-img-delete-icon' src={Delete}
                                             alt='Delete'/>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="m-a-user-review-write-img-frame"
                                 onClick={(e) => {
                                     const parentDiv = e.currentTarget;
                                     const fileInput = parentDiv.querySelector('input[type="file"]');
                                     if (fileInput) fileInput.click();
                                 }}
                            >
                                <input type="file" accept="image/*" style={{display: "none"}}
                                       onChange={reviewImgHandle(index)}/>
                                <div className="m-a-user-review-write-upload">
                                    <img className="m-a-user-review-write-upload-icon" src={Upload} alt=''/>
                                    <div className="m-a-user-review-write-upload-text">Touch Me!</div>
                                </div>
                                {container.reviewImgError &&
                                    <div className={'m-a-user-review-write-error'}>{t(`rvWtErr2`)}😅</div>}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <style>
                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
            </style>
            <button disabled={isSaveBtLoading} className="bt-btn-B wd-80 ht-35 font-s-12"
                    onClick={valiAndSave}>
                {isSaveBtLoading ? (
                    <div className="spinner"></div>
                ) : (
                    <div>{t(`rvdtBt`)}</div>
                )}
            </button>
            {/*작성완료*/}


            {pointPayCompleteMsg ?
                <MsgCheckModal pointPayCompleteMsg={pointPayCompleteMsg}
                               moveToReviewDt={moveToReviewDt}
                               reviewDtPgSeqNum={reviewDtPgSeqNum}/>
                :
                pointPayErrorMsg ?
                    <MsgCheckModal pointPayErrorMsg={pointPayErrorMsg}
                                   moveToReviewDt={moveToReviewDt}
                                   reviewDtPgSeqNum={reviewDtPgSeqNum}/>
                    :
                    unableToWriteMsg ?
                        <MsgCheckModal unableToWriteMsg={unableToWriteMsg}
                                       closeUnableToWriteMsg={closeUnableToWriteMsg}/>
                        :
                    null
            }

        </div>
    )
}

export default withAuth(MUserReviewWritePg);
