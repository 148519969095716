import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import commomHeader from '../../assets/img/commonHeader.png'
import tiktok from '../../assets/img/tiktok.png'
import youtube from '../../assets/img/youtube.png'
import commonGuide from '../../assets/img/commonGuide.png'
import detailCart from '../../assets/img/detailCart.png'
import detailOrder from '../../assets/img/detailOrder.png'
import detailOffline from '../../assets/img/detailOffline.png'
import detailTransfer from '../../assets/img/detailTransfer.png'
import detailDomestic from '../../assets/img/detailDomestic.png'
import detailDelivery from '../../assets/img/detailDelivery.png'
import GuideModal from "../../components/web/modal/GuideModal";

import guidePc1 from '../../assets/img/guidePc1.png'
import guidePc2 from '../../assets/img/guidePc2.png'
import guidePc3 from '../../assets/img/guidePc3.png'
import guidePc4 from '../../assets/img/guidePc4.png'
import guidePc5 from '../../assets/img/guidePc5.png'
import guidePc6 from '../../assets/img/guidePc6.png'
import guidePc7 from '../../assets/img/guidePc7.png'
import guidePc8 from '../../assets/img/guidePc8.png'

import guideOne from '../../assets/img/guideOne.png'
import guideTwo from '../../assets/img/guideTwo.png'
import guideThree from '../../assets/img/guideThree.png'
import guideFour from '../../assets/img/guideFour.png'
import guideFive from '../../assets/img/guideFive.png'
import guideSix from '../../assets/img/guideSix.png'

import axios from '../../services/axiosInstance';
import i18n from "../../i18n";
import {Link} from "react-router-dom";
import SeoHelmet from "../../components/SeoHelmet";

function UserUsageGuidePg() {

    /** region DataSet */

    const { t } = useTranslation();

    const [guideData, setGuideData] = useState([]);

    /** endregion DataTable*/

    const [cartGuide, setCartGuide] = useState(false);
    const [orderGuide, setOrderGuide] = useState(true);
    const [offlineGuide, setOfflineGuide] = useState(false);
    const [transferGuide, setTransferGuide] = useState(false);
    const [domesticGuide, setDomesticGuide] = useState(false);
    const [deliveryGuide, setDeliveryGuide] = useState(false);

    const [cartGuidePopup, setCartGuidePopup] = useState(false);
    const [orderGuidePopup, setOrderGuidePopup] = useState(false);
    const [offlineGuidePopup, setOfflineGuidePopup] = useState(false);
    const [transferGuidePopup, setTransferGuidePopup] = useState(false);
    const [domesticGuidePopup, setDomesticGuidePopup] = useState(false);
    const [deliveryGuidePopup, setDeliveryGuidePopup] = useState(false);

    const cartGuideHeader = t('ugHd1');
    const cartGuideContent = t('ugCont1');
    const orderGuideHeader = t('ugHd2');
    const orderGuideContent = t('ugCont2');
    const offlineGuideHeader = t('ugHd3');
    const offlineGuideContent = t('ugCont3');
    const transferGuideHeader = t('ugHd4');
    const transferGuideContent = t('ugCont4');
    const domesticGuideHeader = t('ugHd5');
    const domesticGuideContent = t('ugCont5');
    const deliveryGuideHeader = t('ugHd6');
    const deliveryGuideContent = t('ugCont6');

    const changeSelectedGuide = ((guide) => {
        if (guide === 'cart') {
            setCartGuide(true);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'order') {
            setCartGuide(false);
            setOrderGuide(true);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'offline') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(true);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'transfer') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(true);
            setDomesticGuide(false);
            setDeliveryGuide(false);
        } else if (guide === 'domestic') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(true);
            setDeliveryGuide(false);
        } else if (guide === 'delivery') {
            setCartGuide(false);
            setOrderGuide(false);
            setOfflineGuide(false);
            setTransferGuide(false);
            setDomesticGuide(false);
            setDeliveryGuide(true);
        }
    })

    const openCartGuidePopup = (() => {
        setCartGuidePopup(true);
    })
    const openOrderGuidePopup = (() => {
        setOrderGuidePopup(true);
    })
    const openOfflineGuidePopup = (() => {
        setOfflineGuidePopup(true);
    })
    const openTransferGuidePopup = (() => {
        setTransferGuidePopup(true);
    })
    const openDomesticGuidePopup = (() => {
        setDomesticGuidePopup(true);
    })
    const openDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(true);
    })
    const closeCartGuidePopup = (() => {
        setCartGuidePopup(false);
    })
    const closeOrderGuidePopup = (() => {
        setOrderGuidePopup(false);
    })
    const closeOfflineGuidePopup = (() => {
        setOfflineGuidePopup(false);
    })
    const closeTransferGuidePopup = (() => {
        setTransferGuidePopup(false);
    })
    const closeDomesticGuidePopup = (() => {
        setDomesticGuidePopup(false);
    })
    const closeDeliveryGuidePopup = (() => {
        setDeliveryGuidePopup(false);
    })

    const groupByType = (guideData) => {
        return guideData.reduce((acc, guide) => {
            const type = guide.gtype;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(guide);
            return acc;
        }, {});
    };

    /** region Event */

    useEffect(() => {
        const selectGuideData = (lang) => {
            axios.get(`/guide/selectGuideList?lang=${lang}`)
                .then((response) => {
                    const filteredData = response.data.filter((guide) => guide.gmachineType === "0");
                    const groupedData = groupByType(filteredData);
                    setGuideData(groupedData);
                });
        };

        const currentLanguage = i18n.language || 'ko';
        let lang = '0';

        switch (currentLanguage) {
            case 'en':
                lang = '1';
                break;
            case 'ja':
                lang = '2';
                break;
            case 'zh':
                lang = '3';
                break;
            case 'id':
                lang = '4';
                break;
            default:
                lang = '0';
        }

        selectGuideData(lang.charAt(0));
    }, [i18n.language]);

    const moveToYouyubeLink = () => {
        const lang = i18n.language || 'ko'
        if (lang === 'en') {
            window.open('https://www.youtube.com/watch?v=WBfR96sBKn8', "_blank");
        } else if (lang === 'ja') {
            window.open('https://www.youtube.com/watch?v=ZQJiBi3mj54', "_blank");
        } else if (lang === 'zh') {
            window.open('https://www.youtube.com/watch?v=2KazaZKlgpo', "_blank");
        } else if (lang === 'id') {
            window.open('https://www.youtube.com/watch?v=wQ3IADErJv8', "_blank");
        } else {
            window.open('https://www.youtube.com/watch?v=gs2gt3sew74', "_blank");
        }
    }

    const moveToTiktokLink = () => {
        const lang = i18n.language || 'ko'
        if (lang === 'en') {
            window.open('https://www.tiktok.com/@btorag_e/video/7460468450054425864', "_blank");
        } else if (lang === 'ja') {
            window.open('https://www.tiktok.com/@btorag_e/video/7460468639989304594', "_blank");
        } else if (lang === 'zh') {
            window.open('https://www.tiktok.com/@btorag_e/video/7460468997545413895', "_blank");
        } else if (lang === 'id') {
            window.open('https://www.tiktok.com/@btorag_e/video/7460469385728249096', "_blank");
        } else {
            window.open('https://www.tiktok.com/@btorag_e/video/7460467183391477000', "_blank");
        }
    }


    /** endregion */

    return (
        <div>

            <SeoHelmet>
                <title>이용가이드</title>
                <meta name="description" content="구매대행/배송대행을 더 쉽게 이용하실 수 있도록 가이드를 참고하세요"/>
                <link rel="canonical" href="https://www.btorage.com/UserUsageGuidePg"/>
            </SeoHelmet>

            <div style={{display: "flex", alignItems: "flex-start", justifyContent:"space-between", padding: "10px 0"}}>
                <div className="user-center">
                <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                <p className="font-s-20 font-t-title font-c-default" style={{margin:"5px 0 0 10px"}}>{t(`hdSMenu2`)}</p>
                </div>
                <div>
                <img onClick={moveToYouyubeLink} className="wd-40 ht-40 m-l-10 c-point" style={{opacity:0.6}} src={youtube} alt="Tiktok"/>
                <img onClick={moveToTiktokLink} className="wd-40 ht-40 m-l-10 c-point" style={{opacity:0.6}} src={tiktok} alt="Youtube"/>
                </div>
            </div>
            <div className="display-flex just-cont-sb align-center m-t-20">
            {/*장바구니가이드*/}
                <div onClick={()=> changeSelectedGuide('cart')} style={{
                    width:"130px",
                    border: cartGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center ht-90">
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailCart} alt="cart"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">{t('ugCont7')}</p>
                    </div>
                </div>
                {/*구매대행가이드*/}
                <div onClick={()=> changeSelectedGuide('order')} style={{
                    width:"130px",
                    border: orderGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailOrder} alt="order"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">{t('ugCont8')}</p>
                    </div>
                </div>
                {/*오프라인가이드*/}
                <div onClick={()=> changeSelectedGuide('offline')} style={{
                    width:"130px",
                    border: offlineGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailOffline} alt="offline"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">{t('ugCont9')}</p>
                    </div>
                </div>
                {/*이체대행가이드*/}
                <div onClick={()=> changeSelectedGuide('transfer')} style={{
                    width:"130px",
                    border: transferGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailTransfer} alt="transfer"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">{t('ugCont10')}</p>
                    </div>
                </div>
                {/*국내배송가이드*/}
                <div onClick={()=> changeSelectedGuide('domestic')} style={{
                    width:"130px",
                    border: domesticGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailDomestic} alt="domestic"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">{t('ugCont11')}</p>
                    </div>
                </div>
                {/*배송대행가이드*/}
                <div onClick={()=> changeSelectedGuide('delivery')} style={{
                    width:"130px",
                    border: deliveryGuide ? "solid 2px darkorange" : null,
                    cursor:"pointer",
                    padding:"15px",
                    borderRadius:"10px"
                }}>
                    <div className="user-center" style={{height:"90px"}}>
                        <div className="user-center" style={{width:"80px", height:"70px", backgroundColor:"#F6F6F6", borderRadius:"10px"}}>
                            <img style={{width:"40px", height:"40px", opacity:"0.7"}} src={detailDelivery} alt="delivery"/>
                        </div>
                    </div>
                    <div className="user-center font-t-cont">
                        <p className="text-center font-s-15">{t('ugCont12')}</p>
                    </div>
                </div>
            </div>
            { cartGuide ?
                // 장바구니가이드 내용
                <div>
                    <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                        <div className="user-center">
                            <p className="font-s-25 font-t-title">{t('ugCont13')}</p>
                            <img onClick={openCartGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                            <p className="font-t-cont font-s-13 m-l-3">({t('ugCont14')})</p>
                        </div>
                    </div>
                    <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                        {/*절차1*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc1} alt="process1"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont15')}</p>
                                </div>
                            </div>
                        </div>
                        {/*절차2*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process2"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont16')}</p>
                                </div>
                            </div>
                        </div>
                        {/*절차3*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont17')}</p>
                                </div>
                            </div>
                        </div>
                        {/*절차4*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont18')}</p>
                                </div>
                            </div>
                        </div>
                        {/*절차5*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont19')}</p>
                                </div>
                            </div>
                        </div>
                        {/*절차6*/}
                        <div>
                            <div style={{width:"150px", minHeight:"150px"}}>
                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "lightgrayscale(100%) brightness(99%)"}}  src={guideSix} alt="six"/>
                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                </div>
                                <div className="user-center">
                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont20')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*장바구니 사진영역*/}
                    <div className="font-t-cont m-t-20 m-b-10">
                        <div className="ht-100pc wd-100pc">
                            <div className="userGuideContFrame p-t-25 p-b-20 c-def">
                                {guideData['P00'] && guideData['P00'][0] && (
                                    <div key={guideData['P00'][0].gtitle}>
                                        {/*<p className="font-t-title font-s-22">제목: {guideData['P00'][0].gtitle}</p>*/}
                                        <div dangerouslySetInnerHTML={{ __html: guideData['P00'][0].gcont }} className="font-s-18"/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                :
                orderGuide ?
                    // 구매대행가이드 내용
                    <div>
                        <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                            <div className="user-center">
                                <p className="font-s-25 font-t-title">{t('ugCont21')}</p>
                                <img onClick={openOrderGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                <p className="font-t-cont font-s-13 m-l-3">({t('ugCont14')})</p>
                            </div>
                        </div>
                        <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                            {/*절차1*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process2"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont22')}</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차2*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont23')}</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차3*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont17')}</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차4*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont18')}</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차5*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont19')}</p>
                                    </div>
                                </div>
                            </div>
                            {/*절차6*/}
                            <div>
                                <div style={{width:"150px", minHeight:"150px"}}>
                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideSix} alt="six"/>
                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                    </div>
                                    <div className="user-center">
                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont20')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*구매대행 사진영역*/}
                        <div className="font-t-cont m-t-20 m-b-10">
                            <div className="ht-100pc wd-100pc">
                                <div className="userGuideContFrame p-t-25 p-b-20 c-def">
                                    {guideData['P01'] && guideData['P01'][0] && (
                                        <div key={guideData['P01'][0].gtitle}>
                                            {/*<p className="font-t-title font-s-22">제목: {guideData['P01'][0].gtitle}</p>*/}
                                            <div dangerouslySetInnerHTML={{ __html: guideData['P01'][0].gcont }} className="font-s-18" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    offlineGuide ?
                        // 오프라인가이드 내용
                        <div>
                            <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                <div className="user-center">
                                    <p className="font-s-25 font-t-title">{t('ugCont24')}</p>
                                    <img onClick={openOfflineGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                    <p className="font-t-cont font-s-13 m-l-3">({t('ugCont14')})</p>
                                </div>
                            </div>
                            <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                {/*절차1*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process1"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont25')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차2*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont23')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차3*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont17')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차4*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont18')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차5*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont19')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/*절차6*/}
                                <div>
                                    <div style={{width:"150px", minHeight:"150px"}}>
                                        <div className="user-center" style={{height:"90px", position:"relative"}}>
                                            <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideSix} alt="six"/>
                                            <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                        </div>
                                        <div className="user-center">
                                            <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont20')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*오프라인 사진영역*/}
                            <div className="font-t-cont m-t-20 m-b-10">
                                <div className="ht-100pc wd-100pc">
                                    <div className="userGuideContFrame p-t-25 p-b-20 c-def">
                                        {guideData['P02'] && guideData['P02'][0] && (
                                            <div key={guideData['P02'][0].gtitle}>
                                                {/*<p className="font-t-title font-s-22">제목: {guideData['P02'][0].gtitle}</p>*/}
                                                <div dangerouslySetInnerHTML={{ __html: guideData['P02'][0].gcont }} className="font-s-18" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        transferGuide ?
                            // 이체대행가이드 내용
                            <div>
                                <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                    <div className="user-center">
                                        <p className="font-s-25 font-t-title">{t('ugCont26')}</p>
                                        <img onClick={openTransferGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                        <p className="font-t-cont font-s-13 m-l-3">({t('ugCont14')})</p>
                                    </div>
                                </div>
                                <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                    {/*절차1*/}
                                    <div>
                                        <div style={{width:"150px", minHeight:"150px"}}>
                                            <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                                <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process1"/>
                                            </div>
                                            <div className="user-center">
                                                <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont27')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*절차2*/}
                                    <div>
                                        <div style={{width:"150px", minHeight:"150px"}}>
                                            <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                                <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                            </div>
                                            <div className="user-center">
                                                <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont28')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/*절차3*/}
                                    <div>
                                        <div style={{width:"150px", minHeight:"150px"}}>
                                            <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                                <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process3"/>
                                            </div>
                                            <div className="user-center">
                                                <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont29')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*이체대행 사진영역*/}
                                <div className="font-t-cont m-t-20 m-b-10">
                                    <div className="ht-100pc wd-100pc">
                                        <div className="userGuideContFrame p-t-25 p-b-20 c-def">
                                            {guideData['P03'] && guideData['P03'][0] && (
                                                <div key={guideData['P03'][0].gtitle}>
                                                    {/*<p className="font-t-title font-s-22">제목: {guideData['P03'][0].gtitle}</p>*/}
                                                    <div dangerouslySetInnerHTML={{ __html: guideData['P03'][0].gcont }} className="font-s-18" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            domesticGuide ?
                                // 국내배송가이드 내용
                                <div>
                                    <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                        <div className="user-center">
                                            <p className="font-s-25 font-t-title">{t('ugCont30')}</p>
                                            <img onClick={openDomesticGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                            <p className="font-t-cont font-s-13 m-l-3">({t('ugCont14')})</p>
                                        </div>
                                    </div>
                                    <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                        {/*절차1*/}
                                        <div>
                                            <div style={{width:"150px", minHeight:"150px"}}>
                                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process1"/>
                                                </div>
                                                <div className="user-center">
                                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont31')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*절차2*/}
                                        <div>
                                            <div style={{width:"150px", minHeight:"150px"}}>
                                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process2"/>
                                                </div>
                                                <div className="user-center">
                                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont23')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/*절차3*/}
                                        <div>
                                            <div style={{width:"150px", minHeight:"150px"}}>
                                                <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                    <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                                    <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc7} alt="process3"/>
                                                </div>
                                                <div className="user-center">
                                                    <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont32')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*국내배송 사진영역*/}
                                    <div className="font-t-cont m-t-20 m-b-10">
                                        <div className="ht-100pc wd-100pc">
                                            <div className="userGuideContFrame p-t-25 p-b-20 c-def">
                                                {guideData['P04'] && guideData['P04'][0] && (
                                                    <div key={guideData['P04'][0].gtitle}>
                                                        {/*<p className="font-t-title font-s-22">제목: {guideData['P04'][0].gtitle}</p>*/}
                                                        <div dangerouslySetInnerHTML={{ __html: guideData['P04'][0].gcont }} className="font-s-18" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                deliveryGuide ?
                                    // 배송대행가이드 내용
                                    <div>
                                        <div className="user-center ht-100 m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                            <div className="user-center">
                                                <p className="font-s-25 font-t-title">{t('ugCont33')}</p>
                                                <img onClick={openDeliveryGuidePopup} className="wd-30 ht-30 m-l-10 c-point m-b-5" src={commonGuide} alt="guide"/>
                                                <p className="font-t-cont font-s-13 m-l-3">({t('ugCont14')})</p>
                                            </div>
                                        </div>
                                        <div className="font-t-cont" style={{height:"150px", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"40px"}}>
                                            {/*절차1*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideOne} alt="one"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc8} alt="process1"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont34')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차2*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideTwo} alt="two"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc2} alt="process2"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont35')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차3*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideThree} alt="three"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc3} alt="process3"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont17')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차4*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFour} alt="four"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc4} alt="process4"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont18')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차5*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideFive} alt="five"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc5} alt="process5"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont19')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*절차6*/}
                                            <div>
                                                <div style={{width:"150px", minHeight:"150px"}}>
                                                    <div className="user-center" style={{height:"90px", position:"relative"}}>
                                                        <img style={{width:"22px", height:"22px", position:"absolute", top:"5px", left:"20px", opacity:"0.6", filter: "grayscale(100%) brightness(99%)"}} src={guideSix} alt="six"/>
                                                        <img style={{width:"60px", height:"60px", opacity:"0.7"}} src={guidePc6} alt="process6"/>
                                                    </div>
                                                    <div className="user-center">
                                                        <p style={{margin:"0", fontSize:"14px", fontWeight:"lighter", color:"black", textAlign:"center"}}>{t('ugCont20')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*배송대행 사진영역*/}
                                        <div className="font-t-cont m-t-20 m-b-10">
                                            <div className="ht-100pc wd-100pc">
                                                <div className="userGuideContFrame p-t-25 p-b-20 c-def">
                                                    {guideData['P05'] && guideData['P05'][0] && (
                                                        <div key={guideData['P05'][0].gtitle}>
                                                            {/*<p className="font-t-title font-s-22">제목: {guideData['P05'][0].gtitle}</p>*/}
                                                            <div dangerouslySetInnerHTML={{ __html: guideData['P05'][0].gcont }} className="font-s-18" />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
            }
            { cartGuidePopup ?
                <GuideModal cartGuidePopup={cartGuidePopup} closeCartGuidePopup={closeCartGuidePopup}
                            cartGuideHeader={cartGuideHeader} cartGuideContent={cartGuideContent}/>
                :
                orderGuidePopup ?
                    <GuideModal orderGuidePopup={orderGuidePopup} closeOrderGuidePopup={closeOrderGuidePopup}
                                orderGuideHeader={orderGuideHeader} orderGuideContent={orderGuideContent}/>
                    :
                    offlineGuidePopup ?
                        <GuideModal offlineGuidePopup={offlineGuidePopup} closeOfflineGuidePopup={closeOfflineGuidePopup}
                                    offlineGuideHeader={offlineGuideHeader} offlineGuideContent={offlineGuideContent}/>
                        :
                        transferGuidePopup ?
                            <GuideModal transferGuidePopup={transferGuidePopup} closeTransferGuidePopup={closeTransferGuidePopup}
                                        transferGuideHeader={transferGuideHeader} transferGuideContent={transferGuideContent}/>
                            :
                            domesticGuidePopup ?
                                <GuideModal domesticGuidePopup={domesticGuidePopup} closeDomesticGuidePopup={closeDomesticGuidePopup}
                                            domesticGuideHeader={domesticGuideHeader} domesticGuideContent={domesticGuideContent}/>
                                :
                                deliveryGuidePopup ?
                                    <GuideModal deliveryGuidePopup={deliveryGuidePopup} closeDeliveryGuidePopup={closeDeliveryGuidePopup}
                                                deliveryGuideHeader={deliveryGuideHeader} deliveryGuideContent={deliveryGuideContent}/>
                                    :
                                    null
            }
        </div>
    )
}

export default UserUsageGuidePg
