import React, {useEffect, useState} from "react";
import {loadPaypalScript} from '../../services/PaypalUtils';
import {useTranslation} from "react-i18next";
import "../../css/mobile/MPaypalModal.css";
import PaypalPaymentBt from "../web/modal/PaypalPaymentBt";
import MsgConfirmModal from "../web/modal/MsgConfirmModal";

// 주문서 -> 페이팔모달로 데이터 전달한 후 페이팔 버튼(PaypalPaymentBt.js)을 클릭하여 다시 데이터를 PaypalUtils.js 페이팔 링크에 전달함
// 전달 후 사용자가 결제 또는 실패할 경우 반환값이 최종 PaypalModal.processAfterPaypal()함수로 반환됨

function MPaypalModal({paypalPopupClose, currency, finalPayExpense, lastForPrice, paypalOrderProcess}) {
    const {t} = useTranslation();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        // Load PayPal script and set state to true once loaded
        loadPaypalScript(currency).then(() => {
            setScriptLoaded(true);
        });
    }, [currency]);

    const [cardNumber, setCardNumber] = useState('');
    const [exp, setExp] = useState('');
    const [cvv, setCvv] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [bZip, setBZip] = useState('');
    const [payErrorMsg, setPayErrorMsg] = useState(false);

    const processAfterPaypal = ((payFl) => {
        if (payFl === "COMPLETED") {
            paypalOrderProcess();
        } else {
            setPayErrorMsg(true);
        }
    })

    const payErrorMsgWord = <p style={{fontSize: "15px"}}>{t(`pmMdMsg`)} &#128514;</p>

    return (
        <div className={'m-a-paypal-modal-background'}>
            <div className={'m-a-paypal-container'}>
                <div className={'m-a-paypal-content'}>
                    <div className={'m-a-paypal-content-row'}>
                        <div className={'m-a-paypal-content-label lg bold'}>{t(`pmHeader1`)}</div>
                        <div className={'m-a-paypal-content-label lg bold primary'}
                             onClick={paypalPopupClose}>Back
                        </div>
                    </div>
                    <div className={'m-a-paypal-content-row'}>
                        <div className={'m-a-paypal-content-label'}>{t(`pcWon`)}</div>
                        <div className={'m-a-paypal-content-label'}
                             onClick={paypalPopupClose}>
                            {finalPayExpense && finalPayExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                    </div>
                    <div className={'m-a-paypal-divider'}/>
                    <div className={'m-a-paypal-content-row'}>
                        <div className={'m-a-paypal-content-label bold'}>{t(`pmHeader2`)}</div>
                        <div className={'m-a-paypal-content-label bold'}
                             onClick={paypalPopupClose}>
                            {lastForPrice && lastForPrice}
                            <div className={'m-a-paypal-content-label'}>{currency && currency}</div>
                        </div>
                    </div>
                </div>
                {scriptLoaded &&
                    <PaypalPaymentBt total={lastForPrice} processAfterPaypal={processAfterPaypal}
                                     currency={currency}
                    />
                }
            </div>

            {payErrorMsg ?
                <MsgConfirmModal payErrorMsg={payErrorMsg} payErrorMsgClose={setPayErrorMsg(false)}
                                 payErrorMsgWord={payErrorMsgWord}/>
                :
                null
            }
        </div>
    )
}

export default MPaypalModal
