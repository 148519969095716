import React, {useEffect, useState, useRef, useMemo, createRef} from "react";
import '../../css/web/UserOrderPg.css';
import PaypalLogo from '../../assets/img/orderPaypal.png';
import WechatpayLogo from '../../assets/img/WechatPayLogo.png';
import AlipayLogo from '../../assets/img/AlipayLogo.png';
import DepositPay from '../../assets/img/depositPay.png';
import PointPay from '../../assets/img/pointPay.png';
import NoBank from '../../assets/img/noBank.png';
import AliQrCode from '../../assets/img/aliqrcode.png';
import wechatQrCode from '../../assets/img/wechatqrcode.png';
import axios from '../../services/axiosInstance'
import {HiReceiptTax} from "react-icons/hi";
import {BiCaretDown} from "react-icons/bi";
import {BsFillPersonCheckFill, BsMailbox2} from "react-icons/bs";
import {MdPhoneIphone} from "react-icons/md";
import {TbAddressBook} from "react-icons/tb";
import UserAddrBook from "../../components/web/modal/UserAddrBook";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

import excel from "exceljs";
import * as XLSX from "xlsx";
import {useLocation, useNavigate} from "react-router-dom";
import CouponUse from "../../components/web/modal/CouponUse";
import PaypalModal from "../../components/web/modal/PaypalModal";
import withAuth from "../../auth/UserRouteAuth";
import {useTranslation} from "react-i18next";
import termsView from "../../assets/img/termsView.png";
import CommonTermsPopup from "../../components/web/modal/CommonTermsPopup";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import {useWebSocket} from "../../services/WebsocketContext";

import stripe from "../../assets/img/StripeLogo.png";
import btoragePay from "../../assets/img/BtorageLogo.png";
import creditCard from "../../assets/img/payFormHeader.png";
import ReadyingPopup from "../../components/web/modal/ReadyingPopup";
import 'flag-icons/css/flag-icons.css';
import {Helmet} from "react-helmet-async";

function UserOrderPg() {

    const {t} = useTranslation();

    // 나중에 URL로 입력하여 들어와도 로그인 안되어있으면 로그인창으로 보내기
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [isSaveBtLoading, setIsSaveBtLoading] = useState(false);
    const [isSaveBtDisabled, setIsSaveBtDisabled] = useState(false);
    const [isPayBtLoading, setIsPayBtLoading] = useState(false);
    const [isPayBtDisabled, setIsPayBtDisabled] = useState(false);

    const [recieverInfoAccordion, setRecieverInfoAccordion] = useState(true);
    const [cartAccordion, setCartAccordion] = useState(false);
    const [orderAccordion, setOrderAccordion] = useState(false);
    const [offlineAccordion, setOfflineAccordion] = useState(false);
    const [transferAccordion, setTransferAccordion] = useState(false);
    const [domesticAccordion, setDomesticAccordion] = useState(false);
    const [deliveryAccordion, setDeliveryAccordion] = useState(false);
    const [invoiceAccordion, setInvoiceAccordion] = useState(true);
    const [requestConfigAccordion, setRequestConfigAccordion] = useState(true);

    // const [selectedOrderChoiceOption, setSelectedOrderChoiceOption] = useState('');

    const [useType, setUseType] = useState("gift");

    const [defaultAddr, setDefaultAddr] = useState(true);
    const [newAddr, setNewAddr] = useState(false);

    const [deliveryChoice, setDeliveryChoice] = useState([]);
    const [selectedDeliveryChoiceOption, setSelectedDeliveryChoiceOption] = useState(t('dvSelect'));
    const [domesticDeliveryChoice, setDomesticDeliveryChoice] = useState([]);

    const [country, setCountry] = useState('국가');
    // const [countryLabel, setCountryLabel] = useState('');
    const [taxid, setTaxid] = useState("");
    const [recname, setRecname] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [telnum, setTelnum] = useState("");

    const selectedDeliveryChoiceOptionRef = useRef(null);
    const taxidRef = useRef(null);
    const recnameRef = useRef(null);
    const address1Ref = useRef(null);
    const cityRef = useRef(null);
    const zipcodeRef = useRef(null);
    const telnumRef = useRef(null);

    const [tagRemove, setTagRemove] = useState(false);
    const [shoesBoxRemove, setShoesBoxRemove] = useState(false);
    const [hangerRemove, setHangerRemove] = useState(false);
    const [compressionPack, setCompressionPack] = useState(false);
    const [productBoxRemove, setProductBoxRemove] = useState(false);
    const [packingHold, setPackingHold] = useState(false);
    const [deliveryDepositPay, setDeliveryDepositPay] = useState(false);
    const [deliveryMessengerGuide, setDeliveryMessengerGuide] = useState(false);
    const [detailRequest, setDetailRequest] = useState("");

    const [orderChoiceError, setOrderChoiceError] = useState(false);
    const [deliveryChoiceError, setDeliveryChoiceError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [taxidError, setTaxidError] = useState(false);
    const [recnameError, setRecnameError] = useState(false);
    const [address1Error, setAddress1Error] = useState(false);
    const [address2Error, setAddress2Error] = useState(false);
    const [address3Error, setAddress3Error] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [zipcodeError, setZipcodeError] = useState(false);
    const [telnumError, setTelnumError] = useState(false);
    const [payMethodError, setPayMethodError] = useState(false);
    const [pointPayError, setPointPayError] = useState(false);
    const [depositSelectErr, setDepositSelectErr] = useState(false);
    const [pointSelectErr, setPointSelectErr] = useState(false);
    const [etcPayError, setEtcPayError] = useState(false);
    const [discountError, setDiscountError] = useState(false);
    const [holdedValidationError, setHoldedValidationError] = useState(false);

    const [orderAddrBookSt, setOrderAddrBookSt] = useState(false);
    const [getDefaultAddrList, setGetDefaultAddrList] = useState("");

    const [nameInputCount, setNameInputCount] = useState(0);
    const [telnumInputCount, setTelnumInputCount] = useState(0);
    const [zipcodeInputCount, setZipcodeInputCount] = useState(0);
    const [address1InputCount, setAddress1InputCount] = useState(0);
    const [address2InputCount, setAddress2InputCount] = useState(0);
    const [address3InputCount, setAddress3InputCount] = useState(0);
    const [cityInputCount, setCityInputCount] = useState(0);

    const [cartProductHandle, setCartProductHandle] = useState([{
        name: "",
        option: "",
        quantity: "",
        price: "",
        url: ""
    }]);
    const [orderProductHandle, setOrderProductHandle] = useState([{
        name: "",
        option: "",
        quantity: "",
        price: "",
        mallShipPrice: "",
        url: ""
    }]);
    const [offlineProductHandle, setOfflineProductHandle] = useState([{name: "", quantity: "", price: "", fee: ""}]);
    const [transferProductHandle, setTransferProductHandle] = useState([{name: "", price: "", fee: ""}]);
    const [domesticProductHandle, setDomesticProductHandle] = useState([{
        name: "",
        option: "",
        quantity: "",
        price: "",
        mallShipPrice: "",
        fee: "",
        url: ""
    }]);
    const [deliveryProductHandle, setDeliveryProductHandle] = useState([{
        domShipco: "",
        inWaynum: "",
        name: "",
        quantity: ""
    }]);

    const excelFileSelectRefCart = useRef(null);
    const excelFileSelectRefOrder = useRef(null);
    const excelFileSelectRefOffline = useRef(null);
    const excelFileSelectRefTransfer = useRef(null);
    const excelFileSelectRefDomestic = useRef(null);
    const excelFileSelectRefDelivery = useRef(null);

    const [invoiceFile, setInvoiceFile] = useState(null);
    const [fileName, setFileName] = useState(t('modFileBt'));

    const [payWay, setPayWay] = useState('');
    const [readyingPopupSt, setReadyingPopupSt] = useState(false);
    const dollarList = ['USD', 'CAD', 'AUD', 'NZD', 'EUR', 'JPY', 'SGD']
    const [currency, setCurrency] = useState('USD');
    const [lastForPrice, setLastForPrice] = useState(0);

    const [dbFormTotalPrices, setDbFormTotalPrices] = useState(0);
    const [dbFormTotalMallShipPrice, setDbFormTotalMallShipPrice] = useState(0);
    const [dbFormTotalFee, setDbFormTotalFee] = useState(0);
    const [dbFormTotalQuantity, setDbFormTotalQuantity] = useState(0);
    const [dbFormTotalOverseasShippingExpense, setDbFormTotalOverseasShippingExpense] = useState(0);
    const [dbFormTotalEtcExpense, setDbFormTotalEtcExpense] = useState(0);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrices, setTotalPrices] = useState(0);
    const [totalMallShipPrice, setTotalMallShipPrice] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [totalOverseasShippingExpense, setTotalOverseasShippingExpense] = useState(0);
    const [totalEtcExpense, setTotalEtcExpense] = useState(0);
    const [pointSale, setPointSale] = useState('');
    const [depositSale, setDepositSale] = useState('');
    const [couponSale, setCouponSale] = useState('');
    // const finalPayExpense = (totalPrices + totalMallShipPrice + totalFee + totalOverseasShippingExpense + totalEtcExpense -
    //     (Number(pointSale) + Number(depositSale) + Number(couponSale))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const finalPayExpense =
        totalPrices
        + totalMallShipPrice
        + totalFee
        + totalOverseasShippingExpense
        + totalEtcExpense
        - (Number(pointSale) + Number(depositSale) + Number(couponSale));

    const [allAgreeCheck, setAllAgreeCheck] = useState(false);
    // const [infoCollectionAgreeCheck, setInfoCollectionAgreeCheck] = useState(0);
    const [cartServiceAgreeCheck, setCartServiceAgreeCheck] = useState(false);
    const [orderServiceAgreeCheck, setOrderServiceAgreeCheck] = useState(false);
    const [offlineServiceAgreeCheck, setOfflineServiceAgreeCheck] = useState(false);
    const [transferServiceAgreeCheck, setTransferServiceAgreeCheck] = useState(false);
    const [domesticServiceAgreeCheck, setDomesticServiceAgreeCheck] = useState(false);
    const [deliveryServiceAgreeCheck, setDeliveryServiceAgreeCheck] = useState(false);

    const [agreeCheckBoxError, setAgreeCheckBoxError] = useState(false);

    const location = useLocation();
    const cartState = location.state?.cartState || false;
    const orderState = location.state?.orderState || false;
    const offlineState = location.state?.offlineState || false;
    const transferState = location.state?.transferState || false;
    const domesticState = location.state?.domesticState || false;
    const deliveryState = location.state?.deliveryState || false;

    const scrollToFirstEmptyField = () => {
        let isValid = true;
        const inputs = [
            {
                value: selectedDeliveryChoiceOption === t('dvSelect') ? '' : selectedDeliveryChoiceOption,
                ref: selectedDeliveryChoiceOptionRef
            },
            {value: country, ref: countryRef},
            {value: recname, ref: recnameRef},
            {value: telnum, ref: telnumRef},
            {value: zipcode, ref: zipcodeRef},
            {value: address1, ref: address1Ref},
            {value: city, ref: cityRef},
            // 두 조건 충족시에만 미입력필드 스크롤 기능 추가
            ...(country === "ID" ? [{value: taxid, ref: taxidRef}] : []),
        ];
        for (const input of inputs) {
            if (!input.value) {
                input.ref.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                input.ref.current.focus();
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addCartProduct = () => {
        setCartProductHandle([...cartProductHandle, {}]);
        setCartAccordion(true);
    };
    const deleteCartProduct = (index) => {
        setCartProductHandle((prevState) => {
            const updatedState = prevState.filter((_, i) => i !== index);
            if (updatedState.length === 0) {
                setCartAccordion(false);
                setCartServiceAgreeCheck(false);
            }
            return updatedState;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        addToTotalQuantity();
    };

    const copyCartProduct = (index) => {
        setCartProductHandle((prevState) => {
            const copiedProduct = {...prevState[index]};
            return [...prevState, copiedProduct];
        });
        addToTotalQuantity();
    };

    const handleCartProductInput = (index, field, value) => {
        const updatedProducts = [...cartProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setCartProductHandle(updatedProducts);
        addToTotalQuantity();
    };

    const cartQuantityReplaceInput = (cartSeqnum, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleCartQuantityChange(cartSeqnum, onlyNumbers);
    };
    const handleCartQuantityChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleCartPriceChange = (index, newValue) => {
        const updatedCartProductHandle = cartProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setCartProductHandle(updatedCartProductHandle);
    };

    const cartPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = cartNumberWithComma(onlyNumbers);
        handleCartPriceChange(index, formattedValue);
    };

    const cartUploadInputChange = (index, key, value) => {
        const updatedRows = [...cartProductHandle];
        updatedRows[index][key] = value;
        setCartProductHandle(updatedRows);
    };

    const cartQuantityNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartQuantityReplaceInput(index, value);
    }

    const cartPriceNumberInput = (index, field, value) => {
        cartUploadInputChange(index, field, value);
        handleCartProductInput(index, field, value);
        cartPriceReplaceInput(index, value);
    }

    const useCartItemsRefs = (cartProductHandle) => {
        const cartItemsRefs = useRef(
            cartProductHandle.map(() => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                option: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };

        useMemo(() => {
            cartItemsRefs.current = initializeRefs(cartProductHandle.length);
        }, [cartProductHandle.length]);

        return cartItemsRefs;
    };
    const cartItemsRefs = useCartItemsRefs(cartProductHandle);

    const scrollToEmptyFieldCart = () => {
        let isValid = true;
        let isAllEmpty = true;

        for (let i = 0; i < cartProductHandle.length; i++) {
            const { name, option, quantity, price, url } = cartProductHandle[i];
            if (name || option || quantity || price || url) {
                isAllEmpty = false; // 하나라도 입력되어 있으면 false
                break;
            }
        }

        if (isAllEmpty) {
            return true; // 유효성검사 종료
        }

        for (let i = 0; i < cartProductHandle.length; i++) {
            const { name, option, quantity, price, url } = cartProductHandle[i];

            if (!name || !option || !quantity || !price || !url) {
                // 아코디언이 접힌 상태에서 필수 상품필드 양식이 누락되었을 경우 Error
                if (!cartAccordion) {
                    setHoldedValidationError(true);
                    console.log('홀드누락 에러');
                } else {
                    const emptyField = cartItemsRefs.current[i];
                    if (!name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!option) {
                        emptyField.option.scrollIntoView({behavior: 'smooth'});
                        emptyField.option.focus();
                    } else if (!quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    } else if (!price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else if (!url) {
                        emptyField.url.scrollIntoView({behavior: 'smooth'});
                        emptyField.url.focus();
                    }
                }

                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addOrderProduct = () => {
        setOrderProductHandle([...orderProductHandle, {}]);
        setOrderAccordion(true);
    };
    const deleteOrderProduct = (index) => {
        setOrderProductHandle(orderProductHandle.filter((_, i) => i !== index));
        if (orderProductHandle.length === 1) {
            setOrderAccordion(false);
            setOrderServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleOrderProductInput = (index, field, value) => {
        const updatedProducts = [...orderProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setOrderProductHandle(updatedProducts);
    };
    const copyOrderProduct = (index) => {
        const copiedProduct = {...orderProductHandle[index]};
        setOrderProductHandle([...orderProductHandle, copiedProduct]);
    };

    const orderQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleOrderQuantityChange(index, onlyNumbers);
    };
    const handleOrderQuantityChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOrderPriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const handleOrderMallpriceChange = (index, newValue) => {
        const updatedOrderProductHandle = orderProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, mallShipPrice: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOrderProductHandle(updatedOrderProductHandle);
    };

    const orderPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderPriceChange(index, formattedValue);
    };

    const orderMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = orderNumberWithComma(onlyNumbers);
        handleOrderMallpriceChange(index, formattedValue);
    };

    const orderUploadInputChange = (index, key, value) => {
        const updatedRows = [...orderProductHandle];
        updatedRows[index][key] = value;
        setOrderProductHandle(updatedRows);
    };

    const orderQuantityNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderQuantityReplaceInput(index, value);
    }

    const orderPriceNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderPriceReplaceInput(index, value);
    }

    const orderMallpricericeNumberInput = (index, field, value) => {
        orderUploadInputChange(index, field, value);
        handleOrderProductInput(index, field, value);
        orderMallpriceReplaceInput(index, value);
    }

    const useOrderItemsRefs = (orderProductHandle) => {
        const orderItemsRefs = useRef(
            orderProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                url: null,
            }));
        };
        useMemo(() => {
            orderItemsRefs.current = initializeRefs(orderProductHandle.length);
        }, [orderProductHandle.length]);

        return orderItemsRefs;
    };
    const orderItemsRefs = useOrderItemsRefs(orderProductHandle);

    const scrollToEmptyFieldOrder = () => {
        let isValid = true;
        let isAllEmpty = true;

        for (let i = 0; i < orderProductHandle.length; i++) {
            const { name, option, quantity, price, mallShipPrice, url } = orderProductHandle[i];
            if (name || option || quantity || price || mallShipPrice || url) {
                isAllEmpty = false; // 하나라도 입력되어 있으면 false
                break;
            }
        }

        if (isAllEmpty) {
            return true; // 유효성검사 종료
        }

        for (let i = 0; i < orderProductHandle.length; i++) {
            const { name, option, quantity, price, mallShipPrice, url } = orderProductHandle[i];
            if (!name || !quantity || !price || !url) {
                // 아코디언이 접힌 상태에서 필수 상품필드 양식이 누락되었을 경우 Error
                if (!orderAccordion) {
                    setHoldedValidationError(true);
                    console.log('홀드누락 에러');
                } else {
                    const emptyField = orderItemsRefs.current[i];
                    if (!name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    } else if (!price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else if (!url) {
                        emptyField.url.scrollIntoView({behavior: 'smooth'});
                        emptyField.url.focus();
                    }
                }

                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addOfflineProduct = () => {
        setOfflineProductHandle([...offlineProductHandle, {}]);
        setOfflineAccordion(true);
    };

    const deleteOfflineProduct = (index) => {
        setOfflineProductHandle(offlineProductHandle.filter((_, i) => i !== index));
        if (offlineProductHandle.length === 1) {
            setOfflineAccordion(false);
            setOfflineServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }

    const handleOfflineProductInput = (index, field, value) => {
        const updatedProducts = [...offlineProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setOfflineProductHandle(updatedProducts);
    };

    const copyOfflineProduct = (index) => {
        const copiedProduct = {...offlineProductHandle[index]};
        setOfflineProductHandle([...offlineProductHandle, copiedProduct]);
    };

    const offlineQuantityReplaceInput = (index, e) => {
        let onlyNumbers;
        onlyNumbers = e.replace(/\D/g, '');
        handleOfflineQuantityChange(index, onlyNumbers);
    };

    const handleOfflineQuantityChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleOfflinePriceChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, price: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlinePriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/\D/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflinePriceChange(index, formattedValue);
    };

    const handleOfflineFeeChange = (index, newValue) => {
        const updatedOfflineProductHandle = offlineProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setOfflineProductHandle(updatedOfflineProductHandle);
    };

    const offlineFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/\D/g, '');
        const formattedValue = offlineNumberWithComma(onlyNumbers);
        handleOfflineFeeChange(index, formattedValue);
    };

    const offlineUploadInputChange = (index, key, value) => {
        const updatedRows = [...offlineProductHandle];
        updatedRows[index][key] = value;
        setOfflineProductHandle(updatedRows);
    };

    const offlineQuantityNumberInput = (index, field, value) => {
        handleOfflineProductInput(index, field, value);
        offlineQuantityReplaceInput(index, value);
        offlineUploadInputChange(index, field, value);
    }

    const offlinePriceNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlinePriceReplaceInput(index, value);
    }
    const offlineFeeNumberInput = (index, field, value) => {
        offlineUploadInputChange(index, field, value);
        handleOfflineProductInput(index, field, value);
        offlineFeeReplaceInput(index, value);
    }

    const useOfflineItemsRefs = (offlineProductHandle) => {
        const offlineItemsRefs = useRef(
            offlineProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
            }));
        };
        useMemo(() => {
            offlineItemsRefs.current = initializeRefs(offlineProductHandle.length);
        }, [offlineProductHandle.length]);

        return offlineItemsRefs;
    };
    const offlineItemsRefs = useOfflineItemsRefs(offlineProductHandle);

    const scrollToEmptyFieldOffline = () => {
        // Offline 작성폼에서 Price가 1행데이터라도 0보다 크면 true
        let isPriceValid = offlineProductHandle.some(item => Number(String(item.price).replace(/,/g, '')) > 0);
        // Offline 작성폼에서 Fee가 1행데이터라도 0보다 크면 true
        let isFeeValid = offlineProductHandle.some(item => Number(String(item.fee).replace(/,/g, '')) > 0);
        let isValid = true;
        let isAllEmpty = true;

        for (let i = 0; i < offlineProductHandle.length; i++) {
            const { name, quantity, price, fee } = offlineProductHandle[i];
            if (name || quantity || price || fee) {
                isAllEmpty = false; // 하나라도 입력되어 있으면 false
                break;
            }
        }

        if (isAllEmpty) {
            return true; // 유효성검사 종료
        }

        for (let i = 0; i < offlineProductHandle.length; i++) {
            const { name, quantity, price, fee } = offlineProductHandle[i];

            if (!name || !quantity || !isPriceValid || !isFeeValid) {
                // 아코디언이 접힌 상태에서 필수 상품필드 양식이 누락되었을 경우 Error
                if (!offlineAccordion) {
                    setHoldedValidationError(true);
                    console.log('홀드누락 에러');
                } else {
                    const emptyField = offlineItemsRefs.current[i];
                    if (!name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    } else if (!isPriceValid && !price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else if (!isFeeValid && !fee) {
                        emptyField.fee.scrollIntoView({behavior: 'smooth'});
                        emptyField.fee.focus();
                    }
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addTransferProduct = () => {
        setTransferProductHandle([...transferProductHandle, {}]);
        setTransferAccordion(true);
    };

    const deleteTransferProduct = (index) => {
        setTransferProductHandle(transferProductHandle.filter((_, i) => i !== index));
        if (transferProductHandle.length === 1) {
            setTransferAccordion(false);
            setTransferServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }

    const handleTransferProductInput = (index, field, value) => {
        const updatedProducts = [...transferProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setTransferProductHandle(updatedProducts);
    };

    const copyTransferProduct = (index) => {
        const copiedProduct = {...transferProductHandle[index]};
        setTransferProductHandle([...transferProductHandle, copiedProduct]);
    };

    const transferNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleTransferPriceChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                const rawCommission = newValue * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭
                return {
                    ...item,
                    price: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    fee: (roundedCommission).toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferPriceChange(index, formattedValue);
    };

    const handleTransferFeeChange = (index, newValue) => {
        const updatedTransferProductHandle = transferProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setTransferProductHandle(updatedTransferProductHandle);
    };

    const transferFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = transferNumberWithComma(onlyNumbers);
        handleTransferFeeChange(index, formattedValue);
    };

    const transferUploadInputChange = (index, key, value) => {
        const updatedRows = [...transferProductHandle];
        updatedRows[index][key] = value;
        setTransferProductHandle(updatedRows);
    };

    const transferPriceNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferPriceReplaceInput(index, value);
    }

    const transferFeeNumberInput = (index, field, value) => {
        transferUploadInputChange(index, field, value);
        handleTransferProductInput(index, field, value);
        transferFeeReplaceInput(index, value);
    }

    const useTransferItemsRefs = (transferProductHandle) => {
        const transferItemsRefs = useRef(
            transferProductHandle.map(() => ({
                name: null,
                price: null,
                fee: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                price: null,
                fee: null,
            }));
        };
        useMemo(() => {
            transferItemsRefs.current = initializeRefs(transferProductHandle.length);
        }, [transferProductHandle.length]);

        return transferItemsRefs;
    };
    const transferItemsRefs = useTransferItemsRefs(transferProductHandle);

    const scrollToEmptyFieldTransfer = () => {
        let isValid = true;
        let isAllEmpty = true;

        for (let i = 0; i < transferProductHandle.length; i++) {
            const { name, price, fee } = transferProductHandle[i];
            if (name || price || fee) {
                isAllEmpty = false; // 하나라도 입력되어 있으면 false
                break;
            }
        }

        if (isAllEmpty) {
            return true; // 유효성검사 종료
        }

        for (let i = 0; i < transferProductHandle.length; i++) {
            const { name, price, fee } = transferProductHandle[i];
            if (!name || !price || !fee) {
                // 아코디언이 접힌 상태에서 필수 상품필드 양식이 누락되었을 경우 Error
                if (!transferAccordion) {
                    setHoldedValidationError(true);
                    console.log('홀드누락 에러');
                } else {
                    const emptyField = transferItemsRefs.current[i];
                    if (!name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else if (!fee) {
                        emptyField.fee.scrollIntoView({behavior: 'smooth'});
                        emptyField.fee.focus();
                    }
                }
                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addDomesticProduct = () => {
        setDomesticProductHandle([...domesticProductHandle, {}]);
        setDomesticAccordion(true);
    };

    const deleteDomesticProduct = (index) => {
        setDomesticProductHandle(domesticProductHandle.filter((_, i) => i !== index));
        if (domesticProductHandle.length === 1) {
            setDomesticAccordion(false);
            setDomesticServiceAgreeCheck(false);
        }
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
    }
    const handleDomesticProductInput = (index, field, value) => {
        const updatedProducts = [...domesticProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDomesticProductHandle(updatedProducts);
    };

    const copyDomesticProduct = (index) => {
        const copiedProduct = {...domesticProductHandle[index]};
        setDomesticProductHandle([...domesticProductHandle, copiedProduct]);
    };

    const domesticQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDomesticQuantityChange(index, onlyNumbers);
    };

    const handleDomesticQuantityChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticNumberWithComma = (number) => {
        if (!number) {
            return '';
        }
        return number;
    };

    const handleDomesticPriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const price = parseInt(newValue) || 0;
                const mallShipPrice = parseInt((item.mallShipPrice || "").toString().replace(/,/g, '')) || 0;
                const total = parseInt(mallShipPrice) + price;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    price: (newValue || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticMallpriceChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                const mallShipPrice = parseInt(newValue) || 0;
                const priceStr = item.price && item.price.replace(/,/g, '');
                const price = parseInt(priceStr) || 0;
                const total = price + mallShipPrice;

                const rawCommission = total * 0.07; // 7% 수수료
                const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                return {
                    ...item,
                    mallShipPrice: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    newProd: true,
                    fee: roundedCommission.toLocaleString(2)
                };
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const handleDomesticFeeChange = (index, newValue) => {
        const updatedDomesticProductHandle = domesticProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, fee: newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};
            }
            return item;
        });
        handleDepositSale('');
        handlePointSale('');
        closeUseCoupon();
        setDomesticProductHandle(updatedDomesticProductHandle);
    };

    const domesticPriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticPriceChange(index, formattedValue);
    };

    const domesticMallpriceReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticMallpriceChange(index, formattedValue);
    };

    const domesticFeeReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        const formattedValue = domesticNumberWithComma(onlyNumbers);
        handleDomesticFeeChange(index, formattedValue);
    };

    const domesticUploadInputChange = (index, key, value) => {
        const updatedRows = [...domesticProductHandle];
        updatedRows[index][key] = value;
        setDomesticProductHandle(updatedRows);
    };

    const domesticQuantityNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticQuantityReplaceInput(index, value);
    }

    const domesticPriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticPriceReplaceInput(index, value);
    }

    const domesticMallpriceNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticMallpriceReplaceInput(index, value);
    }

    const domesticFeeNumberInput = (index, field, value) => {
        domesticUploadInputChange(index, field, value);
        handleDomesticProductInput(index, field, value);
        domesticFeeReplaceInput(index, value);
    }

    const useDomesticItemsRefs = (domesticProductHandle) => {
        const domesticItemsRefs = useRef(
            domesticProductHandle.map(() => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }))
        );
        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
                price: null,
                fee: null,
                url: null,
            }));
        };
        useMemo(() => {
            domesticItemsRefs.current = initializeRefs(domesticProductHandle.length);
        }, [domesticProductHandle.length]);

        return domesticItemsRefs;
    };
    const domesticItemsRefs = useDomesticItemsRefs(domesticProductHandle);

    const scrollToEmptyFieldDomestic = () => {
        let isValid = true;
        let isAllEmpty = true;

        for (let i = 0; i < domesticProductHandle.length; i++) {
            const { name, option, quantity, price, mallShipPrice, fee, url } = domesticProductHandle[i];
            if (name || option || quantity || price || mallShipPrice || fee || url) {
                isAllEmpty = false; // 하나라도 입력되어 있으면 false
                break;
            }
        }

        if (isAllEmpty) {
            return true; // 유효성검사 종료
        }

        for (let i = 0; i < domesticProductHandle.length; i++) {
            const { name, option, quantity, price, mallShipPrice, fee, url } = domesticProductHandle[i];
            if (!name || !quantity || !price || !fee || !url) {
                // 아코디언이 접힌 상태에서 필수 상품필드 양식이 누락되었을 경우 Error
                if (!domesticAccordion) {
                    setHoldedValidationError(true);
                    console.log('홀드누락 에러');
                } else {
                    const emptyField = domesticItemsRefs.current[i];
                    if (!name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    } else if (!price) {
                        emptyField.price.scrollIntoView({behavior: 'smooth'});
                        emptyField.price.focus();
                    } else if (!fee) {
                        emptyField.fee.scrollIntoView({behavior: 'smooth'});
                        emptyField.fee.focus();
                    } else if (!url) {
                        emptyField.url.scrollIntoView({behavior: 'smooth'});
                        emptyField.url.focus();
                    }
                }

                isValid = false;
                break;
            }
        }
        return isValid;
    };

    const addDeliveryProduct = () => {
        setDeliveryProductHandle([...deliveryProductHandle, {
            domShipco: t(`dv`),
            inWaynum: "",
            name: "",
            option: "",
            quantity: ""
        }]);
        setDeliveryAccordion(true);
    };

    const deleteDeliveryProduct = (index) => {
        setDeliveryProductHandle(deliveryProductHandle.filter((_, i) => i !== index));
        if (deliveryProductHandle.length === 1) {
            setDeliveryAccordion(false);
            setDeliveryServiceAgreeCheck(false);
        }
    }

    const handleDeliveryProductInput = (index, field, value) => {
        const updatedProducts = [...deliveryProductHandle];
        updatedProducts[index] = {...updatedProducts[index], [field]: value};
        setDeliveryProductHandle(updatedProducts);
    };

    const copyDeliveryProduct = (index) => {
        const copiedProduct = {...deliveryProductHandle[index]};
        setDeliveryProductHandle([...deliveryProductHandle, copiedProduct]);
    };

    const deliveryQuantityReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryQuantityChange(index, onlyNumbers);
    };

    const handleDeliveryQuantityChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: newValue};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryWaynumReplaceInput = (index, e) => {
        const onlyNumbers = e.replace(/[^\d]/g, '');
        handleDeliveryWaynumChange(index, onlyNumbers);
    };

    const handleDeliveryWaynumChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, inWaynum: newValue};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const handleDeliveryDomesticChange = (index, newValue) => {
        const updatedDeliveryProductHandle = deliveryProductHandle.map((item, idx) => {
            if (idx === index) {
                return {...item, domShipco: newValue};
            }
            return item;
        });
        setDeliveryProductHandle(updatedDeliveryProductHandle);
    };

    const deliveryUploadInputChange = (index, key, value) => {
        const updatedRows = [...deliveryProductHandle];
        updatedRows[index][key] = value;
        setDeliveryProductHandle(updatedRows);
    };

    const deliveryQuantityNumberInput = (index, field, value) => {
        if (value === '0') return;
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryQuantityReplaceInput(index, value);
    }

    const deliveryWaynumNumberInput = (index, field, value) => {
        deliveryUploadInputChange(index, field, value);
        handleDeliveryProductInput(index, field, value);
        deliveryWaynumReplaceInput(index, value);
    }

    const deliveryDomesticSelect = (index, field, value) => {
        handleDeliveryProductInput(index, field, value);
        handleDeliveryDomesticChange(index, value);
    }

    const useDeliveryItemsRefs = (deliveryProductHandle) => {
        const deliveryItemsRefs = useRef(
            deliveryProductHandle.map(() => ({
                name: null,
                quantity: null,
            }))
        );

        const initializeRefs = (length) => {
            return Array.from({length}, () => ({
                name: null,
                quantity: null,
            }));
        };

        useMemo(() => {
            deliveryItemsRefs.current = initializeRefs(deliveryProductHandle.length);
        }, [deliveryProductHandle.length]);

        return deliveryItemsRefs;
    };

    const deliveryItemsRefs = useDeliveryItemsRefs(deliveryProductHandle);

    const scrollToEmptyFieldDelivery = () => {
        let isValid = true;
        let isAllEmpty = true;

        for (let i = 0; i < deliveryProductHandle.length; i++) {
            const { inWaynum, name, option, quantity } = deliveryProductHandle[i];
            if (inWaynum || name || option || quantity) {
                isAllEmpty = false; // 하나라도 입력되어 있으면 false
                break;
            }
        }

        if (isAllEmpty) {
            return true; // 유효성검사 종료
        }

        for (let i = 0; i < deliveryProductHandle.length; i++) {
            const { inWaynum, name, option, quantity } = deliveryProductHandle[i];
            if (!name || !quantity) {
                // 아코디언이 접힌 상태에서 필수 상품필드 양식이 누락되었을 경우 Error
                if (!deliveryAccordion) {
                    setHoldedValidationError(true);
                    console.log('홀드누락 에러');
                } else {
                    const emptyField = deliveryItemsRefs.current[i];
                    if (!name) {
                        emptyField.name.scrollIntoView({behavior: 'smooth'});
                        emptyField.name.focus();
                    } else if (!quantity) {
                        emptyField.quantity.scrollIntoView({behavior: 'smooth'});
                        emptyField.quantity.focus();
                    }
                }

                isValid = false;
                break;
            }
        }
        return isValid;
    };

    // 국내배송 셀렉트함수
    const [domesticShow, setDomesticShow] = useState(
        new Array(deliveryProductHandle.length).fill(false)
    );

    const deliveryProductHandleRef = useRef(deliveryProductHandle);
    deliveryProductHandleRef.current = deliveryProductHandle;

    const domesticRefs = useRef([]);
    const toggleDomesticShow = (index) => {
        // Toggle the corresponding show state for the specific form
        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = !updatedShowStates[index];
        setDomesticShow(updatedShowStates);
    };

    const handleDomesticChange = (index, domestic) => {
        const updatedHandles = [...deliveryProductHandle];
        updatedHandles[index].domShipco = domestic;
        setDeliveryProductHandle(updatedHandles);

        // Close the dropdown for the specific form after selecting
        const updatedShowStates = [...domesticShow];
        updatedShowStates[index] = false;
        setDomesticShow(updatedShowStates);
    };

    useEffect(() => {
        // Function to check if clicked outside of any dropdown
        const handleClickOutside = (event) => {
            domesticRefs.current.forEach((ref, index) => {
                if (ref && !ref.contains(event.target)) {
                    setDomesticShow(prevShow => {
                        const updatedShow = [...prevShow];
                        updatedShow[index] = false;
                        return updatedShow;
                    });
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [domesticRefs, domesticShow]);

    useEffect(() => {
        domesticRefs.current = domesticRefs.current.slice(0, deliveryProductHandle.length);
        deliveryProductHandle.forEach((_, index) => {
            if (!domesticRefs.current[index]) {
                domesticRefs.current[index] = createRef();
            }
        });
    }, [deliveryProductHandle]);


    const [formUploadErrSt, setFormUploadErrSt] = useState(false);
    const closeFormUploadErrSt = (() => {
        setFormUploadErrSt(false);
    })

    // 장바구니 업로드
    const readExcelCart = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((cartSheet) => {
                            const ws = workbook.Sheets[cartSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["MallId"] || !row["MallPw"] || !row["Quantity"] || !row["CartPrice"] || !row["MallURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["MallId"]),
                                        option: row["MallPw"],
                                        quantity: row["Quantity"],
                                        price: row["CartPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["MallURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setCartProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const cartExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelCart(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 구매대행 업로드
    const readExcelOrder = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((orderSheet) => {
                            const ws = workbook.Sheets[orderSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Price"] || !row["ShipPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: row["Price"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: row["ShipPrice"]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setOrderProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const orderExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOrder(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 오프라인 업로드
    const readExcelOffline = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((offlineSheet) => {
                            const ws = workbook.Sheets[offlineSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });

                            let isValid = true;
                            const rows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Quantity"] || !row["TotalPrice"]) {
                                    isValid = false;
                                    return null;
                                }

                                let feeValue = row["Fee"];
                                if (feeValue === undefined || feeValue === null || feeValue === "" || feeValue === 0) {
                                    feeValue = 0;
                                } else {
                                    feeValue = parseFloat(feeValue);
                                }

                                const price = row["TotalPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                const feeFormatted = feeValue > 0 ? feeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : feeValue;

                                return {
                                    name: String(row["ProductName"]),
                                    quantity: row["Quantity"],
                                    price: price,
                                    fee: feeFormatted,
                                    newProd: true,
                                };
                            }).filter((row) => row !== null);

                            if (!isValid) {
                                setFormUploadErrSt("missingFields");
                                return;
                            }

                            // 엑셀에서 Fee열의 값이 1 이상인 값이 무조건 한개의 행은 있어야함
                            const hasValidFee = rows.some((row) => {
                                const fee = parseFloat(row.fee);
                                return fee > 0;
                            });

                            if (!hasValidFee) {
                                setFormUploadErrSt("invalidFee");
                                return;
                            }

                            setFormUploadErrSt(false);
                            setOfflineProductHandle(rows);
                            resolve();
                        });
                    } catch (error) {
                        setFormUploadErrSt(true);
                        reject(error);
                    }
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                console.error("Error Excel:", error.message);
                setFormUploadErrSt(true);
                reject(error);
            }
        });
    };

    const offlineExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelOffline(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 이체대행 업로드
    const readExcelTransfer = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((transferSheet) => {
                            const ws = workbook.Sheets[transferSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });
                            const rows = rawData.map((row) => {

                                if (!row["TransferAccountNumber"] || !row["TransferPrice"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const transferPrice = parseFloat(row["TransferPrice"]);
                                    const fee = transferPrice * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(fee / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["TransferAccountNumber"]),
                                        price: row["TransferPrice"].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                    };
                                }
                            });
                            const isValid = rows.every(row => row !== false);
                            if (isValid) {
                                setTransferProductHandle(rows);
                            }
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const transferExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelTransfer(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 국내배송 업로드
    const readExcelDomestic = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((domesticSheet) => {
                            const ws = workbook.Sheets[domesticSheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });

                            const newRows = rawData.map((row) => {
                                if (!row["ProductName"] || !row["Quantity"] || !row["Price"] || !row["ProductURL"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    const price = parseFloat(row["Price"]);
                                    const mallShipPrice = parseFloat(row["ShipPrice"]) || 0;
                                    const fee = price + mallShipPrice;
                                    const rawCommission = fee * 0.07; // 7% 수수료
                                    const roundedCommission = Math.floor(rawCommission / 10) * 10; // 10원 단위 절삭

                                    return {
                                        name: String(row["ProductName"]),
                                        option: row["Option"],
                                        quantity: row["Quantity"],
                                        price: price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        mallShipPrice: mallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        fee: roundedCommission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        url: row["ProductURL"],
                                    };
                                }
                            });

                            // const isValid = rows.every(row => row !== false);
                            // if (isValid) {
                            //     setDomesticProductHandle(rows);
                            // }

                            const filteredRows = newRows.filter(row => row !== false);
                            setDomesticProductHandle(filteredRows);
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const domesticExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDomestic(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 배송대행 업로드
    const readExcelDelivery = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });

                        workbook.SheetNames.forEach((deliverySheet) => {
                            const ws = workbook.Sheets[deliverySheet];
                            const range = XLSX.utils.decode_range(ws["!ref"]);
                            range.s.r = 0;
                            const newRangeRef = XLSX.utils.encode_range(range);
                            const rawData = XLSX.utils.sheet_to_json(ws, { range: newRangeRef });

                            const newRows = rawData.map((row) => {
                                if (!row["PackageName"] || !row["TotalQuantity"]) {
                                    setFormUploadErrSt(true);
                                    return false;
                                } else {
                                    return {
                                        domShipco: t('dvSelect'),
                                        inWaynum: row["WaybillNumber"] || "",
                                        option: row["Option"] || "",
                                        name: String(row["PackageName"]) || "",
                                        quantity: row["TotalQuantity"] || ""
                                    };
                                }
                            });

                            const filteredRows = newRows.filter(row => row !== false);
                            setDeliveryProductHandle(filteredRows);
                        });
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    };

    const deliveryExcelUpload = (e) => {
        try {
            const file = e.target.files[0];
            readExcelDelivery(file);
        } catch (error) {
            setFormUploadErrSt(true);
        }
    };

    // 여기서부터 엑셀다운 함수
    const cartExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('cartSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('cartSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'MallId', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallPw', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'CartPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'MallURL', width: 70, style: {alignment: {horizontal: 'center'}}},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `cartForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const orderExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('orderSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('orderSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 130},
        ];
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `orderForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const offlineExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('offlineSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('offlineSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'Fee', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `offlineForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const transferExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('transferSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('transferSheet'); // 첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'TransferAccountNumber', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'TransferPrice', width: 30, style: {alignment: {horizontal: 'center'}}},
        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            //다운시 파일명 및 확장자
            anchor.download = `transferForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const domesticExcelDown = () => {

        const workbook = new excel.Workbook();
        workbook.addWorksheet('domesticSheet'); //시트 활성화
        const sheetOne = workbook.getWorksheet('domesticSheet'); //첫번째 시트 제목 정의

        //첫번째 시트의 첫번째 로우. 디자인 및 명칭 정의
        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;
        sheetOne.columns = [
            {header: 'ProductName', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 40, style: {alignment: {horizontal: 'center'}}},
            {header: 'Quantity', width: 10, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'ShipPrice', width: 20, style: {alignment: {horizontal: 'center'}}},
            {header: 'ProductURL', width: 70, style: {alignment: {horizontal: 'center'}}},

        ];

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `domesticForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const deliveryExcelDown = () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('deliverySheet');
        const sheetOne = workbook.getWorksheet('deliverySheet');

        sheetOne.getRow(1).font = {
            name: '맑은 고딕',
            bold: true,
            size: 11
        };
        sheetOne.getRow(1).alignment = {
            horizontal: 'center',
            vertical: 'middle'
        };
        sheetOne.getRow(1).height = 30;

        sheetOne.columns = [
            {header: 'WaybillNumber', width: 40, style: {alignment: {horizontal: 'center'}}, numFmt: '0'},
            {header: 'PackageName', width: 60, style: {alignment: {horizontal: 'center'}}},
            {header: 'Option', width: 60, style: {alignment: {horizontal: 'center'}}},
            {header: 'TotalQuantity', width: 20, style: {alignment: {horizontal: 'center'}}},
        ];

        for (let i = 2; i <= 1; i++) {
            sheetOne.getRow(i).getCell(1).value = '';
            sheetOne.getRow(i).getCell(1).numFmt = '0';
        }

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `deliveryForm.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const address1Length = selectedDeliveryChoiceOption === "EMS" || selectedDeliveryChoiceOption === "EMSP" ? 200 :
                            selectedDeliveryChoiceOption === "UPS" || selectedDeliveryChoiceOption === "UPS-E" ? 200 :
                                selectedDeliveryChoiceOption === "Small-Packet" ? 200 :
                                selectedDeliveryChoiceOption === "K-Packet" ? 200 :
                                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                                        selectedDeliveryChoiceOption === "FedEx" || selectedDeliveryChoiceOption === "FedEx-E" ? 35 :
                                            selectedDeliveryChoiceOption === "SEA" ? 200 :
                                                selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const address2Length = selectedDeliveryChoiceOption === "EMS" || selectedDeliveryChoiceOption === "EMSP" ? 50 :
        selectedDeliveryChoiceOption === "UPS" || selectedDeliveryChoiceOption === "UPS-E" ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" || selectedDeliveryChoiceOption === "FedEx-E" ? 35 :
                        selectedDeliveryChoiceOption === "SEA" ? 50 :
                            selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const address3Length = selectedDeliveryChoiceOption === "EMS" || selectedDeliveryChoiceOption === "EMSP"  ? 50 :
        selectedDeliveryChoiceOption === "UPS" || selectedDeliveryChoiceOption === "UPS-E"  ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" || selectedDeliveryChoiceOption === "FedEx-E"  ? 35 :
                        selectedDeliveryChoiceOption === "SEA" ? 50 :
                            selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;
    const cityLength = selectedDeliveryChoiceOption === "EMS" || selectedDeliveryChoiceOption === "EMSP"  ? 50 :
        selectedDeliveryChoiceOption === "UPS" || selectedDeliveryChoiceOption === "UPS-E"  ? 50 :
            selectedDeliveryChoiceOption === "Small-Packet" ? 50 :
                selectedDeliveryChoiceOption === "K-Packet" ? 50 :
                    selectedDeliveryChoiceOption === "DHL" ? 35 :
                        selectedDeliveryChoiceOption === "FedEx" || selectedDeliveryChoiceOption === "FedEx-E"  ? 35 :
                        selectedDeliveryChoiceOption === "SEA" ? 50 :
                            selectedDeliveryChoiceOption === "국내배송" ? 100 : 35;

    const validTextTaxid = ((e) => {
        const taxidRegex = /^[a-zA-Z0-9\s\-.,]{2,50}$/; /*2~50자*/
        if (!e.target.value || taxidRegex.test(e.target.value)) setTaxidError(false);
        else setTaxidError(true);
        setTaxid(e.target.value);
    })
    const validTextRecname = ((e) => {
        const recnameRegex = /^[a-zA-Z ]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || recnameRegex.test(e.target.value)) setRecnameError(false);
        else setRecnameError(true);
        setRecname(e.target.value);
    })
    const validTextAddr1 = ((e) => {
        const Addr1Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address1Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr1Regex.test(e.target.value)) setAddress1Error(false);
        else setAddress1Error(true);
        setAddress1(e.target.value);
    })
    const validTextAddr2 = ((e) => {
        const Addr2Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address2Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr2Regex.test(e.target.value)) setAddress2Error(false);
        else setAddress2Error(true);
        setAddress2(e.target.value);
    })
    const validTextAddr3 = ((e) => {
        const Addr3Regex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${address3Length}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || Addr3Regex.test(e.target.value)) setAddress3Error(false);
        else setAddress3Error(true);
        setAddress3(e.target.value);
    })
    const validTextCity = ((e) => {
        const cityRegex = new RegExp(`^[a-zA-Z\\d,-/\\s]{2,${cityLength}}$`); // 암호화 // 영문 또는 "-" 또는 "/" 또는 ","만 가능하고 동적 Length값 이하로 입력
        if (!e.target.value || cityRegex.test(e.target.value)) setCityError(false);
        else setCityError(true);
        setCity(e.target.value);
    })
    const validTextZipcode = ((e) => {
        const zipcodeRegex = /^[a-zA-Z\d- ]{3,10}$/; // 암호화 // 영문 또는 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || zipcodeRegex.test(e.target.value)) setZipcodeError(false);
        else setZipcodeError(true);
        setZipcode(e.target.value);
    })
    const validTextTelnum = ((e) => {
        const telnumRegex = /^[\d-]{5,15}$/; // 암호화 // 숫자 또는 "-" 로만 가능하게 10자리 이하
        if (!e.target.value || telnumRegex.test(e.target.value)) setTelnumError(false);
        else setTelnumError(true);
        setTelnum(e.target.value);
    })

    const [paypalPopupSt, setPaypalPopupSt] = useState(false);
    const paypalPopupClose = (() => {
        setIsPayBtLoading(false);
        setPaypalPopupSt(false);
    })

    const parseNumber = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    };

    // 결제수단 출력조건
    const isOrderPay = (
        cartProductHandle.some((item) => parseNumber(item.price) > 0) ||
        orderProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0) ||
        offlineProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
        transferProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.fee) > 0) ||
        domesticProductHandle.some((item) => parseNumber(item.price) > 0 || parseNumber(item.mallShipPrice) > 0 || parseNumber(item.fee) > 0)
    );

    // 이용약관 유효성검사
    const agreeValidation =
        (!cartProductHandle.some(cart => cart.name && cart.name.length > 0) || cartServiceAgreeCheck) &&
        (!orderProductHandle.some(order => order.name && order.name.length > 0) || orderServiceAgreeCheck) &&
        (!offlineProductHandle.some(offline => offline.name && offline.name.length > 0) || offlineServiceAgreeCheck) &&
        (!transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) || transferServiceAgreeCheck) &&
        (!domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) || domesticServiceAgreeCheck) &&
        (!deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) || deliveryServiceAgreeCheck);

    // 이용약관 에러메시지 출력조건
    const agreeErrorMsgCondition = cartProductHandle.some(cart => cart.name && cart.name.length > 0) ||
        orderProductHandle.some(order => order.name && order.name.length > 0) ||
        offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ||
        transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ||
        domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ||
        deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)

    const validation = (saveFl) => {
        if (selectedDeliveryChoiceOption === t('dvSelect')) {
            setDeliveryChoiceError(true);
            return false;
        }
        if (selectedCountry.value === "ID" && shipcoSelectedOption !== 'EMS' && !taxid) {
            setTaxidError(true);
            return false;
        }
        if (selectedCountry.value === "" || !selectedCountry.value) {
            setCountryError(true);
            return false;
        }
        if (!recname) {
            setRecnameError(true);
            return false;
        }
        if (!address1) {
            setAddress1Error(true);
            return false;
        }
        if (selectedCountry.value !== "KR" && !city) {
            setCityError(true);
            return false;
        }
        if (selectedCountry.value !== "KR" && !zipcode) {
            setZipcodeError(true);
            return false;
        }
        if (!telnum) {
            setTelnumError(true);
            return false;
        }

        // 상품폼별로 필수 필드들 검증
        if (!scrollToEmptyFieldCart() || !scrollToEmptyFieldOrder() || !scrollToEmptyFieldOffline() ||
            !scrollToEmptyFieldTransfer() || !scrollToEmptyFieldDomestic() || !scrollToEmptyFieldDelivery()) {
            console.log("상품폼 에러")
            return false;
        }

        // 각 폼마다 입력되었을 때 동의란에 체크하지 않았으면 Error
        if (!agreeValidation) {
            setAgreeCheckBoxError(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)인데 결제수단을 선택하지 않았을 경우 Error
        if (isOrderPay && payWay === '' && !saveFl) {
            setPayMethodError(true);
            return false;
        }

        //  || 예치금이 아닌 다른 결제수단으로 시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense) === 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setPointPayError(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 예치금결제인데 예치금을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense) === 0 && payWay === "PM05" && depositSale === '' && !saveFl) {
            setPointSelectErr(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 결제수단이 포인트결제인데 포인트나 쿠폰을 사용하지 않았을 경우
        if (isOrderPay && Number(finalPayExpense) === 0 && payWay === "PM04" && (pointSale === '' && couponSale === '') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원이고 포인트 사용금액이나 쿠폰 사용금액이 0보다 큰데 결제수단이 포인트결제나 예치금결제가 아닐 경우
        if (isOrderPay && Number(finalPayExpense) === 0 && (pointSale !== '' && depositSale !== '') && couponSale > 0 && (payWay !== 'PM04' && payWay !== 'PM05') && !saveFl) {
            setDepositSelectErr(true);
            return false;
        }

        // isOrderPay(결제수단)이 true(출력)고 최종결제금액이 0원보다 큰데 포인트 || 예치금으로 결제시도할 경우 Error
        if (isOrderPay && Number(finalPayExpense) > 0 && (payWay === "PM04" || payWay === "PM05") && !saveFl) {
            setEtcPayError(true);
            return false;
        }

        if (Number(finalPayExpense) < 0) {
            setDiscountError(true);
            return false;
        }

        return true;
    };

    const [saveFl, setSaveFl] = useState(false);
    const handleSubmit = async (btType) => {
        if (isSaveBtDisabled || isPayBtDisabled) {
            return;
        }

        let isSave = btType === 'save';
        if (isSave) {
            setSaveFl(true);
            setIsSaveBtLoading(true);
            setIsSaveBtDisabled(true);
        } else {
            setIsPayBtLoading(true);
            setIsPayBtDisabled(true);
        }

        try {
            // 모든 상품폼 필드들의 name이 비어 있지 않으면 통과 후 OrderDataSave 함수 실행
            const validations = [
                cartProductHandle,
                orderProductHandle,
                offlineProductHandle,
                transferProductHandle,
                domesticProductHandle,
                deliveryProductHandle,
            ].map(list => list.every(item => item.name !== '') && list.length > 0);

            const orderInputCheck = validations.some(Boolean);
            if (!orderInputCheck) {
                const scrollFunctions = [
                    scrollToFirstEmptyField,
                    scrollToEmptyFieldCart,
                    scrollToEmptyFieldOrder,
                    scrollToEmptyFieldOffline,
                    scrollToEmptyFieldTransfer,
                    scrollToEmptyFieldDomestic,
                    scrollToEmptyFieldDelivery,
                ];

                for (const scrollFunc of scrollFunctions) {
                    if (!scrollFunc()) {
                        if (isSave) {
                            setIsSaveBtLoading(false);
                            setIsSaveBtDisabled(false);
                        } else {
                            setIsPayBtLoading(false);
                            setIsPayBtDisabled(false);
                        }
                        return;
                    }
                }
            }

            const isValid = validation(isSave);
            if (!isValid) {
                if (isSave) {
                    setIsSaveBtLoading(false);
                    setIsSaveBtDisabled(false);
                } else {
                    setIsPayBtLoading(false);
                    setIsPayBtDisabled(false);
                }
                return;
            }


            // 결제수단이 페이팔이면서 PayNow 버튼 클릭 시
            if (payWay === 'PM01' && !isSave) {
                setPaypalPopupSt(true);
                // isSave가 true면 Save, false면 Pay 로직실행
            } else {
                // 유효성검사가 끝날 때까지 대기 후 실행
                await orderDataSave(isSave);
            }

        } catch {
            if (isSave) {
                setIsSaveBtLoading(false);
                setIsSaveBtDisabled(false);
            } else {
                setIsPayBtLoading(false);
                setIsPayBtDisabled(false);
            }
        }
    };

    const closeHoldedValidationError = () => {
        setHoldedValidationError(false);
    }

    const handleUseType = (event) => {
        setUseType(event.target.value);
    };

    const addrBookTrueFn = (() => {
        setOrderAddrBookSt(true);
    })

    const addrBookCloseFn = (() => {
        setOrderAddrBookSt(false);
    })

    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + '1',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setDeliveryChoice(response.data)
        })

    const bringToDomesticShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + '0',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setDomesticDeliveryChoice(response.data)
        })

    const handleChangeDeliveryChoice = (event) => {
        setSelectedDeliveryChoiceOption(event.target.value);
        setDeliveryChoiceError(false);
    };

    const recieverInfoAccordionFn = () => {
        setRecieverInfoAccordion(!recieverInfoAccordion);
    }

    const cartAccordionFn = () => {
        setCartAccordion(!cartAccordion)
    }

    const orderAccordionFn = () => {
        setOrderAccordion(!orderAccordion)
    }

    const offlineAccordionFn = () => {
        setOfflineAccordion(!offlineAccordion)
    }

    const transferAccordionFn = () => {
        setTransferAccordion(!transferAccordion)
    }

    const domesticAccordionFn = () => {
        setDomesticAccordion(!domesticAccordion)
    }

    const deliveryAccordionFn = () => {
        setDeliveryAccordion(!deliveryAccordion)
    }

    const invoiceAccordionFn = () => {
        setInvoiceAccordion(!invoiceAccordion)
    }

    const requestConfigAccordionFn = () => {
        setRequestConfigAccordion(!requestConfigAccordion)
    }


    const addrTypeDefault = () => {
        getDefaultAddrInfo();
    }

    const addrTypeNew = () => {
        setSelectedCountry({value: '', label: t(`ordCrLabel`)})
        setTaxid("")
        setRecname("")
        setTelnum("")
        setZipcode("")
        setAddress1("")
        setAddress2("")
        setAddress3("")
        setCity("")
        setNameInputCount(0)
        setTelnumInputCount(0)
        setZipcodeInputCount(0)
        setAddress1InputCount(0)
        setAddress2InputCount(0)
        setAddress3InputCount(0)
        setCityInputCount(0)
    }

    const defaultAddrRadioChoice = () => {
        setDefaultAddr(true);
        setNewAddr(false);
    }
    const newAddrRadioChoice = () => {
        setDefaultAddr(false);
        setNewAddr(true);
    }

    const defaultAddrRadioHandle = async () => {
        defaultAddrRadioChoice();
        await addrTypeDefault();
    }

    const newAddrRadioHandle = async () => {
        newAddrRadioChoice();
        await addrTypeNew();
    }

    const [shipcoSelectedOption, setShipcoSelectedOption] = useState(t(`ordOsDvLabel`))
    const [shipcoIsOpen, setShipcoIsOpen] = useState(false);
    const [shipcoFocusedIndex, setShipcoFocusedIndex] = useState(0);
    const shipcoDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (shipcoDropdownRef.current && !shipcoDropdownRef.current.contains(e.target)) {
                setShipcoIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const shipcoHandleSelect = (option, index) => {
        setShipcoSelectedOption(option);
        setShipcoFocusedIndex(index);
        setShipcoIsOpen(false);
        setSelectedDeliveryChoiceOption(option);
        if (option !== t(`ordOsDvLabel`)) {
            setDeliveryChoiceError(false);
        }
        if (option === "국내배송") {
            setSelectedCountry({value: 'KR', label: '대한민국(Republic of Korea)'});
        }
        // resetRecInfo();
    };

    const resetRecInfo = (() => {
        setRecname('');
        setTelnum('');
        setZipcode('');
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setCity('');
    })

    const shipcoHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setShipcoFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % deliveryChoice.length;
                setShipcoSelectedOption(deliveryChoice[newIndex]);
                setSelectedDeliveryChoiceOption(deliveryChoice[newIndex]);
                if (e !== t(`ordOsDvLabel`)) {
                    setDeliveryChoiceError(false);
                }
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setShipcoFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + deliveryChoice.length) % deliveryChoice.length;
                setShipcoSelectedOption(deliveryChoice[newIndex]);
                setSelectedDeliveryChoiceOption(deliveryChoice[newIndex]);
                if (e !== t(`ordOsDvLabel`)) {
                    setDeliveryChoiceError(false);
                }
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            shipcoHandleSelect(deliveryChoice[shipcoFocusedIndex], shipcoFocusedIndex);
            if (e !== t(`ordOsDvLabel`)) {
                setDeliveryChoiceError(false);
            }
        } else if (e.key === 'Escape') {
            if (e !== t(`ordOsDvLabel`)) {
                setDeliveryChoiceError(false);
            }
            setShipcoIsOpen(false);
        }
    };

    const shipcoHandleOnFocus = () => {
        if (shipcoFocusedIndex === -1) {
            setShipcoFocusedIndex(deliveryChoice.findIndex((option) => option.value === shipcoSelectedOption.value));
        }
    };

    // 여기서부터 국가 커스텀셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            if (selectedIndex < filteredCountryList.length - 1) {
                setSelectedIndex(selectedIndex + 1);
            }
        } else if (event.key === "ArrowUp") {
            if (selectedIndex > 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        } else if (event.key === "Enter") {
            if (selectedIndex >= 0) {
                handleCountryChange(filteredCountryList[selectedIndex], event);
            }
        }
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({ value: '', label: t(`ordCrLabel`) });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (countryRef.current) {
            const itemElements = countryRef.current.querySelectorAll('.selectChildBt');
            const selectedItem = itemElements[selectedIndex];

            if (selectedItem) {
                const listContainer = countryRef.current.querySelector('.country-select-data');
                const containerHeight = listContainer.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemHeight = selectedItem.clientHeight;

                if (itemTop < 0) {
                    listContainer.scrollTop = itemTop;
                } else if (itemTop + itemHeight > containerHeight) {
                    listContainer.scrollTop = itemTop + itemHeight - containerHeight;
                }
            }
        }
    }, [selectedIndex]);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error fetching countries:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, []);

    // 여기까지 커스텀 셀렉트

    const nameInputLengthCheck = (e) => {
        setNameInputCount(e.target.value.length);
    };

    const telnumInputLengthCheck = (e) => {
        setTelnumInputCount(e.target.value.length);
    };

    const zipcodeInputLengthCheck = (e) => {
        setZipcodeInputCount(e.target.value.length);
    };

    const address1InputLengthCheck = (e) => {
        setAddress1InputCount(e.target.value.length);
    };

    const address2InputLengthCheck = (e) => {
        setAddress2InputCount(e.target.value.length);
    };

    const address3InputLengthCheck = (e) => {
        setAddress3InputCount(e.target.value.length);
    };

    const cityInputLengthCheck = (e) => {
        setCityInputCount(e.target.value.length);
    };

    const nameInputRTCheck = (e) => {
        setNameInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const telnumInputRTCheck = (e) => {
        setTelnumInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const zipcodeInputRTCheck = (e) => {
        setZipcodeInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address1InputRTCheck = (e) => {
        setAddress1InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address2InputRTCheck = (e) => {
        setAddress2InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const address3InputRTCheck = (e) => {
        setAddress3InputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const cityInputRTCheck = (e) => {
        setCityInputCount(
            e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
        );
    };

    const nameValid = (e) => {
        validTextRecname(e)
        nameInputLengthCheck(e);
        nameInputRTCheck(e)
    }

    const getMaxLengthByTelnumCountry = () => {
        if (selectedCountry.value === "US") return 10;
        return 15;
    };

    const currentMaxLengthTelnumDisplay = `/${getMaxLengthByTelnumCountry()}`;

    const telnumValid = (e) => {
        const { value } = e.target;

        //미국 숫자만 10자리
        if (selectedCountry.value === "US") {
            if (/^\d{0,10}$/.test(value)) {
                validTextTelnum(e);
                telnumInputLengthCheck(e);
                telnumInputRTCheck(e);
            }
            return;
        }

        if (value.length <= 15) {
            validTextTelnum(e)
            telnumInputLengthCheck(e);
            telnumInputRTCheck(e)
        }
    }

    const getMaxLengthByZipcode = () => {
        if (selectedCountry.value === "US") return 5; // 미국
        if (selectedCountry.value === "CA") return 6; // 캐나다
        return 10;
    };

    const currentMaxLengthZipcodeDisplay = `/${getMaxLengthByZipcode()}`;

    const getPlaceholderByZipcode = () => {
        if (selectedCountry.value === "US") return t(`zipcodeInput`);
        return "";
    };

    const zipcodeValid = (e) => {
        const { value } = e.target;

        //미국 숫자만 5자리
        if (selectedCountry.value === "US") {
            if (/^\d{0,5}$/.test(value)) {
                validTextZipcode(e);
                zipcodeInputLengthCheck(e);
                zipcodeInputRTCheck(e);
            }
            return;
        }

        //캐나다 알파벳&숫자 6자리
        if (selectedCountry.value === "CA") {
            if (/^[a-zA-Z0-9]{0,6}$/.test(value)) {
                validTextZipcode(e);
                zipcodeInputLengthCheck(e);
                zipcodeInputRTCheck(e);
            }
            return;
        }

        if (value.length <= 10) {
            validTextZipcode(e);
            zipcodeInputLengthCheck(e);
            zipcodeInputRTCheck(e);
        }
    };

    const address1Valid = (e) => {
        validTextAddr1(e)
        address1InputLengthCheck(e);
        address1InputRTCheck(e)
    }

    const address2Valid = (e) => {
        validTextAddr2(e)
        address2InputLengthCheck(e);
        address2InputRTCheck(e)
    }

    const address3Valid = (e) => {
        validTextAddr3(e)
        address3InputLengthCheck(e);
        address3InputRTCheck(e)
    }

    const cityValid = (e) => {
        validTextCity(e)
        cityInputLengthCheck(e);
        cityInputRTCheck(e)
    }

    const [inputMallPwEncryption, setInputMallPwEncryption] = useState({});
    const toggleMallPassword = (index) => {
        setInputMallPwEncryption(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })
    const photoChangeHandler = (e) => {
        const file = e.target.files[0];
        const maxSize = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (file.size > maxSize) {
            setFileSizeOverSt(true);
            return;
        }
        setInvoiceFile(file);
        setFileName(file.name)
    };

    const orderdtData = {
        useType: useType,
        orderStatus: "OS02",
        shipco: selectedDeliveryChoiceOption,
        cartAgree: cartServiceAgreeCheck,
        orderAgree: orderServiceAgreeCheck,
        offlineAgree: offlineServiceAgreeCheck,
        transferAgree: transferServiceAgreeCheck,
        domesticAgree: domesticServiceAgreeCheck,
        shipAgree: deliveryServiceAgreeCheck,
        reviewPayFl: false
    }

    const orderRecinfo = {
        country: selectedCountry.value,
        taxid: selectedCountry.value === "ID" ? taxid : null,
        recname: recname,
        telnum: telnum,
        zipcode: zipcode,
        address1: address1,
        address2: address2,
        address3: address3,
        city: city
    }

    const beforeBackendRemoveComma = (str) => {
        return str.replace(/,/g, '');
    }

    const orderProductDataList = () => {
        const orderProductDTO = {};

        const formatPrice = (productList) => {
            return productList.map(product => {
                if (product.price) product.price = beforeBackendRemoveComma(product.price);
                if (product.mallShipPrice) product.mallShipPrice = beforeBackendRemoveComma(product.mallShipPrice);
                if (product.fee) product.fee = beforeBackendRemoveComma(product.fee);
                return product;
            });
        };

        if (cartProductHandle.some(item => item.name !== "")) {
            orderProductDTO.cartProductHandle = formatPrice(cartProductHandle);
        }
        if (orderProductHandle.some(item => item.name !== "")) {
            orderProductDTO.orderProductHandle = formatPrice(orderProductHandle);
        }
        if (offlineProductHandle.some(item => item.name !== "")) {
            orderProductDTO.offlineProductHandle = formatPrice(offlineProductHandle);
        }
        if (transferProductHandle.some(item => item.name !== "")) {
            orderProductDTO.transferProductHandle = formatPrice(transferProductHandle);
        }
        if (domesticProductHandle.some(item => item.name !== "")) {
            orderProductDTO.domesticProductHandle = formatPrice(domesticProductHandle);
        }
        if (deliveryProductHandle.some(item => item.name !== "")) {
            orderProductDTO.deliveryProductHandle = formatPrice(deliveryProductHandle);
        }

        return orderProductDTO;
    };

    const orderRequest = {
        tagRemove: tagRemove,
        shoesBoxRemove: shoesBoxRemove,
        hangerRemove: hangerRemove,
        compressionPack: compressionPack,
        prodBoxRemove: productBoxRemove,
        packingHold: packingHold,
        shipPriceDepositpay: deliveryDepositPay,
        shipPriceMessenger: deliveryMessengerGuide,
        detailReq: detailRequest,
    }

    const handlePayWayChange = (method) => {
        const initPaypal = method === "PM01";
        // 준비중(준비되면 initPaypal과 통합)
        const initDollar = method === "PM06" || method === "PM07" || method === "PM08";
        const initYuan = method === "PM02" || method === "PM03";
        if (initPaypal) {
            setCurrency("USD")
        } else if (initDollar) {
            setReadyingPopupSt(true);
            return;
        } else if (initYuan) {
            setCurrency("CNY")
        } else {
            setCurrency("")
        }
        setPayWay(method);
        setPayMethodError(false);
        setPointPayError(false);
        setDepositSelectErr(false);
        setPointSelectErr(false);
        setEtcPayError(false);
    }

    const closeReadyingPopup = (() => {
        setReadyingPopupSt(false);
    })

    const [couponSeqnum, setCouponSeqnum] = useState('')
    const [orderSaveFl, setOrderSaveFl] = useState(false);
    const [paypalPayFl, setPaypalPayFl] = useState(false);
    const [paypalPendingFl, setPaypalPendingFl] = useState(false);
    const [paypalPendingId, setPaypalPendingId] = useState("");
    const [paypalState, setPaypalState] = useState(null);

    const paypalOrderProcess = ( async (state, orderId) => {
        setPaypalPayFl(true);
        setPaypalState(state);
        if (state === "PENDING") {
            setPaypalPendingFl(true);
            setPaypalPendingId(orderId);
        }
        await paypalPopupClose();
    })

    useEffect(() => {
        if (paypalPayFl && paypalState != null) {
            if (paypalState === "COMPLETED") {
                orderDataSave(paypalState);
            } else {
                if (paypalPendingId !== "" && paypalPendingFl) {
                    orderDataSave(paypalState);
                }
            }
        }
    }, [paypalPayFl, paypalState, paypalPendingFl, paypalPendingId]);

    const orderFinalPayInfo = {
        ttProdPrice: totalPrices,
        ttMallShipPrice: totalMallShipPrice,
        etcFee: totalFee,
        ttEtcFee: totalFee,
        ttProdExpense: (totalPrices + totalMallShipPrice),
        pointUsePrice: pointSale ? pointSale : 0,
        depUsePrice: depositSale ? depositSale : 0,
        couponUseNum: couponSeqnum,
        couponUsePrice: couponSale ? couponSale : 0,
        payStatus: "PS1",
        payWay: payWay,
        payClass: "0",
        lastPrice: finalPayExpense,
        lastForPrice: lastForPrice,
        forCurrency: currency,
        orderSaveFl: orderSaveFl,
        paypalPayFl: paypalPayFl,
        paypalPendingFl: paypalPendingFl,
        paypalPendingId: paypalPendingId,
        appSalePrice: 0
    }

    // const convertedOrderFinalPrice = Object.entries(orderFinalPayInfo).reduce((acc, [key, value]) => {
    //     if (key === 'payStatus' || key === 'payClass' || key === 'payWay' || key === 'forCurrency' || key === 'paypalPendingId') {
    //         acc[key] = value;
    //     } else if (typeof value === 'number' && !Number.isInteger(value)) {
    //         acc[key] = parseFloat(value);
    //     } else {
    //         acc[key] = parseInt(value);
    //     }
    //     return acc;
    // }, {});

    const convertedOrderFinalPrice = Object.entries(orderFinalPayInfo).reduce((acc, [key, value]) => {
        if (typeof value === 'number') {
            // 소수점 값 처리: 그대로 유지
            if (!Number.isInteger(value)) {
                acc[key] = value; // 소수점을 포함한 값 유지
            } else {
                acc[key] = value; // 정수인 경우 그대로 유지
            }
        } else if (typeof value === 'string' && (key.toLowerCase().includes('price') ||
            key.toLowerCase().includes('expense') || key.toLowerCase().includes('fee'))) {
            // 금액 관련 키워드 포함된 문자열 처리
            acc[key] = parseFloat(value.replace(/,/g, '')) || 0; // 쉼표 제거 후 숫자로 변환
        } else {
            // 그 외는 원래 값 유지
            acc[key] = value;
        }
        return acc;
    }, {});


    const orderDataSave = async (data) => {
        if (data === "COMPLETED" || data === "PENDING" || data === false) {
            await sendOrderDataToServer(false);
        } else {
            setOrderSaveFl(true);
        }
    };

    useEffect(() => {
        if (orderSaveFl) {
            sendOrderDataToServer(true);
        }
    }, [orderSaveFl]);

    const sendOrderDataToServer = async (saveFl) => {
        try {
            if (saveFl) {
                convertedOrderFinalPrice.pointUsePrice = 0;
                convertedOrderFinalPrice.depUsePrice = 0;
                convertedOrderFinalPrice.couponUseNum = '';
                convertedOrderFinalPrice.couponUsePrice = 0;
                convertedOrderFinalPrice.lastPrice = totalPrices + totalMallShipPrice + totalFee + totalOverseasShippingExpense + totalEtcExpense;
            }

            const orderProductListSave = orderProductDataList();
            const formData = new FormData();
            if (invoiceFile) {
                formData.append('file', invoiceFile);
            }
            formData.append('orderdtData', JSON.stringify(orderdtData));
            formData.append('orderRecinfo', JSON.stringify(orderRecinfo));
            formData.append('orderRequest', JSON.stringify(orderRequest));
            formData.append('orderFinalPayInfo', JSON.stringify(convertedOrderFinalPrice));
            formData.append('orderProductListSave', JSON.stringify(orderProductListSave));
            formData.append('couponSeqnum', couponSeqnum);

            await axios({
                method: 'post',
                url: '/order/orderSave',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT ? LCAT : SSAT
                }
            })
                .then(async (response) => {
                    const data = response.data;
                    // 관리자메인에 오더알림업뎃
                    saveOrderAlarmData(data);
                    if ((data.payWay === "PM01" || data.payWay === "PM04" || data.payWay === "PM05") && !saveFl) {
                        navigate("/UserPayComplPg", {state: {data: data}})
                    } else if ((data.payWay === "PM02" || data.payWay === "PM03" || data.payWay === "PM00" || (data.payWay === "PM01" && paypalPendingFl)) && !saveFl) {
                        navigate("/UserPayWaitPg", {state: {data: data}})
                    } else {
                        navigate("/UserMyPg")
                    }
                })
                .catch(() => {
                    if (saveFl) {
                        setIsSaveBtLoading(false);
                        setIsSaveBtDisabled(false);
                    } else {
                        setIsPayBtLoading(false);
                        setIsPayBtDisabled(false);
                    }
                })
                .finally(() => {
                    if (saveFl) {
                        setIsSaveBtLoading(false);
                        setIsSaveBtDisabled(false);
                    } else {
                        setIsPayBtLoading(false);
                        setIsPayBtDisabled(false);
                    }
                })
        } catch {
            if (saveFl) {
                setIsSaveBtLoading(false);
                setIsSaveBtDisabled(false);
            } else {
                setIsPayBtLoading(false);
                setIsPayBtDisabled(false);
            }
        }
    };

    const saveOrderAlarmData = ((orderData) => {
        axios({
            method: 'post',
            url: '/order/saveOrderData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: orderData
        })
            .then(async () => {
                await stompClient.send("/app/sendOrderAlarm", {}, JSON.stringify({sendFl: true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    const [dollarShow, setDollarShow] = useState(false);
    const dollarRef = useRef(null);

    const toggleDollarShow = () => {
        setDollarShow(!dollarShow);
    };

    const selectChangeDollar = (e) => {
        setCurrency(e);
        setDollarShow(false);
        // handleLastForPrice(e);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dollarRef.current && !dollarRef.current.contains(event.target)) {
                setDollarShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const tagRemoveCheck = ((e) => {
        if (e.target.checked) {
            setTagRemove(true);
        } else setTagRemove(false);
    })

    const shoesBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setShoesBoxRemove(true);
        } else setShoesBoxRemove(false);
    })

    const hangerRemoveCheck = ((e) => {
        if (e.target.checked) {
            setHangerRemove(true);
        } else setHangerRemove(false);
    })
    const compressionCheck = ((e) => {
        if (e.target.checked) {
            setCompressionPack(true);
        } else setCompressionPack(false);
    })

    const prodBoxRemoveCheck = ((e) => {
        if (e.target.checked) {
            setProductBoxRemove(true);
        } else setProductBoxRemove(false);
    })

    const packingHoldCheck = ((e) => {
        if (e.target.checked) {
            setPackingHold(true);
        } else setPackingHold(false);
    })

    const deliDepositPayCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryDepositPay(true);
        } else setDeliveryDepositPay(false);
    })

    const deliMsgGuideCheck = ((e) => {
        if (e.target.checked) {
            setDeliveryMessengerGuide(true);
        } else setDeliveryMessengerGuide(false);
    })

    const detailRequestInput = ((e) => {
        setDetailRequest(e.target.value)
    })

    // 여기서부터는 결제정보 기능들
    useEffect(() => {
        addToTotalQuantity();
    })
    const addToTotalQuantity = () => {
        let totalPrices = 0;
        let totalMallShipPrice = 0;
        let totalFee = 0;
        let totalQty = 0;

        cartProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
        });
        orderProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                totalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
        });
        offlineProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
            if (product.fee) {
                totalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
        });
        transferProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.fee) {
                totalFee += (parseInt(product.fee.replace(/,/g, '')))
            }
        });
        domesticProductHandle.forEach((product) => {
            if (product.price) {
                totalPrices += (parseInt(product.price.replace(/,/g, '')));
            }
            if (product.mallShipPrice) {
                totalMallShipPrice += (parseInt(product.mallShipPrice.replace(/,/g, '')));
            }
            if (product.fee) {
                totalFee += (parseInt(product.fee.replace(/,/g, '')));
            }
            if (product.quantity) {
                totalQty += parseInt(product.quantity);
            }
        });

        setTotalPrices(totalPrices);
        setTotalMallShipPrice(totalMallShipPrice)
        setTotalFee(totalFee);
        setTotalQuantity(totalQty);
    };

    const addrChoiceData = ((addrSeqnum) => {
        axios({
            method: 'get',
            url: '/member/choiceAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: addrSeqnum
            }
        })
            .then((response) => {
                const countryData = {
                    value: response.data.countryDTO.countryCode,
                    label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                };
                setSelectedCountry(countryData)
                setTaxid(response.data.taxid)
                setRecname(response.data.recname)
                setAddress1(response.data.address1)
                setAddress2(response.data.address2)
                setAddress3(response.data.address3)
                setCity(response.data.city)
                setZipcode(response.data.zipcode)
                setTelnum(response.data.telnum)
                setNameInputCount(response.data.recname.length)
                setAddress1InputCount(response.data.address1.length)
                setAddress2InputCount(response.data.address2.length)
                setAddress3InputCount(response.data.address3.length)
                setCityInputCount(response.data.city.length)
                setZipcodeInputCount(response.data.zipcode.length)
                setTelnumInputCount(response.data.telnum.length)
                addrBookCloseFn();
            })
    })

    const getDefaultAddrInfo = (() => {
        axios({
            method: 'get',
            url: '/member/defaultAddrInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                if (response.data) {
                    const countryData = {
                        value: response.data.countryDTO.countryCode,
                        label: response.data.countryDTO.countryNm + '(' + response.data.countryDTO.countryEngNm + ')'
                    };
                    // 국내배송신청인데 국가코드가 KR이 아니면 한국으로 강제설정
                    if (domesticState && countryData.value !== 'KR') {
                        setSelectedDeliveryChoiceOption("국내배송")
                        setSelectedCountry({value: 'KR', label: '대한민국(Republic of Korea)'});
                    } else {
                        setSelectedDeliveryChoiceOption(t('dvSelect'))
                        setSelectedCountry(countryData);
                    }
                    setGetDefaultAddrList(response.data)
                    setTaxid(response.data.taxid)
                    setRecname(response.data.recname)
                    setTelnum(response.data.telnum)
                    setZipcode(response.data.zipcode)
                    setAddress1(response.data.address1)
                    setAddress2(response.data.address2)
                    setAddress3(response.data.address3)
                    setCity(response.data.city)
                    setNameInputCount(response.data.recname.length)
                    setTelnumInputCount(response.data.telnum.length)
                    setZipcodeInputCount(response.data.zipcode.length)
                    setAddress1InputCount(response.data.address1.length)
                    setAddress2InputCount(response.data.address2.length)
                    setAddress3InputCount(response.data.address3.length)
                    setCityInputCount(response.data.city.length)

                }
            })
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            if (LCAT || SSAT) {
                bringToShipCo();
                bringToDomesticShipCo();
            }
            if (defaultAddr) {
                getDefaultAddrInfo();
            }
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const [orderType, setOrderType] = useState(null);
    useEffect(() => {
        if (cartState) {
            setOrderType("cart");
            setCartAccordion(true);
            setOrderAccordion(false);
            setOfflineAccordion(false);
            setTransferAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (orderState) {
            setOrderType("order");
            setOrderAccordion(true);
            setCartAccordion(false);
            setOfflineAccordion(false);
            setTransferAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (offlineState) {
            setOrderType("offline");
            setOfflineAccordion(true);
            setOrderAccordion(false);
            setCartAccordion(false);
            setTransferAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (transferState) {
            setOrderType("transfer");
            setTransferAccordion(true);
            setOfflineAccordion(false);
            setOrderAccordion(false);
            setCartAccordion(false);
            setDomesticAccordion(false);
            setDeliveryAccordion(false);
        } else if (domesticState) {
            setOrderType("domestic");
            setDomesticAccordion(true);
            setSelectedDeliveryChoiceOption('국내배송');
            setCountry('KR');
            setSelectedCountry({value: 'KR', label: '대한민국(Republic of Korea)'});
            setTransferAccordion(false);
            setOfflineAccordion(false);
            setOrderAccordion(false);
            setCartAccordion(false);
            setDeliveryAccordion(false);
        } else if (deliveryState) {
            setOrderType("delivery");
            setDeliveryAccordion(true);
            setDomesticAccordion(false);
            setTransferAccordion(false);
            setOfflineAccordion(false);
            setOrderAccordion(false);
            setCartAccordion(false);
        }
    }, [cartState, orderState, offlineState, transferState, domesticState, deliveryState])

    useEffect(() => {
        if (orderType) {
            getDefaultAddrInfo();
        }
    }, [orderType])

    const allAgreeHandle = (e) => {
        const isChecked = e.target.checked;

        setAllAgreeCheck(isChecked);
        setAgreeCheckBoxError(false);

        if (cartProductHandle.some(cart => cart.name && cart.name.length > 0)) {
            setCartServiceAgreeCheck(isChecked);
        }
        if (orderProductHandle.some(order => order.name && order.name.length > 0)) {
            setOrderServiceAgreeCheck(isChecked);
        }
        if (offlineProductHandle.some(offline => offline.name && offline.name.length > 0)) {
            setOfflineServiceAgreeCheck(isChecked);
        }
        if (transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0)) {
            setTransferServiceAgreeCheck(isChecked);
        }
        if (domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0)) {
            setDomesticServiceAgreeCheck(isChecked);
        }
        if (deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)) {
            setDeliveryServiceAgreeCheck(isChecked);
        }
    };

    useEffect(() => {
        const visibleConditions = [
            cartProductHandle.some(cart => cart.name && cart.name.length > 0),
            orderProductHandle.some(order => order.name && order.name.length > 0),
            offlineProductHandle.some(offline => offline.name && offline.name.length > 0),
            transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0),
            domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0),
            deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0)
        ];

        const checkedConditions = [
            cartServiceAgreeCheck,
            orderServiceAgreeCheck,
            offlineServiceAgreeCheck,
            transferServiceAgreeCheck,
            domesticServiceAgreeCheck,
            deliveryServiceAgreeCheck
        ];

        const visibleCheckedCount = visibleConditions.map((condition, index) => {
            if (condition) {
                return checkedConditions[index];
            }
            return null;
        }).filter(checked => checked !== null).length;

        const checkedCount = checkedConditions.filter(checked => checked).length;

        if (visibleCheckedCount === checkedCount) {
            setAllAgreeCheck(true);
        } else {
            setAllAgreeCheck(false);
        }
    }, [cartServiceAgreeCheck, orderServiceAgreeCheck, offlineServiceAgreeCheck, transferServiceAgreeCheck, domesticServiceAgreeCheck, deliveryServiceAgreeCheck, cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle, deliveryProductHandle]);

    const handleAgreeChange = (checkboxSetter) => (e) => {
        checkboxSetter(e.target.checked);
        setAgreeCheckBoxError(false);
    };

    const [memberInfo, setMemberInfo] = useState([{}]);

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);

    const selectMemberInfo = () => axios({
        method: 'get',
        url: '/member/modiInfo/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setMemberInfo(response.data)
        })

    useEffect(() => {
        const timer = setTimeout(() => {
            selectMemberInfo();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const [useCouponSt, setUseCouponSt] = useState(false);
    const [dbCoupon, setDbCoupon] = useState('');
    const [biggerCpPriceSt, setBiggerCpPriceSt] = useState(false);
    const openBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(true);
    })
    const closeBiggerCpPriceSt = (() => {
        setBiggerCpPriceSt(false);
    })

    const showUseCoupon = (() => {
        if (Number(finalPayExpense) === 0) {
            openBiggerCpPriceSt();
        } else {
            setUseCouponSt(true);
        }
    })

    const closeUseCoupon = (() => {
        setUseCouponSt(false);
        setCouponSale('');
        if (couponSale !== '') {
            setDbCoupon(dbCoupon + 1);
            setCouponSeqnum('');
        }
    })

    const couponUseFn = (coupon) => {
        if (coupon && coupon.couponMstDTO) {
            const couponPrice = coupon.couponMstDTO.couponPrice;
            const finalExpense = Number(finalPayExpense);

            if (couponPrice > finalExpense) {
                setUseCouponSt(false);
                openBiggerCpPriceSt();
            } else {
                setCouponSale(couponPrice);
                setUseCouponSt(false);
                setDbCoupon(dbCoupon - 1);
                setCouponSeqnum(coupon.seqNum);
            }
        }
    };

    const [dbPoint, setDbPoint] = useState('');
    useEffect(() => {
        setDbPoint(memberInfo[0] && memberInfo[0].point);
        setDbDeposit(memberInfo[0] && memberInfo[0].deposit);
        const activeCouponCount = memberInfo[0] && memberInfo[0].coupons && memberInfo[0].coupons.filter(coupon => coupon && coupon.activeFl === true).length;
        setDbCoupon(activeCouponCount || 0);
    }, [memberInfo]);

    const handlePointSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        // 입력값이 최종결제금액보다 크면 최종결제금액으로 제한
        if (value > finalExpense) {
            value = finalExpense;
        }

        // 입력값이 적립금잔액보다 크면 input에 잔액(맥시멈)으로 업뎃하고 잔액은 0으로 업뎃
        if (value > memberInfo[0].point) {
            setPointSale(memberInfo[0].point);
            setDbPoint('');
            // 입력값을 지우면 잔액은 되돌리고 input값은 0으로 업뎃
        } else if (value === 0 || value === '') {
            setPointSale('');
            setDbPoint(memberInfo[0].point);
        } else {
            // 이 외에는 input에 사용자입력값, 잔액은 입력값만큼만 차감
            setPointSale(value);
            setDbPoint(memberInfo[0].point - value);
        }
    };

    const allPointSale = () => {
        let maxPoint = memberInfo[0] ? memberInfo[0].point : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(depositSale) + Number(couponSale)));
        if (maxPoint > finalExpense) {
            maxPoint = finalExpense;
        }

        setPointSale(maxPoint);
        setDbPoint(memberInfo[0].point - maxPoint);
    };

    const [dbDeposit, setDbDeposit] = useState('');

    const handleDepositSale = (e) => {
        let value = Number(e.replace(/[^0-9]/g, ''));
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));

        if (value > finalExpense) {
            value = finalExpense;
        }

        if (value > memberInfo[0].deposit) {
            setDepositSale(memberInfo[0].deposit);
            setDbDeposit('');
        } else if (value === 0 || value === '') {
            setDepositSale('');
            setDbDeposit(memberInfo[0].deposit);
        } else {
            setDepositSale(value);
            setDbDeposit(memberInfo[0].deposit - value);
        }
    };

    const allDepositSale = () => {
        let maxDeposit = memberInfo[0] ? memberInfo[0].deposit : '';
        const finalExpense = (totalPrices + dbFormTotalPrices + totalMallShipPrice + dbFormTotalMallShipPrice + totalFee + dbFormTotalFee +
            totalOverseasShippingExpense + totalEtcExpense - (Number(pointSale) + Number(couponSale)));
        if (maxDeposit > finalExpense) {
            maxDeposit = finalExpense;
        }

        setDepositSale(maxDeposit);
        setDbDeposit(memberInfo[0].deposit - maxDeposit);
    };

    // const [showPayNow, setShowPayNow] = useState(false);
    //
    // useEffect(() => {
    //     const checkIfPayNowShouldBeShown = () => {
    //         // 폼마다 금액이 1원이라도 있을 경우 PayNow버튼 비활
    //         const shouldShow = [
    //             ...cartProductHandle,
    //             ...orderProductHandle,
    //             ...offlineProductHandle,
    //             ...transferProductHandle,
    //             ...domesticProductHandle
    //         ].some(form =>
    //             parseFloat(form.price || 0) > 0 ||
    //             parseFloat(form.mallShipPrice || 0) > 0 ||
    //             parseFloat(form.fee || 0) > 0
    //         );
    //         setShowPayNow(shouldShow);
    //     };
    //
    //     checkIfPayNowShouldBeShown();
    // }, [cartProductHandle, orderProductHandle, offlineProductHandle, transferProductHandle, domesticProductHandle]);

    const [usdExrInfo, setUsdExrInfo] = useState([]);

    const selectedAllExrInfo = (() => {
        axios({
            method: 'get',
            url: '/paypal/selectedAllExrInfo/',
        })
            .then((response) => {
                setUsdExrInfo(response.data)
            })
            .catch(() => {
                console.log("환율조회실패")
            })
    })

    useEffect(() => {
        selectedAllExrInfo();
    }, [])

    const calculateLastForPrice = () => {
        if ((payWay === "PM01" || payWay === "PM02" || payWay === "PM03") && currency && usdExrInfo.length > 0) {
            const usdExInfo = usdExrInfo.find(info => info.curPrice === currency);
            const numericFinalPayExpense = parseFloat(finalPayExpense);
            // 금액(50,000) / (환율(1333.14) - 요율(9.1%)) = 41.26usd
            if (!isNaN(numericFinalPayExpense) && usdExInfo) {
                const payRateAsRatio = usdExInfo.payRate / 100;
                const newLastForPrice = numericFinalPayExpense / (usdExInfo.payExr - (usdExInfo.payExr * payRateAsRatio));
                setLastForPrice(Number(newLastForPrice.toFixed(2)));
            }
        } else {
            setLastForPrice(null);
        }
    }

    useEffect(() => {
        calculateLastForPrice();
    }, [payWay, currency, usdExrInfo, finalPayExpense]);


    const [cartTermsPopup, setCartTermsPopup] = useState(false);
    const [orderTermsPopup, setOrderTermsPopup] = useState(false);
    const [offlineTermsPopup, setOfflineTermsPopup] = useState(false);
    const [transferTermsPopup, setTransferTermsPopup] = useState(false);
    const [domesticTermsPopup, setDomesticTermsPopup] = useState(false);
    const [deliveryTermsPopup, setDeliveryTermsPopup] = useState(false);

    const closeCartTermsPopup = (() => {
        setCartTermsPopup(false);
    })
    const closeOrderTermsPopup = (() => {
        setOrderTermsPopup(false);
    })
    const closeOfflineTermsPopup = (() => {
        setOfflineTermsPopup(false);
    })
    const closeTransferTermsPopup = (() => {
        setTransferTermsPopup(false);
    })
    const closeDomesticTermsPopup = (() => {
        setDomesticTermsPopup(false);
    })
    const closeDeliveryTermsPopup = (() => {
        setDeliveryTermsPopup(false);
    })

    const headerText = location.state?.headerText || "";

    return (
        <div className="wd-100pc">

            <Helmet>
                <title>구매대행신청</title>
                <meta name="description" content="한국의 모든 쇼핑몰의 상품들을 해외에서 한 박스로 받아보세요!"/>
                <link rel="canonical" href="https://www.btorage.com/UserOrderPg"/>
            </Helmet>

            <div className="wd-100-pc display-flex flex-column">
                <div className="m-b-40">
                    <p className="font-t-title font-s-24">{t(`ordHeader`)}</p>
                </div>
                <div className="wd-100-pc ht-auto display-flex flex-row just-cont-sb">
                    {/*수취인정보*/}
                    <div className="font-t-cont wd-65pc ht-auto">
                        <div className={`accordion-header ${recieverInfoAccordion ? 'expanded' : ''}`} onClick={recieverInfoAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`modRefInfo`)}</span>
                        </div>
                        <div className={`accordion-body ${recieverInfoAccordion ? 'expanded' : ''}`}>
                            <div className="recInfoRowContainer" style={{height: "60px"}}>
                                <div className="recInfoRowTitle" style={{color: "dimgray"}}>
                                    <span style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                    {t(`modSmallMenu1`)}
                                </div>
                                <div className="recInfoRowContent" style={{paddingLeft: "30px"}}>
                                    <label>
                                        <input className="radioButton" type="radio" name="purposeDelivery"
                                               defaultChecked="gift" value="gift" onChange={handleUseType}/>
                                        <span style={{
                                            verticalAlign: "middle",
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                            color: "darkgray"
                                        }}>{t(`modCont1`)}</span>
                                    </label>
                                    <label style={{marginLeft: "15px"}}>
                                        <input className="radioButton" type="radio" name="purposeDelivery" value="goods"
                                               onChange={handleUseType}/>
                                        <span style={{
                                            verticalAlign: "middle",
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                            color: "darkgray"
                                        }}>{t(`modCont2`)}</span>
                                    </label>
                                    <label style={{marginLeft: "15px"}}>
                                        <input className="radioButton" type="radio" name="purposeDelivery"
                                               value="sample"
                                               onChange={handleUseType}/>
                                        <span style={{
                                            verticalAlign: "middle",
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                            color: "darkgray"
                                        }}>{t(`modCont3`)}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="recInfoRowContainer" style={{height: "60px", marginTop: "-10px"}}>
                                <div className="recInfoRowTitle" style={{color: "dimgray"}}>{t(`ordAbHeader`)}</div>
                                <div className="recInfoRowContent" style={{paddingLeft: "30px"}}>
                                    <label>
                                        <input className="radioButton" type="radio" name="deliveryAddr"
                                               value={defaultAddr}
                                               defaultChecked onChange={defaultAddrRadioHandle}/>
                                        <span style={{
                                            verticalAlign: "middle",
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                            color: "darkgray",
                                        }}>{t(`ordDfAddr`)}</span>
                                    </label>
                                    <label style={{marginLeft: "15px"}}>
                                        <input className="radioButton" type="radio" name="deliveryAddr" value={newAddr}
                                               onChange={newAddrRadioHandle}/>
                                        <span style={{
                                            verticalAlign: "middle",
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                            color: "darkgray"
                                        }}>{t(`ordNewAddr`)}</span>
                                    </label>
                                    <button className="bt-btn-A wd-90 ht-35 m-l-20 font-s-13" onClick={addrBookTrueFn}>{t(`ordDvAddrBook`)}</button> {/*배송주소록 버튼*/}
                                </div>
                            </div>
                            <div className="recInfoRowContainer" style={{height: "auto"}}>
                                <div className="recInfoRowTitle" style={{color: "dimgray"}}>
                                    <span style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                    {t(`modSmallMenu2`)}
                                </div>
                                <div className="recInfoRowContent p-l-30 p-t-8">
                                    <div className="ht-50 wd-300 position-relative" tabIndex="0" onKeyDown={shipcoHandleKeyDown} onFocus={shipcoHandleOnFocus}
                                        ref={(el) => {
                                            shipcoDropdownRef.current = el;
                                            selectedDeliveryChoiceOptionRef.current = el;
                                        }}
                                    >
                                        <button className="selectParentBt wd-100pc ht-100pc" onClick={() => setShipcoIsOpen(!shipcoIsOpen)}>
                                            <p className="font-s-14">{selectedDeliveryChoiceOption}</p>
                                            <span className="position-absolute ht-100pc" style={{right: "5px", top: "0", width: "12%"}}>
                                                    <i className="display-flex just-cont-center align-center ht-100pc font-s-20" style={{color: "darkgray"}}>
                                                        <BiCaretDown/>
                                                    </i>
                                                </span>
                                        </button>
                                        {shipcoIsOpen ?
                                            <div className="wd-100pc position-absolute m-t-5" style={{
                                                maxHeight: "220px",
                                                overflowY: "auto",
                                                border: "solid 1px lightgray",
                                                top: "100%",
                                                zIndex: 1,
                                            }}>
                                                {deliveryChoice.map((option, index) => (
                                                    <div className="selectChildBt display-flex justify-content-center align-center c-point font-s-14"
                                                         key={index} onClick={() => shipcoHandleSelect(option, index)} style={{padding: "10px", zIndex: 99}}>
                                                        <p className="font-s-14">{option}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {deliveryChoiceError ?
                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                            <p className="JoinErrMsg">{t(`recNoSelectOsdvErr`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {!deliveryChoiceError ?
                                        <div style={{height: "10px"}}></div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="recInfoRowContainer">
                                <div className="recInfoRowTitle p-b-4" style={{color: "dimgray"}}>
                                    <span style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                    {t(`modSmallMenu3`)}
                                </div>
                                <div className="recInfoRowContent p-l-30 p-t-8">
                                    {/*국가 커스텀 셀렉트new*/}
                                    <div ref={countryRef} className="ht-50 wd-87pc c-point position-relative" onClick={toggleCountryShow}>
                                        <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                            {selectedCountry.value ? (
                                                <div className="wd-15pc text-center align-center-c">
                                                    <div className={`m-l-6 m-t-3 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                                </div>
                                            ) : (
                                                <div className="wd-15pc text-center align-center-c p-l-5">
                                                    <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                                </div>
                                            )}
                                            <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                                   type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="nope"
                                                   value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                                   onChange={handleCountrySearch}
                                                   onKeyDown={handleKeyDown}/>
                                            <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                                <BiCaretDown />
                                            </i>
                                        </div>
                                        {countryShow && (
                                            <div className="country-select-data wd-100pc position-absolute m-t-5">
                                                <div className="country-select-data-child">
                                                    {filteredCountryList.map((country, index) => (
                                                        <div key={index}
                                                             className={`selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20 ${
                                                                 index === selectedIndex ? "arrow-selected" : ""
                                                             }`}
                                                             onClick={(event) => handleCountryChange(country, event)}>
                                                            <div
                                                                className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                            <p className="font-s-15">{country.label}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {countryError ?
                                        <div className="JoinErrorMsg m-t-10">
                                            <p className="JoinErrMsg">{t(`recCrErr`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    {!countryError ?
                                        <div style={{height: "10px"}}></div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {/*tax Id*/}
                            {selectedCountry.value === "ID" ?
                                <div className="recInfoRowContainer" style={{height: taxidError ? "auto" : "60px"}}>
                                    <div className="recInfoRowTitle" style={{paddingTop: "25px"}}>
                                        <span style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                        Tax ID
                                    </div>
                                    <div style={{
                                        paddingLeft: "30px",
                                        width: "75%",
                                        height: "auto",
                                        margin: "auto",
                                        marginTop: "5px",
                                        display: "block"
                                    }}>
                                        <div style={{display: "flex"}}>
                                            <div className="acc-input-container acc-input-icon"
                                                 style={{height: "100%", width: "87%"}}>
                                                <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                    <i style={{
                                                        fontSize: "28px",
                                                        margin: "0px 0px 5px 5px"
                                                    }}><HiReceiptTax/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="acc-input-inform" type="text" placeholder=" "
                                                           style={{width: "99%", height: "100%"}}
                                                           maxLength={50} value={taxid} onChange={validTextTaxid}
                                                           ref={taxidRef}/>
                                                    <label className="inputLabel"> Tax ID</label>
                                                </div>
                                            </div>
                                        </div>
                                        {taxidError ?
                                            <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                <p className="JoinErrMsg">{t(`recTaxIdErr`)}</p>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className="recInfoRowContainer"
                                 style={{height: recnameError && selectedCountry.value !== "KR" ? "auto" : "60px"}}>
                                <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                    style={{
                                        color: "red",
                                        fontSize: "18px",
                                        marginRight: "5px"
                                    }}>*</span>{t(`modSmallMenu4-1`)}
                                </div>
                                <div style={{
                                    paddingLeft: "30px",
                                    width: "75%",
                                    height: "auto",
                                    margin: "auto",
                                    marginTop: "5px",
                                    display: "block"
                                }}>
                                    <div style={{display: "flex"}}>
                                        <div className="acc-input-container acc-input-icon"
                                             style={{height: "100%", width: "87%"}}>
                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                <i style={{
                                                    fontSize: "28px",
                                                    margin: "0px 0px 5px 5px"
                                                }}><BsFillPersonCheckFill/></i>
                                            </div>
                                            <div className="acc-input-form">
                                                <input className="acc-input-inform" type="text" placeholder=" "
                                                       style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                       maxLength={25} value={recname} onChange={nameValid}
                                                       ref={recnameRef}/>
                                                <label className="inputLabel"> {t(`modSmallMenu4-1`)}</label>
                                            </div>
                                        </div>
                                        {selectedCountry.value !== "KR" ?
                                            <div style={{display: "flex", alignItems: "end"}}>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "end",
                                                marginLeft: "5px",
                                                color: "orangered"
                                            }}>{nameInputCount}</span>
                                                <span style={{
                                                    display: "flex",
                                                    alignItems: "end",
                                                    color: "dimgrey"
                                                }}>/25</span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {recnameError && selectedCountry.value !== "KR" ?
                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                            <p className="JoinErrMsg">{t(`recNmErr`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="recInfoRowContainer"
                                 style={{height: telnumError && selectedCountry.value !== "KR" ? "auto" : "60px"}}>
                                <div className="recInfoRowTitle" style={{color: "dimgray"}}>
                                    <span style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                    {t(`modSmallMenu5`)}
                                </div>
                                <div style={{paddingLeft: "30px", width: "75%", height: "auto", margin: "auto", marginTop: "5px", display: "block"}}>
                                    <div style={{display: "flex"}}>
                                        <div className="acc-input-container acc-input-icon" style={{height: "100%", width: "87%"}}>
                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                <i style={{fontSize: "28px", margin: "0px 0px 5px 5px"}}><MdPhoneIphone/></i>
                                            </div>
                                            <div className="acc-input-form">
                                                <input className="acc-input-inform" type="text" placeholder=" " style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                       maxLength={15} value={telnum} onChange={telnumValid} ref={telnumRef}/>
                                                <label className="inputLabel">{t(`modSmallMenu5`)}</label>
                                            </div>
                                        </div>
                                        {selectedCountry.value !== "KR" ?
                                            <div style={{display: "flex", alignItems: "end"}}>
                                            <span style={{display: "flex", alignItems: "end", marginLeft: "5px", color: "orangered"}}>{telnumInputCount}</span>
                                                <span style={{display: "flex", alignItems: "end", color: "dimgrey"}}>{currentMaxLengthTelnumDisplay}</span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {telnumError && selectedCountry.value !== "KR" ?
                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                            <p className="JoinErrMsg">{t(`recTellErr`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {selectedCountry.value !== "KR" ?
                                <div className="recInfoRowContainer" style={{height: zipcodeError ? "auto" : "60px"}}>
                                    <div className="recInfoRowTitle" style={{color: "dimgray"}}>
                                        <span style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                        {t(`modSmallMenu6`)}
                                    </div>
                                    <div style={{paddingLeft: "30px", width: "75%", height: "auto", margin: "auto", marginTop: "5px", display: "block"}}>
                                        <div style={{display: "flex"}}>
                                            <div className="acc-input-container acc-input-icon" style={{height: "100%", width: "87%"}}>
                                                <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                    <i style={{fontSize: "28px", margin: "0px 0px 5px 5px"}}><BsMailbox2/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="acc-input-inform" type="text" placeholder={''} style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                           maxLength={10} value={zipcode} onChange={zipcodeValid} ref={zipcodeRef}/>
                                                    <label className="inputLabel">{t(`modSmallMenu6`)}</label> {/*우편번호*/}
                                                </div>
                                            </div>
                                            <span style={{display: "flex", alignItems: "end", marginLeft: "5px", color: "orangered"}}>{zipcodeInputCount}</span>
                                            <span style={{display: "flex", alignItems: "end", color: "dimgrey"}}>{currentMaxLengthZipcodeDisplay}</span>
                                        </div>
                                        {zipcodeError ?
                                            <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                <p className="JoinErrMsg">{t(`recZipErr`)}</p>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className="recInfoRowContainer"
                                 style={{height: address1Error && selectedCountry.value !== "KR" ? "auto" : selectedCountry.value === "KR" ? "70px" : "60px"}}>
                                <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                    style={{color: "red", fontSize: "18px", marginRight: "5px"}}>*</span>
                                    {selectedDeliveryChoiceOption === "국내배송" ? t(`modSmallMenu14`) : t(`modSmallMenu7`)}
                                </div>
                                <div style={{
                                    paddingLeft: "30px",
                                    width: "75%",
                                    height: "auto",
                                    margin: "auto",
                                    marginTop: "5px",
                                    display: "block"
                                }}>
                                    <div style={{display: "flex"}}>
                                        <div className="acc-input-container acc-input-icon"
                                             style={{height: "100%", width: "87%"}}>
                                            <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                <i style={{
                                                    fontSize: "28px",
                                                    margin: "0px 0px 5px 5px"
                                                }}><TbAddressBook/></i>
                                            </div>
                                            <div className="acc-input-form">
                                                <input className="acc-input-inform" type="text" placeholder=" "
                                                       style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                       maxLength={address1Length} value={address1}
                                                       onChange={address1Valid}
                                                       ref={address1Ref}/>
                                                <label className="inputLabel">{t(`modSmallMenu7`)}</label>
                                            </div>
                                        </div>
                                        {selectedCountry.value !== "KR" ?
                                            <div style={{display: "flex", alignItems: "end"}}>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "end",
                                                marginLeft: "5px",
                                                color: "orangered"
                                            }}>{address1InputCount}</span>
                                                <span style={{
                                                    display: "flex",
                                                    alignItems: "end",
                                                    color: "dimgrey"
                                                }}>{`/${address1Length}`}</span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {selectedCountry.value !== "KR" && address1Error ?
                                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                            <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${address1Length}${t("recAddrErr2")}`}</p>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {selectedCountry.value !== "KR" ?
                                <div className="recInfoRowContainer" style={{height: address2Error ? "auto" : "60px"}}>
                                    <div className="recInfoRowTitle"
                                         style={{color: "dimgray"}}>{t(`modSmallMenu8`)}</div>
                                    <div style={{
                                        paddingLeft: "30px",
                                        width: "75%",
                                        height: "auto",
                                        margin: "auto",
                                        marginTop: "5px",
                                        display: "block"
                                    }}>
                                        <div style={{display: "flex"}}>
                                            <div className="acc-input-container acc-input-icon"
                                                 style={{height: "100%", width: "87%"}}>
                                                <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                    <i style={{
                                                        fontSize: "28px",
                                                        margin: "0px 0px 5px 5px"
                                                    }}><TbAddressBook/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="acc-input-inform" type="text" placeholder=" "
                                                           style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                           maxLength={address2Length} value={address2}
                                                           onChange={address2Valid}/>
                                                    <label className="inputLabel">{t(`modSmallMenu8`)}</label>
                                                </div>
                                            </div>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "end",
                                                marginLeft: "5px",
                                                color: "orangered"
                                            }}>{address2InputCount}</span><span
                                            style={{
                                                display: "flex",
                                                alignItems: "end",
                                                color: "dimgrey"
                                            }}>{`/${address2Length}`}</span>
                                        </div>

                                        {address2Error ?
                                            <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${address2Length}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                :
                                null
                            }
                            {selectedCountry.value !== "KR" ?
                                <div className="recInfoRowContainer" style={{height: address3Error ? "auto" : "60px"}}>
                                    <div className="recInfoRowTitle"
                                         style={{color: "dimgray"}}>{t(`modSmallMenu9`)}</div>
                                    <div style={{
                                        paddingLeft: "30px",
                                        width: "75%",
                                        height: "auto",
                                        margin: "auto",
                                        marginTop: "5px",
                                        display: "block"
                                    }}>
                                        <div style={{display: "flex"}}>
                                            <div className="acc-input-container acc-input-icon"
                                                 style={{height: "100%", width: "87%"}}>
                                                <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                    <i style={{
                                                        fontSize: "28px",
                                                        margin: "0px 0px 5px 5px"
                                                    }}><TbAddressBook/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="acc-input-inform" type="text" placeholder=" "
                                                           style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                           maxLength={address3Length} value={address3}
                                                           onChange={address3Valid}/>
                                                    <label className="inputLabel">{t(`modSmallMenu9`)}</label>
                                                </div>
                                            </div>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "end",
                                                marginLeft: "5px",
                                                color: "orangered"
                                            }}>{address3InputCount}</span><span
                                            style={{
                                                display: "flex",
                                                alignItems: "end",
                                                color: "dimgrey"
                                            }}>{`/${address3Length}`}</span>
                                        </div>

                                        {address3Error ?
                                            <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${address3Length}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                                :
                                null
                            }
                            {selectedCountry.value !== "KR" ?
                                <div className="recInfoRowContainer">
                                    <div className="recInfoRowTitle" style={{color: "dimgray"}}><span
                                        style={{
                                            color: "red",
                                            fontSize: "18px",
                                            marginRight: "5px"
                                        }}>*</span>{t(`modSmallMenu10`)}
                                    </div>
                                    <div style={{
                                        paddingLeft: "30px",
                                        width: "75%",
                                        height: "auto",
                                        margin: "auto",
                                        marginTop: "5px",
                                        display: "block"
                                    }}>
                                        <div style={{display: "flex"}}>
                                            <div className="acc-input-container acc-input-icon"
                                                 style={{height: "100%", width: "87%"}}>
                                                <div className="acc-input-lg-icon" style={{width: "15%"}}>
                                                    <i style={{
                                                        fontSize: "28px",
                                                        margin: "0px 0px 5px 5px"
                                                    }}><TbAddressBook/></i>
                                                </div>
                                                <div className="acc-input-form">
                                                    <input className="acc-input-inform" type="text" placeholder=" "
                                                           style={{width: "98%", height: "100%", paddingLeft: "10px"}}
                                                           maxLength={cityLength} value={city} onChange={cityValid}
                                                           ref={cityRef}/>
                                                    <label className="inputLabel">{t(`modSmallMenu10`)}</label>
                                                </div>
                                            </div>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "end",
                                                marginLeft: "5px",
                                                color: "orangered"
                                            }}>{cityInputCount}</span><span
                                            style={{
                                                display: "flex",
                                                alignItems: "end",
                                                color: "dimgrey"
                                            }}>{`/${cityLength}`}</span>
                                        </div>

                                        {cityError ?
                                            <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                                                <p className="JoinErrMsg">{`${t("recAddrErr1")} 2~${cityLength}${t("recAddrErr2")}`}</p>
                                            </div>
                                            :
                                            <div style={{minHeight: "20px"}}></div>
                                        }

                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div>
                            <h5 className="font-t-title" style={{marginTop: "100px"}}>{t(`modHeader1`)}</h5>
                        </div>
                        <div className={`accordion-header ${cartAccordion && cartProductHandle.length > 0 ? 'expanded' : ''}`}
                            style={{position: "relative", overflow: "auto", display: "flex"}}
                            onClick={cartAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`cartTitle`)}</span>
                        </div>
                        <div className={`accordion-body ${cartAccordion && cartProductHandle.length > 0 ? 'expanded' : ''}`}>
                            {cartProductHandle.map((cart, index) => (
                                <div key={index}>
                                    <div className="display-flex flex-row just-cont-sb border-bottom-bt ht-60">
                                        <div className="wd-30pc display-flex align-center font-s-18 font-c-default p-l-16">{index + 1}</div>
                                        <div className="wd-70pc display-flex flex-row just-cont-fend align-center p-r-10">
                                            <div>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={addCartProduct}>{t(`modAddBt1`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={() => copyCartProduct(index)}>{t(`modAddBt2`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default"
                                                        onClick={() => deleteCartProduct(index)}>{t(`modAddBt3`)}</button>
                                            </div>
                                            <div>
                                                <input style={{display: "none"}} type="file" name="file" accept=".xlsx" ref={excelFileSelectRefCart} onChange={cartExcelUpload}/>
                                                <button className="OrderPgBt3 m-r-8 wd-100 ht-35 font-c-default" onClick={() => excelFileSelectRefCart.current.click()}>{t(`modAddBt4`)}</button>
                                                <button className="OrderPgBt3 wd-100 ht-35 font-c-default" onClick={cartExcelDown}>{t(`modAddBt5`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`mallId`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={cart.name || ''}
                                                       onChange={(e) => handleCartProductInput(index, 'name', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!cartItemsRefs.current[index]) {
                                                        cartItemsRefs.current[index] = {};
                                                    }
                                                    cartItemsRefs.current[index].name = ref;
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`mallPw`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                position: "relative"
                                            }}>
                                                <input type={inputMallPwEncryption[index] ? "text" : "password"}
                                                       className="orderFormInput" value={cart.option || ''}
                                                       onChange={(e) => handleCartProductInput(index, 'option', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px",
                                                           overflow: "auto",
                                                       }} ref={(ref) => {
                                                    if (!cartItemsRefs.current[index]) {
                                                        cartItemsRefs.current[index] = {};
                                                    }
                                                    cartItemsRefs.current[index].option = ref;
                                                }}/>
                                                {
                                                    inputMallPwEncryption[index] ?
                                                        <i style={{
                                                            fontSize: "25px",
                                                            position: "absolute",
                                                            top: "10px",
                                                            right: "15px",
                                                            margin: "auto",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginTop: "4px",
                                                            opacity: "0.6"
                                                        }}
                                                           onClick={() => toggleMallPassword(index)}><AiOutlineEye/></i>
                                                        :
                                                        !inputMallPwEncryption[index] ?
                                                            <i style={{
                                                                fontSize: "25px",
                                                                position: "absolute",
                                                                top: "10px",
                                                                right: "15px",
                                                                margin: "auto",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginTop: "4px",
                                                                opacity: "0.6"
                                                            }}
                                                               onClick={() => toggleMallPassword(index)}><AiOutlineEyeInvisible/></i>
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`cartQy`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={cart.quantity || ''}
                                                       onChange={(e) => cartQuantityNumberInput(index, 'quantity', e.target.value)}
                                                       maxLength={5}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!cartItemsRefs.current[index]) {
                                                        cartItemsRefs.current[index] = {};
                                                    }
                                                    cartItemsRefs.current[index].quantity = ref;
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            {/*<div style={{height:"50px", display:"flex"}}>*/}
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`cartPr`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(cart.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => cartPriceNumberInput(index, 'price', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!cartItemsRefs.current[index]) {
                                                        cartItemsRefs.current[index] = {};
                                                    }
                                                    cartItemsRefs.current[index].price = ref;
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`cartUrl`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={cart.url || ''}
                                                       onChange={(e) => handleCartProductInput(index, 'url', e.target.value)}
                                                       maxLength={2083}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!cartItemsRefs.current[index]) {
                                                        cartItemsRefs.current[index] = {};
                                                    }
                                                    cartItemsRefs.current[index].url = ref;
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            !cartProductHandle[0] ?
                                <div className="user-center" style={{marginTop: "20px"}}>
                                    <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                            onClick={addCartProduct}>{t(`modAddBt1`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div>
                            <h5 className="font-t-title" style={{marginTop: "100px"}}>{t(`modHeader2`)}</h5>
                        </div>
                        <div className={`accordion-header ${orderAccordion && orderProductHandle.length > 0 ? 'expanded' : ''}`}
                            style={{position: "relative", overflow: "auto", display: "flex"}}
                            onClick={orderAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`orderTitle`)}</span>
                        </div>
                        <div className={`accordion-body ${orderAccordion && orderProductHandle.length > 0 ? 'expanded' : ''}`}>
                            {orderProductHandle.map((order, index) => (
                                <div key={index}>
                                    <div className="display-flex flex-row just-cont-sb border-bottom-bt ht-60">
                                        <div className="wd-30pc display-flex align-center font-s-18 font-c-default p-l-16">{index + 1}</div>
                                        <div className="wd-70pc display-flex flex-row just-cont-fend align-center p-r-10">
                                            <div>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8" style={{}}
                                                        onClick={addOrderProduct}>{t(`modAddBt1`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={() => copyOrderProduct(index)}>{t(`modAddBt2`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default"
                                                        onClick={() => deleteOrderProduct(index)}>{t(`modAddBt3`)}</button>
                                            </div>
                                            <div>
                                                <input style={{display: "none"}} type="file" name="file" accept=".xlsx" ref={excelFileSelectRefOrder} onChange={orderExcelUpload}/>
                                                <button className="OrderPgBt3 m-r-8 wd-100 ht-35 font-c-default" onClick={() => excelFileSelectRefOrder.current.click()}>{t(`modAddBt4`)}</button>
                                                <button className="OrderPgBt3 wd-100 ht-35 font-c-default" onClick={orderExcelDown}>{t(`modOrderAccd`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodNm`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={order.name || ''}
                                                       onChange={(e) => handleOrderProductInput(index, 'name', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (orderItemsRefs.current[index]) {
                                                        orderItemsRefs.current[index].name = ref;
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodOp`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={order.option || ''}
                                                       onChange={(e) => handleOrderProductInput(index, 'option', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px",
                                                           overflow: "auto"
                                                       }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodQy`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={order.quantity || ''}
                                                       onChange={(e) => orderQuantityNumberInput(index, 'quantity', e.target.value)}
                                                       maxLength={5}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (orderItemsRefs.current[index]) {
                                                        orderItemsRefs.current[index].quantity = ref;
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodPr`)}
                                            </div>
                                            {/*<div style={{padding:"0 10px 0 10px",margin:"auto", display:"block", alignItems:"center", width:"85%", height:"auto"}}>*/}
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(order.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => orderPriceNumberInput(index, 'price', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (orderItemsRefs.current[index]) {
                                                        orderItemsRefs.current[index].price = ref;
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodMallPr`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(order.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => orderMallpricericeNumberInput(index, 'mallShipPrice', e.target.value)}
                                                       maxLength={6}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }}/></div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodUrl`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={order.url || ''}
                                                       onChange={(e) => handleOrderProductInput(index, 'url', e.target.value)}
                                                       maxLength={2083}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (orderItemsRefs.current[index]) {
                                                        orderItemsRefs.current[index].url = ref;
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            !orderProductHandle[0] ?
                                <div className="user-center" style={{marginTop: "20px"}}>
                                    <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                            onClick={addOrderProduct}>{t(`modAddBt1`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div>
                            <h5 className="font-t-title" style={{marginTop: "100px"}}>{t(`modHeader3`)}</h5>
                        </div>
                        <div className={`accordion-header ${offlineAccordion && offlineProductHandle.length > 0 ? 'expanded' : ''}`}
                            style={{position: "relative", overflow: "auto", display: "flex"}}
                            onClick={offlineAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`offlineTitle`)}</span>
                        </div>
                        <div className={`accordion-body ${offlineAccordion && offlineProductHandle.length > 0 ? 'expanded' : ''}`}>
                            {/*여기서부터 순수 오프라인 구매대행 항목 자체*/}
                            {offlineProductHandle.map((offline, index) => (
                                <div key={index}>
                                    <div className="display-flex flex-row just-cont-sb border-bottom-bt ht-60">
                                        <div className="wd-30pc display-flex align-center font-s-18 font-c-default p-l-16">{index + 1}</div>
                                        <div className="wd-70pc display-flex flex-row just-cont-fend align-center p-r-10">
                                            <div>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8" style={{}}
                                                        onClick={addOfflineProduct}>{t(`modAddBt1`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={() => copyOfflineProduct(index)}>{t(`modAddBt2`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default"
                                                        onClick={() => deleteOfflineProduct(index)}>{t(`modAddBt3`)}</button>
                                            </div>
                                            <div>
                                                <input style={{display: "none"}} type="file" name="file" accept=".xlsx" ref={excelFileSelectRefOffline} onChange={offlineExcelUpload}/>
                                                <button className="OrderPgBt3 m-r-8 wd-100 ht-35 font-c-default" onClick={() => excelFileSelectRefOffline.current.click()}>{t(`modAddBt4`)}</button>
                                                <button className="OrderPgBt3 wd-100 ht-35 font-c-default" onClick={offlineExcelDown}>{t(`modOffAccd`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodNm`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={offline.name || ''}
                                                       onChange={(e) => handleOfflineProductInput(index, 'name', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!offlineItemsRefs.current[index]) {
                                                        offlineItemsRefs.current[index] = {};
                                                    }
                                                    offlineItemsRefs.current[index].name = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodQy`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={offline.quantity || ''}
                                                       onChange={(e) => offlineQuantityNumberInput(index, 'quantity', e.target.value)}
                                                       maxLength={5}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!offlineItemsRefs.current[index]) {
                                                        offlineItemsRefs.current[index] = {};
                                                    }
                                                    offlineItemsRefs.current[index].quantity = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodPr`)}
                                            </div>
                                            {/*<div style={{padding:"0 10px 0 10px",margin:"auto", display:"block", alignItems:"center", width:"85%", height:"auto"}}>*/}
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(offline.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => offlinePriceNumberInput(index, 'price', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!offlineItemsRefs.current[index]) {
                                                        offlineItemsRefs.current[index] = {};
                                                    }
                                                    offlineItemsRefs.current[index].price = ref;
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodFee`)}
                                            </div>
                                            {/*<div style={{padding:"0 10px 0 10px",margin:"auto", display:"block", alignItems:"center", width:"85%", height:"auto"}}>*/}
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(offline.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => offlineFeeNumberInput(index, 'fee', e.target.value)}
                                                       maxLength={6}
                                                       placeholder={t(`modOffFeeTx`)} ref={(ref) => {
                                                    if (!offlineItemsRefs.current[index]) {
                                                        offlineItemsRefs.current[index] = {};
                                                    }
                                                    offlineItemsRefs.current[index].fee = ref;
                                                }}/>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            !offlineProductHandle[0] ?
                                <div className="user-center" style={{marginTop: "20px"}}>
                                    <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                            onClick={addOfflineProduct}>{t(`modAddBt1`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div>
                            <h5 className="font-t-title" style={{marginTop: "100px"}}>{t(`modHeader4`)}</h5>
                        </div>
                        {/*이체대행 폼*/}
                        <div
                            className={`accordion-header ${transferAccordion && transferProductHandle.length > 0 ? 'expanded' : ''}`}
                            style={{position: "relative", overflow: "auto", display: "flex"}}
                            onClick={transferAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`transferTitle`)}</span>
                        </div>
                        <div
                            className={`accordion-body ${transferAccordion && transferProductHandle.length > 0 ? 'expanded' : ''}`}>
                            {/*여기서부터 순수 이체대행 항목 자체*/}
                            {transferProductHandle.map((transfer, index) => (
                                <div key={index}>
                                    <div className="display-flex flex-row just-cont-sb border-bottom-bt ht-60">
                                        <div className="wd-30pc display-flex align-center font-s-18 font-c-default p-l-16">{index + 1}</div>
                                        <div className="wd-70pc display-flex flex-row just-cont-fend align-center p-r-10">
                                            <div>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8" style={{}}
                                                        onClick={addTransferProduct}>{t(`modAddBt1`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={() => copyTransferProduct(index)}>{t(`modAddBt2`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default"
                                                        onClick={() => deleteTransferProduct(index)}>{t(`modAddBt3`)}</button>
                                            </div>
                                            <div>
                                                <input style={{display: "none"}} type="file" name="file" accept=".xlsx" ref={excelFileSelectRefTransfer} onChange={transferExcelUpload}/>
                                                <button className="OrderPgBt3 m-r-8 wd-100 ht-35 font-c-default" onClick={() => excelFileSelectRefTransfer.current.click()}>{t(`modAddBt4`)}</button>
                                                <button className="OrderPgBt3 wd-100 ht-35 font-c-default" onClick={transferExcelDown}>{t(`modTrAccd`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodAcct`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={transfer.name || ''}
                                                       onChange={(e) => handleTransferProductInput(index, 'name', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!transferItemsRefs.current[index]) {
                                                        transferItemsRefs.current[index] = {};
                                                    }
                                                    transferItemsRefs.current[index].name = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodTransPr`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(transfer.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => transferPriceNumberInput(index, 'price', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (transferItemsRefs.current[index]) {
                                                        transferItemsRefs.current[index].price = ref;
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodFee`)}(7%)
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(transfer.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => transferFeeNumberInput(index, 'fee', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!transferItemsRefs.current[index]) {
                                                        transferItemsRefs.current[index] = {};
                                                    }
                                                    transferItemsRefs.current[index].fee = ref;
                                                }}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                        {
                            !transferProductHandle[0] ?
                                <div className="user-center" style={{marginTop: "20px"}}>
                                    <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                            onClick={addTransferProduct}>{t(`modAddBt1`)}
                                    </button>
                                </div>
                                :
                                null
                        }

                        <div>
                            <h5 className="font-t-title" style={{marginTop: "100px"}}>{t(`modHeader5`)}</h5>
                        </div>
                        {/*국내배송(구매대행) 폼*/}
                        <div
                            className={`accordion-header ${domesticAccordion && domesticProductHandle.length > 0 ? 'expanded' : ''}`}
                            style={{position: "relative", overflow: "auto", display: "flex"}}
                            onClick={domesticAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`domesticTitle`)}</span>
                        </div>
                        <div
                            className={`accordion-body ${domesticAccordion && domesticProductHandle.length > 0 ? 'expanded' : ''}`}>
                            {/*여기서부터 순수 국내배송 구매대행 항목 자체*/}
                            {domesticProductHandle.map((domestic, index) => (
                                <div key={index}>
                                    <div className="display-flex flex-row just-cont-sb border-bottom-bt ht-60">
                                        <div className="wd-30pc display-flex align-center font-s-18 font-c-default p-l-16">{index + 1}</div>
                                        <div className="wd-70pc display-flex flex-row just-cont-fend align-center p-r-10">
                                            <div>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8" style={{}}
                                                        onClick={addDomesticProduct}>{t(`modAddBt1`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={() => copyDomesticProduct(index)}>{t(`modAddBt2`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default"
                                                        onClick={() => deleteDomesticProduct(index)}>{t(`modAddBt3`)}</button>
                                            </div>
                                            <div>
                                                <input style={{display: "none"}} type="file" name="file" accept=".xlsx" ref={excelFileSelectRefDomestic} onChange={domesticExcelUpload}/>
                                                <button className="OrderPgBt3 m-r-8 wd-100 ht-35 font-c-default" onClick={() => excelFileSelectRefDomestic.current.click()}>{t(`modAddBt4`)}</button>
                                                <button className="OrderPgBt3 wd-100 ht-35 font-c-default" onClick={domesticExcelDown}>{t(`modDmAccd`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodNm`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={domestic.name || ''}
                                                       onChange={(e) => handleDomesticProductInput(index, 'name', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!domesticItemsRefs.current[index]) {
                                                        domesticItemsRefs.current[index] = {};
                                                    }
                                                    domesticItemsRefs.current[index].name = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodOp`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={domestic.option || ''}
                                                       onChange={(e) => handleDomesticProductInput(index, 'option', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px",
                                                           overflow: "auto"
                                                       }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodQy`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={domestic.quantity || ''}
                                                       onChange={(e) => domesticQuantityNumberInput(index, 'quantity', e.target.value)}
                                                       maxLength={5}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!domesticItemsRefs.current[index]) {
                                                        domesticItemsRefs.current[index] = {};
                                                    }
                                                    domesticItemsRefs.current[index].quantity = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodPr`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(domestic.price)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => domesticPriceNumberInput(index, 'price', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!domesticItemsRefs.current[index]) {
                                                        domesticItemsRefs.current[index] = {};
                                                    }
                                                    domesticItemsRefs.current[index].price = ref;
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodMallPr`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(domestic.mallShipPrice)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => domesticMallpriceNumberInput(index, 'mallShipPrice', e.target.value)}
                                                       maxLength={6}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }}/></div>
                                        </div>
                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodFee`)}(7%)
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%",
                                                height: "auto"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={(domestic.fee)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}
                                                       onChange={(e) => domesticFeeNumberInput(index, 'fee', e.target.value)}
                                                       maxLength={12}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!domesticItemsRefs.current[index]) {
                                                        domesticItemsRefs.current[index] = {};
                                                    }
                                                    domesticItemsRefs.current[index].fee = ref;
                                                }}/>
                                            </div>
                                        </div>

                                        <div style={{height: "50px", display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodUrl`)}
                                            </div>
                                            <div style={{
                                                padding: "0 10px 0 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput" value={domestic.url || ''}
                                                       onChange={(e) => handleDomesticProductInput(index, 'url', e.target.value)}
                                                       maxLength={2083}
                                                       style={{
                                                           width: "100%",
                                                           height: "35px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!domesticItemsRefs.current[index]) {
                                                        domesticItemsRefs.current[index] = {};
                                                    }
                                                    domesticItemsRefs.current[index].url = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            !domesticProductHandle[0] ?
                                <div className="user-center" style={{marginTop: "20px"}}>
                                    <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                            onClick={addDomesticProduct}>{t(`modAddBt1`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div>
                            <h5 className="font-t-title" style={{marginTop: "100px"}}>{t(`modHeader6`)}</h5>
                        </div>
                        {/*배송대행 폼*/}
                        <div className={`accordion-header ${deliveryAccordion && deliveryProductHandle.length > 0 ? 'expanded' : ''}`}
                            style={{position: "relative", overflow: "auto", display: "flex"}} onClick={deliveryAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`deliveryTitle`)}</span>
                        </div>
                        <div className={`accordion-body ${deliveryAccordion && deliveryProductHandle.length > 0 ? 'expanded' : ''}`}>
                            {/*여기서부터 순수 배송대행 항목 자체*/}
                            {deliveryProductHandle.map((delivery, index) => (
                                <div key={index}>
                                    <div className="display-flex flex-row just-cont-sb border-bottom-bt ht-60">
                                        <div className="wd-30pc display-flex align-center font-s-18 font-c-default p-l-16">{index + 1}</div>
                                        <div className="wd-70pc display-flex flex-row just-cont-fend align-center p-r-10">
                                            <div>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8" style={{}}
                                                        onClick={addDeliveryProduct}>{t(`modAddBt1`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default m-r-8"
                                                        onClick={() => copyDeliveryProduct(index)}>{t(`modAddBt2`)}</button>
                                                <button className="OrderPgBt3 m-r-8 wd-70 ht-35 font-c-default"
                                                        onClick={() => deleteDeliveryProduct(index)}>{t(`modAddBt3`)}</button>
                                            </div>
                                            <div>
                                                <input style={{display: "none"}} type="file" name="file" accept=".xlsx" ref={excelFileSelectRefDelivery} onChange={deliveryExcelUpload}/>
                                                <button className="OrderPgBt3 m-r-8 wd-100 ht-35 font-c-default" onClick={() => excelFileSelectRefDelivery.current.click()}>{t(`modAddBt4`)}</button>
                                                <button className="OrderPgBt3 wd-100 ht-35 font-c-default" onClick={deliveryExcelDown}>{t(`modDvAccd`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodTrNum`)}
                                            </div>
                                            <div style={{
                                                padding: "15px 10px 10px 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <div style={{
                                                    width: "35%",
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}>
                                                    {/*국내배송 셀렉트*/}
                                                    <div style={{width: "100%", height: "100%"}}>
                                                        <div ref={(el) => domesticRefs.current[index] = el} style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            position: "relative"
                                                        }}>
                                                            <button className="selectParentBt"
                                                                    style={{width: "100%", height: "100%"}}
                                                                    onClick={() => toggleDomesticShow(index)}>
                                                                <p>{delivery.domShipco === '' ? t(`dv`) : delivery.domShipco}</p>
                                                                <span style={{
                                                                    position: "absolute",
                                                                    left: "80%",
                                                                    top: "0",
                                                                    height: "100%",
                                                                    width: "20%"
                                                                }}>
                                                                <i style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%",
                                                                    opacity: "0.6"
                                                                }}><BiCaretDown/></i>
                                                            </span>
                                                            </button>
                                                            {domesticShow[index] ?
                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: "55px",
                                                                    width: "100%",
                                                                    border: "solid 1px lightgray",
                                                                    zIndex: 2,
                                                                }}>
                                                                    {domesticDeliveryChoice.map((domestic, i) => (
                                                                        <div className="selectChildBt" key={i}
                                                                             value={domestic}
                                                                             onClick={() => handleDomesticChange(index, domestic)}
                                                                             style={{
                                                                                 display: "flex",
                                                                                 padding: "10px",
                                                                                 justifyContent: "center",
                                                                                 alignItems: "center",
                                                                                 cursor: "pointer",
                                                                                 fontSize: "14px"
                                                                             }}>{domestic}</div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="text" className="orderFormInput"
                                                       value={delivery.inWaynum || ''}
                                                       onChange={(e) => deliveryWaynumNumberInput(index, 'inWaynum', e.target.value)}
                                                       maxLength="25"
                                                       style={{
                                                           width: "65%",
                                                           height: "50px",
                                                           borderRadius: "4px",
                                                           padding: "0 6px 0 6px",
                                                           overflow: "auto",
                                                           marginLeft: "5px"
                                                       }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodNm`)}
                                            </div>
                                            <div style={{
                                                padding: "5px 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={delivery.name || ''}
                                                       onChange={(e) => handleDeliveryProductInput(index, 'name', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "40px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!deliveryItemsRefs.current[index]) {
                                                        deliveryItemsRefs.current[index] = {};
                                                    }
                                                    deliveryItemsRefs.current[index].name = ref;
                                                }}
                                                />
                                            </div>
                                        </div>
                                        {/*배송대행옵션*/}
                                        <div style={{display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodOp`)}
                                            </div>
                                            <div style={{
                                                padding: "5px 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={delivery.option || ''}
                                                       onChange={(e) => handleDeliveryProductInput(index, 'option', e.target.value)}
                                                       maxLength={200}
                                                       style={{
                                                           width: "100%",
                                                           height: "40px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: "flex"}}>
                                            <div style={{
                                                color: "dimgrey",
                                                width: "25%",
                                                backgroundColor: "#FAFAFA",
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: "20px",
                                                fontSize: "15px"
                                            }}>{t(`prodTtQy`)}
                                            </div>
                                            <div style={{
                                                padding: "5px 10px 10px 10px",
                                                margin: "0",
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85%"
                                            }}>
                                                <input type="text" className="orderFormInput"
                                                       value={delivery.quantity || ''}
                                                       onChange={(e) => deliveryQuantityNumberInput(index, 'quantity', e.target.value)}
                                                       maxLength={5}
                                                       style={{
                                                           width: "100%",
                                                           height: "40px",
                                                           borderRadius: "4px",
                                                           borderWidth: "thin 1px",
                                                           padding: "0 6px 0 6px"
                                                       }} ref={(ref) => {
                                                    if (!deliveryItemsRefs.current[index]) {
                                                        deliveryItemsRefs.current[index] = {};
                                                    }
                                                    deliveryItemsRefs.current[index].quantity = ref;
                                                        }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            !deliveryProductHandle[0] ?
                                <div className="user-center" style={{marginTop: "20px"}}>
                                    <button className="OrderPgBt2" style={{width: "80px", height: "40px"}}
                                            onClick={addDeliveryProduct}>{t(`modAddBt1`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div><h5 className="font-t-title" style={{marginTop: "80px"}}>{t(`modHeader7`)}</h5></div>
                        <div className={`accordion-header ${invoiceAccordion ? 'expanded' : ''}`} onClick={invoiceAccordionFn}>
                            <i className="accordion-icon"></i>
                            <p>{t(`modIvAccd`)}</p>
                        </div>
                        <div className={`accordion-body ${invoiceAccordion ? 'expanded' : ''}`}>
                            <div className="ht-80 display-flex">
                                <div className="display-flex align-center p-l-20 wd-25pc font-s-15" style={{backgroundColor: "#FAFAFA"}}>{t(`modSmallMenu11`)}</div>
                                <div className="fileUploadFrame m-l-20 display-flex align-center ht-80">
                                    <input className="fileUploadName font-s-13 p-l-10 ht-50pc border-rd3" value={fileName} style={{border: "solid 1px #EAEAEA"}}
                                           onClick={() => document.getElementById('deliveryInvoice').click()} readOnly/>
                                    <label className="just-cont-center align-center wd-80 font-t-cont font-s-14 ht-50pc border-rd4" htmlFor="deliveryInvoice"
                                           style={{display: "flex", border: "solid 1px #EAEAEA"}}>
                                        {t(`modFileBt`)}
                                    </label>
                                    <input type="file" id="deliveryInvoice" onChange={photoChangeHandler}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 className="font-t-title" style={{marginTop: "120px"}}>{t(`modHeader8`)}</h5>
                        </div>
                        <div className={`accordion-header ${requestConfigAccordion ? 'expanded' : ''}`}
                             onClick={requestConfigAccordionFn}>
                            <i className="accordion-icon"></i>
                            <span>{t(`modReqAccd`)}</span>
                        </div>
                        <div className={`accordion-body ${requestConfigAccordion ? 'expanded' : ''}`}>
                            <div style={{height: "auto", display: "flex", color: "dimgrey"}}>
                                <div style={{
                                    color: "dimgrey",
                                    width: "25%",
                                    backgroundColor: "#FAFAFA",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                    fontSize: "15px"
                                }}>{t(`modSmallMenu12`)}
                                </div>
                                <div style={{width: "85%", marginLeft: "20px"}}>
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        marginTop: "10px"
                                    }}>
                                        <input id="tagRemove" type="checkbox" checked={tagRemove}
                                               onChange={tagRemoveCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="tagRemove" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont1`)}</p>
                                        </label>
                                    </div>
                                    {tagRemove ?
                                        <div className="JoinErrorMsg">
                                            <p className="JoinErrMsg">{t(`modReqWarn1`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="shoesBoxRemove" type="checkbox" checked={shoesBoxRemove}
                                               onChange={shoesBoxRemoveCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="shoesBoxRemove" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont2`)}</p>
                                        </label>
                                    </div>
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="hangerRemove" type="checkbox" checked={hangerRemove}
                                               onChange={hangerRemoveCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="hangerRemove" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont3`)}</p>
                                        </label>
                                    </div>
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="compressionPack" type="checkbox" checked={compressionPack}
                                               onChange={compressionCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="compressionPack" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont4`)}</p>
                                        </label>
                                    </div>
                                    {compressionPack ?
                                        <div className="JoinErrorMsg">
                                            <p className="JoinErrMsg">{t(`modReqWarn2`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="productBoxRemove" type="checkbox" checked={productBoxRemove}
                                               onChange={prodBoxRemoveCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="productBoxRemove" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont5`)}</p>
                                        </label>
                                    </div>
                                    {productBoxRemove ?
                                        <div className="JoinErrorMsg">
                                            <p className="JoinErrMsg">{t(`modReqWarn3`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="packingHold" type="checkbox" checked={packingHold}
                                               onChange={packingHoldCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="packingHold" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont6`)}</p>
                                        </label>
                                    </div>
                                    {packingHold ?
                                        <div className="JoinErrorMsg">
                                            <p className="JoinErrMsg">{t(`modReqWarn4`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="deliveryDepositPay" type="checkbox" checked={deliveryDepositPay}
                                               onChange={deliDepositPayCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="deliveryDepositPay" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont7`)}</p>
                                        </label>
                                    </div>
                                    {deliveryDepositPay ?
                                        <div className="JoinErrorMsg">
                                            <p className="JoinErrMsg">{t(`modReqWarn5`)}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    <div style={{
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "17px",
                                        cursor: "pointer"
                                    }}>
                                        <input id="deliveryMessengerGuide" type="checkbox"
                                               checked={deliveryMessengerGuide}
                                               onChange={deliMsgGuideCheck}
                                               style={{
                                                   width: "19px",
                                                   height: "19px",
                                                   marginRight: "10px",
                                                   cursor: "pointer"
                                               }}/>
                                        <label htmlFor="deliveryMessengerGuide" style={{cursor: "pointer"}}>
                                            <p style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontWeight: "lighter",
                                                color: "#7B7B7B"
                                            }}>{t(`modReqCont8`)}</p>
                                        </label>
                                    </div>

                                    {deliveryMessengerGuide ?
                                        <div className="JoinErrorMsg">
                                            <p className="JoinErrMsg">{t(`modReqCont9`)}</p>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>
                            <div style={{height: "70px", display: "flex", color: "dimgrey"}}>
                                <div style={{
                                    color: "dimgrey",
                                    width: "25%",
                                    backgroundColor: "#FAFAFA",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "20px",
                                    fontSize: "15px"
                                }}>{t(`modSmallMenu13`)}
                                </div>
                                <div style={{width: "85%", margin: "12px 10px 0 15px"}}>
                                    <input type="text" className="orderFormInput" maxLength={300} value={detailRequest}
                                           onChange={detailRequestInput}
                                           style={{
                                               width: "100%",
                                               height: "45px",
                                               borderRadius: "4px",
                                               borderWidth: "thin 1px",
                                               padding: "0 6px 0 6px"
                                           }}
                                           placeholder={t('modSmallMenu15')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*여기서부터 스크롤 시 따라다니는 결제정보 스티키*/}
                    <div className="wd-34pc display-flex" style={{height: "2220px"}} id="orderPaySticky">
                        <div className="wd-100pc p-l-20 p-r-20 ht-auto" style={{border: "solid 1px lightgray", borderRadius: "4px"}}>
                            <div className="font-t-title font-s-20 ht-60 display-flex align-center">{t(`modHeader10`)}</div>
                            <div className="font-t-title font-s-18 ht-50 display-flex align-center" style={{borderBottom: "solid 1px #EAEAEA", margin: "20px 0 3px 0"}}>
                                {t(`modSHeader1`)}
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader2`)}</div>
                                <div>{totalQuantity}{t(`ea`)}</div>
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader0`)}</div>
                                <div>{totalPrices.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{t(`won`)}</div>
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader4`)}</div>
                                <div>{totalMallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{t(`won`)}</div>
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader5`)}</div>
                                <div>{totalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{t(`won`)}</div>
                            </div>
                            <div className="font-t-title font-s-18 ht-50 display-flex align-center" style={{borderBottom: "solid 1px #EAEAEA", margin: "20px 0 3px 0"}}>
                                {t(`modSHeader6`)}
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader7`)}</div>
                                <div>{totalOverseasShippingExpense}{t(`won`)}</div>
                            </div>
                            <div className="font-t-title font-s-18 ht-50 display-flex align-center" style={{borderBottom: "solid 1px #EAEAEA", margin: "20px 0 3px 0"}}>
                                {t(`modSHeader8`)}
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader9`)}</div>
                                <div>{totalEtcExpense}{t(`won`)}</div>
                            </div>
                            {/*나중에 인풋에 금액&쿠폰 사용금액 입력하면 좌측에 사용가능잔액은 실시간으로 차감되어야 함*/}
                            {/*나중에 할인적용 실행 시 input에 value={pointSale} / value={depositSale} / value={couponSale} 넣기*/}
                            <div className="font-t-title font-s-18 ht-50 display-flex align-center" style={{borderBottom: "solid 1px #EAEAEA", margin: "20px 0 3px 0"}}>
                                {t(`modSHeader10`)}
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>
                                    {t(`modSHeader11`)}(
                                    <span className="font-c-btorage">{memberInfo[0] && memberInfo[0].point > 0 ? (dbPoint ? dbPoint.toLocaleString('en-US') : 0) :
                                    <span style={{color: "darkgray"}}>0</span>}
                                    </span>)
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input type="text" className="usePointInput" style={{
                                        width: "100px", height: "35px", borderRadius: "4px", borderWidth: "thin 1px",
                                        marginLeft: "60px", padding: "0 6px 0 6px"
                                    }} value={pointSale.toLocaleString('en-US')} onChange={(e)=> handlePointSale(e.target.value)}/>
                                    <button className="bt-btn-A ht-35 font-s-12 wd-80 m-l-5" onClick={allPointSale}>{t(`modDpUseBt`)}</button>
                                </div>
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader12`)}(<span
                                    className="font-c-btorage">{memberInfo[0] && memberInfo[0].deposit > 0 ? (dbDeposit ? dbDeposit.toLocaleString('en-US') : 0) :
                                    <span style={{color: "darkgray"}}>0</span>}</span>)
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input type="text" className="usePointInput" style={{
                                        width: "100px", height: "35px", borderRadius: "4px", borderWidth: "thin 1px",
                                        marginLeft: "60px", padding: "0 6px 0 6px"
                                    }} value={depositSale.toLocaleString('en-US')} onChange={(e)=> handleDepositSale(e.target.value)}/>
                                    <button className="bt-btn-A ht-35 font-s-12 wd-80 m-l-5" onClick={allDepositSale}>{t(`modDpUseBt`)}</button> {/*전액사용*/}
                                </div>
                            </div>
                            <div className="font-t-cont ht-40 display-flex align-center just-cont-sb">
                                <div>{t(`modSHeader13`)}(<span style={{color: dbCoupon === 0 ? "darkgray" : "darkorange"}}>{dbCoupon}</span>)
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input type="text" className="usePointInput" style={{
                                        width: "100px", height: "35px", borderRadius: "4px", borderWidth: "thin 1px",
                                        marginLeft: "60px", padding: "0 6px 0 6px"
                                    }} value={couponSale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                           onChange={(e)=> handleDepositSale(e.target.value)}/>
                                    {couponSale === '' ?
                                        <button className="bt-btn-A ht-35 font-s-12 wd-80 m-l-5" onClick={showUseCoupon}>{t(`modCUseBt`)}</button>
                                        :
                                        <button className="bt-btn-A ht-35 font-s-12 wd-80 m-l-5" onClick={closeUseCoupon}>{t(`st3`)}</button>
                                    } {/*쿠폰적용*/}
                                </div>
                            </div>
                            <div className="font-t-title font-s-20 ht-50 display-flex align-center m-t-20 just-cont-sb"
                                 style={{border: "solid 1px #EAEAEA", borderRight: "none", borderLeft: "none"}}>
                                <p style={{color: "#2a2a2a"}}>{t(`modFinalPay`)}</p>
                                <div className="display-flex align-center">
                                    <p className="font-c-btorage m-r-5">{finalPayExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                                    <p style={{color: "#2a2a2a"}}>{t(`won`)}</p>
                                </div>
                            </div>
                            {isOrderPay ?
                                <div>
                                    <div className="font-t-title font-s-21 ht-50 display-flex align-center m-t-50" style={{borderBottom: "solid 1px #EAEAEA"}}>
                                        {t(`modPty`)}
                                    </div>
                                    <div className="font-t-cont" style={{height: "auto"}}>
                                        <div style={{width: "100%", marginTop: "20px"}}>

                                            {/*비토리지페이 row*/}
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <label
                                                    className={payWay === "PM06" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                    onClick={() => handlePayWayChange("PM06")}>
                                                    <input className="radioButton" type="radio" name="paymentMethod"
                                                           value="PM06"
                                                           style={{borderStyle: "solid", display: "none"}}/>
                                                    <div style={{
                                                        height: "70%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        padding: "16px"
                                                    }}><img alt="" src={btoragePay}/></div>
                                                    <div style={{height: "30%", textAlign: "center", fontSize: "15px"}}>
                                                        <p style={{fontSize: "13px", color: payWay === "PM06" ? "darkorange" : "black"}}>BtoragePay</p>
                                                    </div>
                                                </label>
                                                <label
                                                    className={payWay === "PM07" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                    onClick={() => handlePayWayChange("PM07")}>
                                                    <input className="radioButton" type="radio" name="paymentMethod"
                                                           value="PM07"
                                                           style={{borderStyle: "solid", display: "none"}}/>
                                                    <div style={{
                                                        height: "70%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        padding: "16px"
                                                    }}><img alt="" src={creditCard}/></div>
                                                    <div style={{height: "30%", textAlign: "center", fontSize: "15px"}}>
                                                        <p style={{fontSize: "13px", color: payWay === "PM07" ? "darkorange" : "black"}}>CreditCard</p>
                                                    </div>
                                                </label>
                                                <label
                                                    className={payWay === "PM08" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                    onClick={() => handlePayWayChange("PM08")}>
                                                    <input className="radioButton" type="radio" name="paymentMethod"
                                                           value="PM08"
                                                           style={{borderStyle: "solid", display: "none"}}/>
                                                    <div style={{
                                                        height: "70%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        alignItems:"flex-end",
                                                        padding: "16px"
                                                    }}><img style={{width:"60px", height:"30px"}} alt="" src={stripe}/></div>
                                                    <div style={{height: "30%", textAlign: "center", fontSize: "15px"}}>
                                                        <p style={{fontSize: "13px", color: payWay === "PM08" ? "darkorange" : "black"}}>Stripe</p>
                                                    </div>
                                                </label>
                                            </div>

                                            {/*페이팔 row*/}
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <label
                                                    className={payWay === "PM01" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                    onClick={() => handlePayWayChange("PM01")}>
                                                    <input className="radioButton" type="radio" name="paymentMethod"
                                                           value="PM01"
                                                           style={{borderStyle: "solid", display: "none"}}/>
                                                    <div style={{
                                                        height: "70%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        padding: "16px"
                                                    }}><img alt="" src={PaypalLogo}/></div>
                                                    <div style={{height: "30%", textAlign: "center", fontSize: "15px"}}>
                                                        <p style={{fontSize: "13px", color: payWay === "PM01" ? "darkorange" : "black"}}>Paypal</p>
                                                    </div>
                                                </label>
                                                <label className={payWay === "PM02" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                    onClick={() => handlePayWayChange("PM02")}>
                                                    <input className="radioButton" type="radio" name="paymentMethod"
                                                           value="PM02"
                                                           style={{borderStyle: "solid", display: "none"}}/>
                                                    <div style={{
                                                        height: "70%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        padding: "16px"
                                                    }}><img alt="" src={WechatpayLogo}/></div>
                                                    <div style={{height: "30%", textAlign: "center", fontSize: "15px"}}>
                                                        <p style={{fontSize: "13px", color: payWay === "PM02" ? "darkorange" : "black"}}>WechatPay</p>
                                                    </div>
                                                </label>
                                                <label
                                                    className={payWay === "PM03" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                    onClick={() => handlePayWayChange("PM03")}>
                                                    <input className="radioButton" type="radio" name="paymentMethod"
                                                           value="PM03"
                                                           style={{borderStyle: "solid", display: "none"}}/>
                                                    <div style={{
                                                        height: "70%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        justifyContent: "center",
                                                        padding: "16px"
                                                    }}><img alt="" src={AlipayLogo}/></div>
                                                    <div style={{height: "30%", textAlign: "center", fontSize: "15px"}}>
                                                        <p style={{fontSize: "13px", color: payWay === "PM03" ? "darkorange" : "black"}}>AliPay</p>
                                                    </div>
                                                </label>
                                            </div>
                                            {/*무통장 row*/}
                                            <div style={{width: "100%"}}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <label
                                                        className={payWay === "PM00" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                        onClick={() => handlePayWayChange("PM00")}>
                                                        <input className="radioButton" type="radio" name="paymentMethod"
                                                               value="PM00"
                                                               style={{borderStyle: "solid", display: "none"}}/>
                                                        <div style={{
                                                            height: "70%",
                                                            display: "flex",
                                                            overflow: "hidden",
                                                            justifyContent: "center",
                                                            padding: "18px"
                                                        }}>
                                                            <img alt="" src={NoBank}/>
                                                        </div>
                                                        <div style={{
                                                            height: "30%",
                                                            textAlign: "center",
                                                            fontSize: "15px"
                                                        }}>
                                                            <p style={{
                                                                fontSize: "13px",
                                                                color: payWay === "PM00" ? "darkorange" : "black"
                                                            }}>{t(`modNoBank`)}</p>
                                                        </div>
                                                    </label>
                                                    <label
                                                        className={payWay === "PM05" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                        onClick={() => handlePayWayChange("PM05")}>
                                                        <input className="radioButton" type="radio" name="paymentMethod"
                                                               value="PM05"
                                                               style={{borderStyle: "solid", display: "none"}}/>
                                                        <div style={{
                                                            height: "70%",
                                                            display: "flex",
                                                            overflow: "hidden",
                                                            justifyContent: "center",
                                                            padding: "16px"
                                                        }}>
                                                            <img alt="" src={DepositPay}/>
                                                        </div>
                                                        <div style={{
                                                            height: "30%",
                                                            textAlign: "center",
                                                            fontSize: "15px"
                                                        }}>
                                                            <p style={{
                                                                fontSize: "13px",
                                                                color: payWay === "PM05" ? "darkorange" : "black"
                                                            }}>{t(`modDep`)}</p>
                                                        </div>
                                                    </label>
                                                    <label
                                                        className={payWay === "PM04" ? 'orderPayMethodFocusContainer' : 'orderPayMethodContainer'}
                                                        onClick={() => handlePayWayChange("PM04")}>
                                                        <input className="radioButton" type="radio" name="paymentMethod"
                                                               value="PM04"
                                                               style={{borderStyle: "solid", display: "none"}}/>
                                                        <div style={{
                                                            height: "70%",
                                                            display: "flex",
                                                            overflow: "hidden",
                                                            justifyContent: "center",
                                                            padding: "16px"
                                                        }}>
                                                            <img alt="" src={PointPay}/>
                                                        </div>
                                                        <div style={{
                                                            height: "30%",
                                                            textAlign: "center",
                                                            fontSize: "15px"
                                                        }}>
                                                            <p style={{
                                                                fontSize: "13px",
                                                                color: payWay === "PM04" ? "darkorange" : "black"
                                                            }}>{t(`modPt`)}</p>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {payWay === "PM01" ?
                                            <div style={{
                                                fontSize: "19px",
                                                marginTop: "20px",
                                                paddingLeft: "5px",
                                                paddingRight: "5px"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    border: "solid 1px #EAEAEA",
                                                    borderLeft: "none",
                                                    borderRight: "none",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <p style={{fontSize: "18px", color: "black"}}>{t(`modPpExr`)}</p>
                                                    <div className="user-center" style={{color: "darkorange"}}>
                                                        {lastForPrice}
                                                        <div ref={dollarRef} style={{
                                                            height: "30px",
                                                            width: "50px",
                                                            position: "relative",
                                                            marginLeft: "10px"
                                                        }}>
                                                            <button className="selectParentBt"
                                                                    style={{width: "100%", height: "100%"}}
                                                                    onClick={toggleDollarShow}>
                                                                <p style={{fontSize: "12px"}}>{currency}</p>
                                                            </button>
                                                            {dollarShow ?
                                                                <div style={{
                                                                    width: "100%",
                                                                    border: "solid 1px lightgray",
                                                                    zIndex: 1,
                                                                    marginTop: "5px",
                                                                    overflowY: "auto",
                                                                }}>
                                                                    {dollarList.map((dollar, index) => (
                                                                        <div className="selectChildBt" key={index}
                                                                             onClick={() => selectChangeDollar(dollar)}
                                                                             style={{
                                                                                 display: "flex",
                                                                                 padding: "10px",
                                                                                 justifyContent: "center",
                                                                                 alignItems: "center",
                                                                                 cursor: "pointer",
                                                                                 fontSize: "12px",
                                                                                 zIndex: 99,
                                                                             }}>{dollar}</div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            payWay === "PM03" ?
                                                <div>

                                                    <div style={{
                                                        fontSize: "19px",
                                                        marginTop: "15px",
                                                        paddingLeft: "5px",
                                                        paddingRight: "5px",
                                                        marginBottom: "30px",
                                                        width: "100%",
                                                    }}>
                                                        <div style={{
                                                            height: "50px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            border: "solid 1px lightgray",
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <p style={{fontSize: "16px", color: "black",}}>{t(`modExr`)}</p>
                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                <p style={{fontSize: "17px", color: "darkorange"}}>{lastForPrice}</p>
                                                                <span>
                                                                    <div className="user-center" style={{margin: "2px 0 0 5px"}}>
                                                                        <p style={{fontSize: "13px"}}>{currency}</p>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        border : "solid 1px #EAEAEA",
                                                        height: "50%",
                                                        marginTop: "15px",
                                                        borderRadius: "8px"
                                                    }}>
                                                        <div style={{height: "40%"}}>
                                                            <div style={{
                                                                height: "33%",
                                                                padding: "20px 0 5px 25px"
                                                            }}>- {t(`modAliAcct`)} : 13165072023
                                                            </div>
                                                            <div style={{
                                                                height: "33%",
                                                                padding: "0 0 15px 25px"
                                                            }}>- {t(`modAcctNm`)} : IMEUNWOO
                                                            </div>

                                                        </div>
                                                        <div style={{height: "60%", paddingBottom: "15px"}}>
                                                            <div style={{
                                                                height: "180px",
                                                                width: "50%",
                                                                display: "flex",
                                                                overflow: "hidden",
                                                                justifyContent: "center",
                                                                margin: "auto"
                                                            }}><img alt="" src={AliQrCode}/></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                payWay === "PM02" ?
                                                    <div>
                                                        <div style={{
                                                            fontSize: "19px",
                                                            marginTop: "15px",
                                                            paddingLeft: "5px",
                                                            paddingRight: "5px",
                                                            marginBottom: "30px",
                                                            width: "100%",
                                                        }}>
                                                            <div style={{
                                                                height: "50px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                border: "solid 1px lightgray",
                                                                borderLeft: "none",
                                                                borderRight: "none",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <p style={{fontSize: "16px", color: "black"}}>{t(`modExr`)}</p>
                                                                <div style={{display: "flex", alignItems: "center"}}>
                                                                    <p style={{fontSize: "17px", color: "darkorange"}}>{lastForPrice}</p>
                                                                    <span>
                                                                <div className="user-center"
                                                                     style={{margin: "2px 0 0 5px"}}>
                                                                    <p style={{fontSize: "13px"}}>{currency}</p>
                                                                </div>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            border: "solid 1px #EAEAEA",
                                                            height: "50%",
                                                            marginTop: "15px",
                                                            borderRadius: "8px"
                                                        }}>
                                                            <div style={{height: "40%"}}>
                                                                <div style={{
                                                                    height: "33%",
                                                                    padding: "20px 0 5px 25px"
                                                                }}>- Weixin ID : lfamily-help
                                                                </div>
                                                                <div style={{
                                                                    height: "33%",
                                                                    padding: "0 0 15px 25px"
                                                                }}>- {t(`modAcctNm`)} : IMEUNWOO
                                                                </div>

                                                            </div>
                                                            <div style={{height: "60%", paddingBottom: "10px"}}>
                                                                <div style={{
                                                                    height: "200px",
                                                                    width: "50%",
                                                                    display: "flex",
                                                                    overflow: "hidden",
                                                                    justifyContent: "center",
                                                                    margin: "auto"
                                                                }}><img alt="" src={wechatQrCode}/></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    payWay === "PM00" ?
                                                        <div style={{
                                                            border: "solid 1px #EAEAEA",
                                                            height: "50%",
                                                            marginTop: "15px",
                                                            borderRadius: "8px"
                                                        }}>
                                                            <div style={{
                                                                height: "auto",
                                                                margin: "20px 11px 20px 11px",
                                                                color: "dimgrey"
                                                            }}>
                                                                <h6>● {t(`modNbInfo1`)}</h6>
                                                                <div>- {t(`modAcctInfo`)}</div>
                                                                <h6 style={{marginTop: "20px"}}>● {t(`welcome`)}</h6>
                                                                <div>- {t(`modAcctNm`)} : Btorage corp</div>
                                                                <div>- {t(`modNbInfo4`)} ShinhanBank 140-014-047039
                                                                </div>
                                                                <div>- {t(`modNbInfo5`)} : 82-10-3338-9354</div>
                                                                <div>- {t(`modNbInfo6`)} : help@btorage.com</div>
                                                                <div>- {t(`modNbInfo7`)} : 1F, 32-15, Yeongok-gil
                                                                    42beon-gil, Gonjiam-eup, Gwangju-si, Gyeonggi-do
                                                                </div>
                                                                <div>- {t(`modNbInfo8`)} : 12722</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                        }
                                    </div>
                                    {payMethodError &&
                                        <p className="JoinErrMsg">{t(`modPiErr1`)}</p>
                                    }
                                    {pointPayError &&
                                        <p className="JoinErrMsg">{t(`modPiErr2`)}</p>
                                    }
                                    {depositSelectErr &&
                                        <p className="JoinErrMsg">{t(`depositErr`)}</p>
                                    }
                                    {pointSelectErr &&
                                        <p className="JoinErrMsg">{t(`pointErr`)}</p>
                                    }
                                    {etcPayError &&
                                        <p className="JoinErrMsg">{t(`modPiErr3`)}</p>
                                    }
                                    {discountError &&
                                        <p className="JoinErrMsg">{t(`finalPriceErr`)}</p>
                                    }
                                </div>
                                :
                                null
                            }
                            {
                                cartProductHandle.some(cart => cart.name && cart.name.length > 0) ||
                                orderProductHandle.some(order => order.name && order.name.length > 0) ||
                                offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ||
                                transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ||
                                domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ||
                                deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) ?
                                    <div className="font-t-cont" style={{
                                        border: "solid 1px #EAEAEA",
                                        marginTop: "30px",
                                        minHeight: "50px",
                                        borderRadius: "4px",
                                        padding: "15px 20px"
                                    }}>
                                        {/*전체동의*/}
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            borderBottom: "solid 1px #EAEAEA",
                                            minHeight: "50px",
                                            marginBottom: "15px"
                                        }}>
                                            <input id="AllAgreeCheckBox" type="checkbox"
                                                   style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                   checked={allAgreeCheck} onChange={allAgreeHandle}/>
                                            <label htmlFor="AllAgreeCheckBox"><span style={{
                                                fontSize: "15px",
                                                marginLeft: "10px",
                                                cursor: "pointer"
                                            }}>{t(`joinAgAll`)}</span></label>
                                        </div>
                                        {cartProductHandle.some(cart => cart.name && cart.name.length > 0) ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: "30px",
                                                marginTop: "10px"
                                            }}>
                                                <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                                    <input id="cartOrderAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={cartServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setCartServiceAgreeCheck)}/>
                                                </div>
                                                <div style={{width: "92%"}}>
                                                    <label htmlFor="cartOrderAgree"><span style={{
                                                        color: "#818181",
                                                        fontSize: "15px",
                                                        cursor: "pointer"
                                                    }}>{t(`modPiAgCart`)}</span>
                                                        <span style={{
                                                            color: "#FF7C48",
                                                            fontSize: "14px",
                                                            marginLeft: "3px"
                                                        }}>({t(`essential`)})</span>
                                                    </label>
                                                </div>
                                                <span onClick={() => setCartTermsPopup(true)} style={{
                                                    cursor: "pointer",
                                                    width: "10%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}>
                                                <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView} alt="view"/>
                                            </span>
                                            </div>
                                            :
                                            null
                                        }
                                        {orderProductHandle.some(order => order.name && order.name.length > 0) ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: "30px",
                                                marginTop: "10px"
                                            }}>
                                                <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                                    <input id="orderUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={orderServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setOrderServiceAgreeCheck)}/>
                                                </div>
                                                <div style={{width: "92%"}}>
                                                    <label htmlFor="orderUseAgree"><span style={{
                                                        color: "#818181",
                                                        fontSize: "15px",
                                                        cursor: "pointer"
                                                    }}>{t(`modPiAgOrder`)}</span>
                                                        <span style={{
                                                            color: "#FF7C48",
                                                            fontSize: "14px",
                                                            marginLeft: "3px"
                                                        }}>({t(`essential`)})</span>
                                                    </label>
                                                </div>
                                                <span onClick={() => setOrderTermsPopup(true)} style={{
                                                    cursor: "pointer",
                                                    width: "10%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}>
                                                <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView} alt="view"/>
                                            </span>
                                            </div>
                                            :
                                            null
                                        }
                                        {offlineProductHandle.some(offline => offline.name && offline.name.length > 0) ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: "30px",
                                                marginTop: "10px"
                                            }}>
                                                <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                                    <input id="offlineUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={offlineServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setOfflineServiceAgreeCheck)}/>
                                                </div>
                                                <div style={{width: "92%"}}>
                                                    <label htmlFor="offlineUseAgree"><span style={{
                                                        color: "#818181",
                                                        fontSize: "15px",
                                                        cursor: "pointer"
                                                    }}>{t(`modPiAgOff`)}</span>
                                                        <span style={{
                                                            color: "#FF7C48",
                                                            fontSize: "14px",
                                                            marginLeft: "3px"
                                                        }}>({t(`essential`)})</span>
                                                    </label>
                                                </div>
                                                <span onClick={() => setOfflineTermsPopup(true)} style={{
                                                    cursor: "pointer",
                                                    width: "10%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}>
                                                <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView} alt="view"/>
                                            </span>
                                            </div>
                                            :
                                            null
                                        }
                                        {transferProductHandle.some(transfer => transfer.name && transfer.name.length > 0) ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: "30px",
                                                marginTop: "10px"
                                            }}>
                                                <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                                    <input id="transferUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={transferServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setTransferServiceAgreeCheck)}/>
                                                </div>
                                                <div style={{width: "92%"}}>
                                                    <label htmlFor="transferUseAgree"><span style={{
                                                        color: "#818181",
                                                        fontSize: "15px",
                                                        cursor: "pointer"
                                                    }}>{t(`modPiAgTr`)}</span>
                                                        <span style={{
                                                            color: "#FF7C48",
                                                            fontSize: "14px",
                                                            marginLeft: "3px"
                                                        }}>({t(`essential`)})</span>
                                                    </label>
                                                </div>
                                                <span onClick={() => setTransferTermsPopup(true)} style={{
                                                    cursor: "pointer",
                                                    width: "10%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}>
                                                <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView} alt="view"/>
                                            </span>
                                            </div>
                                            :
                                            null
                                        }
                                        {domesticProductHandle.some(domestic => domestic.name && domestic.name.length > 0) ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: "30px",
                                                marginTop: "10px"
                                            }}>
                                                <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                                    <input id="domesticUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={domesticServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setDomesticServiceAgreeCheck)}/>
                                                </div>
                                                <div style={{width: "92%"}}>
                                                    <label htmlFor="domesticUseAgree"><span style={{
                                                        color: "#818181",
                                                        fontSize: "15px",
                                                        cursor: "pointer"
                                                    }}>{t(`modPiAgDm`)}</span>
                                                        <span style={{
                                                            color: "#FF7C48",
                                                            fontSize: "14px",
                                                            marginLeft: "3px"
                                                        }}>({t(`essential`)})</span>
                                                    </label>
                                                </div>
                                                <span onClick={() => setDomesticTermsPopup(true)} style={{
                                                    cursor: "pointer",
                                                    width: "10%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}>
                                                <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView} alt="view"/>
                                            </span>
                                            </div>
                                            :
                                            null
                                        }
                                        {deliveryProductHandle.some(delivery => delivery.name && delivery.name.length > 0) ?
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: "30px",
                                                marginTop: "10px"
                                            }}>
                                                <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                                    <input id="deliveryUseAgree" type="checkbox"
                                                           style={{width: "18px", height: "18px", cursor: "pointer"}}
                                                           checked={deliveryServiceAgreeCheck}
                                                           onChange={handleAgreeChange(setDeliveryServiceAgreeCheck)}/>
                                                </div>
                                                <div style={{width: "92%"}}>
                                                    <label htmlFor="deliveryUseAgree"><span style={{
                                                        color: "#818181",
                                                        fontSize: "15px",
                                                        cursor: "pointer"
                                                    }}>{t(`modPiAgDv`)}</span>
                                                        <span style={{
                                                            color: "#FF7C48",
                                                            fontSize: "14px",
                                                            marginLeft: "3px"
                                                        }}>({t(`essential`)})</span>
                                                    </label>
                                                </div>
                                                <span onClick={() => setDeliveryTermsPopup(true)} style={{
                                                    cursor: "pointer",
                                                    width: "10%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end"
                                                }}>
                                                <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView} alt="view"/>
                                            </span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                            }
                            <div className="JoinErrorMsg" style={{marginBottom: "20px"}}>
                                {agreeCheckBoxError && agreeErrorMsgCondition &&
                                    <p className="JoinErrMsg">{t(`modPiErr4`)}</p>}
                            </div>
                            {/*{showPayNow &&*/}
                            {isOrderPay &&
                                <div className="font-t-title" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <style>
                                        {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                    </style>
                                    <button className="bt-btn-B ht-45 font-s-20 wd-100pc"
                                            onClick={() => handleSubmit("pay")}
                                            disabled={isPayBtDisabled}>
                                        {isPayBtLoading ? (
                                            <div className="spinner"></div>
                                        ) : (
                                            <div>{t(`modPiBt1`)}</div>
                                        )}
                                    </button> {/*Pay 버튼*/}
                                </div>
                            }
                            <div className="font-t-title" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <style>
                                    {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                </style>
                                <button className="bt-btn-D-B ht-45 font-s-20 wd-100pc m-t-10" onClick={() => handleSubmit("save")} disabled={isSaveBtDisabled}>
                                    {isSaveBtLoading ? (
                                        <div className="spinner"></div>
                                    ) : (
                                        <div>{t(`modPiBt2`)}</div>
                                    )}
                                </button> {/*Save 버튼*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                orderAddrBookSt === true ?
                    <UserAddrBook addrBookCloseFn={addrBookCloseFn} orderAddrBookSt={orderAddrBookSt}
                                  addrChoiceData={addrChoiceData} selectedShip={selectedDeliveryChoiceOption}/>
                    :
                    useCouponSt ?
                        <CouponUse useCouponSt={useCouponSt} closeUseCoupon={closeUseCoupon} couponUseFn={couponUseFn}/>
                        :
                        paypalPopupSt ?
                            <PaypalModal paypalPopupSt={paypalPopupSt} paypalPopupClose={paypalPopupClose}
                                         currency={currency}
                                         finalPayExpense={finalPayExpense} lastForPrice={lastForPrice}
                                         paypalOrderProcess={paypalOrderProcess}/>
                            :
                            cartTermsPopup ?
                                <CommonTermsPopup cartTermsPopup={cartTermsPopup}
                                                  closeCartTermsPopup={closeCartTermsPopup}/>
                                :
                                orderTermsPopup ?
                                    <CommonTermsPopup orderTermsPopup={orderTermsPopup}
                                                      closeOrderTermsPopup={closeOrderTermsPopup}/>
                                    :
                                    offlineTermsPopup ?
                                        <CommonTermsPopup offlineTermsPopup={offlineTermsPopup}
                                                          closeOfflineTermsPopup={closeOfflineTermsPopup}/>
                                        :
                                        transferTermsPopup ?
                                            <CommonTermsPopup transferTermsPopup={transferTermsPopup}
                                                              closeTransferTermsPopup={closeTransferTermsPopup}/>
                                            :
                                            domesticTermsPopup ?
                                                <CommonTermsPopup domesticTermsPopup={domesticTermsPopup}
                                                                  closeDomesticTermsPopup={closeDomesticTermsPopup}/>
                                                :
                                                deliveryTermsPopup ?
                                                    <CommonTermsPopup deliveryTermsPopup={deliveryTermsPopup}
                                                                      closeDeliveryTermsPopup={closeDeliveryTermsPopup}/>
                                                    :
                                                    biggerCpPriceSt ?
                                                        <MsgCheckModal biggerCpPriceSt={biggerCpPriceSt}
                                                                       closeBiggerCpPriceSt={closeBiggerCpPriceSt}/>
                                                        :
                                                        fileSizeOverSt ?
                                                            <MsgCheckModal fileSizeOverSt={fileSizeOverSt}
                                                                           closeFileSizeOverSt={closeFileSizeOverSt}/>
                                                            :
                                                            readyingPopupSt ?
                                                            <ReadyingPopup readyingPopupSt={readyingPopupSt} closeReadyingPopup={closeReadyingPopup}/>
                                                                :
                                                                formUploadErrSt ?
                                                                    <MsgCheckModal
                                                                        formUploadErrSt={formUploadErrSt}
                                                                        closeFormUploadErrSt={closeFormUploadErrSt}/>
                                                                    :
                                                                    holdedValidationError ?
                                                                        <MsgCheckModal
                                                                            holdedValidationError={holdedValidationError}
                                                                            closeHoldedValidationError={closeHoldedValidationError}/>
                                                                        :
                                                            null
            }
        </div>
    )
}

export default withAuth(UserOrderPg)
