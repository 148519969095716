import React, {useEffect, useState} from 'react';
import axios from '../../services/axiosInstance';
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import AdminPaypalExAdd from "../../components/web/modal/AdminPaypalExAdd";
import AdminPaypalExModify from "../../components/web/modal/AdminPaypalExModify";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminPaypalExPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [payRate, setPayRate] = useState([]);

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        select();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    // region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    // endregion Pagination

    // region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (dataItem) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.some(item => item.seqNum === dataItem.seqNum)) {
                return prevSelectedData.filter((item) => item.seqNum !== dataItem.seqNum);
            } else {
                return [...prevSelectedData, dataItem];
            }
        });
    };

    const allCheckBoxChange = (e) => {
        if (e.target.checked) {
            setChecked(data);
        } else {
            setChecked([]);
        }
    };

    // endregion CheckBox

    /** endregion Function*/

    /** region Event */

    useEffect(() => {
        select();
    }, [perPg])

    const select = () => {
        axios({
            method: 'get',
            url: '/paypal/exrjoinselect',
            params: {
                limit: perPg
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setData(response.data);
                const rePayRate = response.data.map((cData) => cData.payRate);
                setPayRate(rePayRate);
                setChecked([]);
            })
            .catch((error) => {

            });
    };

    const [noSelectedPaypalExShow, setNoSelectedPaypalExShow] = useState(false);
    const noSelectedPaypalExMsg = "수정할 항목을 선택해주세요"
    const closeNoSelectedPaypalExShow = (() => {
        setNoSelectedPaypalExShow(false);
    })

    const [paypalExModifyShow, setPaypalExModifyShow] = useState(false);
    const closePaypalExModifyShow = (() => {
        setPaypalExModifyShow(false);
    })
    const openPaypalExModifyShow = (() => {
        if (!checked || checked.length === 0) {
            setNoSelectedPaypalExShow(true);
        } else {
            setPaypalExModifyShow(true);
        }
    })

    const [paypalExAddShow, setPaypalExAddShow] = useState(false);
    const closePaypalExAddShow = (() => {
        setPaypalExAddShow(false);
    })

    const saveData = (curPrice, payExr, payRate) => {
        axios({
                method: 'post',
                url: '/paypal/saveExrData',
                data: {
                    payRate: payRate,
                    payExr: payExr,
                    curPrice: curPrice
                },
            headers: {
                Authorization: LCAT
            },
            })
                .then((response) => {
                    setData([...data, response.data]);
                    closePaypalExAddShow();
                })
                .catch((error) => {
                    if (error.response.status === 400 || error.response.status === 401) {
                        window.location.href = '/Error401';
                    } else if (error.response.status === 500) {
                        window.location.href = '/Error500';
                    } else if (error.response.status === 404) {
                        window.location.href = '/Error404';
                    }
                });
        }

    const modifyData = ((modifyData) => {
        axios({
            method: 'post',
            url: '/paypal/modifyExrData',
            data: modifyData,
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const modifiedData = response.data;
                const updatedData = data.map(item => {
                    const matchingItem = modifiedData.find(modifiedItem => modifiedItem.seqNum === item.seqNum);
                    if (matchingItem) {
                        return matchingItem;
                    }
                    return item;
                });
                setData(updatedData);
                closePaypalExModifyShow(false);
            })
            .catch((error) => {
                // if (error.response.status === 400 || error.response.status === 401) {
                //     window.location.href = '/Error401';
                // } else if (error.response.status === 500) {
                //     window.location.href = '/Error500';
                // } else if (error.response.status === 404) {
                //     window.location.href = '/Error404';
                // }
            });
    });

    const [paypalExDeleteShow, setPaypalExDeleteShow] = useState(false);
    const paypalExDeleteMsg1 = "선택하신 환율항목을 삭제하시겠습니까?"
    const paypalExDeleteMsg2 = "삭제 후 복구가 불가합니다"
    const closePaypalExDeleteShow = (() => {
        setPaypalExDeleteShow(false);
    })

    const deleteData = ((modifyData) => {
        axios({
            method: 'post',
            url: '/paypal/deleteExrData',
            data: modifyData,
        })
            .then((response) => {
                const updatedData = data.filter((item) => !modifyData.some((deletedItem) => deletedItem.seqNum === item.seqNum));
                setData(updatedData);
                closePaypalExDeleteShow();
            })
            .catch((error) => {
                if (error.response.status === 400 || error.response.status === 401) {
                    window.location.href = '/Error401';
                } else if (error.response.status === 500) {
                    window.location.href = '/Error500';
                } else if (error.response.status === 404) {
                    window.location.href = '/Error404';
                }
            });
    });

    /** endregion */

    return (
        <div className="AdminPaypalExPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">페이팔환율관리</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button onClick={()=> setPaypalExDeleteShow(true)} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">삭제</button>
                            <button onClick={openPaypalExModifyShow} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">수정</button>
                            <button onClick={()=> setPaypalExAddShow(true)} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">신규등록</button>
                        </div>
                    </div>
                    <div className="div-tb">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}><input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                                                onChange={allCheckBoxChange}/></div>
                            <div style={{width: "80px"}}><p>통화</p></div>
                            <div style={{width: "100px"}}><p>환율(&#8361;)</p></div>
                            <div style={{width: "100px"}}><p>요율(%)</p></div>
                            <div style={{width: "120px"}}><p>등록자</p></div>
                            <div style={{width: "150px"}}><p>등록일시</p></div>
                            <div style={{width: "120px"}}><p>수정자</p></div>
                            <div style={{width: "150px"}}><p>수정일시</p></div>
                        </div>
                        {data.slice(0, displayLimit).map((cData, i) => (
                            <div key={cData.seqNum} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.some(item => item.seqNum === cData.seqNum)}
                                           onChange={() => checkBoxChange(cData)}/>
                                </div>
                                <div style={{width: "80px"}}><p className="font-b">{cData.curPrice}</p></div>
                                <div style={{width: "100px"}}><p>{cData?.payExr?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                <div className="font-b wd-100"><p>{cData.payRate}</p></div>
                                <div style={{width: "120px"}}><p>{cData.regNm}</p></div>
                                <div style={{width: "150px"}}><p>{cData.regTime}</p></div>
                                <div style={{width: "120px"}}><p>{cData.modiNm}</p></div>
                                <div style={{width: "150px"}}><p>{cData.modiTime}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            { noSelectedPaypalExShow ?
            <MsgConfirmModal noSelectedPaypalExShow={noSelectedPaypalExShow} noSelectedPaypalExMsg={noSelectedPaypalExMsg} closeNoSelectedPaypalExShow={closeNoSelectedPaypalExShow}/>
                :
                paypalExModifyShow ?
                    <AdminPaypalExModify paypalExModifyShow={paypalExModifyShow} closePaypalExModifyShow={closePaypalExModifyShow} paypalExModifychecked={checked} modifyData={modifyData}/>
                    :
                    paypalExAddShow ?
                        <AdminPaypalExAdd paypalExAddShow={paypalExAddShow} closePaypalExAddShow={closePaypalExAddShow} saveData={saveData}/>
                        :
                        paypalExDeleteShow ?
                            <MsgSelectModal paypalExDeleteShow={paypalExDeleteShow} closePaypalExDeleteShow={closePaypalExDeleteShow} paypalExDeleteChecked={checked}
                                             paypalExDeleteMsg1={paypalExDeleteMsg1} paypalExDeleteMsg2={paypalExDeleteMsg2} deleteData={deleteData}/>
                            :
                null
            }
        </div>
    );
}

export default AdminPaypalExPg
