import React, {useEffect} from "react";
import warnIcon from '../../../assets/img/warnIcon.png'
import complDown from '../../../assets/img/complete.png'
import {useTranslation} from "react-i18next";

function MsgCheckModal({
                           loginErrorMsg,
                           closeLoginErrorMsg,
                           userOutErrorMsg,
                           closeUserOutErrorMsg,
                           mailDuplErrorMsg,
                           closeMailDuplErrorMsg,
                           accountTypeErrorMsg,
                           closeAccountTypeErrorMsg,
                           editMstDeleteModal,
                           deleteMaster,
                           editDtDeleteModal,
                           deleteDetail,
                           deleteMstClose,
                           deleteDtClose,
                           nUserChatRemoveSt,
                           nUserChatRemoveClose,
                           nUserChatRemove,
                           chatConnectFailSt,
                           setChatConnectFailSt,
                           deleteMacroMaster,
                           deleteMacroDetail,
                           deleteMacroDtClose,
                           deleteMacroMstClose,
                           editMacroMstDeleteModal,
                           editMacroDtDeleteModal,
                           dontTrackingShipcoSt,
                           closeDontTrackingShipco,
                           downNoSelectSt,
                           closeDownNoSelect,
                           orderDivideMergeSt,
                           moveToMyPg,
                           dpPayNullSt,
                           closeDpPayNullSt,
                           returnNullSt,
                           closeReturnNullSt,
                           allUpdateProdNullSt,
                           closeAllUpdateProdNullSt,
                           payinfoSelectOrderErrSt,
                           closePayinfoSelectOrderErr,
                           payinfoSelectOrderErrMsg,
                           nonEmsDataSt,
                           closeNonEmsDataSt,
                           nonSelectOrderSt,
                           closeNonSelectOrderSt,
                           biggerCpPriceSt,
                           closeBiggerCpPriceSt,
                           commonError,
                           closeCommonError,
                           fileSizeOverSt,
                           closeFileSizeOverSt,
                           downloadFileComplSt,
                           closeDownloadFileCompl,
                           saveFailSt,
                           closeSaveFailSt,
                           selectedShipCrError,
                           closeSelectedShipCrError,
                           formUploadErrSt,
                           closeFormUploadErrSt,
                           confirm, msgData3, confirmShow1,
                           confirm2, msgData4, confirmShow2,
                           confirm3, msgData5, confirmShow3,
                           confirm4, msgData6, confirmShow4,
                           photoDeleteConfirmSt, photoDeleteConfirmFn, MsgConfirmData7,
                           holdedValidationError,
                           closeHoldedValidationError,
                           agreeCheckBoxError,
                           closeAgreeCheckBoxError,
                           couponDupSt,
                           closeCouponDup,
                           iosAppInstallingSt,
                           closeIosAppInstallingSt,
                           appLaunchMsg,
                           closeAppLaunchMsg,
                           pointPayCompleteMsg,
                           pointPayErrorMsg,
                           moveToReviewDt,
                           reviewDtPgSeqNum,
                           unableToWriteMsg,
                           closeUnableToWriteMsg
                       }) {

    const {t} = useTranslation();

    const errorOrderOffUploadMsg =
        formUploadErrSt === "invalidFee"
            ? t('formFeeErrCont') : t('formErrCont');

    useEffect(() => {
        const handleEnterKey = (event) => {
            if (event.key === 'Enter') {
                if (loginErrorMsg) {
                    closeLoginErrorMsg();
                } else if (userOutErrorMsg) {
                    closeUserOutErrorMsg();
                } else if (mailDuplErrorMsg) {
                    closeMailDuplErrorMsg();
                } else if (accountTypeErrorMsg) {
                    closeAccountTypeErrorMsg();
                } else if (editMstDeleteModal) {
                    deleteMaster();
                } else if (editDtDeleteModal) {
                    deleteDetail();
                } else if (editMacroMstDeleteModal) {
                    deleteMacroMaster();
                } else if (editMacroDtDeleteModal) {
                    deleteMacroDetail();
                } else if (nUserChatRemoveSt) {
                    nUserChatRemove();
                } else if (chatConnectFailSt) {
                    nUserChatRemove();
                } else if (dontTrackingShipcoSt) {
                    closeDontTrackingShipco();
                } else if (downNoSelectSt) {
                    closeDownNoSelect();
                } else if (orderDivideMergeSt) {
                    moveToMyPg();
                } else if (dpPayNullSt) {
                    closeDpPayNullSt();
                } else if (returnNullSt) {
                    closeReturnNullSt();
                } else if (allUpdateProdNullSt) {
                    closeAllUpdateProdNullSt();
                } else if (payinfoSelectOrderErrSt) {
                    closePayinfoSelectOrderErr();
                } else if (nonEmsDataSt) {
                    closeNonEmsDataSt();
                } else if (nonSelectOrderSt) {
                    closeNonSelectOrderSt();
                } else if (biggerCpPriceSt) {
                    closeBiggerCpPriceSt();
                } else if (commonError) {
                    closeCommonError();
                } else if (fileSizeOverSt) {
                    closeFileSizeOverSt();
                } else if (downloadFileComplSt) {
                    closeDownloadFileCompl();
                } else if (saveFailSt) {
                    closeSaveFailSt();
                } else if (selectedShipCrError) {
                    closeSelectedShipCrError();
                } else if (formUploadErrSt) {
                    closeFormUploadErrSt();
                } else if (confirmShow1) {
                    confirm();
                } else if (confirmShow2) {
                    confirm2();
                } else if (confirmShow3) {
                    confirm3();
                } else if (confirmShow4) {
                    confirm4();
                } else if (photoDeleteConfirmSt) {
                    photoDeleteConfirmFn();
                } else if (holdedValidationError) {
                    closeHoldedValidationError();
                } else if (agreeCheckBoxError) {
                    closeAgreeCheckBoxError();
                } else if (couponDupSt) {
                    closeCouponDup();
                } else if (iosAppInstallingSt) {
                    closeIosAppInstallingSt();
                } else if (appLaunchMsg) {
                    closeAppLaunchMsg();
                } else if (pointPayCompleteMsg || pointPayErrorMsg) {
                    moveToReviewDt(reviewDtPgSeqNum);
                } else if (unableToWriteMsg) {
                    closeUnableToWriteMsg();
                }
            }
        };
        document.addEventListener('keydown', handleEnterKey);

        return () => {
            document.removeEventListener('keydown', handleEnterKey);
        };
    }, []);

    return (
        <div style={{position: "fixed", top: "0", left: "0", width: "100%", height: "100%", zIndex: 40,
            background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center"
        }}>
            <div className="modalFrame-lang-price-err border-rd6">
                <div style={{borderBottom: "solid 1px lightgray", height: "35%", display: "flex", padding: "10px"}}>
                    <div className="wd-15pc ht-100pc display-flex just-cont-center align-center">
                        <img className="wd-35 ht-35" alt='' src={
                            downloadFileComplSt || confirmShow1 || confirmShow2 || confirmShow3 || confirmShow4 || photoDeleteConfirmSt || iosAppInstallingSt || pointPayCompleteMsg ?
                            complDown
                                : warnIcon}/>
                    </div>
                    <div className="font-t-title font-s-16 ht-100pc wd-80pc display-flex align-center" style={{margin:"auto"}}>
                        {loginErrorMsg || userOutErrorMsg || mailDuplErrorMsg || accountTypeErrorMsg ?
                            <p style={{marginTop:"5px"}}>{t(`mcmHeader1`)}</p>
                            :
                            editMstDeleteModal ?
                                <p style={{marginTop:"5px"}}>Delete Master</p>
                                :
                                editDtDeleteModal ?
                                    <p style={{marginTop:"5px"}}>Delete Detail</p>
                                    :
                                    editMacroMstDeleteModal ?
                                        <p style={{marginTop:"5px"}}>Delete Master</p>
                                        :
                                        editMacroDtDeleteModal ?
                                            <p style={{marginTop:"5px"}}>Delete Detail</p>
                                            :
                                            nUserChatRemoveSt ?
                                                <p style={{marginTop:"5px"}}>{t(`mcmHeader2`)}</p>
                                                :
                                                chatConnectFailSt ?
                                                    <p style={{marginTop:"5px"}}>{t(`mcmHeader3`)}</p>
                                                    :
                                                    dontTrackingShipcoSt ?
                                                        <p style={{marginTop:"5px"}}>{t(`mcmHeader4`)}</p>
                                                        :
                                                        downNoSelectSt ?
                                                            <p style={{marginTop:"5px"}}>{t(`mcmHeader5`)}</p>
                                                            :
                                                            orderDivideMergeSt ?
                                                                <p style={{marginTop:"5px"}}>{t(`modMdHd1`)}</p>
                                                                :
                                                                dpPayNullSt ?
                                                                    <p style={{marginTop:"5px"}}>Data Null Error</p>
                                                                    :
                                                                    returnNullSt ?
                                                                        <p style={{marginTop:"5px"}}>Data Null Error</p>
                                                                        :
                                                                        allUpdateProdNullSt ?
                                                                            <p style={{marginTop:"5px"}}>Data Null Error</p>
                                                                            :
                                                                            payinfoSelectOrderErrSt ?
                                                                                <p style={{marginTop:"5px"}}>Selection Error</p>
                                                                                :
                                                                                nonEmsDataSt ?
                                                                                    <p style={{marginTop:"5px"}}>Select Data Error</p>
                                                                                    :
                                                                                    nonSelectOrderSt ?
                                                                                        <p style={{marginTop:"5px"}}>No Select Error</p>
                                                                                        :
                                                                                        biggerCpPriceSt ?
                                                                                            <p style={{marginTop:"5px"}}>{t('modCUseBtErr1')}</p>
                                                                                            :
                                                                                            commonError ?
                                                                                                <p style={{marginTop:"5px"}}>Data Null Error</p>
                                                                                                :
                                                                                                fileSizeOverSt ?
                                                                                                    <p style={{marginTop:"5px"}}>{t('uploadSizeOverHd')}</p>
                                                                                                    :
                                                                                                    downloadFileComplSt ?
                                                                                                        <p style={{marginTop:"5px"}}>{t('downloadComplHd')}</p>
                                                                                                        :
                                                                                                        saveFailSt ?
                                                                                                            <p style={{marginTop:"5px"}}>Upload Fail</p>
                                                                                                            :
                                                                                                            selectedShipCrError ?
                                                                                                                <p style={{marginTop:"5px"}}>{t('ciModalHd')}</p>
                                                                                                                :
                                                                                                                formUploadErrSt ?
                                                                                                                    <p style={{marginTop:"5px"}}>{t('formErrHd')}</p>
                                                                                                                    :
                                                                                                                    confirmShow1 || confirmShow2 || confirmShow3 || confirmShow4 || photoDeleteConfirmSt ?
                                                                                                                        <p style={{marginTop:"5px"}}>Apply Changes</p>
                                                                                                                        :
                                                                                                                        holdedValidationError ?
                                                                                                                            <p style={{marginTop:"5px"}}>{t('holdValidationHd')}</p>
                                                                                                                            :
                                                                                                                            agreeCheckBoxError ?
                                                                                                                                <p style={{marginTop:"5px"}}>{t('agreeCheckErrorHd')}</p>
                                                                                                                                :
                                                                                                                                couponDupSt ?
                                                                                                                                    <p style={{marginTop:"5px"}}>Duplicate Issuance</p>
                                                                                                                                    :
                                                                                                                                    iosAppInstallingSt ?
                                                                                                                                        <p style={{marginTop:"5px"}}>{t('msmRunAppModalHd1')}</p>
                                                                                                                                        :
                                                                                                                                        appLaunchMsg ?
                                                                                                                                            <p style={{marginTop:"5px"}}>{t('appBannerHd1')}</p>
                                                                                                                                            :
                                                                                                                                            appLaunchMsg ?
                                                                                                                                                <p style={{marginTop:"5px"}}>{t('appBannerHd1')}</p>
                                                                                                                                                :
                                                                                                                                                pointPayCompleteMsg ?
                                                                                                                                                    <p style={{marginTop:"5px"}}>Complete</p>
                                                                                                                                                    :
                                                                                                                                                    pointPayErrorMsg ?
                                                                                                                                                        <p style={{marginTop:"5px"}}>Error</p>
                                                                                                                                                        :
                                                                                                                                                        unableToWriteMsg ?
                                                                                                                                                            <p style={{marginTop:"5px"}}>{t('rvWtErr5')}</p>
                                                                                                                                                            :
                                                                                                                        null
                        }
                    </div>
                </div>
                <div className="font-t-cont font-s-17 display-flex align-center" style={{height: "30%", padding: "30px", color:"dimgray"}}>
                    {loginErrorMsg ?
                        <p>{t(`mcmCont1`)}</p>
                        :
                        userOutErrorMsg ?
                            <p>{t(`resType0`)}</p>
                            :
                            mailDuplErrorMsg ?
                                <p>{t(`resType1`)}</p>
                                :
                                accountTypeErrorMsg ?
                                    <p>{t(`resType2`)}</p>
                                    :
                        editMstDeleteModal ?
                            <p>하위 Detail 데이터들까지 삭제됩니다</p>
                            :
                            editDtDeleteModal ?
                                <p>삭제 시 복구가 불가합니다</p>
                                :
                                editMacroMstDeleteModal ?
                                    <p>하위 Detail 데이터들까지
                                        삭제됩니다</p>
                                    :
                                    editMacroDtDeleteModal ?
                                        <p>삭제 시 복구가
                                            불가합니다</p>
                                        :
                                        nUserChatRemoveSt ?
                                            <p>{t(`mcmCont2`)}</p>
                                            :
                                            chatConnectFailSt ?
                                                <p>{t(`mcmCont3`)}</p>
                                                :
                                                dontTrackingShipcoSt ?
                                                    <p>{t(`mcmCont4`)}</p>
                                                    :
                                                    downNoSelectSt ?
                                                        <p>{t(`mcmCont5`)}</p>
                                                        :
                                                        orderDivideMergeSt ?
                                                            <p>{t(`modMdMsg1`)}</p>
                                                            :
                                                            dpPayNullSt ?
                                                                <p>결제하실 데이터를 선택해주세요</p>
                                                                :
                                                                allUpdateProdNullSt ?
                                                                    <p>입고처리하실 데이터를 선택해주세요</p>
                                                                    :
                                                                    payinfoSelectOrderErrSt ?
                                                                        <p>배송료타입만 선택해주세요</p>
                                                                        :
                                                                        nonEmsDataSt ?
                                                                            <p>EMS 데이터만 선택해주세요</p>
                                                                            :
                                                                            nonSelectOrderSt ?
                                                                                <p>데이터를 선택해주세요</p>
                                                                                :
                                                                                biggerCpPriceSt ?
                                                                                    <p>{t('modCUseBtErr2')}</p>
                                                                                    :
                                                                                    commonError ?
                                                                                        <p>{t('recInfoNullErr')}</p>
                                                                                        :
                                                                                        fileSizeOverSt ?
                                                                                            <p>{t('uploadSizeOverCont')}</p>
                                                                                            :
                                                                                            downloadFileComplSt ?
                                                                                                <p>{t('downloadComplCont')}</p>
                                                                                                :
                                                                                                saveFailSt ?
                                                                                                    <p>업로드가 실패되었습니다</p>
                                                                                                    :
                                                                                                    selectedShipCrError ?
                                                                                                        <p>{t('ciModalCont')}</p>
                                                                                                        :
                                                                                                        formUploadErrSt ?
                                                                                                            <p>{errorOrderOffUploadMsg}</p>
                                                                                                            :
                                                                                                            confirmShow1 ?
                                                                                                                <p>{msgData3}</p>
                                                                                                                :
                                                                                                                confirmShow2 ?
                                                                                                                    <p>{msgData4}</p>
                                                                                                                    :
                                                                                                                    confirmShow3 ?
                                                                                                                        <p>{msgData5}</p>
                                                                                                                        :
                                                                                                                        confirmShow4 ?
                                                                                                                            <p>{msgData6}</p>
                                                                                                                            :
                                                                                                                            photoDeleteConfirmSt ?
                                                                                                                                <p>{MsgConfirmData7}</p>
                                                                                                                                :
                                                                                                                                holdedValidationError ?
                                                                                                                                    <p>{t('holdValidationCont')}</p>
                                                                                                                                    :
                                                                                                                                    agreeCheckBoxError ?
                                                                                                                                        <p>{t('modPiErr4')}</p>
                                                                                                                                        :
                                                                                                                                        couponDupSt ?
                                                                                                                                            <p>이미 발급된 쿠폰입니다</p>
                                                                                                                                            :
                                                                                                                                            iosAppInstallingSt ?
                                                                                                                                                <p>{t('msmRunAppModalCont1')}</p>
                                                                                                                                                :
                                                                                                                                                appLaunchMsg ?
                                                                                                                                                    <p>{t('appBannerCont1')}</p>
                                                                                                                                                    :
                                                                                                                                                    pointPayCompleteMsg ?
                                                                                                                                                        <p>{t('rvPointComplete')}</p>
                                                                                                                                                        :
                                                                                                                                                        pointPayErrorMsg ?
                                                                                                                                                            <p>{t('rvPointError')}</p>
                                                                                                                                                            :
                                                                                                                                                            unableToWriteMsg ?
                                                                                                                                                                <p>{t('rvWtErr6')}</p>
                                                                                                                                                                :
                                                                                                                null
                    }
                </div>
                <div style={{
                    height: "35%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "10px"
                }}>
                    {loginErrorMsg ?
                        <button onClick={closeLoginErrorMsg} className="orderItemHandleBt">{t(`st6`)}</button>
                        :
                        userOutErrorMsg ?
                            <button onClick={closeUserOutErrorMsg} className="orderItemHandleBt">{t(`st6`)}</button>
                            :
                            mailDuplErrorMsg ?
                                <button onClick={closeMailDuplErrorMsg} className="orderItemHandleBt">{t(`st6`)}</button>
                                :
                                accountTypeErrorMsg ?
                                    <button onClick={closeAccountTypeErrorMsg} className="orderItemHandleBt">{t(`st6`)}</button>
                                    :
                            editMstDeleteModal ?
                                <div style={{display: "flex"}}>
                                    <button onClick={() => deleteMstClose(false)}
                                            className="grayBt" style={{
                                        padding:"8px 15px",
                                        fontSize: "14px",
                                        borderWidth: "thin 1px",
                                        borderRadius: "4px",
                                        marginRight: "5px"
                                    }}>Cancel
                                    </button>
                                    <button onClick={deleteMaster} className="orderItemHandleBt">Go
                                    </button>
                                </div>
                                :
                                editDtDeleteModal ?
                                    <div style={{display: "flex"}}>
                                        <button onClick={() => deleteDtClose(false)}
                                                className="grayBt" style={{
                                            padding:"8px 15px",
                                            fontSize: "14px",
                                            borderWidth: "thin 1px",
                                            borderRadius: "4px",
                                            marginRight: "5px"
                                                }}>Cancel
                                        </button>
                                        <button onClick={deleteDetail} className="orderItemHandleBt">Go
                                        </button>
                                    </div>
                                    :
                                    editMacroMstDeleteModal ?
                                        <div style={{display: "flex"}}>
                                            <button onClick={() => deleteMacroMstClose(false)}
                                                    className="grayBt" style={{
                                                padding:"8px 15px",
                                                fontSize: "14px",
                                                borderWidth: "thin 1px",
                                                borderRadius: "4px",
                                                marginRight: "5px"
                                            }}>Cancel
                                            </button>
                                            <button onClick={deleteMacroMaster} className="orderItemHandleBt">Go
                                            </button>
                                        </div>
                                        :
                                        editMacroDtDeleteModal ?
                                            <div style={{display: "flex"}}>
                                                <button onClick={() => deleteMacroDtClose(false)}
                                                        className="grayBt" style={{
                                                    padding:"8px 15px",
                                                    fontSize: "14px",
                                                    borderWidth: "thin 1px",
                                                    borderRadius: "4px",
                                                    marginRight: "5px"
                                                }}>Cancel
                                                </button>
                                                <button onClick={deleteMacroDetail} className="orderItemHandleBt">Go
                                                </button>
                                            </div>
                                            :
                                            nUserChatRemoveSt ?
                                                <div style={{display: "flex"}}>
                                                    <button onClick={() => nUserChatRemoveClose(false)}
                                                            className="grayBt" style={{
                                                                padding:"8px 15px",
                                                        fontSize: "14px",
                                                        borderWidth: "thin 1px",
                                                        borderRadius: "4px",
                                                        marginRight: "5px"
                                                    }}>{t(`st3`)}
                                                    </button>
                                                    <button onClick={nUserChatRemove} className="orderItemHandleBt"
                                                            >{t(`st4`)}</button>
                                                </div>
                                                :
                                                chatConnectFailSt ?
                                                    <div className="display-flex gap10">
                                                        <button onClick={() => setChatConnectFailSt(false)} className="bt-btn-A wd-70 ht-40 font-s-13">{t(`st3`)}</button>
                                                        <button onClick={nUserChatRemove} className="bt-btn-B wd-70 ht-40 font-s-13">{t(`st5`)}</button>
                                                    </div>
                                                    :
                                                    dontTrackingShipcoSt ?
                                                        <button onClick={closeDontTrackingShipco}
                                                                className="orderItemHandleBt"
                                                        >{t(`st6`)}</button>
                                                        :
                                                        downNoSelectSt ?
                                                            <button onClick={closeDownNoSelect}
                                                                    className="orderItemHandleBt"
                                                            >{t(`st6`)}</button>
                                                            :
                                                            orderDivideMergeSt ?
                                                                <button onClick={moveToMyPg}
                                                                        className="orderItemHandleBt"
                                                                >{t(`st6`)}</button>
                                                                :
                                                                dpPayNullSt ?
                                                                    <button onClick={closeDpPayNullSt}
                                                                            className="orderItemHandleBt"
                                                                     >Check</button>
                                                                    :
                                                                    dpPayNullSt ?
                                                                        <button onClick={closeReturnNullSt}
                                                                                className="orderItemHandleBt"
                                                                          >Check</button>
                                                                        :
                                                                        allUpdateProdNullSt ?
                                                                            <button onClick={closeAllUpdateProdNullSt}
                                                                                    className="orderItemHandleBt"
                                                                                   >Confirm</button>
                                                                            :
                                                                            payinfoSelectOrderErrSt ?
                                                                                <button
                                                                                    onClick={closePayinfoSelectOrderErr}
                                                                                    className="orderItemHandleBt"
                                                                                    >Confirm</button>
                                                                                :
                                                                                nonEmsDataSt ?
                                                                                    <button onClick={closeNonEmsDataSt}
                                                                                            className="orderItemHandleBt"
                                                                                           >Check</button>
                                                                                    :
                                                                                    nonSelectOrderSt ?
                                                                                        <button
                                                                                            onClick={closeNonSelectOrderSt}
                                                                                            className="orderItemHandleBt"
                                                                                            >Check</button>
                                                                                        :
                                                                                        biggerCpPriceSt ?
                                                                                            <button
                                                                                                onClick={closeBiggerCpPriceSt}
                                                                                                className="orderItemHandleBt"
                                                                                                >Check</button>
                                                                                            :
                                                                                            commonError ?
                                                                                                <button
                                                                                                    onClick={closeCommonError}
                                                                                                    className="orderItemHandleBt"
                                                                                                    >{t(`st6`)}</button>
                                                                                                :
                                                                                                fileSizeOverSt ?
                                                                                                    <button
                                                                                                        onClick={closeFileSizeOverSt}
                                                                                                        className="orderItemHandleBt"
                                                                                                        >{t(`st6`)}</button>
                                                                                                    :
                                                                                                    downloadFileComplSt ?
                                                                                                        <button
                                                                                                            onClick={closeDownloadFileCompl}
                                                                                                            className="orderItemHandleBt"
                                                                                                            >{t(`st6`)}</button>
                                                                                                        :
                                                                                                        saveFailSt ?
                                                                                                            <button
                                                                                                                onClick={closeSaveFailSt}
                                                                                                                className="orderItemHandleBt"
                                                                                                                >{t(`st6`)}</button>
                                                                                                            :
                                                                                                            selectedShipCrError ?
                                                                                                                <button
                                                                                                                    onClick={closeSelectedShipCrError}
                                                                                                                    className="orderItemHandleBt"
                                                                                                                    >{t(`st6`)}</button>
                                                                                                                :
                                                                                                                formUploadErrSt ?
                                                                                                                    <button
                                                                                                                        onClick={closeFormUploadErrSt}
                                                                                                                        className="orderItemHandleBt"
                                                                                                                        >{t(`st6`)}</button>
                                                                                                                    :
                                                                                                                    confirmShow1 ?
                                                                                                                        <button
                                                                                                                            onClick={confirm}
                                                                                                                            className="orderItemHandleBt"
                                                                                                                            >{t(`st6`)}</button>
                                                                                                                        :
                                                                                                                        confirmShow2 ?
                                                                                                                            <button
                                                                                                                                onClick={confirm2}
                                                                                                                                className="orderItemHandleBt"
                                                                                                                            >{t(`st6`)}</button>
                                                                                                                            :
                                                                                                                            confirmShow3 ?
                                                                                                                                <button
                                                                                                                                    onClick={confirm3}
                                                                                                                                    className="orderItemHandleBt"
                                                                                                                                   >{t(`st6`)}</button>
                                                                                                                                :
                                                                                                                                confirmShow4 ?
                                                                                                                                    <button
                                                                                                                                        onClick={confirm4}
                                                                                                                                        className="orderItemHandleBt"
                                                                                                                                        >{t(`st6`)}</button>
                                                                                                                                    :
                                                                                                                                    photoDeleteConfirmSt ?
                                                                                                                                        <button
                                                                                                                                            onClick={photoDeleteConfirmFn}
                                                                                                                                            className="orderItemHandleBt"
                                                                                                                                            >{t(`st6`)}</button>
                                                                                                                                        :
                                                                                                                                        holdedValidationError ?
                                                                                                                                            <button
                                                                                                                                                onClick={closeHoldedValidationError}
                                                                                                                                                className="orderItemHandleBt"
                                                                                                                                            >{t(`st6`)}</button>
                                                                                                                                            :
                                                                                                                                            agreeCheckBoxError ?
                                                                                                                                                <button
                                                                                                                                                    onClick={closeAgreeCheckBoxError}
                                                                                                                                                    className="orderItemHandleBt"
                                                                                                                                                >{t(`st6`)}</button>
                                                                                                                                                :
                                                                                                                                                couponDupSt ?
                                                                                                                                                    <button
                                                                                                                                                        onClick={closeCouponDup}
                                                                                                                                                        className="orderItemHandleBt"
                                                                                                                                                    >Check</button>
                                                                                                                                                    :
                                                                                                                                                    iosAppInstallingSt ?
                                                                                                                                                        <button
                                                                                                                                                            onClick={closeIosAppInstallingSt}
                                                                                                                                                            className="orderItemHandleBt"
                                                                                                                                                        >{t(`msmRunAppModalBt1`)}</button>
                                                                                                                                                        :
                                                                                                                                                        appLaunchMsg ?
                                                                                                                                                            <button
                                                                                                                                                                onClick={closeAppLaunchMsg}
                                                                                                                                                                className="orderItemHandleBt"
                                                                                                                                                            >Check</button>
                                                                                                                                                            :
                                                                                                                                                            pointPayCompleteMsg || pointPayErrorMsg ?
                                                                                                                                                                <button
                                                                                                                                                                    onClick={()=> moveToReviewDt(reviewDtPgSeqNum)}
                                                                                                                                                                    className="orderItemHandleBt"
                                                                                                                                                                >Check</button>
                                                                                                                                                                :
                                                                                                                                                                unableToWriteMsg ?
                                                                                                                                                                    <button
                                                                                                                                                                        onClick={closeUnableToWriteMsg}
                                                                                                                                                                        className="orderItemHandleBt"
                                                                                                                                                                    >Check</button>
                                                                                                                                                                    :
                                                                                                    null
                    }
                </div>
            </div>
        </div>
    )
}

export default MsgCheckModal
