import React, {useState} from "react";
import back from "../../assets/img/commonBack.png";
import btorageLogo from '../../assets/img/HeaderLogo.png'
import {useLocation, useNavigate} from "react-router-dom";
import axios from '../../services/axiosInstance';

function MAdminPwFindPg() {

    const navigate = useNavigate()
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const [codeSendSt, setCodeSendSt] = useState(false);
    const [email, setEmail] = useState('');
    const [tempPassword, setTempPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);

    const handleEmail = ((e) => {
        setEmail(e);
        setEmailError(false);
        setNotJoinError(false);
    })

    const handleTempPassword = ((e) => {
        setTempPassword(e);
        setResPassWordError(false);
    })

    const handlePassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e || (passwordRegex.test(e)))) setPasswordError(false);
        else setPasswordError(true);
        setPassword(e);
    };

    const handleConfirmPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (password === e) setPasswordMatchError(false);
        else setPasswordMatchError(true);
        setConfirmPassword(e);
    };

    const [resPassword, setResPassword] = useState('');
    const [resPassWordError, setResPassWordError] = useState(false);
    const [notJoinError, setNotJoinError] = useState(false);

    const generateTempPassword = () => {
        setIsLoading(true);
        if (email === '') {
            setEmailError(true);
            setIsLoading(false);
        } else {
            axios({
                method: 'post',
                url: '/member/generateTempPassword',
                params: {
                    email: email
                }
            })
                .then((response) => {
                    setResPassword(response.data);
                    setCodeSendSt(true);
                    console.log("임시비밀번호 : ", response.data)
                })
                .catch(() => {
                    setNotJoinError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const validation = () => {
        let isValid = true;
        if (!password) {
            setPasswordError(true);
            isValid = false;
        }
        if (password !== confirmPassword || !confirmPassword) {
            setPasswordMatchError(true);
            isValid = false;
        }
        if (resPassword !== tempPassword) {
            setResPassWordError(true);
            isValid = false;
        }
        return isValid;
    }

    const valiAndPasswordModify = () => {
        if (validation()) {
            modifyPassword()
        }
    };

    const modifyPwKeyDown = (e) => {
        if (e.key === "Enter" && validation()) {
            modifyPassword(e);
        }
    };

    // 백엔드에서 변경 후 프론트엔드로 반환 전 로그인함수실행
    const modifyPassword = (() => {
        setIsLoading(true);
        axios({
            method: 'post',
            url: '/member/modifyPassword',
            params: {
                email: email,
                password: password
            }
        })
            .then(() => {
                // 로그인
                console.log("로그인함수 실행")
                login(email, password);
            })
            .catch((error) => {
                setNotJoinError(true);
                setIsLoading(false);
                console.log("변경실패 : ", error)
            })
    })

    const login = ((email, password) => {
        axios({
            method: 'post',
            url: '/member/login',
            data: {
                id: email,
                password: password,
                loginPath: location.pathname,
            }
        })
            .then((response) => {
                const currentTime = new Date();
                sessionStorage.setItem('AToken', response.headers.get('Authorization'))
                sessionStorage.setItem('lastLoginTime', currentTime);
                console.log("로그인페이지 이동")
                navigate("/btcorpad-login")
            })
            .catch((error) => {
                setNotJoinError(true);
                console.log("변경실패 : ", error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    })

    return(
        <div id="m-a-login-pg-container">
            <div className="m-a-common-header">
                <img onClick={()=> navigate("/btcorpad-login")} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">비밀번호변경</div>
            </div>
            <div className="m-a-fixed-container">
                <div className="m-a-login-container m-t-50">
                {!codeSendSt ?
                    <div>
                        <div className="m-a-idpw-container">
                            <div className="m-a-input-frame ht-45 m-a-input-frame-mgb5 wd-100pc">
                                <input type="text" value={email} onChange={(e) => handleEmail(e.target.value)}
                                       maxLength={40} className="m-a-input font-t-cont" placeholder="이메일"/>
                            </div>
                        </div>
                        {notJoinError ?
                            <div className="m-a-error-msg-container wd-100pc m-t-5">
                                <div className="m-a-error-msg font-t-cont font-s-14">가입되지 않은 이메일입니다.</div>
                            </div>
                            :
                            null
                        }
                        {emailError ?
                            <div className="m-a-error-msg-container wd-100pc m-t-5">
                                <div className="m-a-error-msg font-t-cont font-s-14">가입하신 이메일을 입력해주세요</div>
                            </div>
                            :
                            null
                        }
                        <div className="m-a-colorBt-container m-t-5">
                            <button onClick={generateTempPassword} className="m-a-colorBt ht-40 wd-100pc">
                                <style>
                                    {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                </style>
                                {isLoading ? (
                                    <div className="user-center">
                                        <div className="spinner"></div>
                                    </div>
                                ) : (
                                    <div className="m-a-colorBt-text font-t-cont">비밀번호전송</div>
                                )}
                            </button>
                            {/*<button className="m-a-commonBt ht-40 m-b-5" style={{width:"100%"}}>*/}
                            {/*    <div className="m-a-commonBt-text font-t-cont">네이버로그인</div>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    :
                    <div>
                    <div className="m-a-idpw-container">
                        <div className="m-a-input-frame ht-45 wd-100pc">
                            <input type="password" className="m-a-input font-t-cont" placeholder="임시비밀번호"
                                   value={tempPassword} onChange={(e)=> handleTempPassword(e.target.value)}/>
                        </div>
                        {resPassWordError ?
                            <div className="m-a-error-msg-container wd-100pc m-t-5">
                                <div className="m-a-error-msg font-t-cont font-s-14">메일로 전송된 임시비밀번호를 입력해주세요</div>
                            </div>
                            :
                            null
                        }
                        <div className="m-a-input-frame ht-45 wd-100pc m-t-5">
                            <input type="password" className="m-a-input font-t-cont" placeholder="변경비밀번호"
                                   value={password} onChange={(e)=> handlePassword(e.target.value)}/>
                        </div>
                        {passwordError ?
                            <div className="m-a-error-msg-container wd-100pc m-t-5">
                                <div className="m-a-error-msg font-t-cont font-s-14">영문+숫자+특수문자 조합 8~20자를 입력해주세요</div>
                            </div>
                            :
                            null
                        }
                        <div className="m-a-input-frame ht-45 wd-100pc m-t-5">
                            <input type="password" className="m-a-input font-t-cont" placeholder="변경비밀번호 확인" onKeyDown={modifyPwKeyDown}
                                   value={confirmPassword} onChange={(e)=> handleConfirmPassword(e.target.value)}/>
                        </div>
                        {passwordMatchError ?
                            <div className="m-a-error-msg-container wd-100pc m-t-5">
                                <div className="m-a-error-msg font-t-cont font-s-14">변경비밀번호와 일치하지 않습니다</div>
                            </div>
                            :
                            null
                        }
                    </div>
                        <button onClick={valiAndPasswordModify} className="m-a-colorBt ht-40 m-t-10 wd-100pc">
                            <style>
                                {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                            </style>
                            {isLoading ? (
                                <div className="user-center">
                                    <div className="spinner"></div>
                                </div>
                            ) : (
                                <div className="m-a-colorBt-text font-t-cont">변경완료</div>
                            )}
                        </button>

                    </div>
                }
            </div>
            </div>
        </div>
    )
}

export default MAdminPwFindPg
