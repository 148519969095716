import React, {useEffect, useState} from 'react';
import {BsSquareFill} from "react-icons/bs";
import axios from '../../services/axiosInstance';
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import maCateImg from "../../assets/img/m-a-cate.png";
import ApexCharts from "react-apexcharts";
import {TbRefresh} from "react-icons/tb";

function AdminSalesStatusPg() {

    /** region DataTable */

    const [salesData, setSalesData] = useState([]);
    const [salesChartData, setSalesChartData] = useState([]);

    /** endregion */

    /** region Function */

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    const purchaseSeries = salesChartData.purchaseData || [];
    const deliverySeries = salesChartData.deliveryData || [];

    const stateProd = {
        series: [{
            name: "구매비용 & 수수료",
            data: purchaseSeries
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: ['#FF9924'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '70%',
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '12px',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
                formatter: function (value) {
                    return "\u20A9 " + value.toLocaleString();
                }
            },
            xaxis: {
                categories: ['장바구니', '구매대행', '오프라인', '이체대행', '국내배송', '총기타비용', '총수수료'],
                labels: {
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Pretendard-Regular, sans-serif',
                        colors: '#333333',
                    }
                },
                title: {
                    text: '단위(\u20A9)',
                    style: {
                        color: '#bebebe',
                        fontSize: '11px',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    }
                }
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return "\u20A9 " + value.toLocaleString();
                    }
                }
            },
            title: {
                text: '구매비용 & 수수료',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333333',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                }
            }
        },
    };

    const stateDel = {
        series: [{
            name: "배송비 & 예적쿠사용",
            data: deliverySeries
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: ['#ffc376'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '70%',
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '12px',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
                formatter: function (value) {
                    return "\u20A9 " + value.toLocaleString();
                }
            },
            xaxis: {
                categories: ['Fedex', 'Fedex-E','UPS', 'UPS-E', 'EMS', 'EMSP', 'K-Packet', 'Small-Packet', 'Sea', '국내택배', '예치금사용', '적립금사용', '쿠폰사용', '앱할인'],
                title: {
                    text: '단위(\u20A9)',
                    style: {
                        color: '#bebebe',
                        fontSize: '11px',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    }
                }
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return "\u20A9 " + value.toLocaleString();
                    }
                }
            },
            title: {
                text: '배송비 & 예적쿠사용',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333333',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                }
            }
        },
    };

    useEffect(() => {
        const today = new Date();
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(today));
        setEndDate(formatDateString(today));
    }, []);

    const resetToToday = () => {
        const today = new Date();
        const formatDateString = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formattedToday = formatDateString(today);
        setStartDate(formattedToday);
        setEndDate(formattedToday);
    };

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsales();
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (Object.keys(salesData).length) {
            const purchaseTotal =
                salesData.cartPrice +
                salesData.ordPrice +
                salesData.offPrice +
                salesData.trPrice +
                salesData.domPrice +
                salesData.ttEtcPrice +
                salesData.ttFee;

            const deliveryTotal =
                salesData.ttFedexPrice +
                salesData.ttFedexEcoPrice +
                salesData.ttUpsPrice +
                salesData.ttUpsEcoPrice +
                salesData.ttEmsPrice +
                salesData.ttEmsPrePrice +
                salesData.ttKPackPrice +
                salesData.ttAirSPrice +
                salesData.ttSeaPrice +
                salesData.ttDomPrice +
                salesData.ttEtcPrice +
                salesData.dpcPrice +
                salesData.appSale;

            const chartData = [
                { sDate: "1월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "2월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "3월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "4월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "5월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "6월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "7월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "8월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "9월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "10월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "11월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "12월", prod: purchaseTotal, del: deliveryTotal }
            ];

            setSalesChartData(chartData);
        }
    }, [salesData]);

    /** endregion */

    /** region Event */

    const selectOutsales = () => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios.get('/outsales/selectOutsales', {
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        }).then((response) => {
            setSalesData(response.data);
            setSalesChartData(response.data);
        }).catch((error) => {
            console.error("데이터 조회 실패:", error);
        });
    };

    useEffect(() => {
        handleDateRange(12);
    }, []);

    const handleDateRange = (months) => {
        const today = new Date();
        const pastDate = new Date();
        pastDate.setMonth(today.getMonth() - months);

        const formatDateString = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(pastDate));
        setEndDate(formatDateString(today));
    };

    useEffect(() => {
        if (salesData) {
            const purchaseData = [
                salesData.cartPrice || 0,
                salesData.ordPrice || 0,
                salesData.offPrice || 0,
                salesData.trPrice || 0,
                salesData.domPrice || 0,
                salesData.ttEtcPrice || 0,
                salesData.ttEtcFee || 0
            ];

            const deliveryData = [
                salesData.ttFedexPrice || 0,
                salesData.ttFedexEcoPrice || 0,
                salesData.ttUpsPrice || 0,
                salesData.ttUpsEcoPrice || 0,
                salesData.ttEmsPrice || 0,
                salesData.ttEmsPrePrice || 0,
                salesData.ttKPackPrice || 0,
                salesData.ttAirSPrice || 0,
                salesData.ttSeaPrice || 0,
                salesData.ttDomPrice || 0,
                salesData.useDeposit || 0,
                salesData.usePoint || 0,
                salesData.useCoupon || 0,
                salesData.appSale || 0
            ];

            setSalesChartData({ purchaseData, deliveryData });
        }
    }, [salesData]);

    /** endregion */

    return (
        <div className="AdminSalesStatusPg font-t-cont">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">매출현황</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40">
                    <div className="display-flex flex-row ht-350">
                        <div className="wd-50pc ht-100pc">
                            <ApexCharts options={stateProd.options} series={stateProd.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                        <div className="wd-50pc ht-100pc">
                            <ApexCharts options={stateDel.options} series={stateDel.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                    </div>
                </div>
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                            <button onClick={() => handleDateRange(6)} className="admin-btn-B wd-60 ht-30 font-s-13 btn-click-event m-r-15">6개월</button>
                            <button onClick={() => handleDateRange(12)} className="admin-btn-B wd-60 ht-30 font-s-13 btn-click-event m-r-15">1년</button>
                            <TbRefresh onClick={resetToToday} className="c-point font-s-24 btorage-hover m-t-2 font-c-default"/>
                        </div>
                    </div>
                    <div className="display-flex">
                        <div className="wd-50pc">
                            <div className="table-title dark-font">
                                <BsSquareFill className="admin-tb-header-notice"/>
                                <a>구매비용 & 수수료</a>
                            </div>
                            <div className="wd-100pc p-r-20">
                                <div style={{width: "100%", border: "solid 1px", color: "#F2F2F2", borderRadius: "4px"}}>
                                    <div className="display-flex ht-40">
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">장바구니</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.cartPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">구매대행</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ordPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">오프라인</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.offPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>이체대행</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.trPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">국내배송</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.domPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">총기타비용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEtcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">총수수료</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEtcFee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wd-50pc">
                            <div className="table-title dark-font">
                                <BsSquareFill className="admin-tb-header-notice"/>
                                <a>배송비 & 예적쿠사용</a>
                            </div>
                            <div style={{width: "100%", paddingRight: "20px"}}>
                                <div style={{
                                    width: "100%",
                                    border: "solid 1px",
                                    color: "#F2F2F2",
                                    borderRadius: "4px"
                                }}>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>Fedex</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttFedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>Fedex-E</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttFedexEcoPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">UPS</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">UPS-E</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttUpsEcoPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">EMS</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEmsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">EMSP</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttEmsPrePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">K-Packet</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttKPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">Small-Packet</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttAirSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">선편</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    {/*<div style={{height: "40px", display: "flex"}}>*/}
                                    {/*    <div style={{*/}
                                    {/*        width: "50%",*/}
                                    {/*        height: "100%",*/}
                                    {/*        display: "flex",*/}
                                    {/*        justifyContent: "center",*/}
                                    {/*        alignItems: "center",*/}
                                    {/*        backgroundColor: "#F6F6F6"*/}
                                    {/*    }}>*/}
                                    {/*        <p className="font-s-13">DHL</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">*/}
                                    {/*        <p>{salesData && salesData?.ttDhlPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">국내택배</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.ttDomPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">예치금사용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.useDeposit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p style={{fontSize: "13px"}}>적립금사용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.usePoint?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">쿠폰사용</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.useCoupon?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                    <div style={{height: "40px", display: "flex"}}>
                                        <div style={{
                                            width: "50%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#F6F6F6"
                                        }}>
                                            <p className="font-s-13">앱할인</p>
                                        </div>
                                        <div className="wd-50pc ht-100pc display-flex just-cont-center align-center">
                                            <p>{salesData && salesData?.appSale?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSalesStatusPg
