import React, {useEffect, useRef, useState} from "react";
import commonClose from "../../../assets/img/commonClose.png";
import commonInput from "../../../assets/img/commonInput.png";
import {useTranslation} from "react-i18next";
import axios from "../../../services/axiosInstance";
import {useLocation, useNavigate} from "react-router-dom";

function EmailInputPopup({mbNum, joinMail, closeMailInputPopup}) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [existsMailError, setExistsMailError] = useState(false);

    const [isSaveBtLoading, setIsSaveBtLoading] = useState(false);
    const [isSaveBtDisabled, setIsSaveBtDisabled] = useState(false);

    const handleEmailChange = (e) => { /* 두번 째 이메일 주소가 xx.xx가 아니면 에러메시지*/
        const emailRegex = /^[a-zA-Z0-9-ㄱ-힣]{2,25}@([\w-.]+)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3 }\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/;
        if (!e.target.value || emailRegex.test(e.target.value)) {
            setEmailError(false);
            setIsSaveBtDisabled(false);
        } else {
            setEmailError(true);
            setIsSaveBtDisabled(true);
        }
        setEmail(e.target.value);
    };

    const handleSubmit = () => {
        if (isSaveBtDisabled) {
            return;
        }
        setIsSaveBtDisabled(true);
        if (!emailError) {
            modifyMail();
        }
    }

    const modifyMail = () => {
        try {
            setIsSaveBtLoading(true);
            axios({
                method: 'post',
                url: '/member/modifyMail',
                params: {
                    mbNum: mbNum,
                    email: email,
                    appType: '0', // 0은 웹, 1은 앱
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    if (data.loginErrorType === '0') {
                        setExistsMailError(true);
                        console.log('중복된 이메일입니다');
                    } else {
                        localStorage.setItem('AToken', response.headers['authorization']);
                        closeMailInputPopup();
                    }
                });
        } catch (error) {
            setIsSaveBtLoading(false);
            setIsSaveBtDisabled(false);
            console.log('error : ', error);
        } finally {
            setIsSaveBtLoading(false);
            setIsSaveBtDisabled(false);
        }
    };

    return (
        <div className="position-fixed wd-100pc ht-100pc display-flex just-cont-center align-center"
             style={{top: "0", left: "0", zIndex: 1000, background: "rgba(0, 0, 0, 0.3)"}}>
            <div className="wd-400"
                 style={{background: "white", boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", borderRadius: "6px"}}>
                <div className="wd-100pc"
                     style={{borderBottom: "solid 1px #EAEAEA", height: "70px", display: "flex", padding: "10px"}}>
                    <div className="display-flex align-center justify-content-between wd-100pc p-l-10 p-r-10">
                        <div className="user-center">
                            <div className="ht-100pc display-flex just-cont-center align-center">
                                <img className="wd-30 ht-30" alt='' src={commonInput}/>
                            </div>
                            <div style={{height: "100%", display: "flex", alignItems: "center"}}>
                                <p className="font-t-title font-s-18 m-t-2 m-l-10">{t('eipHd')}</p>
                            </div>
                        </div>
                        <div className="user-center ht-100pc">
                            <img className="wd-30 ht-30 m-a-opc-07 c-point" onClick={closeMailInputPopup}
                                 src={commonClose} alt="close"/>
                        </div>
                    </div>
                </div>

                <div className="pd20">
                    <div
                        className="user-input-container wd-100pc ht-50 display-flex align-center just-cont-center">
                        <input onChange={(e) => handleEmailChange(e)} maxLength={50}
                               onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                               type="text" className="user-input wd-100pc ht-45 border-rd4 font-s-14 font-t-cont"
                               style={{padding: "0 10px", color: "dimgray"}} placeholder={t('umiPlaceHd1')} value={email}/>
                    </div>
                    {emailError ?
                        <div className="JoinErrorMsg font-t-cont m-t-10">
                            <p className="JoinErrMsg">{t(`loginLabel1`)}</p>
                        </div>
                        :
                        null
                    }
                    {existsMailError ?
                        <div className="JoinErrorMsg font-t-cont m-t-10">
                            <p className="JoinErrMsg">{t(`joinErr2`)}</p>
                        </div>
                        :
                        null
                    }
                </div>

                <div className="font-t-cont font-s-14 p-l-20 p-r-20 p-b-20">
                    <p>{t('eipLabel1')}</p>
                    <p>{t('eipLabel2')}</p>
                    <p>{t('eipLabel3')}</p>
                    <p className="m-t-10" style={{color:"#6499E1"}}>{t('eipLabel4')}</p>
                </div>

                <div className="display-flex just-cont-fend align-center pd20">
                    <button className="bt-btn-D-B font-s-15" style={{padding:"10px 20px"}}
                            onClick={closeMailInputPopup}>
                        <div>{t(`myPc15`)}</div>
                    </button> {/*취소버튼*/}

                    <button className="bt-btn-B font-s-15 m-l-10" style={{padding:"10px 20px"}}
                            onClick={() => handleSubmit()} disabled={isSaveBtDisabled}>
                        {isSaveBtLoading ? (
                            <div className="spinner"></div>
                        ) : (
                            <div>{t(`saveBt`)}</div>
                        )}
                    </button> {/*저장버튼*/}
                </div>

            </div>
        </div>
    )
}

export default EmailInputPopup