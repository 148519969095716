import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from '../../services/axiosInstance';
import maCateImg from "../../assets/img/m-a-cate.png";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import * as XLSX from "xlsx";
import excel from "exceljs";
import excelLogo from "../../assets/img/HeaderLogo.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import CommonLoading from "../../components/web/modal/CommonLoading";

function AdminOutAreaZipCodePg() {

    /**region DataTable */

    const [areaData, setAreaData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const [isLoading, setIsLoading] = useState(false);

    const [excelDatafe, setExcelDatafe] = useState(null);
    const [selectedSheetfe, setSelectedSheetfe] = useState(null);

    const [alertShow2, setAlertShow2] = useState(false); /*FedEx-E*/
    const msgConfirmData1 = "기존 데이터에서 덮어 씌우시겠습니까?";
    const msgConfirmData2 = "저장 완료시 기존 정보에서 덮어씌워집니다.";
    const alert2 = (() => {
        setAlertShow2(true)
    })
    const modalCancel2 = useCallback(() => {
        setAlertShow2(false);
    })

    const excelUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesfe = workbook.SheetNames;
            setSelectedSheetfe(sheetNamesfe[0]);
            setExcelDatafe(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const sheetSelect = (event) => {
        const sheetNamefe = event.target.value;
        setSelectedSheetfe(sheetNamefe);
    };

    const getSheetDatafe = () => {
        if (!selectedSheetfe || !excelDatafe) {
            return [];
        }
        const worksheet = excelDatafe.Sheets[selectedSheetfe];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(1).map(row => row.slice(1));
    };

    const sheetDatafe = getSheetDatafe();
    const fileSelectfe = useRef(null);

    const transposeData = (data) => {
        if (data.length === 0) return [];

        const headers = data[0];
        return headers.slice(1).map((_, colIndex) => {
            return data.map(row => row[colIndex + 1]);
        });
    };

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [areaData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (areaData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(15);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    /** endregion */

    /** region Event  */

    // region EXCEL DOWN

    const excelDown = async () => {
            try {
                const workbook = new excel.Workbook();
                const sheetOne = workbook.addWorksheet('Sheet1');

                sheetOne.columns = [
                    { header: '호주', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '브라질', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '말레이시아', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '일본', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '캐나다', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '필리핀', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '태국', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '인도네시아', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '이탈리아', width: 11, style: { alignment: { horizontal: 'center' } } },
                    { header: '이스라엘', width: 11, style: { alignment: { horizontal: 'center' } } },
                ];

                sheetDatafe.slice(1).forEach((row) => {
                    sheetOne.addRow(row);
                });

                const data = await workbook.xlsx.writeBuffer();
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                const fileName = `FedEx_서비스외지역_${new Date().toISOString().slice(0, 10)}.xlsx`;
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("엑셀 파일 다운로드 실패:", error);
            }
        };

    // endregion

    // region EXCEL UPLOAD

    const saveExcelFile = async () => {
        try {
            if (isLoading) return;

            setIsLoading(true);

            const sheetData = XLSX.utils.sheet_to_json(
                excelDatafe.Sheets[selectedSheetfe],
                { header: 1 }
            );
            if (!sheetData || sheetData.length === 0) {
                console.error("엑셀 데이터가 없습니다.");
                return;
            }
            const headerRow = sheetData[0];
            const dataRows = sheetData.slice(1);

            const countryMapping = {
                '호주': 'AU',
                '브라질': 'BZ',
                '말레이시아': 'MY',
                '일본': 'JP',
                '캐나다': 'CA',
                '필리핀': 'PH',
                '태국': 'TH',
                '인도네시아': 'ID',
                '이탈리아': 'IT',
                '이스라엘': 'IL'
            };

            const groupedData = {};

            headerRow.forEach((countryName, colIndex) => {
                const countryCode = countryMapping[countryName];

                dataRows.forEach((row) => {
                    let zipCode = row[colIndex];
                    if (zipCode) {
                        if (countryCode === 'CA' && zipCode.length >= 3) {
                            zipCode = zipCode.substring(0, 3);
                        }
                        const key = `${countryCode}-${zipCode}`;
                        if (!groupedData[key]) {
                            groupedData[key] = {
                                countryCode,
                                zipCode,
                                useFl: "Y"
                            };
                        }
                    }
                });
            });

            const excelDataList = Object.values(groupedData);

            await axios.post('/zipcode/fedexOutAreaDataSave', excelDataList, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': LCAT
                },
            });
            setAlertShow2(false);
            setSelectedSheetfe(null);
            setIsLoading(false);
            fedexOutAreaSelect();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // endregion

    useEffect(() => {
        fedexOutAreaSelect();
    }, [])

    const countryMap = {
        AU: "호주",
        BZ: "브라질",
        MY: "말레이시아",
        JP: "일본",
        CA: "캐나다",
        PH: "필리핀",
        TH: "태국",
        ID: "인도네시아",
        IT: "이탈리아",
        IL: "이스라엘"
    };

    const fedexOutAreaSelect = () => {
        axios.get('/zipcode/fedexAreaSelect', {
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAreaData(response.data);
            })
            .catch((error) => {

            });
    };

    /** endregion */

    return (
        <div className="AdminEmployeeInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">서비스 외 배송지역 관리</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn font-t-cont">
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div className="m-l-10" style={{position: "relative"}}>
                                    <input className="font-s-16" type="file" onChange={excelUpload} ref={fileSelectfe}
                                           style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                    <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectfe.current.click()}
                                            style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                    </button>
                                </div>
                                <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                {selectedSheetfe && (
                                    <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetfe.value} onChange={sheetSelect}
                                            style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                        {excelDatafe.SheetNames.map((sheetName) => (
                                            <option key={sheetName} value={sheetName}>
                                                {sheetName}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDown}>EXCEL[양식]</button>
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert2}>저장</button>
                        </div>
                    </div>
                    <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                    <div className="div-tb m-b-20">
                        <div className="div-tb-head ht-60">
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>국가명</p></div>
                            <div className="wd-110"><p>우편번호</p></div>
                            <div className="wd-110"><p>등록여부</p></div>
                            <div className="wd-150"><p>등록일시</p></div>
                        </div>
                        {areaData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div className="wd-40"><p>{i + 1}</p></div>
                                <div className="font-b wd-150"><p>{countryMap[cData.countryCode] || cData.countryCode}</p></div>
                                <div className="wd-110"><p>{cData.zipCode}</p></div>
                                <div className="font-b wd-100"><p>{cData.useFl}</p></div>
                                <div className="wd-150"><p>{cData.regTime}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && areaData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {isLoading && <CommonLoading/>}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={saveExcelFile} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminOutAreaZipCodePg
