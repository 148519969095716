import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from '../../services/axiosInstance';
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";

function MAdminCancelItemPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        select();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const [selectDepConfirmAllCheckBox, setSelectDepConfirmAllCheckBox] = useState(false);
    const [selectDepConfirmCheckData, setSelectDepConfirmCheckData] = useState([]);

    const handleSelectDepConfirmAllCheckBox = e => {
        setSelectDepConfirmAllCheckBox(e.target.checked);
        if (e.target.checked) {
            const filtered = data.filter(dep => dep.status === "0");
            setSelectDepConfirmCheckData(filtered);
        } else {
            setSelectDepConfirmCheckData([]);
        }
    };

    const handleSelectDepConfirmCheckBox = dep => {
        if (selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)) {
            setSelectDepConfirmCheckData(selectDepConfirmCheckData.filter(item => item.seqNum !== dep.seqNum));
        } else {
            setSelectDepConfirmCheckData([...selectDepConfirmCheckData, dep]);
        }
    };

    // const checkBoxChange = (id) => {
    //     setChecked((prevSelectedData) => {
    //         if (prevSelectedData.includes(id)) {
    //             return prevSelectedData.filter((item) => item !== id);
    //         } else {
    //             return [...prevSelectedData, id];
    //         }
    //     });
    // };
    //
    // const allcheckBoxChange = (e) => {
    //     let visibleData = data.slice(0, displayLimit);
    //     if (e.target.checked) {
    //         const visibleIds = visibleData.map((item) => item.seqNum);
    //         setChecked(visibleIds);
    //     } else {
    //         setChecked([]);
    //     }
    // };

    //endregion

    //region Alert

    //endregion

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        return (
            cData?.userNm?.toLowerCase().includes(searchInput?.toLowerCase()) ||
            cData?.userId?.toLowerCase().includes(searchInput?.toLowerCase())
        );
    });

    /** endregion */

    /** region Event */

    const [depConfirmComplShow, setDepConfirmComplShow] = useState(false);
    const depConfirmComplMsg1 = "예치금을 지급/환급 처리하시겠습니까?"
    const depConfirmComplMsg2 = "처리 후 복구할 수 없습니다"
    const closeDepConfirmCompl = (() => {
        setDepConfirmComplShow(false);
    })

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '/member/selectDepWaiting',
            params: {
                perPg: perPg
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setData(response.data)
            })
            .catch(() => {});
    };

    const modifyData = () => {
        axios({
            method: 'post',
            url: '/member/modifyDepConfirm',
            data: selectDepConfirmCheckData
        })
            .then((response) => {
                const responseSeqNums = response.data.map((item) => item.seqNum);
                const updatedData = data.filter((item) => !responseSeqNums.includes(item.seqNum));
                setData(updatedData);
                closeDepConfirmCompl();
            })
            .catch((error) => {
                console.log("adminErr[02] : ", error)
            });
    };

    /** endregion */

    return (
        <div id="m-a-cancelItem-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">예치금대기</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-header-event-combo">
                            <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                        </div>
                        <div className="m-a-header-event-btn font-t-cont font-s-15">
                            <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={()=> setDepConfirmComplShow(true)}>확인</button>
                        </div>
                    </div>
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-search">
                            <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={selectDepConfirmAllCheckBox}
                                       onChange={handleSelectDepConfirmAllCheckBox}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-100"><p>입금완료일</p></div>
                            <div className="wd-180"><p>아이디</p></div>
                            <div className="wd-120"><p>예금주(ID)</p></div>
                            <div className="wd-430"><p>계좌</p></div>
                            <div className="wd-100"><p>금액(&#8361;)</p></div>
                            <div className="wd-70"><p>구분</p></div>
                            <div className="wd-70"><p>상태</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((dep, i) => (
                            <div key={i} className="m-a- div-tb-body ht-70">
                                <div className="wd-40">
                                    {dep.status === "0" ?
                                        <input className="m-a-admin-input-chkBox m-0-a" type={"checkbox"}
                                               checked={selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)}
                                               onChange={() => handleSelectDepConfirmCheckBox(dep)}/> : null
                                    }

                                </div>
                                <div className="wd-40"><p className="font-b">{i+1}</p></div>
                                <div className="wd-100"><p className="font-b">{formatDate(dep.regDate)}</p></div>
                                <div className="wd-180"><p>{dep.userId}</p></div>
                                <div className="wd-120"><p>{dep.name}</p></div>
                                <div style={{width: "430px", display: "flex", flexDirection: "row", paddingTop: "24px"}}>
                                    {dep.currency ?
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <p>{dep.account} -&nbsp;</p>
                                            <p className="font-b" style={{color: "darkorange"}}>{dep.dollarPrice}</p>
                                            <p style={{fontSize: "11px", marginTop: "3px"}}>&nbsp;{dep.currency}</p>
                                        </div>
                                        :
                                        <p>{dep.account}</p>
                                    }
                                </div>
                                <div className="wd-100"><p>{dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                <div className="wd-70">{dep.chargFl ? <p>충전</p> : <p>환급</p>}</div>
                                <div className="wd-70">
                                    {dep.status === "0" ?
                                <p className="font-b" style={{color: "#7C9EDC"}}>대기</p>
                                :
                                dep.status === "1" ?
                                    <p className="font-b" style={{color: "#A3E98C"}}>완료</p>
                                    :
                                    <p className="font-b" style={{color: "#EB939D"}}>취소</p>
                            }
                                </div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l-3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            { depConfirmComplShow ?
                <MsgSelectModal depConfirmComplShow={depConfirmComplShow} depConfirmComplMsg1={depConfirmComplMsg1} depConfirmComplMsg2={depConfirmComplMsg2}
                                closeDepConfirmCompl={closeDepConfirmCompl} modifyDepConfirmCompl={modifyData}/>
                : null
            }
        </div>
    );
}

export default MAdminCancelItemPg
