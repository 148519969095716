import React from "react";
import commonClose from "../../../assets/img/commonClose.png";

function SaveMsgModal({message}) {
    return(
        <div className="position-fixed wd-100pc ht-100pc" style={{zIndex: 9, top: 0, left: 0, background: "rgba(70, 70, 70, 0.25)"}}>
            <div className="position-absolute display-flex just-cont-center align-center text-center wd-140 ht-65 border-rd8"
                style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#707070",}}>
                <p className="font-s-18 font-t-cont font-c-fff" style={{color: "#FFFFFF"}}>{message}</p>
            </div>
        </div>
    )
}

export default SaveMsgModal
