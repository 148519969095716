import React from 'react'
import '../../../css/web/UserAddrBook.css';
import commonClose from '../../../assets/img/commonClose.png'
import chatFileDownload from '../../../assets/img/chatFileDownload.png'
import chatExcelImg from "../../../assets/img/chatExcelImg.png";
import chatFileImg from "../../../assets/img/chatFolderImg.png";

function ChatFileDown({ selectedClickSt, clickFileClose, selectedClickFile, downloadFile, nonSelectedClickSt, nonClickFileClose, nonSelectedClickFile, nonDownloadFile }) {

    const renderClickFile = () => {
        const isImage = selectedClickFile.fileUrl.endsWith('.png') || selectedClickFile.fileUrl.endsWith('.PNG')
            || selectedClickFile.fileUrl.endsWith('.jpg')  || selectedClickFile.fileUrl.endsWith('.JPG') ||
            selectedClickFile.fileUrl.endsWith('.jpeg') || selectedClickFile.fileUrl.endsWith('.JPEG')
            selectedClickFile.fileUrl.endsWith('.gif') || selectedClickFile.fileUrl.endsWith('.GIF');
        const isVideo = selectedClickFile.fileUrl.endsWith('.mp4') || selectedClickFile.fileUrl.endsWith('.avi');
        const isPdf = selectedClickFile.fileUrl.endsWith('.pdf');

        if (isImage) {
            return <img style={{maxWidth:"550px", maxHeight:"400px", borderRadius:"4px"}} alt="" src={selectedClickFile.fileUrl}/>;
        }

        if (isVideo) {
            return <video style={{maxWidth:"550px", maxHeight:"400px", borderRadius:"4px"}} controls src={selectedClickFile.fileUrl}></video>;
        }

        if (isPdf) {
            return <object style={{maxWidth:"550px", maxHeight:"400px", borderRadius:"4px"}} type="application/pdf" data={selectedClickFile.fileUrl}></object>;
        }

        let fileExtension = selectedClickFile.fileUrl.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{maxWidth:"600px", maxHeight:"400px", borderRadius:"4px"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{maxWidth:"600px", maxHeight:"400px", borderRadius:"4px"}} alt="" src={chatFileImg}/>;
    };

    const nonRenderClickFile = () => {
        const isImage = nonSelectedClickFile.fileUrl.endsWith('.png') || nonSelectedClickFile.fileUrl.endsWith('.PNG')
            || nonSelectedClickFile.fileUrl.endsWith('.jpg')  || nonSelectedClickFile.fileUrl.endsWith('.JPG') ||
            nonSelectedClickFile.fileUrl.endsWith('.jpeg') || nonSelectedClickFile.fileUrl.endsWith('.JPEG')
        nonSelectedClickFile.fileUrl.endsWith('.gif') || nonSelectedClickFile.fileUrl.endsWith('.GIF');
        const isVideo = nonSelectedClickFile.fileUrl.endsWith('.mp4') || nonSelectedClickFile.fileUrl.endsWith('.avi');
        const isPdf = nonSelectedClickFile.fileUrl.endsWith('.pdf');

        if (isImage) {
            return <img style={{maxWidth:"550px", maxHeight:"400px", borderRadius:"4px"}} alt="" src={nonSelectedClickFile.fileUrl}/>;
        }

        if (isVideo) {
            return <video style={{maxWidth:"550px", maxHeight:"400px", borderRadius:"4px"}} controls src={nonSelectedClickFile.fileUrl}></video>;
        }

        if (isPdf) {
            return <object style={{maxWidth:"550px", maxHeight:"400px", borderRadius:"4px"}} type="application/pdf" data={nonSelectedClickFile.fileUrl}></object>;
        }

        let fileExtension = nonSelectedClickFile.fileUrl.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{maxWidth:"600px", maxHeight:"400px", borderRadius:"4px"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{maxWidth:"600px", maxHeight:"400px", borderRadius:"4px"}} alt="" src={chatFileImg}/>;
    };

    return (
        <div className="bookContainer" style={{display:"flex", zIndex:2000}}>
            {/*<div className="bookFrame" style={{width:"700px", height:"600px", display:"flex", left:"calc(50vw - 20%)", top:"calc(25vh - 7%)", borderRadius:"5px"}}>*/}
            <div className="bookFrame-new-img-m">
                <div style={{width:"100%", height:"100%"}}>
                    <div style={{height:"10%", display:"flex", justifyContent:"center"}}>
                            <div style={{
                                height: "100%",
                                width: "90%",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "15px"
                            }}>
                                <p style={{fontSize: "15px"
                                }}>{nonSelectedClickSt ? nonSelectedClickFile && nonSelectedClickFile.fileNm :
                                    selectedClickFile ? selectedClickFile && selectedClickFile.fileNm : null}</p>
                                <p style={{
                                    color: "darkgray",
                                    marginLeft: "10px",
                                    fontSize: "14px"
                                }}>{nonSelectedClickSt ? nonSelectedClickFile && nonSelectedClickFile.regTime :
                                    selectedClickFile ? selectedClickFile && selectedClickFile.regTime : null}</p>
                            </div>
                        <div onClick={nonSelectedClickSt ? nonClickFileClose : clickFileClose} style={{height:"100%", width:"10%", display:"flex", justifyContent:"flex-end",
                            alignItems:"center", paddingRight:"15px", cursor:"pointer"}}>
                            <img alt='' style={{width:"40px", height:"40px", opacity:0.6}} src={commonClose}/>
                        </div>
                    </div>
                    <div style={{height:"80%", backgroundColor:"#FFF9EF", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        {nonSelectedClickSt ? nonRenderClickFile() : renderClickFile()}
                    </div>
                    <div style={{height:"10%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        {nonSelectedClickSt ?
                            <div onClick={() => nonDownloadFile(nonSelectedClickFile.chatKey, nonSelectedClickFile.fileNm, "chat")}
                                 style={{cursor: "pointer"}}>
                                <img alt='' style={{width: "25px", height: "25px", opacity: "0.3"}}
                                     src={chatFileDownload}/>
                            </div>
                            :
                            <div onClick={() => downloadFile(selectedClickFile.chatKey, selectedClickFile.fileNm, "chat")}
                                 style={{cursor: "pointer"}}>
                                <img alt='' style={{width: "25px", height: "25px", opacity: "0.3"}}
                                     src={chatFileDownload}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatFileDown
