import React, {useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import '../../css/mobile/MAdminDashBoard.css';
import axios from '../../services/axiosInstance';
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminYmdSmallEvent from "./MAdminYmdSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";

function MAdminDepositSetPg() {

    /** region DataTable */

    const [depHistoryList, setDepHistoryList] = useState([]);
    const [ptHistoryList, setPtHistoryList] = useState([]);
    const [cpHistoryList, setCpHistoryList] = useState([]);
    const [depConfirmHistoryList, setDepConfirmHistoryList] = useState([]);
    const token = localStorage.getItem('adminToken');

    /** endregion */

    /** region Function */

    const [tabSt, setTabSt] = useState('');
    const sliderWrapperRef = useRef(null);
    const handleSelectTabs = ((key) => {
        setTabSt(key);
    })

    //region ScrollPagination

    const [displayLimitDep, setDisplayLimitDep] = useState(10);
    const [displayLimitPt, setDisplayLimitPt] = useState(10);
    const [displayLimitCp, setDisplayLimitCp] = useState(10);
    const [displayLimitDepCon, setDisplayLimitDepCon] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [depHistoryList.length, displayLimitDep, ptHistoryList.length, displayLimitPt, cpHistoryList.length, displayLimitCp, depConfirmHistoryList.length, displayLimitDepCon]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (depHistoryList.length > displayLimitDep && displayLimitDep < 30
            || ptHistoryList.length > displayLimitPt && displayLimitPt < 30
            || cpHistoryList.length > displayLimitCp && displayLimitCp < 30
            || depConfirmHistoryList.length > displayLimitDepCon && displayLimitDepCon < 30) {
            setDisplayLimitDep(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimitDep(prevLimit => prevLimit + perPg);
        setDisplayLimitPt(prevLimit => prevLimit + perPg);
        setDisplayLimitCp(prevLimit => prevLimit + perPg);
        setDisplayLimitDepCon(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimitDep(newPerPage);
        setDisplayLimitPt(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimitDep(perPg);
        setDisplayLimitPt(perPg);
    }, [perPg]);

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    // endregion

    //region slide-card

    useEffect(() => {
        if (tabSt !== '쿠폰') return;

        const sliderWrapper = document.querySelector('.m-a-slider-wrapper');
        if (!sliderWrapper) { return; }

        let isDragging = false;
        let startPosition = 0;
        let currentTranslate = 0;
        let prevTranslate = 0;
        let animationID = 0;
        let currentIndex = 0;

        const slides = document.querySelectorAll('.m-a-card-slider');
        const slideWidth = sliderWrapper.clientWidth;
        const indicators = document.querySelectorAll('.m-a-indicator');

        // const sliderWrapper = sliderWrapperRef.current;
        // if (!sliderWrapper) return;
        //
        // let isDragging = false;
        // let startPosition = 0;
        // let currentTranslate = 0;
        // let prevTranslate = 0;
        // let animationID = 0;
        // let currentIndex = 0;
        //
        // const slides = sliderWrapper.querySelectorAll('.m-a-card-slider');
        // const slideWidth = sliderWrapper.clientWidth;
        // const indicators = sliderWrapper.querySelectorAll('.m-a-indicator');

        const touchStart = (event) => {
            isDragging = true;
            startPosition = event.touches[0].clientX;
            animationID = requestAnimationFrame(animation);
        };

        const touchEnd = () => {
            isDragging = false;
            cancelAnimationFrame(animationID);

            const movedBy = currentTranslate - prevTranslate;

            if (movedBy < -50 && currentIndex < slides.length - 1) {
                currentIndex += 1;
            } else if (movedBy > 50 && currentIndex > 0) {
                currentIndex -= 1;
            }

            setPositionByIndex();
            updateIndicators();
        };

        const touchMove = (event) => {
            if (isDragging) {
                const currentPosition = event.touches[0].clientX;
                currentTranslate = prevTranslate + currentPosition - startPosition;
            }
        };

        const animation = () => {
            setSliderPosition();
            if (isDragging) requestAnimationFrame(animation);
        };

        const setSliderPosition = () => {
            sliderWrapper.style.transform = `translateX(${currentTranslate}px)`;
        };

        const setPositionByIndex = () => {
            currentTranslate = currentIndex * -slideWidth;
            prevTranslate = currentTranslate;
            setSliderPosition();
        };

        const updateIndicators = () => {
            indicators.forEach((indicator, index) => {
                indicator.classList.toggle('active', index === currentIndex);
            });
        };

        sliderWrapper.addEventListener('touchstart', touchStart);
        sliderWrapper.addEventListener('touchend', touchEnd);
        sliderWrapper.addEventListener('touchmove', touchMove);

        updateIndicators();

        return () => {
            sliderWrapper.removeEventListener('touchstart', touchStart);
            sliderWrapper.removeEventListener('touchend', touchEnd);
            sliderWrapper.removeEventListener('touchmove', touchMove);
        };
    }, [tabSt]);

    //endregion slide-card

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(oneMonthAgo));
        setEndDate(formatDateString(today));
    }, []);

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [checkedPoint, setCheckedPoint] = useState([]);
    const [checkedCp, setCheckedCp] = useState([]);

    const checkBoxDepChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxDepChange = (e) => {
        let visibleData = searchDepData.slice(0, displayLimitDep);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    const checkBoxPointChange = (id) => {
        setCheckedPoint((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxPointChange = (e) => {
        let visibleData = searchPtData.slice(0, displayLimitPt);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setCheckedPoint(visibleIds);
        } else {
            setCheckedPoint([]);
        }
    };

    const checkBoxCpChange = (id) => {
        setCheckedCp((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxCpChange = (e) => {
        let visibleData = searchCpData.slice(0, displayLimitCp);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setCheckedCp(visibleIds);
        } else {
            setCheckedCp([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchDepData = depHistoryList.filter((cData) => {
        return (
            cData?.memberDTO?.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.memberDTO?.id.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    const searchPtData = ptHistoryList.filter((cData) => {
        return (
            cData?.memberDTO?.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.memberDTO?.id.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    const searchCpData = cpHistoryList.filter((cData) => {
        return (
            cData?.memberDTO?.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.memberDTO?.id.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    const searchDepConData = depConfirmHistoryList.filter((cData) => {
        return (
            cData?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
            cData?.userId?.toLowerCase().includes(searchInput.toLowerCase())
        );
    });

    //endregion

    // 예치금 내역조회
    const [depStartDate, setDepStartDate] = useState('');
    const [depEndDate, setDepEndDate] = useState('');
    const depStartHandle = (e) => {
        setDepStartDate(e.target.value);
    };
    const depEndHandle = (e) => {
        setDepEndDate(e.target.value);
    };

    // 적립금 내역조회
    const [ptStartDate, setPtStartDate] = useState('');
    const [ptEndDate, setPtEndDate] = useState('');
    const ptStartHandle = (e) => {
        setPtStartDate(e.target.value);
    };
    const ptEndHandle = (e) => {
        setPtEndDate(e.target.value);
    };

    // 쿠폰 내역조회
    const [cpStartDate, setCpStartDate] = useState('');
    const [cpEndDate, setCpEndDate] = useState('');
    const cpStartHandle = (e) => {
        setCpStartDate(e.target.value);
    };
    const cpEndHandle = (e) => {
        setCpEndDate(e.target.value);
    };

    // 예치금신청 내역조회
    const [depConfirmStartDate, setDepConfirmStartDate] = useState('');
    const [depConfirmEndDate, setDepConfirmEndDate] = useState('');
    const depConfirmStartHandle = (e) => {
        setDepConfirmStartDate(e.target.value);
    };
    const depConfirmEndHandle = (e) => {
        setDepConfirmEndDate(e.target.value);
    };

    // 예치금 & 적립금 공통지급
    const [selectedDepSerchCondition, setSelectedDepSerchCondition] = useState('userNm');
    const [depMemberInput, setDepMemberInput] = useState('');
    const [serchDepMemberList, setSerchDepMemberList] = useState([]);
    const [selectedDepMember, setSelectedDepMember] = useState(null);
    const [depositPayContent, setDepositPayContent] = useState('');
    const [depSumFl, setDepSumFl] = useState('');
    const [depositPrice, setDepositPrice] = useState('');

    const [depConditionPopup, setDepConditionPopup] = useState(false);
    const [depSumFlPopup, setDepSumFlPopup] = useState(false);
    const [depMemberPopup, setDepMemberPopup] = useState(false);

    const handleDepMemberClick = (member) => {
        setSelectedDepMember(member.id);
        setDepMemberPopup(false);
    };

    const selectedMember = serchDepMemberList.find(member => member.id === selectedDepMember);
    const selectedMemberDisplay = selectedMember ? `${selectedMember.id} / ${selectedMember.userName}` : '';

    const handleDepSumFlClick = (value) => {
        setDepSumFl(value === 'plus' ? '2' : '3');
        setDepSumFlPopup(false);
    };

    const depSumFlDisplayName = depSumFl === '2' ? '지급[+]' : '회수[-]';

    const handleDepConditionClick = (condition) => {
        setSelectedDepSerchCondition(condition);
        setDepConditionPopup(false);
    };

    const conditionDisplayName = selectedDepSerchCondition === 'userNm' ? '고객명' : '아이디';

    const selectChangeDepCondition = (event) => {
        const value = event.target.value;
        if (value === '고객명') {
            setSelectedDepSerchCondition("userNm");
        } else if (value === '아이디') {
            setSelectedDepSerchCondition("userId");
        }
    };

    const depMemberInputChange = (e) => {
        setDepMemberInput(e.target.value);
    };

    const depositSerchKeyPress = (e) => {
        const key = e.key || e.code || e.keyCode;
        if (key === 'Enter' || key === 'Done' || key === 'Go' || key === 'Search' || key === 13) {
            selectDepositSerchMember();
        }
    };

    const depositSerchInput = (e) => {
        if (e.nativeEvent.inputType === "insertLineBreak") {
            selectDepositSerchMember();
        }
    };

    const selectedDepMemberChange = ((e) => {
        setSelectedDepMember(e.target.value);
    })

    const depPayContentInputChange = (e) => {
        setDepositPayContent(e.target.value);
    };

    const selectDepositSumFlChange = (event) => {
        setDepSumFl(event.target.value === 'plus' ? '2' : '3');
    };

    const depositPriceChange = (e) => {
        setDepositPrice(e.target.value);
    };

    // 예치금 type 커스텀 셀렉트
    const selectedDepTypeList =[
        { value: '4', label: "구분" },
        { value: '1', label: "사용" },
        { value: '0', label: "취소" },
        { value: '2', label: "지급" },
        { value: '3', label: "회수" }
    ];
    const [selectedDepType, setSelectedDepType] = useState(selectedDepTypeList[0]);
    const [selectedDepTypeShow, setSelectedDepTypeShow] = useState(false);
    const handleDepTypeChange = (type) => {
        setSelectedDepType(type);
        setSelectedDepTypeShow(false);
    };
    // 여기까지 커스텀 셀렉트

    // 적립금 type 커스텀 셀렉트
    const selectedPtTypeList =[
        { value: '4', label: "구분" },
        { value: '1', label: "사용" },
        { value: '0', label: "취소" },
        { value: '2', label: "지급" },
        { value: '3', label: "회수" }
    ];
    const [selectedPtType, setSelectedPtType] = useState(selectedPtTypeList[0]);
    const [selectedPtTypeShow, setSelectedPtTypeShow] = useState(false);
    const handlePtTypeChange = (type) => {
        setSelectedPtType(type);
        setSelectedPtTypeShow(false);
    };
    // 여기까지 커스텀 셀렉트

    // 쿠폰 개별지급
    const selectedIndiviSerchCondition = ["고객명", "아이디"]
    const [selectedCpSerchCondition, setSelectedCpSerchCondition] = useState('userNm');
    const [cpMemberInput, setCpMemberInput] = useState('');
    const [serchCpMemberList, setSerchCpMemberList] = useState([]);
    const [selectedCpMember, setSelectedCpMember] = useState(null);
    const [selectedIndiviCpMemberNm, setSelectedIndiviCpMemberNm] = useState(null);

    const [cpConditionPopup, setCpConditionPopup] = useState(false);
    const [cpMemberPopup, setCpMemberPopup] = useState(false);

    const selectedCMember = serchCpMemberList.find(member => member.id === selectedCpMember);
    const selectedCMemberDisplay = selectedCMember ? `${selectedCMember.id} / ${selectedCMember.userName}` : '';

    const handleCpConditionClick = (condition) => {
        setSelectedCpSerchCondition(condition);
        setCpConditionPopup(false);
    };

    const handleCpMemberClick = (member) => {
        setSelectedCpMember(member.id);
        setCpMemberPopup(false);
    };

    const cpMemberInputChange = (e) => {
        setCpMemberInput(e.target.value);
    };

    const cpSerchKeyPress = (e) => {
        const key = e.key || e.code || e.keyCode;
        if (key === 'Enter' || key === 'Done' || key === 'Go' || key === 'Search' || key === 13) {
            selectCpSerchMember();
        }
    };

    const cpSerchInput = (e) => {
        if (e.nativeEvent.inputType === "insertLineBreak") {
            selectCpSerchMember();
        }
    };

    const toggleIndiviCpUserNmShow = () => {
        setIndiviCpUserNmShow(!indiviCpUserNmShow);
    };
    const toggleIndiviCpReturnMemberShow = () => {
        setIndiviCpReturnMemberShow(!indiviCpReturnMemberShow);
    };
    const selectedCpMemberChange = ((member) => {
        setSelectedCpMember(member.id);
        setSelectedIndiviCpMemberNm(member.userName);
        setIndiviCpReturnMemberShow(false);
    })
    const handleIndiviConditionChange = (value) => {
        setSelectedCpSerchCondition(value === "고객명" ? "userNm" : "userId");
        setIndiviCpUserNmShow(false);
    };

    // 개별쿠폰 조회 & 선택
    const [serchIndiviCpList, setSerchIndiviCpList] = useState([]);
    const [selectedIndiviCpNm, setSelectedIndiviCpNm] = useState(null);
    const [selectedIndiviCpPrice, setSelectedIndiviCpPrice] = useState(null);
    const [selectedIndiviCpSeqnum, setSelectedIndiviCpSeqnum] = useState(null);

    const [indiviCpListShow, setIndiviCpListShow] = useState(false);
    const indiviCpListRef = useRef(null);

    const toggleIndiviCpListShow = () => {
        setIndiviCpListShow(!indiviCpListShow);
    };

    const selectedCpListChange = ((member) => {
        setSelectedIndiviCpSeqnum(member.seqNum);
        setSelectedIndiviCpNm(member.cpNm);
        setSelectedIndiviCpPrice(member.cpPrice);
        setIndiviCpListShow(false);
    })

    const indiviCpInit = (() => {
        setSelectedCpSerchCondition('userNm');
        setCpMemberInput('');
        setSerchCpMemberList([]);
        setSelectedCpMember(null);
        setSelectedIndiviCpMemberNm(null);
    })

    // 일괄쿠폰 지급&삭제
    const [serchBatchCpList, setSerchBatchCpList] = useState([]);
    const [selectedBatchCpNm, setSelectedBatchCpNm] = useState(null);
    const [selectedBatchCpPrice, setSelectedBatchCpPrice] = useState(null);
    const [selectedBatchCpSeqnum, setSelectedBatchCpSeqnum] = useState(null);

    const [batchCpListShow, setBatchCpListShow] = useState(false);
    const batchCpListRef = useRef(null);

    const [deleteCpShowSt, setDeleteCpShowSt] = useState(false);
    const [minusBatchCpShowSt, setMinusBatchCpShowSt] = useState(false);
    const [plusBatchCpShowSt, setPlusBatchCpShowSt] = useState(false);

    const deleteCpMsg1 = "선택한 쿠폰을 삭제하시겠습니까?"
    const deleteCpMsg2 = "모든 고객님들에게서 삭제됩니다"
    const minusBatchCpMsg1 = "선택한 쿠폰을 회수하시겠습니까?"
    const minusBatchCpMsg2 = "모든 고객님들에게서 회수됩니다"
    const plusBatchCpMsg1 = "선택한 쿠폰을 지급하시겠습니까?"
    const plusBatchCpMsg2 = "모든 고객님들에게 지급됩니다"

    const closeDeleteCpShow = (() => {
        setDeleteCpShowSt(false);
    })
    const closeMinusBatchCpShow = (() => {
        setMinusBatchCpShowSt(false);
    })
    const closePlusBatchCpShow = (() => {
        setPlusBatchCpShowSt(false);
    })

    const toggleBatchCpListShow = () => {
        setBatchCpListShow(!batchCpListShow);
    };

    const selectedBatchCpListChange = ((member) => {
        setSelectedBatchCpNm(member.seqNum);
        setSelectedBatchCpNm(member.cpNm);
        setSelectedBatchCpPrice(member.cpPrice);
        setBatchCpListShow(false);
    })

    const [newCpNm, setNewCpNm] = useState('');
    const [newCpPrice, setNewCpPrice] = useState('');
    const [newCpExpDate, setNewCpExpDate] = useState('');

    const handleNewCpNm = ((e) => {
        setNewCpNm(e.target.value);
    })

    const handleNewCpPrice = ((e) => {
        setNewCpPrice(e.target.value);
    })

    const handleNewCpExpDate = ((e) => {
        const input = e.target.value;

        if (/^\d{8}$/.test(input)) {
            const year = parseInt(input.substring(0, 4));
            const month = parseInt(input.substring(4, 6));
            const day = parseInt(input.substring(6, 8));
            const date = new Date(year, month - 1, day);

            if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
                const today = new Date();
                const todayFormatted = new Date(today.getFullYear(), today.getMonth(), today.getDate());

                if (date >= todayFormatted) {
                    setNewCpExpDate(input);
                } else {
                    alert('날짜가 오늘보다 이전일 수 없습니다.');
                }
            } else {
                alert('올바른 날짜 형식이 아닙니다.[520]');
            }
        } else if (e.length === 8) {
            alert('올바른 날짜 형식이 아닙니다.[521]');
        } else {
            setNewCpExpDate(input);
        }
    })

    const newCpInit = (() => {
        setNewCpNm('');
        setNewCpPrice('');
        setNewCpExpDate('');
    })

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const [selectDepConfirmAllCheckBox, setSelectDepConfirmAllCheckBox] = useState(false);
    const [selectDepConfirmCheckData, setSelectDepConfirmCheckData] = useState([]);

    const handleSelectDepConfirmAllCheckBox = e => {
        setSelectDepConfirmAllCheckBox(e.target.checked);
        if (e.target.checked) {
            const filtered = depConfirmHistoryList.filter(dep => dep.status === "0");
            setSelectDepConfirmCheckData(filtered);
        } else {
            setSelectDepConfirmCheckData([]);
        }
    };

    const handleSelectDepConfirmCheckBox = dep => {
        if (selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)) {
            setSelectDepConfirmCheckData(selectDepConfirmCheckData.filter(item => item.seqNum !== dep.seqNum));
        } else {
            setSelectDepConfirmCheckData([...selectDepConfirmCheckData, dep]);
        }
    };

    // 쿠폰 type 커스텀 셀렉트
    const selectedCpTypeList =[
        { value: '4', label: "구분" },
        { value: '1', label: "사용" },
        { value: '0', label: "취소" },
        { value: '2', label: "지급" },
        { value: '3', label: "회수" }
    ];
    const [selectedCpType, setSelectedCpType] = useState(selectedCpTypeList[0]);
    const [selectedCpTypeShow, setSelectedCpTypeShow] = useState(false);
    const handleCpTypeChange = (type) => {
        setSelectedCpType(type);
        setSelectedCpTypeShow(false);
    };
    // 여기까지 커스텀 셀렉트

    /** endregion */

    /** region Event */

    useEffect(() => {
        handleSelectTabs("예치금");
    }, [])

    useEffect(() => {
        switch (tabSt) {
            case "예치금":
                selectDepHistoryList();
                break;
            case "적립금":
                selectPtHistoryList();
                break;
            case "쿠폰":
                selectCpHistoryList()
                break;
            case "예치금신청":
                selectedAllDepHistory()
                break;
            default:
        }
    }, [tabSt]);

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setPtStartDate(formatDateString(oneMonthAgo));
        setPtEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (ptStartDate && ptEndDate && tabSt === "적립금") {
            selectPtHistoryList();
        }
    }, [ptStartDate, ptEndDate, tabSt, selectedPtType])

    const selectPtHistoryList = (() => {
        const formattedStartDate = ptStartDate.replace(/-/g, '');
        const formattedEndDate = ptEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectPtHistoryList',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
                type: selectedPtType.value
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setPtHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setCpStartDate(formatDateString(oneMonthAgo));
        setCpEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (cpStartDate && cpEndDate && tabSt === "쿠폰") {
            selectCpHistoryList();
        }
    }, [cpStartDate, cpEndDate, tabSt, selectedCpType])

    const selectCpHistoryList = (() => {
        const formattedStartDate = cpStartDate.replace(/-/g, '');
        const formattedEndDate = cpEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectCpHistoryList',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
                type: selectedCpType.value
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setCpHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setDepConfirmStartDate(formatDateString(oneMonthAgo));
        setDepConfirmEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (depConfirmStartDate && depConfirmEndDate && tabSt === "예치금신청") {
            selectedAllDepHistory();
        }
    }, [depConfirmStartDate, depConfirmEndDate, tabSt])

    const selectedAllDepHistory = (() => {
        const formattedStartDate = depConfirmStartDate.replace(/-/g, '');
        const formattedEndDate = depConfirmEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectedAllDepHistory',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setDepConfirmHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const selectAllMstCp = (() => {
        axios({
            method: 'get',
            url: '/coupon/selectAllMstCp',
        })

            .then((response) => {
                const cp = response.data.map(cp => ({
                    seqNum: cp.seqNum,
                    cpNm: cp.couponNm,
                    cpPrice: cp.couponPrice,
                }));
                setSerchIndiviCpList(cp);
                setSelectedIndiviCpSeqnum(response.data[0].seqNum);
                setSelectedIndiviCpNm(response.data[0].couponNm);
                setSelectedIndiviCpPrice(response.data[0].couponPrice);

                setSerchBatchCpList(cp);
                setSelectedBatchCpSeqnum(response.data[0].seqNum);
                setSelectedBatchCpNm(response.data[0].couponNm);
                setSelectedBatchCpPrice(response.data[0].couponPrice);
            })
            .catch(() => {
                console.log("쿠폰조회 실패")
            })
    })

    const selectDepositSerchMember = (() => {
        axios({
            method: 'get',
            url: '/member/selectSerchMember',
            params: {
                condition: selectedDepSerchCondition,
                memberInput: depMemberInput
            }
        })

            .then((response) => {
                const members = response.data.map(member => ({
                    id: member.id,
                    userName: member.userName
                }));
                setSerchDepMemberList(members);
                setSelectedDepMember(response.data[0].id);
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    const depositInfoInit = (() => {
        setSelectedDepSerchCondition('userNm');
        setSerchDepMemberList([]);
        setDepMemberInput('')
        setSelectedDepMember(null);
        setDepositPayContent('');
        setDepSumFl('2');
        setDepositPrice('');
    })

    const saveDeposit = (() => {
        axios({
            method: 'post',
            url: '/member/saveDeposit',
            headers: {
                Authorization: token
            },
            data: {
                content: depositPayContent,
                depPrice: tabSt === "예치금" ? depositPrice : 0,
                ptPrice: tabSt === "적립금" ? depositPrice : 0,
                type: depSumFl,
            },
            params: {
                email: selectedDepMember,
                depFl: tabSt === "예치금"
            }
        })
            .then(() => {
                if (tabSt === "예치금") {
                    selectDepHistoryList();
                } else {
                    selectPtHistoryList();
                }
            })
            .catch(() => {
                console.log("지급실패")
            })
    })

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setDepStartDate(formatDateString(oneMonthAgo));
        setDepEndDate(formatDateString(today));
    }, []);

    useEffect(() => {
        if (depStartDate && depEndDate && tabSt === "예치금") {
            selectDepHistoryList();
        }
    }, [depStartDate, depEndDate, tabSt, selectedDepType])

    const selectDepHistoryList = (() => {
        const formattedStartDate = depStartDate.replace(/-/g, '');
        const formattedEndDate = depEndDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/member/selectDepHistoryList',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                tabSt: tabSt,
                type: selectedDepType.value
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setDepHistoryList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const selectCpSerchMember = (() => {
        axios({
            method: 'get',
            url: '/member/selectSerchMember',
            params: {
                condition: selectedCpSerchCondition,
                memberInput: cpMemberInput
            }
        })

            .then((response) => {
                const members = response.data.map(member => ({
                    id: member.id,
                    userName: member.userName
                }));
                setSerchCpMemberList(members);
                setSelectedCpMember(response.data[0].id);
                setSelectedIndiviCpMemberNm(response.data[0].userName);

            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    const [indiviCpUserNmShow, setIndiviCpUserNmShow] = useState(false);
    const [indiviCpReturnMemberShow, setIndiviCpReturnMemberShow] = useState(false);

    const indiviCpUserNmRef = useRef(null);
    const indiviCpReturnMemberRef = useRef(null);

    useEffect(() => {
        selectAllMstCp();

        function handleClickOutside(event) {
            if (indiviCpUserNmRef.current && !indiviCpUserNmRef.current.contains(event.target)) {
                setIndiviCpUserNmShow(false);
            }
            if (indiviCpReturnMemberRef.current && !indiviCpReturnMemberRef.current.contains(event.target)) {
                setIndiviCpReturnMemberShow(false);
            }
            if (indiviCpListRef.current && !indiviCpListRef.current.contains(event.target)) {
                setIndiviCpListShow(false);
            }
            if (batchCpListRef.current && !batchCpListRef.current.contains(event.target)) {
                setBatchCpListShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const [couponDupSt, setCouponDupSt] = useState(false);
    const closeCouponDup = () => {
        setCouponDupSt(false);
    }
    const saveIndiviCp = ((type) => {
        axios({
            method: 'post',
            url: '/coupon/saveIndiviCp',
            headers: {
                Authorization: token
            },
            params: {
                memberId: selectedCpMember,
                cpSeqnum: selectedIndiviCpSeqnum,
                type: type
            }
        })
            .then((response) => {
                const res = response.data;
                console.log("data : ", res)
                // 중복일 경우 메세지표시
                if (res === "dup") {
                    setCouponDupSt(true);
                } else {
                    selectCpHistoryList();
                    indiviCpInit();
                }
            })
            .catch(() => {
                console.log("쿠폰조회 실패")
            })
    })

    const [selectedBatchCp, setSelectedBatchCp] = useState(null);

    const handleBatchCpSelect = (cp) => {
        setSelectedBatchCp(cp);
        setBatchCpListShow(false);
    };

    const saveBatchCp = (sumFl) => {
        if (!selectedBatchCp) {
            console.log("선택된 쿠폰이 없습니다.");
            return;
        }

        axios({
            method: 'post',
            url: '/coupon/saveBatchCp',
            headers: {
                Authorization: token
            },
            data: {
                cpSeqnum: selectedBatchCp.seqNum,
                type: sumFl
            }
        })
            .then(() => {
                if (sumFl) {
                    setPlusBatchCpShowSt(false);
                } else {
                    setMinusBatchCpShowSt(false);
                }
                selectCpHistoryList();
            })
            .catch(() => {
                console.log("모두발급 실패");
            });
    };

    const deleteCoupon = (() => {
        axios({
            method: 'post',
            url: '/coupon/deleteCoupon',
            headers: {
                Authorization: token
            },
            params: {
                cpSeqnum: selectedBatchCpSeqnum,
            }
        })
            .then(() => {
                setDeleteCpShowSt(false);
                selectAllMstCp();
            })
            .catch(() => {
                console.log("삭제 실패")
            })
    })

    const saveNewCoupon = (() => {
        axios({
            method: 'post',
            url: '/coupon/saveNewCoupon',
            headers: {
                Authorization: token
            },
            data: {
                couponNm: newCpNm,
                couponPrice: newCpPrice,
                couponExpDate: newCpExpDate
            }
        })
            .then(() => {
                newCpInit();
                selectAllMstCp();
            })
            .catch(() => {
                console.log("쿠폰생성 실패")
            })
    })

    const modifyDepConfirm = (() => {
        axios({
            method: 'post',
            url: '/member/modifyDepConfirm',
            headers: {
                Authorization: token
            },
            data: selectDepConfirmCheckData
        })
            .then((response) => {
                setDepConfirmHistoryList((currentList) => {
                    return currentList.map(item => {
                        if (selectDepConfirmCheckData.some(selectedItem => selectedItem.seqNum === item.seqNum)) {
                            return response.data.find(dataItem => dataItem.seqNum === item.seqNum) || item;
                        } else {
                            return item;
                        }
                    });
                });
            })
            .catch(() => {
                console.log("예치금확인 실패")
            })
    })

    /** endregion */

    return (
        <div id="m-a-depositSet-pg-container">
            <div className="m-a-title-frame m-b-5">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">예적쿠관리</p>
            </div>
            <div className="m-a-tabs font-t-title font-s-16 p-t-15">
                <div className={`m-a-tab ${tabSt === '예치금' ? 'active' : ''}`} onClick={() => handleSelectTabs('예치금')}>
                    <p>예치금</p>
                </div>
                <div className={`m-a-tab ${tabSt === '예치금신청' ? 'active' : ''}`} onClick={() => handleSelectTabs('예치금신청')}>
                    <p>예치금신청</p>
                </div>
                <div className={`m-a-tab ${tabSt === '적립금' ? 'active' : ''}`} onClick={() => handleSelectTabs('적립금')}>
                    <p>적립금</p>
                </div>
                <div className={`m-a-tab ${tabSt === '쿠폰' ? 'active' : ''}`} onClick={() => handleSelectTabs('쿠폰')}>
                    <p>쿠폰</p>
                </div>
                <div className="m-a-tab-underline" style={{ left: `${['예치금', '예치금신청', '적립금', '쿠폰'].indexOf(tabSt) * 25}%` }}></div>
            </div>
            <div className="m-a-top-border-card">
                {tabSt === '예치금' &&
                    <div>
                        <div className="m-a-deposit-set-frame">
                            <div className="m-a-event-frame">
                                <div className="m-a-header-event display-flex flex-row just-cont-fend">
                                    <div className="m-a-header-event-btn font-t-cont font-s-15">
                                        <button className="m-a-commonBt m-a-commonBt-text wd-70 ht-40 m-r-10" onClick={depositInfoInit}>취소</button>
                                        <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={saveDeposit}>확인</button>
                                    </div>
                                </div>
                            </div>
                            <div className="display-flex flex-row wd-100pc">
                                <div className="display-flex flex-column font-t-cont font-c-default font-s-16 wd-10pc">
                                    <label className="p-t-10 m-b-70">검색</label>
                                    <label className="p-t-10 m-b-20">금액</label>
                                    <label className="p-t-7">내용</label>
                                </div>
                                <div className="display-flex flex-column font-t-cont font-c-default wd-90pc p-l-10">
                                    <div className="display-flex flex-row m-b-7">
                                        <div className="m-a-combo wd-30pc ht-45 font-s-15 m-a-flex-jc-start m-r-7" onClick={() => setDepConditionPopup(true)}>
                                            {conditionDisplayName}
                                            {depConditionPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setDepConditionPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">검색 조건 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDepSerchCondition === "userNm" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepConditionClick("userNm")}><p>고객명</p></div>
                                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDepSerchCondition === "userId" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepConditionClick("userId")}><p>아이디</p></div>
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setDepConditionPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="m-a-input-frame ht-45 wd-70pc">
                                            <input onChange={(e) => depMemberInputChange(e)} value={depMemberInput} onKeyDown={depositSerchKeyPress}
                                                   onInput={depositSerchInput} placeholder="검색조건 입력"
                                                   className="m-a-input font-s-15" type="text"/>
                                        </div>
                                    </div>
                                    <div className="m-b-7">
                                        <div className="m-a-combo wd-100pc ht-45 m-a-flex-jc-start font-s-15" onClick={() => setDepMemberPopup(true)}>
                                            {selectedMemberDisplay}
                                            {depMemberPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setDepMemberPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">회원 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            {serchDepMemberList.map((member, i) => (
                                                                <div key={i} className={`m-a-card m-t-10 m-b-10 ${selectedDepMember === member.id ? "m-a-selected-option" : ""}`}
                                                                     onClick={() => handleDepMemberClick(member)}>
                                                                    {member.id} / {member.userName}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setDepMemberPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="display-flex flex-row m-b-7">
                                        <div className="m-a-combo wd-30pc ht-45 font-s-15 m-a-flex-jc-start font-b m-r-7" onClick={() => setDepSumFlPopup(true)}>
                                            {depSumFlDisplayName}
                                            {depSumFlPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setDepSumFlPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">상태 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            <div className={`m-a-card m-t-10 m-b-10 ${depSumFl === '2' ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepSumFlClick("plus")}><p>지급[+]</p></div>
                                                            <div className={`m-a-card m-t-10 m-b-10 ${!depSumFl === '2' ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepSumFlClick("minus")}><p>회수[-]</p></div>
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setDepSumFlPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="m-a-input-frame ht-45 wd-70pc">
                                            <input onChange={(e) => depositPriceChange(e)} value={depositPrice} className="m-a-input font-s-15"
                                                   type="text" inputMode="numeric" pattern="\d*" maxLength="7"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="m-a-input-frame ht-45">
                                            <input onChange={depPayContentInputChange} value={depositPayContent} className="m-a-input wd-100pc font-s-14" type="text"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="display-flex flex-column">
                            <div className="m-a-event-frame">
                                <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                    <div className="m-a-header-event-combo">
                                        <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                            <div className="font-t-cont">{perPg}</div>
                                        </div>
                                        {popupVisible && (
                                            <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                                        )}
                                        <MAdminYmdSmallEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                                    </div>
                                </div>
                                <div className="m-a-header-event">
                                    {/*여기서부터 예치금 type 셀렉트*/}
                                        <div className="m-a-combo wd-30pc ht-40 font-s-15 m-a-flex-jc-start m-r-15"
                                             onClick={() => setSelectedDepTypeShow(true)}>
                                            {selectedDepType.label}
                                            {selectedDepTypeShow && (
                                                <div className="m-a-page-popup-container" onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedDepTypeShow(false);
                                                }}>
                                                    <div className="m-a-page-popup-frame"
                                                         onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">구분</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            {selectedDepTypeList.map((type, index) => (
                                                                <div key={index} onClick={() => handleDepTypeChange(type)}
                                                                 className={`m-a-card m-t-10 m-b-10 ${selectedDepType.value === index ? "m-a-selected-option" : ""}`}>
                                                                <p>{type.label}</p></div>
                                                                ))}
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setSelectedDepTypeShow(false);
                                                                        }}>
                                                                    <div
                                                                        className="m-a-colorBt-text font-t-cont">Close
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    {/*여기까지 커스텀셀렉트*/}
                                    <div className="m-a-header-event-search wd-70pc">
                                        <MAdminSearchEvent searchInput={searchInput}
                                                           handleSearchInputChange={handleSearchInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="m-a-tb-frame">
                                <div className="m-a-div-tb">
                                    <div className="m-a-div-tb-head ht-60">
                                        <div className="wd-40">
                                            <input className="m-a-admin-input-chkBox m-0-a" type="checkbox"
                                                   checked={checked.length === depHistoryList.length}
                                                   onChange={allcheckBoxDepChange}/>
                                        </div>
                                        <div className="wd-40"><p>번호</p></div>
                                        <div className="wd-150"><p>고객명</p></div>
                                        <div className="wd-150"><p>아이디</p></div>
                                        <div className="wd-100"><p>사용구분</p></div>
                                        <div className="wd-250"><p>내용</p></div>
                                        <div className="wd-100"><p>금액(&#8361;)</p></div>
                                        <div className="wd-100"><p>잔액(&#8361;)</p></div>
                                        <div className="wd-120"><p>일자</p></div>
                                    </div>
                                    {searchDepData.slice(0, displayLimitDep).map((dep, i) => (
                                        <div key={i} className="m-a-div-tb-body ht-70">
                                            <div className="wd-40">
                                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox"
                                                       name={dep.seqNum} checked={checked.includes(dep.seqNum)}
                                                       onChange={() => checkBoxDepChange(dep.seqNum)}/>
                                            </div>
                                            <div className="wd-40"><p>{i + 1}</p></div>
                                            <div className="wd-150"><p className="font-b">{dep?.memberDTO?.userName}</p>
                                            </div>
                                            <div className="wd-150"><p>{dep?.memberDTO?.id}</p></div>
                                            <div className="wd-100">
                                                <p className="font-b font-s-13" style={{color: dep?.type === '1' ? "#48dc50" : dep?.type === '2' ? "#7c9edc" : '#ff7575'}}>
                                                    {dep?.type === '0' ? '취소' : dep?.type === '1' ? '사용' : dep?.type === '2' ? '지급' : '회수'}
                                                </p>
                                            </div>
                                            <div className="wd-250"><p>{dep?.content}</p></div>
                                            <div className="wd-100"><p className="font-b">{dep?.depPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-100"><p>{dep?.depBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-120"><p>{formatDate(dep?.regDate)}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {displayLimitDep >= 30 && searchDepData.length > displayLimitDep && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                }
                {tabSt === '예치금신청' &&
                    <div className="display-flex flex-column">
                        <div className="m-a-event-frame">
                            <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                <div className="m-a-header-event-combo wd-80pc">
                                    <div className="m-a-combo font-t-cont font-c-default wd-21pc ht-40 font-s-14 m-a-flex-jc-start" onClick={togglePopup}>
                                        <div className="font-t-cont">{perPg}</div>
                                    </div>
                                    {popupVisible && (
                                        <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                                    )}
                                    <MAdminYmdSmallEvent startDate={depConfirmStartDate} endDate={depConfirmEndDate} startHandle={depConfirmStartHandle} endHandle={depConfirmEndHandle}/>
                                </div>
                                <div className="m-a-header-event-btn font-t-cont font-s-13 wd-20pc">
                                    <button className="m-a-colorBt m-a-colorBt-text wd-100pc ht-40" onClick={modifyDepConfirm}>확인</button>
                                </div>
                            </div>
                            <div className="m-a-header-event">
                                <div className="m-a-header-event-search">
                                    <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                                </div>
                            </div>
                        </div>

                        <div className="m-a-tb-frame">
                            <div className="m-a-div-tb m-b-10">
                                <div className="m-a-div-tb-head ht-60">
                                    <div className="wd-40">
                                        <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={selectDepConfirmAllCheckBox}
                                               onChange={handleSelectDepConfirmAllCheckBox}/>
                                    </div>
                                    <div className="wd-40"><p>번호</p></div>
                                    <div className="wd-150"><p>입금완료일</p></div>
                                    <div className="wd-150"><p>고객명</p></div>
                                    <div className="wd-150"><p>아이디</p></div>
                                    <div className="wd-150"><p>예금주(ID)</p></div>
                                    <div className="wd-150"><p>계좌</p></div>
                                    <div className="wd-120"><p>금액</p></div>
                                    <div className="wd-80"><p>구분</p></div>
                                    <div className="wd-80"><p>상태</p></div>
                                </div>
                                {searchDepConData.slice(0, displayLimitDepCon).map((dep, i) => (
                                    <div key={i} className="m-a-div-tb-body ht-70">
                                        <div className="wd-40">
                                            {dep.status === "0" ?
                                                <input className="m-a-admin-input-chkBox m-0-a" type={"checkbox"}
                                                       checked={selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)}
                                                       onChange={() => handleSelectDepConfirmCheckBox(dep)}/> : null
                                            }
                                        </div>
                                        <div className="wd-40"><p className="font-b">{i + 1}</p></div>
                                        <div className="wd-150"><p>{formatDate(dep.sendDate)}</p></div>
                                        <div className="wd-150"><p>{dep.userNm}</p></div>
                                        <div className="wd-150"><p>{dep.userId}</p></div>
                                        <div className="wd-150"><p>{dep.name}</p></div>
                                        <div className="wd-150">
                                            <p>{dep.account}</p>
                                        </div>
                                        <div style={{width: "120px", display:"flex", flexDirection:"row", alignItems:"center"}}>
                                            <p>{dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            {dep.currency ?
                                                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                    <a style={{margin:"0 5px"}}> - </a>
                                                    <a className="font-c-btorage font-b">{dep.dollarPrice}</a>
                                                    <a className="m-l-5 font-s-11">{dep.currency}</a>
                                                </div> : null
                                            }
                                        </div>
                                        <div className="wd-80">{dep.chargFl ? <p>충전</p> : <p>환급</p>}</div>
                                        <div className="wd-80">
                                            {dep.status === "0" ?
                                <p className="font-b" style={{color: "#7C9EDC"}}>대기</p>
                                :
                                dep.status === "1" ?
                                    <p className="font-b" style={{color: "#A3E98C"}}>완료</p>
                                    :
                                    <p className="font-b" style={{color: "#EB939D"}}>취소</p>
                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {displayLimitDepCon >= 30 && searchDepConData.length > displayLimitDepCon && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                }
                {tabSt === '적립금' &&
                    <div>
                        <div className="m-a-deposit-set-frame">
                            <div className="m-a-event-frame">
                                <div className="m-a-header-event display-flex flex-row just-cont-fend">
                                    <div className="m-a-header-event-btn font-t-cont font-s-15">
                                        <button className="m-a-commonBt m-a-commonBt-text wd-70 ht-40 m-r-10" onClick={depositInfoInit}>취소</button>
                                        <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={saveDeposit}>확인</button>
                                    </div>
                                </div>
                            </div>
                            <div className="display-flex flex-row wd-100pc">
                                <div className="display-flex flex-column font-t-cont font-c-default font-s-16 wd-10pc">
                                    <label className="p-t-10 m-b-70">검색</label>
                                    <label className="p-t-10 m-b-20">금액</label>
                                    <label className="p-t-7">내용</label>
                                </div>
                                <div className="display-flex flex-column font-t-cont font-c-default wd-90pc p-l-10">
                                    <div className="display-flex flex-row m-b-7">
                                        <div className="m-a-combo wd-30pc ht-45 font-s-15 m-a-flex-jc-start m-r-7" onClick={() => setDepConditionPopup(true)}>
                                            {conditionDisplayName}
                                            {depConditionPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setDepConditionPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">검색 조건 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDepSerchCondition === "userNm" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepConditionClick("userNm")}><p>고객명</p></div>
                                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDepSerchCondition === "userId" ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepConditionClick("userId")}><p>아이디</p></div>
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setDepConditionPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="m-a-input-frame ht-45 wd-70pc">
                                            <input onChange={(e) => depMemberInputChange(e)} value={depMemberInput} onKeyDown={depositSerchKeyPress}
                                                   onInput={depositSerchInput} placeholder="검색조건 입력"
                                                   className="m-a-input font-s-15" type="text"/>
                                        </div>
                                    </div>
                                    <div className="m-b-7">
                                        <div className="m-a-combo wd-100pc ht-45 m-a-flex-jc-start font-s-15" onClick={() => setDepMemberPopup(true)}>
                                            {selectedMemberDisplay}
                                            {depMemberPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setDepMemberPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">회원 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            {serchDepMemberList.map((member, i) => (
                                                                <div key={i} className={`m-a-card m-t-10 m-b-10 ${selectedDepMember === member.id ? "m-a-selected-option" : ""}`}
                                                                     onClick={() => handleDepMemberClick(member)}>
                                                                    {member.id} / {member.userName}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setDepMemberPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="display-flex flex-row m-b-7">
                                        <div className="m-a-combo wd-30pc ht-45 font-s-15 m-a-flex-jc-start font-b m-r-7" onClick={() => setDepSumFlPopup(true)}>
                                            {depSumFlDisplayName}
                                            {depSumFlPopup && (
                                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setDepSumFlPopup(false); }}>
                                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                        <div className="m-a-page-popup-header-frame">
                                                            <div className="font-t-title font-s-18 m-t-4">상태 선택</div>
                                                        </div>
                                                        <div className="m-a-page-popup-body-frame">
                                                            <div className={`m-a-card m-t-10 m-b-10 ${depSumFl === '2' ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepSumFlClick("plus")}><p>지급[+]</p></div>
                                                            <div className={`m-a-card m-t-10 m-b-10 ${!depSumFl === '2' ? "m-a-selected-option" : ""}`}
                                                                 onClick={() => handleDepSumFlClick("minus")}><p>회수[-]</p></div>
                                                        </div>
                                                        <div className="m-a-page-popup-footer-frame">
                                                            <div className="m-a-file-bt-container">
                                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setDepSumFlPopup(false); }}>
                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="m-a-input-frame ht-45 wd-70pc">
                                            <input onChange={(e) => depositPriceChange(e)} value={depositPrice} className="m-a-input font-s-15"
                                                   type="text" inputMode="numeric" pattern="\d*" maxLength="7"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="m-a-input-frame ht-45">
                                            <input onChange={depPayContentInputChange} value={depositPayContent} className="m-a-input wd-100pc font-s-14" type="text"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="display-flex flex-column">
                            <div className="m-a-event-frame">
                                <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                    <div className="m-a-header-event-combo">
                                        <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                            <div className="font-t-cont">{perPg}</div>
                                        </div>
                                        {popupVisible && (
                                            <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                                        )}
                                        <MAdminYmdSmallEvent startDate={ptStartDate} endDate={ptEndDate} startHandle={ptStartHandle} endHandle={ptEndHandle}/>
                                    </div>
                                </div>
                                <div className="m-a-header-event">
                                    {/*여기서부터 적립금 type 셀렉트*/}
                                    <div className="m-a-combo wd-30pc ht-40 font-s-15 m-a-flex-jc-start m-r-15"
                                         onClick={() => setSelectedPtTypeShow(true)}>
                                        {selectedDepType.label}
                                        {selectedPtTypeShow && (
                                            <div className="m-a-page-popup-container" onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedPtTypeShow(false);
                                            }}>
                                                <div className="m-a-page-popup-frame"
                                                     onClick={(e) => e.stopPropagation()}>
                                                    <div className="m-a-page-popup-header-frame">
                                                        <div className="font-t-title font-s-18 m-t-4">구분</div>
                                                    </div>
                                                    <div className="m-a-page-popup-body-frame">
                                                        {selectedPtTypeList.map((type, index) => (
                                                            <div key={index} onClick={() => handlePtTypeChange(type)}
                                                                 className={`m-a-card m-t-10 m-b-10 ${selectedPtType.value === index ? "m-a-selected-option" : ""}`}>
                                                                <p>{type.label}</p></div>
                                                        ))}
                                                    </div>
                                                    <div className="m-a-page-popup-footer-frame">
                                                        <div className="m-a-file-bt-container">
                                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setSelectedPtTypeShow(false);
                                                                    }}>
                                                                <div
                                                                    className="m-a-colorBt-text font-t-cont">Close
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/*여기까지 커스텀셀렉트*/}
                                    <div className="m-a-header-event-search wd-70pc">
                                        <MAdminSearchEvent searchInput={searchInput}
                                                           handleSearchInputChange={handleSearchInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="m-a-tb-frame">
                                <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head ht-60">
                                        <div className="wd-40">
                                            <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checkedPoint.length === ptHistoryList.length}
                                                   onChange={allcheckBoxPointChange}/>
                                        </div>
                                        <div className="wd-40"><p>번호</p></div>
                                        <div className="wd-150"><p>고객명</p></div>
                                        <div className="wd-150"><p>아이디</p></div>
                                        <div className="wd-100"><p>사용구분</p></div>
                                        <div className="wd-250"><p>내용</p></div>
                                        <div className="wd-100"><p>금액(&#8361;)</p></div>
                                        <div className="wd-100"><p>잔액(&#8361;)</p></div>
                                        <div className="wd-120"><p>일자</p></div>
                                    </div>
                                    {searchPtData.slice(0, displayLimitPt).map((pt, i) => (
                                        <div key={i} className="m-a-div-tb-body ht-70">
                                            <div className="wd-40">
                                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" name={pt.seqNum} checked={checkedPoint.includes(pt.seqNum)}
                                                       onChange={() => checkBoxPointChange(pt.seqNum)}/>
                                            </div>
                                            <div className="wd-40"><p>{i + 1}</p></div>
                                            <div className="wd-150"><p className="font-b">{pt?.memberDTO?.userName}</p></div>
                                            <div className="wd-150"><p>{pt?.memberDTO?.id}</p></div>
                                            <div className="wd-100">
                                                <p className="font-b font-s-13" style={{color: pt?.type === '1' ? "#48dc50" : pt?.type === '2' ? "#7c9edc" : '#ff7575'}}>
                                                    {pt?.type === '0' ? '취소' : pt?.type === '1' ? '사용' : pt?.type === '2' ? '지급' : '회수'}
                                                </p>
                                            </div>
                                            <div className="wd-250"><p>{pt?.content}</p></div>
                                            <div className="wd-100">
                                                <p className="font-b">{pt?.ptPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                            <div className="wd-100"><p>{pt?.ptBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-120"><p>{formatDate(pt?.regDate)}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {displayLimitPt >= 30 && searchPtData.length > displayLimitPt && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                }
                {tabSt === '쿠폰' && (
                    <div>
                        <div className="m-a-cp-slider m-b-15">
                            <div ref={sliderWrapperRef} className="m-a-slider-wrapper m-b-60">
                                <div className="m-a-card-slider m-a-coupon-set-1 m-a-indicators-top p-b-43">
                                    <div className="m-a-event-frame">
                                        <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                            <div className="m-a-header-event-combo">
                                                <p className="font-t-title font-c-default font-s-14">개별관리</p>
                                            </div>
                                            <div className="m-a-header-event-btn font-t-cont font-s-15">
                                                <button className="m-a-commonBt m-a-commonBt-text wd-70 ht-40 m-r-10" onClick={indiviCpInit}>초기화</button>
                                                <button className="m-a-gr-Bt m-a-gr-Bt-text wd-70 ht-40 m-r-10" onClick={() => saveIndiviCp('3')}>회수</button>
                                                <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={() => saveIndiviCp('2')}>지급</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display-flex flex-column wd-100pc">
                                        <div className="display-flex flex-row m-b-7">
                                            <div className="m-a-combo wd-40pc ht-45 font-s-15 m-a-flex-jc-start m-r-7" onClick={() => setCpConditionPopup(true)}>
                                                {conditionDisplayName}
                                            </div>
                                            <div className="m-a-input-frame ht-45 wd-60pc">
                                                <input onChange={(e) => cpMemberInputChange(e)} value={cpMemberInput} onKeyDown={cpSerchKeyPress}
                                                       onInput={cpSerchInput} placeholder="검색조건 입력"
                                                       className="m-a-input font-s-15" type="text"/>
                                            </div>
                                        </div>
                                        <div className="m-b-7">
                                            <div className="m-a-combo wd-100pc ht-45 m-a-flex-jc-start font-s-15" onClick={() => setCpMemberPopup(true)}>
                                                {selectedCMemberDisplay}
                                            </div>
                                        </div>
                                        <div>
                                            <div ref={indiviCpListRef}>
                                                <div className="m-a-combo wd-100pc ht-45 m-a-flex-jc-start font-s-15" onClick={toggleIndiviCpListShow}>
                                                    {selectedIndiviCpNm || selectedIndiviCpPrice ? (
                                                        <p>{selectedIndiviCpNm} / {selectedIndiviCpPrice.toLocaleString()}</p>
                                                    ) : (
                                                        <p>선택하세요</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-a-card-slider m-a-coupon-set-2 m-a-indicators-top p-b-43">
                                    <div className="m-a-event-frame">
                                        <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                            <div className="m-a-header-event-combo">
                                                <p className="font-t-title font-c-default font-s-14">일괄관리 & 삭제</p>
                                            </div>
                                            <div className="m-a-header-event-btn font-t-cont font-s-15">
                                                <button className="m-a-commonBt m-a-commonBt-text wd-70 ht-40 m-r-10" onClick={() => setDeleteCpShowSt(true)}>삭제</button>
                                                <button className="m-a-gr-Bt m-a-gr-Bt-text wd-70 ht-40 m-r-10" onClick={() => setMinusBatchCpShowSt(true)}>회수</button>
                                                <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={() => setPlusBatchCpShowSt(true)}>지급</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display-flex flex-column wd-100pc">
                                        <div>
                                            <div ref={batchCpListRef}
                                                 style={{width: "100%", position: "relative"}}>
                                                <button className="selectParentBt wd-100pc ht-40"
                                                        onClick={toggleBatchCpListShow}>
                                                    {selectedBatchCpNm || selectedBatchCpPrice ?
                                                        <p style={{margin: "0"}}>{selectedBatchCpNm} / {selectedBatchCpPrice}</p>
                                                        :
                                                        null
                                                    }
                                                </button>
                                                {batchCpListShow ?
                                                    <div style={{position: 'absolute', width: "100%", border: "solid", height: "auto", maxHeight: "300px",
                                                        borderWidth: "thin 1px", borderColor: "lightgray", zIndex: 1000, overflow: "auto"
                                                    }}>
                                                        {serchBatchCpList && serchBatchCpList.map((cp, i) => (
                                                            <div key={i} className="selectChildBt"
                                                                 // onClick={() => selectedBatchCpListChange(cp)}
                                                                 onClick={() => handleBatchCpSelect(cp)}
                                                                 style={{height: "40px", display: "flex", justifyContent: "center", alignItems: "center",}}>{cp.cpNm} / {cp.cpPrice}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-a-card-slider m-a-coupon-set-3 m-a-indicators-top p-b-43">
                                    <div className="m-a-event-frame">
                                        <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                            <div className="m-a-header-event-combo">
                                                <p className="font-t-title font-c-default font-s-14">쿠폰생성</p>
                                            </div>
                                            <div className="m-a-header-event-btn font-t-cont font-s-15">
                                                <button className="m-a-commonBt m-a-commonBt-text wd-70 ht-40 m-r-10" onClick={newCpInit}>초기화</button>
                                                <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={saveNewCoupon}>생성</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="display-flex flex-column wd-100pc">
                                        <div>
                                            <div className="user-input-container ht-40"
                                                 style={{borderRadius: "2px"}}>
                                                <input onChange={handleNewCpNm} className="ReviewInput user-input"
                                                       value={newCpNm}
                                                       placeholder="쿠폰명을 입력하세요"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "14px",
                                                           color: "dimgray",
                                                       }} maxLength={25} type="text"
                                                />
                                            </div>
                                            <div className="user-input-container ht-40 m-t-5"
                                                 style={{borderRadius: "2px"}}>
                                                <input onChange={handleNewCpPrice} className="ReviewInput user-input"
                                                       value={newCpPrice}
                                                       placeholder="금액을 입력하세요(ex/100000)"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "14px",
                                                           color: "dimgray",
                                                       }} maxLength={6} type="text"
                                                />
                                            </div>
                                            <div className="user-input-container ht-40 m-t-5"
                                                 style={{borderRadius: "2px"}}>
                                                <input onChange={handleNewCpExpDate} className="ReviewInput user-input"
                                                       value={newCpExpDate}
                                                       placeholder="만료기한을 입력하세요(ex/20240101)"
                                                       style={{
                                                           width: "100%",
                                                           paddingLeft: "10px",
                                                           paddingRight: "10px",
                                                           fontSize: "14px",
                                                           color: "dimgray",
                                                       }} maxLength={8} type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-a-indicators m-b-18">
                                <span className="m-a-indicator"></span>
                                <span className="m-a-indicator"></span>
                                <span className="m-a-indicator"></span>
                            </div>
                        </div>
                        {cpConditionPopup && (
                            <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setCpConditionPopup(false); }}>
                                <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                    <div className="m-a-page-popup-header-frame">
                                        <div className="font-t-title font-s-18 m-t-4">검색 조건 선택</div>
                                    </div>
                                    <div className="m-a-page-popup-body-frame">
                                        <div className={`m-a-card m-t-10 m-b-10 ${selectedCpSerchCondition === "userNm" ? "m-a-selected-option" : ""}`}
                                             onClick={() => handleCpConditionClick("userNm")}><p>고객명</p></div>
                                        <div className={`m-a-card m-t-10 m-b-10 ${selectedCpSerchCondition === "userId" ? "m-a-selected-option" : ""}`}
                                             onClick={() => handleCpConditionClick("userId")}><p>아이디</p></div>
                                    </div>
                                    <div className="m-a-page-popup-footer-frame">
                                        <div className="m-a-file-bt-container">
                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setCpConditionPopup(false); }}>
                                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {indiviCpListShow && (
                            <div className="m-a-page-popup-container">
                                <div className="m-a-page-popup-frame">
                                    <div className="m-a-page-popup-header-frame">
                                        <div className="font-t-title font-s-18 m-t-4 font-c-default">쿠폰 선택</div>
                                    </div>
                                    <div className="m-a-page-popup-body-frame font-c-default">
                                        {serchIndiviCpList.map((cp, i) => (
                                            <div key={i} className={`m-a-card m-t-10 m-b-10 ${selectedIndiviCpSeqnum === cp.seqNum ? "m-a-selected-option" : ""}`}
                                                 onClick={() => selectedCpListChange(cp)}>
                                                <p>{cp.cpNm} / {cp.cpPrice.toLocaleString()}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="m-a-page-popup-footer-frame">
                                        <div className="m-a-file-bt-container">
                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={() => setIndiviCpListShow(false)}>
                                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {cpMemberPopup && (
                            <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setCpMemberPopup(false); }}>
                                <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                    <div className="m-a-page-popup-header-frame">
                                        <div className="font-t-title font-s-18 m-t-4">회원 선택</div>
                                    </div>
                                    <div className="m-a-page-popup-body-frame">
                                        {serchCpMemberList.map((member, i) => (
                                            <div key={i} className={`m-a-card m-t-10 m-b-10 ${selectedCMember === member.id ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleCpMemberClick(member)}>
                                                {member.id} / {member.userName}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="m-a-page-popup-footer-frame">
                                        <div className="m-a-file-bt-container">
                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setCpMemberPopup(false); }}>
                                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="display-flex flex-column m-t-20">
                            <div className="m-a-event-frame">
                                <div className="m-a-header-event display-flex flex-row just-cont-sb">
                                    <div className="m-a-header-event-combo">
                                        <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                            <div className="font-t-cont">{perPg}</div>
                                        </div>
                                        {popupVisible && (
                                            <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                                        )}
                                        <MAdminYmdSmallEvent startDate={cpStartDate} endDate={cpEndDate} startHandle={cpStartHandle} endHandle={cpEndHandle}/>
                                    </div>
                                </div>
                                <div className="m-a-header-event">
                                    {/*여기서부터 쿠폰 type 셀렉트*/}
                                    <div className="m-a-combo wd-30pc ht-40 font-s-15 m-a-flex-jc-start m-r-15"
                                         onClick={() => setSelectedCpTypeShow(true)}>
                                        {selectedDepType.label}
                                        {selectedCpTypeShow && (
                                            <div className="m-a-page-popup-container" onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedCpTypeShow(false);
                                            }}>
                                                <div className="m-a-page-popup-frame"
                                                     onClick={(e) => e.stopPropagation()}>
                                                    <div className="m-a-page-popup-header-frame">
                                                        <div className="font-t-title font-s-18 m-t-4">구분</div>
                                                    </div>
                                                    <div className="m-a-page-popup-body-frame">
                                                        {selectedCpTypeList.map((type, index) => (
                                                            <div key={index} onClick={() => handleCpTypeChange(type)}
                                                                 className={`m-a-card m-t-10 m-b-10 ${selectedCpType.value === index ? "m-a-selected-option" : ""}`}>
                                                                <p>{type.label}</p></div>
                                                        ))}
                                                    </div>
                                                    <div className="m-a-page-popup-footer-frame">
                                                        <div className="m-a-file-bt-container">
                                                            <button className="m-a-colorBt ht-40 m-b-5 wd-80"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setSelectedCpTypeShow(false);
                                                                    }}>
                                                                <div
                                                                    className="m-a-colorBt-text font-t-cont">Close
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/*여기까지 커스텀셀렉트*/}
                                    <div className="m-a-header-event-search wd-70pc">
                                        <MAdminSearchEvent searchInput={searchInput}
                                                           handleSearchInputChange={handleSearchInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="m-a-tb-frame">
                                <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head ht-60">
                                        <div className="wd-40">
                                            <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checkedCp.length === cpHistoryList.length}
                                                   onChange={allcheckBoxCpChange}/>
                                        </div>
                                        <div className="wd-40"><p>번호</p></div>
                                        <div className="wd-150"><p>고객명</p></div>
                                        <div className="wd-150"><p>아이디</p></div>
                                        <div className="wd-100"><p>사용구분</p></div>
                                        <div className="wd-250"><p>내용</p></div>
                                        <div className="wd-100"><p>금액(&#8361;)</p></div>
                                        <div className="wd-100"><p>잔액(&#8361;)</p></div>
                                        <div className="wd-120"><p>일자</p></div>
                                    </div>
                                    {searchCpData.slice(0, displayLimitCp).map((cp, i) => (
                                        <div key={i} className="m-a-div-tb-body ht-70">
                                            <div className="wd-40">
                                                <input className="admin-input-chkBox m-0-a" type="checkbox" name={cp.seqNum} checked={checkedCp.includes(cp.seqNum)}
                                                       onChange={() => checkBoxCpChange(cp.seqNum)}/>
                                            </div>
                                            <div className="wd-40"><p>{i + 1}</p></div>
                                            <div className="wd-150"><p className="font-b">{cp?.memberDTO?.userName}</p></div>
                                            <div className="wd-150"><p>{cp?.memberDTO?.id}</p></div>
                                            <div className="wd-100">
                                                <p className="font-b font-s-13" style={{color: cp?.type === '1' ? "#48dc50" : cp?.type === '2' ? "#7c9edc" : '#ff7575'}}>
                                                    {cp?.type === '0' ? '취소' : cp?.type === '1' ? '사용' : cp?.type === '2' ? '지급' : '회수'}
                                                </p>
                                            </div>
                                            <div className="wd-250"><p className="font-b">{cp?.cpNm}</p></div>
                                            <div className="wd-100"><p>{cp?.cpPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-100"><p>{}</p></div>
                                            <div className="wd-120"><p>{formatDate(cp?.regDate)}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {displayLimitCp >= 30 && searchCpData.length > displayLimitCp && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {deleteCpShowSt ?
                <MsgSelectModal deleteCpShowSt={deleteCpShowSt} deleteCpMsg1={deleteCpMsg1} deleteCpMsg2={deleteCpMsg2}
                                closeDeleteCpShow={closeDeleteCpShow} deleteCoupon={deleteCoupon}/>
                :
                minusBatchCpShowSt ?
                    <MsgSelectModal minusBatchCpShowSt={minusBatchCpShowSt} minusBatchCpMsg1={minusBatchCpMsg1}
                                    minusBatchCpMsg2={minusBatchCpMsg2}
                                    closeMinusBatchCpShow={closeMinusBatchCpShow} saveBatchCp={saveBatchCp}/>
                    :
                    plusBatchCpShowSt ?
                        <MsgSelectModal plusBatchCpShowSt={plusBatchCpShowSt} plusBatchCpMsg1={plusBatchCpMsg1}
                                        plusBatchCpMsg2={plusBatchCpMsg2}
                                        closePlusBatchCpShow={closePlusBatchCpShow} saveBatchCp={saveBatchCp}/>
                        :
                        couponDupSt ?
                            <MsgCheckModal couponDupSt={couponDupSt} closeCouponDup={closeCouponDup} />
                            :
                        null
            }
        </div>
    );
}

export default MAdminDepositSetPg
