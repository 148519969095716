import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from '../../services/axiosInstance';
import {Table} from "react-bootstrap";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminNoticePg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const navigate = useNavigate();

    const moveToDetailPg = ((seqNum) => {
        navigate(`/btcorpad/adminnotice-detail/${seqNum}`)
    })

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checkItems, setCheckItems] = useState([]);
    useEffect(() => {}, [checkItems]);
    const handleSingleCheck = (checked, seqNum) => {
        if (checked) {
            setCheckItems(prev => [...prev, seqNum]);
        } else {
            setCheckItems(checkItems.filter((el) => el !== seqNum));
        }
    };
    const handleAllCheck = (checked) => {
        if(checked) {
            const idArray = [];
            data.forEach((el) => idArray.push(el.seqNum));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }
    }

    //endregion

    /** endregion */

    /** region Event */

    const selectFixFirstCondition = () => axios({
        method: 'get',
        url: '/notice/selectFixFirstCondition/',
        params: {
            limit: perPg
        }
    })
        .then((response) => {
            let data = response.data.sort((a, b) => b.seqnum - a.seqnum);
            setData(data);
        })

    useEffect(() => {
        selectFixFirstCondition();
    }, [])

    const convertToLang = ((lang) => {
        if (lang === "0") return "Korean"
        if (lang === "1") return "English"
        if (lang === "2") return "Japanese"
        if (lang === "3") return "Chinese"
        if (lang === "4") return "Indonesian"
    })

    /** endregion */

    return (
        <div className="AdminNoticePg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">공지사항</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                        </div>
                        <div className="admin-event-wrap-btn">
                                <button onClick={()=> navigate("/btcorpad/adminnotice-write")} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">등록</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "30px"}}>
                                <input className="admin-input-chkBox m-0-a" type='checkbox'
                                       onChange={(e) => handleAllCheck(e.target.checked)}
                                       checked={checkItems.length === data.length}/>
                            </div>
                            <div style={{width: "30px"}}><p>번호</p></div>
                            <div style={{width: "40px"}}><p>언어</p></div>
                            <div style={{width: "300px"}}><p>제목</p></div>
                            <div style={{width: "90px"}}><p>작성자</p></div>
                            <div style={{width: "150px"}}><p>등록일시</p></div>
                            <div style={{width: "90px"}}><p>수정자</p></div>
                            <div style={{width: "150px"}}><p>수정일시</p></div>
                        </div>
                        {data.filter(data => data.fixFl === true).map((data, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "30px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={data.seqNum}
                                           checked={checkItems.includes(data.seqNum)}
                                           onChange={(e) => handleSingleCheck(e.target.checked, data.seqNum)}/>
                                </div>
                                <div style={{width: "30px"}}><p className="font-b" style={{color: "#FFAF34"}}>고정</p></div>
                                <div style={{width: "40px"}}><p>{convertToLang(data?.lang)}</p></div>
                                <div style={{width: "300px", cursor: "pointer"}}
                                     onClick={() => moveToDetailPg(data.seqNum)}>
                                    <p className="NoticeAndFaqText" style={{cursor: "pointer", maxWidth:"300px"}}>{data?.title}</p>
                                </div>
                                <div style={{width: "90px"}}><p>{data?.regNm}</p></div>
                                <div style={{width: "150px"}}><p>{data?.regTime}</p></div>
                                <div style={{width: "90px"}}><p>{data?.modiNm}</p></div>
                                <div style={{width: "150px"}}><p>{data?.modiTile}</p></div>
                            </div>
                        ))}
                        {data.filter(data => data.fixFl === false).map((data, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "30px"}}>
                                    <input className="admin-input-chkBox m-0-a" type='checkbox'
                                           name={`select-${data.seqNum}`}
                                           onChange={(e) => handleSingleCheck(e.target.checked, data.seqNum)}
                                           checked={checkItems.includes(data.seqNum)}/>
                                </div>
                                <div style={{width: "30px"}}><p>{i + 1}</p></div>
                                <div style={{width: "40px"}}><p>{convertToLang(data?.lang)}</p></div>
                                <div style={{width: "300px", cursor: "pointer"}} onClick={() => moveToDetailPg(data.seqNum)}>
                                    <p className="NoticeAndFaqText" style={{cursor: "pointer", maxWidth:"300px"}}>{data?.title}</p>
                                </div>
                                <div style={{width: "90px"}}><p>{data?.regNm}</p></div>
                                <div style={{width: "150px"}}><p>{data?.regTime}</p></div>
                                <div style={{width: "90px"}}><p>{data?.modiNm}</p></div>
                                <div style={{width: "150px"}}><p>{data?.modiTile}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminNoticePg
