import React, {useEffect, useState} from 'react';
import '../../css/web/UserReviewPg.css';
import noReviewPhoto from "../../assets/img/btorage_noimage.png";
import '../../css/web/HeartButton.css';
import {Link, useNavigate} from "react-router-dom";
import axios from '../../services/axiosInstance';
import {useTranslation} from "react-i18next";
import commomHeader from "../../assets/img/commonHeader.png";
import SeoHelmet from "../../components/SeoHelmet";

function UserReviewPg() {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [hasClickedMore, setHasClickedMore] = useState(false);
    const navigate = useNavigate();

    const [reviewAllList, setReviewAllList] = useState([]);
    const [filteredReviews, setFilteredReviews] = useState([]);
    const [displayLimit, setDisplayLimit] = useState(30);
    const [searchText, setSearchText] = useState('');

    const selectReviewAllList = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewAllList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setReviewAllList(sortedData)
                setFilteredReviews(response.data.slice(0, displayLimit));
            })

    useEffect(() => {
        selectReviewAllList();
    }, [])

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);
        const filtered = reviewAllList.filter(review =>
            review.title.toLowerCase().includes(value) ||
            review.country.toLowerCase().includes(value) ||
            review.shipco.toLowerCase().includes(value)
        );
        setFilteredReviews(filtered.slice(0, displayLimit));
    }

    const handleMore = () => {
        setDisplayLimit(prev => prev + 30);
        setFilteredReviews(reviewAllList.slice(0, displayLimit + 30));
        setHasClickedMore(true);
    }

    const newTextPrintFl = (regDate) => {
        // 오늘 날짜 객체를 생성
        const today = new Date();
        const todayFormatted = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
        // 입력된 날짜와 오늘 날짜를 비교
        return regDate === todayFormatted;
    };

    return (
        <div>

            <SeoHelmet>
                <title>이용후기</title>
                <meta name="description" content="국가별 이용후기를 참고하여 이용하세요"/>
                <link rel="canonical" href="https://www.btorage.com/UserReviewPg"/>
            </SeoHelmet>

            <div style={{display: "flex", alignItems: "flex-start", padding: "10px 0"}}>
                <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`rvHeader`)}</p>
            </div>
            <div className="font-t-cont ht-80 font-s-20 display-flex m-l-10" style={{alignItems: "flex-end"}}>
                <div className="ReviewPgSearchFrame acc-icon wd-25pc">
                    <div className="ReviewPgInputForm" style={{width: "97%"}}>
                        <input className="ReviewInput user-input" type="text"
                               style={{width: "100%", marginLeft: "5px"}}
                               placeholder="" onChange={handleSearch}/>
                        <label className="JoinLabel" style={{fontSize: "14px"}}>{t(`rvPlaceHd`)}</label>
                    </div>
                </div>
                <div style={{
                    width: "75%",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginRight: "10px"
                }}>
                    <Link to={"/UserReviewWritePg"}>
                        <button className="bt-btn-B wd-100 ht-45 font-s-15">{t(`rvdtBt`)}</button> {/*등록 버튼*/}
                    </Link>
                </div>
            </div>
            {/*후기카드 컨테이너*/}
            <div className="font-t-cont" style={{marginTop: "20px", display: "flex", flexWrap: "wrap"}}>
                {filteredReviews.slice(0, displayLimit).map((review, index) => (
                    <div key={index} className="" style={{width: "33.3%", padding: "10px"}}>
                        <div className="reviewImage wd-100pc ht-330 c-point" onClick={() => navigate(`/UserReviewDetailPg/${review.seqNum}`)}>
                            {review && review.reviewPhotoDTO[0] && review.reviewPhotoDTO[0].fileNm ?
                                <img alt="" className="ReviewImageTest"
                                     src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + review.reviewPhotoDTO[0].fileNm}
                                     style={{width: "100%", height: "100%", borderRadius: "6px"}}/>
                                :
                                <div className="wd-100pc ht-100pc align-center justify-content-center"
                                     style={{backgroundColor: "#f6f6f6", borderRadius: "6px", padding: "40px"}}>
                                    <img alt="" className="wd-100pc ht-100pc" src={noReviewPhoto}/>
                                </div>
                            }
                        </div>
                        <div className="ReviewTitleCon" style={{display: "flex", marginTop: "10px"}}>
                            <div className="ReviewTitle"
                                 style={{fontWeight: "lighter", margin: "0", padding: "0", width: "85%"}}><p
                                 style={{
                                     padding: "0",
                                     fontSize: "15px",
                                     margin: "0",
                                     color: "dimgrey",
                                     overflow: "hidden",
                                     textOverflow: "ellipsis",
                                     whiteSpace: "nowrap",
                                     wordBreak: "break-all"
                                 }}>[{review.country}-{review.shipco}]
                                <span style={{marginLeft: "5px"}}>{review.title}</span></p></div>

                            <div style={{width: "15%"}}>
                                {newTextPrintFl(review.regDate) &&
                                    <p style={{
                                        padding: "0",
                                        margin: "0",
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                        color: "orangered"
                                    }}>New</p>}
                            </div>
                        </div>
                        {/*날짜컨테이너*/}
                        <div style={{display: "flex", width: "100%", alignItems: "end"}}>
                            <div style={{
                                width: "50%",
                                color: "dimgrey",
                                fontSize: "14px",
                                paddingBottom: "5px"
                            }}>{formatDate(review.regDate)}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                {
                    (!hasClickedMore && filteredReviews.length === displayLimit) ||
                    (hasClickedMore && displayLimit < reviewAllList.length) ?
                        <button className="ReviewPgWriteBtn" onClick={handleMore}
                                style={{width: "90px", height: "40px", marginTop: "40px"}}>+ More
                        </button>
                        : null
                }
            </div>
        </div>
    );
}

export default UserReviewPg
