import "../../css/mobile/MUserIdPwFindPg.css";
import Logo from "../../assets/img/HeaderLogo.png";
import React, {useState} from "react";
import MHeader from "../../components/mobile/MHeader";
import pwView from "../../assets/img/pwView.png"
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import i18n from "../../i18n";
import axios from '../../services/axiosInstance';

const MUserIdPwFindPg = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const [codeSendSt, setCodeSendSt] = useState(false);
    const [email, setEmail] = useState('');
    const [tempPassword, setTempPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);

    const handleEmail = ((e) => {
        setEmail(e);
        setEmailError(false);
        setNotJoinError(false);
    })

    const handleTempPassword = ((e) => {
        setTempPassword(e);
        setResPassWordError(false);
    })

    const handlePassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e || (passwordRegex.test(e)))) setPasswordError(false);
        else setPasswordError(true);
        setPassword(e);
    };

    const handleConfirmPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (password === e) setPasswordMatchError(false);
        else setPasswordMatchError(true);
        setConfirmPassword(e);
    };

    const [resPassword, setResPassword] = useState('');
    const [resPassWordError, setResPassWordError] = useState(false);
    const [notJoinError, setNotJoinError] = useState(false);

    const generateTempPassword = () => {
        setIsLoading(true);
        if (email === '') {
            setEmailError(true);
            setIsLoading(false);
        } else {
            axios({
                method: 'post',
                url: '/member/generateTempPassword',
                params: {
                    email: email
                }
            })
                .then((response) => {
                    setResPassword(response.data);
                    setCodeSendSt(true);
                    console.log("임시비밀번호 : ", response.data)
                })
                .catch(() => {
                    setNotJoinError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const validation = () => {
        let isValid = true;
        if (!password) {
            setPasswordError(true);
            isValid = false;
        }
        if (password !== confirmPassword || !confirmPassword) {
            setPasswordMatchError(true);
            isValid = false;
        }
        if (resPassword !== tempPassword) {
            setResPassWordError(true);
            isValid = false;
        }
        return isValid;
    }

    const valiAndPasswordModify = () => {
        if (validation()) {
            modifyPassword()
        }
    };


    // 백엔드에서 변경 후 프론트엔드로 반환 전 로그인함수실행
    const modifyPassword = (() => {
        setIsLoading(true);
        axios({
            method: 'post',
            url: '/member/modifyPassword',
            params: {
                email: email,
                password: password
            }
        })
            .then(() => {
                // 로그인
                console.log("로그인함수 실행")
                login(email, password);
            })
            .catch((error) => {
                setNotJoinError(true);
                setIsLoading(false);
                console.log("변경실패 : ", error)
            })
    })

    const login = ((email, password) => {
        axios({
            method: 'post',
            url: '/member/login',
            data: {
                id: email,
                password: password,
                loginPath: location.pathname,
            }
        })
            .then((response) => {
                const currentTime = new Date();
                sessionStorage.setItem('AToken', response.headers.get('Authorization'))
                sessionStorage.setItem('lastLoginTime', currentTime);
                console.log("로그인페이지 이동")
                navigate("/")
            })
            .catch((error) => {
                setNotJoinError(true);
                console.log("변경실패 : ", error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    })

    return (
        <div className='m-a-find-pw-pg-container'>
            <MHeader title={t(`loginRePw`)} url={"/"}/>
            <img className='m-a-find-pw-pg-logo' src={Logo} alt="BTORAGE"/>
            {codeSendSt ? (
                <div className='m-a-find-pw-pg-frame'>
                    <div className='m-a-find-pw-pg-input-frame border'>
                        <input className='m-a-find-pw-pg-input'
                               type="password" placeholder={t(`ipfTempPw`)}
                               value={tempPassword} minLength="8" maxLength="20"
                               onChange={(e) => handleTempPassword(e.target.value)}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter') {
                                       valiAndPasswordModify();
                                   }
                               }}
                        />
                    </div>
                    {resPassWordError && (
                        <div className='m-a-find-pw-pg-error-text'>
                            {t(`ipfTempErr`)}
                        </div>
                    )}

                    <div className='m-a-find-pw-pg-input-frame border'>
                        <input className='m-a-find-pw-pg-input' placeholder={t(`ipfCgPw`)}
                               type="password" value={password}
                               onChange={(e) => handlePassword(e.target.value)}
                               maxLength={20}/>
                    </div>
                    {passwordError && (
                        <div className='m-a-find-pw-pg-error-text'>
                            {t(`ipfCgPwErr`)}
                        </div>
                    )}

                    <div className='m-a-find-pw-pg-input-frame border'>
                        <input className='m-a-find-pw-pg-input' placeholder={t(`ipfCgConfPw`)}
                               type="password" value={confirmPassword} maxLength={20}
                               onChange={(e) => handleConfirmPassword(e.target.value)}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter') {
                                       valiAndPasswordModify();
                                   }
                               }}
                        />
                    </div>
                    {passwordMatchError && (
                        <div className='m-a-find-pw-pg-error-text'>
                            {t(`ipfCgConfErr`)}
                        </div>
                    )}

                    <div className='m-a-find-pw-pg-button' onClick={valiAndPasswordModify}>
                        {isLoading ? (
                            <div className='m-a-find-pw-pg-button'>
                                <div className='m-a-small-spinner'/>
                            </div>
                        ) : (
                            <div className='m-a-find-pw-pg-button'>
                                {t(`ipfCgDoneBt`)}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className='m-a-find-pw-pg-frame'>
                    <input className='m-a-find-pw-pg-input border' type="text"
                           placeholder={t(`ipfLabel`)} value={email}
                           onChange={(e) => handleEmail(e.target.value)} maxLength={40}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') {
                                   generateTempPassword();
                               }
                           }}/>

                    {notJoinError && (
                        <div className='m-a-find-pw-pg-error-text'>
                            {t(`ipfErr1`)}
                        </div>
                    )}
                    {emailError && (
                        <div className='m-a-find-pw-pg-error-text'>
                            {t(`ipfErr2`)}
                        </div>
                    )}

                    {isLoading ? (
                        <div className='m-a-find-pw-pg-button'>
                            <div className='m-a-small-spinner'/>
                        </div>
                    ) : (
                        <div className='m-a-find-pw-pg-button'
                             onClick={generateTempPassword}>
                            {t(`idfBt`)}
                        </div>
                    )}
                </div>
            )}


        </div>
    )
}

export default MUserIdPwFindPg;
