import React, {useEffect, useRef, useState} from "react";
import axios from '../../services/axiosInstance';
import {BiCaretDown} from "react-icons/bi";
import weight from "../../assets/img/weight.png";
import {useTranslation} from "react-i18next";
import SeoHelmet from "../../components/SeoHelmet";

function UserCompareShipPg() {

    const {t} = useTranslation();

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country, event) => {
        event.stopPropagation();
        setSelectedCountry(country);
        setSearchKeyword(country.label);
        setCountryShow(false);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
        '터키': '튀르키예',
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            if (selectedIndex < filteredCountryList.length - 1) {
                setSelectedIndex(selectedIndex + 1);
            }
        } else if (event.key === "ArrowUp") {
            if (selectedIndex > 0) {
                setSelectedIndex(selectedIndex - 1);
            }
        } else if (event.key === "Enter") {
            if (selectedIndex >= 0) {
                handleCountryChange(filteredCountryList[selectedIndex], event);
            }
        }
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || countryNameMapping[keyword] || keyword;

        const filteredCountries = countryList.filter((country) =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);

        if (!keyword) {
            setSelectedCountry({value: '', label: t(`ordCrLabel`)});
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (countryRef.current) {
            const itemElements = countryRef.current.querySelectorAll('.selectChildBt');
            const selectedItem = itemElements[selectedIndex];

            if (selectedItem) {
                const listContainer = countryRef.current.querySelector('.country-select-data');
                const containerHeight = listContainer.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemHeight = selectedItem.clientHeight;

                if (itemTop < 0) {
                    listContainer.scrollTop = itemTop;
                } else if (itemTop + itemHeight > containerHeight) {
                    listContainer.scrollTop = itemTop + itemHeight - containerHeight;
                }
            }
        }
    }, [selectedIndex]);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    const [realWeight, setRealWeight] = useState('');
    const [wid, setWid] = useState('');
    const [len, setLen] = useState('');
    const [hei, setHei] = useState('');

    const [fedexAppWeight, setFedExAppWeight] = useState('');
    const [fedexEcoAppWeight, setFedExEcoAppWeight] = useState('');
    const [dhlAppWeight, setDhlAppWeight] = useState('');
    const [emsAppWeight, setEmsAppWeight] = useState('');
    const [emsPreAppWeight, setEmsPreAppWeight] = useState('');
    const [upsAppWeight, setUpsAppWeight] = useState('');
    const [upsEcoAppWeight, setUpsEcoAppWeight] = useState('');
    const [kpackAppWeight, setKpackAppWeight] = useState('');
    const [smallPackAppWeight, setSmallPackAppWeight] = useState('');
    const [seaAppWeight, setSeaAppWeight] = useState('');


    const [fedexPrice, setFedexPrice] = useState('');
    const [fedexEcoPrice, setFedexEcoPrice] = useState('');
    const [dhlPrice, setDhlPrice] = useState('');
    const [emsPrice, setEmsPrice] = useState('');
    const [emsPrePrice, setEmsPrePrice] = useState('');
    const [upsPrice, setUpsPrice] = useState('');
    const [upsEcoPrice, setUpsEcoPrice] = useState('');
    const [kpackPrice, setKpackPrice] = useState('');
    const [smallPackPrice, setSmallPackPrice] = useState('');
    const [seaPrice, setSeaPrice] = useState('');

    const initWeight = (() => {
        setRealWeight('');
        setWid('');
        setLen('');
        setHei('');
    })

    const findPrice = (weight, priceList) => {
        const weightInGrams = Math.round(weight * 1000); // kg를 그램으로 변환, 반올림 처리
        const weightKeys = Object.keys(priceList).map(key => parseInt(key.substring(1))); // "w500" -> 500
        const sortedWeights = weightKeys.sort((a, b) => a - b);

        for (let i = 0; i < sortedWeights.length; i++) {
            if (weightInGrams <= sortedWeights[i]) {
                return priceList[`w${sortedWeights[i]}`]; // 해당 무게에 맞는 가격 반환
            }
        }
        return t('cspWtNoSurch'); // 무게구간에 해당하지 않을 경우 '별도문의' 반환
    };

    const roundUp = (value, unit) => {
        return Math.ceil(value / unit) * unit;
    };

    const apyWeightCalculation = (dvType, wid, len, hei, realWeight) => {
        const width = parseFloat(wid);
        const height = parseFloat(hei);
        const length = parseFloat(len);
        const netWeight = parseFloat(realWeight);

        if (isNaN(width) || isNaN(height) || isNaN(length) || isNaN(netWeight)) return 0;

        let volWeight, baseWeight, finalWeight;

        switch (dvType) {
            // FEDEX, FEDEX-ECO, UPS, UPS-ECO, DHL, 국내
            case "D01":
            case "D16":
            case "D11":
            case "D17":
            case "D04":
                volWeight = (width * length * height) / 5000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = baseWeight <= 21
                    ? roundUp(baseWeight, 0.5)
                    : roundUp(baseWeight, 1);
                break;
            case "D02": // EMS
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                const gram = baseWeight * 1000;
                finalWeight = gram <= 2000
                    ? roundUp(gram, 250) / 1000
                    : roundUp(gram, 500) / 1000;
                break;
            case "D15": // EMS 프리미엄
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = baseWeight <= 20
                    ? roundUp(baseWeight, 0.5)
                    : roundUp(baseWeight, 1);
                break;
            case "D13": // K-Packet
                volWeight = (width * length * height) / 6000;
                baseWeight = Math.max(netWeight, volWeight);
                finalWeight = roundUp(baseWeight * 1000, 100) / 1000;
                break;
            case "D14": // Air Small
                finalWeight = roundUp(netWeight, 0.1);
                break;
            case "D03": // SEA
                const weightGram = netWeight * 1000;
                finalWeight = weightGram <= 1000
                    ? roundUp(weightGram, 1000) / 1000
                    : roundUp(weightGram, 2000) / 1000;
                break;
            case "D00": // 국내배송
                volWeight = (width * length * height) / 5000;
                baseWeight = Math.max(netWeight, volWeight);
                if (baseWeight <= 3) {
                    finalWeight = 3;
                } else if (baseWeight <= 5) {
                    finalWeight = 5;
                } else if (baseWeight <= 10) {
                    finalWeight = 10;
                } else if (baseWeight <= 20) {
                    finalWeight = 20;
                } else {
                    finalWeight = 25;
                }
                break;
            default:
                finalWeight = 0;
        }

        return parseFloat(finalWeight.toFixed(2));
    };

    // 실무게&가로&세로&높이 모두 입력될때만 DB에 조회쿼리
    useEffect(() => {
        if (selectedCountry.value !== '' && realWeight !== '' && wid !== '' && len !== '' && hei !== '') {
            selectedShipFee(selectedCountry.value);
        }
    }, [selectedCountry, realWeight, wid, len, hei])

    const selectedShipFee = ((crCd) => {
        axios({
            method: 'get',
            url: '/shipinfo/selectedShipFee',
            params: {
                crCd: crCd
            }
        })
            .then((response) => {
                const data = response.data;

                // 각 배송사별 절상된 무게단위로 계산
                const formattedFedexAppWeight = apyWeightCalculation("D01", wid, len, hei, realWeight);
                const formattedFedexEcoAppWeight = apyWeightCalculation("D16", wid, len, hei, realWeight);
                const formattedUpsAppWeight = apyWeightCalculation("D11", wid, len, hei, realWeight);
                const formattedUpsEcoAppWeight = apyWeightCalculation("D17", wid, len, hei, realWeight);
                const formattedEmsAppWeight = apyWeightCalculation("D02", wid, len, hei, realWeight);
                const formattedEmsPreAppWeight = apyWeightCalculation("D15", wid, len, hei, realWeight);
                const formattedKpackAppWeight = apyWeightCalculation("D13", wid, len, hei, realWeight);
                const formattedSmallPackAppWeight = apyWeightCalculation("D14", wid, len, hei, realWeight);
                const formattedSeaAppWeight = apyWeightCalculation("D03", wid, len, hei, realWeight);
                const formattedDhlAppWeight = apyWeightCalculation("D04", wid, len, hei, realWeight);

                setFedExAppWeight(formattedFedexAppWeight);
                setFedExEcoAppWeight(formattedFedexEcoAppWeight);
                setUpsAppWeight(formattedUpsAppWeight);
                setUpsEcoAppWeight(formattedUpsEcoAppWeight);
                setEmsAppWeight(formattedEmsAppWeight);
                setEmsPreAppWeight(formattedEmsPreAppWeight);
                setKpackAppWeight(formattedKpackAppWeight);
                setSmallPackAppWeight(formattedSmallPackAppWeight)
                setSeaAppWeight(formattedSeaAppWeight)
                setDhlAppWeight(formattedDhlAppWeight);

                console.log("formattedSeaAppWeight : ", formattedSeaAppWeight)

                // 가격찾고 서비스하지 않는 국가로 인해 null이면 '별도문의 반환'
                let fedexPrice = data.exrInfoFEDEXDTO ? findPrice(formattedFedexAppWeight, data.exrInfoFEDEXDTO) : t('cspWtNoSurch');
                let fedexEcoPrice = data.exrInfoFEDEXECODTO ? findPrice(formattedFedexEcoAppWeight, data.exrInfoFEDEXECODTO) : t('cspWtNoSurch');
                let upsPrice = data.exrInfoUPSDTO ? findPrice(formattedUpsAppWeight, data.exrInfoUPSDTO) : t('cspWtNoSurch');
                let upsEcoPrice = data.exrInfoUPSECODTO ? findPrice(formattedUpsEcoAppWeight, data.exrInfoUPSECODTO) : t('cspWtNoSurch');
                let dhlPrice = data.exrInfoDHLDTO ? findPrice(formattedDhlAppWeight, data.exrInfoDHLDTO) : t('cspWtNoSurch');
                let emsPrice = data.exrInfoEMSDTO ? findPrice(formattedEmsAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let emsPrePrice = data.exrInfoEMSPREDTO ? findPrice(formattedEmsPreAppWeight, data.exrInfoEMSPREDTO) : t('cspWtNoSurch');
                let kpackPrice = data.exrInfoKPACKDTO ? findPrice(formattedKpackAppWeight, data.exrInfoKPACKDTO) : t('cspWtNoSurch');
                let smallPackPrice = data.exrInfoAIRSDTO ? findPrice(realWeight, data.exrInfoAIRSDTO) : t('cspWtNoSurch');
                let seaPrice = data.exrInfoSHIPDTO ? findPrice(realWeight, data.exrInfoSHIPDTO) : t('cspWtNoSurch');

                // fedex 유류할증료 합산
                if (typeof fedexPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexPrice = Math.round(fedexPrice / 10) * 10;
                }
                // fedex 운송수수료 합산
                if (typeof fedexPrice === "number" && data.countryDTO.fedexFee > 0) {
                    const additionalFedexFee = formattedFedexAppWeight * 10 * data.countryDTO.fedexFee;
                    fedexPrice += additionalFedexFee;
                }

                // fedex-e 유류할증료 합산
                if (typeof fedexEcoPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexEcoPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexEcoPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexEcoPrice = Math.round(fedexEcoPrice / 10) * 10;
                }
                // fedex-e 운송수수료 합산
                if (typeof fedexEcoPrice === "number" && data.countryDTO.fedexEcoFee > 0) {
                    const additionalFedexEcoFee = formattedFedexEcoAppWeight * 10 * data.countryDTO.fedexEcoFee;
                    fedexEcoPrice += additionalFedexEcoFee;
                }

                // ups 유류할증료 합산
                if (typeof upsPrice === "number" && data.countryDTO && data.countryDTO.upsFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.upsFuelSurcharge / 100;
                    const fuelSurcharge = upsPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    upsPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    upsPrice = Math.round(upsPrice / 10) * 10;
                }
                // ups 운송수수료 합산
                if (typeof upsPrice === "number" && data.countryDTO.upsFee > 0) {
                    const additionalUpsFee = formattedUpsAppWeight * 10 * data.countryDTO.upsFee;
                    upsPrice += additionalUpsFee;
                }

                // ups-e 유류할증료 합산
                if (typeof upsEcoPrice === "number" && data.countryDTO && data.countryDTO.upsFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.upsFuelSurcharge / 100;
                    const fuelSurcharge = upsEcoPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    upsEcoPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    upsEcoPrice = Math.round(upsEcoPrice / 10) * 10;
                }
                // ups 운송수수료 합산
                if (typeof upsEcoPrice === "number" && data.countryDTO.upsEcoFee > 0) {
                    const additionalUpsEcoFee = formattedUpsEcoAppWeight * 10 * data.countryDTO.upsEcoFee;
                    upsEcoPrice += additionalUpsEcoFee;
                }

                // dhl 유류할증료 합산
                if (typeof dhlPrice === "number" && data.countryDTO && data.countryDTO.dhlFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 dhlPrice에서 dhlFuelSurcharge값만큼 dhlPrice에 +=
                    const surchargeRate = data.countryDTO.dhlFuelSurcharge / 100;
                    const fuelSurcharge = dhlPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    dhlPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    dhlPrice = Math.round(dhlPrice / 10) * 10;
                }
                // dhl 운송수수료 합산
                if (typeof dhlPrice === "number" && data.countryDTO.dhlFee > 0) {
                    const additionalDhlFee = formattedFedexAppWeight * 10 * data.countryDTO.dhlFee;
                    dhlPrice += additionalDhlFee;
                }

                // ems 운송수수료 합산
                if (typeof emsPrice === "number" && data.countryDTO.emsFee > 0) {
                    const additionalEmsFee = formattedEmsAppWeight * 10 * data.countryDTO.emsFee;
                    emsPrice += additionalEmsFee;
                }

                // emsp 운송수수료 합산
                if (typeof emsPrePrice === "number" && data.countryDTO.emsPreFee > 0) {
                    const additionalEmsPreFee = formattedEmsPreAppWeight * 10 * data.countryDTO.emsPreFee;
                    emsPrePrice += additionalEmsPreFee;
                }

                // K-Packet 운송수수료 합산
                if (typeof kpackPrice === "number" && data.countryDTO.kpackFee > 0) {
                    const additionalKpackFee = formattedKpackAppWeight * 10 * data.countryDTO.kpackFee;
                    kpackPrice += additionalKpackFee;
                }

                // Small-Packet 운송수수료 합산
                if (typeof smallPackPrice === "number" && data.countryDTO.airSFee > 0) {
                    const additionalSmallPackFee = formattedSmallPackAppWeight * 10 * data.countryDTO.airSFee;
                    smallPackPrice += additionalSmallPackFee;
                }

                // sea 운송수수료 합산
                if (typeof seaPrice === "number" && (data.countryDTO.countryCode === "US" || data.countryDTO.countryCode === "CA")) {
                    const additionalSeaFee = formattedSeaAppWeight * 10 * data.countryDTO.seaFee;
                    seaPrice += additionalSeaFee;
                }
                // console.log("smallPackPrice1 : ", smallPackPrice)
                setFedexPrice(fedexPrice);
                setFedexEcoPrice(fedexEcoPrice);
                setUpsPrice(upsPrice);
                setUpsEcoPrice(upsEcoPrice);
                setDhlPrice(dhlPrice);
                setEmsPrice(emsPrice);
                setEmsPrePrice(emsPrePrice);
                setKpackPrice(kpackPrice);
                setSmallPackPrice(smallPackPrice);
                setSeaPrice(seaPrice);

                // console.log("DATA: ", data)
                // console.log("smallPackPrice 2: ", smallPackPrice)
            })
            .catch((error) => {
                console.log("배송사별 요금조회 실패 : ", error)
            })
    })

    const handleNumericInput = (e, setter, allowDecimal = false) => {
        let value = e.target.value;
        let regex = allowDecimal ? /^[0-9]*\.?[0-9]*$/ : /^[0-9]*$/;

        if (regex.test(value)) {
            setter(value);
        }
    };

    return (
        <div className="display-flex just-cont-center align-center font-t-cont c-def" style={{minHeight: "700px"}}>

            <SeoHelmet>
                <title>배송료비교</title>
                <meta name="description" content="원하시는 해외배송사를 통해 견적받으세요"/>
                <link rel="canonical" href="https://www.btorage.com/UserCompareShipPg"/>
            </SeoHelmet>

            <div className="">
                <div className="display-flex ht-40 just-cont-sb align-center">
                    <div className="display-flex just-cont-center">
                        <img className="wd-30 ht-30" src={weight} alt="header"/>
                        <p className="font-t-title m-l-10 font-s-18 m-t-3">{t(`cspHeader`)}</p> {/*배송료비교*/}
                    </div>
                </div>
                <div className="wd-500 border-bt border-rd4 pd20">
                    <div className="display-flex align-center border-bottom-bt ht-40 m-b-20">
                        <p className="font-s-16">{t(`cspSmallHeader1`)}</p> {/*무게정보*/}
                    </div>
                    <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                        <div className="wd-30pc"><p className="font-s-14">{t(`modSmallMenu3`)}</p> {/*국가*/}</div>
                        <div className="wd-70pc">
                            <div ref={countryRef} className="ht-45 wd-100pc c-point position-relative"
                                 onClick={toggleCountryShow}>
                                <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                    {selectedCountry.value ? (
                                        <div className="wd-15pc text-center align-center-c">
                                            <div
                                                className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`}/>
                                        </div>
                                    ) : (
                                        <div className="wd-15pc text-center align-center-c p-l-5">
                                            <div
                                                className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?
                                            </div>
                                        </div>
                                    )}
                                    <input
                                        className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                        type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="nope"
                                        value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                        onChange={handleCountrySearch}
                                        onKeyDown={handleKeyDown}/>
                                    <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                        <BiCaretDown/>
                                    </i>
                                </div>
                                {countryShow && (
                                    <div className="country-select-data wd-100pc position-absolute m-t-5">
                                        <div className="country-select-data-child">
                                            {filteredCountryList.map((country, index) => (
                                                <div
                                                    className={`selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20 ${
                                                        index === selectedIndex ? "arrow-selected" : ""
                                                    }`} key={index}
                                                    onClick={(event) => handleCountryChange(country, event)}>
                                                    <div
                                                        className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                    <p className="font-s-15">{country.label}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                        <div className="wd-30pc"><p className="font-s-14">{t(`cspRw`)}(kg)</p> {/*무게*/}</div>
                        <div className="wd-70pc">
                            <input type="text"
                                   className="border-bt ht-45 wd-100pc p-l-5 border-rd4 font-s-16 font-b text-center"
                                   inputMode="numeric" pattern="\d*"
                                   placeholder="0.00" value={realWeight} maxLength="5"
                                   onChange={(e) => handleNumericInput(e, setRealWeight, true)}/>
                        </div>
                    </div>
                    <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                        <div className="wd-30pc"><p className="font-s-14">{t(`cspV`)}(cm)</p> {/*부피*/}</div>
                        <div className="wd-70pc display-flex flex-row just-cont-sb">
                            <div className="wd-28pc">
                                <input type="text"
                                       className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-16 font-b"
                                       inputMode="numeric" pattern="\d*"
                                       placeholder="0" value={wid} minLength="1" maxLength="3"
                                       onChange={(e) => handleNumericInput(e, setWid)}/>
                            </div>
                            <div className="align-center"><p className="font-s-18 p-t-10">*</p></div>
                            <div className="wd-28pc">
                                <input type="text"
                                       className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-16 font-b"
                                       inputMode="numeric" pattern="\d*"
                                       placeholder="0" value={len} minLength="1" maxLength="3"
                                       onChange={(e) => handleNumericInput(e, setLen)}/>
                            </div>
                            <div className="align-center"><p className="font-s-18 p-t-10">*</p></div>
                            <div className="wd-28pc">
                                <input type="text"
                                       className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-16 font-b"
                                       inputMode="numeric" pattern="\d*"
                                       placeholder="0" value={hei} minLength="1" maxLength="3"
                                       onChange={(e) => handleNumericInput(e, setHei)}/>
                            </div>
                        </div>
                    </div>
                    <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                        <div className="wd-30pc"><p className="font-s-14">{t(`cspAw`)}(kg)</p> {/*부피무게*/}</div>

                        {emsAppWeight && fedexAppWeight && (
                            <div className="wd-70pc display-flex flex-row just-cont-fstart" style={{color: "dimgray"}}>
                                <p>{emsAppWeight}kg</p><p>&nbsp;~&nbsp;</p><p>{fedexAppWeight}kg</p>
                            </div>
                        )}
                    </div>
                    <div className="m-b-40 text-center">
                        <button className="bt-btn-A wd-40pc ht-45 font-s-14"
                                onClick={initWeight}>{t(`cspBt`)} {/*초기화 버튼*/}</button>
                    </div>
                    <div className="display-flex align-center border-bottom-bt ht-40 m-b-20">
                        <p className="font-s-16">{t(`cspSmallHeader2`)}</p> {/*최종배송료*/}
                    </div>
                    <div className="display-flex flex-column gap3">
                        {/*FedEx 배송료*/}
                        <div className="display-flex ht-35">
                            <div className="wd-70pc display-flex align-center">
                                <p className="font-s-13">FedEx&nbsp;</p>
                                {fedexAppWeight ?
                                    <span className="font-s-12" style={{color: "dimgray"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {fedexAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div className="wd-70pc display-flex just-cont-fend align-center">
                                {/*추후 FedEx 배송료+유류할증료 연동*/}
                                <span className="font-s-13" style={{
                                    color: !fedexPrice ? "#1f1f1f" : "#FFAF34",
                                    fontWeight: fedexPrice ? "bold" : null
                                }}>
                                    &#8361; {fedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*FedEx-E 배송료*/}
                        <div className="display-flex ht-35">
                            <div className="wd-70pc display-flex align-center">
                                <p className="font-s-13">FedEx-E&nbsp;</p>
                                {fedexEcoAppWeight ?
                                    <span className="font-s-12" style={{color: "dimgray"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {fedexEcoAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div className="wd-70pc display-flex just-cont-fend align-center">
                                {/*추후 FedEx 배송료+유류할증료 연동*/}
                                <span className="font-s-13" style={{
                                    color: !fedexEcoPrice ? "#1f1f1f" : "#FFAF34",
                                    fontWeight: fedexEcoPrice ? "bold" : null
                                }}>
                                    &#8361; {fedexEcoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*UPS 배송료*/}
                        <div className="display-flex ht-35">
                            <div className="wd-70pc display-flex align-center">
                                <p className="font-s-13">UPS&nbsp;</p>
                                {upsAppWeight ?
                                    <span className="font-s-12" style={{color: "dimgray"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {upsAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div className="wd-70pc display-flex just-cont-fend align-center">
                                    <span className="font-s-13" style={{
                                        color: !upsPrice ? "#1f1f1f" : "#FFAF34",
                                        fontWeight: upsPrice ? "bold" : null
                                    }}>
                                    &#8361; {upsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*UPS-E 배송료*/}
                        <div className="display-flex ht-35">
                            <div className="wd-70pc display-flex align-center">
                                <p className="font-s-13">UPS-E&nbsp;</p>
                                {upsEcoAppWeight ?
                                    <span className="font-s-12" style={{color: "dimgray"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {upsEcoAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div className="wd-70pc display-flex just-cont-fend align-center">
                                    <span className="font-s-13" style={{
                                        color: !upsEcoPrice ? "#1f1f1f" : "#FFAF34",
                                        fontWeight: upsEcoPrice ? "bold" : null
                                    }}>
                                    &#8361; {upsEcoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*EMSP 배송료*/}
                        <div style={{height: "35px", display: "flex"}}>
                            <div style={{width: "70%", display: "flex", alignItems: "center"}}>
                                <p style={{
                                    margin: "0",
                                    fontSize: "13px",
                                    fontWeight: "lighter",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "black"
                                }}>EMS-Premium</p>
                                {emsPreAppWeight ?
                                    <span style={{fontSize: "12px", color: "dimgray", marginLeft: "5px"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {emsPreAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{
                                width: "30%",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                    <span style={{
                                        color: !emsPrePrice ? "black" : "orange",
                                        fontWeight: emsPrePrice ? "bold" : null
                                    }}>
                                    &#8361; {emsPrePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*EMS 배송료*/}
                        <div style={{height: "35px", display: "flex"}}>
                            <div style={{width: "70%", display: "flex", alignItems: "center"}}>
                                <p style={{
                                    margin: "0",
                                    fontSize: "13px",
                                    fontWeight: "lighter",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "black"
                                }}>EMS</p>
                                {emsAppWeight ?
                                    <span style={{fontSize: "12px", color: "dimgray", marginLeft: "5px"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {emsAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{
                                width: "30%",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                {/*추후 EMS 배송료+코로나수수료 연동*/}
                                <span style={{
                                    color: !emsPrice ? "black" : "orange",
                                    fontWeight: emsPrice ? "bold" : null
                                }}>
                                    &#8361; {emsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*K-P 배송료*/}
                        <div style={{height: "35px", display: "flex"}}>
                            <div style={{width: "70%", display: "flex", alignItems: "center"}}>
                                <p style={{
                                    fontSize: "13px",
                                    fontWeight: "lighter",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "black"
                                }}>K-Packet</p>
                                {kpackAppWeight ?
                                    <span style={{fontSize: "12px", color: "dimgray", marginLeft: "5px"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {kpackAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{
                                width: "30%",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                <span style={{
                                    color: !kpackPrice ? "black" : "orange",
                                    fontWeight: kpackPrice ? "bold" : null
                                }}>
                                    &#8361; {kpackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*Small-Packet 배송료*/}
                        <div style={{height: "35px", display: "flex"}}>
                            <div style={{width: "70%", display: "flex", alignItems: "center"}}>
                                <p style={{
                                    fontSize: "13px",
                                    fontWeight: "lighter",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "black"
                                }}>Small-Packet</p>
                                {smallPackAppWeight ?
                                    <span style={{fontSize: "12px", color: "dimgray", marginLeft: "5px"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {smallPackAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{
                                width: "30%",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                <span style={{
                                    color: !smallPackPrice ? "black" : "orange",
                                    fontWeight: smallPackPrice ? "bold" : null
                                }}>
                                    &#8361; {smallPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                            </div>
                        </div>
                        {/*선편 배송료*/}
                        <div style={{height: "35px", display: "flex"}}>
                            <div style={{width: "70%", display: "flex", alignItems: "center"}}>
                                <p style={{
                                    fontSize: "13px",
                                    fontWeight: "lighter",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "black"
                                }}>Sea Parcel</p>
                                {seaAppWeight ?
                                    <span style={{fontSize: "12px", color: "dimgray", marginLeft: "5px"}}>({t(`cspAw`)}
                                        <span className="font-c-btorage font-s-12 font-c-darkorange"> {seaAppWeight}</span>kg)</span>
                                    :
                                    null
                                }
                            </div>
                            <div style={{
                                width: "30%",
                                fontSize: "13px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                {/*추후 선편배송료 연동*/}
                                <span style={{
                                    color: !seaPrice ? "black" : "orange",
                                    fontWeight: seaPrice ? "bold" : null
                                }}>&#8361; {seaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "15px", height: "20px"}}><span
                        style={{fontSize: "12px", color: "dimgray"}}>※ {t('cspNt1')}</span></div>
                    <div><span style={{fontSize: "12px", color: "#4B89DC"}}>※ {t('cspNt2')}</span></div>
                </div>
            </div>
        </div>
    )
}

export default UserCompareShipPg
