import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import complete from "../../assets/img/complete.png";
import withAuth from "../../auth/UserRouteAuth";
import nobankPig from "../../assets/img/nobankPig.png";
import "../../css/mobile/MUserPayWaitPg.css";


const MUserPayWaitPg = () => {


    const {t} = useTranslation();
    const location = useLocation();
    const data = location.state.data;

    const navigate = useNavigate();

    const orderDetailPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    return (
        <div className={'m-a-user-pay-wait-page-container font-t-cont'}>
            <div className={'m-a-user-pay-wait-contents'}>
                <img className={'m-a-user-pay-wait-icon'} src={nobankPig} alt={''}/>
                <div className={'m-a-user-pay-wait-title'}>
                    {/*결제신청이 완료되었습니다*/}
                    {data?.payWay === "PM01" ? t('pwHeader4') : t(`pwCont1`)}
                </div>
                <div className={'m-a-user-pay-wait-title-sm'}>
                    {/*원활한 확인을 위해 24시간 내에 입금해주세요*/}
                    {data?.payWay === "PM01" ? t('pwCont3') : t(`pwCont2`)}
                </div>
                <div className={'m-a-user-pay-wait-divider'}/>
                <div className={'m-a-user-pay-wait-row'}>
                    <div className={'m-a-user-pay-wait-label'}>{t(`myTab1`)}</div> {/*주문서*/}
                    <div className={'m-a-user-pay-wait-value font-b'}>{data && data?.orderNumber}</div>
                </div>
                <div className={'m-a-user-pay-wait-row'}>
                    <div className={'m-a-user-pay-wait-label'}>{t(`myTab1`)}</div>
                    <div className={'m-a-user-pay-wait-value'}>
                        {data && data?.payWay === "PM00" ? t(`modNoBank`)
                            : data?.payWay === "PM02" ? 'WechatPay'
                                : data?.payWay === "PM03" ? 'AliPay'
                                    : data?.payWay === "PM03" ? 'Paypal'
                                    : null
                        }
                    </div>
                </div>
                {data && data?.payWay === "PM00" ?
                    <div className={'m-a-user-pay-wait-row'}>
                        <div className={'m-a-user-pay-wait-label'}>{t(`pwHeader1`)}</div> {/*수령인*/}
                        <div className={'m-a-user-pay-wait-value'}>
                            {t(`pwAcctNm`)}
                        </div>
                    </div>
                    :
                    null
                }
                {data && data?.payWay === "PM00" ?
                    <div className={'m-a-user-pay-wait-row'}>
                        <div className={'m-a-user-pay-wait-label'}>{t(`pwHeader2`)}</div>
                        <div className={'m-a-user-pay-wait-value'}>
                            {t(`pwAcctNum`)}
                        </div>
                    </div>
                    : data?.payWay === "PM02" ?
                        <div className={'m-a-user-pay-wait-row'}>
                            <div className={'m-a-user-pay-wait-label'}>Weixin ID</div>
                            <div className={'m-a-user-pay-wait-value'}>
                                lfamily-help
                            </div>
                        </div>
                        : data?.payWay === "PM03" ?
                            <div className={'m-a-user-pay-wait-row'}>
                                <div className={'m-a-user-pay-wait-label'}>{t(`pwHeader2`)}</div>
                                <div className={'m-a-user-pay-wait-value'}>
                                    13165072023
                                </div>
                            </div>
                            : null
                }
                <div className={'m-a-user-pay-wait-row'}>
                    <div className={'m-a-user-pay-wait-label'}>{t(`pcWon`)}</div> {/*최종원화금액*/}
                    <div className={'m-a-user-pay-wait-value font-b primary'}>
                        &#8361; {data && data?.lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                </div>
                {data && (data?.payWay === "PM02" || data?.payWay === "PM03" || data?.payWay === "PM01") &&
                    <div className={'m-a-user-pay-wait-row'}>
                        <div className={'m-a-user-pay-wait-label'}>
                            {(data?.payWay === "PM02" || data?.payWay === "PM03") ? t(`pwCny`) : t(`pcFor`)}
                        </div>
                        <div className={'m-a-user-pay-wait-value bold'}>
                            {data && data?.lastForPrice}
                            <span className={'m-a-user-pay-wait-value pale'}>
                                &nbsp;{data && data?.forCurrency}
                            </span>
                        </div>
                    </div>
                }
            </div>
            <div className={'m-a-user-pay-wait-buttons'}>
                <div className={'m-a-user-pay-wait-button'}
                     onClick={() => navigate("/UserMyPg")}>{t(`hdMypg`)}</div>
                <div className={'m-a-user-pay-wait-button primary'}
                     onClick={orderDetailPgMove(data.orderNumber)}>{t(`modHeader`)}</div>
            </div>
        </div>
    )
}

export default withAuth(MUserPayWaitPg);
