import '../../../css/web/SectionMain.css';
import '../../../css/web/ReviewPgSection.css';
import '../../../css/web/ReviewCard.css';
import React, {useEffect, useRef, useState} from 'react';
import mainReviewHeather from '../../../assets/img/mainReviewHeather.png';
import mainNoticeHeather from '../../../assets/img/mainNotice.png';
import mainFaqHeather from '../../../assets/img/mainFaq.png';
import CenterImg from '../../../assets/img/CenterIcon.png';
import LookupImg from '../../../assets/img/LookupImage.png';
import btoragePay from '../../../assets/img/BtorageLogo.png';
import creditCard from '../../../assets/img/payFormHeader.png';
import PaypalLogo from '../../../assets/img/PaypalLogo.png';
import WireBarleyLogo from '../../../assets/img/WireBarleyLogo.png';
import SentbeLogo from '../../../assets/img/SentbeLogo.png';
import StripeLogo from '../../../assets/img/StripeLogo.png';
import TransferWiseLogo from '../../../assets/img/TransferWiseLogo.png';
import AlipayLogo from '../../../assets/img/AlipayLogo.png';
import WechatPayLogo from '../../../assets/img/WechatPayLogo.png';
import commonEtc from '../../../assets/img/commonEtc.png';
import FedexLogo from '../../../assets/img/FedexLogo.png';

import EmsLogo from '../../../assets/img/EmsLogo.png';
import UpsLogo from '../../../assets/img/UpsLogo.png';
import Kpacket from '../../../assets/img/Kpacket.png';
import SmallPacket from "../../../assets/img/commonSmallPacket.png";
import SeaShipLogo from '../../../assets/img/SeaShipLogo.png';
import noData from '../../../assets/img/noData.png';
import {BiCaretDown} from "react-icons/bi"
import axios from '../../../services/axiosInstance';
import {useNavigate} from "react-router-dom";
import noReviewPhoto from "../../../assets/img/btorage_noimage.png";

import DOMPurify from "dompurify";
import {useTranslation} from "react-i18next";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import i18n from '../../../i18n';
import UserMainPopModal from "../../../page/web/UserMainPopModal";

function SectionMain() {
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const navigate = useNavigate();
    const [reviewAllList, setReviewAllList] = useState([]);
    const { t } = useTranslation();

    const selectReviewAllList = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewAllList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setReviewAllList(sortedData)
            })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const reviewPgMove = () => () => {
        navigate("/UserReviewPg");
    };


    const [selectedShipco, setSelectedShipco] = useState('');

    const [overseasShipcoList, setOverseasShipcoList] = useState([]);
    const bringToOverseasShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + '1',
    })
        .then((response) => {
            setOverseasShipcoList(response.data)
        })

    const [domesticShipcoList, setDomesticShipcoList] = useState([]);
    const bringToDomesticShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + '0',
    })
        .then((response) => {
            setDomesticShipcoList(response.data)
        })

    useEffect(() => {
        selectReviewAllList();
        bringToOverseasShipCo();
        bringToDomesticShipCo();
    }, [])

    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipcoRef.current && !shipcoRef.current.contains(event.target)) {
                setShipcoShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [selectedDomestic, setSelectedDomestic] = useState('');

    const [domesticShow, setDomesticShow] = useState(false);
    const domesticRef = useRef(null);

    const toggleDomesticShow = () => {
        setDomesticShow(!domesticShow);
    };

    const handleDomesticChange = (domestic) => {
        setSelectedDomestic(domestic);
        setDomesticShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (domesticRef.current && !domesticRef.current.contains(event.target)) {
                setDomesticShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const [osTrackNum, setOsTrackNum] = useState('');
    const [dsTrackNum, setDsTrackNum] = useState('');

    const handleOsTrackNum = ((e) => {
        setOsTrackNum(e);
    })

    const handleDsTrackNum = ((e) => {
        const filteredValue = e.replace(/\D/g,'');
        setDsTrackNum(filteredValue);
    })

    const selectOsTrackNum = (() => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedShipco === "FedEx" || selectedShipco === "FedEx-E") {
            const url = `https://www.fedex.com/fedextrack/?trknbr=${osTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedShipco === "UPS" || selectedShipco === "UPS-E") {
            const url = `https://www.ups.com/track?loc=ko_KR&trackNums=${osTrackNum}&requester=ST/trackdetails`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedShipco === "DHL") {
            const url = `https://www.dhl.com/kr-ko/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${osTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedShipco === "EMS" || selectedShipco === "SEA" || selectedShipco === "K-Packet" || selectedShipco === "Small-Packet"  || selectedShipco === "EMSP") {
            const url = `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=${osTrackNum}&displayHeader=N`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
    })

    const selectDsTrackNum = (() => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
        // etc 대신택배/일양택배 등
    })

    const [noticeList, setNoticeList] = useState([]);
    const selectAllNoticeList = (lang) => axios({
        method: 'get',
        url: '/notice/selectFixFirstCondition/',
        params: {
            limit: 10,
            lang: lang
        }
    })
        .then((response) => {
            const data = response.data.sort((a, b) => b.seqNum - a.seqNum);
            setNoticeList(data);
        })

    const [faqDtList, setFaqDtList] = useState([]);
    const selectFixFirstCondition = (lang) => axios({
        method: 'get',
        url: '/faq/selectFixFirstCondition/',
        params: {
            limit: 10,
            lang: lang
        }
    })
        .then((response) => {
            const data = response.data.sort((a, b) => b.seqNum - a.seqNum);
            setFaqDtList(data);
        })

    const moveToNoticeDtPg = ((seqNum) => {
        navigate(`/UserNoticeDtPg/${seqNum}`)
    })

    const moveToFaqDtPg = ((seqNum) => {
        navigate(`/UserFaqDtPg/${seqNum}`)
    })

    const newTextPrintFl = (regDate) => {
        const today = new Date();
        const todayFormatted = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
        return regDate === todayFormatted;
    };

    const [currentIndex, setCurrentIndex] = useState(1);
    const [banners, setBanners] = useState([]);
    const scrollRef = useRef();

    const selectMainBanners = (lang) => {
        axios({
            method: 'get',
            url: '/banner/selectFixFirstCondition/',
            params: {
                limit: 100,
                type: '0',
                lang: lang
            }
        })
            .then((response) => {
                const bannerData = response.data.map((data) => ({
                    source: { uri: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/bannerImg/" + data?.bannerPhotoDTO?.[0]?.fileNm }
                }));
                setBanners(bannerData);
                setCurrentIndex(1);
            })
            .catch((error) => {
                console.error("배너조회 오류: ", error);
            });
    };

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            let lang;

            switch (lng) {
                case 'en':
                    lang = '1';
                    break;
                case 'ja':
                    lang = '2';
                    break;
                case 'zh':
                    lang = '3';
                    break;
                case 'id':
                    lang = '4';
                    break;
                default:
                    lang = '0';
            }
            selectMainBanners(lang);
            selectAllNoticeList(lang);
            selectFixFirstCondition(lang);
        };

        handleLanguageChange(i18n.language);

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    return (
        <div className="SectionMain">
            <div className="BannerContainer">
                <Swiper className="scroll-container" modules={[Autoplay, Pagination, Navigation]}
                        slidesPerView={1} loop={true}
                        centeredSlides={true}
                        autoplay={{delay: 2500, disableOnInteraction: false,}}
                        pagination={{dynamicBullets: true,}}
                        navigation={true}
                        watchOverflow={true} //배너 이미지가 1개의 경우 페이지네이션, 네비게이션 비활성화
                >
                    {banners.map((banner, index) => (
                        <SwiperSlide className="image-container" key={index}>
                            <img className="banner-image" src={banner.source.uri} alt={`Slide ${index}`}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/*메인리뷰(12개표시(3x4)*/}
            <div className="wd-100pc m-t-30">
                <div className="MainHeatherContainer font-t-title">
                    <img style={{width:"35px", height:"35px", marginBottom:"5px"}} alt="mainReviewHeather" src={mainReviewHeather}/>
                    <p className="font-s-18 m-l-10">{t(`smHeader`)}</p>
                </div>
                {reviewAllList.length > 0 ?
                    <div className="display-flex flex-wrap just-cont-sb wd-100pc">
                        <div className="display-flex flex-wrap just-cont-fstart wd-100pc">
                            {reviewAllList.slice(0, 9).map((review, index) => (
                                <div key={index} className="m-b-30" style={{width: "32%",marginRight: ((index + 1) % 3 === 0) ? "0" : "2%"}}>
                                    <div className="reviewImage wd-100pc ht-330 c-point" onClick={()=> navigate(`/UserReviewDetailPg/${review.seqNum}`)}>
                                        {review && review.reviewPhotoDTO[0] && review.reviewPhotoDTO[0].fileNm ?
                                            <img alt="" className="ReviewImageTest"
                                                 src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + review.reviewPhotoDTO[0].fileNm}
                                                 style={{width: "100%", height: "100%", borderRadius: "6px"}}/>
                                            :
                                            <div className="wd-100pc ht-100pc align-center justify-content-center"
                                                 style={{backgroundColor: "#f6f6f6", borderRadius: "6px", padding: "40px"}}>
                                                <img alt="" className="wd-100pc ht-100pc" src={noReviewPhoto}/>
                                            </div>
                                        }
                                    </div>
                                    <div className="ReviewTitleCon display-flex m-t-5 c-def">
                                        <div className="ReviewTitle font-t-cont wd-85pc" style={{fontWeight: "lighter", margin: "0", padding: "0"}}>
                                            <p style={{fontSize: "15px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "break-all"}}>
                                                [{review.country}-{review.shipco}]
                                                <span style={{marginLeft: "5px"}}>{review.title}</span>
                                            </p>
                                        </div>
                                        <div className="wd-15pc">
                                            {newTextPrintFl(review.regDate) &&
                                                <p className="display-flex font-c-btorage" style={{justifyContent: "right", alignItems: "center"}}>New</p>}
                                        </div>
                                    </div>
                                    <div className="font-t-cont c-def display-flex wd-100pc " style={{alignItems: "end"}}>
                                        <div className="wd-50pc font-s-13 p-b-5">{formatDate(review.regDate)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {reviewAllList.length > 9 ?
                            <div className="user-center wd-100pc">
                                <div className="user-center m-t-20">
                                    <button className="bt-btn-E ht-30 wd-80 font-s-12" onClick={reviewPgMove()}>More</button>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    <div style={{textAlign: "center", margin: "auto"}}>
                        <img style={{width: "50px", height: "50px", opacity: "0.6"}} src={noData} alt="no data"/>
                        <p style={{fontSize: "17px", color: "#B9B9B9", marginTop: "5px"}}>No Data</p>
                    </div>
                }
                <div className="display-flex flex-column m-t-80">
                    <div className="display-flex flex-row just-cont-sb">
                        <div className="MainHeatherContainer wd-48pc font-t-title">
                            <img style={{width:"30px", height:"30px"}} alt="mainReviewHeather" src={mainNoticeHeather}/>
                            <p className="font-s-18 m-l-10">{t(`smNotice`)}</p>
                        </div>
                        <div className="MainHeatherContainer wd-48pc font-t-title">
                            <img style={{width:"30px", height:"30px"}} alt="mainReviewHeather" src={mainFaqHeather}/>
                            <p className="font-s-18 m-l-10">{t(`smFaq`)}</p>
                        </div>
                    </div>
                    {/*메인공지*/}
                    <div className="display-flex flex-row just-cont-sb wd-100pc">
                        <div className="wd-48pc">
                            <div className="NoticeAndFaqContainer font-t-cont font-c-default wd-100pc">
                                <div className="wd-100pc">
                                    {noticeList && noticeList.map((notice, i) => (
                                        <div key={i} onClick={()=> moveToNoticeDtPg(notice?.seqNum)} className="NoticeAndFaqTextContainer wd-100pc">
                                            <div className="wd-80pc" style={{display:"flex", alignItems:"center"}}>
                                                <p className="NoticeAndFaqText admin-tb-text-cut btorage-hover">{notice?.title}</p>
                                                {newTextPrintFl(notice.regDate) && <p className="NoticeAndFaqNewText">New</p>}
                                            </div>
                                            <div className="wd-20pc text-right">
                                                <p className="NoticeAndFaqText font-s-13">{formatDate(notice?.regDate)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="user-center" style={{margin:"10px 0"}}>
                                    <button onClick={()=> navigate("/UserNoticePg")} className="bt-btn-E ht-30 wd-80 font-s-12">More</button>
                                </div>
                            </div>
                        </div>
                        {/*FAQ*/}
                        <div className="wd-48pc">
                            <div className="NoticeAndFaqContainer font-t-cont font font-c-default wd-100pc">
                                <div className="wd-100pc">
                                    {faqDtList && faqDtList.map((faq, i) => (
                                        <div key={i} onClick={()=> moveToFaqDtPg(faq?.seqNum)} className="NoticeAndFaqTextContainer wd-100pc">
                                            <div className="wd-80pc p-r-5" style={{display:"flex", alignItems:"center"}}>
                                                <p className="NoticeAndFaqText" style={{marginRight:5, color:"darkgray", maxWidth:"180px"}}>[{faq?.faqMstDTO?.title}]</p>
                                                <p className="NoticeAndFaqText btorage-hover">{faq?.title}</p>
                                            </div>
                                            <div className="wd-20pc text-right">
                                                <p className="NoticeAndFaqText font-s-13">{formatDate(faq?.regDate)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="user-center" style={{margin:"10px 0"}}>
                                    <button onClick={()=> navigate("/UserFaqPg")} className="bt-btn-E ht-30 wd-80 font-s-12">More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="display-flex flex-column m-t-100 m-b-50">
                <div className="display-flex flex-row just-cont-sb">
                    <div className="CenterAddressCard font-t-cont" style={{position:"relative"}}>
                        <div className="CenterImage"><img alt="" src={CenterImg}/></div>
                        <div className="DeliveryCenterTitle">{t(`smCenter`)}</div> {/*배송대행 물류센터*/}
                        <div style={{marginTop:"15px"}}>{t(`smZipcode`)}</div> {/*우편번호 : 12722*/}
                        <div>{t(`smAddress`)} : 경기도 광주시 곤지암읍 연곡길42번길 32-15</div>
                        <div className="CenterInformation2">{t(`smTell`)}</div>
                        <div className="CenterInformation3">
                            <a>{t(`smMailbox`)}</a>
                        </div>
                        <div style={{display: "flex", justifyContent: "right", position:"absolute", bottom:"40px", right:"40px"}}>
                            <button className="bt-btn-B font-s-15 wd-120 ht-45 font-b">{t(`smPickupBt`)}</button> {/*픽업신청*/}
                        </div>
                    </div>
                    <div className="ParcelLookupCard font-t-cont" style={{position:"relative"}}>
                        <div className="ParcelImage"><img alt="" src={LookupImg}/></div>
                        <div className="ParcelTitle">{t(`smTrackHeader`)}</div> {/*배송조회*/}
                        <div className="ParcelText">{t(`smTrackContent1`)}</div>
                        <div className="ParcelForm" style={{position:"absolute", width:"87%", bottom:"40px"}}>
                            {/*국제배송조회*/}
                            <div style={{width:"100%", height:"50px", display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"5px"}}>
                                {/*국제배송 셀렉트*/}
                                <div style={{width:"35%", height:"100%"}}>
                                    <div ref={shipcoRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                onClick={toggleShipcoShow}>
                                            <p style={{color:"darkgray"}}>{selectedShipco === "" ? t(`ordOsDvLabel`) : selectedShipco}</p>
                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                            <i style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                color:"darkgray"
                                            }}><BiCaretDown/></i>
                                        </span>
                                        </button>
                                        {shipcoShow ?
                                            <div style={{
                                                width:"100%",
                                                border: "solid 1px lightgray",
                                                height: "200px",
                                                overflowY:"scroll",
                                                position: "absolute",
                                                top:"100%",
                                                bottom:"100%",
                                                zIndex:1,
                                                marginTop: "5px",
                                            }}>
                                                {overseasShipcoList.filter(shipco => shipco !== "국내배송").map((shipco, index) => (
                                                    <div className="selectChildBt" key={index} value={shipco}
                                                         onClick={() => handleShipcoChange(shipco)} style={{
                                                        display: "flex", padding: "10px", justifyContent: "center",
                                                        alignItems: "center", cursor: "pointer", fontSize: "14px",
                                                    }}>{shipco}</div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                {/*국제배송 인풋*/}
                                <div className="user-input-container" style={{width:"65%", height:"100%", marginLeft:"5px", position:"relative"}}>
                                    <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px 0 10px", fontSize:"14px", border: "none", outline: "none"}}
                                           value={osTrackNum} onChange={(e)=> handleOsTrackNum(e.target.value)} onKeyDown={(e) => { if(e.key === 'Enter') selectOsTrackNum() }}/>
                                    <div style={{position:"absolute", right:"3px", top:"30%"}}>
                                        <div style={{marginRight:"5px", cursor:"pointer"}}>
                                            <p onClick={selectOsTrackNum} style={{fontSize:"14px", color:"darkgray"}}>Enter</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*국내배송조회*/}
                            <div style={{width:"100%", height:"50px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                                {/*국내배송 셀렉트*/}
                                <div style={{width:"35%", height:"100%"}}>
                                    <div ref={domesticRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                onClick={toggleDomesticShow}>
                                            <p style={{color:"darkgray"}}>{selectedDomestic === "" ? t(`ordDsDvLabel`) : selectedDomestic}</p>
                                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    color:"darkgray"
                                }}><BiCaretDown/></i>
                            </span>
                                        </button>
                                        {domesticShow ?
                                            <div style={{
                                                width:"100%",
                                                height:"200px",
                                                overflowY:"scroll",
                                                border: "solid 1px lightgray",
                                                zIndex:1000,
                                                marginTop: "5px",
                                                position: "absolute",
                                                top:"100%",
                                                bottom:"100%",
                                            }}>
                                                {domesticShipcoList.filter(dom => dom !== "기타택배" && dom !== "쿠팡").map((domestic, index) => (
                                                    <div className="selectChildBt" key={index} value={domestic}
                                                         onClick={() => handleDomesticChange(domestic)} style={{
                                                        display: "flex", padding: "10px", justifyContent: "center",
                                                        alignItems: "center", cursor: "pointer", fontSize: "14px",
                                                    }}>{domestic}</div>
                                                ))}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                {/*국내배송 인풋*/}
                                <div className="user-input-container" style={{width:"65%", height:"100%", marginLeft:"5px", position:"relative"}}>
                                    <input className="user-input" style={{width:"100%", height:"100%", padding:"0 10px 0 10px", fontSize:"14px"}}
                                           value={dsTrackNum} onChange={(e)=> handleDsTrackNum(e.target.value)} onKeyDown={(e) => { if(e.key === 'Enter') selectDsTrackNum() }}/>
                                    <div style={{position:"absolute", right:"3px", top:"30%"}}>
                                        <div style={{marginRight:"5px", cursor:"pointer"}}>
                                            <p onClick={selectDsTrackNum} style={{margin:"0", fontSize:"14px", color:"darkgray"}}>Enter</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="display-flex flex-row just-cont-sb">
                    <div className="PayTypeCard font-t-cont" style={{position:"relative"}}>
                        <div className="PayTypeImgContainer">
                            <div className="PayTypeImage"><img alt="pay" src={btoragePay}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={creditCard}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={PaypalLogo}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={WireBarleyLogo}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={SentbeLogo}/></div>
                            <div className="StripeTypeImage"><img alt="pay" src={StripeLogo}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={TransferWiseLogo}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={AlipayLogo}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={WechatPayLogo}/></div>
                            <div className="PayTypeImage"><img alt="pay" src={commonEtc}/></div>
                        </div>
                        <div className="PayTypeTitle">{t(`smPayty`)}</div>
                        <div className="PayTypeInformation">Paypal, WireBarley, Sentbe,<br/> TransperWise, Stripe, Alipay,
                            Wechatpay,<br/> {t(`smPaytyContent`)}
                        </div>
                        <div style={{display: "flex", justifyContent: "right", marginTop: "117px", position:"absolute", bottom:"40px", right:"40px"}}>
                            <button className="bt-btn-B font-s-15 wd-120 ht-45 font-b">{t(`smPaytyBt`)}</button> {/*주문서이동*/}
                        </div>
                    </div>
                    <div className="DeliveryTypeCard font-t-cont position-relative">
                        <div className="DeliveryImgContainer">
                            <div className="FedexLogoImage"><img alt="" src={FedexLogo}/></div>
                            <div className="UpsLogoImage"><img alt="" src={UpsLogo}/></div>
                            <div className="SmallPacketLogoImage"><img alt="" src={SmallPacket}/></div>
                            <div className="EmsLogoImage"><img alt="" src={EmsLogo}/></div>
                            <div className="KpacketLogoImage"><img style={{width:"100%", height:"100%", objectFit:"contain"}} alt="" src={Kpacket}/></div>
                            <div className="SeaLogoImage"><img alt="" src={SeaShipLogo}/></div>
                        </div>
                        <div className="DeliveryTitle" style={{marginBottom: "18px"}}>{t(`smShipcoHeader`)}</div>
                        <div>{t(`smShipcoContent1`)}</div>
                        <div>{t(`smShipcoContent2`)}</div>
                        <div style={{display: "flex", justifyContent: "right", marginTop: "142px", position:"absolute", bottom:"40px", right:"40px"}}>
                            <button className="bt-btn-B font-s-15 wd-120 ht-45 font-b">{t(`smShipcoBt`)}</button> {/*요금견적*/}
                        </div>
                    </div>
                </div>
            </div>
            <UserMainPopModal />
        </div>
    );
}

export default SectionMain
