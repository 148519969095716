import React, {useCallback, useEffect, useState} from 'react';
import axios from '../../services/axiosInstance';
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminKorOutPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const SSAT = sessionStorage.getItem('adminToken');
    const LCAT = localStorage.getItem('adminToken');

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (data.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "확인 하시겠습니까?";
    const msgConfirmData2 = "해당 건이 출고 처리됩니다.";
    const modalCancel = useCallback (() => {setAlertShow(false);})
    const alert = (()=> {setAlertShow(true)})

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        if (cData.orderdtDTO && cData.orderdtDTO.memberDTO && cData.orderdtDTO.memberDTO.userName) {
            return (
                cData.orderdtDTO.memberDTO.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
                (cData.orderdtDTO.orderNumber && cData.orderdtDTO.orderNumber.toString().toLowerCase().includes(searchInput.toLowerCase()))
            );
        }
        return false;
    });

    //endregion

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};
    const stringStartDate = startDate.replace(/-/g, '');
    const stringEndDate = endDate.replace(/-/g, '');

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 30);

    const defaultMonth = () => {
        const stringStartDate = formatDate(twoWeeksAgo);
        const stringEndDate = formatDate(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    /** endregion */

    /** region Event */

    useEffect(() => {
        defaultMonth();
        dateSelect();
    }, [endDate])

    const dateSelect = (() => {
        axios({
            method: 'get',
            url: '/order/selectPayinfo',
            params: {
                startDate: stringStartDate,
                endDate: stringEndDate,
            },
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                console.log("adminErr[00] : ", error)
            })
    })

    const modifyData = () => {
        const seqNums = checked.join(",");

        axios({
            method: 'post',
            url: '/order/adminpayconfirm',
            params: {
                seqNum: seqNums,
                payStatus: 1
            }
        })
            .then(() => {
                setAlertShow(false);
                dateSelect();
            })
            .catch((error) => {
                console.log("adminErr[02] : ", error)
            });
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.seqNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '고객명': item.userName,
                    '수취인명': item.orderdtDTO.recinfoDTO.recname,
                    '배송사': item.orderdtDTO.shipco,
                    '배송비': 0,
                    '등록일시': item.regTime,
                    '결제일시': item.regTime,
                };
            }

            const sheetConfigs = {
                '국내출고관리': {
                    name: '국내출고관리',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '고객명', key: '고객명', width: 25, style: { alignment: { horizontal: 'center' }}},
                        {header: '수취인명', key: '수취인명', width: 25, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송사', key: '배송사', width: 10, style: { alignment: { horizontal: 'center' }}},
                        {header: '배송비', key: '배송비', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '등록일시', key: '등록일시', width: 15, style: { alignment: { horizontal: 'center' }}},
                        {header: '결제일시', key: '결제일시', width: 15, style: { alignment: { horizontal: 'center' }}}
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['국내출고관리'].name, sheetConfigs['국내출고관리'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `국내출고관리_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminKorOutPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">국내출고관리</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDown}>EXCEL</button>
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert}>출고확인</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                       onChange={allcheckBoxChange}/>
                            </div>
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-150"><p>고객명</p></div>
                            <div className="wd-100"><p>배송사</p></div>
                            <div className="wd-100"><p>배송비</p></div>
                            <div className="wd-140"><p>등록일시</p></div>
                            <div className="wd-140"><p>결제일시</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div className="wd-40">
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div className="wd-40"><p>{i + i + 1}</p></div>
                                <div className="wd-150"><p>{cData.orderdtDTO.memberDTO.userName}</p></div>
                                <div className="wd-100"><p>{cData.orderdtDTO.shipco}</p></div>
                                <div className="wd-100"><p>0</p></div>
                                <div className="wd-140"><p>{cData.regTime}</p></div>
                                <div className="wd-140"><p>{cData.regTime}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal eventHandle1={modalCancel} eventHandle2={modifyData} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
        </div>
    );
}

export default AdminKorOutPg
