import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Tab, Table, Tabs} from "react-bootstrap";
import {BsSquareFill} from "react-icons/bs";
import * as XLSX from "xlsx";
import axios from '../../services/axiosInstance';
import excel from "exceljs";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import excelLogo from "../../assets/img/HeaderLogo.png";

function AdminCurrencyRatePg() {

    /** region DataTable */

    const [emsData, setEmsData] = useState([]);
    const [emsPreData, setEmsPreData] = useState([]);
    const [fedexData, setFedexData] = useState([]);
    const [fedexEcoData, setFedexEcoData] = useState([]);
    const [shipData, setShipData] = useState([]);
    const [domeData, setDomeData] = useState([]);
    const [dhlData, setDhlData] = useState([]);
    const [upsData, setUpsData] = useState([]);
    const [upsEcoData, setUpsEcoData] = useState([]);
    const [kpackData, setKpackData] = useState([]);
    const [airSData, setAirSData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [excelData, setExcelData] = useState(null);
    const [excelDataep, setExcelDataep] = useState(null);
    const [excelDataf, setExcelDataf] = useState(null);
    const [excelDatafe, setExcelDatafe] = useState(null);
    const [excelDatas, setExcelDatas] = useState(null);
    const [excelDataDhl, setExcelDataDhl] = useState(null);
    const [excelDataUps, setExcelDataUps] = useState(null);
    const [excelDataUpse, setExcelDataUpse] = useState(null);
    const [excelDataKp, setExcelDataKp] = useState(null);
    const [excelDataAs, setExcelDataAs] = useState(null);
    const [selectedSheet, setSelectedSheet] = useState(null);
    const [selectedSheetp, setSelectedSheetp] = useState(null);
    const [selectedSheetf, setSelectedSheetf] = useState(null);
    const [selectedSheetfe, setSelectedSheetfe] = useState(null);
    const [selectedSheets, setSelectedSheets] = useState(null);
    const [selectedSheetDhl, setSelectedSheetDhl] = useState(null);
    const [selectedSheetUps, setSelectedSheetUps] = useState(null);
    const [selectedSheetUpse, setSelectedSheetUpse] = useState(null);
    const [selectedSheetKp, setSelectedSheetKp] = useState(null);
    const [selectedSheetAs, setSelectedSheetAs] = useState(null);

    /// region Alert

    const [alertShow, setAlertShow] = useState(false); /*FedEx*/
    const [alertShow2, setAlertShow2] = useState(false); /*FedEx-E*/
    const [alertShow3, setAlertShow3] = useState(false); /*EMS*/
    const [alertShow4, setAlertShow4] = useState(false); /*EMSP*/
    const [alertShow5, setAlertShow5] = useState(false); /*UPS*/
    const [alertShow6, setAlertShow6] = useState(false); /*UPSE*/
    const [alertShow7, setAlertShow7] = useState(false); /*KP*/
    const [alertShow8, setAlertShow8] = useState(false); /*AS*/
    const [alertShow9, setAlertShow9] = useState(false); /*SHIP*/
    const msgConfirmData1 = "기존 데이터에서 덮어 씌우시겠습니까?";
    const msgConfirmData2 = "저장 완료시 기존 요금정보는 복구 불가능합니다.";
    const alert = (() => {
        setAlertShow(true)
    })
    const alert2 = (() => {
        setAlertShow2(true)
    })
    const alert3 = (() => {
        setAlertShow3(true)
    })
    const alert4 = (() => {
        setAlertShow4(true)
    })
    const alert5 = (() => {
        setAlertShow5(true)
    })
    const alert6 = (() => {
        setAlertShow6(true)
    })
    const alert7 = (() => {
        setAlertShow7(true)
    })
    const alert8 = (() => {
        setAlertShow8(true)
    })
    const alert9 = (() => {
        setAlertShow9(true)
    })

    const modalCancel = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancel2 = useCallback(() => {
        setAlertShow2(false);
    })
    const modalCancel3 = useCallback(() => {
        setAlertShow3(false);
    })
    const modalCancel4 = useCallback(() => {
        setAlertShow4(false);
    })
    const modalCancel5 = useCallback(() => {
        setAlertShow5(false);
    })
    const modalCancel6 = useCallback(() => {
        setAlertShow6(false);
    })
    const modalCancel7 = useCallback(() => {
        setAlertShow7(false);
    })
    const modalCancel8 = useCallback(() => {
        setAlertShow8(false);
    })
    const modalCancel9 = useCallback(() => {
        setAlertShow9(false);
    })

    // endregion Alert

    const excelUploadEMS = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames;
            setSelectedSheet(sheetName[0]);
            setExcelData(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadEMSP = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamep = workbook.SheetNames;
            setSelectedSheetp(sheetNamep[0]);
            setExcelDataep(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadFEDEX = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesf = workbook.SheetNames;
            setSelectedSheetf(sheetNamesf[0]);
            setExcelDataf(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadFEDEXE = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesfe = workbook.SheetNames;
            setSelectedSheetfe(sheetNamesfe[0]);
            setExcelDatafe(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadSHIP = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNames = workbook.SheetNames;
            setSelectedSheets(sheetNames[0]);
            setExcelDatas(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadDHL = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesDhl = workbook.SheetNames;
            setSelectedSheetDhl(sheetNamesDhl[0]);
            setExcelDataDhl(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadUPS = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesUps = workbook.SheetNames;
            setSelectedSheetUps(sheetNamesUps[0]);
            setExcelDataUps(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadUPSE = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesUpse = workbook.SheetNames;
            setSelectedSheetUpse(sheetNamesUpse[0]);
            setExcelDataUpse(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadKP = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesKp = workbook.SheetNames;
            setSelectedSheetKp(sheetNamesKp[0]);
            setExcelDataKp(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const excelUploadAS = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesAs = workbook.SheetNames;
            setSelectedSheetAs(sheetNamesAs[0]);
            setExcelDataAs(workbook);
        };
        reader.readAsArrayBuffer(file);
    };

    const sheetSelectFEDEX = (event) => {
        const sheetNamef = event.target.value;
        setSelectedSheetf(sheetNamef);
    };

    const sheetSelectFEDEXE = (event) => {
        const sheetNamefe = event.target.value;
        setSelectedSheetfe(sheetNamefe);
    };

    const sheetSelectEMS = (event) => {
        const sheetName = event.target.value;
        setSelectedSheet(sheetName);
    };

    const sheetSelectEMSP = (event) => {
        const sheetNameep = event.target.value;
        setSelectedSheetp(sheetNameep);
    };

    const sheetSelectSHIP = (event) => {
        const sheetNames = event.target.value;
        setSelectedSheets(sheetNames);
    };

    const sheetSelectDHL = (event) => {
        const sheetNameDhl = event.target.value;
        setSelectedSheetDhl(sheetNameDhl);
    };

    const sheetSelectUPS = (event) => {
        const sheetNameUps = event.target.value;
        setSelectedSheetUps(sheetNameUps);
    };

    const sheetSelectUPSE = (event) => {
        const sheetNameUpse = event.target.value;
        setSelectedSheetUpse(sheetNameUpse);
    };

    const sheetSelectKP = (event) => {
        const sheetNameKp = event.target.value;
        setSelectedSheetKp(sheetNameKp);
    };

    const sheetSelectAS = (event) => {
        const sheetNameAs = event.target.value;
        setSelectedSheetAs(sheetNameAs);
    };

    const getSheetDataf = () => {
        if (!selectedSheetf || !excelDataf) {
            return [];
        }
        const worksheet = excelDataf.Sheets[selectedSheetf];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDatafe = () => {
        if (!selectedSheetfe || !excelDatafe) {
            return [];
        }
        const worksheet = excelDatafe.Sheets[selectedSheetfe];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetData = () => {
        if (!selectedSheet || !excelData) {
            return [];
        }
        const worksheet = excelData.Sheets[selectedSheet];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDataep = () => {
        if (!selectedSheetp || !excelDataep) {
            return [];
        }
        const worksheet = excelDataep.Sheets[selectedSheetp];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDatas = () => {
        if (!selectedSheets || !excelDatas) {
            return [];
        }

        const worksheet = excelDatas.Sheets[selectedSheets];

        // 전체 데이터 읽어오기
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // 첫 번째 열과 앞의 4행 제외하기
        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDataDhl = () => {
        if (!selectedSheetDhl || !excelDataDhl) {
            return [];
        }
        const worksheet = excelDataDhl.Sheets[selectedSheetDhl];
        return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    };

    const getSheetDataUps = () => {
        if (!selectedSheetUps || !excelDataUps) {
            return [];
        }
        const worksheet = excelDataUps.Sheets[selectedSheetUps];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDataUpse = () => {
        if (!selectedSheetUpse || !excelDataUpse) {
            return [];
        }
        const worksheet = excelDataUpse.Sheets[selectedSheetUpse];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDataKp = () => {
        if (!selectedSheetKp || !excelDataKp) {
            return [];
        }
        const worksheet = excelDataKp.Sheets[selectedSheetKp];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const getSheetDataAs = () => {
        if (!selectedSheetAs || !excelDataAs) {
            return [];
        }
        const worksheet = excelDataAs.Sheets[selectedSheetAs];
        const allData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        return allData.slice(5).map(row => row.slice(1));
    };

    const sheetData = getSheetData();
    const sheetDatap = getSheetDataep();
    const sheetDataf = getSheetDataf();
    const sheetDatafe = getSheetDatafe();
    const sheetDatas = getSheetDatas();
    const sheetDataDhl = getSheetDataDhl();
    const sheetDataUps = getSheetDataUps();
    const sheetDataUpse = getSheetDataUpse();
    const sheetDataKp = getSheetDataKp();
    const sheetDataAs = getSheetDataAs();

    const fileSelect = useRef(null);
    const fileSelectep = useRef(null);
    const fileSelectf = useRef(null);
    const fileSelectfe = useRef(null);
    const fileSelects = useRef(null);
    const fileSelectDhl = useRef(null);
    const fileSelectUps = useRef(null);
    const fileSelectUpse = useRef(null);
    const fileSelectKp = useRef(null);
    const fileSelectAs = useRef(null);

    const [editMode, setEditMode] = useState(null);
    const [editedWeights, setEditedWeights] = useState({});

    const handleEditClick = (cData) => {
        setEditMode(cData.seqNum);
        setEditedWeights({
            w3000: cData.w3000,
            w5000: cData.w5000,
            w10000: cData.w10000,
            w20000: cData.w20000,
            w25000: cData.w25000
        });
    };

    const handleFeeChange = (e, weightType) => {
        setEditedWeights(prevWeights => ({
            ...prevWeights,
            [weightType]: e.target.value
        }));
    };

    /** endregion */

    /** region Event */

    useEffect(() => {
        selectFEDEX();
        selectFEDEXE();
        selectUPS();
        selectUPSE();
        selectEMS();
        selectEMSP();
        selectSHIP();
        selectKP();
        selectAS();
        selectDOME();
    }, [])

    // region dataSelect

    const selectFEDEX = () => {
        axios({
            method: 'get',
            url: '/shipinfo/fedexexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setFedexData(response.data)
            })
            .catch(() => {});
    };

    const selectFEDEXE = () => {
        axios({
            method: 'get',
            url: '/shipinfo/fedexEcoexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setFedexEcoData(response.data)
            })
            .catch(() => {});
    };

    const selectUPS= () => {
        axios({
            method: 'get',
            url: '/shipinfo/upsexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUpsData(response.data)
            })
            .catch(() => {});
    };

    const selectUPSE= () => {
        axios({
            method: 'get',
            url: '/shipinfo/upsEcoexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUpsEcoData(response.data)
            })
            .catch(() => {});
    };

    const selectEMS = () => {
        axios({
            method: 'get',
            url: '/shipinfo/emsexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setEmsData(response.data)
            })
            .catch(() => {});
    };

    const selectEMSP = () => {
        axios({
            method: 'get',
            url: '/shipinfo/emsPreexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setEmsPreData(response.data)
            })
            .catch(() => {});
    };

    const selectSHIP= () => {
        axios({
            method: 'get',
            url: '/shipinfo/shipexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setShipData(response.data)
            })
            .catch(() => {});
    };

    const selectKP= () => {
        axios({
            method: 'get',
            url: '/shipinfo/kpexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setKpackData(response.data)
            })
            .catch(() => {});
    };

    const selectAS= () => {
        axios({
            method: 'get',
            url: '/shipinfo/airsexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAirSData(response.data)
            })
            .catch(() => {});
    };

    const selectDOME= () => {
        axios({
            method: 'get',
            url: '/shipinfo/domeexrdata',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setDomeData(response.data)
            })
            .catch(() => {});
    };

    // endregion dataSelect

    // region excelSave

    const transposeData = (data) => {
        if (data.length === 0) return [];

        const headers = data[0];
        return headers.slice(1).map((_, colIndex) => {
            return data.map(row => row[colIndex + 1]);
        });
    };

    const getCountryKpCode = (countryName) => {
        const countryCodes = {
            'Australia': 'AU',
            'Brazil': 'BR',
            'Canada': 'CA',
            'China': 'CN',
            'France': 'FR',
            'Germany': 'DE',
            'Hong Kong': 'HK',
            'Indonesia': 'ID',
            'Japan': 'JP',
            'Malaysia': 'MY',
            'New Zealand': 'NZ',
            'Philippines': 'PH',
            'Singapore': 'SG',
            'Spain': 'ES',
            'Taiwan': 'TW',
            'Thailand': 'TH',
            'United Kingdom': 'GB',
            'United States': 'US',
            'Vietnam': 'VN',
        };

        return countryCodes[countryName] || 'ZZ';
    };

    const saveExcelFileFEDEX = () => {
        const sheetDataf = getSheetDataf();
        const transposedData = transposeData(sheetDataf);
        const excelDataList = transposedData.map(rowData => {
            return {
                appGroup: rowData[0],
                w500: rowData[1], w1000: rowData[2], w1500: rowData[3], w2000: rowData[4], w2500: rowData[5],
                w3000: rowData[6], w3500: rowData[7], w4000: rowData[8], w4500: rowData[9], w5000: rowData[10],
                w5500: rowData[11], w6000: rowData[12], w6500: rowData[13], w7000: rowData[14], w7500: rowData[15],
                w8000: rowData[16], w8500: rowData[17], w9000: rowData[18], w9500: rowData[19], w10000: rowData[20],
                w10500: rowData[21], w11000: rowData[22], w11500: rowData[23], w12000: rowData[24], w12500: rowData[25],
                w13000: rowData[26], w13500: rowData[27], w14000: rowData[28], w14500: rowData[29], w15000: rowData[30],
                w15500: rowData[31], w16000: rowData[32], w16500: rowData[33], w17000: rowData[34], w17500: rowData[35],
                w18000: rowData[36], w18500: rowData[37], w19000: rowData[38], w19500: rowData[39], w20000: rowData[40],
                w20500: rowData[41], w21000: rowData[42], w22000: rowData[43], w23000: rowData[44], w24000: rowData[45],
                w25000: rowData[46], w26000: rowData[47], w27000: rowData[48], w28000: rowData[49], w29000: rowData[50], w30000: rowData[51]
            };
        });
        axios.post('/shipinfo/foamdatasavefedex', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow(false);
                setSelectedSheetf([]);
                selectFEDEX()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileFEDEXE = () => {
        const sheetDatafe = getSheetDatafe();
        const transposedData = transposeData(sheetDatafe);
        const excelDataList = transposedData.map(rowData => {
            return {
                appGroup: rowData[0],
                w500: rowData[1], w1000: rowData[2], w1500: rowData[3], w2000: rowData[4], w2500: rowData[5],
                w3000: rowData[6], w3500: rowData[7], w4000: rowData[8], w4500: rowData[9], w5000: rowData[10],
                w5500: rowData[11], w6000: rowData[12], w6500: rowData[13], w7000: rowData[14], w7500: rowData[15],
                w8000: rowData[16], w8500: rowData[17], w9000: rowData[18], w9500: rowData[19], w10000: rowData[20],
                w10500: rowData[21], w11000: rowData[22], w11500: rowData[23], w12000: rowData[24], w12500: rowData[25],
                w13000: rowData[26], w13500: rowData[27], w14000: rowData[28], w14500: rowData[29], w15000: rowData[30],
                w15500: rowData[31], w16000: rowData[32], w16500: rowData[33], w17000: rowData[34], w17500: rowData[35],
                w18000: rowData[36], w18500: rowData[37], w19000: rowData[38], w19500: rowData[39], w20000: rowData[40],
                w20500: rowData[41], w21000: rowData[42], w22000: rowData[43], w23000: rowData[44], w24000: rowData[45],
                w25000: rowData[46], w26000: rowData[47], w27000: rowData[48], w28000: rowData[49], w29000: rowData[50], w30000: rowData[51]
            };
        });
        axios.post('/shipinfo/foamdatasavefedexEco', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow2(false);
                setSelectedSheetfe([]);
                selectFEDEXE()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileUPS = () => {
        const sheetDataUps = getSheetDataUps();
        const transposedData = transposeData(sheetDataUps);
        const excelDataList = transposedData.map(rowData => {
            return {
                appUpsCode: rowData[0],
                w500: rowData[1], w1000: rowData[2], w1500: rowData[3], w2000: rowData[4], w2500: rowData[5], w3000: rowData[6], w3500: rowData[7], w4000: rowData[8],
                w4500: rowData[9], w5000: rowData[10], w5500: rowData[11], w6000: rowData[12], w6500: rowData[13], w7000: rowData[14], w7500: rowData[15], w8000: rowData[16],
                w8500: rowData[17], w9000: rowData[18], w9500: rowData[19], w10000: rowData[20], w10500: rowData[21], w11000: rowData[22], w11500: rowData[23], w12000: rowData[24],
                w12500: rowData[25], w13000: rowData[26], w13500: rowData[27], w14000: rowData[28], w14500: rowData[29], w15000: rowData[30], w15500: rowData[31], w16000: rowData[32],
                w16500: rowData[33], w17000: rowData[34], w17500: rowData[35], w18000: rowData[36], w18500: rowData[37], w19000: rowData[38], w19500: rowData[39], w20000: rowData[40],
                w21000: rowData[41], w22000: rowData[42], w23000: rowData[43], w24000: rowData[44], w25000: rowData[45], w26000: rowData[46], w27000: rowData[47],
                w28000: rowData[48], w29000: rowData[49], w30000: rowData[50], w31000: rowData[51], w32000: rowData[52], w33000: rowData[53], w34000: rowData[54], w35000: rowData[55],
                w36000: rowData[56], w37000: rowData[57], w38000: rowData[58], w39000: rowData[59], w40000: rowData[60], w41000: rowData[61], w42000: rowData[62], w43000: rowData[63],
                w44000: rowData[64], w45000: rowData[65], w46000: rowData[66], w47000: rowData[67], w48000: rowData[68], w49000: rowData[69], w50000: rowData[70], w51000: rowData[71],
                w52000: rowData[72], w53000: rowData[73], w54000: rowData[74], w55000: rowData[75], w56000: rowData[76], w57000: rowData[77], w58000: rowData[78], w59000: rowData[79],
                w60000: rowData[80], w61000: rowData[81], w62000: rowData[82], w63000: rowData[83], w64000: rowData[84], w65000: rowData[85], w66000: rowData[86], w67000: rowData[87],
                w68000: rowData[88], w69000: rowData[89], w70000: rowData[90],
            };
        });
        axios.post('/shipinfo/foamdatasaveups', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow5(false);
                setSelectedSheetUps([]);
                selectUPS();
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileUPSE = () => {
        const sheetDataUpse = getSheetDataUpse();
        const transposedData = transposeData(sheetDataUpse);
        const excelDataList = transposedData.map(rowData => {
            return {
                appUpsEcoCode: rowData[0],
                w500: rowData[1], w1000: rowData[2], w1500: rowData[3], w2000: rowData[4], w2500: rowData[5], w3000: rowData[6], w3500: rowData[7], w4000: rowData[8],
                w4500: rowData[9], w5000: rowData[10], w5500: rowData[11], w6000: rowData[12], w6500: rowData[13], w7000: rowData[14], w7500: rowData[15], w8000: rowData[16],
                w8500: rowData[17], w9000: rowData[18], w9500: rowData[19], w10000: rowData[20], w10500: rowData[21], w11000: rowData[22], w11500: rowData[23], w12000: rowData[24],
                w12500: rowData[25], w13000: rowData[26], w13500: rowData[27], w14000: rowData[28], w14500: rowData[29], w15000: rowData[30], w15500: rowData[31], w16000: rowData[32],
                w16500: rowData[33], w17000: rowData[34], w17500: rowData[35], w18000: rowData[36], w18500: rowData[37], w19000: rowData[38], w19500: rowData[39], w20000: rowData[40],
                w21000: rowData[41], w22000: rowData[42], w23000: rowData[43], w24000: rowData[44], w25000: rowData[45], w26000: rowData[46], w27000: rowData[47],
                w28000: rowData[48], w29000: rowData[49], w30000: rowData[50], w31000: rowData[51], w32000: rowData[52], w33000: rowData[53], w34000: rowData[54], w35000: rowData[55],
                w36000: rowData[56], w37000: rowData[57], w38000: rowData[58], w39000: rowData[59], w40000: rowData[60], w41000: rowData[61], w42000: rowData[62], w43000: rowData[63],
                w44000: rowData[64], w45000: rowData[65], w46000: rowData[66], w47000: rowData[67], w48000: rowData[68], w49000: rowData[69], w50000: rowData[70], w51000: rowData[71],
                w52000: rowData[72], w53000: rowData[73], w54000: rowData[74], w55000: rowData[75], w56000: rowData[76], w57000: rowData[77], w58000: rowData[78], w59000: rowData[79],
                w60000: rowData[80], w61000: rowData[81], w62000: rowData[82], w63000: rowData[83], w64000: rowData[84], w65000: rowData[85], w66000: rowData[86], w67000: rowData[87],
                w68000: rowData[88], w69000: rowData[89], w70000: rowData[90],
            };
        });
        axios.post('/shipinfo/foamdatasaveupsEco', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow6(false);
                setSelectedSheetUpse([]);
                selectUPS();
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileEMS = () => {
        const tableData = sheetData.slice(1).map((row) => {
            const rowData = {};
            sheetData[0].forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        const excelDataList = tableData.map((rowData) => {
            let countryCode;
            if (rowData['Country'] === 'Zone 1') {
                countryCode = 'LA';
            } else if (rowData['Country'] === 'Zone 2') {
                countryCode = 'BD';
            } else if (rowData['Country'] === 'Zone 3') {
                countryCode = 'IL';
            } else if (rowData['Country'] === 'Zone 4') {
                countryCode = 'MX';
            } else if (rowData['Country'] === 'New Zealand') {
                countryCode = 'NZ';
            } else if (rowData['Country'] === 'Taiwan') {
                countryCode = 'TW';
            } else if (rowData['Country'] === 'Germany') {
                countryCode = 'DE';
            } else if (rowData['Country'] === 'Russia') {
                countryCode = 'RU';
            } else if (rowData['Country'] === 'Malaysia') {
                countryCode = 'MY';
            } else if (rowData['Country'] === 'United States') {
                countryCode = 'US';
            } else if (rowData['Country'] === 'Vietnam') {
                countryCode = 'VN';
            } else if (rowData['Country'] === 'Brazil') {
                countryCode = 'BR';
            } else if (rowData['Country'] === 'Spain') {
                countryCode = 'ES';
            } else if (rowData['Country'] === 'Singapore') {
                countryCode = 'SG';
            } else if (rowData['Country'] === 'United Kingdom') {
                countryCode = 'GB';
            } else if (rowData['Country'] === 'Indonesia') {
                countryCode = 'ID';
            } else if (rowData['Country'] === 'Japan') {
                countryCode = 'JP';
            } else if (rowData['Country'] === 'China') {
                countryCode = 'CN';
            } else if (rowData['Country'] === 'Canada') {
                countryCode = 'CA';
            } else if (rowData['Country'] === 'Thailand') {
                countryCode = 'TH';
            } else if (rowData['Country'] === 'France') {
                countryCode = 'FR';
            } else if (rowData['Country'] === 'Philippines') {
                countryCode = 'PH';
            } else if (rowData['Country'] === 'Australia') {
                countryCode = 'AU';
            } else if (rowData['Country'] === 'Hong Kong') {
                countryCode = 'HK';
            } else {
                countryCode = 'ZZ';
            }
            return {
                countryCode,
                w500: rowData['500g'], w750: rowData['750g'], w1000: rowData['1000g'], w1250: rowData['1250g'], w1500: rowData['1500g'], w1750: rowData['1750g'], w2000: rowData['2000g'],
                w2500: rowData['2500g'], w3000: rowData['3000g'], w3500: rowData['3500g'], w4000: rowData['4000g'], w4500: rowData['4500g'], w5000: rowData['5000g'], w5500: rowData['5500g'],
                w6000: rowData['6000g'], w6500: rowData['6500g'], w7000: rowData['7000g'], w7500: rowData['7500g'], w8000: rowData['8000g'], w8500: rowData['8500g'], w9000: rowData['9000g'],
                w9500: rowData['9500g'], w10000: rowData['10000g'], w10500: rowData['10500g'], w11000: rowData['11000g'], w11500: rowData['11500g'], w12000: rowData['12000g'],
                w12500: rowData['12500g'], w13000: rowData['13000g'], w13500: rowData['13500g'], w14000: rowData['14000g'], w14500: rowData['14500g'], w15000: rowData['15000g'],
                w15500: rowData['15500g'], w16000: rowData['16000g'], w16500: rowData['16500g'], w17000: rowData['17000g'], w17500: rowData['17500g'], w18000: rowData['18000g'],
                w18500: rowData['18500g'], w19000: rowData['19000g'], w19500: rowData['19500g'], w20000: rowData['20000g'], w20500: rowData['20500g'], w21000: rowData['21000g'],
                w21500: rowData['21500g'], w22000: rowData['22000g'], w22500: rowData['22500g'], w23000: rowData['23000g'], w23500: rowData['23500g'], w24000: rowData['24000g'],
                w24500: rowData['24500g'], w25000: rowData['25000g'], w25500: rowData['25500g'], w26000: rowData['26000g'], w26500: rowData['26500g'], w27000: rowData['27000g'],
                w27500: rowData['27500g'], w28000: rowData['28000g'], w28500: rowData['28500g'], w29000: rowData['29000g'], w29500: rowData['29500g'], w30000: rowData['30000g']
            };
        });

        axios.post('/shipinfo/foamdatasave', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow3(false);
                setSelectedSheet([]);
                selectEMS()
            })
            .catch((error) => {setAlertShow2(false); console.error('Error:', error);});
    };

    const saveExcelFileEMSP = () => {
        const sheetDataEmsp = getSheetDataep();
        const transposedData = transposeData(sheetDataEmsp);

        const parseBigInt = (value) => {
            if (!value) return null;
            return parseInt(value.replace(/,/g, '').trim(), 10);
        };

        const excelDataList = transposedData.map(rowData => {
            return {
                appEmsPreCode: rowData[0],
                w500: parseBigInt(rowData[1]),
                w1000: parseBigInt(rowData[2]),
                w1500: parseBigInt(rowData[3]),
                w2000: parseBigInt(rowData[4]),
                w2500: parseBigInt(rowData[5]),
                w3000: parseBigInt(rowData[6]),
                w3500: parseBigInt(rowData[7]),
                w4000: parseBigInt(rowData[8]),
                w4500: parseBigInt(rowData[9]),
                w5000: parseBigInt(rowData[10]),
                w5500: parseBigInt(rowData[11]),
                w6000: parseBigInt(rowData[12]),
                w6500: parseBigInt(rowData[13]),
                w7000: parseBigInt(rowData[14]),
                w7500: parseBigInt(rowData[15]),
                w8000: parseBigInt(rowData[16]),
                w8500: parseBigInt(rowData[17]),
                w9000: parseBigInt(rowData[18]),
                w9500: parseBigInt(rowData[19]),
                w10000: parseBigInt(rowData[20]),
                w10500: parseBigInt(rowData[21]),
                w11000: parseBigInt(rowData[22]),
                w11500: parseBigInt(rowData[23]),
                w12000: parseBigInt(rowData[24]),
                w12500: parseBigInt(rowData[25]),
                w13000: parseBigInt(rowData[26]),
                w13500: parseBigInt(rowData[27]),
                w14000: parseBigInt(rowData[28]),
                w14500: parseBigInt(rowData[29]),
                w15000: parseBigInt(rowData[30]),
                w15500: parseBigInt(rowData[31]),
                w16000: parseBigInt(rowData[32]),
                w16500: parseBigInt(rowData[33]),
                w17000: parseBigInt(rowData[34]),
                w17500: parseBigInt(rowData[35]),
                w18000: parseBigInt(rowData[36]),
                w18500: parseBigInt(rowData[37]),
                w19000: parseBigInt(rowData[38]),
                w19500: parseBigInt(rowData[39]),
                w20000: parseBigInt(rowData[40]),
                w21000: parseBigInt(rowData[41]),
                w22000: parseBigInt(rowData[42]),
                w23000: parseBigInt(rowData[43]),
                w24000: parseBigInt(rowData[44]),
                w25000: parseBigInt(rowData[45]),
                w26000: parseBigInt(rowData[46]),
                w27000: parseBigInt(rowData[47]),
                w28000: parseBigInt(rowData[48]),
                w29000: parseBigInt(rowData[49]),
                w30000: parseBigInt(rowData[50]),
                w31000: parseBigInt(rowData[51]),
                w32000: parseBigInt(rowData[52]),
                w33000: parseBigInt(rowData[53]),
                w34000: parseBigInt(rowData[54]),
                w35000: parseBigInt(rowData[55]),
                w36000: parseBigInt(rowData[56]),
                w37000: parseBigInt(rowData[57]),
                w38000: parseBigInt(rowData[58]),
                w39000: parseBigInt(rowData[59]),
                w40000: parseBigInt(rowData[60]),
                w41000: parseBigInt(rowData[61]),
                w42000: parseBigInt(rowData[62]),
                w43000: parseBigInt(rowData[63]),
                w44000: parseBigInt(rowData[64]),
                w45000: parseBigInt(rowData[65]),
                w46000: parseBigInt(rowData[66]),
                w47000: parseBigInt(rowData[67]),
                w48000: parseBigInt(rowData[68]),
                w49000: parseBigInt(rowData[69]),
                w50000: parseBigInt(rowData[70]),
                w51000: parseBigInt(rowData[71]),
                w52000: parseBigInt(rowData[72]),
                w53000: parseBigInt(rowData[73]),
                w54000: parseBigInt(rowData[74]),
                w55000: parseBigInt(rowData[75]),
                w56000: parseBigInt(rowData[76]),
                w57000: parseBigInt(rowData[77]),
                w58000: parseBigInt(rowData[78]),
                w59000: parseBigInt(rowData[79]),
                w60000: parseBigInt(rowData[80]),
                w61000: parseBigInt(rowData[81]),
                w62000: parseBigInt(rowData[82]),
                w63000: parseBigInt(rowData[83]),
                w64000: parseBigInt(rowData[84]),
                w65000: parseBigInt(rowData[85]),
                w66000: parseBigInt(rowData[86]),
                w67000: parseBigInt(rowData[87]),
                w68000: parseBigInt(rowData[88]),
                w69000: parseBigInt(rowData[89]),
                w70000: parseBigInt(rowData[90]),
            };
        });

        axios.post('/shipinfo/foamdatasaveemsp', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow4(false);
                setSelectedSheetp([]);
                selectEMSP();
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileSHIP = () => {
        const tableData = sheetDatas.slice(1).map((row) => {
            const rowData = {};
            sheetDatas[0].forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        const excelDataList = tableData.map((rowData) => {
            let appShipCode;
            if (rowData['Zone'] === 'Zone 1') {
                appShipCode = 'Z1';
            } else if (rowData['Zone'] === 'Zone 2') {
                appShipCode = 'Z2';
            } else if (rowData['Zone'] === 'Zone 3') {
                appShipCode = 'Z3';
            } else {
                appShipCode = 'Z4';
            }
            return {
                appShipCode,
                w1000: rowData['1000g'], w2000: rowData['2000g'], w4000: rowData['4000g'], w6000: rowData['6000g'], w8000: rowData['8000g'], w10000: rowData['10000g'],
                w12000: rowData['12000g'], w14000: rowData['14000g'], w16000: rowData['16000g'], w18000: rowData['18000g'], w20000: rowData['20000g']
            };
        });
        axios.post('/shipinfo/foamdatasaveship', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow9(false);
                setSelectedSheets([]);
                selectSHIP()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileKP = () => {
        const sheetDataKp = getSheetDataKp();
        const transposedData = transposeData(sheetDataKp);

        const parseValue = (value) => {
            if (typeof value === 'string') {
                return parseInt(value.replace(/,/g, ''), 10) || 0;
            } else if (typeof value === 'number') {
                return value;
            }
            return 0;
        };

        const excelDataList = transposedData.map(rowData => ({
            countryCode: getCountryKpCode(rowData[0]),
            w100: parseValue(rowData[1]),
            w200: parseValue(rowData[2]),
            w300: parseValue(rowData[3]),
            w400: parseValue(rowData[4]),
            w500: parseValue(rowData[5]),
            w600: parseValue(rowData[6]),
            w700: parseValue(rowData[7]),
            w800: parseValue(rowData[8]),
            w900: parseValue(rowData[9]),
            w1000: parseValue(rowData[10]),
            w1100: parseValue(rowData[11]),
            w1200: parseValue(rowData[12]),
            w1300: parseValue(rowData[13]),
            w1400: parseValue(rowData[14]),
            w1500: parseValue(rowData[15]),
            w1600: parseValue(rowData[16]),
            w1700: parseValue(rowData[17]),
            w1800: parseValue(rowData[18]),
            w1900: parseValue(rowData[19]),
            w2000: parseValue(rowData[20])
        }));

        // const excelDataList = transposedData.map(rowData => {
        //     return {
        //         countryCode: getCountryKpCode(rowData[0]),
        //         w100: parseInt(rowData[1].replace(/,/g, ''), 10) || 0,
        //         w200: parseInt(rowData[2].replace(/,/g, ''), 10) || 0,
        //         w300: parseInt(rowData[3].replace(/,/g, ''), 10) || 0,
        //         w400: parseInt(rowData[4].replace(/,/g, ''), 10) || 0,
        //         w500: parseInt(rowData[5].replace(/,/g, ''), 10) || 0,
        //         w600: parseInt(rowData[6].replace(/,/g, ''), 10) || 0,
        //         w700: parseInt(rowData[7].replace(/,/g, ''), 10) || 0,
        //         w800: parseInt(rowData[8].replace(/,/g, ''), 10) || 0,
        //         w900: parseInt(rowData[9].replace(/,/g, ''), 10) || 0,
        //         w1000: parseInt(rowData[10].replace(/,/g, ''), 10) || 0,
        //         w1100: parseInt(rowData[11].replace(/,/g, ''), 10) || 0,
        //         w1200: parseInt(rowData[12].replace(/,/g, ''), 10) || 0,
        //         w1300: parseInt(rowData[13].replace(/,/g, ''), 10) || 0,
        //         w1400: parseInt(rowData[14].replace(/,/g, ''), 10) || 0,
        //         w1500: parseInt(rowData[15].replace(/,/g, ''), 10) || 0,
        //         w1600: parseInt(rowData[16].replace(/,/g, ''), 10) || 0,
        //         w1700: parseInt(rowData[17].replace(/,/g, ''), 10) || 0,
        //         w1800: parseInt(rowData[18].replace(/,/g, ''), 10) || 0,
        //         w1900: parseInt(rowData[19].replace(/,/g, ''), 10) || 0,
        //         w2000: parseInt(rowData[20].replace(/,/g, ''), 10) || 0
        //     };
        // });

        axios.post('/shipinfo/foamdatasavekpack', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow7(false);
                setSelectedSheetKp([]);
                selectKP()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    const saveExcelFileAS = () => {
        const tableData = sheetDataAs.slice(1).map((row) => {
            const rowData = {};
            sheetDataAs[0].forEach((header, index) => {
                rowData[header] = row[index];
            });
            return rowData;
        });

        const excelDataList = tableData.map((rowData) => {
            let appAsCode;
            if (rowData['Zone'] === 'Zone 1') {
                appAsCode = 'Z1';
            } else if (rowData['Zone'] === 'Zone 2') {
                appAsCode = 'Z2';
            } else if (rowData['Zone'] === 'Zone 3') {
                appAsCode = 'Z3';
            } else {
                appAsCode = 'Z4';
            }
            return {
                appAsCode: appAsCode,
                w100: rowData['100g'],w200: rowData['200g'],w300: rowData['300g'],w400: rowData['400g'],
                w500: rowData['500g'],w600: rowData['600g'],w700: rowData['700g'],w800: rowData['800g'],
                w900: rowData['900g'],w1000: rowData['1000g'],w1100: rowData['1100g'],w1200: rowData['1200g'],
                w1300: rowData['1300g'],w1400: rowData['1400g'],w1500: rowData['1500g'],w1600: rowData['1600g'],
                w1700: rowData['1700g'],w1800: rowData['1800g'],w1900: rowData['1900g'],w2000: rowData['2000g']
            };
        });
        axios.post('/shipinfo/foamdatasaveairs', excelDataList, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            },
        })
            .then(() => {
                setAlertShow8(false);
                setSelectedSheetAs([]);
                selectAS()
            })
            .catch((error) => {console.error('Error:', error);});
    };

    // endregion excelSave

    //region ExcelDown

    const excelDownFEDEX = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('FedEx');
        const sheetOne = workbook.getWorksheet('FedEx');

        sheetOne.columns = [
            {header: '', width: 0.5},
            {header: '', width: 13, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'weight(kg)',
            'A',
            'D',
            'F',
            'G',
            'H',
            'I',
            'J',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDataf.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        // sheetOne.getRow(2).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDataf.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:V4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]); // 병합된 셀의 첫 번째 셀 가져오기
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:V4') {
                firstCell.value = 'FedEx Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:M4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoSheet = workbook.addWorksheet('Group Reference');

        const headers = ['Group A', 'Group D', 'Group F', 'Group G', 'Group H', 'Group I', 'Group J', 'Group M', 'Group N', 'Group O', 'Group P', 'Group Q', 'Group R', 'Group S', 'Group T', 'Group U', 'Group V', 'Group W', 'Group X', 'Group Y'];
        headers.forEach((header, index) => {
            const columnIndex = index + 1;
            memoSheet.getCell(1, columnIndex).value = header;
            memoSheet.getCell(1, columnIndex).alignment = { horizontal: 'center', vertical: 'middle' };
            memoSheet.getCell(1, columnIndex).font = { name: '맑은 고딕', size: 11, bold: true };
        });

        const rowData = [
            /*A*/ ['Macau[MO]'],
            /*D*/ ['Cambodia[KH]', 'Laos[LA]', 'Mongolia[MN]', 'Brunei[BN]', 'Guam[GU]', 'Rota[RO]', 'Tinian[TI]'],
            /*F*/ ['Canada[CA]', 'United States[US]', 'Mexico[MX]', 'Austria[AT]', 'Puerto Rico[PR]'],
            /*G*/ [
                'Austria[AT]', 'Czech Republic[CZ]', 'Denmark[DK]', 'Finland[FI]', 'Greece[GR]',
                'Hungary[HU]', 'Ireland[IE]', 'Norway[NO]', 'Poland[PL]', 'Portugal[PT]',
                'Sweden[SE]', 'Switzerland[CH]', 'Czechia[CZ]', 'Faroe Islands[FO]',
                'Greenland[GL]', 'Liechtenstein[LI]', 'Luxembourg[LU]', 'Monaco[MC]',
                'Slovakia[SK]'
            ],
            /*H*/ [
                'Israel[IL]', 'Turkey[TR]', 'Russia[RU]', 'Ukraine[UA]', 'Uzbekistan[UZ]',
                'Albania[AL]', 'Andorra[AD]', 'Armenia[AM]', 'Azerbaijan[AZ]', 'Belarus[BY]',
                'Bosnia and Herzegovina[BA]', 'Bulgaria[BG]', 'Croatia[HR]', 'Cyprus[CY]',
                'Estonia[EE]', 'Gibraltar[GI]', 'Georgia[GE]', 'Iceland[IS]',
                'Kyrgyzstan[KG]', 'Kazakhstan[KZ]', 'Latvia[LV]', 'Lithuania[LT]',
                'North Macedonia[MK]', 'Malta[MT]', 'Moldova[MD]', 'Montenegro[ME]',
                'Romania[RO]', 'Serbia[RS]', 'Slovenia[SI]'
            ],
            /*I*/ [
                'Argentina[AR]', 'Brazil[BR]', 'Chile[CL]', 'Peru[PE]', 'American Samoa[AS]',
                'Anguilla[AI]', 'Antigua and Barbuda[AG]', 'Aruba[AW]', 'Bahamas[BS]',
                'Barbados[BB]', 'Barbuda[BB]', 'Belize[BZ]', 'Bermuda[BM]', 'Bolivia[BO]',
                'Bonaire[BQ]', 'British Virgin Islands[VG]', 'Cayman Islands[KY]',
                'Colombia[CO]', 'Cook Islands[CK]', 'Costa Rica[CR]', 'Curaçao[CW]',
                'Dominica[DM]', 'Saint Barthélemy[BL]', 'Saint Kitts and Nevis[KN]',
                'Suriname[SR]', 'Tahiti[PF]', 'Tonga[TO]', 'Tortola[VG]',
                'Trinidad and Tobago[TT]', 'Union Island[VC]', 'Vanuatu[VU]', 'Venezuela[VE]',
                'Marshall Islands[MH]', 'Martinique[MQ]', 'Micronesia[FM]', 'Montserrat[MS]',
                'Nevis[KN]', 'New Caledonia[NC]', 'Nicaragua[NI]', 'Norman Islands[VG]',
                'Northern Mariana Islands[MP]', 'Palau[PW]', 'Papua New Guinea[PG]',
                'Paraguay[PY]', 'Saba[BQ]', 'Saipan[MP]', 'Samoa[WS]', 'Dominican Republic[DO]',
                'Timor-Leste[TL]', 'Ecuador[EC]', 'El Salvador[SV]', 'Fiji[FJ]', 'Grenada[GD]',
                'Guadeloupe[GP]', 'Guyana[GY]', 'Haiti[HT]', 'Honduras[HN]', 'Jamaica[JM]'
            ],
            /*J*/ [
                'Bangladesh[BD]', 'Morocco[MA]', 'Qatar[QA]', 'United Arab Emirates[AE]',
                'Jordan[JO]', 'Saudi Arabia[SA]', 'Afghanistan[AF]', 'Algeria[DZ]',
                'Angola[AO]', 'Bahrain[BH]', 'Benin[BJ]', 'Bhutan[BT]', 'Burkina Faso[BF]',
                'Burundi[BI]', 'Cabo Verde[CV]', 'Cameroon[CM]', 'Botswana[BW]', 'Chad[TD]',
                'Congo[CG]', 'Democratic Republic of the Congo[CD]', 'Djibouti[DJ]',
                'Zimbabwe[ZW]', 'Zambia[ZM]', 'Yemen[YE]', 'Tanzania[TZ]', 'Tunisia[TN]',
                'Uganda[UG]', 'Eswatini[SZ]', 'Sri Lanka[LK]', 'South Africa[ZA]', 'Syria[SY]',
                'Togo[TG]', 'Senegal[SN]', 'Seychelles[SC]', 'Rwanda[RW]', 'Réunion[RE]',
                'Qatar[QA]', 'Palestine[PS]', 'Pakistan[PK]', 'Oman[OM]', 'Nigeria[NG]',
                'Niger[NE]', 'Nepal[NP]', 'Mozambique[MZ]', 'Namibia[NA]', 'Mauritius[MU]',
                'Mauritania[MR]', 'Mali[ML]', 'Maldives[MV]', 'Malawi[MW]', 'Madagascar[MG]',
                'Egypt[EG]', 'Eritrea[ER]', 'Ethiopia[ET]', 'Gabon[GA]', 'Gambia[GM]',
                'Ghana[GH]', 'Guinea[GN]', 'Iraq[IQ]', 'Côte d\'Ivoire[CI]', 'Kenya[KE]',
                'Kuwait[KW]', 'Lebanon[LB]', 'Lesotho[LS]', 'Liberia[LR]', 'Libya[LY]'
            ],
            /*M*/ ['Belgium[BE]', 'Germany[DE]', 'Italy[IT]', 'Netherlands[NL]', 'Spain[ES]', 'United Kingdom[GB]', 'France[FR]', 'Canary Islands[IC]', 'Channel Islands[JE]', 'Vatican City[VA]', 'San Marino[SM]'],
            /*N*/ ['Vietnam[VN]'],
            /*O*/ ['India[IN]'],
            /*P*/ ['Japan[JP]'],
            /*Q*/ ['Malaysia[MY]'],
            /*R*/ ['Thailand[TH]'],
            /*S*/ ['Philippines[PH]'],
            /*T*/ ['Indonesia[ID]'],
            /*U*/ ['Australia[AU]', 'New Zealand[NZ]', 'Norfolk Island[NF]'],
            /*V*/ ['Hong Kong[HK]'],
            /*W*/ ['China[CN]'],
            /*X*/ ['Taiwan[TW]'],
            /*Y*/ ['Singapore[SG]']
        ];

        rowData.forEach((columnData, columnIndex) => {
            columnData.forEach((value, rowIndex) => {
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).value = value;  // 데이터 입력
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'center', vertical: 'middle' };
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).font = { name: '맑은 고딕', size: 11 };
            });
        });

        const equalColumnWidth = 28;
        for (let i = 1; i <= headers.length; i++) {
            memoSheet.getColumn(i).width = equalColumnWidth;
        }

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[FedEx].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    const excelDownFEDEXE = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('FedEx-E');
        const sheetOne = workbook.getWorksheet('FedEx-E');

        sheetOne.columns = [
            {header: '', width: 0.5},
            {header: '', width: 13, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'weight(kg)',
            'A',
            'D',
            'F',
            'G',
            'H',
            'I',
            'J',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDatafe.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        // sheetOne.getRow(2).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDatafe.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:V4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]); // 병합된 셀의 첫 번째 셀 가져오기
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:V4') {
                firstCell.value = 'FedEx-Economy Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:M4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoSheet = workbook.addWorksheet('Group Reference');

        const headers = ['Group A', 'Group D', 'Group F', 'Group G', 'Group H', 'Group I', 'Group J', 'Group M', 'Group N', 'Group O', 'Group P', 'Group Q', 'Group R', 'Group S', 'Group T', 'Group U', 'Group V', 'Group W', 'Group X', 'Group Y'];
        headers.forEach((header, index) => {
            const columnIndex = index + 1;
            memoSheet.getCell(1, columnIndex).value = header;
            memoSheet.getCell(1, columnIndex).alignment = { horizontal: 'center', vertical: 'middle' };
            memoSheet.getCell(1, columnIndex).font = { name: '맑은 고딕', size: 11, bold: true };
        });

        const rowData = [
            /*A*/ ['Macau[MO]'],
            /*D*/ ['Cambodia[KH]', 'Laos[LA]', 'Mongolia[MN]', 'Brunei[BN]', 'Guam[GU]', 'Rota[RO]', 'Tinian[TI]'],
            /*F*/ ['Canada[CA]', 'United States[US]', 'Mexico[MX]', 'Austria[AT]', 'Puerto Rico[PR]'],
            /*G*/ [
                'Austria[AT]', 'Czech Republic[CZ]', 'Denmark[DK]', 'Finland[FI]', 'Greece[GR]',
                'Hungary[HU]', 'Ireland[IE]', 'Norway[NO]', 'Poland[PL]', 'Portugal[PT]',
                'Sweden[SE]', 'Switzerland[CH]', 'Czechia[CZ]', 'Faroe Islands[FO]',
                'Greenland[GL]', 'Liechtenstein[LI]', 'Luxembourg[LU]', 'Monaco[MC]',
                'Slovakia[SK]'
            ],
            /*H*/ [
                'Israel[IL]', 'Turkey[TR]', 'Russia[RU]', 'Ukraine[UA]', 'Uzbekistan[UZ]',
                'Albania[AL]', 'Andorra[AD]', 'Armenia[AM]', 'Azerbaijan[AZ]', 'Belarus[BY]',
                'Bosnia and Herzegovina[BA]', 'Bulgaria[BG]', 'Croatia[HR]', 'Cyprus[CY]',
                'Estonia[EE]', 'Gibraltar[GI]', 'Georgia[GE]', 'Iceland[IS]',
                'Kyrgyzstan[KG]', 'Kazakhstan[KZ]', 'Latvia[LV]', 'Lithuania[LT]',
                'North Macedonia[MK]', 'Malta[MT]', 'Moldova[MD]', 'Montenegro[ME]',
                'Romania[RO]', 'Serbia[RS]', 'Slovenia[SI]'
            ],
            /*I*/ [
                'Argentina[AR]', 'Brazil[BR]', 'Chile[CL]', 'Peru[PE]', 'American Samoa[AS]',
                'Anguilla[AI]', 'Antigua and Barbuda[AG]', 'Aruba[AW]', 'Bahamas[BS]',
                'Barbados[BB]', 'Barbuda[BB]', 'Belize[BZ]', 'Bermuda[BM]', 'Bolivia[BO]',
                'Bonaire[BQ]', 'British Virgin Islands[VG]', 'Cayman Islands[KY]',
                'Colombia[CO]', 'Cook Islands[CK]', 'Costa Rica[CR]', 'Curaçao[CW]',
                'Dominica[DM]', 'Saint Barthélemy[BL]', 'Saint Kitts and Nevis[KN]',
                'Suriname[SR]', 'Tahiti[PF]', 'Tonga[TO]', 'Tortola[VG]',
                'Trinidad and Tobago[TT]', 'Union Island[VC]', 'Vanuatu[VU]', 'Venezuela[VE]',
                'Marshall Islands[MH]', 'Martinique[MQ]', 'Micronesia[FM]', 'Montserrat[MS]',
                'Nevis[KN]', 'New Caledonia[NC]', 'Nicaragua[NI]', 'Norman Islands[VG]',
                'Northern Mariana Islands[MP]', 'Palau[PW]', 'Papua New Guinea[PG]',
                'Paraguay[PY]', 'Saba[BQ]', 'Saipan[MP]', 'Samoa[WS]', 'Dominican Republic[DO]',
                'Timor-Leste[TL]', 'Ecuador[EC]', 'El Salvador[SV]', 'Fiji[FJ]', 'Grenada[GD]',
                'Guadeloupe[GP]', 'Guyana[GY]', 'Haiti[HT]', 'Honduras[HN]', 'Jamaica[JM]'
            ],
            /*J*/ [
                'Bangladesh[BD]', 'Morocco[MA]', 'Qatar[QA]', 'United Arab Emirates[AE]',
                'Jordan[JO]', 'Saudi Arabia[SA]', 'Afghanistan[AF]', 'Algeria[DZ]',
                'Angola[AO]', 'Bahrain[BH]', 'Benin[BJ]', 'Bhutan[BT]', 'Burkina Faso[BF]',
                'Burundi[BI]', 'Cabo Verde[CV]', 'Cameroon[CM]', 'Botswana[BW]', 'Chad[TD]',
                'Congo[CG]', 'Democratic Republic of the Congo[CD]', 'Djibouti[DJ]',
                'Zimbabwe[ZW]', 'Zambia[ZM]', 'Yemen[YE]', 'Tanzania[TZ]', 'Tunisia[TN]',
                'Uganda[UG]', 'Eswatini[SZ]', 'Sri Lanka[LK]', 'South Africa[ZA]', 'Syria[SY]',
                'Togo[TG]', 'Senegal[SN]', 'Seychelles[SC]', 'Rwanda[RW]', 'Réunion[RE]',
                'Qatar[QA]', 'Palestine[PS]', 'Pakistan[PK]', 'Oman[OM]', 'Nigeria[NG]',
                'Niger[NE]', 'Nepal[NP]', 'Mozambique[MZ]', 'Namibia[NA]', 'Mauritius[MU]',
                'Mauritania[MR]', 'Mali[ML]', 'Maldives[MV]', 'Malawi[MW]', 'Madagascar[MG]',
                'Egypt[EG]', 'Eritrea[ER]', 'Ethiopia[ET]', 'Gabon[GA]', 'Gambia[GM]',
                'Ghana[GH]', 'Guinea[GN]', 'Iraq[IQ]', 'Côte d\'Ivoire[CI]', 'Kenya[KE]',
                'Kuwait[KW]', 'Lebanon[LB]', 'Lesotho[LS]', 'Liberia[LR]', 'Libya[LY]'
            ],
            /*M*/ ['Belgium[BE]', 'Germany[DE]', 'Italy[IT]', 'Netherlands[NL]', 'Spain[ES]', 'United Kingdom[GB]', 'France[FR]', 'Canary Islands[IC]', 'Channel Islands[JE]', 'Vatican City[VA]', 'San Marino[SM]'],
            /*N*/ ['Vietnam[VN]'],
            /*O*/ ['India[IN]'],
            /*P*/ ['Japan[JP]'],
            /*Q*/ ['Malaysia[MY]'],
            /*R*/ ['Thailand[TH]'],
            /*S*/ ['Philippines[PH]'],
            /*T*/ ['Indonesia[ID]'],
            /*U*/ ['Australia[AU]', 'New Zealand[NZ]', 'Norfolk Island[NF]'],
            /*V*/ ['Hong Kong[HK]'],
            /*W*/ ['China[CN]'],
            /*X*/ ['Taiwan[TW]'],
            /*Y*/ ['Singapore[SG]']
        ];

        rowData.forEach((columnData, columnIndex) => {
            columnData.forEach((value, rowIndex) => {
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).value = value;  // 데이터 입력
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'center', vertical: 'middle' };
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).font = { name: '맑은 고딕', size: 11 };
            });
        });

        const equalColumnWidth = 28;
        for (let i = 1; i <= headers.length; i++) {
            memoSheet.getColumn(i).width = equalColumnWidth;
        }

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[FedEx-Economy].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    const excelDownUPS = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('UPS');
        const sheetOne = workbook.getWorksheet('UPS');

        sheetOne.columns = [
            { header: '', width: 0.5 },
            { header: '', width: 12, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20,
            20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
            // , 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
            // 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            // 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
            // 61, 62, 63, 64, 65, 66, 67, 68, 69, 70
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'weight(kg)',
            'Z1',
            'Z2',
            'Z3',
            'Z4',
            'Z5',
            'Z6',
            'Z7',
            'Z8',
            'Z9',
            'Z0',

        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDataUps.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDataUps.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:L4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]); // 병합된 셀의 첫 번째 셀 가져오기
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:L4') {
                firstCell.value = 'UPS Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:L4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoSheet = workbook.addWorksheet('Zone Reference');

        const headers = ['Z1[Zone1]', 'Z2[Zone2]', 'Z3[Zone3]', 'Z4[Zone4]', 'Z5[Zone5]', 'Z6[Zone6]', 'Z7[Zone7]', 'Z8[Zone8]', 'Z9[Zone9]', 'Z0[Zone10]'];
        headers.forEach((header, index) => {
            const columnIndex = index + 1;
            memoSheet.getCell(1, columnIndex).value = header;
            memoSheet.getCell(1, columnIndex).alignment = { horizontal: 'center', vertical: 'middle' };
            memoSheet.getCell(1, columnIndex).font = { name: '맑은 고딕', size: 11, bold: true };
        });

        const rowData = [
            ['Singapore[SG]', 'Taiwan, China[TW]', 'Macau SAR, China[MO]'],
            ['Japan[JP]', 'Vietnam[VN]'],
            ['Indonesia[ID]', 'Thailand[TH]', 'Malaysia[MY]', 'Philippines[PH]'],
            ['New Zealand[NZ]', 'Australia[AU]', 'Norfolk Island[NF]', 'India[IN]'],
            ['United States[US]', 'Canada[CA]', 'Mexico[MX]'],
            ['United Kingdom[GB]', 'France[FR]', 'Germany[DE]', 'Spain[ES]', 'Netherlands[NL]', 'Poland[PL]', 'Luxembourg[LU]', 'Liechtenstein[LI]', 'Monaco[MC]', 'Vatican City[VA]', 'Belgium[BE]', 'San Marino[SM]', 'Sweden[SE]', 'Switzerland[CH]', 'Slovakia[SK]', 'Andorra[AD]', 'Italy[IT]', 'Czech Republic[CZ]'],
            ['Austria[AT]', 'Ireland[IE]', 'Greece[GR]', 'Norway[NO]', 'Denmark[DK]', 'Portugal[PT]', 'Finland[FI]'],
            ['Turkey[TR]', 'Russia[RU]', 'Laos[LA]', 'Chile[CL]', 'United Arab Emirates[AE]', 'Cambodia[KH]', 'Bangladesh[BD]', 'Saudi Arabia[SA]', 'Peru[PE]', 'Guadeloupe[GP]', 'Guatemala[GT]', 'Guam[GU]', 'Grenada[GD]', 'French Guiana[GF]', 'South Africa[ZA]', 'New Caledonia[NC]', 'Nicaragua[NI]', 'Dominican Republic[DO]', 'Dominica[DM]', 'Timor-Leste[TL]', 'Latvia[LV]', 'Romania[RO]', 'Lithuania[LT]', 'Martinique[MQ]', 'Marshall Islands[MH]', 'Montserrat[MS]', 'Maldives[MV]', 'Malta[MT]', 'Myanmar[MM]', 'Micronesia[FM]', 'Vanuatu[VU]', 'Bahrain[BH]', 'Barbados[BB]', 'Bahamas[BS]', 'St. Croix (Virgin Islands)[VI]', 'Virgin Gorda[VG]', 'Venezuela[VE]', 'Belize[BZ]', 'Botswana[BW]', 'Bolivia[BO]', 'Bhutan[BT]', 'Bulgaria[BG]', 'Brazil[BR]', 'Samoa[WS]', 'American Samoa[AS]', 'Cyprus[CY]', 'St. Lucia[LC]', 'St. Vincent & the Grenadines[VC]', 'St. Kitts and Nevis[KN]', 'Solomon Islands[SB]', 'Suriname[SR]', 'Sri Lanka[LK]', 'Slovenia[SI]', 'Aruba[AW]', 'Argentina[AR]', 'Iceland[IS]', 'El Salvador[SV]', 'Azerbaijan[AZ]', 'Antigua and Barbuda[AG]', 'Anguilla[AI]', 'Eritrea[ER]', 'Ecuador[EC]', 'Oman[OM]', 'Honduras[HN]', 'Wallis & Futuna Islands[WF]', 'Uruguay[UY]', 'Ukraine[UA]', 'Egypt[EG]', 'Jamaica[JM]', 'Gibraltar[GI]', 'Qatar[QA]', 'Costa Rica[CR]', 'Colombia[CO]', 'Kuwait[KW]', 'Croatia[HR]', 'Tonga[TO]', 'Panama[PA]', 'Paraguay[PY]', 'Pakistan[PK]', 'Papua New Guinea[PG]', 'Palau[PW]', 'Fiji[FJ]', 'Hungary[HU]'],
            ['Israel[IL]', 'Mongolia[MN]', 'Ghana[GH]', 'Gabon[GA]', 'Guyana[GY]', 'Gambia[GM]', 'Greenland[GL]', 'Guinea[GN]', 'Guinea-Bissau[GW]', 'Namibia[NA]', 'Nigeria[NG]', 'Nepal[NP]', 'Niger[NE]', 'Liberia[LR]', 'Lesotho[LS]', 'Reunion Island[RE]', 'Rwanda[RW]', 'Madagascar[MG]', 'Mayotte[YT]', 'Malawi[MW]', 'Mali[ML]', 'Morocco[MA]', 'Mauritius[MU]', 'Mauritania[MR]', 'Mozambique[MZ]', 'Montenegro[ME]', 'Moldova[MD]', 'Bermuda[BM]', 'Benin[BJ]', 'Belarus[BY]', 'Bosnia and Herzegovina[BA]', 'Burundi[BI]', 'Burkina Faso[BF]', 'Macedonia (FYROM)[MK]', 'Botswana[BW]', 'Northern Mariana Islands[MP]', 'Senegal[SN]', 'Serbia[RS]', 'Seychelles[SC]', 'Sierra Leone[SL]', 'Armenia[AM]', 'Afghanistan[AF]', 'Albania[AL]', 'Algeria[DZ]', 'Angola[AO]', 'Swaziland[SZ]', 'Estonia[EE]', 'Ethiopia[ET]', 'Jordan[JO]', 'Uganda[UG]', 'Uzbekistan[UZ]', 'Iraq[IQ]', 'Zambia[ZM]', 'Central African Republic[CF]', 'Djibouti[DJ]', 'Zimbabwe[ZW]', 'Chad[TD]', 'Cameroon[CM]', 'Cape Verde[CV]', 'Kazakhstan[KZ]', 'Kenya[KE]', 'Cote d\'Ivoire (Ivory Coast)[CI]', 'Congo (Brazzaville)[CG]', 'Tanzania[TZ]', 'Togo[TG]', 'Tunisia[TN]'],
            ['Southern China Mainland[CN]', 'Hong Kong[HK]', 'Southern China Mainland[CN]']
        ];

        rowData.forEach((columnData, columnIndex) => {
            columnData.forEach((value, rowIndex) => {
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).value = value;  // 데이터 입력
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'center', vertical: 'middle' };
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).font = { name: '맑은 고딕', size: 11 };
            });
        });

        const equalColumnWidth = 28;
        for (let i = 1; i <= headers.length; i++) {
            memoSheet.getColumn(i).width = equalColumnWidth;
        }

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[UPS].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownUPSE = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('UPS-E');
        const sheetOne = workbook.getWorksheet('UPS-E');

        sheetOne.columns = [
            { header: '', width: 0.5 },
            { header: '', width: 12, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20,
            20.5, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
            // , 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
            // 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            // 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
            // 61, 62, 63, 64, 65, 66, 67, 68, 69, 70
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'weight(kg)',
            'Z1',
            'Z2',
            'Z3',
            'Z4',
            'Z5',
            'Z6',
            'Z7',
            'Z8',
            'Z9',
            'Z0',

        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDataUpse.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDataUpse.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:L4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]); // 병합된 셀의 첫 번째 셀 가져오기
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:L4') {
                firstCell.value = 'UPS-Economy Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:L4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoSheet = workbook.addWorksheet('Zone Reference');

        const headers = ['Z1[Zone1]', 'Z2[Zone2]', 'Z3[Zone3]', 'Z4[Zone4]', 'Z5[Zone5]', 'Z6[Zone6]', 'Z7[Zone7]', 'Z8[Zone8]', 'Z9[Zone9]', 'Z0[Zone10]'];
        headers.forEach((header, index) => {
            const columnIndex = index + 1;
            memoSheet.getCell(1, columnIndex).value = header;
            memoSheet.getCell(1, columnIndex).alignment = { horizontal: 'center', vertical: 'middle' };
            memoSheet.getCell(1, columnIndex).font = { name: '맑은 고딕', size: 11, bold: true };
        });

        const rowData = [
            ['Singapore[SG]', 'Taiwan, China[TW]', 'Macau SAR, China[MO]'],
            ['Japan[JP]', 'Vietnam[VN]'],
            ['Indonesia[ID]', 'Thailand[TH]', 'Malaysia[MY]', 'Philippines[PH]'],
            ['New Zealand[NZ]', 'Australia[AU]', 'Norfolk Island[NF]', 'India[IN]'],
            ['United States[US]', 'Canada[CA]', 'Mexico[MX]'],
            ['United Kingdom[GB]', 'France[FR]', 'Germany[DE]', 'Spain[ES]', 'Netherlands[NL]', 'Poland[PL]', 'Luxembourg[LU]', 'Liechtenstein[LI]', 'Monaco[MC]', 'Vatican City[VA]', 'Belgium[BE]', 'San Marino[SM]', 'Sweden[SE]', 'Switzerland[CH]', 'Slovakia[SK]', 'Andorra[AD]', 'Italy[IT]', 'Czech Republic[CZ]'],
            ['Austria[AT]', 'Ireland[IE]', 'Greece[GR]', 'Norway[NO]', 'Denmark[DK]', 'Portugal[PT]', 'Finland[FI]'],
            ['Turkey[TR]', 'Russia[RU]', 'Laos[LA]', 'Chile[CL]', 'United Arab Emirates[AE]', 'Cambodia[KH]', 'Bangladesh[BD]', 'Saudi Arabia[SA]', 'Peru[PE]', 'Guadeloupe[GP]', 'Guatemala[GT]', 'Guam[GU]', 'Grenada[GD]', 'French Guiana[GF]', 'South Africa[ZA]', 'New Caledonia[NC]', 'Nicaragua[NI]', 'Dominican Republic[DO]', 'Dominica[DM]', 'Timor-Leste[TL]', 'Latvia[LV]', 'Romania[RO]', 'Lithuania[LT]', 'Martinique[MQ]', 'Marshall Islands[MH]', 'Montserrat[MS]', 'Maldives[MV]', 'Malta[MT]', 'Myanmar[MM]', 'Micronesia[FM]', 'Vanuatu[VU]', 'Bahrain[BH]', 'Barbados[BB]', 'Bahamas[BS]', 'St. Croix (Virgin Islands)[VI]', 'Virgin Gorda[VG]', 'Venezuela[VE]', 'Belize[BZ]', 'Botswana[BW]', 'Bolivia[BO]', 'Bhutan[BT]', 'Bulgaria[BG]', 'Brazil[BR]', 'Samoa[WS]', 'American Samoa[AS]', 'Cyprus[CY]', 'St. Lucia[LC]', 'St. Vincent & the Grenadines[VC]', 'St. Kitts and Nevis[KN]', 'Solomon Islands[SB]', 'Suriname[SR]', 'Sri Lanka[LK]', 'Slovenia[SI]', 'Aruba[AW]', 'Argentina[AR]', 'Iceland[IS]', 'El Salvador[SV]', 'Azerbaijan[AZ]', 'Antigua and Barbuda[AG]', 'Anguilla[AI]', 'Eritrea[ER]', 'Ecuador[EC]', 'Oman[OM]', 'Honduras[HN]', 'Wallis & Futuna Islands[WF]', 'Uruguay[UY]', 'Ukraine[UA]', 'Egypt[EG]', 'Jamaica[JM]', 'Gibraltar[GI]', 'Qatar[QA]', 'Costa Rica[CR]', 'Colombia[CO]', 'Kuwait[KW]', 'Croatia[HR]', 'Tonga[TO]', 'Panama[PA]', 'Paraguay[PY]', 'Pakistan[PK]', 'Papua New Guinea[PG]', 'Palau[PW]', 'Fiji[FJ]', 'Hungary[HU]'],
            ['Israel[IL]', 'Mongolia[MN]', 'Ghana[GH]', 'Gabon[GA]', 'Guyana[GY]', 'Gambia[GM]', 'Greenland[GL]', 'Guinea[GN]', 'Guinea-Bissau[GW]', 'Namibia[NA]', 'Nigeria[NG]', 'Nepal[NP]', 'Niger[NE]', 'Liberia[LR]', 'Lesotho[LS]', 'Reunion Island[RE]', 'Rwanda[RW]', 'Madagascar[MG]', 'Mayotte[YT]', 'Malawi[MW]', 'Mali[ML]', 'Morocco[MA]', 'Mauritius[MU]', 'Mauritania[MR]', 'Mozambique[MZ]', 'Montenegro[ME]', 'Moldova[MD]', 'Bermuda[BM]', 'Benin[BJ]', 'Belarus[BY]', 'Bosnia and Herzegovina[BA]', 'Burundi[BI]', 'Burkina Faso[BF]', 'Macedonia (FYROM)[MK]', 'Botswana[BW]', 'Northern Mariana Islands[MP]', 'Senegal[SN]', 'Serbia[RS]', 'Seychelles[SC]', 'Sierra Leone[SL]', 'Armenia[AM]', 'Afghanistan[AF]', 'Albania[AL]', 'Algeria[DZ]', 'Angola[AO]', 'Swaziland[SZ]', 'Estonia[EE]', 'Ethiopia[ET]', 'Jordan[JO]', 'Uganda[UG]', 'Uzbekistan[UZ]', 'Iraq[IQ]', 'Zambia[ZM]', 'Central African Republic[CF]', 'Djibouti[DJ]', 'Zimbabwe[ZW]', 'Chad[TD]', 'Cameroon[CM]', 'Cape Verde[CV]', 'Kazakhstan[KZ]', 'Kenya[KE]', 'Cote d\'Ivoire (Ivory Coast)[CI]', 'Congo (Brazzaville)[CG]', 'Tanzania[TZ]', 'Togo[TG]', 'Tunisia[TN]'],
            ['Southern China Mainland[CN]', 'Hong Kong[HK]', 'Southern China Mainland[CN]']
        ];

        rowData.forEach((columnData, columnIndex) => {
            columnData.forEach((value, rowIndex) => {
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).value = value;  // 데이터 입력
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'center', vertical: 'middle' };
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).font = { name: '맑은 고딕', size: 11 };
            });
        });

        const equalColumnWidth = 28;
        for (let i = 1; i <= headers.length; i++) {
            memoSheet.getColumn(i).width = equalColumnWidth;
        }

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[UPS-Economy].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownEMS = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('EMS');
        const sheetOne = workbook.getWorksheet('EMS');

        sheetOne.columns = [
            {header: '', width: 0.5},
            {header: '', width: 18, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            'New Zealand',
            'Taiwan',
            'Germany',
            'Russia',
            'Malaysia',
            'United States',
            'Vietnam',
            'Brazil',
            'Spain',
            'Singapore',
            'United Kingdom',
            'Indonesia',
            'Japan',
            'China',
            'Canada',
            'Thailand',
            'France',
            'Philippines',
            'Australia',
            'Hong Kong',
            'Zone 1',
            'Zone 2',
            'Zone 3',
            'Zone 4'
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'Country',
            '500g',
            '750g',
            '1000g',
            '1250g',
            '1500g',
            '1750g',
            '2000g',
            '2500g',
            '3000g',
            '3500g',
            '4000g',
            '4500g',
            '5000g',
            '5500g',
            '6000g',
            '6500g',
            '7000g',
            '7500g',
            '8000g',
            '8500g',
            '9000g',
            '9500g',
            '10000g',
            '10500g',
            '11000g',
            '11500g',
            '12000g',
            '12500g',
            '13000g',
            '13500g',
            '14000g',
            '14500g',
            '15000g',
            '15500g',
            '16000g',
            '16500g',
            '17000g',
            '17500g',
            '18000g',
            '18500g',
            '19000g',
            '19500g',
            '20000g',
            '20500g',
            '21000g',
            '21500g',
            '22000g',
            '22500g',
            '23000g',
            '23500g',
            '24000g',
            '24500g',
            '25000g',
            '25500g',
            '26000g',
            '26500g',
            '27000g',
            '27500g',
            '28000g',
            '28500g',
            '29000g',
            '29500g',
            '30000g',
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetData.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetData.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 },
            ext: { width: 168, height: 40 },
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:V4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]);
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:V4') {
                firstCell.value = 'EMS Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:V4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoData = [
            `[Zone 1] - Laos, Macau, Cambodia, Myanmar, Mongolia`,
            `[Zone 2] - Bangladesh, Brunei, India, Nepal, Sri Lanka, Maldives, Bhutan`,
            `[Zone 3] - Belgium, Denmark, Finland, Norway, Portugal, Switzerland, Sweden, Austria, Romania, Poland, Hungary, Czech Republic,`,
            `Bahrain, Iran, Iraq, Israel, Jordan, Türkiye, Kuwait, Saudi Arabia, Qatar, Syria, Papua New Guinea, Guam, Afghanistan, United Arab Emirates`,
            `[Zone 4] - Egypt, Kenya, Libya, Mexico, Panama, Argentina, Uruguay, Peru, Cuba, Haiti, Dominican Republic`
        ];

        const memoSheet = workbook.addWorksheet('Zone Reference');

        memoData.forEach((text, index) => {
            const rowIndex = index + 1;
            memoSheet.getCell(`A${rowIndex}`).value = text;
            memoSheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'top' };
            memoSheet.getCell(`A${rowIndex}`).font = { name: '맑은 고딕', size: 11 };
        });

        memoSheet.getColumn(1).width = 130;

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[EMS].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownEMSP = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('EMSP');
        const sheetOne = workbook.getWorksheet('EMSP');

        sheetOne.columns = [
            { header: '', width: 0.5 },
            { header: '', width: 12, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
            10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
            41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
            51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
            61, 62, 63, 64, 65, 66, 67, 68, 69, 70
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'weight(kg)',
            'Z1',
            'Z2',
            'Z3',
            'Z4',
            'ZR',
            'Z5'
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDatap.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDatap.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:H4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]); // 병합된 셀의 첫 번째 셀 가져오기
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:H4') {
                firstCell.value = 'EMSP Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:H4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoData = [
            `1지역 - A1: CN, HK, MO, TW`,
            `2지역 - A2: BN, KH, JP, LA, MY, SG, TH, VN`,
            `3지역 - AL, AM, AU, AT, AZ, BH, BE, BG, "BA", "CA", "HR", "CZ", "DK", "GR", "HU", "IS", "IN", "ID", "IE", "IT", "LV", "LU", "MV", "MD", "MN", "NL",`,
            `"NZ", "MK", "NO", "PG", "PH", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "CH", "TR", "US", "AE", "GB", "BY", "BT"`,
            `4지역 - DZ, AO, BS, BB, BJ, BM, BO, BW, "BF", "CM", "CV", "TD", "CL", "CO", "CR", "CI", "DJ", "EG", "ER", "ET", "FJ", "GT", "GY", "HT", "HN", "SV",`,
            `"IL", "JM", "JO", "KW", "LS", "LR", "ML", "MX", "MZ", "NI", "NE", "NG", "OM", "PK", "PA", "PY", "QA", "RW", "TZ", "TG", "ZM"`,
            `4-1지역 - RU`,
            `5지역 - "AF", "AD", "AG", "AR", "AW", "BD", "BZ", "BR", "BI", "CF", "CG", "DM", "DO", "TL", "GA", "GM", "GH", "GI", "GL", "GD", "GP", "GU", "GF", "GN",`,
            `"GW", "IQ", "KZ", "KE", "SZ", "KG", "LB", "LI", "LT", "MG", "MW", "MT", "MP", "MQ", "MR", "MU", "YT", "FM", "MC", "MS", "MA", "NA", "NP", "NC", "NF",`,
            `"PW", "PE", "ZA", "RE", "ME", "KN", "LC", "VC", "AS", "SM", "SA", "SN", "SC", "SL", "LK", "SR", "RS", "TO", "TN", "UG", "UA", "UY", "UZ", "VU", "VE", "VI", "VG", "WF", "WS", "ZW"`
        ];

        const memoSheet = workbook.addWorksheet('Zone Reference');

        memoData.forEach((text, index) => {
            const rowIndex = index + 1;
            memoSheet.getCell(`A${rowIndex}`).value = text;
            memoSheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'top' };
            memoSheet.getCell(`A${rowIndex}`).font = { name: '맑은 고딕', size: 11 };
        });

        memoSheet.getColumn(1).width = 160;

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[EMSP].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownSHIP = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('SEA');
        const sheetOne = workbook.getWorksheet('SEA');

        sheetOne.columns = [
            { header: '', width: 0.5},
            { header: '', width: 13, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
            { header: '', width: 10, style: { alignment: { horizontal: 'center' } }},
        ];

        const weights = [
            'Zone 1', 'Zone 2', 'Zone 3', 'Zone 4'
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'Zone',
            '1000g',
            '2000g',
            '4000g',
            '6000g',
            '8000g',
            '10000g',
            '12000g',
            '14000g',
            '16000g',
            '18000g',
            '20000g'
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDatas.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDatas.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:M4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]);
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:M4') {
                firstCell.value = 'SEA Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:M4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoSheet = workbook.addWorksheet('Zone Reference');

        const headers = ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4'];
        headers.forEach((header, index) => {
            const columnIndex = index + 1;
            memoSheet.getCell(1, columnIndex).value = header;
            memoSheet.getCell(1, columnIndex).alignment = { horizontal: 'center', vertical: 'middle' };
            memoSheet.getCell(1, columnIndex).font = { name: '맑은 고딕', size: 11, bold: true };
        });

        const rowData = [
            /*1*/ ['China[CN]', 'Hong Kong[HO]', 'Japan[JP]', 'Taiwan[TW]'],
            /*2*/ ['Bangladesh[BD]', 'Indonesia[IN]', 'Malaysia[MY]', 'Philippines[PH]', 'Singapore[SG]', 'Vietnam[VN]'],
            /*3*/ ['Australia[AU]', 'Canada[CA]', 'Germany[DE]', 'Spain[SE]', 'France[FR]', 'United Kingdom[GB]', 'Ireland[IE]', 'Netherlands[NL]'
                , 'New Zealand[NZ]', 'Poland[PL]', 'United States[US]'],
            /*4*/ ['Peru[PE]', 'Chile[CL]', 'Brazil[BZ]', 'South Africa[ZA]', 'Mexico[MX]'],
        ];

        rowData.forEach((columnData, columnIndex) => {
            columnData.forEach((value, rowIndex) => {
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).value = value;  // 데이터 입력
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'center', vertical: 'middle' };
                memoSheet.getCell(rowIndex + 2, columnIndex + 1).font = { name: '맑은 고딕', size: 11 };
            });
        });

        const equalColumnWidth = 28;
        for (let i = 1; i <= headers.length; i++) {
            memoSheet.getColumn(i).width = equalColumnWidth;
        }

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[SEA].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    const excelDownKP = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('K-Packet');
        const sheetOne = workbook.getWorksheet('K-Packet');

        sheetOne.columns = [
            { header: '', width: 0.5},
            {header: '', width: 11, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 15, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            '100',
            '200',
            '300',
            '400',
            '500',
            '600',
            '700',
            '800',
            '900',
            '1000',
            '1100',
            '1200',
            '1300',
            '1400',
            '1500',
            '1600',
            '1700',
            '1800',
            '1900',
            '2000'
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'Weight(g)',
            'Australia',
            'Brazil',
            'Canada',
            'China',
            'France',
            'Germany',
            'Hong Kong',
            'Indonesia',
            'Japan',
            'Malaysia',
            'New Zealand',
            'Philippines',
            'Singapore',
            'Spain',
            'Taiwan',
            'Thailand',
            'United Kingdom',
            'United States',
            'Vietnam'
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDataKp.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDataKp.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:U4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]);
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:U4') {
                firstCell.value = 'K-Packet Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:U4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[K-Packet].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    const excelDownAS = async () => {
        const workbook = new excel.Workbook();
        workbook.addWorksheet('SmallAir');
        const sheetOne = workbook.getWorksheet('SmallAir');

        sheetOne.columns = [
            { header: '', width: 0.5},
            {header: '', width: 13, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
            {header: '', width: 10, style: { alignment: { horizontal: 'center' }}},
        ];

        const weights = [
            'Zone 1', 'Zone 2', 'Zone 3', 'Zone 4'
        ];

        weights.forEach((weight, index) => {
            sheetOne.getCell(`B${index + 7}`).value = weight;
            sheetOne.getCell(`B${index + 7}`).alignment = { horizontal: 'center' };
        });

        const headerRowTitle = sheetOne.getRow(2);
        headerRowTitle.values = ['운임표는 각 배송사 측의 유류할증료 및 기타서비스 수수료가 제외된 금액입니다. (Shipping rates exclude fuel surcharges and other service fees from each carrier)'];
        headerRowTitle.font = { name: '맑은 고딕', size: 10 };
        headerRowTitle.alignment = { horizontal: 'left', vertical: 'middle' };
        headerRowTitle.height = 18;

        const headerRow = sheetOne.getRow(6);
        headerRow.values = [
            '',
            'Zone',
            '100g',
            '200g',
            '300g',
            '400g',
            '500g',
            '600g',
            '700g',
            '800g',
            '900g',
            '1000g',
            '1100g',
            '1200g',
            '1300g',
            '1400g',
            '1500g',
            '1600g',
            '1700g',
            '1800g',
            '1900g',
            '2000g',
        ];
        headerRow.font = {name: '맑은 고딕', bold: true, size: 11};
        headerRow.alignment = {horizontal: 'center', vertical: 'middle'};
        headerRow.height = 20;

        sheetDataAs.slice(1).forEach((row) => {
            sheetOne.addRow(row);
        });

        sheetOne.getRow(1).height = 3;
        sheetOne.getRow(3).values = [];
        sheetOne.getRow(4).values = [];
        sheetOne.getRow(5).height = 3;

        sheetDataAs.slice(1).forEach((row, i) => {
            sheetOne.addRow(row);
        });

        const response = await fetch(excelLogo);
        const imageBuffer = await response.arrayBuffer();

        const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: 'png',
        });

        sheetOne.addImage(imageId, {
            tl: { col: 1, row: 2 }, // top-left 위치
            ext: { width: 168, height: 40 }, //이미지 사이즈
            editAs: 'oneCell'
        });

        const mergeCells = [
            { range: 'B3:C4'},
            { range: 'D3:V4', fillColor: 'f8f8f8',  fontSize: 16, fontBold: true }
        ];

        mergeCells.forEach(({ range, fillColor, fontColor, fontSize, fontBold }) => {
            sheetOne.mergeCells(range);
            const firstCell = sheetOne.getCell(range.split(':')[0]); // 병합된 셀의 첫 번째 셀 가져오기
            firstCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: fillColor }
            };
            firstCell.font = {
                name: '맑은 고딕',
                color: { argb: fontColor },
                size: fontSize,
                bold: fontBold
            };
            firstCell.alignment = { horizontal: 'center', vertical: 'middle' };

            if (range === 'D3:V4') {
                firstCell.value = 'SmallAir Shipping Rate List';
            }
        });

        const addBorders = (range) => {
            const [startCell, endCell] = range.split(':');
            const [startCol, startRow] = startCell.match(/[A-Z]+|[0-9]+/g);
            const [endCol, endRow] = endCell.match(/[A-Z]+|[0-9]+/g);

            for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
                for (let col = startCol.charCodeAt(0); col <= endCol.charCodeAt(0); col++) {
                    const cell = sheetOne.getCell(String.fromCharCode(col) + row);
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            }
        };

        addBorders('B3:C4');
        addBorders('D3:V4');

        // 헤더옵션
        sheetOne.getRow(6).eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber !== 1) { //A열 제외 ㄱ
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ebf8fc' }
                };
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });

        // region Sheet2

        const memoData = [
            `[Zone 1] - "China", "Hong Kong", "Japan", "Macau", "Taiwan"`,
            `[Zone 2] - "Bangladesh", "Brunei", "Cambodia", "East Timor", "Indonesia", "Laos", "Malaysia", "Mongolia", "Myanmar", "Philippines", "Singapore", "Thailand", "Vietnam"`,
            `[Zone 3] - "Afghanistan", "Albania", "Andorra", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahrain", "Belarus", "Belgium", "Bhutan", "Bulgaria", "Bosnia and Herzegovina", "Canada", "Christmas Island", "Cocos (Keeling) Islands", "Croatia", "Cyprus",`,
            `"Czech Republic", "Denmark", "Estonia", "Finland", "France", "Georgia", "Germany", "Gibraltar", "Greece", "Greenland", "Guam", "Hungary", "Iceland", "Israel", "Italy", "Jordan", "Kazakhstan", "Kuwait", "Kyrgyzstan",`,
            `"Latvia", "Lebanon", "Liechtenstein", "Lithuania", "Luxembourg", "Maldives", "Malta", "Northern Mariana Islands", "Moldova", "Monaco", "Nepal", "Netherlands", "New Zealand", "Niue", "Norfolk Island", "North Macedonia", "Norway", "Oman", "Pakistan",`,
            `"Papua New Guinea", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Montenegro", "San Marino", "Saudi Arabia", "Slovakia", "Slovenia", "Spain", "Sri Lanka", "Sweden", "Switzerland", "Serbia", "Turkey", "United States", "Ukraine", "United Arab Emirates", "United Kingdom", "Uzbekistan", "Vatican City"`,
            `[Zone 4] - "Algeria", "Angola", "Antigua and Barbuda", "Argentina", "Aruba", "Bahamas", "Barbados", "Belize", "Benin", "Bermuda", "Botswana", "Brazil", "Burkina Faso", "Burundi", "Cameroon", "Cape Verde", "Central African Republic", "Chad", "Chile", "Colombia",`,
            `"Congo (Brazzaville)", "Costa Rica", "Ivory Coast", "Cuba", "Djibouti", "Dominica", "Dominican Republic", "Congo (Kinshasa)", "Egypt", "Equatorial Guinea", "Eritrea", "Ethiopia", "Fiji", "France (Overseas)", "Gabon", "Gambia", "Ghana", "Grenada", "Guadeloupe",`,
            `"Guatemala", "French Guiana", "Guinea", "Guinea-Bissau", "Guyana", "Honduras", "Jamaica", "Kenya", "Eswatini", "Lesotho", "Liberia", "Madagascar", "Malawi", "Mali", "Martinique", "Mauritania", "Mauritius", "Mayotte", "Mexico",`,
            `"Micronesia", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nauru", "New Caledonia", "Nicaragua", "Niger", "Nigeria", "Palau", "Peru", "South Africa", "Réunion", "Rwanda", "Saint Kitts and Nevis", "American Samoa", "Sao Tome and Principe", "Senegal",`,
            `"Seychelles", "Solomon Islands", "Somalia", "Sudan", "Suriname", "Tanzania", "Togo", "Tonga", "Tunisia", "Uganda", "Uruguay", "Vanuatu", "Venezuela", "United States Virgin Islands", "British Virgin Islands", "Wallis and Futuna", "Samoa", "Zambia", "Zimbabwe"`
        ];

        const memoSheet = workbook.addWorksheet('Zone Reference');

        memoData.forEach((text, index) => {
            const rowIndex = index + 1;
            memoSheet.getCell(`A${rowIndex}`).value = text;
            memoSheet.getCell(`A${rowIndex}`).alignment = { horizontal: 'left', vertical: 'top' };
            memoSheet.getCell(`A${rowIndex}`).font = { name: '맑은 고딕', size: 11 };
        });

        memoSheet.getColumn(1).width = 250;

        // endregion Sheet2

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Btorage 요금표[Small-Packet].xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }

    //endregion

    const handleSaveClick = (seqNum) => {
        const weightData = domeData.find(item => item.seqNum === seqNum);

        const weightToSubmit = {
            seqNum,
            w3000: editedWeights.w3000 || 0,
            w5000: editedWeights.w5000 || 0,
            w10000: editedWeights.w10000 || 0,
            w20000: editedWeights.w20000 || 0,
            w25000: editedWeights.w25000 || 0
        };

        axios.post('/shipinfo/updateDomePrice', weightToSubmit, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT,
            }
        })
            .then(() => {
                setDomeData(prevData => prevData.map(item =>
                    item.seqNum === seqNum ? { ...item, ...editedWeights } : item
                ));
                setEditMode(null);
            })
            .catch(error => {
                console.error("err", error);
            });
    };

    /** endregion */

    return (
        <div className="AdminCurrencyRatePg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">배송비용관리</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card">
                    <div className="display-flex flex-column wd-100pc p-b-10 m-b-20">
                        <a href="https://ems.epost.go.kr/front.EmsDeliveryDelivery02.postal" target="_blank" className="text-underline wd-200" style={{color: "#3177bb"}}>우체국 요금표 바로가기 ◁◁</a>
                        <p>EXCEL[양식] 버튼 클릭 후 업로드 엑셀양식을 다운 받은 후 각 배송사별 요금정보를 '값으로만'★복붙하여 업로드 및 저장</p>
                        <p style={{color: "#ff3535"}}>※ 주의 ※ 저장 즉시 배송비가 업데이트됩니다. 반드시 내용을 확인해주세요.</p>
                    </div>
                    <Tabs className="mandm-tab m-b-20 font-s-15" defaultActiveKey="mam-tab-main" style={{marginBottom: "20px", fontSize: "15px"}}>
                        <Tab eventKey="mam-tab-main" title="&nbsp;&nbsp;&nbsp;FedEx&nbsp;&nbsp;&nbsp;">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="m-l-10" style={{position: "relative"}}>
                                            <input className="font-s-16" type="file" onChange={excelUploadFEDEX} ref={fileSelectf}
                                                   style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                            <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectf.current.click()}
                                                    style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                            </button>
                                        </div>
                                        <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                        {selectedSheetf && (
                                            <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetf.value} onChange={sheetSelectFEDEX}
                                                    style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                {excelDataf.SheetNames.map((sheetName) => (
                                                    <option key={sheetName} value={sheetName}>
                                                        {sheetName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="admin-event-wrap-btn gap10">
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownFEDEX}>EXCEL[양식]</button>
                                    {/*<button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={generateFedExExcel}>EXCEL[비용]</button>*/}
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert}>저장</button>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className="admin-event-wrap">
                                        <div className="admin-btn-wrap-l">
                                            <BsSquareFill className="admin-tb-header-notice"/>
                                            <a>새로 적용할 요금표[EXCEL]</a>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", maxWidth: "100%",overflowX: "auto"}}>
                                        {selectedSheetf && (
                                            <Table className="admin-tb" striped bordered hover>
                                                <thead className="admin-tb-head">
                                                <tr className="admin-tb-head-line">
                                                    {sheetDataf[0] && sheetDataf[0].map((header) =>
                                                        <th key={header} style={{width: "80px"}}>
                                                            <b>{header}</b>
                                                        </th>)
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody className="admin-tb-body">
                                                {sheetDataf.slice(1).map((row, i) => (
                                                    <tr className="admin-tb-body-line" key={i}>
                                                        {row.map((cell, j) => (
                                                            <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                        ))}
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                        <div className="admin-btn-wrap-l">
                                            <BsSquareFill className="admin-tb-header-notice"/>
                                            <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", maxWidth: "100%",overflowX: "auto"}}>
                                        <Table striped bordered hover className="admin-tb">
                                            <thead className="admin-tb-head">
                                                <tr className="admin-tb-head-line">
                                                <th style={{width: "75px"}}><b>그룹코드</b></th>
                                                <th style={{width: "75px"}}><b>0.5kg</b></th>
                                                <th style={{width: "75px"}}><b>1kg</b></th>
                                                <th style={{width: "75px"}}><b>1.5kg</b></th>
                                                <th style={{width: "75px"}}><b>2kg</b></th>
                                                <th style={{width: "75px"}}><b>2.5kg</b></th>
                                                <th style={{width: "75px"}}><b>3.0kg</b></th>
                                                <th style={{width: "75px"}}><b>3.5kg</b></th>
                                                <th style={{width: "75px"}}><b>4.0kg</b></th>
                                                <th style={{width: "75px"}}><b>4.5kg</b></th>
                                                <th style={{width: "75px"}}><b>5.0kg</b></th>
                                                <th style={{width: "75px"}}><b>5.5kg</b></th>
                                                <th style={{width: "75px"}}><b>6.0kg</b></th>
                                                <th style={{width: "75px"}}><b>6.5kg</b></th>
                                                <th style={{width: "75px"}}><b>7.0kg</b></th>
                                                <th style={{width: "75px"}}><b>7.5kg</b></th>
                                                <th style={{width: "75px"}}><b>8.0kg</b></th>
                                                <th style={{width: "75px"}}><b>8.5kg</b></th>
                                                <th style={{width: "75px"}}><b>9.0kg</b></th>
                                                <th style={{width: "75px"}}><b>9.5kg</b></th>
                                                <th style={{width: "75px"}}><b>10kg</b></th>
                                                <th style={{width: "75px"}}><b>10.5kg</b></th>
                                                <th style={{width: "75px"}}><b>11kg</b></th>
                                                <th style={{width: "75px"}}><b>11.5kg</b></th>
                                                <th style={{width: "75px"}}><b>12kg</b></th>
                                                <th style={{width: "75px"}}><b>12.5kg</b></th>
                                                <th style={{width: "75px"}}><b>13kg</b></th>
                                                <th style={{width: "75px"}}><b>13.5kg</b></th>
                                                <th style={{width: "75px"}}><b>14kg</b></th>
                                                <th style={{width: "75px"}}><b>14.5kg</b></th>
                                                <th style={{width: "75px"}}><b>15kg</b></th>
                                                <th style={{width: "75px"}}><b>15.5kg</b></th>
                                                <th style={{width: "75px"}}><b>16kg</b></th>
                                                <th style={{width: "75px"}}><b>16.5kg</b></th>
                                                <th style={{width: "75px"}}><b>17kg</b></th>
                                                <th style={{width: "75px"}}><b>17.5kg</b></th>
                                                <th style={{width: "75px"}}><b>18kg</b></th>
                                                <th style={{width: "75px"}}><b>18.5kg</b></th>
                                                <th style={{width: "75px"}}><b>19kg</b></th>
                                                <th style={{width: "75px"}}><b>19.5kg</b></th>
                                                <th style={{width: "75px"}}><b>20kg</b></th>
                                                <th style={{width: "75px"}}><b>20.5kg</b></th>
                                                <th style={{width: "75px"}}><b>21kg</b></th>
                                                <th style={{width: "75px"}}><b>22kg</b></th>
                                                <th style={{width: "75px"}}><b>23kg</b></th>
                                                <th style={{width: "75px"}}><b>24kg</b></th>
                                                <th style={{width: "75px"}}><b>25kg</b></th>
                                                <th style={{width: "75px"}}><b>26kg</b></th>
                                                <th style={{width: "75px"}}><b>27kg</b></th>
                                                <th style={{width: "75px"}}><b>28kg</b></th>
                                                <th style={{width: "75px"}}><b>29kg</b></th>
                                                <th style={{width: "75px"}}><b>30kg</b></th>
                                            </tr>
                                            </thead>
                                            <tbody className="admin-tb-body">
                                            {fedexData.map((cData, i) => (
                                                <tr className="admin-tb-body-line" key={cData.seqNum}>
                                                    <td><a>{fedexData[i].appGroup}</a></td>
                                                    <td><a>{fedexData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w20500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    <td><a>{fedexData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">A - 마카오</p>*/}
                                {/*<p className="font-b">D - 캄보디아, 라오스 몽골</p>*/}
                                {/*<p className="font-b">F - 캐나다, 미국, 멕시코</p>*/}
                                {/*<p className="font-b">G - 오스트리아, 체코, 덴마크, 핀란드, 그리스, 헝가리, 아일랜드, 노르웨이, 폴란드, 포르투갈, 스웨덴, 스위스</p>*/}
                                {/*<p className="font-b">H - 이스라엘, 튀르키예, 러시아, 우크라이나, 우즈베키스탄</p>*/}
                                {/*<p className="font-b">I - 아르헨티아, 브라질, 칠레, 페루</p>*/}
                                {/*<p className="font-b">J - 방글라데시, 모로코, 카타르, 아랍에미리트, 요르단 ,사우디아라비아</p>*/}
                                {/*<p className="font-b">M - 벨기에, 독일, 이탈리아, 네덜란드, 스페인, 영국, 프랑스</p>*/}
                                {/*<p className="font-b">N - 베트남</p>*/}
                                {/*<p className="font-b">O - 인도</p>*/}
                                {/*<p className="font-b">P - 일본</p>*/}
                                {/*<p className="font-b">Q - 말레이시아</p>*/}
                                {/*<p className="font-b">R - 태국</p>*/}
                                {/*<p className="font-b">S - 필리핀</p>*/}
                                {/*<p className="font-b">T - 인도네시아</p>*/}
                                {/*<p className="font-b">U - 호주, 뉴질랜드</p>*/}
                                {/*<p className="font-b">V - 홍콩</p>*/}
                                {/*<p className="font-b">W - 중국</p>*/}
                                {/*<p className="font-b">X - 대만</p>*/}
                                {/*<p className="font-b">Y - 싱가포르</p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-main-eco" title="&nbsp;&nbsp;&nbsp;FedEx-E&nbsp;&nbsp;&nbsp;">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="m-l-10" style={{position: "relative"}}>
                                            <input className="font-s-16" type="file" onChange={excelUploadFEDEXE} ref={fileSelectfe}
                                                   style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                            <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectfe.current.click()}
                                                    style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                            </button>
                                        </div>
                                        <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                        {selectedSheetfe && (
                                            <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetfe.value} onChange={sheetSelectFEDEXE}
                                                    style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                {excelDatafe.SheetNames.map((sheetName) => (
                                                    <option key={sheetName} value={sheetName}>
                                                        {sheetName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="admin-event-wrap-btn gap10">
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownFEDEXE}>EXCEL[양식]</button>
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert2}>저장</button>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div className="admin-event-wrap">
                                    <div className="admin-btn-wrap-l">
                                        <BsSquareFill className="admin-tb-header-notice"/>
                                        <a>새로 적용할 요금표[EXCEL]</a>
                                    </div>
                                </div>
                                <div style={{width: "100%", maxWidth: "100%",overflowX: "auto"}}>
                                    {selectedSheetfe && (
                                        <Table className="admin-tb" striped bordered hover>
                                            <thead className="admin-tb-head">
                                            <tr className="admin-tb-head-line">
                                                {sheetDatafe[0] && sheetDatafe[0].map((header) =>
                                                    <th key={header} style={{width: "80px"}}>
                                                        <b>{header}</b>
                                                    </th>)
                                                }
                                            </tr>
                                            </thead>
                                            <tbody className="admin-tb-body">
                                            {sheetDatafe.slice(1).map((row, i) => (
                                                <tr className="admin-tb-body-line" key={i}>
                                                    {row.map((cell, j) => (
                                                        <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                    ))}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                    <div className="admin-btn-wrap-l">
                                        <BsSquareFill className="admin-tb-header-notice"/>
                                        <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                    </div>
                                </div>
                                <div style={{width: "100%", maxWidth: "100%",overflowX: "auto"}}>
                                    <Table striped bordered hover className="admin-tb">
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            <th style={{width: "75px"}}><b>그룹코드</b></th>
                                            <th style={{width: "75px"}}><b>0.5kg</b></th>
                                            <th style={{width: "75px"}}><b>1kg</b></th>
                                            <th style={{width: "75px"}}><b>1.5kg</b></th>
                                            <th style={{width: "75px"}}><b>2kg</b></th>
                                            <th style={{width: "75px"}}><b>2.5kg</b></th>
                                            <th style={{width: "75px"}}><b>3.0kg</b></th>
                                            <th style={{width: "75px"}}><b>3.5kg</b></th>
                                            <th style={{width: "75px"}}><b>4.0kg</b></th>
                                            <th style={{width: "75px"}}><b>4.5kg</b></th>
                                            <th style={{width: "75px"}}><b>5.0kg</b></th>
                                            <th style={{width: "75px"}}><b>5.5kg</b></th>
                                            <th style={{width: "75px"}}><b>6.0kg</b></th>
                                            <th style={{width: "75px"}}><b>6.5kg</b></th>
                                            <th style={{width: "75px"}}><b>7.0kg</b></th>
                                            <th style={{width: "75px"}}><b>7.5kg</b></th>
                                            <th style={{width: "75px"}}><b>8.0kg</b></th>
                                            <th style={{width: "75px"}}><b>8.5kg</b></th>
                                            <th style={{width: "75px"}}><b>9.0kg</b></th>
                                            <th style={{width: "75px"}}><b>9.5kg</b></th>
                                            <th style={{width: "75px"}}><b>10kg</b></th>
                                            <th style={{width: "75px"}}><b>10.5kg</b></th>
                                            <th style={{width: "75px"}}><b>11kg</b></th>
                                            <th style={{width: "75px"}}><b>11.5kg</b></th>
                                            <th style={{width: "75px"}}><b>12kg</b></th>
                                            <th style={{width: "75px"}}><b>12.5kg</b></th>
                                            <th style={{width: "75px"}}><b>13kg</b></th>
                                            <th style={{width: "75px"}}><b>13.5kg</b></th>
                                            <th style={{width: "75px"}}><b>14kg</b></th>
                                            <th style={{width: "75px"}}><b>14.5kg</b></th>
                                            <th style={{width: "75px"}}><b>15kg</b></th>
                                            <th style={{width: "75px"}}><b>15.5kg</b></th>
                                            <th style={{width: "75px"}}><b>16kg</b></th>
                                            <th style={{width: "75px"}}><b>16.5kg</b></th>
                                            <th style={{width: "75px"}}><b>17kg</b></th>
                                            <th style={{width: "75px"}}><b>17.5kg</b></th>
                                            <th style={{width: "75px"}}><b>18kg</b></th>
                                            <th style={{width: "75px"}}><b>18.5kg</b></th>
                                            <th style={{width: "75px"}}><b>19kg</b></th>
                                            <th style={{width: "75px"}}><b>19.5kg</b></th>
                                            <th style={{width: "75px"}}><b>20kg</b></th>
                                            <th style={{width: "75px"}}><b>20.5kg</b></th>
                                            <th style={{width: "75px"}}><b>21kg</b></th>
                                            <th style={{width: "75px"}}><b>22kg</b></th>
                                            <th style={{width: "75px"}}><b>23kg</b></th>
                                            <th style={{width: "75px"}}><b>24kg</b></th>
                                            <th style={{width: "75px"}}><b>25kg</b></th>
                                            <th style={{width: "75px"}}><b>26kg</b></th>
                                            <th style={{width: "75px"}}><b>27kg</b></th>
                                            <th style={{width: "75px"}}><b>28kg</b></th>
                                            <th style={{width: "75px"}}><b>29kg</b></th>
                                            <th style={{width: "75px"}}><b>30kg</b></th>
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {fedexEcoData.map((cData, i) => (
                                            <tr className="admin-tb-body-line" key={cData.seqNum}>
                                                <td><a>{fedexEcoData[i].appGroup}</a></td>
                                                <td><a>{fedexEcoData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w20500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{fedexEcoData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">A - 마카오</p>*/}
                                {/*<p className="font-b">D - 캄보디아, 라오스 몽골</p>*/}
                                {/*<p className="font-b">F - 캐나다, 미국, 멕시코</p>*/}
                                {/*<p className="font-b">G - 오스트리아, 체코, 덴마크, 핀란드, 그리스, 헝가리, 아일랜드, 노르웨이, 폴란드, 포르투갈, 스웨덴, 스위스</p>*/}
                                {/*<p className="font-b">H - 이스라엘, 튀르키예, 러시아, 우크라이나, 우즈베키스탄</p>*/}
                                {/*<p className="font-b">I - 아르헨티아, 브라질, 칠레, 페루</p>*/}
                                {/*<p className="font-b">J - 방글라데시, 모로코, 카타르, 아랍에미리트, 요르단 ,사우디아라비아</p>*/}
                                {/*<p className="font-b">M - 벨기에, 독일, 이탈리아, 네덜란드, 스페인, 영국, 프랑스</p>*/}
                                {/*<p className="font-b">N - 베트남</p>*/}
                                {/*<p className="font-b">O - 인도</p>*/}
                                {/*<p className="font-b">P - 일본</p>*/}
                                {/*<p className="font-b">Q - 말레이시아</p>*/}
                                {/*<p className="font-b">R - 태국</p>*/}
                                {/*<p className="font-b">S - 필리핀</p>*/}
                                {/*<p className="font-b">T - 인도네시아</p>*/}
                                {/*<p className="font-b">U - 호주, 뉴질랜드</p>*/}
                                {/*<p className="font-b">V - 홍콩</p>*/}
                                {/*<p className="font-b">W - 중국</p>*/}
                                {/*<p className="font-b">X - 대만</p>*/}
                                {/*<p className="font-b">Y - 싱가포르</p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-ups" title="&nbsp;&nbsp;UPS&nbsp;&nbsp;">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="m-l-10" style={{position: "relative"}}>
                                            <input className="font-s-16" type="file" onChange={excelUploadUPS} ref={fileSelectUps}
                                                   style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                            <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectUps.current.click()}
                                                    style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                            </button>
                                        </div>
                                        <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                        {selectedSheetUps && (
                                            <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetUps.value} onChange={sheetSelectUPS}
                                                    style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                {excelDataUps.SheetNames.map((sheetName) => (
                                                    <option key={sheetName} value={sheetName}>
                                                        {sheetName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="admin-event-wrap-btn gap10">
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownUPS}>EXCEL[양식]</button>
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert5}>저장</button>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetUps && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetDataUps[0] && sheetDataUps[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetDataUps.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "75px"}}><b>지역코드</b></th>
                                        <th style={{width: "75px"}}><b>0.5kg</b></th>
                                        <th style={{width: "75px"}}><b>1kg</b></th>
                                        <th style={{width: "75px"}}><b>1.5kg</b></th>
                                        <th style={{width: "75px"}}><b>2kg</b></th>
                                        <th style={{width: "75px"}}><b>2.5kg</b></th>
                                        <th style={{width: "75px"}}><b>3.0kg</b></th>
                                        <th style={{width: "75px"}}><b>3.5kg</b></th>
                                        <th style={{width: "75px"}}><b>4.0kg</b></th>
                                        <th style={{width: "75px"}}><b>4.5kg</b></th>
                                        <th style={{width: "75px"}}><b>5.0kg</b></th>
                                        <th style={{width: "75px"}}><b>5.5kg</b></th>
                                        <th style={{width: "75px"}}><b>6.0kg</b></th>
                                        <th style={{width: "75px"}}><b>6.5kg</b></th>
                                        <th style={{width: "75px"}}><b>7.0kg</b></th>
                                        <th style={{width: "75px"}}><b>7.5kg</b></th>
                                        <th style={{width: "75px"}}><b>8.0kg</b></th>
                                        <th style={{width: "75px"}}><b>8.5kg</b></th>
                                        <th style={{width: "75px"}}><b>9.0kg</b></th>
                                        <th style={{width: "75px"}}><b>9.5kg</b></th>
                                        <th style={{width: "75px"}}><b>10.0kg</b></th>
                                        <th style={{width: "75px"}}><b>10.5kg</b></th>
                                        <th style={{width: "75px"}}><b>11kg</b></th>
                                        <th style={{width: "75px"}}><b>11.5kg</b></th>
                                        <th style={{width: "75px"}}><b>12kg</b></th>
                                        <th style={{width: "75px"}}><b>12.5kg</b></th>
                                        <th style={{width: "75px"}}><b>13kg</b></th>
                                        <th style={{width: "75px"}}><b>13.5kg</b></th>
                                        <th style={{width: "75px"}}><b>14kg</b></th>
                                        <th style={{width: "75px"}}><b>14.5kg</b></th>
                                        <th style={{width: "75px"}}><b>15kg</b></th>
                                        <th style={{width: "75px"}}><b>15.5kg</b></th>
                                        <th style={{width: "75px"}}><b>16kg</b></th>
                                        <th style={{width: "75px"}}><b>16.5kg</b></th>
                                        <th style={{width: "75px"}}><b>17kg</b></th>
                                        <th style={{width: "75px"}}><b>17.5kg</b></th>
                                        <th style={{width: "75px"}}><b>18kg</b></th>
                                        <th style={{width: "75px"}}><b>18.5kg</b></th>
                                        <th style={{width: "75px"}}><b>19kg</b></th>
                                        <th style={{width: "75px"}}><b>19.5kg</b></th>
                                        <th style={{width: "75px"}}><b>20kg</b></th>
                                        <th style={{width: "75px"}}><b>21kg</b></th>
                                        <th style={{width: "75px"}}><b>22kg</b></th>
                                        <th style={{width: "75px"}}><b>23kg</b></th>
                                        <th style={{width: "75px"}}><b>24kg</b></th>
                                        <th style={{width: "75px"}}><b>25kg</b></th>
                                        <th style={{width: "75px"}}><b>26kg</b></th>
                                        <th style={{width: "75px"}}><b>27kg</b></th>
                                        <th style={{width: "75px"}}><b>28kg</b></th>
                                        <th style={{width: "75px"}}><b>29kg</b></th>
                                        <th style={{width: "75px"}}><b>30kg</b></th>
                                        {/*<th style={{width: "75px"}}><b>31kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>32kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>33kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>34kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>35kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>36kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>37kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>38kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>39kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>40kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>41kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>42kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>43kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>44kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>45kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>46kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>47kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>48kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>49kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>50kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>51kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>52kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>53kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>54kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>55kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>56kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>57kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>58kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>59kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>60kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>61kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>62kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>63kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>64kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>65kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>66kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>67kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>68kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>69kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>70kg</b></th>*/}
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                    {upsData.map((cData, i) => (
                                        <tr className="admin-tb-body-line" key={cData.seqNum}>
                                            <td><a>{upsData[i].appUpsCode}</a></td>
                                            <td><a>{upsData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            {/*<td><a>{upsData[i].w31000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w32000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w33000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w34000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w35000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w36000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w37000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w38000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w39000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w40000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w41000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w42000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w43000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w44000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w45000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w46000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w47000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w48000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w49000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w50000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w51000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w52000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w53000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w54000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w55000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w56000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w57000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w58000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w59000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w60000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w61000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w62000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w63000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w64000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w65000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w66000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w67000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w68000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w69000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w70000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, MO:마카오, TW:타이완</p>*/}
                                {/*<p className="font-b">Z2[2지역] - BN:브루나이, KH:캄보디아, JP:일본, LA:라오스, MY:말레이시아, SG:싱가포르, TH:태국, VN:베트남</p>*/}
                                {/*<p className="font-b">Z3[3지역] - </p>*/}
                                {/*<p className="font-b">Z4[4지역] - </p>*/}
                                {/*<p className="font-b">ZA[4-1지역] - RU:러시아</p>*/}
                                {/*<p className="font-b">Z5[5지역] - </p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-ups-eco" title="&nbsp;&nbsp;UPS-E&nbsp;&nbsp;">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="m-l-10" style={{position: "relative"}}>
                                            <input className="font-s-16" type="file" onChange={excelUploadUPSE} ref={fileSelectUpse}
                                                   style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                            <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectUpse.current.click()}
                                                    style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                            </button>
                                        </div>
                                        <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                        {selectedSheetUpse && (
                                            <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetUpse.value} onChange={sheetSelectUPSE}
                                                    style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                {excelDataUpse.SheetNames.map((sheetName) => (
                                                    <option key={sheetName} value={sheetName}>
                                                        {sheetName}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                                <div className="admin-event-wrap-btn gap10">
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownUPSE}>EXCEL[양식]</button>
                                    <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert6}>저장</button>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetUpse && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetDataUpse[0] && sheetDataUpse[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetDataUpse.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "75px"}}><b>지역코드</b></th>
                                        <th style={{width: "75px"}}><b>0.5kg</b></th>
                                        <th style={{width: "75px"}}><b>1kg</b></th>
                                        <th style={{width: "75px"}}><b>1.5kg</b></th>
                                        <th style={{width: "75px"}}><b>2kg</b></th>
                                        <th style={{width: "75px"}}><b>2.5kg</b></th>
                                        <th style={{width: "75px"}}><b>3.0kg</b></th>
                                        <th style={{width: "75px"}}><b>3.5kg</b></th>
                                        <th style={{width: "75px"}}><b>4.0kg</b></th>
                                        <th style={{width: "75px"}}><b>4.5kg</b></th>
                                        <th style={{width: "75px"}}><b>5.0kg</b></th>
                                        <th style={{width: "75px"}}><b>5.5kg</b></th>
                                        <th style={{width: "75px"}}><b>6.0kg</b></th>
                                        <th style={{width: "75px"}}><b>6.5kg</b></th>
                                        <th style={{width: "75px"}}><b>7.0kg</b></th>
                                        <th style={{width: "75px"}}><b>7.5kg</b></th>
                                        <th style={{width: "75px"}}><b>8.0kg</b></th>
                                        <th style={{width: "75px"}}><b>8.5kg</b></th>
                                        <th style={{width: "75px"}}><b>9.0kg</b></th>
                                        <th style={{width: "75px"}}><b>9.5kg</b></th>
                                        <th style={{width: "75px"}}><b>10.0kg</b></th>
                                        <th style={{width: "75px"}}><b>10.5kg</b></th>
                                        <th style={{width: "75px"}}><b>11kg</b></th>
                                        <th style={{width: "75px"}}><b>11.5kg</b></th>
                                        <th style={{width: "75px"}}><b>12kg</b></th>
                                        <th style={{width: "75px"}}><b>12.5kg</b></th>
                                        <th style={{width: "75px"}}><b>13kg</b></th>
                                        <th style={{width: "75px"}}><b>13.5kg</b></th>
                                        <th style={{width: "75px"}}><b>14kg</b></th>
                                        <th style={{width: "75px"}}><b>14.5kg</b></th>
                                        <th style={{width: "75px"}}><b>15kg</b></th>
                                        <th style={{width: "75px"}}><b>15.5kg</b></th>
                                        <th style={{width: "75px"}}><b>16kg</b></th>
                                        <th style={{width: "75px"}}><b>16.5kg</b></th>
                                        <th style={{width: "75px"}}><b>17kg</b></th>
                                        <th style={{width: "75px"}}><b>17.5kg</b></th>
                                        <th style={{width: "75px"}}><b>18kg</b></th>
                                        <th style={{width: "75px"}}><b>18.5kg</b></th>
                                        <th style={{width: "75px"}}><b>19kg</b></th>
                                        <th style={{width: "75px"}}><b>19.5kg</b></th>
                                        <th style={{width: "75px"}}><b>20kg</b></th>
                                        <th style={{width: "75px"}}><b>21kg</b></th>
                                        <th style={{width: "75px"}}><b>22kg</b></th>
                                        <th style={{width: "75px"}}><b>23kg</b></th>
                                        <th style={{width: "75px"}}><b>24kg</b></th>
                                        <th style={{width: "75px"}}><b>25kg</b></th>
                                        <th style={{width: "75px"}}><b>26kg</b></th>
                                        <th style={{width: "75px"}}><b>27kg</b></th>
                                        <th style={{width: "75px"}}><b>28kg</b></th>
                                        <th style={{width: "75px"}}><b>29kg</b></th>
                                        <th style={{width: "75px"}}><b>30kg</b></th>
                                        {/*<th style={{width: "75px"}}><b>31kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>32kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>33kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>34kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>35kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>36kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>37kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>38kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>39kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>40kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>41kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>42kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>43kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>44kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>45kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>46kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>47kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>48kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>49kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>50kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>51kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>52kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>53kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>54kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>55kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>56kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>57kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>58kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>59kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>60kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>61kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>62kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>63kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>64kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>65kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>66kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>67kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>68kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>69kg</b></th>*/}
                                        {/*<th style={{width: "75px"}}><b>70kg</b></th>*/}
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                    {upsEcoData.map((cData, i) => (
                                        <tr className="admin-tb-body-line" key={cData.seqNum}>
                                            <td><a>{upsEcoData[i].appUpsEcoCode}</a></td>
                                            <td><a>{upsEcoData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{upsEcoData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            {/*<td><a>{upsData[i].w31000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w32000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w33000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w34000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w35000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w36000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w37000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w38000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w39000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w40000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w41000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w42000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w43000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w44000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w45000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w46000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w47000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w48000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w49000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w50000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w51000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w52000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w53000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w54000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w55000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w56000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w57000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w58000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w59000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w60000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w61000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w62000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w63000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w64000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w65000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w66000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w67000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w68000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w69000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                            {/*<td><a>{upsData[i].w70000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>*/}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, MO:마카오, TW:타이완</p>*/}
                                {/*<p className="font-b">Z2[2지역] - BN:브루나이, KH:캄보디아, JP:일본, LA:라오스, MY:말레이시아, SG:싱가포르, TH:태국, VN:베트남</p>*/}
                                {/*<p className="font-b">Z3[3지역] - </p>*/}
                                {/*<p className="font-b">Z4[4지역] - </p>*/}
                                {/*<p className="font-b">ZA[4-1지역] - RU:러시아</p>*/}
                                {/*<p className="font-b">Z5[5지역] - </p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub" title="&nbsp;&nbsp;&nbsp;&nbsp;EMS&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={excelUploadEMS} ref={fileSelect}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelect.current.click()}
                                                        style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheet && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheet.value} onChange={sheetSelectEMS}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelData.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn gap10">
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownEMS}>EXCEL[양식]</button>
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert3}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheet && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetData[0] && sheetData[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetData.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "70px"}}><b>식별번호</b></th>
                                        <th style={{width: "120px"}}><b>국가</b></th>
                                        <th style={{width: "75px"}}><b>500g</b></th>
                                        <th style={{width: "75px"}}><b>750g</b></th>
                                        <th style={{width: "75px"}}><b>1000g</b></th>
                                        <th style={{width: "75px"}}><b>1250g</b></th>
                                        <th style={{width: "75px"}}><b>1500g</b></th>
                                        <th style={{width: "75px"}}><b>1750g</b></th>
                                        <th style={{width: "75px"}}><b>2000g</b></th>
                                        <th style={{width: "75px"}}><b>2500g</b></th>
                                        <th style={{width: "75px"}}><b>3000g</b></th>
                                        <th style={{width: "75px"}}><b>3500g</b></th>
                                        <th style={{width: "75px"}}><b>4000g</b></th>
                                        <th style={{width: "75px"}}><b>4500g</b></th>
                                        <th style={{width: "75px"}}><b>5000g</b></th>
                                        <th style={{width: "75px"}}><b>5500g</b></th>
                                        <th style={{width: "75px"}}><b>6000g</b></th>
                                        <th style={{width: "75px"}}><b>6500g</b></th>
                                        <th style={{width: "75px"}}><b>7000g</b></th>
                                        <th style={{width: "75px"}}><b>7500g</b></th>
                                        <th style={{width: "75px"}}><b>8000g</b></th>
                                        <th style={{width: "75px"}}><b>8500g</b></th>
                                        <th style={{width: "75px"}}><b>9000g</b></th>
                                        <th style={{width: "75px"}}><b>9500g</b></th>
                                        <th style={{width: "75px"}}><b>10000g</b></th>
                                        <th style={{width: "75px"}}><b>10500g</b></th>
                                        <th style={{width: "75px"}}><b>11000g</b></th>
                                        <th style={{width: "75px"}}><b>11500g</b></th>
                                        <th style={{width: "75px"}}><b>12000g</b></th>
                                        <th style={{width: "75px"}}><b>12500g</b></th>
                                        <th style={{width: "75px"}}><b>13000g</b></th>
                                        <th style={{width: "75px"}}><b>13500g</b></th>
                                        <th style={{width: "75px"}}><b>14000g</b></th>
                                        <th style={{width: "75px"}}><b>14500g</b></th>
                                        <th style={{width: "75px"}}><b>15000g</b></th>
                                        <th style={{width: "75px"}}><b>15500g</b></th>
                                        <th style={{width: "75px"}}><b>16000g</b></th>
                                        <th style={{width: "75px"}}><b>16500g</b></th>
                                        <th style={{width: "75px"}}><b>17000g</b></th>
                                        <th style={{width: "75px"}}><b>17500g</b></th>
                                        <th style={{width: "75px"}}><b>18000g</b></th>
                                        <th style={{width: "75px"}}><b>18500g</b></th>
                                        <th style={{width: "75px"}}><b>19000g</b></th>
                                        <th style={{width: "75px"}}><b>19500g</b></th>
                                        <th style={{width: "75px"}}><b>20000g</b></th>
                                        <th style={{width: "75px"}}><b>20500g</b></th>
                                        <th style={{width: "75px"}}><b>21000g</b></th>
                                        <th style={{width: "75px"}}><b>21500g</b></th>
                                        <th style={{width: "75px"}}><b>22000g</b></th>
                                        <th style={{width: "75px"}}><b>22500g</b></th>
                                        <th style={{width: "75px"}}><b>23000g</b></th>
                                        <th style={{width: "75px"}}><b>23500g</b></th>
                                        <th style={{width: "75px"}}><b>24000g</b></th>
                                        <th style={{width: "75px"}}><b>24500g</b></th>
                                        <th style={{width: "75px"}}><b>25000g</b></th>
                                        <th style={{width: "75px"}}><b>25500g</b></th>
                                        <th style={{width: "75px"}}><b>26000g</b></th>
                                        <th style={{width: "75px"}}><b>26500g</b></th>
                                        <th style={{width: "75px"}}><b>27000g</b></th>
                                        <th style={{width: "75px"}}><b>27500g</b></th>
                                        <th style={{width: "75px"}}><b>28000g</b></th>
                                        <th style={{width: "75px"}}><b>28500g</b></th>
                                        <th style={{width: "75px"}}><b>29000g</b></th>
                                        <th style={{width: "75px"}}><b>29500g</b></th>
                                        <th style={{width: "75px"}}><b>30000g</b></th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                        {emsData.map((cData, i) => (
                                            <tr className="admin-tb-body-line" key={cData.seqNum}>
                                                <td><a>{emsData[i].seqNum}</a></td>
                                                <td>
                                                    <a>
                                                        {["LA", "BD", "IL", "MX"].includes(emsData[i].countryDTO.countryCode) ? "" : emsData[i].countryDTO.countryNm}
                                                        {
                                                            emsData[i].countryDTO.countryCode === "LA" ? "1지역" :
                                                                emsData[i].countryDTO.countryCode === "BD" ? "2지역" :
                                                                    emsData[i].countryDTO.countryCode === "IL" ? "3지역" :
                                                                        emsData[i].countryDTO.countryCode === "MX" ? "4지역" : ""
                                                        }
                                                    </a>
                                                </td>
                                                <td><a>{emsData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w750.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1250.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w1750.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w20500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w21500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w22500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w23500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w24500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w25500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w26500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w27500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w28500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w29500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                <td><a>{emsData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-s-14 font-t-cont">
                                <p className="font-b">[1지역] - LA:라오스, MO:마카오, KH:캄보디아, MM:미얀마, MN:몽골</p>
                                <p className="font-b">[2지역] - BD:방글라데시, BN:브루나이, IN:인도, NP:네팔, LK:스리랑카, MV:몰디브, BT:부탄</p>
                                <p className="font-b">[3지역] - BE:벨기에, DK:덴마크, FL:핀란드, NO:노르웨이, PT:포르투갈, CH:스위스, SE:스웨덴, AT:오스트리아, RO:루마니아, PL:폴란드, HU:헝가리,
                                    CZ:체코, BH:바레인, IR:이란, IQ:이라크, IL:이스라엘, JO:요르단, TR:튀르키예, KW:쿠웨이트, SA:사우디아라비아, QA:카타르, SY:시리아, PG:파푸아뉴기니, GU:괌, AF:아프가니스탄, AE:아랍에미리트</p>
                                <p className="font-b">[4지역] - EG:이집트, KE:케냐, LY:리비아, MX:멕시코, PA:파나마, AR:아르헨티나, UY:우루과이, PE:페루, CU:쿠바, HT:아이티, DO:도미니카공화국</p>
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-pre" title="&nbsp;&nbsp;&nbsp;&nbsp;EMSP&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={excelUploadEMSP} ref={fileSelectep}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectep.current.click()}
                                                        style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheetp && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetp.value} onChange={sheetSelectEMSP}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDataep.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn gap10">
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownEMSP}>EXCEL[양식]</button>
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert4}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetp && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetDatap[0] && sheetDatap[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetDatap.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:kg/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "70px"}}><b>식별번호</b></th>
                                        <th style={{width: "120px"}}><b>국가</b></th>
                                        <th style={{width: "75px"}}><b>0.5kg</b></th>
                                        <th style={{width: "75px"}}><b>1kg</b></th>
                                        <th style={{width: "75px"}}><b>1.5kg</b></th>
                                        <th style={{width: "75px"}}><b>2kg</b></th>
                                        <th style={{width: "75px"}}><b>2.5kg</b></th>
                                        <th style={{width: "75px"}}><b>3.0kg</b></th>
                                        <th style={{width: "75px"}}><b>3.5kg</b></th>
                                        <th style={{width: "75px"}}><b>4.0kg</b></th>
                                        <th style={{width: "75px"}}><b>4.5kg</b></th>
                                        <th style={{width: "75px"}}><b>5.0kg</b></th>
                                        <th style={{width: "75px"}}><b>5.5kg</b></th>
                                        <th style={{width: "75px"}}><b>6.0kg</b></th>
                                        <th style={{width: "75px"}}><b>6.5kg</b></th>
                                        <th style={{width: "75px"}}><b>7.0kg</b></th>
                                        <th style={{width: "75px"}}><b>7.5kg</b></th>
                                        <th style={{width: "75px"}}><b>8.0kg</b></th>
                                        <th style={{width: "75px"}}><b>8.5kg</b></th>
                                        <th style={{width: "75px"}}><b>9.0kg</b></th>
                                        <th style={{width: "75px"}}><b>9.5kg</b></th>
                                        <th style={{width: "75px"}}><b>10kg</b></th>
                                        <th style={{width: "75px"}}><b>10.5kg</b></th>
                                        <th style={{width: "75px"}}><b>11kg</b></th>
                                        <th style={{width: "75px"}}><b>11.5kg</b></th>
                                        <th style={{width: "75px"}}><b>12kg</b></th>
                                        <th style={{width: "75px"}}><b>12.5kg</b></th>
                                        <th style={{width: "75px"}}><b>13kg</b></th>
                                        <th style={{width: "75px"}}><b>13.5kg</b></th>
                                        <th style={{width: "75px"}}><b>14kg</b></th>
                                        <th style={{width: "75px"}}><b>14.5kg</b></th>
                                        <th style={{width: "75px"}}><b>15kg</b></th>
                                        <th style={{width: "75px"}}><b>15.5kg</b></th>
                                        <th style={{width: "75px"}}><b>16kg</b></th>
                                        <th style={{width: "75px"}}><b>16.5kg</b></th>
                                        <th style={{width: "75px"}}><b>17kg</b></th>
                                        <th style={{width: "75px"}}><b>17.5kg</b></th>
                                        <th style={{width: "75px"}}><b>18kg</b></th>
                                        <th style={{width: "75px"}}><b>18.5kg</b></th>
                                        <th style={{width: "75px"}}><b>19kg</b></th>
                                        <th style={{width: "75px"}}><b>19.5kg</b></th>
                                        <th style={{width: "75px"}}><b>20kg</b></th>
                                        <th style={{width: "75px"}}><b>21kg</b></th>
                                        <th style={{width: "75px"}}><b>22kg</b></th>
                                        <th style={{width: "75px"}}><b>23kg</b></th>
                                        <th style={{width: "75px"}}><b>24kg</b></th>
                                        <th style={{width: "75px"}}><b>25kg</b></th>
                                        <th style={{width: "75px"}}><b>26kg</b></th>
                                        <th style={{width: "75px"}}><b>27kg</b></th>
                                        <th style={{width: "75px"}}><b>28kg</b></th>
                                        <th style={{width: "75px"}}><b>29kg</b></th>
                                        <th style={{width: "75px"}}><b>30kg</b></th>
                                        <th style={{width: "75px"}}><b>31kg</b></th>
                                        <th style={{width: "75px"}}><b>32kg</b></th>
                                        <th style={{width: "75px"}}><b>33kg</b></th>
                                        <th style={{width: "75px"}}><b>34kg</b></th>
                                        <th style={{width: "75px"}}><b>35kg</b></th>
                                        <th style={{width: "75px"}}><b>36kg</b></th>
                                        <th style={{width: "75px"}}><b>37kg</b></th>
                                        <th style={{width: "75px"}}><b>38kg</b></th>
                                        <th style={{width: "75px"}}><b>39kg</b></th>
                                        <th style={{width: "75px"}}><b>40kg</b></th>
                                        <th style={{width: "75px"}}><b>41kg</b></th>
                                        <th style={{width: "75px"}}><b>42kg</b></th>
                                        <th style={{width: "75px"}}><b>43kg</b></th>
                                        <th style={{width: "75px"}}><b>44kg</b></th>
                                        <th style={{width: "75px"}}><b>45kg</b></th>
                                        <th style={{width: "75px"}}><b>46kg</b></th>
                                        <th style={{width: "75px"}}><b>47kg</b></th>
                                        <th style={{width: "75px"}}><b>48kg</b></th>
                                        <th style={{width: "75px"}}><b>49kg</b></th>
                                        <th style={{width: "75px"}}><b>50kg</b></th>
                                        <th style={{width: "75px"}}><b>51kg</b></th>
                                        <th style={{width: "75px"}}><b>52kg</b></th>
                                        <th style={{width: "75px"}}><b>53kg</b></th>
                                        <th style={{width: "75px"}}><b>54kg</b></th>
                                        <th style={{width: "75px"}}><b>55kg</b></th>
                                        <th style={{width: "75px"}}><b>56kg</b></th>
                                        <th style={{width: "75px"}}><b>57kg</b></th>
                                        <th style={{width: "75px"}}><b>58kg</b></th>
                                        <th style={{width: "75px"}}><b>59kg</b></th>
                                        <th style={{width: "75px"}}><b>60kg</b></th>
                                        <th style={{width: "75px"}}><b>61kg</b></th>
                                        <th style={{width: "75px"}}><b>62kg</b></th>
                                        <th style={{width: "75px"}}><b>63kg</b></th>
                                        <th style={{width: "75px"}}><b>64kg</b></th>
                                        <th style={{width: "75px"}}><b>65kg</b></th>
                                        <th style={{width: "75px"}}><b>66kg</b></th>
                                        <th style={{width: "75px"}}><b>67kg</b></th>
                                        <th style={{width: "75px"}}><b>68kg</b></th>
                                        <th style={{width: "75px"}}><b>69kg</b></th>
                                        <th style={{width: "75px"}}><b>70kg</b></th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                    {emsPreData.map((cData, i) => (
                                        <tr className="admin-tb-body-line" key={cData.seqNum}>
                                            <td><a>{emsPreData[i].seqNum}</a></td>
                                            <td><a>{emsPreData[i].appEmsPreCode}</a></td>
                                            <td><a>{emsPreData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w2500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w3500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w4500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w5500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w6500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w7000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w7500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w8500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w9000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w9500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w10500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w11000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w11500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w12500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w13000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w13500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w14500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w15000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w15500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w16500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w17000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w17500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w18500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w19000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w19500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w21000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w22000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w23000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w24000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w26000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w27000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w28000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w29000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w30000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w31000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w32000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w33000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w34000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w35000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w36000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w37000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w38000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w39000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w40000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w41000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w42000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w43000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w44000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w45000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w46000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w47000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w48000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w49000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w50000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w51000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w52000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w53000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w54000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w55000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w56000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w57000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w58000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w59000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w60000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w61000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w62000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w63000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w64000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w65000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w66000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w67000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w68000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w69000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td><a>{emsPreData[i].w70000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-s-14 font-t-cont">
                                {/*<p className="font-b">[1지역] - LA:라오스, MO:마카오, KH:캄보디아, MM:미얀마, MN:몽골</p>*/}
                                {/*<p className="font-b">[2지역] - BD:방글라데시, BN:브루나이, IN:인도, NP:네팔, LK:스리랑카, MV:몰디브, BT:부탄</p>*/}
                                {/*<p className="font-b">[3지역] - BE:벨기에, DK:덴마크, FL:핀란드, NO:노르웨이, PT:포르투갈, CH:스위스, SE:스웨덴, AT:오스트리아, RO:루마니아, PL:폴란드, HU:헝가리,*/}
                                {/*    CZ:체코, BH:바레인, IR:이란, IQ:이라크, IL:이스라엘, JO:요르단, TR:튀르키예, KW:쿠웨이트, SA:사우디아라비아, QA:카타르, SY:시리아, PG:파푸아뉴기니, GU:괌, AF:아프가니스탄, AE:아랍에미리트</p>*/}
                                {/*<p className="font-b">[4지역] - EG:이집트, KE:케냐, LY:리비아, MX:멕시코, PA:파나마, AR:아르헨티나, UY:우루과이, PE:페루, CU:쿠바, HT:아이티, DO:도미니카공화국</p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-ship" title="&nbsp;&nbsp;&nbsp;&nbsp;SHIP&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={excelUploadSHIP} ref={fileSelects}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelects.current.click()}
                                                        style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheets && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheets.value} onChange={sheetSelectSHIP}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDatas.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn gap10">
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownSHIP}>EXCEL[양식]</button>
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert9}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheets && (
                                    <div className="div-tb m-b-10">
                                        <div className="div-tb-head ht-60">
                                            {sheetDatas[0] && sheetDatas[0].map((header) =>
                                                <div key={header} className="wd-80">
                                                    <p>{header}</p>
                                                </div>)
                                            }
                                        </div>
                                        {sheetDatas.slice(1).map((row, i) => (
                                            <div className="div-tb-body ht-70" key={i}>
                                                {row.map((cell, j) => (
                                                    <div key={`${i}-${j}`} className="wd-80">
                                                        <a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div className="wd-80"><p>지역코드</p></div>
                                        <div className="wd-80"><p>1000g</p></div>
                                        <div className="wd-80"><p>2000g</p></div>
                                        <div className="wd-80"><p>4000g</p></div>
                                        <div className="wd-80"><p>6000g</p></div>
                                        <div className="wd-80"><p>8000g</p></div>
                                        <div className="wd-80"><p>10000g</p></div>
                                        <div className="wd-80"><p>12000g</p></div>
                                        <div className="wd-80"><p>14000g</p></div>
                                        <div className="wd-80"><p>16000g</p></div>
                                        <div className="wd-80"><p>18000g</p></div>
                                        <div className="wd-80"><p>20000g</p></div>
                                    </div>
                                    {shipData.map((cData, i) => (
                                        <div key={cData.seqNum} className="div-tb-body ht-70">
                                            <div className="wd-80"><p>{shipData[i].appShipCode}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w4000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w6000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w8000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w12000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w14000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w16000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w18000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div className="wd-80"><p>{shipData[i].w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="font-t-cont font-s-14">
                                <p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, JP:일본, TW:타이완</p>
                                <p className="font-b">Z2[2지역] - BD:방글라데시, ID:인도네시아, MY:말레이시아, PH:필리핀, SG:싱가포르, VN:베트남</p>
                                <p className="font-b">Z3[3지역] - AU:호주, CA:캐나다, DE:독일, ES:스페인, FR:프랑스, GB:영국, IE:아일랜드, NL:네덜란드, NZ:뉴질랜드, PL:폴란드, US:미국</p>
                                <p className="font-b">Z4[4지역] - PE:페루</p>
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-kPack" title="&nbsp;&nbsp;&nbsp;K-Packet&nbsp;&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={excelUploadKP} ref={fileSelectKp}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectKp.current.click()}
                                                        style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheetKp && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetKp.value} onChange={sheetSelectKP}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDataKp.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn gap10">
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownKP}>EXCEL[양식]</button>
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert7}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetKp && (
                                    <Table className="admin-tb" striped bordered hover>
                                        <thead className="admin-tb-head">
                                        <tr className="admin-tb-head-line">
                                            {sheetDataKp[0] && sheetDataKp[0].map((header) =>
                                                <th key={header} style={{width: "80px"}}>
                                                    <b>{header}</b>
                                                </th>)
                                            }
                                        </tr>
                                        </thead>
                                        <tbody className="admin-tb-body">
                                        {sheetDataKp.slice(1).map((row, i) => (
                                            <tr className="admin-tb-body-line" key={i}>
                                                {row.map((cell, j) => (
                                                    <td key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <Table striped bordered hover className="admin-tb">
                                    <thead className="admin-tb-head">
                                    <tr className="admin-tb-head-line">
                                        <th style={{width: "100px"}}><b>국가</b></th>
                                        <th style={{width: "50px"}}><b>100g</b></th>
                                        <th style={{width: "50px"}}><b>200g</b></th>
                                        <th style={{width: "50px"}}><b>300g</b></th>
                                        <th style={{width: "50px"}}><b>400g</b></th>
                                        <th style={{width: "50px"}}><b>500g</b></th>
                                        <th style={{width: "50px"}}><b>600g</b></th>
                                        <th style={{width: "50px"}}><b>700g</b></th>
                                        <th style={{width: "50px"}}><b>800g</b></th>
                                        <th style={{width: "50px"}}><b>900g</b></th>
                                        <th style={{width: "50px"}}><b>1000g</b></th>
                                        <th style={{width: "50px"}}><b>1100g</b></th>
                                        <th style={{width: "50px"}}><b>1200g</b></th>
                                        <th style={{width: "50px"}}><b>1200g</b></th>
                                        <th style={{width: "50px"}}><b>1400g</b></th>
                                        <th style={{width: "50px"}}><b>1500g</b></th>
                                        <th style={{width: "50px"}}><b>1600g</b></th>
                                        <th style={{width: "50px"}}><b>1700g</b></th>
                                        <th style={{width: "50px"}}><b>1800g</b></th>
                                        <th style={{width: "50px"}}><b>1900g</b></th>
                                        <th style={{width: "50px"}}><b>2000g</b></th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-tb-body">
                                    {kpackData.map((cData, i) => (
                                        <tr className="admin-tb-body-line" key={cData.seqNum}>
                                            <td style={{width: "100px"}}><a>{kpackData[i].countryDTO.countryNm}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w1900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                            <td style={{width: "50px"}}><a>{kpackData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="font-s-14 font-t-cont">

                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-air-s" title="&nbsp;&nbsp;Small-Packet&nbsp;&nbsp;">
                            <div>
                                <div className="admin-event-wrap">
                                    <div className="admin-event-wrap-btn">
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div className="m-l-10" style={{position: "relative"}}>
                                                <input className="font-s-16" type="file" onChange={excelUploadAS} ref={fileSelectAs}
                                                       style={{border: "none", paddingLeft: "14px", height: "25px", width: "280px"}}/>
                                                <button id="ex-btn" className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={() => fileSelectAs.current.click()}
                                                        style={{position: "absolute", top: -3, right: "190px"}}>파일선택
                                                </button>
                                            </div>
                                            <label style={{fontSize: "14px", marginTop: "5px", marginRight: "5px"}}>Sheet 선택</label>
                                            {selectedSheetAs && (
                                                <select className="admin-combo font-s-13" id="sheet-select" value={selectedSheetAs.value} onChange={sheetSelectAS}
                                                        style={{width: "120px", height: "25px", marginTop: "2px"}}>
                                                    {excelDataAs.SheetNames.map((sheetName) => (
                                                        <option key={sheetName} value={sheetName}>
                                                            {sheetName}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="admin-event-wrap-btn gap10">
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDownAS}>EXCEL[양식]</button>
                                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={alert8}>저장</button>
                                    </div>
                                </div>
                            </div>
                            <div className="gbn-border-b" style={{width: "100%", marginTop: "18px", marginBottom: "15px"}}></div>
                            <div className="admin-event-wrap" style={{marginTop: "10px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>새로 적용할 요금표[EXCEL]</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                {selectedSheetAs && (
                                    <div className="div-tb m-b-10">
                                        <div className="div-tb-head ht-60">
                                            {sheetDataAs[0] && sheetDataAs[0].map((header) =>
                                                <div key={header} style={{width: "80px"}}>
                                                    <p>{header}</p>
                                                </div>)
                                            }
                                        </div>
                                        {sheetDataAs.slice(1).map((row, i) => (
                                            <div className="div-tb-body ht-70" key={i}>
                                                {row.map((cell, j) => (
                                                    <p key={`${i}-${j}`}><a>{cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</a></p>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="admin-event-wrap" style={{marginTop: "70px", marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>현재 적용된 요금표(단위:g/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div style={{width: "60px"}}><p>지역코드</p></div>
                                        <div style={{width: "50px"}}><p>100g</p></div>
                                        <div style={{width: "50px"}}><p>200g</p></div>
                                        <div style={{width: "50px"}}><p>300g</p></div>
                                        <div style={{width: "50px"}}><p>400g</p></div>
                                        <div style={{width: "50px"}}><p>500g</p></div>
                                        <div style={{width: "50px"}}><p>600g</p></div>
                                        <div style={{width: "50px"}}><p>700g</p></div>
                                        <div style={{width: "50px"}}><p>800g</p></div>
                                        <div style={{width: "50px"}}><p>900g</p></div>
                                        <div style={{width: "50px"}}><p>1000g</p></div>
                                        <div style={{width: "50px"}}><p>1100g</p></div>
                                        <div style={{width: "50px"}}><p>1200g</p></div>
                                        <div style={{width: "50px"}}><p>1200g</p></div>
                                        <div style={{width: "50px"}}><p>1400g</p></div>
                                        <div style={{width: "50px"}}><p>1500g</p></div>
                                        <div style={{width: "50px"}}><p>1600g</p></div>
                                        <div style={{width: "50px"}}><p>1700g</p></div>
                                        <div style={{width: "50px"}}><p>1800g</p></div>
                                        <div style={{width: "50px"}}><p>1900g</p></div>
                                        <div style={{width: "50px"}}><p>2000g</p></div>
                                    </div>
                                    {airSData.map((cData, i) => (
                                        <div key={cData.seqNum} className="div-tb-body ht-70">
                                            <div style={{width: "60px"}}><p>{airSData[i].appAsCode}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1100.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1200.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1300.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1400.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1500.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1600.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1700.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1800.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w1900.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                            <div style={{width: "50px"}}><p>{airSData[i].w2000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="font-t-cont font-s-14">
                                {/*<p className="font-b">Z1[1지역] - CN:중국, HK:홍콩, JP:일본, TW:타이완</p>*/}
                                {/*<p className="font-b">Z2[2지역] - BD:방글라데시, ID:인도네시아, MY:말레이시아, PH:필리핀, SG:싱가포르, VN:베트남</p>*/}
                                {/*<p className="font-b">Z3[3지역] - AU:호주, CA:캐나다, DE:독일, ES:스페인, FR:프랑스, GB:영국, IE:아일랜드, NL:네덜란드, NZ:뉴질랜드, PL:폴란드, US:미국</p>*/}
                                {/*<p className="font-b">Z4[4지역] - PE:페루</p>*/}
                            </div>
                        </Tab>
                        <Tab eventKey="mam-tab-sub-dome" title="&nbsp;&nbsp;&nbsp;&nbsp;국내배송&nbsp;&nbsp;&nbsp;&nbsp;">
                            <div className="admin-event-wrap" style={{marginBottom: "-1px"}}>
                                <div className="admin-btn-wrap-l">
                                    <BsSquareFill className="admin-tb-header-notice"/>
                                    <a>(단위:kg/&#8361;)</a>
                                </div>
                            </div>
                            <div style={{width: "100%", maxWidth: "100%",overflowX: "auto", display: "flex", flexDirection: "row"}}>
                                <div className="div-tb m-b-10">
                                    <div className="div-tb-head ht-60">
                                        <div className="wd-40"><p>번호</p></div>
                                        <div className="wd-80"><p>부피</p></div>
                                        <div className="wd-80"><p>~3kg</p></div>
                                        <div className="wd-80"><p>~5kg</p></div>
                                        <div className="wd-80"><p>~10kg</p></div>
                                        <div className="wd-80"><p>~20kg</p></div>
                                        <div className="wd-80"><p>25kg~</p></div>
                                        <div className="wd-80"><p></p></div>
                                    </div>
                                    {domeData.map((cData, i) => (
                                        <div key={cData.seqNum} className="div-tb-body ht-70">
                                            <div className="wd-40"><p>{domeData[i].seqNum}</p></div>
                                            <div className="wd-80">
                                                <p className="font-b">{domeData[i].volumes === "V08" ? "~80cm"
                                                    : domeData[i].volumes === "V10" ? "~100cm"
                                                        : domeData[i].volumes === "V12" ? "~120cm"
                                                            : domeData[i].volumes === "V14" ? "~140cm"
                                                                : domeData[i].volumes === "V16" ? "~160cm"
                                                                    : domeData[i].volumes === "V20" ? "~200cm": null}</p>
                                            </div>
                                            <div className="wd-80">
                                                {editMode === cData.seqNum ? (
                                                    <input className="admin-input-line m-0-a font-b font-c-cancel wd-100pc ht-30 text-center font-s-13 com-input"
                                                           maxLength="5" type="text" value={editedWeights.w3000} onChange={(e) => handleFeeChange(e, 'w3000')}/>
                                                ) : (
                                                    <p>{cData.w3000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                )}
                                            </div>
                                            <div className="wd-80">
                                                {editMode === cData.seqNum ? (
                                                    <input className="admin-input-line m-0-a font-b font-c-cancel wd-100pc ht-30 text-center font-s-13 com-input"
                                                           maxLength="5" type="text" value={editedWeights.w5000} onChange={(e) => handleFeeChange(e, 'w5000')}/>
                                                ) : (
                                                    <p>{cData.w5000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                )}
                                            </div>
                                            <div className="wd-80">
                                                {editMode === cData.seqNum ? (
                                                    <input className="admin-input-line m-0-a font-b font-c-cancel wd-100pc ht-30 text-center font-s-13 com-input"
                                                           maxLength="5" type="text" value={editedWeights.w10000} onChange={(e) => handleFeeChange(e, 'w10000')}/>
                                                ) : (
                                                    <p>{cData.w10000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                )}
                                            </div>
                                            <div className="wd-80">
                                                {editMode === cData.seqNum ? (
                                                    <input className="admin-input-line m-0-a font-b font-c-cancel wd-100pc ht-30 text-center font-s-13 com-input"
                                                           maxLength="7" type="text" value={editedWeights.w20000} onChange={(e) => handleFeeChange(e, 'w20000')}/>
                                                ) : (
                                                    <p>{cData.w20000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                )}
                                            </div>
                                            <div className="wd-80">
                                                {editMode === cData.seqNum ? (
                                                    <input className="admin-input-line m-0-a font-b font-c-cancel wd-100pc ht-30 text-center font-s-13 com-input"
                                                           maxLength="7" type="text" value={editedWeights.w25000} onChange={(e) => handleFeeChange(e, 'w25000')}/>
                                                ) : (
                                                    <p>{cData.w25000.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                )}
                                            </div>
                                            <div className="wd-80">
                                                {editMode === cData.seqNum ? (
                                                    <button className="admin-btn-A ht-30 font-s-13 btn-click-event" onClick={() => handleSaveClick(cData.seqNum)}>저장</button>
                                                ) : (
                                                    <button className="admin-btn-B ht-30 font-s-13 btn-click-event" onClick={() => handleEditClick(cData)}>수정</button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={saveExcelFileFEDEX} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={saveExcelFileFEDEXE} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow3 === true ? <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={saveExcelFileEMS} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow4 === true ? <MsgSelectModal alertShow={alertShow4} eventHandle1={modalCancel4} eventHandle2={saveExcelFileEMSP} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow5 === true ? <MsgSelectModal alertShow={alertShow5} eventHandle1={modalCancel5} eventHandle2={saveExcelFileUPS} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow6 === true ? <MsgSelectModal alertShow={alertShow6} eventHandle1={modalCancel6} eventHandle2={saveExcelFileUPSE} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow7 === true ? <MsgSelectModal alertShow={alertShow7} eventHandle1={modalCancel7} eventHandle2={saveExcelFileKP} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow8 === true ? <MsgSelectModal alertShow={alertShow8} eventHandle1={modalCancel8} eventHandle2={saveExcelFileAS} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow9 === true ? <MsgSelectModal alertShow={alertShow9} eventHandle1={modalCancel9} eventHandle2={saveExcelFileSHIP} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {/*{alertShow9 === true ? <MsgSelectModal alertShow={alertShow9} eventHandle1={modalCancel9} eventHandle2={saveExcelFileSHIP} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}*/}
        </div>
    );
}

export default AdminCurrencyRatePg
