import axios from 'axios';
axios.defaults.baseURL = 'https://btorage.com/api';
// axios.defaults.baseURL = 'https://btorage-dev.com/api';
// axios.defaults.baseURL = 'http://localhost:8080/api';
axios.defaults.timeout = 10000; // 요청 제한 시간 (ms 단위)

// 요청 인터셉터 (필요 시 추가)
// axios.interceptors.request.use(
//     (config) => {
//         // 요청 전에 필요한 로직 추가 가능 (예: 토큰 추가)
//         console.log('Request sent to:', config.url);
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

axios.interceptors.response.use(
    (response) => response, // 정상 응답 처리
    (error) => {
        // 네트워크 오류 감지 및 상태 업데이트
        if (!error.response) {
            // 전역 상태 업데이트를 위한 커스텀 이벤트 발생
            window.dispatchEvent(new Event('network-error'));
        } else {
            // 서버 응답 오류 (HTTP 상태 코드에 따른 처리)
            if (error.response.status === 400) {
                console.error('Bad Request: ', error.response.data);
            } else if (error.response.status === 500) {
                console.error('Server Error: ', error.response.data);
            }
        }
        // 이 외에 etc error status 추가가능

        console.error('Axios error:', error.message || error);

        return Promise.reject(error);
    }
);

export default axios;
