import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import {useNavigate} from "react-router-dom";
import axios from '../../services/axiosInstance';
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";

function MAdminFAQPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const navigate = useNavigate();

    const moveToDetailPg = ((seqNum) => {
        navigate(`/btcorpad/adminfaq-detail/${seqNum}`)
    })

    const moveToWritePg = (() => {
        navigate(`/adminfaq-write`)
    })

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        if (data.length > displayLimit && displayLimit < 30) {
            setDisplayLimit(prevLimit => prevLimit + perPg);
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checkItems, setCheckItems] = useState([]);
    useEffect(() => {}, [checkItems]);
    const handleSingleCheck = (checked, seqNum) => {
        if (checked) {
            setCheckItems(prev => [...prev, seqNum]);
        } else {
            setCheckItems(checkItems.filter((el) => el !== seqNum));
        }
    };
    const handleAllCheck = (checked) => {
        if(checked) {
            const idArray = [];
            data.forEach((el) => idArray.push(el.seqNum));
            setCheckItems(idArray);
        } else {
            setCheckItems([]);
        }
    }

    //endregion

    /** endregion */

    /** region Event */

    useEffect(() => {
        selectFixFirstCondition();
    }, [])

    const selectFixFirstCondition = () => axios({
        method: 'get',
        url: '/faq/selectFixFirstCondition/',
        params: {
            limit: perPg
        }
    })
        .then((response) => {
            setData(response.data);
        })

    const convertToLang = ((lang) => {
        if (lang === "0") return "한국어"
        if (lang === "1") return "English"
        if (lang === "2") return "日本語"
        if (lang === "3") return "中文語"
        if (lang === "4") return "Indonesian"
    })

    /** endregion */

    return (
        <div id="m-a-faq-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">FAQ</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-header-event-combo">
                            <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start m-r-10" onClick={togglePopup}>
                                <div className="font-t-cont">{perPg}</div>
                            </div>
                            {popupVisible && (
                                <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                            )}
                        </div>
                        <div className="m-a-header-event-btn font-t-cont font-s-15">
                            <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={moveToWritePg}>등록</button>
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-40"><p>언어</p></div>
                            <div className="wd-150"><p>구분</p></div>
                            <div className="wd-300"><p>제목</p></div>
                            <div className="wd-90"><p>작성자</p></div>
                            <div className="wd-150"><p>등록일시</p></div>
                            <div className="wd-90"><p>수정자</p></div>
                            <div className="wd-150"><p>수정일시</p></div>
                        </div>
                        {data.filter(data => data.fixFl === true).map((data, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40"><p className="font-b font-c-btorage">고정</p></div>
                                <div className="wd-40"><p>{convertToLang(data?.faqMstDTO?.lang)}</p></div>
                                <div className="wd-150"><p>{data?.faqMstDTO?.title}</p></div>
                                <div className="wd-300" onClick={()=> moveToDetailPg(data.seqNum)}><p style={{cursor:"pointer"}}>{data?.faqMstDTO?.title}</p></div>
                                <div className="wd-90"><p>{data?.regNm}</p></div>
                                <div className="wd-150"><p>{data?.regTime}</p></div>
                                <div className="wd-90"><p>{data?.modiNm}</p></div>
                                <div className="wd-150"><p>{data?.modiTile}</p></div>
                            </div>
                        ))}
                        {data.filter(data => data.fixFl === false).map((data, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40"><p>{i+1}</p></div>
                                <div className="wd-40"><p>{convertToLang(data?.faqMstDTO?.lang)}</p></div>
                                <div className="wd-150"><p>{data?.faqMstDTO?.title}</p></div>
                                <div className="wd-300" onClick={()=> moveToDetailPg(data.seqNum)}><p>{data?.title}</p></div>
                                <div className="wd-90"><p>{data?.regNm}</p></div>
                                <div className="wd-150"><p>{data?.regTime}</p></div>
                                <div className="wd-90"><p>{data?.modiNm}</p></div>
                                <div className="wd-150"><p>{data?.modiTile}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MAdminFAQPg
