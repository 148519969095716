import React, {useEffect, useRef, useState} from 'react'
import '../../css/web/UserAddrBook.css';
import commonClose from '../../assets/img/commonClose.png'
import chatFileImg from '../../assets/img/chatFolderImg.png'
import chatVideoImg from '../../assets/img/chatVideoImg.png'
import chatPdfImg from '../../assets/img/chatPdfImg.png'
import chatExcelImg from '../../assets/img/chatExcelImg.png'
import fileExchange from '../../assets/img/fileExchange.png'
import {useTranslation} from "react-i18next";
import MsgCheckModal from "./MMsgCheckModal";

function MChatFileUpload({showChatFileClose, selectedFile, selectedFileUrl, saveChatFile,
                            chatFileModifySt, chatFileModifyFalseFn, messageToModify, chatFileModify,
                            nonSelectedFile, nonShowChatFileClose, nonSelectedFileUrl, saveNonChatFile, nonShowChatFile,
                            nonChatFileModifySt, nonChatFileModifyFalseFn, nonChatFileModify, nonMessageToModify}) {

    const { t } = useTranslation();

    const [selectedModifyFile, setSelectedModifyFile] = useState(selectedFile)
    const [selectedModifyFileUrl, setSelectedModifyFileUrl] = useState('');

    const [nonSelectedModifyFile, setNonSelectedModifyFile] = useState(nonSelectedFile)
    const [nonSelectedModifyFileUrl, setNonSelectedModifyFileUrl] = useState('');

    const [modifyFile, setModifyFile] = useState('');
    const [nonModifyFile, setNonModifyFile] = useState('');

    useEffect(()=> {
        if (chatFileModifySt) {
            setModifyFile("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/" + messageToModify.chatKey)
        } else if (nonChatFileModifySt) {
            setNonModifyFile("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/" + nonMessageToModify.chatKey)
        }
    }, [])

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 128 * 1024 * 1024;
        if (file.size > maxSize) {
            setFileSizeOverSt(true);
        } else {
            if (chatFileModifySt) {
                setSelectedModifyFile(file);
                setSelectedModifyFileUrl(URL.createObjectURL(file));
            } else if (nonChatFileModifySt) {
                setNonSelectedModifyFile(file);
                setNonSelectedModifyFileUrl(URL.createObjectURL(file));
            }
            renderFilePreview();
        }
    };

    const renderFilePreview = () => {
        if (selectedModifyFile) {
            return renderChangeFilePreview();
        } else if (nonSelectedModifyFile) {
            return nonRenderChangeFilePreview();
        }
        else if (selectedFile) {
            return renderUploadFilePreview();
        }
        else if (modifyFile) {
            return renderModifyFilePreview();
        }
        else if (nonModifyFile) {
            return nonRenderModifyFilePreview();
        }
        else if (nonSelectedFile) {
            return nonRenderUploadFilePreview();
        }
        else {
            return null;
        }
    };

    // 사용자(주문서/비회원) new 파일업로드 통합
    const renderUploadFilePreview = () => {
        if (!selectedFile) {
            return null;
        }
        if (selectedFile) {
            if (selectedFile.type.startsWith('image/')) {
                return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={selectedFileUrl}/>;
            }

            if (selectedFile.type.startsWith('video/')) {
                return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatVideoImg}/>;
            }
            if (selectedFile.type.startsWith('application/pdf')) {
                return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatPdfImg}/>;
            }

            if (selectedFile) {
                let fileExtension = selectedFile.name.split('.').pop().toLowerCase();

                if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                    return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatExcelImg}/>;
                }
            } else return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatFileImg}/>;
        }
    };

    // 관리자 비회원채팅 new 파일업로드
    const nonRenderUploadFilePreview = (() => {
        if (!nonSelectedFile) {
            return null;
        }
        if (nonSelectedFile) {
            if (nonSelectedFile.type.startsWith('image/')) {
                return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={nonSelectedFileUrl}/>;
            }

            if (nonSelectedFile.type.startsWith('video/')) {
                return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatVideoImg}/>;
            }
            if (nonSelectedFile.type.startsWith('pdf/')) {
                return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatPdfImg}/>;
            }

            if (nonSelectedFile) {
                let fileExtension = nonSelectedFile.name.split('.').pop().toLowerCase();

                if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                    return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatExcelImg}/>;
                }
            } else return <img style={{width: "90%", height: "90%", objectFit: "cover"}} alt="" src={chatFileImg}/>;
        }
    })

    // 관리자 주문서수정 렌더링
    const renderModifyFilePreview = () => {
        const isImage = modifyFile.endsWith('.png') || modifyFile.endsWith('.jpg') || modifyFile.endsWith('.jpeg') || modifyFile.endsWith('.gif')
            || modifyFile.endsWith('.PNG') || modifyFile.endsWith('.JPG') || modifyFile.endsWith('.JPEG') || modifyFile.endsWith('.GIF');
        const isVideo = modifyFile.endsWith('.mp4') || modifyFile.endsWith('.avi');
        const isPdf = modifyFile.endsWith('.pdf') || modifyFile.endsWith('.PDF');

        if (isImage) {
            return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={modifyFile}/>;
        }

        if (isVideo) {
            return <video style={{width:"90%", height:"90%", objectFit: "cover"}} controls src={modifyFile}></video>;
        }

        if (isPdf) {
            return <object style={{width:"90%", height:"90%", objectFit: "cover"}} type="application/pdf" data={modifyFile}></object>;
        }

        let fileExtension = modifyFile.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatFileImg}/>;
    };

    const fileInputRef = useRef(null);
    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    // 관리자 주문서수정 변경
    const renderChangeFilePreview = () => {
        if (!selectedModifyFile) {
            return null;
        }
        if (selectedModifyFile.type.startsWith('image/')) {
            return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={selectedModifyFileUrl}/>;
        }

        if (selectedModifyFile.type.startsWith('video/')) {
            return <video style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatVideoImg}/>;
        }
        if (selectedModifyFile.type.startsWith('application/pdf')) {
            return <img style={{ width: "90%", height: "90%", objectFit: "cover" }} alt="PDF preview" src={chatPdfImg} />;
        }

        if (selectedModifyFile) {
            let fileExtension = selectedModifyFile.name.split('.').pop().toLowerCase();

            if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatExcelImg}/>;
            }
        }  else return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatFileImg}/>;
    };

    // 관리자 비회원수정 렌더링
    const nonRenderModifyFilePreview = () => {
        const isImage = nonModifyFile.endsWith('.png') || nonModifyFile.endsWith('.jpg') || nonModifyFile.endsWith('.jpeg') || nonModifyFile.endsWith('.gif')
            || nonModifyFile.endsWith('.PNG') || nonModifyFile.endsWith('.JPG') || nonModifyFile.endsWith('.JPEG') || nonModifyFile.endsWith('.GIF');
        const isVideo = nonModifyFile.endsWith('.mp4') || nonModifyFile.endsWith('.avi');
        const isPdf = nonModifyFile.endsWith('.pdf') || nonModifyFile.endsWith('.PDF');

        if (isImage) {
            return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={nonModifyFile}/>;
        }

        if (isVideo) {
            return <video style={{width:"90%", height:"90%", objectFit: "cover"}} controls src={nonModifyFile}></video>;
        }

        if (isPdf) {
            return <object style={{width:"90%", height:"90%", objectFit: "cover"}} type="application/pdf" data={nonModifyFile}></object>;
        }

        let fileExtension = nonModifyFile.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatFileImg}/>;
    };

    // 관리자 비회원수정 변경
    const nonRenderChangeFilePreview = () => {
        if (!nonSelectedModifyFile) {
            return null;
        }
        if (nonSelectedModifyFile.type.startsWith('image/')) {
            return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={nonSelectedModifyFileUrl}/>;
        }

        if (nonSelectedModifyFile.type.startsWith('video/')) {
            return <video style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatVideoImg}/>;
        }
        if (nonSelectedModifyFile.type.startsWith('pdf/')) {
            return <img style={{ width: "90%", height: "90%", objectFit: "cover" }} alt="PDF preview" src={chatPdfImg} />;
        }

        if (nonSelectedModifyFile) {
            let fileExtension = nonSelectedModifyFile.name.split('.').pop().toLowerCase();

            if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatExcelImg}/>;
            }
        }  else return <img style={{width:"90%", height:"90%", objectFit: "cover"}} alt="" src={chatFileImg}/>;
    };


    const [isUploadBtLoading, setIsUploadBtLoading] = useState(false);
    const sendFileSave = ((type) => {
        if (isUploadBtLoading) return;
        setIsUploadBtLoading(true);
        if (type === "adminOrdChatModify") {
            chatFileModify(selectedModifyFile, messageToModify);
        } else if (type === "adminNonChatModify") {
            nonChatFileModify(nonSelectedModifyFile, nonMessageToModify);
        } else if (type === "userNonChatUpload") {
            saveNonChatFile();
        } else {
            saveChatFile();
        }
    })

    return (
        <div className="bookContainer" style={{display:"flex", zIndex:2000}}>
            <div className="bookFrame-new-m wd-70pc ht-auto">
                <div style={{width:"100%", height:"100%"}}>
                    <div style={{height:"40px", display:"flex", alignItems:"center"}}>
                        <div style={{width:"100%", color:"lightgray", textAlign:"right", paddingRight:"5px", position:"relative"}}>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <p style={{fontSize:"16px"}}>{(chatFileModifySt || nonChatFileModifySt) ? t('cfuHeader1') : t('cfuHeader2')}</p> {/*전송파일*/}
                                <img onClick={chatFileModifySt ? chatFileModifyFalseFn : nonShowChatFile ? nonShowChatFileClose :
                                    nonChatFileModifySt ? nonChatFileModifyFalseFn : showChatFileClose} style={{
                                    width:"30px", height:"30px", opacity:"0.7", position:"absolute", right:"5px", cursor:"pointer"}} alt="close" src={commonClose}/>
                            </div>
                        </div>
                    </div>
                    <div className="ht-50pc pd25">
                        <div className="wd-100pc ht-100pc border-rd4" style={{maxHeight:"250px", display:"flex", justifyContent:"center", alignItems:"center", overflow:"hidden"}}>
                            {renderFilePreview()}
                        </div>
                        <p className="wd-100pc admin-tb-text-cut font-s-11 m-t-7">
                            {
                                nonSelectedModifyFile ? nonSelectedModifyFile.name
                                    :
                                    !nonSelectedModifyFile && nonMessageToModify ? nonMessageToModify.fileNm
                                        :
                                        selectedModifyFile ? selectedModifyFile.name
                                            :
                                            nonSelectedFile ? nonSelectedFile.name
                                                :
                                                !selectedModifyFile && messageToModify ? messageToModify.fileNm
                                                    :
                                                    selectedFile ? selectedFile.name
                                                        :
                                                        null
                            }
                        </p>
                    </div>
                    {chatFileModifySt || nonChatFileModifySt ?
                        <div style={{height: "40px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <input type="file" style={{display: "none"}} id="fileInput" ref={fileInputRef} onChange={handleFileChange}/>
                            <label htmlFor="fileInput"/>
                            <button style={{border:"none"}} onClick={handleFileButtonClick}>
                                <span style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <img style={{width: "30px", height: "30px", border: "solid 1px red"}} src={fileExchange} alt={''}/>
                                </span>
                            </button>
                        </div>
                        :
                        null
                    }
                    <div className="pd10 ht-auto">
                        <style>
                            {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                        </style>
                        {chatFileModifySt ?
                            <button className="admin-btn-C wd-100pc ht-45 user-center"
                                    onClick={() => {
                                        sendFileSave("adminOrdChatModify")
                                        // chatFileModify(selectedModifyFile, messageToModify)
                                    }}>
                                {isUploadBtLoading ? (
                                    <div className="spinner"></div>
                                ) : (
                                    <div>{t(`modRegModiBt`)}</div>
                                )}
                            </button>
                            :
                            nonChatFileModifySt ?
                                <button className="admin-btn-C wd-100pc ht-45 user-center"
                                        onClick={() => {
                                            sendFileSave("adminNonChatModify")
                                            // nonChatFileModify(nonSelectedModifyFile, nonMessageToModify)
                                        }}>
                                    {isUploadBtLoading ? (
                                        <div className="spinner"></div>
                                    ) : (
                                        <div>{t(`modRegModiBt`)}</div>
                                    )}
                                </button>
                                :
                                nonShowChatFile ?
                                    <button className="admin-btn-C wd-100pc ht-45 user-center"
                                            // onClick={saveNonChatFile}>
                                            onClick={()=> sendFileSave("userNonChatUpload")
                                            }>
                                        {isUploadBtLoading ? (
                                            <div className="spinner"></div>
                                        ) : (
                                            <div>{t(`sendBt`)}</div>
                                        )}
                                    </button>
                                    :
                                    <button className="admin-btn-C wd-100pc ht-45 user-center"
                                            // onClick={saveChatFile}>
                                            onClick={()=> sendFileSave("userOrdChatUpload")
                                            }>
                                        {isUploadBtLoading ? (
                                            <div className="spinner"></div>
                                        ) : (
                                            <div>{t(`sendBt`)}</div>
                                        )}
                                    </button>
                        }
                    </div>

                </div>
            </div>

            {
                fileSizeOverSt ?
                    <MsgCheckModal fileSizeOverSt={fileSizeOverSt} closeFileSizeOverSt={closeFileSizeOverSt}/>
                    :
                    null
            }
        </div>
    )
}

export default MChatFileUpload
