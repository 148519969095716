import "../../css/mobile/MSideQuickMenu.css"
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import MainChat from '../../assets/img/mainChatList.png';
import MainChatCs from "../../assets/img/mainChatCs.png";
import MainChatLine from "../../assets/img/mainChatLine.png";
import MainChatInstagram from "../../assets/img/mainChatInstagram.png";
import MainChatKakao from "../../assets/img/mainChatKakao.png";
import MainChatWechat from "../../assets/img/MainChatWechat.png";
import MainChatMail from "../../assets/img/mainChatMail.png";
import React, {useEffect, useRef, useState} from "react";
import {useWebSocket} from "../../services/WebsocketContext";
import axios from "../../services/axiosInstance";

const MSideQuickMenu = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [openNonUserChat, setOpenNonUserChat] = useState(false);
    const msgNum = localStorage.getItem('msgNum');
    const [lcstMsgNum, setLcstMsgNum] = useState(msgNum)

    const openLink = (url) => {
        window.open(url, "_target");
        setIsOpen(false);
    }



    const generateMsgNum = (() => {
        const today = new Date();
        const formatter = new Intl.DateTimeFormat('ko-KR', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        });

        const dateString = formatter.format(today).replace(/[^0-9]/g, ''); // yyMMdd
        const randomLong = Math.floor(Math.random() * 1_000_000_000_0); // 10자리 랜덤 숫자
        const randomNumber = randomLong.toString().padStart(10, '0');

        const msgNum = dateString + randomNumber;

        setLcstMsgNum(msgNum)
        localStorage.setItem('msgNum', msgNum)
    })

    const [unreadCount, setUnreadCount] = useState(0);
    const getChatData = () => {
        axios.get(`/member/chatData/${lcstMsgNum}`, {})
            .then(response => {
                if (response && response.data.length > 0 && response.data[0].unreadCount) {
                    setUnreadCount(response.data[0].unreadCount)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (!lcstMsgNum) {
            generateMsgNum();
        }
    }, [])

    useEffect(() => {
        if (lcstMsgNum) {
            getChatData();
        }
    }, [lcstMsgNum])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!msgNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(msgNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/user/${msgNum}/queue/nonUserAdminMessage`, onMessageReceived);
                subscribe(`/user/${msgNum}/queue/adminNonChatUpdate`, onChatAdminUpdate);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${msgNum}/queue/nonUserAdminMessage`);
                unsubscribe(`/user/${msgNum}/queue/nonUserAdminRead`);
                unsubscribe(`/user/${msgNum}/queue/adminNonChatUpdate`);
                isSubscribedRef.current = false;
            }
        };

    }, [msgNum, isConnected, stompClient]);

    const onMessageReceived = (payload) => {
        let newMessage = JSON.parse(payload.body);
        getChatData();
    }

    const onChatAdminUpdate = ((payload) => {
        let adminUpdateData = JSON.parse(payload.body);
        console.log("adminUpdateData : ", adminUpdateData)
        if (adminUpdateData !== null) {
            getChatData();
        }
    })

    const moveToMailPg = () => {
        navigate("/UserMailInquiryPg");
    };

    return (
        <div className={'m-a-side-quick-menu-container'}>
            <div className={'m-a-side-quick-menu-default'}
                 onClick={(e) => {
                     setIsOpen(!isOpen);
                     e.stopPropagation();
                 }}>
                <img className={'m-a-side-quick-menu-img'} src={MainChat} alt={''}/>
                {unreadCount > 0 ?
                    <p className="chat-unread-count-balloon" style={{padding:"0 6px"}}>{unreadCount}</p>
                    :
                    null
                }
            </div>
            {isOpen && (
                <div className={'m-a-side-quick-menu-open'}>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatCs} alt={''}
                         onClick={() => {
                             navigate("/NonUserChatPg");
                         }}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatLine} alt={''}
                         onClick={() => openLink('https://line.me/ko/')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatInstagram} alt={''}
                         onClick={() => openLink('https://www.instagram.com/lfamily0212/')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatKakao} alt={''}
                         onClick={() => openLink('https://pf.kakao.com/_zTxllC/chat')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatWechat} alt={''}
                         onClick={() => openLink('https://www.wechat.com/ko/')}/>
                    <img className={'m-a-side-quick-menu-item-img'} src={MainChatMail} alt={''}
                         onClick={moveToMailPg}/>
                </div>
            )}
        </div>
    )
}

export default MSideQuickMenu;