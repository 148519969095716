import React, {useState, useCallback, useEffect, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import '../../css/web/UserJoinPg.css';
import HeaderLogo from "../../assets/img/HeaderLogo.png";
import {AiFillLock} from "react-icons/ai";
import {GoMail} from "react-icons/go";
import {MdLanguage} from "react-icons/md";
import {MdOutlineDriveFileRenameOutline} from "react-icons/md";
import {BiX, BiCaretDown} from "react-icons/bi";
import axios from '../../services/axiosInstance';
import {useTranslation} from "react-i18next";
import CommonTermsPopup from "../../components/web/modal/CommonTermsPopup";
import termsView from '../../assets/img/termsView.png'
import SaveMsgModal from "../../components/web/modal/SaveMsgModal";

export default function UserJoinPg() {

    const { t } = useTranslation();
    const navigate = useNavigate()

    const [allCheck, setAllCheck] = useState(false);
    const [ServiceAgreeCheck, setServiceAgreeCheck] = useState(false);
    const [InformationCheck, setInformationCheck] = useState(false);
    const [markUseAgreeCheck, setMarkUseAgreeCheck] = useState(false);
    const [MailReceptionCheck, setMailReceptionCheck] = useState(false);
    const [useTermsAgree, setUseTermsAgree] = useState("N");
    const [infoGatAgree, setInfoGatAgree] = useState("N");
    const [markUseAgree, setMarkUseAgree] = useState("N");
    const [mailRecFl, setMailRecFl] = useState("N");
    const userType = "0";
    const userOutFl = "N"
    const socialJoinFl = false;

    const [userName, setUserName] = useState("");
    const [email1, setEmail1] = useState("");
    const [email2, setEmail2] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [lang, setLang] = useState("");

    const [emailError, setEmailError] = useState(false);
    const [idErrorCheck, setIdErrorCheck] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [langError, setLangError] = useState(false);
    const [agreeError, setAgreeError] = useState(false);
    const id = email1 + "@" + email2

    // "2022-12-28T21:15:07.109Z"  // 좌측이 몇번째 자리까지 가져올건지, 우측이 시작위치
    // 앞에서 네글자 가져오기 이런식으로 년/월/일 가져오고, 중간에 하이픈 및 : 없애기
    const dateToString = JSON.stringify(new Date())
    const year = dateToString.substring(1, 5) * 60 * 60 * 24 * 365; // 2022년
    const month = dateToString.substring(8, 6) * 60 * 60 * 24 * 30; // 12월
    const day = dateToString.substring(11, 9) * 60 * 60 * 24; // 29일
    const hours = dateToString.substring(14, 12) * 60 * 60; // 07시
    const minutes = dateToString.substring(17, 15) * 60; // 39분
    const seconds = dateToString.substring(20, 18) * 1; // 20초
    const atTime = year + month + day + hours + minutes + seconds; // 2022-12-29 // 07:39:20

    // 회원가입 페이지 동의 체크박스 Start
    const allCheckBox = () => {
        if (allCheck === false) {
            setAllCheck(true);
            setServiceAgreeCheck(true);
            setInformationCheck(true);
            setMarkUseAgreeCheck(true);
            setMailReceptionCheck(true);
        } else {
            setAllCheck(false);
            setServiceAgreeCheck(false);
            setInformationCheck(false);
            setMarkUseAgreeCheck(false);
            setMailReceptionCheck(false);
        }
    }

    const handleChange_AllAgree = (e) => {
        if (e.target.checked)
            setUseTermsAgree("Y") || setInfoGatAgree("Y") || setMarkUseAgree("Y") || setMailRecFl("Y")
        else setUseTermsAgree("N") || setInfoGatAgree("N") || setMarkUseAgree("N") || setMailRecFl("N")
    }

    const serviceCheckBox = () => {
        if (ServiceAgreeCheck === false) {
            setServiceAgreeCheck(true)
        } else {
            setServiceAgreeCheck(false)
        }
    }

    const handleChange_useTermsAgree = (e) => {
        if (e.target.checked)
            setUseTermsAgree("Y");
        else setUseTermsAgree("N")
    }

    const infoCheckBox = () => {
        if (InformationCheck === false) {
            setInformationCheck(true)
        } else {
            setInformationCheck(false)
        }
    }

    const handleChange_infoGatAgree = (e) => {
        if (e.target.checked)
            setInfoGatAgree("Y");
        else setInfoGatAgree("N")
    }

    const markUseCheckBox = () => {
        if (markUseAgreeCheck === false) {
            setMarkUseAgreeCheck(true)
        } else {
            setMarkUseAgreeCheck(false)
        }
    }

    const handleChange_markUseAgree = (e) => {
        if (e.target.checked)
            setMarkUseAgree("Y");
        else setMarkUseAgree("N")
    }

    const mailCheckBox = () => {
        if (MailReceptionCheck === false) {
            setMailReceptionCheck(true)
        } else {
            setMailReceptionCheck(false)
        }
    }

    const handleChange_mailRecFl = (e) => {
        if (e.target.checked)
            setMailRecFl("Y");
        else setMailRecFl("N")
    }

    const JoinDbSave = () => axios.post("/member/join",
        {
            id: id,
            password: password,
            passwordConfirm: confirmPassword,
            userName: userName,
            lang: lang,
            markUseAgree: markUseAgree,
            mailRecFl: mailRecFl,
            infoGatAgree: infoGatAgree,
            useTermsAgree: useTermsAgree,
            userOutFl:userOutFl,
            userType: userType,
            joinVerFl: "N",
            socialJoinFl: socialJoinFl,
            joinType: '0'
        }
    )
        .then(() => {
            axios.post("/member/login",
                {
                    id: id,
                    password: password,
                    // withCredentials: true,
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then((response) => {
                    sessionStorage.setItem('AToken', response.headers.get('Authorization'))
                    const currentTime = new Date();
                    sessionStorage.setItem('lastLoginTime', currentTime);
                    // 로그인 전 페이지로 이동
                    let redirectTo = sessionStorage.getItem("redirectAfterLogin") || "/";
                    sessionStorage.removeItem("redirectAfterLogin");
                    navigate(redirectTo);
            })
        })

    // 리액트 회원가입 실시간 이메일중복 체크
    const IdCheck = async (id) => {
        if (id === undefined) return;
        await axios.get("/member/" + id).then((response) => {
            if (response.data === true) setIdErrorCheck(true);
            else setIdErrorCheck(false);
        });
    }

    useEffect(() => {
        IdCheck(id).then();
    }, [email1, email2]);

    useEffect(() => {
        if (ServiceAgreeCheck && InformationCheck && markUseAgreeCheck && MailReceptionCheck) {
            setAllCheck(true)
        } else {
            setAllCheck(false)
        }
    }, [ServiceAgreeCheck, InformationCheck, markUseAgreeCheck, MailReceptionCheck])

    // 회원가입 페이지 유효성검사 Start
    const onChangeEmail1 = (e) => { /* 첫번 째 이메일 주소가 xx또는 xx.xx 식의 형식이  아니면 에러메시지*/
        const emailRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 2~25자*/
        if (!e.target.value || emailRegex.test(e.target.value)) setEmailError(false);
        else setEmailError(true);
        setEmail1(e.target.value);
    };

    const checkEmail1 = (e) => {
        onChangeEmail1(e);
        IdCheck().then();
    };

    const onChangeEmail2 = (e) => { /* 두번 째 이메일 주소가 xx.xx가 아니면 에러메시지*/
        const email2Regex = /([\w-.]+)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/
        if (!e.target.value || email2Regex.test(e.target.value)) setEmailError(false);
        else setEmailError(true);
        setEmail2(e.target.value);
    };

    const checkEmail2 = (e) => {
        onChangeEmail2(e);
        IdCheck().then();
    };

    const onChangePassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (passwordRegex.test(e.target.value)))) setPasswordError(false);
        else setPasswordError(true);

        if (!confirmPassword || e.target.value === confirmPassword) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setPassword(e.target.value);
    };

    const onChangeConfirmPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (password === e.target.value) setConfirmPasswordError(false);
        else setConfirmPasswordError(true);
        setConfirmPassword(e.target.value);
    };

    const onChangeName = (e) => {
        const value = e.target.value;
        const userNameRegex = /^[a-zA-Z-ㄱ-힣\s]{2,25}$/;

        const koreanCharCount = [...value].filter(char => char.charCodeAt(0) >= 0xAC00 && char.charCodeAt(0) <= 0xD7A3).length;

        if (koreanCharCount > 12) {
            return;
        }

        if (!value || userNameRegex.test(value)) setNameError(false);
        else setNameError(true);

        setUserName(value);
    };

    const onChangeLang = (e) => {
        const langRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 또는 한글 2~25자*/
        if ((!e.target.value || (langRegex.test(e.target.value)))) setLangError(false);
        else setLangError(true);
        setLang(e.target.value);
    };

    // 체크박스 유효성 검사 함수 2개
    const onChangeAllAgree = (e) => {
        if (allCheck === false) {
            setAgreeError(false);
        } else setAgreeError(true);
    };

    const onChangeAgree = (e) => {
        if (ServiceAgreeCheck || InformationCheck === !e.target.Check) {
            setAgreeError(false);
        } else setAgreeError(true);
    };

    // 메일인증시작
    const [certCode, setCertCode] = useState('');
    const handleCertCode = ((e) => {
        setCertCode(e);
    })

    const [codeSendSt, setCodeSendSt] = useState(false);
    const [certCompleteSt, setCertCompleteSt] = useState(false);
    const [certError, setCertError] = useState(false); // 인증 안 했을 때
    const [certCodeErrorCount, setCertCodeErrorCount] = useState(0);
    const [certInputError, setCertInputError] = useState(false); // 인증코드가 안 맞을 때
    const [certCodeInitMsg, setCertCodeInitMsg] = useState(false); // 10번 틀렸을 때
    const [validTime, setValidTime] = useState(300);
    const [timerId, setTimerId] = useState(null);
    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const savePopupMsg = t('joinMsg');

    useEffect(() => {
        // 컴포넌트 언마운트 시 타이머 정리
        return () => {
            if (timerId) clearInterval(timerId);
        };
    }, [timerId]);

    const codeSend = (() => {
        if (email1 && email2 && !idErrorCheck) {
            setValidTime(300);
            setCodeSendSt(true);
            setCertCodeInitMsg(false);
            generateCertcode();
            startTimer();
        }
    })

    const startTimer = () => {
        // 기존 타이머가 있다면 초기화
        if (timerId) clearInterval(timerId);

        const newTimerId = setInterval(() => {
            setValidTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(newTimerId);
                    setCodeSendSt(false);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        setTimerId(newTimerId);
    };

    const certComplete = (() => {
        // 입력코드와 반환코드가 동일할 경우
        if (resCertCode > 0 && Number(certCode) === Number(resCertCode)) {
            setSaveMsgSt(true);
            setTimeout(() => setSaveMsgSt(false), 700);
            setCertCompleteSt(true);
            setCertInputError(false);
            setCertError(false);
        } else {
            // 틀릴 경우
            const newCount = certCodeErrorCount + 1;
            setCertCodeErrorCount(newCount);
            // 1~9번 입력코드 틀렸을 경우
            if (newCount < 10) {
                setCertInputError(true);
            }
            // 10번 틀렸을 경우
            else if (newCount >= 10) {
                setCertCodeInitMsg(true);
                setCodeSendSt(false);
                setCertCodeErrorCount(0); // 카운트 초기화
            }
        }
    })

    const [resCertCode, setResCertCode] = useState('');
    const generateCertcode = (() =>{
        axios({
            method: 'get',
            url: '/member/generateCertcode',
            params: {
                email: id,
                chatFl: false
            },
        })
            .then((response) => {
                setResCertCode(response.data);
            })
            .catch((error) => {
                console.log("인증생성실패 : ", error)
            })
    })
    // 메일인증종료

    const validation = () => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;

        let isValid = true;

        if (!certCompleteSt) {
            setCertError(true);
            isValid = false;
        }
        if ((!email1 || !email2) || email2 === 'none') {
            setEmailError(true);
            isValid = false;
        }
        if (!password || !passwordRegex.test(password)) {
            setPasswordError(true);
            isValid = false;
        }
        if (!confirmPassword) {
            setConfirmPasswordError(true);
            isValid = false;
        }
        if (!userName) {
            setNameError(true);
            isValid = false;
        }
        if (!lang || lang === '' || langSelectedOption.value === "none") {
            setLangError(true);
            isValid = false;
        }
        if (!useTermsAgree) {
            setAgreeError(true);
            isValid = false;
        }
        if (!infoGatAgree) {
            setAgreeError(true);
            isValid = false;
        }

        return isValid;
    };

    const errorAndSave = () => {
        if (validation()) {
            JoinDbSave()
        }
    };
    // 회원가입 페이지 유효성검사 End

    // 회원가입 소통언어 직접입력 인풋 Start
    // 소통언어 양식 셀렉트&인풋 전환 기능
    const [langChoiceReturn, setLangChoiceReturn] = useState(true);
    const LangHandle = useCallback(() => {
        setLangChoiceReturn(true);
        setLangSelectedOption(langOptions[0]);
    }, []);
    const [email2ChoiceReturn, setEmail2ChoiceReturn] = useState(true);
    const Email2Handle = useCallback(() => {
        setEmail2ChoiceReturn(true);
        setEmail2SelectedOption(email2Options[0]);
        setEmailError(false);
        setIdErrorCheck(false);
    }, []);

    // 이메일 수신동의 체크&언체크 메시지 표기 기능
    const [isChecked, setIsChecked] = useState(false)
    const MailState = useCallback((e) => {
        setIsChecked(e.target.checked);
    }, []);

    // 함수가 2개인 체크박스 기능들
    const AllAgreeCheck = (e) => {
        allCheckBox(e);
        onChangeAllAgree(e);
        handleChange_AllAgree(e);
    }

    const serviceCheck = (e) => {
        serviceCheckBox(e);
        onChangeAgree(e);
        handleChange_useTermsAgree(e);
    }

    const infoCheck = (e) => {
        infoCheckBox(e);
        onChangeAgree(e);
        handleChange_infoGatAgree(e);
    }

    const markCheck = (e) => {
        markUseCheckBox(e);
        handleChange_markUseAgree(e);
    }

    const MailFlCheck = (e) => {
        mailCheckBox(e);
        handleChange_mailRecFl(e);
    }

    const email2Options = [
        { value: 'none', label: t('joinLabel2') },
        { value: '', label: t('mdSfIp') },
        { value: 'naver.com', label: 'naver.com' },
        { value: 'gmail.com', label: 'gmail.com' },
        { value: 'hanmail.net', label: 'hanmail.net' },
        { value: 'hotmail.com', label: 'hotmail.com' },
        { value: 'daum.net', label: 'daum.net' },
        { value: 'nate.com', label: 'nate.com' },
        { value: 'yahoo.com', label: 'yahoo.com' },
        { value: 'yahoo.co.jp', label: 'yahoo.co.jp' },
        { value: 'outlook.com', label: 'outlook.com' },
        { value: 'icloud.com', label: 'icloud.com' },
    ];

    const [email2SelectedOption, setEmail2SelectedOption] = useState(email2Options[0]);
    const [email2IsOpen, setEmail2IsOpen] = useState(false);
    const [email2FocusedIndex, setEmail2FocusedIndex] = useState(0);
    const email2DropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (email2DropdownRef.current && !email2DropdownRef.current.contains(e.target)) {
                setEmail2IsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const email2HandleSelect = (option, index) => {
        setEmail2SelectedOption(option);
        setEmail2FocusedIndex(index);
        setEmail2IsOpen(false);
        setEmail2(option.value);
        if (option.value === '') setEmail2ChoiceReturn(false);
        else setEmail2ChoiceReturn(true);
    };

    const email2HandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setEmail2FocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % email2Options.length;
                setEmail2SelectedOption(email2Options[newIndex]);
                setEmail2(email2Options[newIndex].value);
                if (email2Options[newIndex].value === '') setEmail2ChoiceReturn(false);
                else setEmail2ChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setEmail2FocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + email2Options.length) % email2Options.length;
                setEmail2SelectedOption(email2Options[newIndex]);
                setEmail2(email2Options[newIndex].value);
                if (email2Options[newIndex].value === '') setEmail2ChoiceReturn(false);
                else setEmail2ChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            email2HandleSelect(email2Options[email2FocusedIndex], email2FocusedIndex);
        } else if (e.key === 'Escape') {
            setEmail2IsOpen(false);
        }
    };

    const email2HandleOnFocus = () => {
        if (email2FocusedIndex === -1) {
            setEmail2FocusedIndex(email2Options.findIndex((option) => option.value === email2SelectedOption.value));
        }
    };

    useEffect(() => {
        checkEmail2({ target: { value: email2 } });
    }, [email2]);

    const langOptions = [
        { value: 'none', label: t('lang') },
        { value: 'English', label: 'English' },
        { value: 'Japanese', label: 'Japanese' },
        { value: 'Chinese', label: 'Chinese' },
        { value: 'Indonesian', label: 'Indonesian' },
        { value: 'Korean', label: 'Korean' },
        { value: '', label: t('mdSfIp') },
    ];

    const [langSelectedOption, setLangSelectedOption] = useState(langOptions[0]);
    const [langIsOpen, setLangIsOpen] = useState(false);
    const [langFocusedIndex, setLangFocusedIndex] = useState(0);
    const langDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (langDropdownRef.current && !langDropdownRef.current.contains(e.target)) {
                setLangIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const langHandleSelect = (option, index) => {
        if (option.value !== '' || option.value !== "none") {
            setLangError(false);
        }
        setLangSelectedOption(option);
        setLangFocusedIndex(index);
        setLangIsOpen(false);
        setLang(option.value);
        if (option.value === '') setLangChoiceReturn(false);
        else setLangChoiceReturn(true);
    };

    const langHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + langOptions.length) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            langHandleSelect(langOptions[langFocusedIndex], langFocusedIndex);
        } else if (e.key === 'Escape') {
            setLangIsOpen(false);
        }
    };

    const langHandleOnFocus = () => {
        if (langFocusedIndex === -1) {
            setLangFocusedIndex(langOptions.findIndex((option) => option.value === langSelectedOption.value));
        }
    };

    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const closeTermsOfUsePopup = (() => {
        setTermsOfUsePopup(false);
    })

    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);
    const closePrivacyPolicyPopup = (() => {
        setPrivacyPolicyPopup(false);
    })

    const [markUsePopup, setMarkUsePopup] = useState(false);
    const closeMarkUsePopup = (() => {
        setMarkUsePopup(false);
    })

    return (
        <div className="JoinContainer">
            <div className="JoinMain font-t-cont">
                <div className="JoinFrame">
                    <div className="AccPgLogo" style={{marginBottom: "40px"}}>
                        <Link to={"/"}>
                            <img alt="mainLogo" style={{width:"159px", height:"38px", opacity: "0.8"}} src={HeaderLogo}/>
                        </Link>
                    </div>
                    <div style={{display: "flex", alignItems:"center"}}>
                        <div className="joinMailForm acc-icon">
                            <div className="acc-lg-icon" style={{marginLeft: "15px"}}><i><GoMail/></i></div>
                            <div className="acc-input-form" style={{width: "140px"}}>
                                <input className="JoinInput" type="text" placeholder=" " maxLength="22" autoComplete="nope"
                                       value={email1} onChange={!certCompleteSt ? checkEmail1 : null}/>
                                <label className="JoinLabel">{t(`joinLabel1`)}</label>
                            </div>
                        </div>
                        <div className="joinMailAlpha display-flex align-center m-l-5">@</div>
                        {email2ChoiceReturn && email2SelectedOption.value !== "" ? (
                            <div className="JoinSelectForm acc-icon c-point wd-50pc">
                                <div className="JoinSelectInputForm" style={{width:"100%"}}>
                                        <div ref={email2DropdownRef}
                                            className="dropdown"
                                            tabIndex="0"
                                            onKeyDown={email2HandleKeyDown}
                                            onFocus={email2HandleOnFocus}
                                            style={{height:"100%", border:"none", outline:"none"}}
                                        >
                                            <div className="dropdown-selected" onClick={() => !certCompleteSt ? setEmail2IsOpen(!email2IsOpen) : null}
                                                 style={{height:"100%", border:"none", display:"flex", alignItems:"center", color:"darkgray", marginLeft:"23px"}}>
                                                {email2SelectedOption.label}
                                            </div>
                                            {email2IsOpen && (
                                                <ul className="dropdown-options" style={{width:"194px", height:"270px", border:"none", boxShadow:"0px 0px 10px lightgray", padding:"0",
                                                        position:"absolute", left:0, top:"70px", zIndex:3, backgroundColor:"white", borderRadius:"6px", overflowY:"scroll"}} onChange={checkEmail2}>
                                                    {email2Options.map((option, index) => (
                                                        <li key={option.value}
                                                            onClick={() => email2HandleSelect(option, index)}
                                                            className={index === email2FocusedIndex ? 'focused' : '' && "mypgFilterBt"}
                                                            style={{cursor: "pointer", width:"100%", height:"50px", border:"none", display:"flex", justifyContent:"center", alignItems:"center"}}
                                                        >
                                                            {option.label}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    <div style={{position:"absolute", right:"8px", top:"15px"}}><i style={{fontSize:"18px", color:"darkgray"}}><BiCaretDown/></i></div>
                                </div>
                            </div>
                            ) :
                                <div className="JoinLangForm acc-icon c-point wd-50pc">
                                    <div className="LangInputForm ht-100pc">
                                        <input className="JoinInput user-input m-l-5" type="text" placeholder=" " value={email2} maxLength="25" autoComplete="nope"
                                               onChange={(e)=> !certCompleteSt ? checkEmail2(e) : null}
                                        />
                                        <label className="JoinLabel" style={{marginLeft: "5px", color:"darkgray"}}>{t(`mdSfIp`)}</label>
                                    </div>
                                    <div className="SwitchToSelectIcon acc-lg-icon">
                                        <a className="m-t-3" onClick={!certCompleteSt ? Email2Handle : null}><i><BiX/></i></a>
                                    </div>
                                </div>
                        }
                    </div>
                    { emailError ?
                        <div className="JoinErrorMsg">
                            <p className="JoinErrMsg">{t(`joinErr1`)}</p>
                        </div>
                        :
                        null
                    }
                    { idErrorCheck ?
                        <div className="JoinErrorMsg">
                            <p className="JoinErrMsg">{t(`joinErr2`)}</p>
                        </div>
                        :
                        null
                    }
                    <div>
                        {/*인증 안 되었으면*/}
                        { !certCompleteSt &&
                        <div className="user-center" style={{width:"100%", height:"50px", marginBottom:"10px"}}>
                            {/*인증코드전송 버튼*/}
                            {!codeSendSt ?
                                <div style={{width: "100%", height: "100%"}}>
                                    <button onClick={codeSend} className="bt-btn-A wd-100pc ht-100pc font-s-15">{t(`joinCodeBt`)}</button>
                                </div>
                                :
                                <div className="user-center wd-100pc ht-100pc gap10">
                                    {/*인증인풋*/}
                                    <div style={{width:"70%", height:"100%"}}>
                                        <div className="user-input-container" style={{width:"100%", height:"100%", position:"relative"}}>
                                            <input type="text" className="user-input" value={certCode} onChange={(e) => handleCertCode(e.target.value)} autoComplete="nope"
                                                   maxLength={6} style={{width:"100%", height:"100%", padding:"0 10px", fontSize:"14px"}}
                                                   onKeyDown={(e) => {
                                                       if (e.key === 'Enter' && !e.shiftKey) {
                                                           e.preventDefault();
                                                           certComplete();
                                                       }
                                                   }}
                                            />
                                            <div style={{width:"35px", height:"100%", position:"absolute", right:"15px", top:"32%"}}>
                                                {codeSendSt && validTime !== 0 ?
                                                    <p style={{fontSize: "13px"}}>{validTime}{t(`joinS`)}</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*인증완료 버튼*/}
                                    <div style={{width: "30%", height: "100%"}}>
                                    <button onClick={certComplete} className="bt-btn-B wd-100pc ht-100pc font-s-15">{t(`joinCodeDoneBt`)}</button>
                                </div>
                                </div>
                            }
                        </div>
                        }
                        { certError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t(`joinErr3`)}</p>
                            </div>
                            :
                            null
                        }
                        { certInputError && certCodeErrorCount > 0 ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t(`joinErr4`)} {certCodeErrorCount}{t(`joinErr5`)}</p>
                            </div>
                            :
                            null
                        }
                        { certCodeInitMsg ?
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">{t(`joinErr6`)}</p>
                                    </div>
                                    :
                            null
                        }
                        <div className="JoinForm acc-icon" style={{marginTop: "25px"}}>
                            <div className="acc-lg-icon"><i><AiFillLock/></i></div>
                            <div className="acc-input-form">
                                <input className="JoinInput" type="password" placeholder=" "
                                       value={password} onChange={onChangePassword}
                                       minLength="8" maxLength="20"/>
                                <label className="JoinLabel">{t(`joinLabel3`)}</label>
                            </div>
                        </div>
                        { passwordError ?
                        <div className="JoinErrorMsg">
                            <p className="JoinErrMsg">{t(`joinErr7`)}</p>
                        </div>
                            :
                            null
                        }
                        <div className="JoinForm acc-icon">
                            <div className="acc-lg-icon"><i><AiFillLock/></i></div>
                            <div className="acc-input-form">
                                <input className="JoinInput" type="password" placeholder=" "
                                       value={confirmPassword} onChange={onChangeConfirmPassword}
                                       minLength="8" maxLength="20"/>
                                <label className="JoinLabel">{t(`joinLabel4`)}</label>
                            </div>
                        </div>
                        {confirmPasswordError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t(`joinErr8`)}</p>
                            </div>
                            :
                            null
                        }
                        <div className="JoinForm acc-icon">
                            <div className="acc-lg-icon"><i><MdOutlineDriveFileRenameOutline/></i></div>
                            <div className="acc-input-form">
                                <input className="JoinInput" type="text" placeholder=" "
                                       value={userName} onChange={onChangeName}
                                       minLength="2" maxLength="25"/>
                                <label className="JoinLabel">{t(`joinLabel5`)}</label>
                            </div>
                        </div>
                        { nameError ?
                            <div className="JoinErrorMsg">
                                <p className="JoinErrMsg">{t(`joinErr9`)}</p>
                            </div>
                            :
                            null
                        }
                        {langChoiceReturn && langSelectedOption.value !== "" ? (
                            // 셀렉트
                                <div className="JoinSelectForm acc-icon c-point">
                                    <div className="acc-lg-icon" style={{width:"13%"}}><i><MdLanguage/></i></div>
                                    <div className="JoinSelectInputForm" style={{width:"87%"}}>
                                        <div
                                            ref={langDropdownRef}
                                            className="dropdown"
                                            tabIndex="0"
                                            onKeyDown={langHandleKeyDown}
                                            onFocus={langHandleOnFocus}
                                            style={{height: "100%", border: "none", outline: "none"}}
                                        >
                                            <div className="dropdown-selected" onClick={() => setLangIsOpen(!langIsOpen)}
                                                 style={{
                                                     height: "100%",
                                                     border: "none",
                                                     display: "flex",
                                                     alignItems: "center",
                                                     color: "darkgray",
                                                     paddingLeft:"18px",
                                                     zIndex:1000,
                                                     position:"relative"
                                                 }}>
                                                {langSelectedOption.label}
                                            </div>
                                            {langIsOpen && (
                                                <ul className="dropdown-options" style={{
                                                    width: "418px",
                                                    height: "auto",
                                                    border: "none",
                                                    boxShadow: "0px 0px 10px lightgray",
                                                    padding: "0",
                                                    position: "absolute",
                                                    right: "0",
                                                    top: "70px",
                                                    zIndex: 3,
                                                    backgroundColor: "white",
                                                    borderRadius:"6px",
                                                    overflow: "hidden" // 추가된 부분
                                                }} onChange={onChangeLang}>
                                                    {langOptions.map((option, index) => (
                                                        <li
                                                            key={option.value}
                                                            onClick={() => langHandleSelect(option, index)}
                                                            className={index === langFocusedIndex ? 'focused' : '' && "mypgFilterBt"}
                                                            style={{
                                                                width: "100%",
                                                                height: "50px",
                                                                border: "none",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {option.label}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div style={{position:"absolute", right:"10px", top:"15px"}}><i style={{fontSize:"18px", color:"darkgray"}}><BiCaretDown/></i></div>
                                    </div>
                                </div>
                            ) :
                            // 인풋
                                <div className="JoinLangForm acc-icon">
                                    <div className="JoinSelectIcon" style={{width:"14%", padding:"0 2px 9px 0"}}><i><MdLanguage/></i></div>
                                    <div className="LangInputForm" style={{width:"86%"}}>
                                        <input className="JoinInput user-input"
                                               type="text" placeholder=" "
                                               value={lang}
                                               onChange={onChangeLang}
                                               maxLength="15"
                                        />
                                        <label className="JoinLabel" style={{paddingLeft:"9px"}}>{t(`joinLabel6`)}</label>
                                    </div>
                                    <div className="SwitchToSelectIcon acc-lg-icon"><a className="m-t-3" onClick={LangHandle}><i><BiX/></i></a></div>
                                </div>
                        }
                    </div>
                    { langError ?
                        <div className="JoinErrorMsg">
                            <p className="JoinErrMsg">{t(`joinErr10`)}</p>
                        </div>
                        :
                        null
                    }
                    {/*약관 컨테이너*/}
                    <div style={{
                        border: "solid",
                        marginTop: "27px",
                        minHeight: "200px",
                        borderWidth: "thin 1px",
                        borderColor: "#EAEAEA",
                        borderRadius: "4px",
                        padding: "10px 20px"
                    }}>
                        {/*전체동의*/}
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "solid",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            minHeight: "60px",
                            marginBottom: "10px",
                            cursor: "pointer"
                        }}>
                            <input id="AllAgreeCheckBox" type="checkbox"
                                   style={{width: "18px", height: "18px", cursor: "pointer"}}
                                   checked={allCheck} onClick={MailState} onChange={AllAgreeCheck}/>
                            <label htmlFor="AllAgreeCheckBox"><span style={{
                                color: "dimgray",
                                fontSize: "15px",
                                marginLeft: "10px",
                                cursor: "pointer"
                            }}>{t(`joinAgAll`)}</span></label>
                        </div>
                        {/*비토리지 이용약관*/}
                        <div style={{display: "flex", alignItems: "center", minHeight: "40px", marginTop: "10px"}}>
                            <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                <input id="AgreeCheckBox" type="checkbox"
                                       style={{width: "18px", height: "18px", cursor: "pointer"}}
                                       checked={ServiceAgreeCheck} onChange={serviceCheck}/>
                            </div>
                            <div style={{width: "92%"}}>
                                <label htmlFor="AgreeCheckBox"><span style={{
                                    color: "#818181",
                                    fontSize: "15px",
                                    cursor: "pointer"
                                }}>{t(`joinAg1`)}</span>
                                    <span style={{
                                        color: "#FF7C48",
                                        fontSize: "14px",
                                        marginLeft: "3px"
                                    }}>({t(`essential`)})</span>
                                </label>
                            </div>
                            <span onClick={() => setTermsOfUsePopup(true)} style={{
                                cursor: "pointer",
                                width: "10%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                    <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView}
                                         alt="view"/>
                            </span>
                        </div>
                        {/*개인정보처리방침*/}
                        <div style={{display: "flex", alignItems: "center", minHeight: "40px"}}>
                            <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                <input id="InformationCheckBox" type="checkbox"
                                       style={{width: "18px", height: "18px", cursor: "pointer"}}
                                       checked={InformationCheck} onChange={infoCheck}/>
                            </div>
                            <div style={{width: "92%"}}>
                                <label htmlFor="InformationCheckBox"><span style={{
                                    color: "#818181",
                                    fontSize: "15px",
                                    cursor: "pointer"
                                }}>{t(`joinAg2`)}</span>
                                    <span style={{
                                        color: "#FF7C48",
                                        fontSize: "14px",
                                        marginLeft: "3px"
                                    }}>({t(`essential`)})</span>
                                </label>
                            </div>
                            <span onClick={() => setPrivacyPolicyPopup(true)} style={{
                                cursor: "pointer",
                                width: "10%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                    <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView}
                                         alt="view"/>
                            </span>
                        </div>
                        {/*마케팅활용동의*/}
                        <div style={{display: "flex", alignItems: "center", minHeight: "40px"}}>
                            <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                <input id="markUseCheckBox" type="checkbox"
                                       style={{width: "18px", height: "18px", cursor: "pointer"}}
                                       checked={markUseAgreeCheck} onChange={markCheck}/>
                            </div>
                            <div style={{width: "92%"}}>
                                <label htmlFor="markUseCheckBox"><span style={{
                                    color: "#818181",
                                    fontSize: "15px",
                                    cursor: "pointer"
                                }}>{t(`joinAg4`)}</span>
                                    <span style={{
                                        color: "darkgray",
                                        fontSize: "14px",
                                        marginLeft: "3px"
                                    }}>({t(`select`)})</span>
                                </label>
                            </div>
                            <span onClick={() => setMarkUsePopup(true)} style={{
                                cursor: "pointer",
                                width: "10%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end"
                            }}>
                                    <img style={{width: "20px", height: "20px", opacity: "0.4"}} src={termsView}
                                         alt="view"/>
                            </span>
                        </div>
                        {/*메일수신동의*/}
                        <div style={{display: "flex", alignItems: "center", minHeight: "40px"}}>
                            <div style={{width: "8%", display: "flex", alignItems: "center"}}>
                                <input id="MailReceptionCheckBox" type="checkbox"
                                       style={{width: "18px", height: "18px", cursor: "pointer"}}
                                       checked={MailReceptionCheck} onClick={MailState} onChange={MailFlCheck}/>
                            </div>
                            <div style={{width: "92%"}}>
                                <label htmlFor="MailReceptionCheckBox"><span style={{
                                    color: "#818181",
                                    fontSize: "15px",
                                    cursor: "pointer"
                                }}>{t(`joinAg3`)}</span>
                                    <span style={{
                                        color: "darkgray",
                                        fontSize: "14px",
                                        marginLeft: "3px"
                                    }}>({t(`select`)})</span>
                                </label>
                            </div>
                        </div>
                        {isChecked === false ?
                            <div className="MailUnCheck">{t(`joinAgMailMsg`)}</div>
                            :
                            null
                        }
                    </div>
                    {agreeError ?
                        <div className="JoinErrorMsg" style={{marginTop: "10px"}}>
                            <p className="JoinErrMsg">{t(`joinErr11`)}</p>
                        </div>
                        :
                        null
                    }
                    <button onClick={errorAndSave} className="bt-btn-B wd-100pc ht-50 font-s-15 m-t-20">{t(`joinBt`)}</button>
                    {/*가입하기 버튼*/}
                </div>
            </div>
            {termsOfUsePopup ?
                <CommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
                :
                privacyPolicyPopup ?
                    <CommonTermsPopup privacyPolicyPopup={privacyPolicyPopup}
                                      closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
                    :
                    markUsePopup ?
                        <CommonTermsPopup markUsePopup={markUsePopup}
                                          closeMarkUsePopup={closeMarkUsePopup}/>
                        :
                    saveMsgSt ?
                        <SaveMsgModal message={savePopupMsg}/>
                        :
                        null
            }
        </div>
    )
}
