import React, {useEffect, useState} from "react";
import { loadPaypalScript } from '../../../services/PaypalUtils';
import '../../../css/web/PaypalModal.css'
import PaypalPaymentBt from "./PaypalPaymentBt";
import MsgConfirmModal from "./MsgConfirmModal";
import {useTranslation} from "react-i18next";
import commonClose from "../../../assets/img/commonClose.png";

// ▶▶ 웹 페이팔결제 API 연동프로세스(Paypal Javascript SDK)
// 1. OrderPg에서 페이팔 선택하여 PaypalModal 팝업오픈
// 2. 오픈 후 currency값 업데이트 시(페이팔 결제버튼 클릭 시) useEffect을 통해 값 전달과 함께 loadPaypalScript(PaypalUtils.js) 실행
// 3. loadPaypalScript(PaypalUtils.js)가 실행되면 페이팔팝업이 나타나서 카드번호입력 또는 페이팔계정으로 결제처리
// 4. 결제완료 후 scriptLoaded가 true로 되면서 PaypalPaymentBt.js의 함수가 실행되어 페이팔 측 응답값을 processAfterPaypal(state, orderId)로 반환
// 5. 반환값이 "COMPLETED"면 OrderPg의 paypalOrderProcess()를 실행하고 백엔드로 COMPLETE를 전달하여 OS/IS 결제완료처리
// 6. 반환값이 "PENDING"이면 OrderPg의 paypalOrderProcess()를 실행하고 백엔드로 PENDING을 전달하여 OS/IS 결제중처리
// 7. PENDING 상태는 추후에 페이팔이 COMPLETED로 업데이트되면 백엔드의 PaypalWebhook.handlePaypalWebhook메서드가 호출되고 결제내역의 ConfirmPay() 실행하여 OS/IS 결제완료처리

function PaypalModal({paypalPopupClose, currency, finalPayExpense, lastForPrice, paypalOrderProcess}) {
    const { t } = useTranslation();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        // currency 값 설정 시 PaypalUtils에 전달하여 스크립트로드
        loadPaypalScript(currency).then(() => {
            setScriptLoaded(true);
        });
    }, [currency]);

    const [cardNumber, setCardNumber] = useState('');
    const [exp, setExp] = useState('');
    const [cvv, setCvv] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [bZip, setBZip] = useState('');
    const [payErrorMsg, setPayErrorMsg] = useState(false);

    const processAfterPaypal = ((state, orderId) => {
        if (state === "COMPLETED" || state === "PENDING") {
            paypalOrderProcess(state, orderId);
        } else {
            setPayErrorMsg(true);
        }
    })

    const payErrorMsgWord = <p style={{margin:"0", fontSize:"15px"}}>{t(`pmMdMsg`)} &#128514;</p>

    return (
        <div className="bookContainer" style={{display: "flex"}}>
            <div className="bookFrame" style={{
                width: "600px",
                height: "800px",
                top: "calc(25vh - 170px)",
                left: "calc(50vw - 280px)",
                borderRadius: "5px",
                padding:"20px",
                overflowY:"auto"
            }}>
                <div className="font-t-cont">
                    <div className="display-flex just-cont-fend m-b-20">
                        <img onClick={paypalPopupClose} className="c-point wd-30 ht-30 m-a-opc-06" src={commonClose} alt="" style={{marginRight: "-4px"}}/>
                    </div>
                    <div style={{border:"solid", borderWidth:"thin 1px", borderColor:"lightgray", borderRadius:"4px", padding:"15px"}}>
                        <div style={{height:"40px", display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
                            <div style={{minWidth:"200px", height:"100%", display:"flex", alignItems:"center"}}>
                                <p className="font-t-title font-s-17">{t(`pmHeader1`)}</p></div>
                            {/*<div onClick={paypalPopupClose} style={{width:"100px", height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-end", cursor:"pointer"}}>*/}
                            {/*    <span style={{margin:"0", fontSize:"16px", color:"darkorange"}}>Back</span>*/}
                            {/*</div>*/}
                        </div>
                        <div style={{height:"45px", display:"flex", justifyContent:"space-between", borderBottom:"solid", borderWidth:"thin 1px", borderColor:"lightgray"}}>
                            <div style={{width:"200px", height:"100%", display:"flex", alignItems:"center"}}>
                                <span style={{margin:"0", fontSize:"16px", color:"dimgrey"}}>{t(`pcWon`)}</span></div>
                            <div onClick={paypalPopupClose} style={{width:"100px", height:"100%", display:"flex",
                                alignItems:"center", justifyContent:"flex-end", cursor:"pointer"}}>
                                <span style={{margin:"0", fontSize:"16px"}}>{finalPayExpense && finalPayExpense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                            </div>
                        </div>
                        <div style={{height:"45px", display:"flex", justifyContent:"space-between"}}>
                            <div style={{width:"200px", height:"100%", display:"flex", alignItems:"center"}}>
                                <span style={{margin:"0", fontSize:"16px", fontWeight:"bold", color:"black"}}>{t(`pmHeader2`)}</span></div>
                            <div onClick={paypalPopupClose} style={{width:"100px", height:"100%", display:"flex", alignItems:"center", justifyContent:"flex-end", cursor:"pointer"}}>
                                <span style={{margin:"0", fontSize:"16px", fontWeight:"bold", color:"black"}}>{lastForPrice && lastForPrice}</span>
                                <span style={{marginLeft:"3px"}}>{currency && currency}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="font-t-cont" style={{marginTop:"20px"}}>
                    <div>
                        <div style={{height:"40px", marginBottom:"10px"}}>
                            <div style={{width:"200px", height:"100%", display:"flex", alignItems:"center"}}>
                                <p style={{margin:"0", fontSize:"16px", fontWeight:"bold"}}>{t(`pmHeader3`)}</p></div>
                        </div>
                        <div style={{height:"50px", width:"100%"}}>
                            {/*<button onClick={paypalPayment} className="paypalBt" style={{fontWeight:"bold", fontSize:"18px", borderRadius:"4px",*/}
                            {/*    width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>*/}
                            {/*    <Image style={{width:"100px", height:"90px"}} src={paypalFullLogo}/>*/}
                            {/*</button>*/}
                            {scriptLoaded &&
                            <PaypalPaymentBt total={lastForPrice} processAfterPaypal={processAfterPaypal} currency={currency}/>
                            }
                        </div>
                        {/*<div style={{borderBottom:"solid", borderWidth:"thin 1px", borderColor:"lightgrey", height:"30px", position:"relative"}}>*/}
                        {/*    <span style={{position:"absolute", left:"45%", top:"45%", width:"50px", height:"30px", background:"white", textAlign:"center", fontSize:"18px", color:"dimgrey"}}>or</span>*/}
                        {/*</div>*/}


                        {/*<div style={{height:"60px", marginTop:"30px"}}>*/}
                        {/*    <div className="ReviewPgSearchFrame acc-icon">*/}
                        {/*        <div className="ReviewPgInputForm" style={{width: "97%"}}>*/}
                        {/*            <input className="ReviewInput user-input"*/}
                        {/*                   style={{width: "100%", marginLeft: "10px", height: "100%", zIndex:10}}*/}
                        {/*                   type="text"*/}
                        {/*                   placeholder=""*/}
                        {/*                   maxLength={30}*/}
                        {/*                   onChange={(e)=> setCardNumber(e.target.value)}*/}
                        {/*            />*/}
                        {/*            <label className="JoinLabel" style={{fontSize: "14px"}}>Card Number</label>*/}
                        {/*            <div style={{position:"absolute", top:"1%", left:"65%", height:"100%", width:"38%",*/}
                        {/*                display:"flex", justifyContent:"flex-end", alignItems:"center"}}>*/}
                        {/*                <Image style={{width:"40px", height:"40px"}} src={visa}/>*/}
                        {/*                <Image style={{width:"40px", height:"40px", marginLeft:"5px"}} src={master}/>*/}
                        {/*                <Image style={{width:"40px", height:"40px", marginLeft:"5px"}} src={amex}/>*/}
                        {/*                <Image style={{width:"40px", height:"50px", marginLeft:"5px", marginRight:"10px"}} src={discover}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div style={{height:"60px", display:"flex", justifyContent:"space-between", marginTop:"10px"}}>*/}
                        {/*    <div className="ReviewPgSearchFrame acc-icon" style={{width:"48%"}}>*/}
                        {/*        <div className="ReviewPgInputForm" style={{width: "93%"}}>*/}
                        {/*            <input className="ReviewInput user-input"*/}
                        {/*                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"*/}
                        {/*                   placeholder=""*/}
                        {/*                   onChange={(e)=> setExp(e.target.value)}*/}
                        {/*            />*/}
                        {/*            <label className="JoinLabel" style={{fontSize: "14px"}}>Expiration</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="ReviewPgSearchFrame acc-icon" style={{width:"48%", marginLeft:"10px"}}>*/}
                        {/*        <div className="ReviewPgInputForm" style={{width: "93%"}}>*/}
                        {/*            <input className="ReviewInput user-input"*/}
                        {/*                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"*/}
                        {/*                   placeholder=""*/}
                        {/*                   onChange={(e)=> setCvv(e.target.value)}*/}
                        {/*            />*/}
                        {/*            <label className="JoinLabel" style={{fontSize: "14px"}}>CVV</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div style={{height:"60px", display:"flex", justifyContent:"space-between", marginTop:"10px"}}>*/}
                        {/*    <div className="ReviewPgSearchFrame acc-icon" style={{width:"48%"}}>*/}
                        {/*        <div className="ReviewPgInputForm" style={{width: "93%"}}>*/}
                        {/*            <input className="ReviewInput user-input"*/}
                        {/*                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"*/}
                        {/*                   placeholder=""*/}
                        {/*                   onChange={(e)=> setFName(e.target.value)}*/}
                        {/*            />*/}
                        {/*            <label className="JoinLabel" style={{fontSize: "14px"}}>First Name</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="ReviewPgSearchFrame acc-icon" style={{width:"48%", marginLeft:"10px"}}>*/}
                        {/*        <div className="ReviewPgInputForm" style={{width: "93%"}}>*/}
                        {/*            <input className="ReviewInput user-input"*/}
                        {/*                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"*/}
                        {/*                   placeholder=""*/}
                        {/*                   onChange={(e)=> setLName(e.target.value)}*/}
                        {/*            />*/}
                        {/*            <label className="JoinLabel" style={{fontSize: "14px"}}>Last Name</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div style={{height:"60px", marginTop:"10px"}}>*/}
                        {/*    <div className="ReviewPgSearchFrame acc-icon">*/}
                        {/*        <div className="ReviewPgInputForm" style={{width: "97%"}}>*/}
                        {/*            <input className="ReviewInput user-input"*/}
                        {/*                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"*/}
                        {/*                   placeholder=""*/}
                        {/*                   onChange={(e)=> setBZip(e.target.value)}*/}
                        {/*            />*/}
                        {/*            <label className="JoinLabel" style={{fontSize: "14px"}}>Billing zip code</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        {/*<div style={{height:"60px", marginTop:"30px"}}>*/}
                        {/*    <button className="orderItemHandleBt" style={{width:"100%" ,height: "50px", margin:"0", fontSize:"18px", borderRadius:"4px"}}>Payment</button>*/}
                        {/*</div>*/}


                        {/*<div style={{height:"100px", marginTop:"10px"}}>*/}
                        {/*    <div style={{fontSize:"12px", color:"dimgrey"}}>※ 페이팔 개인정보 보호정책에 따라 페이팔에서 데이터를 수집/처리할 수 있습니다</div>*/}
                        {/*    /!*<div style={{fontSize:"12px", marginTop:"10px", color:"dimgrey"}}>※ 위 비토리지와 연동된 페이팔을 통한 결제 시, 결제 및 기타 식별 정보 등 페이팔은 사기 탐지, 피해 및 손실 방지,*!/*/}
                        {/*    /!*    인증, 서비스 성능과 관련된 분석을 포함하여 페이팔이 제공하는 서비스 운영/개선/규정준수를 위해 해당 정보를 사용할 수 있으며,*!/*/}
                        {/*    /!*    법적 요구사항 및 해당 정보 처리 시에는 페이팔에서 규정한 개인정보취급방침이 적용됩니다.</div>*!/*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
            {payErrorMsg ?
                <MsgConfirmModal payErrorMsg={payErrorMsg} payErrorMsgClose={setPayErrorMsg(false)} payErrorMsgWord={payErrorMsgWord}/>
                :
                null
            }
        </div>
    )
}

export default PaypalModal
