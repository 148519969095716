import React, {useEffect, useState} from 'react';
import axios from '../../services/axiosInstance';
import i18n from "../../i18n";
import '../../css/web/AdminPopupSetPg.css';
import {useTranslation} from "react-i18next";

function UserMainPopModal() {

    /** region DataSet */

    const [popupData, setPopupData] = useState([]);
    const [isSmallOpen, setIsSmallOpen] = useState(true);
    const [isMediumOpen, setIsMediumOpen] = useState(true);
    const [isLargeOpen, setIsLargeOpen] = useState(true);

    /** endregion */

    /** region Function */

    const {t} = useTranslation();

    const getFutureDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    };

    const handlePopupClose = (popupSize, days) => {
        const futureDate = getFutureDate(days);
        localStorage.setItem(`${popupSize}PopupHideUntil`, futureDate);
        if (popupSize === 'small') setIsSmallOpen(false);
        if (popupSize === 'medium') setIsMediumOpen(false);
        if (popupSize === 'large') setIsLargeOpen(false);
    };

    const checkPopupVisibility = () => {
        const now = new Date();
        const smallPopupHide = localStorage.getItem('smallPopupHideUntil');
        const mediumPopupHide = localStorage.getItem('mediumPopupHideUntil');
        const largePopupHide = localStorage.getItem('largePopupHideUntil');

        if (smallPopupHide && new Date(smallPopupHide) > now) {
            setIsSmallOpen(false);
        }
        if (mediumPopupHide && new Date(mediumPopupHide) > now) {
            setIsMediumOpen(false);
        }
        if (largePopupHide && new Date(largePopupHide) > now) {
            setIsLargeOpen(false);
        }
    };

    /** endregion */

    useEffect(() => {
        const selectPopupData = (lang) => {
            axios.get(`/popup/selectPopupList?lang=${lang}`)
                .then((response) => {
                    const filteredData = response.data.filter((popup) =>
                        popup.pmachineType === "0" && (popup.psizeType === "0" || popup.psizeType === "1" || popup.psizeType === "2")
                    );
                    const popupData = filteredData.map((data) => ({
                        ...data,
                        imageUrl: data?.bannerPopPhotoDTOS?.[0]?.fileNm
                            ? `https://toeloperfile.s3.ap-northeast-2.amazonaws.com/popupImg/${data.bannerPopPhotoDTOS[0].fileNm}`
                            : null
                    }));
                    setPopupData(popupData);
                });
        };

        const handleLanguageChange = (lng) => {
            let lang;
            switch (lng) {
                case 'ko':
                    lang = '0';
                    break;
                case 'en':
                    lang = '1';
                    break;
                case 'ja':
                    lang = '2';
                    break;
                case 'zh':
                    lang = '3';
                    break;
                case 'id':
                    lang = '4';
                    break;
                default:
                    lang = '0';
            }
            selectPopupData(lang.charAt(0));
            checkPopupVisibility();
        };
        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const smallPopups = popupData.filter(popup => popup.psizeType === '0');
    const mediumPopups = popupData.filter(popup => popup.psizeType === '1');
    const largePopups = popupData.filter(popup => popup.psizeType === '2');
    const hasSmallPopups = smallPopups && smallPopups.length > 0;
    const hasMediumPopups = mediumPopups && mediumPopups.length > 0;
    const hasLargePopups = largePopups && largePopups.length > 0;

    const isAnyPopupOpen = (isSmallOpen && hasSmallPopups) ||
        (isMediumOpen && hasMediumPopups) ||
        (isLargeOpen && hasLargePopups);

    return (
        <>
            {isAnyPopupOpen && (
                <div className="custom-popup-container font-t-cont">
                    {isSmallOpen && smallPopups.map((popup, index) => (
                        <div className="custom-popup-frame popup-s-frame" key={index}>
                            <div className="c-p-main ht-86pc position-relative">
                                {/*<div className="position-absolute text-right c-point font-s-26" style={{ zIndex: 100, top: "3px", right: "10px", color: "#e8e8e8" }}>*/}
                                {/*    <RxCross1 onClick={() => handlePopupClose('small', 0)} />*/}
                                {/*</div>*/}
                                <div className="wd-100pc ht-100pc">
                                    {popup.imageUrl ? (
                                        <img className="wd-100pc ht-100pc" src={popup.imageUrl} alt="popup" style={{ objectFit: "cover", cursor: popup.pimgType === '0' ? 'default' : 'pointer' }}
                                            onClick={() => {
                                                if (popup.pimgType === '1') {
                                                    window.open(popup.purl, '_self');
                                                }
                                            }}
                                        />
                                    ) : (
                                        <p>Image Error</p>
                                    )}
                                </div>
                            </div>
                            <div className="c-p-footer ht-auto display-flex flex-row just-cont-sb gap10 c-def p-t-10 p-r-10 p-b-10 p-l-10 font-s-13" style={{ borderTop: "solid 1px #EAEAEA" }}>
                                <div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('small', 1)}>{t(`popupFooterToday`)}</div>
                                {/*<div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('small', 7)}>7일 동안 보지 않기</div>*/}
                                <div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('small', 0)}>{t(`popupFooterClose`)}</div>
                            </div>
                        </div>
                    ))}
                    {isMediumOpen && mediumPopups.map((popup, index) => (
                        <div className="custom-popup-frame popup-m-frame" key={index}>
                            <div className="c-p-main ht-90pc position-relative">
                                <div className="wd-100pc ht-100pc">
                                    {popup.imageUrl ? (
                                        <img className="wd-100pc ht-100pc" src={popup.imageUrl} alt="popup" style={{ objectFit: "cover", cursor: popup.pimgType === '0' ? 'default' : 'pointer' }}
                                             onClick={() => {
                                                 if (popup.pimgType === '1') {
                                                     window.open(popup.purl, '_self');
                                                 }
                                             }}
                                        />
                                    ) : (
                                        <p>Image Error</p>
                                    )}
                                </div>
                            </div>
                            <div className="c-p-footer ht-auto display-flex flex-row just-cont-sb gap10 c-def p-t-10 p-r-10 p-b-10 p-l-10 font-s-13" style={{ borderTop: "solid 1px #EAEAEA" }}>
                                <div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('medium', 1)}>{t(`popupFooterToday`)}</div>
                                <div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('medium', 0)}>{t(`popupFooterClose`)}</div>
                            </div>
                        </div>
                    ))}
                    {isLargeOpen && largePopups.map((popup, index) => (
                        <div className="custom-popup-frame popup-l-frame" key={index}>
                            <div className="c-p-main ht-92pc position-relative">
                                <div className="wd-100pc ht-100pc">
                                    {popup.imageUrl ? (
                                        <img className="wd-100pc ht-100pc" src={popup.imageUrl} alt="popup" style={{ objectFit: "cover", cursor: popup.pimgType === '0' ? 'default' : 'pointer' }}
                                             onClick={() => {
                                                 if (popup.pimgType === '1') {
                                                     window.open(popup.purl, '_self');
                                                 }
                                             }}
                                        />
                                    ) : (
                                        <p>Image Error</p>
                                    )}
                                </div>
                            </div>
                            <div className="c-p-footer ht-auto display-flex flex-row just-cont-sb gap10 c-def p-t-10 p-r-10 p-b-10 p-l-10 font-s-13" style={{ borderTop: "solid 1px #EAEAEA" }}>
                                <div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('large', 1)}>{t(`popupFooterToday`)}</div>
                                <div className="wd-50pc ht-40 text-center align-center-c c-point pop-btn" onClick={() => handlePopupClose('large', 0)}>{t(`popupFooterClose`)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default UserMainPopModal
