import React, {useCallback, useEffect, useState} from 'react';
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import axios from '../../services/axiosInstance';
import {MdKeyboardArrowDown} from "react-icons/md";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import maCateImg from "../../assets/img/m-a-cate.png";
import profileImage from "../../assets/img/profileDefaultImg.png";
import SaveMsgModal from "../../components/web/modal/SaveMsgModal";
import deposit from "../../assets/img/depositPay.png";
import point from "../../assets/img/pointPay.png";
import coupons from "../../assets/img/coupons.png";

function AdminUserInfoPg() {

    /**region DataTable */

    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    /** endregion */

    /** region Function */

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('adminToken');

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = userData.filter((cData) => {
        return (
            cData?.userName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
            cData?.lang?.toLowerCase().includes(searchInput?.toLowerCase()) ||
            cData?.id?.toLowerCase().includes(searchInput?.toLowerCase())
        );
    });

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [searchData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (searchData.length > displayLimit && displayLimit < 10) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    // region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id, event) => {
        event.stopPropagation(); // Stop event propagation
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = searchData.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.mbNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Alert

    const [pendingUserOutFl, setPendingUserOutFl] = useState(null);
    const [pendingUserFeeFl, setPendingUserFeeFl] = useState(null);
    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const [alertShow3, setAlertShow3] = useState(false);
    const msgConfirmData1 = "선택된 회원이 탈퇴됩니다.";
    const msgConfirmData2 = "정말 탈퇴하시겠습니까?";
    const msgConfirmData3 = "해당 유저의 프로필 이미지가 삭제됩니다.";
    const msgConfirmData4 = "정말 삭제하시겠습니까?";
    const msgConfirmData5 = "해당 고객은 배송대행 수수료가 면제됩니다.";
    const msgConfirmData6 = "정말 변경하시겠습니까?";
    const outFlAlert = (newValue) => {
        setPendingUserOutFl(newValue);
        setAlertShow(true);
    };
    const profileDeleteAlert = (newValue) => {
        setPendingUserOutFl(newValue);
        setAlertShow2(true);
    };
    const delFeeFlAlert = (newValue) => {
        setPendingUserFeeFl(newValue);
        setAlertShow3(true);
    };

    const modalCancel = useCallback (() => {setAlertShow(false);})
    const modalCancel2 = useCallback (() => {setAlertShow2(false);})
    const modalCancel3 = useCallback (() => {setAlertShow3(false);})

    //endregion

    const handleRowClick = (user) => {
        setSelectedUser(user);
    };

    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);

    const [openImagePop, setOpenImagePop] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const openImageP = (imageSrc) => {
        setImageSrc(imageSrc);
        setOpenImagePop(true);
    };

    const closeImageP = () => {
        setOpenImagePop(false);
        setImageSrc('');
    };

    const [dbCoupon, setDbCoupon] = useState('');
    useEffect(() => {
        const activeCouponCount = userData[0] && userData[0].coupons && userData[0].coupons.filter(coupon => coupon && coupon.activeFl === true).length;
        setDbCoupon(activeCouponCount || 0);
    }, [userData]);

    /** endregion */

    /** region Event  */

    const profileDelete = (id) => {
        axios({
            method: 'post',
            url: '/member/profileDelete',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                id: id
            },
        })
            .then(() => {
                setUserData(prevData => prevData.map(user =>
                    user.profileDTO && user.profileDTO.seqnum === id
                        ? { ...user, profilePhoto: profileImage, profileDTO: null }
                        : user
                ));
                setAlertShow2(false);
            })
            .catch(() => {});
    };

    useEffect(() => {
        select();
    }, [])

    useEffect(() => {
        if (userData.length > 0) {
            setSelectedUser(userData[0]);
        }
    }, [userData]);

    const select = () => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const filteredData = response.data.filter(item => item.role === "0");
                const sortedData = filteredData.sort((a, b) => a.userName.localeCompare(b.userName));
                setUserData(sortedData);

                const updatedData = sortedData.map(user => ({
                    ...user,
                    profilePhoto: user.profileDTO ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + user.profileDTO.filename : profileImage
                }));

                setUserData(updatedData);
            })
            .catch(() => {})
    };

    const outFlUpdate = (newValue) => {
        axios.post('/member/userOutFlupdate', {
            mbNum: selectedUser.mbNum,
            userOutFl: newValue
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            }
        })
            .then(() => {
                setAlertShow(false);
                setPendingUserOutFl(null);
                setSelectedUser(prevUser => ({ ...prevUser, userOutFl: newValue }));
            })
            .catch((error) => {
                console.error("에러:", error);
            });
    };

    const delFeeUpdate = (newValue) => {
        axios.post('/member/userFeeFlupdate', {
            mbNum: selectedUser.mbNum,
            delFeeEmpFl: Number(newValue)
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            }
        })
            .then(() => {
                setAlertShow3(false);
                setPendingUserFeeFl(null);
                setSelectedUser(prevUser => ({ ...prevUser, delFeeEmpFl: Number(newValue) }));
            })
            .catch((error) => {
                console.error("에러:", error);
            });
    };

    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const [savePopupMsg, setSavePopupMsg] = useState('');

    const [userNm, setUserNm] = useState({});
    const handleUserNmChange = (mbNum, value) => {
        const koreanCharCount = [...value].filter(char => char.charCodeAt(0) >= 0xAC00 && char.charCodeAt(0) <= 0xD7A3).length;

        if (koreanCharCount > 12 || value.length > 25) {
            return;
        }

        setUserNm(prevMemos => ({
            ...prevMemos,
            [mbNum]: value
        }));
    };

    const modifyUserNm = ((mbNum, name) => {
        axios({
            method: 'post',
            url: '/member/modifyUserNm',
            headers: {
                Authorization: LCAT
            },
            params: {
                mbNum: mbNum,
                userNm: name
            }
        })
            .then((response) => {
                const updatedUserMbNum = response.data;
                setUserData(prevData =>
                    prevData.map(user =>
                        user.mbNum === updatedUserMbNum
                            ? { ...user, userName: name }  // 매칭되는 userNm만 변경
                            : user
                    )
                );
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch((error) => {
                setSavePopupMsg("저장 실패");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
    })

    const saveUserNm = (e, mbNum) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            const name = userNm[mbNum] || '';
            modifyUserNm(mbNum, name);
        }
    };

    const [userSpfMemo, setUserSpfMemo] = useState({});
    const handleSpfMemoChange = (mbNum, value) => {
        setUserSpfMemo(prevMemos => ({
            ...prevMemos,
            [mbNum]: value
        }));
    };

    const modifySpfMemo = ((mbNum, memo) => {
        axios({
            method: 'post',
            url: '/member/modifySpfMemo',
            headers: {
                Authorization: LCAT
            },
            data: {
                mbNum: mbNum,
                spfRegName: memo
            }
        })
            .then((response) => {
                const updatedUser = response.data;
                setUserData(prevData =>
                    prevData.map(user =>
                        user.mbNum === updatedUser.mbNum
                            ? updatedUser
                            : user
                    )
                );
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch((error) => {
                setSavePopupMsg("저장 실패");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
    })

    const saveSpfMemo = (e, mbNum) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            const memo = userSpfMemo[mbNum] || '';
            modifySpfMemo(mbNum, memo);
        }
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.mbNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '아이디': item.id,
                    '회원명': item.userName,
                    '사서함번호': 'BT' + item.mbNum,
                    '문의언어국가': item.lang,
                    '예치금': item.deposit,
                    '적립금': item.point,
                    '보유쿠폰개수': '1',
                    '메일수신여부': item.mailRecFl,
                    '가입일시': item.regTime,
                    '회원탈퇴여부': item.userOutFl,
                    '탈퇴일시': '',
                    '특이사항': item.spfRegName,
                    '수정일시': ''
                };
            }

            const sheetConfigs = {
                '회원정보': {
                    name: '회원정보',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '아이디', key: '아이디', width: 25},
                        {header: '회원명', key: '회원명', width: 21, style: { alignment: { horizontal: 'center'}}},
                        {header: '사서함번호', key: '사서함번호', width: 11, style: { alignment: { horizontal: 'center'}}},
                        {header: '문의언어국가', key: '문의언어국가', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '예치금', key: '예치금', width: 10},
                        {header: '적립금', key: '적립금', width: 10},
                        {header: '보유쿠폰개수', key: '보유쿠폰개수', width: 12, style: { alignment: { horizontal: 'right' }}},
                        {header: '메일수신여부', key: '메일수신여부', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '가입일시', key: '가입일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '회원탈퇴여부', key: '회원탈퇴여부', width: 12, style: { alignment: { horizontal: 'center' }}},
                        {header: '탈퇴일시', key: '탈퇴일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '특이사항', key: '특이사항', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '수정일시', key: '수정일시', width: 20, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['회원정보'].name, sheetConfigs['회원정보'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `비토리지 회원정보_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminUserInfoPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">회원정보</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button onClick={excelDown} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">EXCEL</button>
                        </div>
                    </div>
                    <div className="display-flex gap15">
                        {/*회원정보*/}
                        <div className="wd-50pc">
                            <div className="div-tb">
                                <div className="div-tb-head ht-60">
                                    <div className="wd-35">
                                        <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === userData.length} onChange={allcheckBoxChange}/>
                                    </div>
                                    <div className="wd-80"><p>사서함번호</p></div>
                                    <div className="wd-160"><p>회원명</p></div>
                                    <div className="wd-260"><p>아이디</p></div>
                                    <div className="wd-100"><p>CS국가</p></div>
                                </div>
                                {searchData.slice(0, displayLimit).map((cData, i) => (
                                    <div key={i} className="div-tb-body ht-70 c-point" onClick={() => handleRowClick(cData)}>
                                        <div className="wd-35">
                                            <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.mbNum} checked={checked.includes(cData.mbNum)} onChange={(e) => checkBoxChange(cData.mbNum, e)}/>
                                        </div>
                                        {/*<div className="wd-80"><p>{indexOfFirst + i + 1}</p></div>*/}
                                        <div className="wd-80"><p>BT {cData.mbNum}</p></div>
                                        <div className="wd-160"><p className="font-b">{cData.userName}</p></div>
                                        <div className="wd-260 admin-tb-text-cut"><p>{cData.id}</p></div>
                                        <div className="wd-100"><p>{cData.lang}</p></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/*회원상세정보*/}
                        <div className="wd-50pc">
                            <div className="user-detail-container">
                                <div className="user-detail-card">
                                    <div className="ht-60 user-detail-frame p-t-18"><p className="font-s-18">회원상세정보</p></div>
                                    {selectedUser && (
                                        <div className="display-flex flex-column pd10">
                                            <div className="display-flex flex-row wd-100pc">
                                                <div className="wd-55pc">
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">회원명:</label>
                                                        <input className="admin-input-line ht-35 p-l-5 wd-180" type="text"
                                                               placeholder="Enter키로 저장"
                                                               value={userNm[selectedUser.mbNum] ?? selectedUser.userName ?? ''}
                                                               onChange={(e) => handleUserNmChange(selectedUser.mbNum, e.target.value)}
                                                               onKeyDown={(e) => saveUserNm(e, selectedUser.mbNum)}
                                                               maxLength={25}
                                                        />
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">아이디:</label>
                                                        <div className="" style={{wordBreak: "break-all", whiteSpace: "normal", overflowWrap: "break-word"}}>{selectedUser.id}</div>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">사서함번호:</label>
                                                        <p className="font-b">BT {selectedUser.mbNum}</p>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">CS 국가:</label>
                                                        <p className="">{selectedUser.lang}</p>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">가입일시:</label>
                                                        <p className="">{selectedUser.regTime}</p>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">수수료면제:</label>
                                                        <select className="admin-combo wd-130 ht-35" value={selectedUser.delFeeEmpFl ? "1" : "0"}
                                                                onChange={(e) => {const newValue = e.target.value === "1";delFeeFlAlert(newValue);}}>
                                                            <option value="0">N:대상아님</option>
                                                            <option value="1">Y:면제대상</option>
                                                        </select>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120 font-b">메일수신여부:</label>
                                                        <p className="">{selectedUser.mailRecFl === 'Y' ? 'Y:수신' : 'N' ? 'N:거부' : null}</p>
                                                    </div>
                                                    <div className="detail-item m-t-40">
                                                        <label className="wd-120">탈퇴여부:</label>
                                                        <select className="admin-combo wd-130 ht-35" value={selectedUser.userOutFl}
                                                                onChange={(e) => {const newValue = e.target.value;outFlAlert(newValue);}}>
                                                            <option value="Y">Y</option>
                                                            <option value="N">N</option>
                                                        </select>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120">탈퇴일시:</label>
                                                        <p className="">{selectedUser.secTime}</p>
                                                    </div>
                                                    <div className="detail-item">
                                                        <label className="wd-120">정보수정일시:</label>
                                                        <p className="">{selectedUser.modiTime}</p>
                                                    </div>
                                                </div>
                                                <div className="display-flex flex-column just-cont-center text-center wd-45pc">
                                                    <div className="m-b-50" style={{marginLeft: "auto", marginRight: "auto"}}>
                                                        <div className="admin-profile-modi c-point" onClick={() => openImageP(selectedUser.profilePhoto)}
                                                             style={{display: "block", width: "180px", height: "180px", borderRadius: "50%", verticalAlign: "middle"}}>
                                                            <img className="admin-profile-modi-img" alt="" src={selectedUser.profilePhoto}/>
                                                        </div>
                                                        <button className="admin-btn-B wd-100 ht-35 font-s-12 btn-click-event m-t-20" onClick={profileDeleteAlert}>이미지삭제</button>
                                                    </div>
                                                    <div className="m-b-5">
                                                        <img style={{width:"24px", height:"24px"}} alt="deposit" src={deposit}/>
                                                        <a className="m-l-10">&#8361; {selectedUser && selectedUser.deposit != null ? selectedUser.deposit.toLocaleString() : 0}</a>
                                                    </div>
                                                    <div className="m-b-5">
                                                        <img style={{width:"22px", height:"22px"}} alt="point" src={point}/>
                                                        <a className="m-l-10">&#8361; {selectedUser && selectedUser.point != null ? selectedUser.point.toLocaleString() : 0}</a>
                                                    </div>
                                                    <div>
                                                        <img style={{width:"22px", height:"22px"}} alt="coupon" src={coupons}/>
                                                        {dbCoupon && dbCoupon > 0 ? <a className="m-l-10">{dbCoupon} EA</a> : <a>0 EA</a>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-flex align-center">
                                                <label className="wd-120 font-b">특이사항:</label>
                                                <textarea className="admin-input-line min-ht-50 max-ht-120 p-l-5 wd-600" placeholder="Enter키로 저장"
                                                          value={userSpfMemo[selectedUser.mbNum] ?? selectedUser.spfRegName ?? ''}
                                                          onChange={(e) => handleSpfMemoChange(selectedUser.mbNum, e.target.value)}
                                                          onKeyDown={(e) => saveSpfMemo(e, selectedUser.mbNum)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {displayLimit >= 10 && searchData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {openImagePop && (
                <div className="pop-dt-img-container" onClick={closeImageP}>
                    <div className="pop-dt-img-frame" onClick={(e) => e.stopPropagation()}>
                        <img className="pop-dt-img" src={imageSrc} alt="profileDelete" />
                        <button className="pop-dt-close font-t-head" onClick={closeImageP}>X</button>
                    </div>
                </div>
            )}
            {
                saveMsgSt ?
                    <SaveMsgModal message={savePopupMsg} />
                    :
                    null
            }
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={() => outFlUpdate(pendingUserOutFl)} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={() => profileDelete(selectedUser.profileDTO.seqnum)} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}
            {alertShow3 === true ? <MsgSelectModal alertShow={alertShow3} eventHandle1={modalCancel3} eventHandle2={() => delFeeUpdate(pendingUserFeeFl)} msgData1={msgConfirmData5} msgData2={msgConfirmData6}/> : null}
            <TopBtn/>
        </div>
    );
}

export default AdminUserInfoPg
