import "../../css/mobile/MCouponUse.css";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from '../../services/axiosInstance';
import commonClose from "../../assets/img/commonClose.png";
import commonHether from "../../assets/img/commonHether.png";

function MCouponUse({closeUseCoupon, couponUseFn}) {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const [couponList, setCouponList] = useState([{}]);

    const [selectedCoupon, setSelectedCoupon] = useState(null);

    const selectCoupon = () => axios({
        method: 'get',
        url: '/coupon/selectCoupon/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            const activeCoupons = response.data.filter(cp => cp.activeFl === true);
            setCouponList(activeCoupons);
        })

    useEffect(() => {
        const timer = setTimeout(() => {
            selectCoupon();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])

    const deadlineDate = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    return (
        <div className={'m-a-coupon-use-price-detail-background'}>
            <div className="m-a-coupon-use-price-detail-container">
                <div className={'m-a-coupon-use-price-detail-header'}>
                    <div className={'m-a-coupon-use-price-detail-header-title'}>{t(`cpuBt`)}</div> {/*쿠폰적용*/}
                    <img className={'m-a-coupon-use-price-detail-header-icon'}
                         onClick={closeUseCoupon} src={commonClose} alt={''}/>
                </div>
                <div className="m-a-coupon-use-price-detail-body">
                    <div className={'m-a-coupon-use-price-detail-table-title'}>
                        <img className={'m-a-coupon-use-price-detail-table-icon'} src={commonHether} alt={''}/>
                        {t(`cpuHeader1`)}
                    </div> {/*사용가능쿠폰*/}
                    <div className={'m-a-prod-price-detail-table'}>
                        <div className={'m-a-prod-price-detail-table-row label'}>
                            <div className={'m-a-prod-price-detail-table-item lg'}>{t(`cpuSMenu1`)}</div> {/*쿠폰명*/}
                            <div className={'m-a-prod-price-detail-table-item'}>{t(`cpuSMenu2`)}</div> {/*쿠폰금액*/}
                            <div className={'m-a-prod-price-detail-table-item'}>{t(`cpuSMenu3`)}</div> {/*사용기한*/}
                        </div>
                        {couponList.map((cp, index) => (
                            <div className={'m-a-prod-price-detail-table-row'} key={index}>
                                <div className={'m-a-prod-price-detail-table-item lg admin-tb-text-cut p-l-7'}>
                                    {cp && cp.couponMstDTO && cp.couponMstDTO.couponNm}
                                </div>
                                <div className={'m-a-prod-price-detail-table-item admin-tb-text-cut'}>
                                    {cp && cp.couponMstDTO && cp.couponMstDTO.couponPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                                <div className={'m-a-prod-price-detail-table-item'}>
                                    ~ {cp?.couponMstDTO?.couponExpDate ? deadlineDate(cp?.couponMstDTO?.couponExpDate) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'m-a-coupon-use-buttons'}>
                        <div className={'m-a-coupon-use-button outline'} onClick={closeUseCoupon}>{t(`cpuCcBt`)}</div> {/*적용취소*/}
                        <div className={'m-a-coupon-use-button'} onClick={() => couponUseFn(selectedCoupon)}>{t(`cpuBt`)}</div> {/*쿠폰적용*/}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MCouponUse;
