import React from "react";
import {Helmet} from "react-helmet-async";

const SeoHelmet = () => {

    return (
        <Helmet>
            {/* 웹마스터도구 사이트검증 */}
            <meta name="naver-site-verification" content="f8329556d78f51ce0fce731c47f5c71e92af2f7e"/>
            <meta name="google-site-verification" content="BNnyt5-Mu70F2UdREMzukFO53BSAa6TA9uD3Q6wB3CM"/>
            <meta name="msvalidate.01" content="A99A3CC15E4F2D3FCEB144BA0C63B1B7"/>
        </Helmet>
    );
};

export default SeoHelmet;


