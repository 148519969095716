import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const NetworkStatusContext = createContext();

export const NetworkStatusProvider = ({ children }) => {
    const [isOffline, setIsOffline] = useState(false);
    const navigate = useNavigate(); // 네트워크 오류 시 이동 처리

    useEffect(() => {
        const handleNetworkError = () => {
            setIsOffline(true); // 네트워크 상태 업데이트
            navigate('/ErrStatusPg'); // 네트워크 오류 페이지로 이동
        };

        const handleOnline = () => setIsOffline(false);

        window.addEventListener('network-error', handleNetworkError);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('network-error', handleNetworkError);
            window.removeEventListener('online', handleOnline);
        };
    }, [navigate]);

    return (
        <NetworkStatusContext.Provider value={{ isOffline }}>
            {children}
        </NetworkStatusContext.Provider>
    );
};