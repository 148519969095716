import React, {useCallback, useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from '../../services/axiosInstance';
import AdminPagination from "../../components/web/layoutsadmin/AdminPagination";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import MAdminSearchEvent from "./MAdminSearchEvent";

function MAdminEmployeeInfoPg() {

    /**region DataTable */

    const [userEmployeeData, setUserEmployeeData] = useState([]);
    const [employeeReqData, setEmployeeReqData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    /** endregion */

    /** region Function */

    const SSAT = sessionStorage.getItem('adminToken');
    const LCAT = localStorage.getItem('adminToken');
    const MAX_DISPLAY_LIMIT = 20;
    const INITIAL_DISPLAY_LIMIT = 10;

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        return cleanedNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    };

    const formatDate = (dateString) => {
        return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`;
    };

    //region Search

    const [searchInput, setSearchInput] = useState('');

    const handleSearchInputChange = (event) => setSearchInput(event.target.value);

    const searchData = userEmployeeData.filter((cData) =>
        cData.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
        cData.id.toLowerCase().includes(searchInput.toLowerCase()) ||
        cData.nicName.toLowerCase().includes(searchInput.toLowerCase())
    );

    //endregion

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(INITIAL_DISPLAY_LIMIT);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [searchData.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (searchData.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => setDisplayLimit((prevLimit) => prevLimit + perPg);

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [perPg, setperPg] = useState(10);
    const [perPg2, setperPg2] = useState(5);

    const indexOfLast2 = currentPage2 * perPg2;
    const indexOfFirst2 = indexOfLast2 - perPg2;

    const pageChange2 = (page) => setCurrentPage2(page);

    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e);
        setperPg(newPerPage);
        setperPg2(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
        setCurrentPage2(1);
    };

    const [popupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [checkedReg, setCheckedReg] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) =>
            prevSelectedData.includes(id) ? prevSelectedData.filter((item) => item !== id) : [...prevSelectedData, id]
        );
    };

    const allCheckBoxChange = (e) => {
        let visibleData = searchData.slice(0, displayLimit);
        setChecked(e.target.checked ? visibleData.map((item) => item.mbNum) : []);
    };

    const checkBoxRegChange = (id) => {
        setCheckedReg((prevSelectedData) =>
            prevSelectedData.includes(id) ? prevSelectedData.filter((item) => item !== id) : [...prevSelectedData, id]
        );
    };

    const allCheckBoxRegChange = (e) => {
        setCheckedReg(e.target.checked ? employeeReqData.map((item) => item.mbNum) : []);
    };

    //endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShow2, setAlertShow2] = useState(false);
    const msgConfirmData1 = "승인 하시겠습니까?";
    const msgConfirmData2 = "승인정보를 확인해주세요.";
    const msgConfirmData3 = "직책구분을 변경 하시겠습니까?";
    const msgConfirmData4 = "변경할 정보를 확인해주세요.";
    const conFirmAlert = () => setAlertShow(true);
    const modalCancel = useCallback(() => setAlertShow(false), []);

    const [pendingUserTypeSt, setPendingUserTypeSt] = useState(null);

    const typeStAlert = (newValue) => {
        setPendingUserTypeSt(newValue);
        setAlertShow2(true);
    };

    const modalCancel2 = useCallback (() => {setAlertShow2(false);})

    //endregion

    const handleRowClick = (user) => {
        setSelectedUser(user);
    };

    function getUserRole(userType) {
        const roles = {
            1: "Staff",
            2: "Snr Staff",
            3: "Assistant",
            4: "Manager",
            5: "Owner",
        };

        return roles[userType] || "Unknown";
    }

    /** endregion */

    /** region Event  */

    useEffect(() => {
        employeeSelect();
        employeesReqSelect();
    }, [])

    const employeeSelect = useCallback(() => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const filteredData = response.data.filter(item => item.role === "1");
                const sortedData = filteredData.sort((a, b) => a.userName.localeCompare(b.userName));
                setUserEmployeeData(sortedData);
            })
            .catch(() => {});
    }, [LCAT, SSAT]);

    const employeesReqSelect = useCallback(() => {
        axios({
            method: 'get',
            url: '/member/adminuserselect',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const filteredData2 = response.data.filter(item => item.joinVerFl === "N" && item.role === "1");
                const sortedData = filteredData2.sort((a, b) => a.userName.localeCompare(b.userName));
                setEmployeeReqData(sortedData);
            })
            .catch(() => {});
    }, [LCAT, SSAT]);

    const reqConfirm = () => {
        if (checkedReg.length === 0) {
            setAlertShow(false);
            return;
        }
        setCheckedReg([]);
        axios
            .post('/member/reqconfirmupdate', checkedReg)
            .then(() => {
                employeesReqSelect();
                setAlertShow(false);
            })
            .catch()
    };

    const typeStUpdate = (newValue) => {
        axios.post('/member/userTypeStupdate', {
            mbNum: selectedUser.mbNum,
            userType: newValue
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then(() => {
                setAlertShow2(false);
                setPendingUserTypeSt(null);
            })
            .catch((error) => {
                console.error("에러:", error);
            });
    };

    useEffect(() => {
        employeeSelect();
        employeesReqSelect();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [employeeSelect, employeesReqSelect]);

    /** endregion */

    return (
        <div id="m-a-employee-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">사원정보</p>
            </div>
            <div className="m-a-card display-flex flex-column m-b-15 font-t-cont">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-fend">
                        <div className="m-a-header-event-btn font-t-cont font-s-15">
                            <button className="m-a-colorBt m-a-colorBt-text wd-70 ht-40" onClick={conFirmAlert}>승인</button>
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb m-b-20">
                        <div className="m-a-div-tb-head ht-60">
                            <div className="wd-40">
                                <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checkedReg.length === employeeReqData.length} onChange={allCheckBoxRegChange}/>
                            </div>
                            <div className="wd-110"><p>이름</p></div>
                            <div className="wd-120"><p>닉네임</p></div>
                            <div className="wd-150"><p>계정</p></div>
                            <div className="wd-150"><p>연락처</p></div>
                            <div className="wd-120"><p>생년월일</p></div>
                            <div className="wd-180"><p>가입요청일시</p></div>
                        </div>
                        {employeeReqData.slice(indexOfFirst2, indexOfLast2).map((cData, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70">
                                <div className="wd-40">
                                    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" name={cData.mbNum} checked={checkedReg.includes(cData.mbNum)}
                                           onChange={() => checkBoxRegChange(cData.mbNum)}/>
                                </div>
                                <div className="font-b wd-110"><p>{cData.userName}</p></div>
                                <div className="wd-120"><p>{cData.nicName}</p></div>
                                <div className="wd-150"><p>{cData.id}</p></div>
                                <div className="wd-150"><p>{formatPhoneNumber(cData.phone)}</p></div>
                                <div className="wd-120"><p>{cData.byMd}</p></div>
                                <div className="wd-180"><p>{cData.regTime}</p></div>
                            </div>
                        ))}
                    </div>
                    <AdminPagination perPg={perPg2} totalData={employeeReqData.length} paginate={currentPage2} pageChange={pageChange2}/>
                </div>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event display-flex flex-row just-cont-sb">
                        <div className="m-a-combo font-t-cont font-c-default wd-55 ht-40 font-s-14 m-a-flex-jc-start" onClick={togglePopup}>
                            <div className="font-t-cont">{perPg}</div>
                        </div>
                        {popupVisible && (
                            <MAdminPageSmallEvent perPg={perPg} handleSelectChange={handleSelectChange} togglePopup={togglePopup} />
                        )}
                    </div>
                    <div className="m-a-header-event">
                        <div className="m-a-header-event-search">
                            <MAdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                    </div>
                </div>
                <div className="m-a-tb-frame">
                    <div className="m-a-div-tb">
                        <div className="m-a-div-tb-head ht-60">
                            {/*<div className="wd-40">*/}
                            {/*    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === userEmployeeData.length} onChange={allCheckBoxChange} />*/}
                            {/*</div>*/}
                            <div className="wd-40"><p>번호</p></div>
                            <div className="wd-110"><p>이름</p></div>
                            <div className="wd-110"><p>닉네임</p></div>
                            <div className="wd-180"><p>계정</p></div>
                            <div className="wd-100"><p>부서</p></div>
                            <div className="wd-120"><p>직책</p></div>
                            <div className="wd-80"><p>근무여부</p></div>
                            <div className="wd-130"><p>연락처</p></div>
                            <div className="wd-110"><p>생년월일</p></div>
                            <div className="wd-120"><p>가입일자</p></div>
                        </div>
                        {searchData.sort((a, b) => new Date(b.regDate) - new Date(a.regDate)).slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="m-a-div-tb-body ht-70" onClick={() => handleRowClick(cData)}>
                                {/*<div className="wd-40">*/}
                                {/*    <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" name={cData.mbNum} checked={checked.includes(cData.mbNum)} onChange={() => checkBoxChange(cData.mbNum)} />*/}
                                {/*</div>*/}
                                <div className="wd-40"><p>{i+1}</p></div>
                                <div className="font-b wd-110"><p>{cData.userName}</p></div>
                                <div className="wd-110"><p>{cData.nicName}</p></div>
                                <div className="wd-180"><p>{cData.id}</p></div>
                                <div className="wd-100"><p>비토리지</p></div>
                                <div className="wd-120">
                                    <select className="admin-combo wd-110 ht-25 m-0-a font-s-14" disabled={cData.joinVerFl === 'N' || cData.userType === "5"}
                                            style={{ backgroundColor: cData.joinVerFl === 'N' ? '#dedede' : 'none' }}
                                            value={cData.userType} onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        if (cData.userType !== "5" || selectedValue === "5") {
                                            typeStAlert(selectedValue);
                                        }
                                    }}>
                                        <option value="1">1: Staff</option>
                                        <option value="2">2: Snr Staff</option>
                                        <option value="3">3: Assistant</option>
                                        <option value="4">4: Manager</option>
                                        <option value="5" disabled={true}>5: Owner</option>
                                    </select>
                                </div>
                                <div className="wd-80"><p>{cData.userOutFl === 'N' ? '재직중' : '퇴사'}</p></div>
                                <div className="wd-130"><p>{formatPhoneNumber(cData.phone)}</p></div>
                                <div className="wd-110"><p>{cData.byMd}</p></div>
                                <div className="wd-120"><p>{formatDate(cData.regDate)}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= MAX_DISPLAY_LIMIT && searchData.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l-3 font-s-17" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel} eventHandle2={reqConfirm} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShow2 === true ? <MsgSelectModal alertShow={alertShow2} eventHandle1={modalCancel2} eventHandle2={() => typeStUpdate(pendingUserTypeSt)} msgData1={msgConfirmData3} msgData2={msgConfirmData4}/> : null}
        </div>
    );
}

export default MAdminEmployeeInfoPg
