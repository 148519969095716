import React, {useEffect, useState} from "react";
import axios from '../../../services/axiosInstance';
import commonClose from '../../../assets/img/commonClose.png'
import {useTranslation} from "react-i18next";

function IntegDetailPopup({integDetailPopupClose, integPaySeqnum}) {
    const { t } = useTranslation();
    const [prodPay, setProdPay] = useState([]);
    const [shipPay, setShipPay] = useState([]);
    const [etcPay, setEtcPay] = useState([]);

    const [useDepPrice, setUseDepPrice] = useState(0);
    const [usePointPrice, setUsePointPrice] = useState(0);
    const [useCpPrice, setUseCpPrice] = useState(0);
    const [appSale, setAppSale] = useState(0);
    const [integLastPrice, setIntegLastPrice] = useState([])

    const selectPayinfoDetail = () => axios({
        method: 'get',
        url: '/order/selectIntegPayHistory/',
        params: {
            paySeqnum: integPaySeqnum
        },
    })
        .then((response) => {
            let prodPayArr = [];
            let shipPayArr = [];
            let etcPayArr = [];

            response.data.forEach(item => {
                switch(item.payType) {
                    case "PT0":
                        prodPayArr.push(item);
                        break;
                    case "PT1":
                        shipPayArr.push(item);
                        break;
                    case "PT2":
                        etcPayArr.push(item);
                        break;
                    case "PT3":
                        setUseDepPrice(item.depUsePrice);
                        setUsePointPrice(item.pointUsePrice);
                        setUseCpPrice(item.couponUsePrice);
                        setAppSale(item.appSalePrice);
                        setIntegLastPrice(item.lastPrice);
                        break;
                }
            });

            setProdPay(prodPayArr);
            setShipPay(shipPayArr);
            setEtcPay(etcPayArr);
        })

    useEffect(() => {
        if (integPaySeqnum) {
            selectPayinfoDetail();
        }
    }, [integPaySeqnum])


    const [totalAllPrice, setTotalAllPrice] = useState(0);
    useEffect(() => {
        const calcTotalAllPrice = () => {
            let prodTotal = 0;
            let shipTotal = 0;
            let etcTotal = 0;

            // prodPay 금액 합산
            prodPay.forEach(item => {
                const ttEtcFee = item.ttEtcFee || 0;
                const ttProdExpense = item.ttProdExpense || 0;
                prodTotal += ttEtcFee + ttProdExpense;
            });

            // shipPay 금액 합산
            shipPay.forEach(item => {
                const prices = [
                    item.ttFedexPrice, item.ttFedexEcoPrice, item.ttDhlPrice, item.ttEmsPrice,
                    item.ttEmsPrePrice, item.ttUpsPrice, item.ttUpsEcoPrice, item.ttKPackPrice,
                    item.ttAirSPrice, item.ttSeaPrice, item.ttDomPrice
                ];
                const validPrice = prices.find(price => price > 0) || 0;
                shipTotal += validPrice;
            });

            // etcPay 금액 합산
            etcPay.forEach(item => {
                const courierPay = item.courierPay || 0;
                const returnFee = item.returnFee || 0;
                const deliveryFee = item.deliveryFee || 0;
                const repackFee = item.repackFee || 0;
                const agencyFee = item.agencyFee || 0;
                const comprFee = item.comprFee || 0;
                const etcPrice = item.etcPrice || 0;
                const etcFee = item.etcFee || 0;
                etcTotal += courierPay + returnFee + deliveryFee + repackFee + agencyFee + comprFee + etcPrice + etcFee;
            });

            const totalSum = prodTotal + shipTotal + etcTotal;
            setTotalAllPrice(totalSum);
        };

        calcTotalAllPrice();

    }, [prodPay, shipPay, etcPay]);






    return (
        <div className="bookContainer font-t-cont">
            <div className="price-pop-center">
                <div className="wd-100pc ht-45">
                    <div className="m-a-prod-price-detail-header-new ht-100pc display-flex align-center just-cont-sb">
                        <div className="font-t-title text-center" style={{flex: 1}}>{t(`idpHeader`)}</div> {/*a통합결제내역*/}
                        <img className="m-a-prod-price-detail-header-icon c-point" src={commonClose} alt={''} onClick={integDetailPopupClose}/>
                    </div>
                </div>
                <div className="wd-100pc ht-90pc border-bt-none pd25" style={{overflowY: "auto"}}>
                        {prodPay.length > 0 ?
                            <div className="m-a-tb-frame m-b-50">
                                <div className="m-a-div-tb">
                                    <div className="m-a-div-tb-head ht-40">
                                        <div className="wd-10pc"><p>{t(`num`)}</p> {/*번호*/}</div>
                                        <div className="wd-25pc"><p>{t(`prodPr`)}</p> {/*상품금액*/}</div>
                                        <div className="wd-25pc"><p>{t(`prodMallPr`)}</p> {/*쇼핑몰배송비*/}</div>
                                        <div className="wd-15pc"><p>{t(`prodFee`)}</p> {/*수수료*/}</div>
                                        <div className="wd-25pc"><p>{t(`ttPr`)}</p></div> {/*총 금액*/}
                                    </div>
                                    {prodPay.map((prod, i) => (
                                        <div key={i} className="m-a-div-tb-body ht-50">
                                            <div className="wd-10pc"><p>{i + 1}</p></div>
                                            <div className="wd-25pc"><p>{prod?.ttProdPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p></div>
                                            <div className="wd-25pc"><p>{prod?.ttMallShipPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p></div>
                                            <div className="wd-15pc"><p>{prod?.ttEtcFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p></div>
                                            <div className="wd-25pc"><p>{(prod.ttProdExpense + prod.ttEtcFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                        {shipPay.length > 0 ?
                            <div className="m-a-tb-frame m-b-50">
                                <div className="m-a-div-tb">
                                    <div className="m-a-div-tb-head" style={{padding:"10px 0"}}>
                                        <div className="wd-15pc"><p>{t(`num`)}</p></div> {/*번호*/}
                                        <div className="wd-35pc"><p>{t(`dv`)}</p></div> {/*배송사*/}
                                        <div className="wd-25pc"><p>{t(`appWt`)}</p></div> {/*적용무게*/}
                                        <div className="wd-25pc"><p>{t(`dvPr`)}</p></div> {/*배송료*/}
                                    </div>
                                    {shipPay.map((ship, i) => (
                                        <div key={i} className="m-a-div-tb-body ht-50">
                                            <div className="wd-15pc"><p>{i + 1}</p></div>
                                            <div className="wd-35pc">
                                                <p>
                                                    {ship.ttFedexPrice > 0 ? "FedEx" :
                                                        ship.ttFedexEcoPrice > 0 ? "FedEx-E" :
                                                        ship.ttUpsPrice > 0 ? "UPS" :
                                                        ship.ttUpsEcoPrice > 0 ? "UPS-E" :
                                                            ship.ttDhlPrice > 0 ? "DHL" :
                                                                ship.ttEmsPrice > 0 ? "EMS" :
                                                                ship.ttEmsPrePrice > 0 ? "EMSP" :
                                                                    ship.ttKPackPrice > 0 ? "K-Packet" :
                                                                        ship.ttAirSPrice > 0 ? "Small-Packet" :
                                                                            ship.ttSeaPrice > 0 ? "Sea Parcel" :
                                                                                ship.ttDomPrice > 0 ? "Domestic Delivery"
                                                                                    :
                                                                                    null}
                                                </p>
                                            </div>
                                            <div className="wd-25pc">
                                                <p>
                                                    {ship.ttFedexPrice > 0 ? ship.shipInfoDTO.fedexApyWeight :
                                                        ship.ttFedexEcoPrice > 0 ? ship.shipInfoDTO.fedexEcoApyWeight :
                                                        ship.ttDhlPrice > 0 ? ship.shipInfoDTO.dhlApyWeight :
                                                            ship.ttEmsPrice > 0 ? ship.shipInfoDTO.emsApyWeight :
                                                            ship.ttEmsPrePrice > 0 ? ship.shipInfoDTO.emsPreApyWeight :
                                                                ship.ttUpsPrice > 0 ? ship.shipInfoDTO.upsApyWeight :
                                                                ship.ttUpsEcoPrice > 0 ? ship.shipInfoDTO.upsApyEcoWeight :
                                                                    ship.ttKPackPrice > 0 ? ship.shipInfoDTO.kPackApyWeight :
                                                                        ship.ttAirSPrice > 0 ? ship.shipInfoDTO.airSApyWeight :
                                                                            ship.ttSeaPrice > 0 ? ship.shipInfoDTO.seaApyWeight :
                                                                                ship.ttDomPrice > 0 ? ship.shipInfoDTO.weight
                                                                                    :
                                                                                    0}kg
                                                </p>
                                            </div>
                                            <div className="wd-25pc user-center">
                                                <div className="font-s-12" style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                    {ship.ttFedexPrice > 0 ? ship.ttFedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                        ship.ttFedexEcoPrice > 0 ? ship.ttFedexEcoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                        ship.ttDhlPrice > 0 ? ship.ttDhlPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                            ship.ttEmsPrice > 0 ? ship.ttEmsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                            ship.ttEmsPrePrice > 0 ? ship.ttEmsPrePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                ship.ttUpsPrice > 0 ? ship.ttUpsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                ship.ttUpsEcoPrice > 0 ? ship.ttUpsEcoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                    ship.ttKPackPrice > 0 ? ship.ttKPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                        ship.ttAirSPrice > 0 ? ship.ttAirSPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                            ship.ttSeaPrice > 0 ? ship.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                                ship.ttDomPrice > 0 ? ship.ttDomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                                                                                    0}
                                                    {ship.payStatus === "PS5" ? <p style={{fontSize:"12px", color:"#EB939D", marginLeft:"3px"}}>(취소)</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                        {etcPay.length > 0 ?
                            <div className="m-a-tb-frame m-b-50">
                                <div className="m-a-div-tb">
                                    <div className="m-a-div-tb-head" style={{padding:"10px 0"}}>
                                        <div className="wd-40"><p>{t(`num`)}</p></div>
                                        <div className="wd-100"><p>{t(`epdHeader3`)}</p></div> {/*착불비*/}
                                        <div className="wd-100"><p>{t(`epdHeader4`)}</p></div> {/*인천반송비*/}
                                        <div className="wd-130"><p>{t(`epdHeader10`)}</p></div> {/*배송대행수수료*/}
                                        <div className="wd-100"><p>{t(`epdHeader6`)}</p></div> {/*재포장수수료*/}
                                        <div className="wd-100"><p>{t(`epdHeader8`)}</p></div> {/*대행수수료*/}
                                        <div className="wd-100"><p>{t(`epdHeader7`)}</p></div> {/*압축팩수수료*/}
                                        <div className="wd-100"><p>{t(`epdHeader5`)}</p></div> {/*기타비용*/}
                                        <div className="wd-100"><p>{t(`epdHeader9`)}</p></div> {/*기타수수료*/}
                                    </div>
                                    {etcPay.map((etc, i) => (
                                        <div key={i} className="m-a-div-tb-body ht-50">
                                            <div className="wd-40"><p>{i + 1}</p></div>
                                            <div className="wd-100">
                                                <p>{etc?.courierPay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-100">
                                                <p>{etc?.returnFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-130">
                                                <p>{etc?.deliveryFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-100">
                                                <p>{etc?.repackFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-100">
                                                <p>{etc?.agencyFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-100">
                                                <p>{etc?.comprFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-100">
                                                <p>{etc?.etcPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                            <div className="wd-100">
                                                <p>{etc?.etcFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                        {/*추후 통합결제 건의 lastPrice 맵핑*/}
                        <div>
                            <div style={{borderTop:"1px solid lightgray", borderBottom:"1px solid lightgray", borderWidth:"thin 1px", padding:"10px 0"}}>
                                <div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom:"15px"
                                    }}>
                                        <p style={{
                                            fontSize: "13px",
                                            height: "25px",
                                            fontWeight: "lighter"
                                        }}>{t('idpTtAmount')}</p>
                                        <p style={{fontSize: "13px", height: "25px", fontWeight: "lighter"}}>
                                            {totalAllPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <p style={{
                                            fontSize: "13px",
                                            height: "25px",
                                            fontWeight: "lighter"
                                        }}>{t('myUseDep')}</p>
                                        <p style={{fontSize: "13px", height: "25px", fontWeight: "lighter"}}>
                                            {useDepPrice > 0 ? <span>-</span> : null}
                                            {useDepPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <p style={{
                                            fontSize: "13px",
                                            height: "25px",
                                            fontWeight: "lighter"
                                        }}>{t('myUsePt')}</p>
                                        <p style={{fontSize: "13px", height: "25px", fontWeight: "lighter"}}>
                                            {usePointPrice > 0 ? <span>-</span> : null}
                                            {usePointPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <p style={{
                                            fontSize: "13px",
                                            height: "25px",
                                            fontWeight: "lighter"
                                        }}>{t('myUseCp')}</p>
                                        <p style={{fontSize: "13px", height: "25px", fontWeight: "lighter"}}>
                                            {useCpPrice > 0 ? <span>-</span> : null}
                                            {useCpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom:"15px"
                                    }}>
                                        <p style={{
                                            fontSize: "13px",
                                            height: "25px",
                                            fontWeight: "lighter"
                                        }}>{t('appSale')}</p> {/*앱할인*/}
                                        <p style={{fontSize: "13px", height: "25px", fontWeight: "lighter"}}>
                                            {appSale > 0 ? <span>-</span> : null}
                                            {appSale.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <p style={{fontSize: "15px"}}>{t(`idpFinalAmount`)}</p> {/*통합결제금액*/}
                                    </div>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <p style={{
                                            fontSize: "15px",
                                            color: integLastPrice > 0 ? "darkorange" : "dimgray"
                                        }}>
                                        &#8361; {integLastPrice > 0 ? integLastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default IntegDetailPopup
