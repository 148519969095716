import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from '../../services/axiosInstance';
import reviewUpload from "../../assets/img/reviewUpload.png";
import {BsTrash} from "react-icons/bs";
import back from "../../assets/img/commonBack.png";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import '../../css/mobile/MAdmin.css';
import '../../css/Common.css';

function MAdminNoticeModifyPg() {

    const navigate = useNavigate();
    const {seqNum} = useParams();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [contentErrorMsg, setContentErrorMsg] = useState(false);

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [contentText, setContentText] = useState('');
    const handleChangeContentText = (e) => {
        setContentText(e.target.value);
    };

    const [photoContainers, setPhotoContainers] = useState([{ photoImg: reviewUpload, photoImgSt: false, photoImgError: false, photoImgOriNm: '' }]);
    const [photoRemoveContainers, setPhotoRemoveContainers] = useState([{ removeImg: ''}]);
    const selectNoticeData = () =>
        axios({
            method: 'get',
            url: '/notice/selectNoticeData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setTitleText(response.data.title);
                setContentText(response.data.content);
                setFixCheck(response.data.fixFl);

                const noticePhotos = response.data.noticePhotoDTO;
                if (noticePhotos && noticePhotos.length > 0) {
                    setPhotoContainers(prev => {
                        // 기존 사진 데이터를 포함한 배열 생성
                        const updatedContainers = noticePhotos.map(photo => ({
                            photoImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/writePhoto/" + photo.fileNm,
                            photoImgSt: true,
                            photoImgError: false,
                            photoImgFileOriNm: photo.fileOriNm
                        }));

                        // 마지막에 빈 폼 추가
                        updatedContainers.push({ photoImg: reviewUpload, photoImgSt: false, photoImgError: false });

                        return updatedContainers;
                    });
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })

    useEffect(() => {
        if (seqNum) {
            selectNoticeData();
        }
    }, [seqNum])


    const photoImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setPhotoContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        photoImg: URL.createObjectURL(file),
                        photoImgSt: true,
                        photoImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const formData = new FormData();

    const photoImgInit = (idx, photoImg) => () => {
        setPhotoRemoveContainers(prev => [...prev, { removeImg: photoImg }]);
        setPhotoContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.photoImgSt) {
                newContainers.push({ photoImg: reviewUpload, photoImgSt: false, photoImgError: false });
            }

            return newContainers;
        });
        formData.delete('photoImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.photoImgSt) {
            return;
        }

        newContainers.push({ photoImg: reviewUpload, photoImgSt: false, photoImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (contentText === '' || !contentText) setContentErrorMsg(true);
        return !!(titleText && contentText)
    }

    useEffect(() => {
        if (titleText.length > 0) {
            setTitleErrorMsg(false);
        } if (contentText.length > 0) {
            setContentErrorMsg(false);
        }
    }, [titleText, contentText])

    const noticeModify = async () => {

        const newFormData = new FormData();

        newFormData.append('title', titleText);
        newFormData.append('contentText', contentText);

        photoContainers.forEach((container, i) => {
            if (container.file) {
                newFormData.append('photoImg', container.file);
            } else {
                newFormData.append('photoImg', container.photoImg);
            }
        });

        photoRemoveContainers.forEach((remove, i) => {
            if (remove.removeImg) {
                const filename = remove.removeImg.split('/').pop(); // URL에서 파일 이름만 추출
                newFormData.append('removeImg', encodeURIComponent(filename));
            }
        });

        return axios({
            method: 'post',
            url: '/notice/noticeModify',
            data: newFormData,
            params: {
                noticeSeqNum: seqNum,
                fixFl: fixCheck,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(() => {
                navigate(`/btcorpad/adminnotice-detail/${seqNum}`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            noticeModify();
        }
    }

    const [fixCheck, setFixCheck] = useState(false);
    const selectedFixCheckBox = (() => {
        setFixCheck(!fixCheck);
    })

    const pageBack = (() => {
        navigate(-1);
    })

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    return(
        <div id="m-a-notice-modi-pg-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">공지사항수정</div>
            </div>
            <div className="m-a-fixed-container">
                <div className="m-t-15 display-flex flex-column">
                    <div className="m-a-input-frame ht-40 m-b-15">
                        <input className="m-a-input wd-100pc font-t-title font-s-14" type="text" placeholder="제목" value={titleText} onChange={handleChangeTitleText}/>
                    </div>
                </div>
                <div className="display-flex flex-row just-cont-fend wd-100pc m-b-15 font-t-cont">
                    <p className="font-s-15 m-r-5">공지 상단고정</p>
                    <input className="m-a-admin-input-chkBox m-t-1" id="AgreeCheckBox" type="checkbox" checked={fixCheck} onChange={selectedFixCheckBox}/>
                </div>
                <div>
                    <div className="m-a-textarea-frame m-b-15 font-s-14 font-t-cont ht-150" style={{minHeight: "auto", maxHeight: "300px"}}>
                        <textarea className="m-a-textarea-b" placeholder="내용입력" spellCheck="false" value={contentText} onChange={handleChangeContentText}/>
                    </div>
                </div>
                <div>
                    <div>
                        {photoContainers.map((container, idx) => (
                            <div key={idx} className="display-flex just-cont-center align-center border-c ht-400">
                                {!container.photoImgSt ?
                                    <div className="display-block text-center" onClick={(e) => {
                                        const parentDiv = e.target.parentNode;
                                        const fileInput = parentDiv.querySelector('input[type="file"]');
                                        if (fileInput) fileInput.click();
                                    }}>
                                        <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle(idx)}/>
                                        <img className="wd-70 ht-70" alt="uploadImg" style={{filter: "opacity(0.3) drop-shadow(0 0 0 gray)",}} src={container.photoImg}/>
                                        <p className="font-s-18 font-c-default">Click here!</p>
                                    </div>
                                    :
                                    <div className="display-flex wd-90pc ht-90pc position-relative" style={{maxHeight: "400px"}}>
                                        <img className="display-flex just-cont-center align-center wd-100pc ht-100pc" alt="uploadImg" src={container.photoImg}/>
                                        <button className="orderItemHandleBt position-absolute wd-40 ht-40 m-a-opc-06" style={{top:"85%", left:"86%", borderRadius:"4px"}}
                                                onClick={photoImgInit(idx, container.photoImg)}>
                                            <i style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize:"26px"}}>
                                                <BsTrash className="display-flex just-cont-center align-center font-s-26"/>
                                            </i>
                                        </button>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-45" onClick={valiAndSave}>저장</button>
            </div>
        </div>
    )
}

export default MAdminNoticeModifyPg
