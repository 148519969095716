import React, {useEffect, useRef, useState} from "react";
import '../../css/web/AdminPopupSetPg.css';
import maCateImg from "../../assets/img/m-a-cate.png";
import {useNavigate, useParams} from "react-router-dom";
import axios from '../../services/axiosInstance';
import {BiCaretDown} from "react-icons/bi";
import CommonLoading from "../../components/web/modal/CommonLoading";
import photoUpload from "../../assets/img/reviewUpload.png";
import {BsTrash} from "react-icons/bs";
import {RxCross1} from "react-icons/rx";

function AdminPopupSetPg() {

    /** region DataSet */

    const { seqNum } = useParams();

    const LCAT = localStorage.getItem('adminToken');

    const [popupTitle, setPopupTitle] = useState('');
    const [photoContainer, setPhotoContainer] = useState({ photoImg: photoUpload, photoImgSt: false, photoImgError: false });
    const [isLoading, setIsLoading] = useState(false);

    /** endregion */

    /** region Function */

    const navigate = useNavigate();

    const [formError, setFormError] = useState(false);

    const langRef = useRef(null);
    const [langList, setLangList] = useState(["한국어", "English", "日本語", "中文語", "Indonesian"]);
    const [selectedLang, setSelectedLang] = useState('');
    const [langShow, setLangShow] = useState(false);

    const sizeRef = useRef(null);
    const [sizeList, setSizeList] = useState(["소형", "중형", "대형"]);
    const [selectedSize, setSelectedSize] = useState('');
    const [sizeShow, setSizeShow] = useState(false);

    const deviceRef = useRef(null);
    const [deviceList, setDeviceList] = useState(["PC", "모바일", "앱"]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [deviceShow, setDeviceShow] = useState(false);

    const useFlRef = useRef(null);
    const [useList, setUseList] = useState(["사용", "사용안함"]);
    const [selectedUse, setSelectedUse] = useState('');
    const [useShow, setUseShow] = useState(false);

    const imgTyRef = useRef(null);
    const [imgTyList, setImgTyList] = useState(["일반", "URL"]);
    const [selectedImgTy, setSelectedImgTy] = useState('');
    const [imgTyShow, setImgTyShow] = useState(false);

    const [selectedImages, setSelectedImages] = React.useState([]);
    const [popupUrl, setPopupUrl] = useState('');

    const toggleLangShow = () => {
        setLangShow(!langShow);
    };

    const handleLangChange = (mst) => {
        setSelectedLang(mst);
        setLangShow(false);
    };

    const toggleSizeShow = () => {
        if (selectedDevice !== "모바일" && selectedDevice !== "앱") {
            setSizeShow(!sizeShow);
        }
    };

    const toggleUseShow = () => {
        setUseShow(!useShow);
    };

    const toggleImgTyShow = () => {
        setImgTyShow(!imgTyShow);
    };

    const handleServiceChange = (mst) => {
        setSelectedSize(mst);
        setSizeShow(false);
    };

    const toggleDeviceShow = () => {
        setDeviceShow(!deviceShow);
    };

    const handleDeviceChange = (mst) => {
        setSelectedDevice(mst);
        setDeviceShow(false);
        if (mst === "모바일" || mst === "앱") {
            setSelectedSize("스와이프");
        } else {
            setSelectedSize('');
        }
    };

    const handleUseChange = (mst) => {
        setSelectedUse(mst);
        setUseShow(false);
    };

    const handleImgTyChange = (mst) => {
        setSelectedImgTy(mst);
        setImgTyShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setLangShow(false);
            }
            if (sizeRef.current && !sizeRef.current.contains(event.target)) {
                setSizeShow(false);
            }
            if (deviceRef.current && !deviceRef.current.contains(event.target)) {
                setDeviceShow(false);
            }
            if (useFlRef.current && !useFlRef.current.contains(event.target)) {
                setUseShow(false);
            }
            if (imgTyRef.current && !imgTyRef.current.contains(event.target)) {
                setImgTyShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const convertToLang = ((lang) => {
        if (lang === "0") return "한국어"
        if (lang === "1") return "English"
        if (lang === "2") return "日本語"
        if (lang === "3") return "中文語"
        if (lang === "4") return "Indonesian"
    })

    const photoImgHandle = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (!file.type.startsWith('image/')) {
                setPhotoContainer({
                    ...photoContainer,
                    photoImgError: true,
                });
            } else {
                setPhotoContainer({
                    ...photoContainer,
                    photoImg: URL.createObjectURL(file),
                    photoImgSt: true,
                    photoImgError: false,
                    file: file
                });
            }
        }
    };

    const photoImgInit = () => {
        setPhotoContainer({
            photoImg: photoUpload,
            photoImgSt: false,
            photoImgError: false
        });
    };

    useEffect(() => {
        if (selectedDevice === "모바일" || selectedDevice === "앱") {
            setSelectedSize("스와이프");
        }
    }, [selectedDevice]);

    /** endregion */

    /** region Event */

    useEffect(() => {
        if (seqNum) {
            axios.get(`/popup/getSelectPopupData?seqNum=${seqNum}`)
                .then(response => {
                    const data = response.data;
                    const size = data.ptype;
                    const langName = convertToLang(data.plang);
                    const device = data.pmachineType;
                    const useFl = data.useFl;

                    setPopupTitle(data.ptitle);
                    setSelectedLang(langName);
                    setSelectedDevice(device);
                    setSelectedUse(useFl);

                    let sizeTy = "";
                    switch (size) {
                        case "0":
                            sizeTy = "소형";
                            break;
                        case "1":
                            sizeTy = "중형";
                            break;
                        case "2":
                            sizeTy = "대형";
                            break;
                        default:
                            sizeTy = size;
                    }
                    setSelectedSize(sizeTy);
                    const popupPhoto = data.bannerPopPhotoDTOS[0];
                })
                .catch(error => {
                    console.error("수정data err:", error);
                });
        } else {
            setPopupTitle("");
            setSelectedSize("");
            setSelectedLang("");
            setSelectedDevice("");
            setSelectedUse("");
        }
    }, [seqNum]);

    const popupSave = () => {
        let hasError = false;

        if (!selectedSize || selectedSize === "팝업크기선택") {
            hasError = true;
        }

        if (!selectedLang || selectedLang === "언어선택") {
            hasError = true;
        }

        if (!selectedDevice || selectedDevice.trim() === '기기선택') {
            hasError = true;
        }

        if (!selectedUse || selectedUse.trim() === '사용구분') {
            hasError = true;
        }

        if (!selectedImgTy || selectedImgTy.trim() === '이미지타입') {
            hasError = true;
        }

        if (hasError) {
            setFormError(true);
            return;
        } else {
            setFormError(false);
        }

        if (isLoading) return;

        setIsLoading(true);

        const newFormData = new FormData();
        const size = selectedSize === "소형" ? "0" : selectedSize === "중형" ? "1" : selectedSize === "대형" ? "2" : "0";
        const lang = selectedLang === "한국어" ? 0 : selectedLang === "English" ? 1 : selectedLang === "日本語" ? 2 : selectedLang === "中文語" ? 3 : 4;
        const device = selectedDevice === "PC" ? 0 : selectedDevice === "모바일" ? 1 : 2;
        const useFl = selectedUse === "사용" ? 0 : 1;
        const img = selectedUse === "일반" ? 0 : 1;

        newFormData.append('popupTitle', popupTitle);
        newFormData.append('pSize', size);
        newFormData.append('pLang', lang);
        newFormData.append('mType', device);
        newFormData.append('useFl', useFl);
        newFormData.append('pImgType', img);
        newFormData.append('pUrl', popupUrl);
        newFormData.append('popupImg', photoContainer.file);

        return axios({
            method: 'post',
            url: '/popup/savePopup',
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then(async (response) => {
                navigate('/btcorpad/pageset');
                setIsLoading(false);
            });
    }

    const popupModifySave = () => {
        let hasError = false;

        if (!selectedSize || selectedSize === "팝업크기선택") {
            hasError = true;
        }

        if (!selectedLang || selectedLang === "언어선택") {
            hasError = true;
        }

        if (!selectedDevice || selectedDevice.trim() === '기기선택') {
            hasError = true;
        }

        if (!selectedUse || selectedUse.trim() === '사용구분') {
            hasError = true;
        }

        if (!selectedImgTy || selectedImgTy.trim() === '이미지타입') {
            hasError = true;
        }

        if (hasError) {
            setFormError(true);
            return;
        } else {
            setFormError(false);
        }

        if (isLoading) return;

        setIsLoading(true);

        const newFormData = new FormData();
        const size = selectedSize === "소형" ? "0" : selectedSize === "중형" ? "1" : "2";
        const lang = selectedLang === "한국어" ? 0 : selectedLang === "English" ? 1 : selectedLang === "Japanese" ? 2 : selectedLang === "Chinese" ? 3 : 4;
        const device = selectedDevice === "PC" ? 0 : selectedDevice === "모바일" ? 1 : 2;
        const useFl = selectedUse === "사용" ? 0 : 1;
        const img = selectedUse === "일반" ? "0" : "1";

        newFormData.append('popupTitle', popupTitle);
        newFormData.append('pSize', size);
        newFormData.append('pLang', lang);
        newFormData.append('mType', device);
        newFormData.append('useFl', useFl);
        newFormData.append('pImgType', img);
        newFormData.append('pUrl', popupUrl);
        selectedImages.forEach((file, index) => {
            newFormData.append('popupImg', file, file.name);
        });

        return axios({
            method: 'put',
            url: `/popup/savePopup/${seqNum}`,
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT
            },
        })
            .then((response) => {
                navigate('/btcorpad/pageset');
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Modify guide error:", error);
            });
    }

    /** endregion */

    return (
        <div id="AdminGuideSetPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">팝업창 관리</p>
            </div>
            <div className="admin-wrap font-t-cont">
                <div className="admin-wrap-card guide-container">
                    <div className="display-flex flex-row just-cont-sb m-b-20">
                        <div className="display-flex flex-row just-cont-sb">
                            <div className="wd-130 ht-40 m-r-10">
                                <div ref={deviceRef} className="ht-100pc wd-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleDeviceShow}>
                                        <p>{selectedDevice === "0" ? "PC" : selectedDevice === "1" ? "모바일" : selectedDevice === "2" ? "앱" : selectedDevice === "" ? "기기선택" : selectedDevice}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                        <i style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%"
                                                        }}><BiCaretDown/></i>
                                                    </span>
                                    </button>
                                    {deviceShow ?
                                        <div className="wd-100pc ht-100 m-t-5 position-absolute" style={{
                                            overflowY:"scroll",
                                            border: "solid 1px lightgray",
                                            top: "100%",
                                            bottom: "100%",
                                            zIndex: 1,
                                        }}>
                                            {deviceList.map((device, index) => (
                                                <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={device}
                                                     onClick={() => handleDeviceChange(device)} style={{padding: "10px"}}>
                                                    {device}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="wd-130 ht-40 m-r-10">
                                <div ref={sizeRef} className="ht-100pc wd-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleSizeShow}>
                                        <p>{selectedSize === "" ? "팝업크기선택" : selectedSize}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                            </span>
                                    </button>
                                    {sizeShow ?
                                        <div className="wd-100pc ht-100 m-t-5 position-absolute" style={{
                                            overflowY:"scroll",
                                            border: "solid 1px lightgray",
                                            top: "100%",
                                            bottom: "100%",
                                            zIndex: 1,
                                        }}>
                                            {sizeList.map((sizePop, index) => (
                                                <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={sizePop}
                                                     onClick={() => handleServiceChange(sizePop)} style={{padding: "10px"}}>
                                                    {sizePop}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="wd-130 ht-40 m-r-10">
                                <div ref={langRef} className="ht-100pc wd-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleLangShow}>
                                        <p>{selectedLang === "" ? "언어선택" : selectedLang}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                        <i style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%"
                                                        }}><BiCaretDown/></i>
                                                    </span>
                                    </button>
                                    {langShow ?
                                        <div className="wd-100pc ht-180 m-t-5 position-absolute" style={{
                                            overflowY:"scroll",
                                            border: "solid 1px lightgray",
                                            top: "100%",
                                            bottom: "100%",
                                            zIndex: 1,
                                        }}>
                                            {langList.map((lang, index) => (
                                                <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={lang}
                                                     onClick={() => handleLangChange(lang)} style={{padding: "10px"}}>
                                                    {lang}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="wd-130 ht-40 m-r-10">
                                <div ref={useFlRef} className="ht-100pc wd-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleUseShow}>
                                        <p>{selectedUse === "" ? "사용구분" : selectedUse}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                            </span>
                                    </button>
                                    {useShow ?
                                        <div className="wd-100pc ht-80 m-t-5 position-absolute" style={{
                                            overflowY:"scroll",
                                            border: "solid 1px lightgray",
                                            top: "100%",
                                            bottom: "100%",
                                            zIndex: 1,
                                        }}>
                                            {useList.map((usePop, index) => (
                                                <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={usePop}
                                                     onClick={() => handleUseChange(usePop)} style={{padding: "10px"}}>
                                                    {usePop}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="wd-130 ht-40 m-r-10">
                                <div ref={imgTyRef} className="ht-100pc wd-100pc position-relative">
                                    <button className="selectParentBt wd-100pc ht-100pc" onClick={toggleImgTyShow}>
                                        <p>{selectedImgTy === "" ? "이미지타입" : selectedImgTy}</p>
                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", zIndex:10, width: "20%"}}>
                                                <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                            </span>
                                    </button>
                                    {imgTyShow ?
                                        <div className="wd-100pc ht-80 m-t-5 position-absolute" style={{
                                            overflowY:"scroll",
                                            border: "solid 1px lightgray",
                                            top: "100%",
                                            bottom: "100%",
                                            zIndex: 1,
                                        }}>
                                            {imgTyList.map((imgPop, index) => (
                                                <div className="selectChildBt display-flex just-cont-center c-point font-s-14 align-center" key={index} value={imgPop}
                                                     onClick={() => handleImgTyChange(imgPop)} style={{padding: "10px"}}>
                                                    {imgPop}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <input className="pop-title-input font-t-cont m-r-10" type="text" id="popupTitle" placeholder="제목입력(관리자용)" maxLength="30"
                                   value={popupTitle}
                                   onChange={(e) => setPopupTitle(e.target.value)}
                            />
                            <input className="pop-url-input font-t-cont font-s-12" type="text" id="popupUrl" value={popupUrl}
                                   onChange={(e) => setPopupUrl(e.target.value)} placeholder="경로를 입력하세요"/>
                        </div>
                        <div className="display-flex flex-row just-cont-fend text-right">
                                {formError && <div className="font-c-cancel font-s-16 p-t-9 m-r-7">선택항목 및 입력 내용을 확인해주세요.</div>}
                                {seqNum ?
                                    <button className="admin-btn-A font-s-14 btn-click-event ht-40 wd-120" type="submit" onClick={popupModifySave}>저장</button>
                                    :
                                    <button className="admin-btn-A font-s-14 btn-click-event ht-40 wd-120" type="submit" onClick={popupSave}>등록</button>
                                }
                            </div>
                    </div>
                    <div className="display-flex justify-content-center p-t-20 p-b-20">
                        {(selectedDevice === "PC" && selectedSize === "소형") && (
                            <div className="display-flex flex-column popup-frame-s">
                                <div className="ht-86pc position-relative">
                                    <div className="position-absolute text-right c-point font-s-26" style={{zIndex: 100, top: "3px", right: "10px", color: "#dcdcdc"}}>
                                        <RxCross1 />
                                    </div>
                                    <div className="wd-100pc ht-100pc">
                                        {!photoContainer.photoImgSt ? (
                                            <div className="display-block text-center align-center-c c-point ht-100pc" onClick={(e) => {
                                                const parentDiv = e.target.parentNode;
                                                const fileInput = parentDiv.querySelector('input[type="file"]');
                                                if (fileInput) fileInput.click();
                                            }}>
                                                <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                <img alt="uploadImg" style={{width: "80px", height: "80px", filter: "opacity(0.2) drop-shadow(0 0 0 gray)"}} src={photoContainer.photoImg}/>
                                                <p className="font-s-18" style={{color: "rgba(112,112,109,0.67)"}}>Click me!</p>
                                            </div>
                                        ) : (
                                            <img src={photoContainer.photoImg} alt="Uploaded Image" className="wd-100pc ht-100pc" style={{objectFit: "cover"}} />
                                        )}
                                    </div>
                                </div>
                                <div className="ht-auto display-flex flex-row just-cont-sb gap10 c-def p-t-10 p-r-10 p-b-10 p-l-10 font-s-15"  style={{borderTop: "solid 1px #EAEAEA"}}>
                                    <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>오늘 하루 보지 않기</div>
                                    <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>더 이상 보지 않기</div>
                                </div>
                            </div>
                        )}
                        {(selectedDevice === "PC" && selectedSize === "중형") && (
                            <div className="display-flex flex-column popup-frame-m">
                                <div className="ht-90pc position-relative">
                                    <div className="position-absolute text-right c-point font-s-26" style={{zIndex: 100, top: "3px", right: "10px", color: "#dcdcdc"}}>
                                        <RxCross1 />
                                    </div>
                                    <div className="wd-100pc ht-100pc">
                                        {!photoContainer.photoImgSt ? (
                                            <div className="display-block text-center align-center-c c-point ht-100pc" onClick={(e) => {
                                                const parentDiv = e.target.parentNode;
                                                const fileInput = parentDiv.querySelector('input[type="file"]');
                                                if (fileInput) fileInput.click();
                                            }}>
                                                <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                <img alt="uploadImg" style={{width: "80px", height: "80px", filter: "opacity(0.2) drop-shadow(0 0 0 gray)"}} src={photoContainer.photoImg}/>
                                                <p className="font-s-18" style={{color: "rgba(112,112,109,0.67)"}}>Click me!</p>
                                            </div>
                                        ) : (
                                            <img src={photoContainer.photoImg} alt="Uploaded Image" className="wd-100pc ht-100pc" style={{objectFit: "cover"}} />
                                        )}
                                    </div>
                                </div>
                                <div className="ht-auto display-flex flex-row just-cont-sb gap10 c-def p-t-10 p-r-10 p-b-10 p-l-10 font-s-15"  style={{borderTop: "solid 1px #EAEAEA"}}>
                                    <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>오늘 하루 보지 않기</div>
                                    <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>더 이상 보지 않기</div>
                                </div>
                            </div>
                        )}
                        {(selectedDevice === "PC" && selectedSize === "대형") && (
                            <div className="display-flex flex-column popup-frame-l">
                                <div className="ht-92pc position-relative">
                                    <div className="position-absolute text-right c-point font-s-26" style={{zIndex: 100, top: "3px", right: "10px", color: "#f6f6f6"}}>
                                        <RxCross1 />
                                    </div>
                                    <div className="wd-100pc ht-100pc">
                                        {!photoContainer.photoImgSt ? (
                                            <div className="display-block text-center align-center-c c-point ht-100pc" onClick={(e) => {
                                                const parentDiv = e.target.parentNode;
                                                const fileInput = parentDiv.querySelector('input[type="file"]');
                                                if (fileInput) fileInput.click();
                                            }}>
                                                <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                <img alt="uploadImg" style={{width: "80px", height: "80px", filter: "opacity(0.2) drop-shadow(0 0 0 gray)"}} src={photoContainer.photoImg}/>
                                                <p className="font-s-18" style={{color: "rgba(112,112,109,0.67)"}}>Click me!</p>
                                            </div>
                                        ) : (
                                            <img src={photoContainer.photoImg} alt="Uploaded Image" className="wd-100pc ht-100pc" style={{objectFit: "cover"}} />
                                        )}
                                    </div>
                                </div>
                                <div className="ht-auto display-flex flex-row just-cont-sb gap10 c-def p-t-10 p-r-10 p-b-10 p-l-10 font-s-15"  style={{borderTop: "solid 1px #EAEAEA"}}>
                                    <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>오늘 하루 보지 않기</div>
                                    <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>더 이상 보지 않기</div>
                                </div>
                            </div>
                        )}
                        {(selectedDevice === "모바일" || selectedDevice === "앱") && (
                            <div className="display-flex flex-column popup-frame-mobile just-cont-center" style={{ height: "700px"}}>
                                <div className="display-flex flex-column p-f-m-popup-s m-0-a">
                                    <div className="ht-92pc position-relative">
                                        <div className="position-absolute text-right c-point font-s-22" style={{zIndex: 100, top: "3px", right: "10px", color: "#dcdcdc"}}>
                                            <RxCross1 />
                                        </div>
                                        <div className="wd-100pc ht-100pc">
                                            {!photoContainer.photoImgSt ? (
                                                <div className="display-block text-center align-center-c c-point ht-100pc" onClick={(e) => {
                                                    const parentDiv = e.target.parentNode;
                                                    const fileInput = parentDiv.querySelector('input[type="file"]');
                                                    if (fileInput) fileInput.click();
                                                }}>
                                                    <input type="file" accept="image/*" style={{display: "none"}} onChange={photoImgHandle}/>
                                                    <img alt="uploadImg" style={{width: "80px", height: "80px", filter: "opacity(0.2) drop-shadow(0 0 0 gray)"}} src={photoContainer.photoImg}/>
                                                    <p className="font-s-18" style={{color: "rgba(112,112,109,0.67)"}}>Click me!</p>
                                                </div>
                                            ) : (
                                                <img src={photoContainer.photoImg} alt="Uploaded Image" className="wd-100pc ht-100pc" style={{objectFit: "cover"}} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="ht-auto display-flex flex-row just-cont-sb c-def font-s-13"  style={{borderTop: "solid 1px #EAEAEA"}}>
                                        <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>오늘 하루 보지 않기</div>
                                        <div className="wd-50pc ht-40 text-center align-center-c" style={{border: "solid 1px #EAEAEA"}}>더 이상 보지 않기</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(selectedDevice !== "" && selectedSize !== "") && (
                            <div className="position-relative m-l-15">
                                <button className="orderItemHandleBt position-absolute wd-40 ht-40 border-rd4 m-a-opc-07" style={{top: "85%"}} onClick={photoImgInit}>
                                    <i className="display-flex justify-content-center align-center font-s-26"><BsTrash/></i>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isLoading && <CommonLoading />}
        </div>
    );
}
export default AdminPopupSetPg;
