import React, {useEffect, useRef, useState} from "react";
import '../../css/web/UserMyPg.css';
import mypgOrder from "../../assets/img/mypgOrder.png";
import mypgDelivery from "../../assets/img/mypgDelivery.png";
import mypgReturn from "../../assets/img/mypgReturn.png";
import mypgReturnIcon from "../../assets/img/mypgReturnIcon.png";
import mypgOrderdt from "../../assets/img/mypgOrderdt.png";
import mypgPayinfo from "../../assets/img/mypgPayinfo.png";
import mypgWarn from "../../assets/img/mypgWarn.png"
import mypgPayCardOrder from "../../assets/img/mypgPayCardOrder.png"
import mypgPayCardDelivery from "../../assets/img/mypgPayCardDelivery.png"
import commonClose from "../../assets/img/commonClose.png"

import noData from "../../assets/img/noData.png";
import {AiOutlineDoubleRight} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {BiChevronDown} from "react-icons/bi";
import axios from '../../services/axiosInstance';
import DeliveryPriceDetail from "../../components/web/modal/DeliveryPriceDetail";
import excel from "exceljs";
import withAuth from '../../auth/UserRouteAuth'
import CommonLoading from "../../components/web/modal/CommonLoading";
import IntegDetailPopup from "../../components/web/modal/IntegDetailPopup";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import {useTranslation} from "react-i18next";
import commomHeader from "../../assets/img/commonHeader.png";
import {useWebSocket} from "../../services/WebsocketContext";
import TopBtn from "../../components/web/layoutsadmin/TopBtn";
import i18n from "../../i18n";

function UserMyPg() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // 주문서 기간셀렉트
    const [periodSelectShow, setPeriodSelectShow] = useState(false);
    const [periodSelectCommonValue, setPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const periodButtonRef = useRef(null);
    const periodDivRef = useRef(null);

    const periodBtHandle = (newValue) => {
        setPeriodSelectShow(false);
        setPeriodSelectCommonValue(newValue);
    };
    const periodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const periodClickOutside = (event) => {
            if (
                periodButtonRef.current &&
                periodButtonRef.current.contains(event.target)
            ) {
                setPeriodSelectShow(true);
            } else if (
                periodDivRef.current &&
                !periodDivRef.current.contains(event.target)
            ) {
                setPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', periodClickOutside);
        return () => {
            document.removeEventListener('mousedown', periodClickOutside);
        };
    }, []);

    // 주문서 유형셀렉트
    const [orderTypeSelectShow, setOrderTypeSelectShow] = useState(false);
    const [orderTypeSelectCommonValue, setOrderTypeSelectCommonValue] = useState(t(`orderType`));
    const orderTypeButtonRef = useRef(null);
    const orderTypeDivRef = useRef(null);

    const orderTypeBtHandle = (newValue) => {
        setOrderTypeSelectShow(false);
        setOrderTypeSelectCommonValue(newValue);
    };
    const orderTypeDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const orderTypeClickOutside = (event) => {
            if (
                orderTypeButtonRef.current &&
                orderTypeButtonRef.current.contains(event.target)
            ) {
                setOrderTypeSelectShow(true);
            } else if (
                orderTypeDivRef.current &&
                !orderTypeDivRef.current.contains(event.target)
            ) {
                setOrderTypeSelectShow(false);
            }
        };
        document.addEventListener('mousedown', orderTypeClickOutside);
        return () => {
            document.removeEventListener('mousedown', orderTypeClickOutside);
        };
    }, []);

    // 주문서 상태셀렉트
    const [orderStSelectShow, setOrderStSelectShow] = useState(false);
    const [orderStSelectCommonValue, setOrderStSelectCommonValue] = useState(t(`state`));
    const orderStButtonRef = useRef(null);
    const orderStDivRef = useRef(null);

    const myPgContentRef = useRef(null);
    const scrollToMyPgContent = () => {
        if (myPgContentRef.current) {
            myPgContentRef?.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    const orderStBtHandle = (newValue) => {
        setOrderStSelectShow(false);
        setOrderStSelectCommonValue(newValue);
        filterOrderSt(newValue);
        scrollToMyPgContent();
    };
    const orderStDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const orderStClickOutside = (event) => {
            if (
                orderStButtonRef.current &&
                orderStButtonRef.current.contains(event.target)
            ) {
                setOrderStSelectShow(true);
            } else if (
                orderStDivRef.current &&
                !orderStDivRef.current.contains(event.target)
            ) {
                setOrderStSelectShow(false);
            }
        };
        document.addEventListener('mousedown', orderStClickOutside);
        return () => {
            document.removeEventListener('mousedown', orderStClickOutside);
        };
    }, []);

    const [memberInfo, setMemberInfo] = useState("");

    const selectMember = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberInfo(response.data[0])
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT || SSAT) {
            selectMember();
        }
    }, [LCAT, SSAT])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/user/${memberInfo.mbNum}/queue/adminOrderStUpdate`, receivedOrderStChange);
                subscribe(`/user/${memberInfo.mbNum}/queue/adminOrderDivideMerge`, receivedOrderDivideMerge);
                subscribe(`/user/${memberInfo.mbNum}/queue/adminUpdateOrder`, updateOrderList);
                isSubscribedRef.current = true;
            }
        }
        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminOrderStUpdate`);
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminOrderDivideMerge`);
                unsubscribe(`/user/${memberInfo.mbNum}/queue/adminUpdateOrder`);
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);

    const [orderdtList, setOrderdtList] = useState([{}]);
    const [productsList, setProductsList] = useState([]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);

    const selectTotalOrderList = ((date) => {
        setIsLoading(true);

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(date)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(date)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(date)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(date)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(date)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/selectTotalOrderList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate,
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                // sortedData = sortedData.slice(0, 10);
                setOrderdtList(response.data);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

            })
            .catch(() => {
            })
            .finally(() => {
                setIsLoading(false);
            })
    })

    useEffect(() => {
        selectTotalOrderList(periodSelectCommonValue);
    }, [periodSelectCommonValue]);

    const myOrdPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    const [paySeqnum, setPaySeqnum] = useState(null);
    const [payShipco, setPayShipco] = useState('');
    const [deliveryDetailSt, setDeliveryDetailSt] = useState(false);
    const deliveryDetailFalseFn = (() => {
        setDeliveryDetailSt(false);
    })
    const deliveryDetailTrueFn = ((seqNum, shipco) => {
        setPaySeqnum(seqNum);
        setPayShipco(shipco);
        setDeliveryDetailSt(true);
    })

    const updateAppRecinfo = (updatedPayinfo) => {
        setFilteredList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) => {
                if (payinfo.appRecDTO && payinfo.appRecDTO.seqnum === updatedPayinfo.seqnum) {
                    return {...payinfo, appRecDTO: updatedPayinfo};
                }
                return payinfo;
            });
        });
    };

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };

    const receivedOrderStChange = ((payload) => {
        let data = JSON.parse(payload.body);
        setFilteredList(prevList => {
            return prevList.map(item => {
                // 조건에 맞는 항목 찾기
                if (item.type === 'orderdt' && item.seqnum === data.orderSeqNum) {
                    // 해당 항목의 orderStatus를 data.orderSt로 업데이트
                    return {
                        ...item,
                        orderStatus: data.orderSt
                    };
                }
                // 다른 항목들은 그대로 유지
                return item;
            });
        });
    });

    const receivedOrderDivideMerge = ((payload) => {
        let data = JSON.parse(payload.body);
        selectTotalOrderList(periodSelectCommonValue);
        selectedAllProcessCount(memberInfo.mbNum)
    })

    const updateOrderList = ((payload) => {
        let data = JSON.parse(payload.body);
        selectTotalOrderList(periodSelectCommonValue);
        selectedAllProcessCount(memberInfo.mbNum)
    })

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [orderListDisplayLimit, setOrderListDisplayLimit] = useState(20);
    const handleMoreOrderList = () => {
        setOrderListDisplayLimit(prev => prev + 20);
    }

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SEA"
        } else if (dvType === "D04") {
            return "DHL"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else if (dvType === "D15") {
            return "EMSP"
        } else if (dvType === "D16") {
            return "FedEx-E"
        } else if (dvType === "D17") {
            return "UPS-E"
        } else {
            return defalutShipco;
        }
    })

    useEffect(() => {
        const combined = [
            ...orderdtList.map((item) => ({
                type: 'orderdt',
                date: item.modiTime || item.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
            ...payinfoList.map((item) => ({
                type: 'payinfo',
                date: item?.modiTime || item?.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
        ];

        combined.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
        // combined.sort((a, b) => moment(b.date).isAfter(moment(a.date)) ? 1 : -1);

        const filterStatus = (order) => {
            if (orderStSelectCommonValue === t(`state`)) return true;
            switch (orderStSelectCommonValue) {
                case "PS0":
                    return (order.type === 'orderdt' && order.orderStatus === "OS09") ||
                        (order.type === 'payinfo' && order.payStatus === "PS0");
                case "PS1":
                    return (order.type === 'orderdt' && order.orderStatus === "OS10") ||
                        (order.type === 'payinfo' && order.payStatus === "PS1");
                case "PS2":
                    return (order.type === 'orderdt' && order.orderStatus === "OS11") ||
                        (order.type === 'payinfo' && order.payStatus === "PS2");
                case "PS3":
                    return (order.type === 'orderdt' && order.orderStatus === "OS12") ||
                        (order.type === 'payinfo' && order.payStatus === "PS3");
                case "PS4":
                    return (order.type === 'orderdt' && order.orderStatus === "OS13") ||
                        (order.type === 'payinfo' && order.payStatus === "PS4");
                case "PS5":
                    return (order.type === 'orderdt' && order.orderStatus === "OS14") ||
                        (order.type === 'payinfo' && order.payStatus === "PS5");
                default:
                    return (order.type === 'orderdt' && order.orderStatus === orderStSelectCommonValue) ||
                        (order.type === 'payinfo' && order.payStatus === orderStSelectCommonValue);
            }
        }

        const filterOrderType = (order) => {
            if (orderTypeSelectCommonValue === t('orderType')) {
                return true;  // 모든데이터
            }
            if (orderTypeSelectCommonValue === t('myTab1')) {
                return order.type === 'orderdt';
            }
            if (orderTypeSelectCommonValue === t('deliveryOption')) {
                return order.type === 'payinfo';
            }
            return true;
        };

        const newList = combined.filter((order) => {
            if (!filterStatus(order)) return false;
            if (!filterOrderType(order)) return false;
            if (searchQuery === "") return true;

            if (order.type === 'orderdt') {
                return (
                    (order.shipco && order.shipco.toLowerCase().includes(searchQuery)) ||
                    (order.recinfoDTO.recname && order.recinfoDTO.recname.toLowerCase().includes(searchQuery)) ||
                    (order.recinfoDTO.countryDTO && order.recinfoDTO.countryDTO.countryEngNm.toLowerCase().includes(searchQuery)) ||
                    (order.productsDTO && order.productsDTO.some((prod) => prod.name && prod.name.toLowerCase().includes(searchQuery))) ||
                    (order.orderNumber && order.orderNumber.toLowerCase().includes(searchQuery))
                );
            }
            if (order.type === 'payinfo') {
                return (
                    (order && convertToShipco(order.dvType, order.orderdtDTO?.shipco).toLowerCase().includes(searchQuery)) ||
                    (order.appRecDTO && order.appRecDTO.countryDTO && order.appRecDTO.countryDTO.countryEngNm.toLowerCase().includes(searchQuery)) ||
                    (order.appRecDTO && order.appRecDTO.recname && order.appRecDTO.recname.toLowerCase().includes(searchQuery)) ||
                    (order.packListDTO && order.packListDTO.productsDTO && order.packListDTO.productsDTO.some((prod) => prod.name && prod.name.toLowerCase().includes(searchQuery))) ||
                    (order.orderdtDTO?.orderNumber && order.orderdtDTO?.orderNumber.toLowerCase().includes(searchQuery))
                );
            }
        });
        setFilteredList(newList);
    }, [orderdtList, payinfoList, searchQuery, orderStSelectCommonValue, orderTypeSelectCommonValue]);

    useEffect(() => {
        if (memberInfo.mbNum)
            selectedAllProcessCount(memberInfo.mbNum)
    }, [memberInfo])

    const [processData, setProcessData] = useState([]);
    const selectedAllProcessCount = ((mbNum) => {
        axios({
            method: 'get',
            url: '/order/selectedAllProcessCount',
            params: {
                mbNum: mbNum
            }
        })
            .then((response) => {
                setProcessData(response.data);
            })
            .catch((error) => {
                console.log("프로세스수량 조회실패 : ", error)
            })
    })

    const selectNoDateTotalOrderList = ((orderSt) => {
        axios({
            method: 'get',
            url: '/order/selectTotalOrderList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                // sortedData = sortedData.slice(0, 10);
                setOrderdtList(response.data);
                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                orderStBtHandle(orderSt);
                setPeriodSelectCommonValue(t('all'))

            })
            .catch((error) => {
                console.log("노데이트 주문조회실패 : ", error)
            })
    })

    const [payPeriodSelectShow, setPayPeriodSelectShow] = useState(false);
    const [payPeriodSelectCommonValue, setPayPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const payPeriodButtonRef = useRef(null);
    const payPeriodDivRef = useRef(null);

    const payPeriodBtHandle = (newValue) => {
        setPayPeriodSelectShow(false);
        setPayPeriodSelectCommonValue(newValue);
    };
    const payPeriodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const payPeriodClickOutside = (event) => {
            if (
                payPeriodButtonRef.current &&
                payPeriodButtonRef.current.contains(event.target)
            ) {
                // Clicked on the duration button
                setPayPeriodSelectShow(true);
            } else if (
                payPeriodDivRef.current &&
                !payPeriodDivRef.current.contains(event.target)
            ) {
                // Clicked outside the div
                setPayPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', payPeriodClickOutside);
        return () => {
            document.removeEventListener('mousedown', payPeriodClickOutside);
        };
    }, []);

    const filterOrderSt = (status) => {

        let viewStatus;
        switch (status) {
            case t(`state`):
                viewStatus = t(`state`);
                break;
            case "OS00":
                viewStatus = t(`prodStLabel1`);
                break;
            case "OS01":
                viewStatus = t(`prodStLabel2`);
                break;
            case "OS02":
                viewStatus = t(`prodStLabel3`);
                break;
            case "OS03":
                viewStatus = t(`prodStLabel4`);
                break;
            case "OS04":
                viewStatus = t(`prodStLabel14`);
                break;
            case "OS05":
                viewStatus = t(`prodStLabel5`);
                break;
            case "OS06":
                viewStatus = t(`prodStLabel15`);
                break;
            case "OS07":
                viewStatus = t(`prodStLabel6`);
                break;
            case "OS08":
                viewStatus = t(`prodStLabel7`);
                break;
            case "PS0":
                viewStatus = t(`prodStLabel8`);
                break;
            case "PS1":
                viewStatus = t(`prodStLabel9`);
                break;
            case "PS2":
                viewStatus = t(`prodStLabel10`);
                break;
            case "PS3":
                viewStatus = t(`prodStLabel11`);
                break;
            case "PS4":
                viewStatus = t(`prodStLabel12`);
                break;
            case "PS5":
                viewStatus = t(`prodStLabel13`);
                break;
        }
        return viewStatus;
    };

    const [payList, setPayList] = useState([]);
    const payHistoryList = ((dateParam) => {

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/payHistoryList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate,
            },
        })
            .then((response) => {
                // payType이 통합결제이거나 자식이 아닌 단독결제건(상품금액/해외배송료/기타비용)만 출력
                const filteredData = response.data.filter(data => data.payType === 'PT3' || !data.childFl);
                setPayList(filteredData);
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const sortedPayList = payList.sort((a, b) => b.seqNum - a.seqNum);
    const [payListDisplayLimit, setPayListDisplayLimit] = useState(20);
    const handleMorePayList = () => {
        setPayListDisplayLimit(prev => prev + 20);
    }

    const [payStSelectShow, setPayStSelectShow] = useState(false);
    const [payStSelectCommonValue, setPayStSelectCommonValue] = useState(t(`state`));
    const payStButtonRef = useRef(null);
    const payStDivRef = useRef(null);

    const payStBtHandle = (newValue) => {
        setPayStSelectShow(false);
        setPayStSelectCommonValue(newValue);
        filterPaySt(newValue);
    };

    useEffect(() => {
        payHistoryList(payPeriodSelectCommonValue);
    }, [payPeriodSelectCommonValue]);

    const payStDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const payStClickOutside = (event) => {
            if (
                payStButtonRef.current &&
                payStButtonRef.current.contains(event.target)
            ) {
                setPayStSelectShow(true);
            } else if (
                payStDivRef.current &&
                !payStDivRef.current.contains(event.target)
            ) {
                setPayStSelectShow(false);
            }
        };
        document.addEventListener('mousedown', payStClickOutside);
        return () => {
            document.removeEventListener('mousedown', payStClickOutside);
        };
    }, []);

    const filterStatus = (pay) => {
        if (payStSelectCommonValue === t(`state`)) return true;
        return pay.payStatus === payStSelectCommonValue;
    }

    const filterPaySt = (status) => {
        let viewStatus;
        switch (status) {
            case t(`state`):
                viewStatus = t(`state`)
                break;
            case "PS0":
                viewStatus = t(`prodStLabel8`)
                break;
            case "PS1":
                viewStatus = t(`prodStLabel9`)
                break;
            case "PS2":
                viewStatus = t(`prodStLabel10`)
                break;
        }
        return viewStatus;
    };

    const [dacPeriodSelectShow, setDacPeriodSelectShow] = useState(false);
    const [dacPeriodSelectCommonValue, setDacPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const dacPeriodButtonRef = useRef(null);
    const dacPeriodDivRef = useRef(null);

    const dacPeriodBtHandle = (newValue) => {
        setDacPeriodSelectShow(false);
        setDacPeriodSelectCommonValue(newValue);
        dacHistoryList(newValue);
    };
    const dacPeriodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const dacPeriodClickOutside = (event) => {
            if (
                dacPeriodButtonRef.current &&
                dacPeriodButtonRef.current.contains(event.target)
            ) {
                // Clicked on the duration button
                setDacPeriodSelectShow(true);
            } else if (
                dacPeriodDivRef.current &&
                !dacPeriodDivRef.current.contains(event.target)
            ) {
                // Clicked outside the div
                setDacPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', dacPeriodClickOutside);
        return () => {
            document.removeEventListener('mousedown', dacPeriodClickOutside);
        };
    }, []);

    const [dacList, setDacList] = useState([]);
    const dacHistoryList = ((dateParam) => {

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/dacHistoryList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate
            },
        })
            .then((response) => {
                setDacList(response.data)
                // console.log("예적쿠 리스트 : ", response.data)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        dacHistoryList(dacPeriodSelectCommonValue);
    }, [dacPeriodSelectCommonValue]);

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }
        return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`;
    };

    const sortedDacList = dacList.sort((a, b) => b.seqNum - a.seqNum);
    const initialLimit = 10;

    const [dacDisplayLimits, setDacDisplayLimits] = useState({
        deposit: initialLimit,
        point: initialLimit,
        coupon: initialLimit,
    });

    const handleMoreDacList = (type) => {
        setDacDisplayLimits(prevLimits => ({
            ...prevLimits,
            [type]: prevLimits[type] + 10
        }));
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const handleCheckboxChange = (orderId) => {
        setSelectedRows(prevRows => {
            if (prevRows.includes(orderId)) {
                return prevRows.filter(row => row !== orderId);
            } else {
                return [...prevRows, orderId];
            }
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(
                filteredList.map(order => {
                    if (order.type === 'orderdt') {
                        return order.seqnum;
                    } else if (order.type === 'payinfo' && (order.payType === "PT1")) {
                        return order.seqNum;
                    }
                    return null;
                }).filter(seqNum => seqNum !== null)
            );
        }
        setSelectAll(!selectAll);
    };

    const [downNoSelectSt, setDownNoSelectSt] = useState(false);
    const closeDownNoSelect = (() => {
        setDownNoSelectSt(false);
    })

    const downloadInvoice = async () => {
        if (selectedRows.length === 0) {
            setDownNoSelectSt(true);
            return;
        }

        const workbook = new excel.Workbook();

        const combinedRows = [];
        const headers = [
            {header: 'OrderNumber', key: 'seqnum', width: 30, style: {alignment: {horizontal: 'center'}}},
            {header: 'Date', key: 'date', width: 17, style: {alignment: {horizontal: 'center'}}},
            {header: 'Product Name', key: 'productName', width: 43, style: {alignment: {horizontal: 'center'}}},
            {header: 'Price', key: 'price', width: 17, style: {alignment: {horizontal: 'center'}}}
        ];

        // filteredList.forEach(order => {
        //     if (order.type === 'orderdt' && selectedRows.includes(order.seqnum)) {
        //         if (order.productsDTO) {
        //             order.productsDTO.forEach(product => {
        //                 combinedRows.push({
        //                     seqnum: order.orderNumber,
        //                     date: order.regDate,
        //                     productName: product.name,
        //                     price: product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //                 });
        //             });
        //         } else {
        //             console.error(`err_o ${order.orderNumber}`);
        //         }
        //     } else if (order.type === 'payinfo' && selectedRows.includes(order.seqNum)) {
        //         if (order.packListDTO && order.packListDTO.productsDTO) {
        //             order.packListDTO.productsDTO.forEach(product => {
        //                 combinedRows.push({
        //                     seqnum: order.orderdtDTO.orderNumber,
        //                     date: order.regDate,
        //                     productName: product.name,
        //                     price: product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //                 });
        //             });
        //         } else {
        //             // console.error(`err_p ${order.orderdtDTO.orderNumber}`);
        //         }
        //     }
        // });

        const worksheet = workbook.addWorksheet("Orders and Payinfo");
        worksheet.columns = headers;

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = {bold: true};
        });

        worksheet.addRows(combinedRows);

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Btorage_OrderList.xlsx';
        link.click();
    };

    const [guidePopupOrderSt, setGuidePopupOrderSt] = useState({});
    const openGuidePopupOrder = (index) => {
        setGuidePopupOrderSt(prevStates => ({...prevStates, [index]: true}));
    }
    const closeGuidePopupOrder = (index) => {
        setGuidePopupOrderSt(prevStates => ({...prevStates, [index]: false}));
    }

    const [guidePopupPaySt, setGuidePopupPaySt] = useState({});
    const openGuidePopupPay = ((index) => {
        setGuidePopupPaySt(prevStates => ({...prevStates, [index]: true}));
    })
    const closeGuidePopupPay = ((index) => {
        setGuidePopupPaySt(prevStates => ({...prevStates, [index]: false}));
    })

    const [integPayDetailPopupSt, setIntegPayDetailPopupSt] = useState(false);
    const [integPaySeqnum, setIntegPaySeqnum] = useState(null);
    const integPayDetail = ((seqnum) => {
        setIntegPaySeqnum(seqnum);
        setIntegPayDetailPopupSt(true)
    })
    const integDetailPopupClose = (() => {
        setIntegPayDetailPopupSt(false);
    })
    useEffect(() => {
        if (integPaySeqnum !== null) {
            setIntegPayDetailPopupSt(true);
        }
    }, [integPaySeqnum]);

    const selectOsTrackNum = ((shipco, trackNum) => {

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (shipco === "FedEx" || shipco === "FedEx-E") {
            const url = `https://www.fedex.com/fedextrack/?trknbr=${trackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "UPS" || shipco === "UPS-E") {
            const url = `https://www.ups.com/track?loc=ko_KR&trackNums=${trackNum}&requester=ST/trackdetails`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "DHL") {
            const url = `https://www.dhl.com/kr-ko/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${trackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "EMS" || shipco === "SEA" || shipco === "EMSP" || shipco === "K-Packet" || shipco === "Small-Packet") {
            const url = `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=${trackNum}&displayHeader=N`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
    })

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: {color: "#98B0D4", label: t(`prodStLabel1`)},
            OS01: {color: "#98B0D4", label: t(`prodStLabel2`)},
            OS02: {color: "#98B0D4", label: t(`prodStLabel3`)},
            OS03: {color: "#FFB378", label: t(`prodStLabel4`)},
            OS04: {color: "#98B0D4", label: t(`prodStLabel14`)},
            OS05: {color: "#FFB378", label: t(`prodStLabel5`)},
            OS06: {color: "#98B0D4", label: t(`prodStLabel15`)},
            OS07: {color: "#6EDA60", label: t(`prodStLabel6`)},
            OS08: {color: "#98B0D4", label: t(`prodStLabel7`)},
            OS09: {color: "#98B0D4", label: t(`prodStLabel8`)},
            OS10: {color: "#98B0D4", label: t(`prodStLabel9`)},
            OS11: {color: "#FFB378", label: t(`prodStLabel10`)},
            OS12: {color: "#979696", label: t(`prodStLabel11`)},
            OS13: {color: "#FF8F62", label: t(`prodStLabel12`)},
            OS14: {color: "#FF8F62", label: t(`prodStLabel13`)},
        };

        const style = orderStStyles[orderSt] || {color: "black", label: null};
        return <p style={{margin: "0", color: style.color, fontSize: "15px"}}>{style.label}</p>;
    };

    const selectedTotalPricePrint = (pay) => {
        if (!pay) return;

        let prodTotal = 0;
        let shipTotal = 0;
        let etcTotal = 0;
        let integTotal = 0;

        // 상품금액 총합 계산
        if (pay.payType === "PT0") {
            const ttEtcFee = pay.ttEtcFee || 0;
            const ttProdExpense = pay.ttProdExpense || 0;
            prodTotal += ttEtcFee + ttProdExpense;
            return prodTotal;

            // 해외배송비 총합 계산
        } else if (pay.payType === "PT1") {
            const prices = [
                pay.ttFedexPrice, pay.ttFedexEcoPrice, pay.ttDhlPrice, pay.ttEmsPrice,
                pay.ttEmsPrePrice, pay.ttUpsPrice, pay.ttUpsEcoPrice, pay.ttKPackPrice,
                pay.ttAirSPrice, pay.ttSeaPrice, pay.ttDomPrice
            ];
            const validPrice = prices.find(price => price > 0) || 0;
            shipTotal += validPrice;
            return shipTotal;

            // 기타비용 총합 계산
        } else if (pay.payType === "PT2") {
            const courierPay = pay.courierPay || 0;
            const returnFee = pay.returnFee || 0;
            const deliveryFee = pay.deliveryFee || 0;
            const repackFee = pay.repackFee || 0;
            const agencyFee = pay.agencyFee || 0;
            const comprFee = pay.comprFee || 0;
            const etcPrice = pay.etcPrice || 0;
            const etcFee = pay.etcFee || 0;
            etcTotal += courierPay + returnFee + deliveryFee + repackFee + agencyFee + comprFee + etcPrice + etcFee;
            return etcTotal;
        } else if (pay.payType === "PT3") {
            const ttProdPrice = pay.ttProdExpense || 0;
            const ttEtcFee = pay.ttEtcFee || 0;
            const ttEtcPrice = pay.ttEtcPrice || 0;
            const ttShipExpense = pay.ttShipExpense || 0;
            integTotal += ttProdPrice + ttEtcFee + ttEtcPrice + ttShipExpense;
            return integTotal;
        }
    };


    // 현재 사용자 브라우저위치
    const pathComponent = window.location.pathname;

    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        {
            label: t(`myTab1`), content:
                <div>
                    <div style={{height: "80px", display: "flex", alignItems: "center", position: "relative"}}>
                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={periodButtonRef}
                                onClick={() => setPeriodSelectShow(true)}>{periodSelectCommonValue}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            periodSelectShow === true ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "0",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={periodDivRef} onClick={periodDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => periodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                    </button>
                                </div>
                                :
                                null
                        }

                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative",
                            marginLeft: "20px",
                        }} ref={orderTypeButtonRef}
                                onClick={() => setOrderTypeSelectShow(true)}>{orderTypeSelectCommonValue}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            orderTypeSelectShow ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "220px",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={orderTypeDivRef} onClick={orderTypeDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderTypeBtHandle(t('orderType'))}>{t(`orderType`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderTypeBtHandle(t('myTab1'))}>{t(`myTab1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderTypeBtHandle(t('deliveryOption'))}>{t(`deliveryOption`)}
                                    </button>
                                </div>
                                :
                                null
                        }

                        <div style={{
                            borderRight: "solid",
                            width: "220px",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray"
                        }}>
                            <button style={{
                                height: "40px",
                                width: "180px",
                                borderColor: "lightgray",
                                border: "none",
                                backgroundColor: "#efeef0",
                                color: "#AAA7A6",
                                display: "flex",
                                marginLeft: "20px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                overflow: "hidden",
                                position: "relative"
                            }} ref={orderStButtonRef}
                                    onClick={() => setOrderStSelectShow(true)}>{filterOrderSt(orderStSelectCommonValue)}<i
                                style={{
                                    fontSize: "27px",
                                    position: "absolute",
                                    right: "3px",
                                    bottom: "2px"
                                }}><BiChevronDown/></i></button>
                        </div>
                        {
                            orderStSelectShow === true ?
                                <div style={{
                                    width: "300px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "380px",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                }} ref={orderStDivRef} onClick={orderStDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle(t('state'))}>{t(`state`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS00")}>{t(`prodStLabel1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS01")}>{t(`prodStLabel2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS02")}>{t(`prodStLabel3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS03")}>{t(`prodStLabel4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS04")}>{t(`prodStLabel14`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS05")}>{t(`prodStLabel5`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS06")}>{t(`prodStLabel15`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS07")}>{t(`prodStLabel6`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("OS08")}>{t(`prodStLabel7`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS0")}>{t(`prodStLabel8`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS1")}>{t(`prodStLabel9`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS2")}>{t(`prodStLabel10`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS3")}>{t(`prodStLabel11`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS4")}>{t(`prodStLabel12`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => orderStBtHandle("PS5")}>{t(`prodStLabel13`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <div style={{width: "250px", height: "40px", marginLeft: "20px", position: "relative"}}>
                            <input className="mypgSerchInput wd-100pc font-s-13 p-l-15" type="text"
                                   placeholder={t(`myPlaceHd`)} spellCheck="false"
                                   style={{
                                       width: "100%",
                                       border: "solid 1px #EAEAEA",
                                       height: "100%",
                                       color: "#C1C1C1",
                                       borderRadius: "6px"
                                   }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                        </div>
                        <div>
                            <button className="ReviewPgWriteBtn" style={{
                                padding: "0 10px",
                                miWidth: "100px",
                                height: "40px",
                                fontSize: "13px",
                                borderWidth: "thin 1px",
                                borderRadius: "4px",
                                marginLeft: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "3px"
                            }} onClick={downloadInvoice}>{t(`myExcelDwBt`)}
                            </button>
                        </div>
                    </div>
                    <div className="ht-auto m-t-30">
                        <div style={{
                            height: "80px",
                            display: "flex",
                            // borderBottom: "1px solid #F2F2F2",
                            border: "none",
                            backgroundColor: "#f8f8f8",
                            zIndex: "2",
                            position: "sticky",
                            top: "55px",
                            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                        }}>
                            {/*체크박스*/}
                            <div style={{
                                width: "3%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <input id="orderCheckBox" type="checkbox" checked={selectAll}
                                       className="myPgCustomCheckBox"
                                       onChange={handleSelectAllChange} style={{width: "20px", height: "20px"}}/>
                            </div>
                            {/*국가, 수취인성함*/}
                            <div style={{
                                width: "15%",
                                height: "100%",
                                display: "inline-block",
                                textAlign: "center",
                                color: "dimgray"
                            }}>
                                <div className="m-t-15">
                                    <p className="font-s-13">{t(`modSmallMenu3`)}</p>
                                </div>
                                <div>
                                    <p style={{fontSize: "13px"}}>{t(`myHeader1`)}</p>
                                </div>
                            </div>
                            {/*상품명*/}
                            <div style={{
                                width: "25%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                            }}>
                                <div>
                                    <p style={{margin: "0", color: "dimgray", fontSize: "13px"}}>{t(`prodNm`)}</p>
                                </div>
                            </div>
                            {/*미결제, 결제완료*/}
                            <div style={{
                                width: "30%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                            }}>
                                <div>
                                    <p style={{margin: "0", color: "dimgray", fontSize: "13px"}}>{t(`myHeader2`)}</p>
                                </div>
                            </div>
                            {/*상태*/}
                            <div style={{
                                width: "12%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                            }}>
                                <div>
                                    <p style={{
                                        margin: "0",
                                        color: "dimgray",
                                        fontSize: "13px"
                                    }}>{t(`dchSmallMenu5`)}</p>
                                </div>
                            </div>
                            {/*출고일, 운송장번호*/}
                            <div style={{
                                width: "15%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#7d7a7a",
                            }}>
                                <div style={{textAlign: "center"}}>
                                    <p style={{margin: "0", color: "dimgray", fontSize: "13px"}}>{t(`myHeader3`)}</p>
                                    <p style={{margin: "0", color: "dimgray", fontSize: "13px"}}>{t(`prodTrNum`)}</p>
                                </div>
                            </div>
                        </div>
                        {orderdtList.length === 0 || filteredList.length === 0 ?
                            <div style={{
                                height: "500px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                <div style={{height: "100px", width: "30%"}}>
                                    <span style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%"
                                    }}>
                                        <img style={{width: "50px", height: "50px", opacity: "0.3"}} alt=""
                                             src={noData}/><span style={{
                                        fontSize: "28px",
                                        marginLeft: "20px",
                                        fontWeight: "bold",
                                        color: "lightgrey"
                                    }}>No Data</span></span>
                                </div>
                            </div>
                            :
                            <div ref={myPgContentRef}>
                                {filteredList.slice(0, orderListDisplayLimit).map((order, index) => {
                                    let data1 = 0, data2 = 0, data3 = 0, data4 = 0;

                                    if (order.type === 'orderdt') {
                                        // 상품미결제 + 관리자가 등록한 상품누락금 미결제에 추가
                                        data1 = order.productsDTO?.reduce((sum, item) => {
                                            return (item.prodSt === "IS01" || item.prodSt === "IS02") && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                        }, 0) || 0;
                                        data1 += order.payInfoDTO?.reduce((sum, item) => {
                                            return (item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1'
                                            && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                                        }, 0) || 0;

                                        // 상품총결제 + 관리자가 등록한 상품누락금 결제완료에 추가
                                        data2 = order.productsDTO?.reduce((sum, item) => {
                                            return item.prodSt !== "IS01" && item.prodSt !== "IS02" && item.prodSt !== "IS12" && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                        }, 0) || 0;
                                        data2 += order.payInfoDTO?.reduce((sum, item) => {
                                            return item.payStatus !== "PS0" && item.payStatus !== "PS1" && item.payClass === '1'
                                            && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                                        }, 0) || 0;

                                        // 기타미결제
                                        data3 = order.payInfoDTO?.reduce((sum, item) => {
                                            if ((item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1') {
                                                // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                                const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                                const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                                return sum + etcPrice + etcFee;
                                            }
                                            return sum;
                                        }, 0) || 0;

                                        // 기타총결제
                                        data4 = order.payInfoDTO?.reduce((sum, item) => {
                                            if ((item.payStatus !== "PS0" && item.payStatus !== "PS1") && item.payClass === '1') {
                                                // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                                const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                                const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                                return sum + etcPrice + etcFee;
                                            }
                                            return sum;
                                        }, 0) || 0;
                                    }
                                    return (
                                        <div style={{margin: "auto"}}
                                             key={`order-${order.seqnum}-${index}`}>
                                            {/* orderdt map*/}
                                            {order.type === 'orderdt' ?
                                                <div style={{marginBottom: "30px"}}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: "19px",
                                                        borderTop: "solid",
                                                        borderWidth: "thin 1px",
                                                        borderColor: "#EDEDED",
                                                        padding: "20px 0",
                                                        // border:"solid"
                                                    }}>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: "50px",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "22px",
                                                                cursor: "pointer",
                                                                paddingLeft: "10px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "100%",
                                                            }} onClick={myOrdPgMove(order.orderNumber)}>
                                                                <img alt="orderPacking" style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    marginRight: "20px"
                                                                }} src={mypgPayinfo}/>
                                                                {order?.orderNumber ? order.orderNumber : order.orderdtDTO?.orderNumber}
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "100%",
                                                            }}>
                                                                <div style={{
                                                                    borderWidth: "thin 1px",
                                                                    height: "30px",
                                                                }}>
                                                                </div>
                                                                <div style={{
                                                                    width: "130px",
                                                                    borderWidth: "thin 1px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    height: "20px",
                                                                    alignItems: "center",
                                                                    marginRight: "10px"
                                                                }}>{order.regDate ? `${order.regDate.substring(0, 4)}-${order.regDate.substring(4, 6)}-${order.regDate.substring(6, 8)}` : ''}
                                                                </div>
                                                                <div style={{
                                                                    width: "90px",
                                                                    borderLeft: "solid",
                                                                    borderWidth: "thin 1px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    height: "20px",
                                                                    alignItems: "center"
                                                                }}>{order.shipco}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderColor: "lightgray",
                                                        borderWidth: "thin 1px",
                                                        padding: "30px 0",
                                                    }}>
                                                        {/*오더 체크박스*/}
                                                        <div style={{
                                                            width: "3%",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}>
                                                            <input id="orderCheckBox" type="checkbox"
                                                                   checked={selectedRows.includes(order.seqnum)}
                                                                   onChange={() => handleCheckboxChange(order.seqnum)}
                                                                   style={{width: "20px", height: "20px"}}/>
                                                        </div>
                                                        {/*오더 국가, 수취인명*/}
                                                        <div className="mypgOrderCr" style={{
                                                            width: "15%",
                                                            margin: "auto",
                                                            display: "inline-block",
                                                            color: "#7d7a7a",
                                                            textAlign: "center",
                                                        }}>
                                                            <div style={{
                                                                fontSize: "15px",
                                                                fontWeight: "bold"
                                                            }}>{order.recinfoDTO && order.recinfoDTO.countryDTO.countryEngNm}</div>
                                                            <div>{order.recinfoDTO && order.recinfoDTO.recname}</div>
                                                        </div>
                                                        {/*오더 상품명*/}
                                                        <div className="display-block wd-25pc p-l-20 p-r-20">
                                                            {order.productsDTO && order.productsDTO.slice(0, 10).map((prod, prodIndex) => (
                                                                <div key={`product-${prod.seqnum}-${prodIndex}`}
                                                                     className="mypgOrderCr" style={{
                                                                    height: "auto",
                                                                    alignItems: "center",
                                                                    color: "#7d7a7a",
                                                                    fontSize: "15px"
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "30px"
                                                                    }}>
                                                                    <span style={{fontSize: "13px", color: "darkgrey"}}>
                                                                        {
                                                                            prod.prodTy === "P00" ? t(`myProdTy1`) :
                                                                                prod.prodTy === "P01" ? t(`myProdTy2`) :
                                                                                    prod.prodTy === "P02" ? t(`myProdTy3`) :
                                                                                        prod.prodTy === "P03" ? t(`myProdTy4`) :
                                                                                            prod.prodTy === "P04" ? t(`myProdTy5`) :
                                                                                                prod.prodTy === "P05" ? t(`myProdTy6`) :
                                                                                                    null
                                                                        }
                                                                    </span>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "13px",
                                                                            marginLeft: "5px",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                            wordBreak: "break-all"
                                                                        }}>
                                                                        {prod.name}
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/*오더 미결제, 결제완료*/}
                                                        <div className="p-l-15 p-r-15 wd-30pc">
                                                            <div style={{
                                                                display: "flex",
                                                                padding: "20px",
                                                                backgroundColor: "white",
                                                                borderRadius: "10px",
                                                                boxShadow: "0 5px 8px 0 rgba(0,0,0,0.1)"
                                                            }}>
                                                                <div style={{width: "80%"}}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        height: "40px"
                                                                    }}>
                                                                        <img style={{
                                                                            width: "22px",
                                                                            height: "22px",
                                                                            marginRight: "5px"
                                                                        }} src={mypgPayCardOrder} alt=""/>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "16px",
                                                                            color: "black",
                                                                            fontWeight: "bold"
                                                                        }}>{t(`myOrddtCardHeader1`)}</span>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        marginTop: "10px"
                                                                    }}>
                                                                        <div style={{
                                                                            width: "85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent: "space-between"
                                                                        }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader2`)}</span>
                                                                            <span style={{
                                                                                margin: "0 0 0 10px",
                                                                                fontSize: "15px",
                                                                                color: data1 > 0 ? "darkorange" : "black",
                                                                                fontWeight: data1 > 0 ? "bold" : null
                                                                            }}>{data1?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width: "85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent: "space-between"
                                                                        }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader3`)}</span>
                                                                            <span style={{
                                                                                margin: "0 0 0 10px",
                                                                                fontSize: "15px",
                                                                                color: "black"
                                                                            }}>{data2?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width: "85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent: "space-between"
                                                                        }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader4`)}</span>
                                                                            <span style={{
                                                                                margin: "0 0 0 10px",
                                                                                fontSize: "15px",
                                                                                color: data3 > 0 ? "darkorange" : "black",
                                                                                fontWeight: data3 > 0 ? "bold" : null
                                                                            }}>{data3?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{display: "flex", alignItems: "center"}}>
                                                                        <div style={{
                                                                            width: "85%",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px",
                                                                            justifyContent: "space-between"
                                                                        }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "14px",
                                                                            color: "black"
                                                                        }}>{t(`myOrddtCardHeader`)}</span>
                                                                            <span style={{
                                                                                margin: "0 0 0 10px",
                                                                                fontSize: "15px",
                                                                                color: "black",
                                                                            }}>{data4?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    width: "20%",
                                                                    position: "relative",
                                                                    paddingBottom: "10px"
                                                                }}>
                                                                    <div style={{
                                                                        height: "50%",
                                                                        display: "flex",
                                                                        justifyContent: "flex-end"
                                                                    }}>
                                                                        <img onClick={() => openGuidePopupOrder(index)}
                                                                             style={{
                                                                                 width: "40px",
                                                                                 height: "40px",
                                                                                 cursor: "pointer"
                                                                             }} src={mypgWarn} alt=""/>
                                                                    </div>
                                                                    <div style={{
                                                                        height: "50%",
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignItems: "flex-end"
                                                                    }}>
                                                                        <button onClick={myOrdPgMove(order.orderNumber)}
                                                                                style={{
                                                                                    width: "60px",
                                                                                    height: "40px",
                                                                                    border: "none",
                                                                                    background: "orange",
                                                                                    marginTop: "auto",
                                                                                    borderRadius: "20px"
                                                                                }}>
                                                                        <span style={{
                                                                            margin: "0",
                                                                            fontSize: "13px",
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}>{t(`myDt`)}</span>
                                                                        </button>
                                                                    </div>
                                                                    {guidePopupOrderSt[index] ?
                                                                        <div style={{
                                                                            width: "300px",
                                                                            height: "170px",
                                                                            position: "absolute",
                                                                            backgroundColor: "white",
                                                                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                                                            zIndex: 3,
                                                                            top: 0,
                                                                            right: 0
                                                                        }}>
                                                                            <div style={{
                                                                                paddingTop: "8px",
                                                                                cursor: "pointer",
                                                                                height: "40px",
                                                                                top: "0",
                                                                                right: "5px",
                                                                                display: "flex",
                                                                                marginRight: "6px",
                                                                                borderBottom: "solid",
                                                                                borderWidth: "thin 1px",
                                                                                borderColor: "#E6E6E6",

                                                                            }}
                                                                                 onClick={() => closeGuidePopupOrder(index)}>
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        fontWeight: "bold"
                                                                                    }}>{t(`myCardHeader`)}</p>
                                                                                </div>
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    right: "6px"
                                                                                }}>
                                                                                    <img style={{
                                                                                        width: "25px",
                                                                                        height: "25px"
                                                                                    }} src={commonClose} alt=""/>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{
                                                                                width: "100%",
                                                                                height: "110px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px"
                                                                                    }}>
                                                                                        {t(`myOrddtCont1`)}
                                                                                        <button
                                                                                            onClick={myOrdPgMove(order.orderNumber)}
                                                                                            style={{
                                                                                                width: "50px",
                                                                                                height: "30px",
                                                                                                border: "none",
                                                                                                background: "orange",
                                                                                                margin: "0 2px",
                                                                                                borderRadius: "20px"
                                                                                            }}>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "12px",
                                                                                        color: "white",
                                                                                        fontWeight: "bold"
                                                                                    }}>{t(`myDt`)}</span>
                                                                                        </button>
                                                                                        {t(`myOrddtCont2`)}
                                                                                    </p>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        marginTop: "5px"
                                                                                    }}>
                                                                                        {t(`myOrddtCont3`)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/*오더 상태*/}
                                                        <div style={{
                                                            width: "12%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            color: "#7d7a7a",
                                                        }}>
                                                            <div>
                                                                {orderStPrint(order?.orderStatus)}
                                                            </div>
                                                        </div>
                                                        {/*오더 출고일, 운송장번호*/}
                                                        <div style={{
                                                            width: "15%",
                                                            color: "#7d7a7a",
                                                            margin: "auto",
                                                        }}>
                                                            {/*<div style={{textAlign: "center"}}>{order.payInfoDTO.shipInfoDTO.outRegDate ? `${order.payInfoDTO.shipInfoDTO.outRegDate.substring(0, 4)}-${order.payInfoDTO.shipInfoDTO.outRegDate.substring(4, 6)}-${order.payInfoDTO.shipInfoDTO.outRegDate.substring(6, 8)}` : ''}</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                // payinfo map
                                                order.type === 'payinfo' && (order.payType === "PT1") ?
                                                    <div style={{marginBottom: "30px"}}
                                                         key={`pay-${order.seqNum}-${index}`}>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            fontSize: "19px",
                                                            borderTop: "solid",
                                                            borderWidth: "thin 1px",
                                                            borderColor: "#EDEDED",
                                                            padding: "20px 0"
                                                        }}>
                                                            <div style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                height: "50px",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <div style={{
                                                                    fontSize: "22px",
                                                                    cursor: "pointer",
                                                                    paddingLeft: "10px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    height: "100%",
                                                                }}
                                                                     onClick={() => deliveryDetailTrueFn(order.seqNum, convertToShipco(order.dvType, order.orderdtDTO.shipco))}>
                                                                    <img alt="order" style={{
                                                                        width: "30px",
                                                                        height: "30px",
                                                                        marginRight: "20px"
                                                                    }} src={mypgOrderdt}/>
                                                                    {order?.orderNumber ? order.orderNumber : order.orderdtDTO?.orderNumber}
                                                                    -{order.shipInfoDTO.boxNum}
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    height: "100%",
                                                                }}>
                                                                    <div style={{
                                                                        borderWidth: "thin 1px",
                                                                        height: "30px",
                                                                    }}>
                                                                    </div>
                                                                    <div style={{
                                                                        width: "130px",
                                                                        borderWidth: "thin 1px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        height: "20px",
                                                                        alignItems: "center",
                                                                        marginRight: "10px"
                                                                    }}>{order.regDate ? `${order.regDate.substring(0, 4)}-${order.regDate.substring(4, 6)}-${order.regDate.substring(6, 8)}` : ''}
                                                                    </div>
                                                                    <div style={{
                                                                        width: "90px",
                                                                        borderLeft: "solid",
                                                                        borderWidth: "thin 1px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        height: "20px",
                                                                        alignItems: "center"
                                                                    }}>{convertToShipco(order.dvType, order.orderdtDTO.shipco)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            borderColor: "lightgray",
                                                            borderWidth: "thin 1px",
                                                            padding: "30px 0",
                                                        }}>
                                                            {/*페이 체크박스*/}
                                                            <div style={{
                                                                width: "3%",
                                                                height: "100%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}>
                                                                <input id="orderCheckBox" type="checkbox"
                                                                       checked={selectedRows.includes(order.seqNum)}
                                                                       onChange={() => handleCheckboxChange(order.seqNum)}
                                                                       style={{width: "20px", height: "20px"}}/>
                                                            </div>
                                                            {/*페이 국가, 수취인명*/}
                                                            <div className="mypgOrderCr wd-15pc text-center" style={{
                                                                margin: "auto",
                                                                display: "inline-block",
                                                                color: "#7d7a7a",
                                                            }}>
                                                                <div style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}>{order.appRecDTO.countryDTO.countryEngNm}</div>
                                                                <div>{order.appRecDTO.recname}</div>
                                                            </div>
                                                            {/*페이 상품명*/}
                                                            <div className="display-block wd-25pc p-l-20 p-r-20">
                                                                {order.packListDTO && order.packListDTO.productsDTO && order.packListDTO.productsDTO.slice(0, 10).map((prod, prodIndex) => (
                                                                    <div key={`product-${prod.seqnum}-${prodIndex}`}
                                                                         className="mypgOrderCr" style={{
                                                                        height: "auto",
                                                                        alignItems: "center",
                                                                        color: "#7d7a7a",
                                                                        fontSize: "15px"
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "30px"
                                                                        }}>
                                                                    <span style={{fontSize: "13px", color: "darkgrey"}}>
                                                                        {
                                                                            prod.prodTy === "P00" ? t(`myProdTy1`) :
                                                                                prod.prodTy === "P01" ? t(`myProdTy2`) :
                                                                                    prod.prodTy === "P02" ? t(`myProdTy3`) :
                                                                                        prod.prodTy === "P03" ? t(`myProdTy4`) :
                                                                                            prod.prodTy === "P04" ? t(`myProdTy5`) :
                                                                                                prod.prodTy === "P05" ? t(`myProdTy6`) :
                                                                                                    null
                                                                        }
                                                                    </span>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "13px",
                                                                                marginLeft: "5px",
                                                                                color: "dimgrey",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis",
                                                                                whiteSpace: "nowrap",
                                                                                wordBreak: "break-all"
                                                                            }}>
                                                                        {prod.name}
                                                                    </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="wd-30pc p-l-15 p-r-15">
                                                                <div style={{
                                                                    display: "flex",
                                                                    padding: "20px",
                                                                    backgroundColor: "white",
                                                                    borderRadius: "10px",
                                                                    boxShadow: "0 5px 8px 0 rgba(0,0,0,0.1)"
                                                                }}>
                                                                    <div style={{width: "80%"}}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            height: "40px"
                                                                        }}>
                                                                            <img style={{
                                                                                width: "23px",
                                                                                height: "23px",
                                                                                marginRight: "8px"
                                                                            }} src={mypgPayCardDelivery} alt=""/>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "16px",
                                                                                color: "black",
                                                                                fontWeight: "bold"
                                                                            }}>{t(`modSHeader6`)}</span> {/*해외배송비*/}
                                                                        </div>
                                                                        {/*FedEx*/}
                                                                        {order?.ttFedexPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: "10px"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>FedEx</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttFedexPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttFedexPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttFedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*FedEx-E*/}
                                                                        {order?.ttFedexEcoPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>FedEx-E</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttFedexEcoPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttFedexEcoPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttFedexEcoPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*UPS*/}
                                                                        {order?.ttUpsPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>UPS</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*UPS-E*/}
                                                                        {order?.ttUpsEcoPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>UPS-E</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttUpsEcoPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttUpsEcoPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttUpsEcoPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*EMS*/}
                                                                        {order?.ttEmsPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>EMS</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttEmsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttEmsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttEmsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*EMSP*/}
                                                                        {order?.ttEmsPrePrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>EMSP</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttEmsPrePrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttEmsPrePrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttEmsPrePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*K-Packet*/}
                                                                        {order?.ttKPackPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>K-Packet</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttKPackPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttKPackPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttKPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*항공소형포장물*/}
                                                                        {order?.ttAirSPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>Small-Packet</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttAirSPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttAirSPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttAirSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*선편*/}
                                                                        {order?.ttSeaPrice > 0 && (
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>SEA</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttSeaPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttSeaPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : "normal"
                                                                                    }}>
                                                                                {order?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {/*국내배송*/}
                                                                        {order.ttDomPrice > 0 ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <div style={{
                                                                                    width: "85%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    height: "40px",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "14px",
                                                                                color: "black"
                                                                            }}>{t(`myPayinfoCardHeader2`)}</span>
                                                                                    <span style={{
                                                                                        margin: "0",
                                                                                        fontSize: "15px",
                                                                                        color: order?.ttDomPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "darkorange" : "black",
                                                                                        fontWeight: order?.ttDomPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") ? "bold" : null
                                                                                    }}>
                                                                                {order?.ttDomPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <div style={{
                                                                        width: "20%",
                                                                        position: "relative",
                                                                        paddingBottom: "10px",
                                                                    }}>
                                                                        <div style={{
                                                                            height: "50%",
                                                                            display: "flex",
                                                                            justifyContent: "flex-end"
                                                                        }}>
                                                                            <img
                                                                                onClick={() => openGuidePopupPay(index)}
                                                                                style={{
                                                                                    width: "40px",
                                                                                    height: "40px",
                                                                                    cursor: "pointer"
                                                                                }} src={mypgWarn} alt=""/>
                                                                        </div>
                                                                        <div style={{
                                                                            height: "50%",
                                                                            display: "flex",
                                                                            justifyContent: "flex-end",
                                                                            alignItems: "flex-end"
                                                                        }}>
                                                                            <button
                                                                                onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}
                                                                                style={{
                                                                                    width: "60px",
                                                                                    height: "40px",
                                                                                    border: "none",
                                                                                    background: "orange",
                                                                                    marginTop: "auto",
                                                                                    borderRadius: "20px"
                                                                                }}>
                                                                            <span style={{
                                                                                margin: "0",
                                                                                fontSize: "13px",
                                                                                color: "white",
                                                                                fontWeight: "bold"
                                                                            }}>{t(`myDt`)}</span>
                                                                            </button>
                                                                        </div>
                                                                        {guidePopupPaySt[index] ?
                                                                            <div style={{
                                                                                width: "300px",
                                                                                height: "170px",
                                                                                position: "absolute",
                                                                                backgroundColor: "white",
                                                                                boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                                                                                zIndex: 3,
                                                                                top: 0,
                                                                                right: 0
                                                                            }}>
                                                                                <div style={{
                                                                                    paddingTop: "8px",
                                                                                    cursor: "pointer",
                                                                                    height: "40px",
                                                                                    top: "0",
                                                                                    right: "5px",
                                                                                    display: "flex",
                                                                                    marginRight: "6px",
                                                                                    borderBottom: "solid",
                                                                                    borderWidth: "thin 1px",
                                                                                    borderColor: "#E6E6E6",

                                                                                }}
                                                                                     onClick={() => closeGuidePopupPay(index)}>
                                                                                    <div style={{
                                                                                        width: "100%",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                        <p style={{
                                                                                            margin: "0",
                                                                                            fontSize: "15px",
                                                                                            fontWeight: "bold"
                                                                                        }}>{t(`myCardHeader`)}</p>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        right: "6px"
                                                                                    }}>
                                                                                        <img style={{
                                                                                            width: "25px",
                                                                                            height: "25px"
                                                                                        }} src={commonClose} alt=""/>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    width: "100%",
                                                                                    height: "110px",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center"
                                                                                }}>
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: "5px"}}>
                                                                                            <p style={{fontSize: "15px"}}>
                                                                                                {t(`myOrddtCont1`)}
                                                                                                <button
                                                                                                    onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}
                                                                                                    style={{
                                                                                                        width: "50px",
                                                                                                        height: "30px",
                                                                                                        border: "none",
                                                                                                        background: "orange",
                                                                                                        margin: "0 2px",
                                                                                                        borderRadius: "20px"
                                                                                                    }}>
                                                                                                    <span style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "12px",
                                                                                                        color: "white",
                                                                                                        fontWeight: "bold"
                                                                                                    }}>{t(`myDt`)}</span>
                                                                                                </button>
                                                                                                {t(`myOrddtCont2`)}
                                                                                            </p>
                                                                                        </div>
                                                                                        <p style={{
                                                                                            fontSize: "15px",
                                                                                        }}>
                                                                                            {t(`myPayinfoCont`)}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div><br/></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*페이 상태*/}
                                                            <div style={{
                                                                width: "12%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                color: "#7d7a7a",
                                                            }}>
                                                                <div>
                                                                    {order.payStatus === "PS0" ?
                                                                        <p style={{
                                                                            margin: "0",
                                                                            color: "#98B0D4",
                                                                            fontSize: "15px"
                                                                        }}>{t(`prodStLabel8`)}</p>
                                                                        :
                                                                        order.payStatus === "PS1" ?
                                                                            <p style={{
                                                                                margin: "0",
                                                                                color: "#98B0D4",
                                                                                fontSize: "15px"
                                                                            }}>{t(`prodStLabel9`)}</p>
                                                                            :
                                                                            order.payStatus === "PS2" ?
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    color: "#FFB378",
                                                                                    fontSize: "15px"
                                                                                }}>{t(`prodStLabel10`)}</p>
                                                                                :
                                                                                order.payStatus === "PS3" ?
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        color: "#979696",
                                                                                        fontSize: "15px"
                                                                                    }}>{t(`prodStLabel11`)}</p>
                                                                                    :
                                                                                    order.payStatus === "PS4" ?
                                                                                        <p style={{
                                                                                            margin: "0",
                                                                                            color: "#FF8F62",
                                                                                            fontSize: "15px"
                                                                                        }}>{t(`prodStLabel12`)}</p>
                                                                                        :
                                                                                        order.payStatus === "PS5" ?
                                                                                            <p style={{
                                                                                                margin: "0",
                                                                                                color: "#FF8F62",
                                                                                                fontSize: "15px"
                                                                                            }}>{t(`prodStLabel13`)}</p>
                                                                                            :
                                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/*페이 출고일, 운송장번호*/}
                                                            <div style={{
                                                                width: "15%",
                                                                color: "#7d7a7a",
                                                                margin: "auto",
                                                            }}>
                                                                <div
                                                                    style={{textAlign: "center"}}>{order.shipInfoDTO.outRegDate ? `${order.shipInfoDTO.outRegDate.substring(0, 4)}-${order.shipInfoDTO.outRegDate.substring(4, 6)}-${order.shipInfoDTO.outRegDate.substring(6, 8)}` : ''}</div>
                                                                <div className="font-c-progress text-underline"
                                                                     onClick={() => selectOsTrackNum(convertToShipco(order.dvType), order.shipInfoDTO.outWayNum)}
                                                                     style={{
                                                                         cursor: "pointer",
                                                                         marginTop: "5px",
                                                                         fontSize: "16px",
                                                                         fontWeight: "bold",
                                                                         textAlign: "center",
                                                                     }}>{order.shipInfoDTO.outWayNum}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    {/*주문서탭 더보기버튼*/}
                    {
                        orderListDisplayLimit && filteredList && filteredList.length > orderListDisplayLimit ?
                            <div className="m-t-100 m-b-50 user-center font-s-17">
                                <button onClick={handleMoreOrderList}
                                        className="moreBt1 m-r-8 wd-100 ht-40 font-c-default m-r-8 font-s-14">More
                                </button>
                            </div>
                            :
                            null
                    }

                </div>
        },
        {
            label: t(`myTab2`), content:
                <div>
                    <div style={{height: "80px", display: "flex", alignItems: "center", position: "relative"}}>
                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={payPeriodButtonRef}
                                onClick={() => setPayPeriodSelectShow(true)}>{payPeriodSelectCommonValue}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            payPeriodSelectShow ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "0",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={payPeriodDivRef} onClick={payPeriodDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payPeriodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                        <button style={{
                            height: "40px",
                            width: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            marginLeft: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={payStButtonRef}
                                onClick={() => setPayStSelectShow(true)}>{filterPaySt(payStSelectCommonValue)}<i
                            style={{
                                fontSize: "27px",
                                position: "absolute",
                                right: "3px",
                                bottom: "2px"
                            }}><BiChevronDown/></i></button>
                        {
                            payStSelectShow === true ?
                                <div style={{
                                    width: "300px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "170px",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={payStDivRef} onClick={payStDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle(t('state'))}>{t(`state`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("PS0")}>{t(`prodStLabel8`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("PS1")}>{t(`prodStLabel9`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => payStBtHandle("PS2")}>{t(`prodStLabel10`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                    {/*결제내역 컨테이너*/}
                    <div style={{
                        minHeight: "600px",
                        border: "solid",
                        borderWidth: "thin 1px",
                        borderColor: "#EAEAEA",
                        borderRadius: "4px",
                        margin: "10px"
                    }}>
                        <div style={{
                            borderBottom: "solid",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            display: "flex",
                            height: "60px",
                            fontSize: "15px",
                            color: "dimgrey",
                            marginBottom: "15px",
                            backgroundColor: "#FAFAFA",
                        }}>
                            <div style={{
                                width: "9%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{fontSize: "14px"}}>{t(`myHtDate`)}</p>
                            </div>
                            <div style={{
                                width: "11%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{fontSize: "14px"}}>{t(`ttPr`)}</p>
                            </div>
                            <div style={{
                                width: "10%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{fontSize: "14px"}}>{t(`myUseDep`)}</p>
                            </div>
                            <div style={{
                                width: "7%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{fontSize: "14px"}}>{t(`myUsePt`)}</p>
                            </div>
                            <div style={{
                                width: "7%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{margin: "0", fontSize: "14px", color: "dimgrey"}}>{t(`myUseCp`)}</p>
                            </div>
                            <div style={{
                                width: "7%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{margin: "0", fontSize: "14px", color: "dimgrey"}}>{t(`appSale`)}</p>
                            </div>
                            <div style={{
                                width: "7%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                            </div>
                            <div style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{margin: "0", fontSize: "14px", color: "dimgrey"}}>{t(`myFnPr`)}</p>
                            </div>
                            <div style={{
                                width: "13%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{margin: "0", fontSize: "14px", color: "dimgrey"}}>{t(`myPt`)}</p>
                            </div>
                            <div style={{
                                width: "14%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                                <p style={{margin: "0", fontSize: "14px", color: "dimgrey"}}>{t(`modPlHeader5`)}</p>
                            </div>
                        </div>
                        {/*아래에 맵핑*/}
                        {sortedPayList.filter(filterStatus).slice(0, payListDisplayLimit).map((pay, index) => (
                            <div key={index}
                                 style={{display: "flex", color: "dimgrey", fontSize: "14px", padding: "10px 0"}}>
                                <div style={{
                                    width: "9%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{formatDate(pay?.regDate)}
                                </div>
                                <div style={{
                                    width: "11%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{selectedTotalPricePrint(pay).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "10%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "7%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "7%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "7%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{pay?.appSalePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </div>
                                <div style={{
                                    width: "7%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}>
                                        {pay.payType === "PT3" ?
                                            <button onClick={() => integPayDetail(pay.seqNum)}
                                                    className="bt-btn-C wd-85 font-s-13 m-t-3">{t(`myHtItBt`)}</button>
                                            :
                                            null
                                        } {/*통합내역 버튼*/}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "15%",
                                        display: "flex",
                                        flexDirection: "row", // 기본적으로 한 줄 유지
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        flexWrap: "wrap", // 길 경우 줄바꿈
                                        gap: "5px", // 줄바꿈 간 간격
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: 0,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                    </p>
                                    {pay.payWay === "PM01" && (
                                        <p
                                            style={{
                                                margin: 0,
                                                color: "darkorange",
                                                fontWeight: "bold",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {pay?.lastForPrice}
                                        </p>
                                    )}
                                    {pay.payWay === "PM01" && (
                                        <p
                                            style={{
                                                margin: 0,
                                                fontSize: "12px",
                                                color: "gray",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {pay?.forCurrency}
                                        </p>
                                    )}
                                </div>
                                <div style={{
                                    width: "13%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}>
                                    {pay.payWay === "PM00" ? t(`modNoBank`) :
                                        pay.payWay === "PM01" ? "Paypal" :
                                            pay.payWay === "PM02" ? "Wechat" :
                                                pay.payWay === "PM03" ? "Alipay" :
                                                    pay.payWay === "PM04" ? "Point" :
                                                        pay.payWay === "PM05" ? "Deposit" : null}
                                </div>
                                <div style={{
                                    width: "14%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}>
                                    {pay.payStatus === "PS1" ?
                                        <p style={{
                                            margin: "0",
                                            color: "#98B0D4",
                                            fontSize: "15px"
                                        }}>{t(`prodStLabel9`)}</p>
                                        :
                                        pay.payStatus === "PS5" ?
                                            <p style={{
                                                margin: "0",
                                                color: "#EB939D",
                                                fontSize: "15px"
                                            }}>{t(`prodStLabel13`)}</p>
                                            :
                                            <p style={{
                                                margin: "0",
                                                color: "#FFB378",
                                                fontSize: "15px"
                                            }}>{t(`prodStLabel10`)}</p>}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/*결제내역탭 더보기버튼*/}
                    {
                        payListDisplayLimit && sortedPayList && sortedPayList.length > payListDisplayLimit ?
                            <div className="m-t-20 m-b-50 user-center font-s-17">
                                <button onClick={handleMorePayList}
                                        className="moreBt1 m-r-8 wd-100 ht-40 font-c-default m-r-8 font-s-14">More
                                </button>
                            </div>
                            :
                            null
                    }

                </div>
        },
        {
            label: t(`myTab3`), content:
                <div>
                    <div style={{height: "80px", display: "flex", alignItems: "center", position: "relative"}}>
                        <button style={{
                            height: "40px",
                            minWidth: "200px",
                            borderColor: "lightgray",
                            border: "none",
                            backgroundColor: "#efeef0",
                            color: "#AAA7A6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative"
                        }} ref={dacPeriodButtonRef}
                                onClick={() => setDacPeriodSelectShow(true)}>{dacPeriodSelectCommonValue}<i style={{
                            fontSize: "27px",
                            position: "absolute",
                            right: "3px",
                            bottom: "2px"
                        }}><BiChevronDown/></i></button>
                        {
                            dacPeriodSelectShow === true ?
                                <div style={{
                                    width: "200px",
                                    height: "auto",
                                    border: "none",
                                    boxShadow: "0px 0px 10px lightgray",
                                    position: "absolute",
                                    left: "0",
                                    top: "70px",
                                    zIndex: 3,
                                    backgroundColor: "white",
                                    borderRadius: "6px"
                                }} ref={dacPeriodDivRef} onClick={dacPeriodDivHandle}>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                    </button>
                                    <button className="mypgFilterBt"
                                            onClick={() => dacPeriodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="display-flex font-t-cont font-s-16">
                        <div style={{width: "35%", height: "40px", margin: "10px 10px 0 10px"}}>
                            <p style={{paddingTop: "10px"}}>{t(`myDepHt`)}</p>
                        </div>
                        <div style={{width: "35%", height: "40px", margin: "10px 10px 0 10px"}}>
                            <p style={{paddingTop: "10px"}}>{t(`myPtHt`)}</p>
                        </div>
                        <div style={{width: "30%", height: "40px", margin: "10px 10px 0 10px"}}>
                            <p style={{paddingTop: "10px"}}>{t(`myCpHt`)}</p>
                        </div>
                    </div>
                    {/*예적쿠내역 컨테이너*/}
                    <div className="font-t-cont display-flex">
                        {/*예치금*/}
                        <div style={{
                            width: "35%",
                            minHeight: "500px",
                            border: "solid",
                            margin: "10px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "4px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                height: "40px",
                                display: "flex",
                                fontSize: "14px",
                                color: "dimgrey",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                margin: "10px 0 10px 0"
                            }}>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myHtDate`)}
                                </div>
                                <div style={{
                                    width: "40%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myCont`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myPr`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myBal`)}
                                </div>
                            </div>
                            {sortedDacList.filter(dac => dac.depPrice > 0).slice(0, dacDisplayLimits.deposit).map((dac, index) => (
                                <div key={index} style={{height: "30px", display: "flex", fontSize: "12px"}}>

                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{formatDate(dac?.regDate)}
                                    </div>
                                    <div style={{
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}><span style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}>{dac.content}</span>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.depPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{dac?.depBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            ))}

                            {/*예치금&적립금탭 예치금 더보기버튼*/}
                            {
                                dacDisplayLimits["deposit"] && sortedDacList && sortedDacList.filter(dac => dac.depPrice > 0).length > dacDisplayLimits["deposit"] ?
                                    <div className="m-t-20 m-b-20 user-center font-s-17">
                                        <button onClick={() => handleMoreDacList("deposit")}
                                                className="moreBt1 m-r-8 wd-70 ht-35 font-c-default m-r-8 font-s-12">More
                                        </button>
                                    </div>
                                    :
                                    null
                            }

                        </div>

                        {/*적립금*/}
                        <div style={{
                            width: "35%",
                            minHeight: "500px",
                            border: "solid",
                            margin: "10px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "4px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                height: "40px",
                                display: "flex",
                                fontSize: "14px",
                                color: "dimgrey",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                margin: "10px 0 10px 0"
                            }}>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myHtDate`)}
                                </div>
                                <div style={{
                                    width: "40%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myCont`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myPr`)}
                                </div>
                                <div style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myBal`)}
                                </div>
                            </div>
                            {sortedDacList.filter(dac => dac.ptPrice > 0).slice(0, dacDisplayLimits.point).map((dac, index) => (
                                <div key={index} style={{height: "30px", display: "flex", fontSize: "12px"}}>

                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{formatDate(dac?.regDate)}
                                    </div>
                                    <div style={{
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}><span style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}>{dac.content}</span>
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.ptPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    <div style={{
                                        width: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{dac?.ptBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            ))}

                            {/*예치금&적립금탭 적립금 더보기버튼*/}
                            {
                                dacDisplayLimits["point"] && sortedDacList && sortedDacList.filter(dac => dac.ptPrice > 0).length > dacDisplayLimits["point"] ?
                                    <div className="m-t-20 m-b-20 user-center font-s-17">
                                        <button onClick={() => handleMoreDacList("point")}
                                                className="moreBt1 m-r-8 wd-70 ht-35 font-c-default m-r-8 font-s-12">More
                                        </button>
                                    </div>
                                    :
                                    null
                            }

                        </div>

                        {/*쿠폰*/}
                        <div style={{
                            width: "30%",
                            minHeight: "500px",
                            border: "solid",
                            margin: "10px",
                            borderWidth: "thin 1px",
                            borderColor: "#EAEAEA",
                            borderRadius: "4px"
                        }}>
                            <div style={{
                                borderBottom: "solid",
                                height: "40px",
                                display: "flex",
                                fontSize: "14px",
                                color: "dimgrey",
                                borderWidth: "thin 1px",
                                borderColor: "#EAEAEA",
                                margin: "10px 0 10px 0"
                            }}>
                                <div style={{
                                    width: "25%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myHtDate`)}
                                </div>
                                <div style={{
                                    width: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myCpNm`)}
                                </div>
                                <div style={{
                                    width: "25%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>{t(`myPr`)}
                                </div>
                            </div>
                            {sortedDacList.filter(dac => dac.cpPrice > 0).slice(0, dacDisplayLimits.coupon).map((dac, index) => (
                                <div key={index} style={{height: "30px", display: "flex", fontSize: "12px"}}>

                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>{formatDate(dac?.regDate)}
                                    </div>
                                    <div style={{
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}><span style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        wordBreak: "break-all"
                                    }}>{dac.cpNm}</span>
                                    </div>
                                    <div style={{
                                        width: "25%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>{(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.cpPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            ))}

                            {/*예치금&적립금탭 쿠폰 더보기버튼*/}
                            {
                                dacDisplayLimits["coupon"] && sortedDacList && sortedDacList.filter(dac => dac.cpPrice > 0).length > dacDisplayLimits["coupon"] ?
                                    <div className="m-t-20 m-b-20 user-center font-s-17">
                                        <button onClick={() => handleMoreDacList("coupon")}
                                                className="moreBt1 m-r-8 wd-70 ht-35 font-c-default m-r-8 font-s-12">More
                                        </button>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </div>
        },
    ];

    useEffect(() => {
        tabs.forEach((tab) => {
            if (tab.label === t(`myTab1`)) {
                setOrderTypeSelectCommonValue(t('orderType'))
                setOrderStSelectCommonValue(t(`state`));
                setPeriodSelectCommonValue(t('myPrd1'))
            } else if (tab.label === t(`myTab2`)) {
                setPayStSelectCommonValue(t(`state`));
                setPayPeriodSelectCommonValue(t('myPrd1'))
            } else if (tab.label === t(`myTab3`)) {
                setDacPeriodSelectCommonValue(t('myPrd1'))
            }
        })
    }, [i18n.language]);

    return (
        <div className="mypgContainer">
            {/*주문현황*/}
            <div style={{height: "auto"}}>
                <div style={{display: "flex", alignItems: "flex-start", padding: "10px 0"}}>
                    <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                    <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`myOrdPc`)}</p>
                </div>
                <div className="font-t-cont" style={{
                    backgroundColor: "#FAFAFA",
                    borderRadius: "6px",
                    display: "flex",
                    marginTop: "20px",
                    padding: "70px",
                }}>
                    <div style={{
                        width: "30%",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow: "0 0 12px 6px rgba(0,0,0,0.03)"
                    }}>
                        <div style={{
                            height: "120px",
                            display: "flex",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                        }}><img alt="" src={mypgOrder} style={{width: "80px", height: "80px"}}/></div>
                        <div style={{
                            height: "50px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#5b5b5b",
                        }}>{t(`myOrdPcHeader1`)}
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS00")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer",
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc1`)}</p> {/*입고대기*/}
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                style={{color: processData.OS00 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS00 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS01")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc2`)}</p> {/*결제대기*/}
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS01 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS01 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS02")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc3`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS02 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS02 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS03")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc4`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS03 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS03 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS04")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc5`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS04 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS04 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS05")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc6`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS05 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS05 || 0}
                            </p>
                        </div>
                    </div>
                    <div style={{
                        width: "5%",
                        color: "#5b5b5b",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <i style={{
                            fontSize: "25px",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center"
                        }}><AiOutlineDoubleRight style={{color: "#cbcbcc"}}/></i>
                    </div>
                    <div style={{
                        width: "30%",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow: "0 0 12px 6px rgba(0,0,0,0.03)"
                    }}>
                        <div style={{
                            height: "120px",
                            display: "flex",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center"
                        }}><img alt="" src={mypgDelivery} style={{width: "80px", height: "80px"}}/></div>
                        <div style={{
                            height: "50px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#5b5b5b"
                        }}>{t(`myOrdPcHeader2`)}
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS06")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc7`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS06 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS06 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS07")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc8`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS07 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS07 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("OS08")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc9`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS08 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS08 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS0")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc10`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS09 + processData.PS0 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS09 + processData.PS0 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS1")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc11`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS10 + processData.PS1 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS10 + processData.PS1 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS2")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc12`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS11 + processData.PS2 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS11 + processData.PS2 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS3")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer",
                            marginBottom: "20px"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc13`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                                  style={{color: processData.OS12 + processData.PS3 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS12 + processData.PS3 || 0}
                            </p>
                        </div>
                    </div>
                    <div style={{
                        width: "5%",
                        color: "#5b5b5b",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{fontSize: "20px", display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <img style={{width: "20px", height: "20px", opacity: "0.4"}} alt="" src={mypgReturnIcon}/>
                        </div>
                    </div>
                    <div style={{
                        width: "30%",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow: "0 0 12px 6px rgba(0,0,0,0.03)"
                    }}>
                        <div style={{
                            height: "120px",
                            display: "flex",
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center"
                        }}><img alt="" src={mypgReturn} style={{width: "80px", height: "80px"}}/></div>
                        <div style={{
                            height: "50px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#5b5b5b"
                        }}>{t(`myOrdPcHeader3`)}
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS4")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc14`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                               style={{color: processData.OS13 + processData.PS4 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS13 + processData.PS4 || 0}
                            </p>
                        </div>
                        <div onClick={() => selectNoDateTotalOrderList("PS5")} style={{
                            width: "240px",
                            margin: "auto",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "#5b5b5b",
                            cursor: "pointer"
                        }}>
                            <p className="ht-100pc display-flex just-cont-fstart align-center font-s-15">{t(`myPc15`)}</p>
                            <p className="font-b font-s-17 ht-100pc display-flex just-cont-center align-center"
                               style={{color: processData.OS14 + processData.PS5 > 0 ? "#FFAF34" : "#5b5b5b"}}>
                                {processData.OS14 + processData.PS5 || 0}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="tab-menu font-t-cont">
                        <div className="tabs">
                            {tabs.map((tab, index) => (
                                <button
                                    key={index}
                                    className={`tab ${index === activeTab ? 'active' : ''}`}
                                    onClick={() => {
                                        // 나중에 주문서선택하면 주문서1개월, 결제내역 1개월, 예&적 1개월, 결제내역 선택하면 나머지 1개월, 예&적 선택하면 나머지 1개월 기본세팅 ㄱㄱ
                                        if (tab.label === t(`myTab1`)) {
                                            setPeriodSelectCommonValue(t(`myPrd1`));
                                            setOrderTypeSelectCommonValue(t('orderType'))
                                            setOrderStSelectCommonValue(t(`state`));
                                        }
                                        if (tab.label === t(`myTab2`)) {
                                            setPayPeriodSelectCommonValue(t(`myPrd1`))
                                            setPayStSelectCommonValue(t(`state`));
                                        }
                                        if (tab.label === t(`myTab3`)) {
                                            setDacPeriodSelectCommonValue(t(`myPrd1`))
                                        }

                                        setActiveTab(index);
                                    }}
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                        <div className="content">{tabs[activeTab].content}</div>
                    </div>
                </div>
            </div>
            {deliveryDetailSt ?
                <DeliveryPriceDetail deliveryDetailSt={deliveryDetailSt} deliveryDetailFalseFn={deliveryDetailFalseFn}
                                     selectedShip={payShipco}
                                     paySeqnum={paySeqnum} updateAppRecinfo={updateAppRecinfo}
                                     updatePayinfoList={updatePayinfoList} pathComponent={pathComponent}/>
                :
                isLoading ?
                    <CommonLoading/>
                    :
                    integPayDetailPopupSt ?
                        <IntegDetailPopup integDetailPopupClose={integDetailPopupClose}
                                          integPaySeqnum={integPaySeqnum}/>
                        :
                        downNoSelectSt ?
                            <MsgCheckModal downNoSelectSt={downNoSelectSt} closeDownNoSelect={closeDownNoSelect}/>
                            :
                            null
            }
            <TopBtn/>
        </div>
    )
}

export default withAuth(UserMyPg);
