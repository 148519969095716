import '../../css/mobile/MUserReviewDetailPg.css';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import axios from '../../services/axiosInstance';
import profileImage from "../../assets/img/profileDefaultImg.png";
import DOMPurify from "dompurify";
import commonHeader from "../../assets/img/commonHeader.png";
import MHeader from "../../components/mobile/MHeader";
import reviewComment from "../../assets/img/reviewComment.png";
import defaultImg from "../../assets/img/profileDefaultImg.png";

const MUserReviewDetailPg = () => {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const {seqNum} = useParams();

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response)=> {
                setMemberInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
        }
    }, [SSAT, LCAT])

    const [reviewData, setReviewData] = useState([]);
    const [reviewProfileImg, setReviewProfileImg] = useState(profileImage)
    const [reviewList, setReviewList] = useState([]);
    const [reviewCommentList, setReviewCommentList] = useState([]);

    const selectReviewData = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setReviewData(response.data);
                const isDefaultImg = response && response.data && response.data.memberDTO && response.data.memberDTO.profileDTO && response.data.memberDTO.profileDTO.filename;
                setReviewProfileImg(
                    isDefaultImg ?
                        "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data.memberDTO.profileDTO.filename
                        :
                        profileImage
                );
                setReviewList(response.data.reviewPhotoDTO)
                setReviewCommentList(response.data.reviewCommentDTO)
            })

    useEffect(() => {
        if (seqNum) {
            selectReviewData();
        }
    }, [seqNum])



    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })
    const sanitizedContent = useSanitizedHTML(reviewData?.content);

    const textareaRef = useRef(null);
    const [reviewCommentText, setReviewCommentText] = useState('');

    const handleCommentTextChange = (e) => {
        setReviewCommentText(e.target.value);
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = Math.min(textarea.scrollHeight, 200) + 'px';
        }
    }, [reviewCommentText])

    const saveReviewComment = () =>
        axios({
            method: 'post',
            url: '/review/saveReviewComment',
            params: {
                reviewSeqNum: reviewData.seqNum,
                memberMbNum: memberInfo.mbNum,
                content: reviewCommentText
            }
        })
            .then((response) => {
                const newComment = response.data;
                const updatedComments = [...reviewCommentList, newComment];
                setReviewCommentList(updatedComments);
                setReviewCommentText('');
            })
            .catch((response) => {
                console.log("코멘트 등록실패")
            })

    const sanitizeHTML = (rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    };

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    return (
        <div className='m-a-user-review-detail-pg-container font-t-cont'>
            <MHeader title={t(`rvdtHeader`)} url={"/UserReviewPg"}/>
            <div className='m-a-user-review-detail-sub-title'>
                <img className='m-a-user-review-detail-sub-title-icon' src={commonHeader} alt=''/>
                <div className='m-a-user-review-detail-sub-title-text'>{t(`rvdtHeader`)}</div> {/*후기상세*/}
            </div>
            <div className='m-a-user-review-detail-divider'>&nbsp;</div>
            <div className='m-a-user-review-detail-review-info-frame'>
                <img className='m-a-user-review-detail-review-info-profile' src={reviewProfileImg} alt='profileImg'/>
                <div className='m-a-user-review-detail-review-user'>
                    <div className='m-a-user-review-detail-review-user-name'>{reviewData?.memberDTO?.userName}</div>
                    <div className='m-a-user-review-detail-review-sub'>[{reviewData?.country}-{reviewData?.shipco}]
                    </div>
                    <div className='m-a-user-review-detail-review-title'>{reviewData?.title}</div>
                </div>
            </div>
            <div className='m-a-user-review-detail-content' dangerouslySetInnerHTML={{__html: sanitizedContent}}/>
            {reviewList.map((photo, index) => (
                <img key={index} className="m-a-user-review-detail-img" alt='reviewImg'
                     src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + photo.fileNm}/>
            ))}
            <div className='m-a-user-review-detail-comment-frame'>
                <div className='m-a-user-review-detail-comment-title'>
                    <img className='m-a-user-review-detail-comment-title-icon' src={reviewComment} alt='CommentImage'/>
                    <div className='m-a-user-review-detail-comment-title-text'>{t(`rvdtComt`)}</div> {/*댓글*/}
                    <div className='m-a-user-review-detail-comment-title-length'>{reviewCommentList.length}</div>
                </div>
                <div className='m-a-user-review-detail-divider'/>
                {reviewCommentList.map((comment, index) => (
                    <div className='m-a-user-review-detail-comment-item' key={index}>
                        <div className='m-a-user-review-detail-comment-user'>
                            <div className='m-a-user-review-detail-comment-user-left'>
                                <img className='m-a-user-review-detail-comment-user-profile'
                                     src={comment?.memberDTO?.profileDTO?.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/"
                                         + comment?.memberDTO?.profileDTO?.filename : defaultImg}
                                     alt='reviewImg'/>
                                <div
                                    className='m-a-user-review-detail-comment-user-name'>{comment?.memberDTO?.userName}</div>
                            </div>
                            <div
                                className='m-a-user-review-detail-comment-user-right'>{formatDate(comment.regDate)}</div>
                        </div>
                        <div className='m-a-user-review-detail-comment-content'
                             dangerouslySetInnerHTML={{__html: sanitizeHTML(comment?.content)}}></div>
                    </div>
                ))}
            </div>
            {(LCAT || SSAT) && (
                <div className={'m-a-user-review-comment-box-write'}>
                    <textarea className={'m-a-user-review-comment-box-input'}
                              ref={textareaRef}
                              spellCheck="false"
                              value={reviewCommentText}
                              maxLength={5000}
                              onChange={handleCommentTextChange}
                              onKeyDown={(e) => {
                                  if (e.key === 'Enter' && !e.shiftKey) {
                                      e.preventDefault();
                                      saveReviewComment();
                                  }
                              }}
                              placeholder={''}/>
                    <div className={'m-a-user-review-comment-box-button'}
                         onClick={saveReviewComment}>{t(`rvdtBt`)}</div>
                </div>
            )}
        </div>
    )
}

export default MUserReviewDetailPg;
