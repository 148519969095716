import React, {useEffect, useState} from 'react';
import '../../css/web/AdminReviewPg.css';
import axios from '../../services/axiosInstance';
import {useNavigate} from "react-router-dom";
import {MdKeyboardArrowDown} from "react-icons/md";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminReviewPg() {

    /** region DataTable */

    const [reviewList, setReviewList] = useState([]);

    /** endregion */

    /** region Function */

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [reviewList.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (reviewList.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    const navigate = useNavigate();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};

    useEffect(() => {
        const today = new Date();
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(oneMonthAgo));
        setEndDate(formatDateString(today));
    }, []);

    /** endregion */

    /** region Event */

    const selectAllReviewList = (() => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');
        axios({
            method: 'get',
            url: '/review/selectAllReviewBetDates',
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setReviewList(sortedData);
            })
            .catch((response) => {
                console.log("리뷰조회실패")
            })
    });

    useEffect(() => {
        if (startDate && endDate)
        selectAllReviewList();
    }, [startDate, endDate])

    const moveToDetail = ((seqNum) => {
        navigate(`/btcorpad/adminreview-detail/${seqNum}`);
    })

    /** endregion */

    return (
        <div className="AdminReviewPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">이용후기</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "45px"}}><p>번호</p></div>
                            <div style={{width: "450px"}}><p>제목</p></div>
                            <div style={{width: "230px"}}><p>작성자</p></div>
                            <div style={{width: "130px"}}><p>일자</p></div>
                        </div>
                        {reviewList.slice(0, displayLimit).map((rv, i) => (
                            <div key={i} className="div-tb-body ht-70 c-point" onClick={()=> moveToDetail(rv.seqNum)}>
                                <div style={{width: "45px"}}><p className="c-point">{i+1}</p></div>
                                <div style={{width: "450px"}}><p className="c-point">{rv.title}</p></div>
                                <div style={{width: "230px"}}><p className="c-point">{rv.memberDTO.userName}({rv.memberDTO.id})</p></div>
                                <div style={{width: "130px"}}><p className="c-point">{rv?.regDate}</p></div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && reviewList.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminReviewPg
