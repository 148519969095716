import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from '../../services/axiosInstance';
import maCateImg from "../../assets/img/m-a-cate.png";
import ApexCharts from "react-apexcharts";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import {TbRefresh} from "react-icons/tb";

function MAdminSalesStatusPg() {

    /** region DataTable */

    const [salesData, setSalesData] = useState([]);
    const [salesChartData, setSalesChartData] = useState([]);

    /** endregion */

    /** region Function */

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };

    const purchaseSeries = salesChartData.purchaseData || [];
    const deliverySeries = salesChartData.deliveryData || [];

    const stateProd = {
        series: [{
            name: "구매비용 & 수수료",
            data: purchaseSeries
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: ['#FF9924'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '70%',
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '12px',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
                formatter: function (value) {
                    return "\u20A9 " + value.toLocaleString();
                }
            },
            xaxis: {
                categories: ['장바구니', '구매대행', '오프라인', '이체대행', '국내배송', '총기타비용', '총수수료'],
                min: 0,
                max: Math.ceil(Math.max(...purchaseSeries) * 1.2),
                tickAmount: 3,
                labels: {
                    formatter: function (value) {
                        return `${value.toLocaleString()}`;
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Pretendard-Regular, sans-serif',
                        colors: '#333333'
                    }
                },
                title: {
                    text: '단위(\u20A9)',
                    style: {
                        color: '#bebebe',
                        fontSize: '11px',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    }
                }
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return "\u20A9 " + value.toLocaleString();
                    }
                }
            },
            title: {
                text: '구매비용 & 수수료',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333333',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                }
            }
        },
    };

    const stateDel = {
        series: [{
            name: "배송비 & 예적쿠사용",
            data: deliverySeries
        }],
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            colors: ['#ffc376'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '70%',
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '12px',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                },
                formatter: function (value) {
                    return "\u20A9 " + value.toLocaleString();
                }
            },
            xaxis: {
                categories: ['Fedex', 'Fedex-E','UPS', 'UPS-E', 'EMS', 'EMSP', 'K-Packet', 'Small-Packet', 'Sea', '국내배송', '예치금사용', '적립금사용', '쿠폰사용', '앱할인'],
                tickAmount: 3,
                labels: {
                    formatter: function (value) {
                        return `${value.toLocaleString()}`;
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Pretendard-Regular, sans-serif',
                        colors: '#333333'
                    }
                },
                title: {
                    text: '단위(\u20A9)',
                    style: {
                        color: '#bebebe',
                        fontSize: '11px',
                        fontFamily: 'Pretendard-Regular, sans-serif'
                    }
                }
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return "\u20A9 " + value.toLocaleString();
                    }
                }
            },
            title: {
                text: '배송비 & 예적쿠사용',
                align: 'left',
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333333',
                    fontFamily: 'Pretendard-Regular, sans-serif'
                }
            }
        },
    };

    useEffect(() => {
        const today = new Date();
        const oneYearAgo = new Date(today);
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(today));
        setEndDate(formatDateString(today));
    }, []);

    const resetToToday = () => {
        const today = new Date();
        const formatDateString = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formattedToday = formatDateString(today);
        setStartDate(formattedToday);
        setEndDate(formattedToday);
    };

    useEffect(() => {
        if (startDate && endDate) {
            selectOutsales();
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (Object.keys(salesData).length) {
            const purchaseTotal =
                salesData.cartPrice +
                salesData.ordPrice +
                salesData.offPrice +
                salesData.trPrice +
                salesData.domPrice +
                salesData.ttEtcPrice +
                salesData.ttFee;

            const deliveryTotal =
                salesData.ttFedexPrice +
                salesData.ttFedexEcoPrice +
                salesData.ttUpsPrice +
                salesData.ttUpsEcoPrice +
                salesData.ttEmsPrice +
                salesData.ttEmsPrePrice +
                salesData.ttKPackPrice +
                salesData.ttAirSPrice +
                salesData.ttSeaPrice +
                salesData.ttDomPrice +
                salesData.ttEtcPrice +
                salesData.dpcPrice +
                salesData.appSale;

            const chartData = [
                { sDate: "1월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "2월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "3월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "4월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "5월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "6월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "7월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "8월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "9월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "10월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "11월", prod: purchaseTotal, del: deliveryTotal },
                { sDate: "12월", prod: purchaseTotal, del: deliveryTotal }
            ];

            setSalesChartData(chartData);
        }
    }, [salesData]);

    /** endregion */

    /** region Event */

    const selectOutsales = () => {
        const formattedStartDate = startDate.replace(/-/g, '');
        const formattedEndDate = endDate.replace(/-/g, '');

        axios.get('/outsales/selectOutsales', {
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
        }).then((response) => {
            setSalesData(response.data);
            setSalesChartData(response.data);
        }).catch((error) => {
            console.error("데이터 조회 실패:", error);
        });
    };

    useEffect(() => {
        handleDateRange(12);
    }, []);

    const handleDateRange = (months) => {
        const today = new Date();
        const pastDate = new Date();
        pastDate.setMonth(today.getMonth() - months);

        const formatDateString = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(pastDate));
        setEndDate(formatDateString(today));
    };

    useEffect(() => {
        if (salesData) {
            const purchaseData = [
                salesData.cartPrice || 0,
                salesData.ordPrice || 0,
                salesData.offPrice || 0,
                salesData.trPrice || 0,
                salesData.domPrice || 0,
                salesData.ttEtcPrice || 0,
                salesData.ttEtcFee || 0
            ];

            const deliveryData = [
                salesData.ttFedexPrice || 0,
                salesData.ttFedexEcoPrice || 0,
                salesData.ttUpsPrice || 0,
                salesData.ttUpsEcoPrice || 0,
                salesData.ttEmsPrice || 0,
                salesData.ttEmsPrePrice || 0,
                salesData.ttKPackPrice || 0,
                salesData.ttAirSPrice || 0,
                salesData.ttSeaPrice || 0,
                salesData.ttDomPrice || 0,
                salesData.useDeposit || 0,
                salesData.usePoint || 0,
                salesData.useCoupon || 0,
                salesData.appSale || 0
            ];

            setSalesChartData({ purchaseData, deliveryData });
        }
    }, [salesData]);

    // region slide-card

    useEffect(() => {
        const sliderWrapper = document.querySelector('.m-a-slider-wrapper');
        if (!sliderWrapper) {
            return;
        }

        let isDragging = false;
        let startPosition = 0;
        let currentTranslate = 0;
        let prevTranslate = 0;
        let animationID = 0;
        let currentIndex = 0;

        const slides = document.querySelectorAll('.m-a-card-slider');
        const slideWidth = sliderWrapper.clientWidth;
        const indicators = document.querySelectorAll('.m-a-indicator');

        sliderWrapper.addEventListener('touchstart', touchStart);
        sliderWrapper.addEventListener('touchend', touchEnd);
        sliderWrapper.addEventListener('touchmove', touchMove);

        function touchStart(event) {
            isDragging = true;
            startPosition = event.touches[0].clientX;
            animationID = requestAnimationFrame(animation);
        }

        function touchEnd() {
            isDragging = false;
            cancelAnimationFrame(animationID);

            const movedBy = currentTranslate - prevTranslate;

            if (movedBy < -50 && currentIndex < slides.length - 1) {
                currentIndex += 1;
            } else if (movedBy > 50 && currentIndex > 0) {
                currentIndex -= 1;
            }

            setPositionByIndex();
            updateIndicators();
        }

        function touchMove(event) {
            if (isDragging) {
                const currentPosition = event.touches[0].clientX;
                currentTranslate = prevTranslate + currentPosition - startPosition;
            }
        }

        function animation() {
            setSliderPosition();
            if (isDragging) requestAnimationFrame(animation);
        }

        function setSliderPosition() {
            sliderWrapper.style.transform = `translateX(${currentTranslate}px)`;
        }

        function setPositionByIndex() {
            currentTranslate = currentIndex * -slideWidth;
            prevTranslate = currentTranslate;
            setSliderPosition();
        }

        function updateIndicators() {
            indicators.forEach((indicator, index) => {
                indicator.classList.toggle('active', index === currentIndex);
            });
        }

        updateIndicators();

        return () => {
            sliderWrapper.removeEventListener('touchstart', touchStart);
            sliderWrapper.removeEventListener('touchend', touchEnd);
            sliderWrapper.removeEventListener('touchmove', touchMove);
        };
    }, []);

    // endregion slide-card

    /** endregion */

    return (
        <div id="m-a-salesSt-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">매출현황</p>
            </div>
            <div className="m-a-card m-b-15">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event-combo just-cont-sb">
                        <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        {/*<button onClick={() => handleDateRange(6)} className="admin-btn-B wd-35 ht-30 font-s-13 btn-click-event">6M</button>*/}
                        <button onClick={() => handleDateRange(12)} className="admin-btn-B wd-35 ht-30 font-s-13 btn-click-event">1Y</button>
                        <TbRefresh onClick={resetToToday} className="c-point font-s-24 btorage-hover m-t-2 font-c-default"/>
                    </div>
                </div>
            </div>
            <div className="m-a-main-slider ht-600">
                <div className="m-a-slider-wrapper ht-100pc">
                    <div className="m-a-card m-a-dash-progress-card m-a-card-slider">
                        <div className="wd-100pc ht-550">
                            <ApexCharts options={stateProd.options} series={stateProd.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                    </div>
                    <div className="m-a-card m-a-dash-progress-card m-a-card-slider">
                        <div className="wd-100pc ht-550">
                            <ApexCharts options={stateDel.options} series={stateDel.series} type="bar" width={"100%"} height={"100%"} />
                        </div>
                    </div>
                </div>
                <div className="m-a-indicators">
                    <span className="m-a-indicator"></span>
                    <span className="m-a-indicator"></span>
                </div>
            </div>
        </div>
    );
}

export default MAdminSalesStatusPg
