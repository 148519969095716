import React, {useEffect} from "react";
import {Image} from "react-bootstrap";

import nobankPig from '../../assets/img/nobankPig.png'
import {useLocation, useNavigate} from "react-router-dom";
import withAuth from '../../auth/UserRouteAuth'
import {useTranslation} from "react-i18next";
import TagManager from 'react-gtm-module';

function UserPayWaitPg() {

    const { t } = useTranslation();
    const location = useLocation();
    const data = location.state.data;
    const navigate = useNavigate();

    const orderDetailPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    useEffect(() => {
        if (data) {
            const dataLayer = {
                event: 'purchase',
                transaction_id: data.orderNumber,
                value: data?.lastPrice, // 원화
                currency: 'KRW',
            }
            TagManager.dataLayer({ dataLayer });
        }
    }, [data]);

    return(
        <div className="display-flex flex-column just-cont-center text-center ht-100vh font-t-cont">
            <div className="display-flex flex-column align-center">
                <div style={{border:"solid 1px lightgray", width:"500px", padding:"20px"}}>
                    <div style={{height:"200px", padding:"20px", borderBottom:"solid 1px lightgray", marginTop:"-20px"}}>
                        <div style={{height:"50%", alignItems:"flex-end", justifyContent:"center"}}>
                            <div style={{width:"100%", height:"100%", textAlign:"center"}}>
                                <Image style={{width:"50px", height:"50px", marginTop:"8%"}} src={nobankPig}/>
                            </div>
                        </div>
                        <div style={{height:"30%", alignItems:"center"}}>

                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"flex-start", paddingTop:"20px"}}>
                                <p style={{fontSize:"18px"}}>
                                    {/*결제신청이 완료되었습니다*/}
                                    {data?.payWay === "PM01" ? t('pwHeader4') : t(`pwCont1`)}
                                </p>
                            </div>

                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"flex-start", paddingTop:"5px"}}>
                                <span style={{fontSize:"14px", color:"dimgrey"}}>
                                    {/*원활한 확인을 위해 24시간 내에 입금해주세요*/}
                                    {data?.payWay === "PM01" ? t('pwCont3') : t(`pwCont2`)}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div style={{padding:"10px", marginTop:"10px", marginBottom:"-15px"}}>
                        <div style={{height:"35px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p style={{fontSize:"14px", display:"flex", margin:"0"}}>{t(`myTab1`)}</p>
                            <p style={{fontSize:"14px", color:"black", display:"flex", fontWeight:"bold", margin:"0"}}>{data && data?.orderNumber}</p>
                        </div>
                        {/*payWay가 PM00이면 무통장입금, PM02면 위챗, PM03이면 알리 하드코딩*/}
                        <div style={{height:"35px",  display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p style={{fontSize:"14px", display:"flex", margin:"0"}}>{t(`modPty`)}</p>
                            { data && data?.payWay === "PM00" ?
                            <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>{t(`modNoBank`)}</p>
                                :
                                data?.payWay === "PM02" ?
                                    <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>WechatPay</p>
                                    :
                                    data?.payWay === "PM03" ?
                                        <p style={{fontSize:"14px", color:"dimgrey", display:"flex", margin:"0"}}>AliPay</p>
                                        :
                                        data?.payWay === "PM01" ?
                                            <p style={{
                                                fontSize: "14px",
                                                color: "dimgrey",
                                                display: "flex",
                                                margin: "0"
                                            }}>Paypal</p>
                                            :
                                            null
                            }
                        </div>

                        {data && data?.payWay === "PM00" ?
                            <div style={{
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <p style={{fontSize: "14px", display: "flex"}}>{t(`pwHeader1`)}</p>
                                <p style={{fontSize: "14px", color: "dimgrey", display: "flex"}}>{t(`pwAcctNm`)}</p>
                            </div>
                            :
                            null
                        }
                        {/*payWay가 PM00이면 신한계좌번호, PM02면 위챗ID, PM03이면 알리ID*/}
                        {data && data?.payWay === "PM00" ?
                            <div style={{
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <p style={{fontSize: "14px", display: "flex"}}>{t(`pwHeader2`)}</p>
                                <p style={{
                                    fontSize: "14px",
                                    color: "dimgrey",
                                    display: "flex",
                                    margin: "0"
                                }}>{t(`pwAcctNum`)}</p>
                            </div>
                            :
                            data?.payWay === "PM02" ?
                                <div style={{
                                    height: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                    <p style={{fontSize: "14px", display: "flex"}}>Weixin ID</p>
                                    <p style={{
                                        fontSize: "14px",
                                        color: "dimgrey",
                                        display: "flex",
                                        margin: "0"
                                    }}>lfamily-help</p>
                                </div>
                                :
                                data?.payWay === "PM03" ?
                                    <div style={{
                                        height: "35px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <p style={{fontSize: "14px", display: "flex", margin: "0"}}>{t(`pwHeader2`)}</p>
                                        <p style={{
                                            fontSize: "14px",
                                            color: "dimgrey",
                                            display: "flex",
                                        }}>13165072023</p>
                                    </div>
                                    :
                                    null
                        }
                        <div style={{height:"35px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p style={{fontSize:"14px", display:"flex"}}>{t(`pcWon`)}</p> {/*최종원화금액*/}
                            <p style={{fontSize:"14px", color:"black", display:"flex", fontWeight:"bold"}}>
                                &#8361; {data && data?.lastPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                        </div>
                        {/*payWay가 PM02나 PM03이면 실행*/}
                        { data && data?.payWay === "PM02" || data?.payWay === "PM03" || data?.payWay === "PM01" ?
                        <div style={{height:"35px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <p style={{fontSize:"14px", display:"flex"}}>
                                {(data?.payWay === "PM02" || data?.payWay === "PM03") ? t(`pwCny`) : t(`pcFor`)}
                            </p>
                            <div className="user-center">
                            <p style={{fontSize:"14px", color:"black", display:"flex", fontWeight:"bold", alignItems:"center"}}>{data && data?.lastForPrice}</p>
                                <p style={{marginLeft:"3px", color:"dimgrey", fontWeight:"lighter", fontSize:"13px", paddingTop:"2px"}}>{data && data?.forCurrency}</p>
                            </div>
                        </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div style={{width:"500px",  height:"100px", textAlign:"center", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <button onClick={ ()=> navigate("/UserMyPg")} className="orderDeleteBt" style={{
                        width: "49%",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "16px"
                    }}>{t(`hdMypg`)}</button>
                    <button onClick={orderDetailPgMove(data.orderNumber)} className="colorFullBt" style={{
                        width: "49%",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "16px",
                    }}>{t(`modHeader`)}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default withAuth(UserPayWaitPg)
