import React, {useEffect, useState} from 'react';
import '../../css/mobile/MAdmin.css';
import axios from '../../services/axiosInstance';
import maCateImg from "../../assets/img/m-a-cate.png";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import ApexCharts from "react-apexcharts";

function MAdminInflowStatusPg() {

    /** region DataTable */

    const [searchData, setSearchData] = useState([]);
    const [socialData, setSocialData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => { setStartDate(e.target.value); };
    const endHandle = (e) => { setEndDate(e.target.value); };

    useEffect(() => {
        const today = new Date();
        const sixMonthsAgo = new Date(today);
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        const formatDateString = (date) => {
            // return date.toISOString().split('T')[0];
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setStartDate(formatDateString(sixMonthsAgo));
        setEndDate(formatDateString(today));
    }, []);

    const formattedStartDate = startDate.replace(/-/g, '');
    const formattedEndDate = endDate.replace(/-/g, '');

    /** endregion */

    /** region Event */

    const [stateDevice, setStateDevice] = useState({
        series: [{
            name: "Device",
            data: deviceData
        }],
        options: {
            chart: {
                type: 'pie',
            },
            labels: ['PC', 'Mobile', 'Application'],
            stroke: {
                width: 3,
                colors: ['#fff'],
            },
            tooltip: {
                enabled: true,
                theme: 'dark',
                y: {
                    formatter: (value) => `${value} users`,
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff'],
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
                dropShadow: {
                    enabled: true,
                    top: 1,
                    left: 1,
                    blur: 1,
                    opacity: 0.5,
                },
            },
            legend: {
                position: 'bottom',
                fontSize: '16px',
            },
            colors: ['#ffea7e', '#ffaf4b', '#ff7c22'],
            responsive: [{
                breakpoint: 768,
                options: {
                    chart: {
                        width: '100%'
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    useEffect(() => {
        if (startDate && endDate) {
            deviceSelect();
        }
    }, [startDate, endDate])

    const deviceSelect = () => {
        axios.get('/init/deviceInit', {headers: { Authorization: LCAT }, params: { startDate: formattedStartDate, endDate: formattedEndDate }})
            .then(response => {
                const deviceCounts = response.data;

                setDeviceData(deviceCounts);

                setStateDevice(prevState => ({
                    ...prevState,
                    series: deviceCounts
                }));
            })
            .catch(error => console.error("err123: ", error));
    };

    /** endregion */

    return (
        <div id="m-a-traffic-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">트래픽현황</p>
            </div>
            <div className="m-a-card m-b-15">
                <div className="m-a-event-frame">
                    <div className="m-a-header-event-combo just-cont-sb">
                        <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                        <label className="font-s-12 font-c-default p-t-5">1 year</label>
                    </div>
                </div>
            </div>
            <div className="m-a-card ht-450">
                <div className="wd-100pc ht-100pc">
                    <div className="m-b-20"><p className="font-t-title font-s-22">Connect Device</p></div>
                    <ApexCharts options={stateDevice.options} series={stateDevice.series} type="pie" width={"100%"} height={"100%"} />
                </div>
            </div>
        </div>
    );
}

export default MAdminInflowStatusPg
