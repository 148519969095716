import React, {useEffect, useRef, useState} from "react";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import axios from '../../services/axiosInstance';
import reviewUpload from "../../assets/img/reviewUpload.png";
import {useNavigate, useParams} from "react-router-dom";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import back from "../../assets/img/commonBack.png";
import '../../css/mobile/MAdmin.css';
import '../../css/Common.css';

function MAdminReviewModifyPg() {

    const {seqNum} = useParams();
    const navigate = useNavigate();
    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT) {
            findMember();
        }
    }, [LCAT])

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: '수취국가선택'});

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [countryErrorMsg, setCountryErrorMsg] = useState(false);
    const [shipcoErrorMsg, setShipcoErrorMsg] = useState(false);
    const [reviewErrorMsg, setReviewErrorMsg] = useState(false);


    // 여기서부터 커스텀 셀렉트
    const [countryShow, setCountryShow] = useState(false);
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(!countryShow);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setCountryShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트


    const selectCountry = (() => {
        axios({
            method: 'get',
            url: '/order/selectCountry',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const countries = response.data
                    .map(country => ({
                        value: country.countryCode,
                        label: `${country.countryNm}(${country.countryEngNm})`,
                        engName: country.countryEngNm
                    }))
                    .sort((a, b) => a.engName.localeCompare(b.engName));

                setCountryList(countries.map(country => ({
                    value: country.value,
                    label: country.label
                })));
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const selectReviewData = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewData',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
                setReviewOrdNum(response.data.orderNumber);
                setTitleText(response.data.title);
                setReviewText(response.data.content);
                setSelectedShipco(response.data.shipco);

                const countryValue = response.data.country;
                const matchingCountry = countryList && countryList.find(country => {
                    const regex = /\(([^)]+)\)/;  // countryList.label 괄호 안의 텍스트 추출을 위한 정규표현식
                    const match = country.label.match(regex);
                    return match && match[1] === countryValue;  // 괄호 내의 영문명과 countryValue 비교
                });
                setSelectedCountry(matchingCountry);

                const reviewPhotos = response.data.reviewPhotoDTO;
                if (reviewPhotos && reviewPhotos.length > 0) {
                    setReviewContainers(prev => {
                        // 기존 사진 데이터를 포함한 배열 생성
                        const updatedContainers = reviewPhotos.map(photo => ({
                            reviewImg: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + photo.fileNm,
                            reviewImgSt: true,
                            reviewImgError: false,
                            reviewImgFileOriNm: photo.fileOriNm
                        }));

                        // 마지막에 빈 폼 추가
                        updatedContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });

                        return updatedContainers;
                    });
                }
            })
            .catch(() => {
                console.log("리뷰조회실패")
            })


    useEffect(() => {
        if (seqNum && countryList.length > 0) {
            selectReviewData();
        }
    }, [countryList])


    useEffect(() => {
        selectCountry();
        bringToShipCo();
    }, [])

    const [shipcoList, setShipcoList] = useState([]); // DB데이터
    const [selectedShipco, setSelectedShipco] = useState('');

    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setShipcoList(response.data)
        })

    // 여기서부터 커스텀 셀렉트
    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipcoRef.current && !shipcoRef.current.contains(event.target)) {
                setShipcoShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [reviewOrdNum, setReviewOrdNum] = useState('');

    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [reviewText, setReviewText] = useState('');
    const handleChangeReviewText = (e) => {
        setReviewText(e.target.value);
    };


    const [reviewContainers, setReviewContainers] = useState([{ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false, reviewImgOriNm: '' }]);
    const [reviewRemoveContainers, setReviewRemoveContainers] = useState([{ reviewImg: ''}]);

    const reviewImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setReviewContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImg: URL.createObjectURL(file),
                        reviewImgSt: true,
                        reviewImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const formData = new FormData();

    const reviewImgInit = (idx, reviewImg) => () => {
        setReviewRemoveContainers(prev => [...prev, { removeImg: reviewImg }]);
        setReviewContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.reviewImgSt) {
                newContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });
            }

            return newContainers;
        });
        formData.delete('reviewImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.reviewImgSt) {
            return;
        }

        newContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedCountry.value === '' || !selectedCountry) setCountryErrorMsg(true);
        if (selectedShipco === '' || !selectedShipco) setShipcoErrorMsg(true);
        if (reviewText === '' || !reviewText) setReviewErrorMsg(true);
        return !!(titleText && selectedCountry && selectedShipco && reviewText)
    }

    const reviewModify = async () => {

        const newFormData = new FormData();
        const crEngNm = selectedCountry.label.match(/\(([^)]+)\)/);

        newFormData.append('orderNumber', reviewOrdNum);
        newFormData.append('title', titleText);
        newFormData.append('country', crEngNm[1]);
        newFormData.append('shipco', selectedShipco);
        newFormData.append('reviewText', reviewText);

        reviewContainers.forEach((container, i) => {
            if (container.file) {
                newFormData.append('reviewImg', container.file);
            } else {
                newFormData.append('reviewImg', container.reviewImg);
            }
        });

        reviewRemoveContainers.forEach((remove, i) => {
            if (remove.removeImg) {
                const filename = remove.removeImg.split('/').pop(); // URL에서 파일 이름만 추출
                newFormData.append('removeImg', encodeURIComponent(filename));
            }
        });

        return axios({
            method: 'post',
            url: '/review/reviewModify',
            data: newFormData,
            params: {
                reviewSeqNum: seqNum,
                // removeImg: removeImgFilenames
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                navigate(`/btcorpad/adminreview-detail/${seqNum}`);
            });
    }

    const valiAndSave = () => {
        if (validation()) {
            reviewModify();
        }
    }

    const pageBack = (() => {
        navigate(-1);
    })

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    return(
        <div id="m-a-rv-modi-pg-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">후기글수정</div>
            </div>
            <div className="m-a-fixed-container">
                <div className="m-t-15 display-flex flex-column">
                    <div className="m-a-input-frame ht-40 m-b-15">
                        <input className="m-a-input wd-100pc font-t-title font-s-14" type="text" placeholder="제목" value={titleText} onChange={handleChangeTitleText}/>
                        {/*<div className="JoinErrorMsg">*/}
                        {/*    {titleErrorMsg && <p className="JoinErrMsg">&nbsp;제목을 입력해주세요</p>}*/}
                        {/*</div>*/}
                    </div>
                    <div className="display-flex flex-row wd-100pc gap8 m-b-15">
                        <div className="m-a-input-frame wd-70pc ht-40">
                            <div ref={countryRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                <button className="selectParentBt" style={{width: "100%", height: "100%"}} onClick={toggleCountryShow}>
                                    <p style={{margin: "0"}}>{selectedCountry.label}</p>
                                    <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                        <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                    </span>
                                </button>
                                {countryShow ?
                                    <div style={{
                                        width:"300px",
                                        border: "solid",
                                        height: "400px",
                                        borderWidth: "thin 1px",
                                        borderColor: "lightgray",
                                        position: "absolute",
                                        top:"100%",
                                        bottom:"100%",
                                        right:"-6%",
                                        zIndex:1,
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                    }}>
                                        {countryList.map((country, index) => (
                                            <div className="selectChildBt" key={index} onClick={() => handleCountryChange(country)} style={{
                                                display: "flex", padding: "10px", justifyContent: "center", alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                            }}>{country.label}</div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {/*<div className="JoinErrorMsg">*/}
                            {/*    {countryErrorMsg && <p className="JoinErrMsg">&nbsp;국가를 선택해주세요</p>}*/}
                            {/*</div>*/}
                        </div>
                        <div className="m-a-input-frame wd-30pc">
                            <div ref={shipcoRef} style={{height: "100%", width: "100%", position: "relative"}}>
                                <button className="selectParentBt" style={{width: "100%", height: "100%"}} onClick={toggleShipcoShow}>
                                    <p style={{margin: "0"}}>{selectedShipco}</p>
                                    <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                            <i style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}><BiCaretDown/></i>
                                        </span>
                                </button>
                                {shipcoShow ?
                                    <div style={{width:"100%", border: "solid", borderWidth: "thin 1px", borderColor: "lightgray", position: "absolute", top:"100%", bottom:"100%", zIndex:1}}>
                                        {shipcoList.map((shipco, index) => (
                                            <div className="selectChildBt" key={index} value={shipco} onClick={() => handleShipcoChange(shipco)} style={{
                                                display: "flex", justifyContent: "center", alignItems: "center"
                                            }}>{shipco}</div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {/*<div className="JoinErrorMsg">*/}
                            {/*    {shipcoErrorMsg && <p className="JoinErrMsg">&nbsp;배송사를 선택해주세요</p>}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="m-a-textarea-frame m-b-15 font-s-14 font-t-cont ht-150" style={{minHeight: "auto", maxHeight: "300px"}}>
                        <textarea className="m-a-textarea-b" placeholder="내용입력" spellCheck="false" value={reviewText} onChange={handleChangeReviewText}/>
                    </div>
                    {/*<div className="JoinErrorMsg">*/}
                    {/*    {reviewErrorMsg && <p className="JoinErrMsg">&nbsp;내용을 입력해주세요</p>}*/}
                    {/*</div>*/}
                </div>
                <div>
                    <div>
                        {reviewContainers.map((container, idx) => (
                            <div key={idx} className="display-flex just-cont-center align-center border-c ht-400">
                                {!container.reviewImgSt ?
                                    <div className="display-block text-center" onClick={(e) => {
                                        const parentDiv = e.target.parentNode;
                                        const fileInput = parentDiv.querySelector('input[type="file"]');
                                        if (fileInput) fileInput.click();
                                    }}>
                                        <input type="file" accept="image/*" style={{display: "none"}} onChange={reviewImgHandle(idx)}/>
                                        <img className="wd-70 ht-70" alt="uploadImg" style={{filter: "opacity(0.3) drop-shadow(0 0 0 gray)",}} src={container.reviewImg}/>
                                        <p className="font-s-18 font-c-default">Click here!</p>
                                        {/*<div className="JoinErrorMsg">*/}
                                        {/*    {container.reviewImgError && <p className="JoinErrMsg">&nbsp;사진만 업로드해주세요😅</p>}*/}
                                        {/*</div>*/}
                                    </div>
                                    :
                                    <div className="display-flex wd-90pc ht-90pc position-relative" style={{maxHeight: "400px"}}>
                                        <img className="display-flex just-cont-center align-center wd-100pc ht-100pc" alt="reviewImg" src={container.reviewImg}/>
                                        <button className="orderItemHandleBt position-absolute wd-40 ht-40 m-a-opc-06" style={{top:"85%", left:"86%", borderRadius:"4px"}}
                                                onClick={reviewImgInit(idx, container.reviewImg)}>
                                            <i style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize:"26px"}}>
                                                <BsTrash className="display-flex just-cont-center align-center font-s-26"/>
                                            </i>
                                        </button>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-45" onClick={valiAndSave}>저장</button>
            </div>
        </div>
    )
}

export default MAdminReviewModifyPg
