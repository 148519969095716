import React, {useEffect, useRef, useState} from 'react';
import MsgSelectModal from "../web/modal/MsgSelectModal";
import axios from '../../services/axiosInstance';
import {connect} from "../../services/WebsocketService";
import back from "../../assets/img/commonBack.png";
import useTheme from "../web/layoutscommon/DarkModeStorage";
import {useNavigate} from "react-router-dom";
import '../../css/mobile/MAdmin.css';
import '../../css/Common.css';
import orderIMG from "../../assets/img/mypgPayinfo.png";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminDivideModal() {

    const LCAT = localStorage.getItem('adminToken');

    const [adminInfo, setAdminInfo] = useState([]);

    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    useEffect(() => {
        selectAdminInfo();
    }, [])

    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const cData = JSON.parse(localStorage.getItem('orderData'));
    const closeDivideModal = (() => {
        window.close();
    })

    // 기준주문서 상품총수량
    const totalQuantity = cData.productsDTO.reduce((sum, item) => {
        if (item.prodSt !== "IS12") {
            return sum + item.quantity;
        }
        return sum;
    }, 0);

    const [totalProdList, setTotalProdList] = useState([])
    const [divideProdList, setDivideProdList] = useState([]);
    const selectProdList = (() => {
        axios({
            method: 'get',
            url: '/order/selectProdList',
            params: {
                orderNumber: cData.orderNumber
            }
        })
            .then((response) => {
                let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setTotalProdList(sortedData);
            })
            .catch(() => {})
    })

    useEffect(() => {
        selectProdList();
    }, [])

    const [isAllChecked, setIsAllChecked] = useState(false);

    const handleAllCheck = (event) => {
        if (event.target.checked) {
            const allSeqNums = totalProdList.map(prod => prod.seqnum);
            setDivideProdList(allSeqNums);
        } else {
            setDivideProdList([]);
        }
        setIsAllChecked(event.target.checked);
    };

    const toggleProdSelection = (seqnum) => {
        setDivideProdList(prev => {
            const newDivideProdList = prev.includes(seqnum) ? prev.filter(num => num !== seqnum) : [...prev, seqnum];
            setIsAllChecked(newDivideProdList.length === totalProdList.length);
            return newDivideProdList;
        });
    };

    const orderDivide = (() => {
        let divideProdQueryString = divideProdList.map(item => 'divideProd=' + encodeURIComponent(item)).join('&');

        axios({
            method: 'post',
            url: `/order/orderDivide?standardOrder=${encodeURIComponent(cData.seqnum)}&${divideProdQueryString}`,
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                // 웹소켓 전송하여 사용자 마이페이지도 order 업뎃 ㄱ
                await stompClient.send("/app/orderDivideAndMerge", {}, JSON.stringify({userMbNum:cData.memberDTO.mbNum}));
                // 추후 여기에 AdminDashBoardPg.selectAllData() 조회함수 실행코드 추가

                closeDivideModal();
                navigate(-1);
            });
    });

    const [divideModalSt, setDivideModalSt] = useState(false);

    const openDivideModalShow = (() => {
        if (divideProdList.length > 0) {
            setDivideModalSt(true);
        }
    })

    const closeDivideModalShow = (() => {
        setDivideModalSt(false);
    })

    const divideModalMsg1 = "분리작업을 시작하시겠습니까?"
    const divideModalMsg2 = "진행 후 되돌릴 수 없습니다"

    const navigate = useNavigate();
    const pageBack = (() => {
        navigate(-1);
    })

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    return (
        <div id="m-a-divide-modal-container" data-theme={theme}>
            <div className="m-a-common-header">
                <img onClick={pageBack} className="m-a-backIcon" src={back} alt="back"/>
                <div className="m-a-pg-header-text font-t-head">주문서(상품)분리</div>
            </div>
            <div className="m-a-fixed-container font-t-cont">
                <div className="m-t-15">
                    <div className="m-a-card">
                        <div className="display-flex flex-column just-cont-center dark-font font-s-14">
                            <div className="display-flex flex-row align-center">
                                <img className="wd-17 ht-17 m-r-3" alt="" src={orderIMG}/>
                                <p className="font-b font-s-17">{cData.orderNumber}</p>
                            </div>
                            <div className="display-flex flex-row">
                                <p className="wd-35pc">고객명:</p>
                                <p className="wd-65pc">{cData.memberDTO.userName}</p>
                            </div>
                            <div className="display-flex flex-row">
                                <p className=" wd-35pc">수취인명:</p>
                                <p className="font-b wd-65pc">{cData.recinfoDTO.recname}</p>
                            </div>
                            <div className="display-flex flex-row font-s-14">
                                <p className="wd-35pc">국가:</p>
                                <p className="wd-65pc">{cData.recinfoDTO.countryDTO.countryNm + "[" + cData.recinfoDTO.countryDTO.countryCode + "]"}</p>
                            </div>
                            <div className="display-flex flex-row font-s-14">
                                <p className="wd-35pc">총수량:</p>
                                <p className="wd-65pc">{totalQuantity}</p>
                            </div>
                        </div>
                    </div>
                    <div className="m-a-card m-t-15">
                        <div className="m-a-tb-frame-b">
                            <div className="m-a-div-tb">
                                <div className="m-a-div-tb-head-b ht-60 font-s-12">
                                    <div className="wd-10pc">
                                        <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" checked={isAllChecked} onChange={handleAllCheck}/>
                                    </div>
                                    <div className="wd-15pc"><p>품번</p></div>
                                    <div className="wd-45pc">
                                        <p>상품명</p>
                                        <p>옵션</p>
                                    </div>
                                    <div className="wd-10pc"><p>수량</p></div>
                                    <div className="wd-20pc"><p>상태구분</p></div>
                                </div>
                                {totalProdList.map((prod, i) => (
                                    <div key={i} className="m-a-div-tb-body-b ht-70">
                                        <div className="wd-10pc">
                                            <input className="m-a-admin-input-chkBox m-0-a" type="checkbox" onChange={() => toggleProdSelection(prod.seqnum)}
                                                   checked={divideProdList.includes(prod.seqnum)}/>
                                        </div>
                                        <div className="wd-15pc"><p className="font-b font-s-14">{i + 1}</p></div>
                                        <div className="wd-45pc font-s-12">
                                            <p className="m-a-text-cut">{truncateText(prod.name, 18)}</p>
                                            <p className="m-a-text-cut">{truncateText(prod.option, 18)}</p>
                                        </div>
                                        <div className="wd-10pc"><p className="font-s-14">{prod.quantity}</p></div>
                                        <div className="wd-20pc font-s-14">
                                            <p className="font-b">
                                                {prod?.prodSt === "IS00" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>입고대기</p>
                                                ) : prod?.prodSt === "IS01" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제대기(상품)</p>
                                                ) : prod?.prodSt === "IS02" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제중(상품)</p>
                                                ) : prod?.prodSt === "IS03" ? (
                                                    <p style={{margin:"0", color: "#FFB378", fontSize:"13px"}}>결제완료(상품)</p>
                                                ) : prod?.prodSt === "IS04" ? (
                                                    <p style={{margin:"0", color: "#FF8600", fontSize:"13px"}}>구매완료</p>
                                                ) : prod?.prodSt === "IS05" ? (
                                                    <p style={{margin:"0", color: "#6EDA60", fontSize:"13px"}}>센터도착</p>
                                                ) : prod?.prodSt === "IS06" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>패킹중</p>
                                                ) : prod?.prodSt === "IS07" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제대기(배송)</p>
                                                ) : prod?.prodSt === "IS08" ? (
                                                    <p style={{margin:"0", color: "#98B0D4", fontSize:"13px"}}>결제중(배송)</p>
                                                ) : prod?.prodSt === "IS09" ? (
                                                    <p style={{margin:"0", color: "#FFB378", fontSize:"13px"}}>결제완료(배송)</p>
                                                ) : prod?.prodSt === "IS10" ? (
                                                    <p style={{margin:"0", color: "#979696", fontSize:"13px"}}>출고완료</p>
                                                ) : prod?.prodSt === "IS11" ? (
                                                    <p style={{margin:"0", color: "#FF8F62", fontSize:"13px"}}>반송중</p>
                                                ) : prod?.prodSt === "IS12" ? (
                                                    <p style={{margin:"0", color: "#FF8F62", fontSize:"13px"}}>신청취소</p>
                                                ) : null}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-a-m-fixed-bt-frame">
                <button className="m-a-colorBt-radius-n m-a-colorBt-text wd-100pc ht-50" onClick={openDivideModalShow}>확인</button>
            </div>
            { divideModalSt ?
                <MsgSelectModal divideModalSt={divideModalSt} orderDivide={orderDivide} closeDivideModalShow={closeDivideModalShow}
                                divideModalMsg1={divideModalMsg1} divideModalMsg2={divideModalMsg2}/>
                :
                null
            }
        </div>
    );
}

export default MAdminDivideModal
