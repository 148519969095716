import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import '../../css/mobile/MAdminAlarmListPg.css';
import commonHeader from '../../assets/img/commonHeader.png'
import commonClose from '../../assets/img/commonClose.png'
import { useLocation } from 'react-router-dom';
import axios from '../../services/axiosInstance';
import maCateImg from "../../assets/img/m-a-cate.png";

function MAdminAlarmListPg() {
    const {type} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const LCAT = localStorage.getItem('adminToken');

    const [orderData, setOrderData] = useState([]);
    const [payData, setPayData] = useState([]);
    const [depositData, setDepositData] = useState([]);
    const [reviewData, setReviewData] = useState([]);

    useEffect( () => {
        updateData();
        if (type === "order") {
            deleteAlarmOrder();
        } else if (type === "pay") {
            deleteAlarmPay();
        } else if (type === "deposit") {
            deleteAlarmDeposit();
        }
    }, [location]);

    const updateData = (() => {
        if (location.state) {
            if (type === "order") {
                setOrderData(location.state);
            } if (type === "pay") {
                setPayData(location.state);
            } if (type === "deposit") {
                setDepositData(location.state);
            } if (type === "review") {
                setReviewData(location.state);
            }
        }
    })

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: { color: "#98B0D4", label: "입고대기" },
            IS01: { color: "#98B0D4", label: "결제대기(상품)" },
            IS02: { color: "#98B0D4", label: "결제중(상품)" },
            IS03: { color: "#FFB378", label: "결제완료(상품)" },
            IS04: { color: "#FF8600", label: "구매완료" },
            IS05: { color: "#6EDA60", label: "센터도착" },
            IS06: { color: "#98B0D4", label: "패킹중" },
            IS07: { color: "#98B0D4", label: "결제대기(배송)" },
            IS08: { color: "#98B0D4", label: "결제중(배송)" },
            IS09: { color: "#FFB378", label: "결제완료(배송)" },
            IS10: { color: "#979696", label: "출고완료" },
            IS11: { color: "#FF8F62", label: "반송중" },
            IS12: { color: "#FF8F62", label: "신청취소" },
            IS13: { color: "#98B0D4", label: "구매중" },
            IS14: { color: "#98B0D4", label: "입고중" }
        };

        const style = prodStStyles[prodSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "13px" }}>{style.label}</p>;
    };

    const [alarmOrderIndex, setAlarmOrderIndex] = useState(null);

    // 클릭 이벤트 핸들러
    const toggleAlarmOrder = (index) => {
        setAlarmOrderIndex(alarmOrderIndex === index ? null : index);
    };

    const closeAlarm = (async () => {
        navigate("/btcorpad")
    })

    const deleteAlarmOrder = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmOrder',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const deleteAlarmPay = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmPay',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const deleteAlarmDeposit = (() => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmDeposit',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const moveToAdminReviewDtPg = (async (seqNum) => {
        await deleteAlarmReview(seqNum);
        navigate(`/btcorpad/adminreview-detail/${seqNum}`);
    })

    const deleteAlarmReview = ((seqNum) => {
        axios({
            method: 'post',
            url: '/order/deleteAlarmReview',
            headers: {
                Authorization: LCAT
            },
            params: {
                seqNum: seqNum
            }
        })
            .then((response) => {
            })
            .catch((error) => {
                console.log("삭제실패 : ", error)
            })
    })

    const formatOrderNumber = (orderNum) => {
        if (!orderNum) return '';
        return orderNum.slice(-6); // 뒤에서 6자리
    };

    return(
        <div id="m-a-alarm-pg-container">
            <div className="m-a-title-frame">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">Alarm List</p>
            </div>
            <div className="m-a-card display-flex flex-column">
                <div className="m-a-alarm-list-container">

                    {type === 'order' ?
                        // type에 따라 조건화/상품
                        <div className="m-a-alarm-list-content-frame">
                            {/* Header */}
                            <div className="m-a-alarm-list-content-header">
                                <div className="m-a-alarm-list-header-title two-lines">
                                    <p className="font-t-cont font-s-14">주문번호</p>
                                    <p className="font-t-cont font-s-14">성함</p>
                                </div>
                                <div className="m-a-alarm-list-content-text">
                                    <p className="font-t-cont font-s-14">타입</p>
                                </div>
                                <div className="m-a-alarm-list-content-text">
                                    <p className="font-t-cont font-s-14">총금액</p>
                                </div>
                                <div className="m-a-alarm-list-content-text">
                                    <p className="font-t-cont font-s-14">결제상태</p>
                                </div>
                            </div>

                            {/* Body */}
                            {orderData && orderData.map((order, i) => {
                                // 총합 계산
                                const totalPrice = order.prodList.reduce((sum, prod) => sum + (prod.price || 0), 0);
                                const totalMallShipPrice = order.prodList.reduce((sum, prod) => sum + (prod.mallShipPrice || 0), 0);
                                const totalFee = order.prodList.reduce((sum, prod) => sum + (prod.fee || 0), 0);
                                return (
                                    <div key={i}>
                                        {alarmOrderIndex === i && order.prodList.map((prod, j) => (
                                            <div key={i} className="m-a-alarm-list-content-body">
                                                <div className="m-a-alarm-list-content-text two-lines">
                                                    <p className="font-t-cont font-s-14">~ {formatOrderNumber(order?.ordNum)}</p>
                                                    <p className="font-t-cont font-s-14 txt_line">{prod?.regNm}</p>
                                                </div>
                                                <div className="m-a-alarm-list-content-text">
                                                    <p className="font-t-cont font-s-14">
                                                        {prod.prodTy === "P00" ? "장바구니" : prod.prodTy === "P01" ? "구매대행" :
                                                            prod.prodTy === "P02" ? "오프라인" : prod.prodTy === "P03" ? "이체대행" : prod.prodTy === "P04" ? "국내배송" : prod.prodTy === "P05" ? "배송대행" : null}
                                                    </p>
                                                </div>
                                                <div className="m-a-alarm-list-content-text">
                                                    <p className="font-t-cont font-s-14">{(totalPrice + totalMallShipPrice + totalFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</p>
                                                </div>
                                                <div className="m-a-alarm-list-content-text">
                                                    <p className="font-t-cont font-s-14">
                                                        {prodStPrint(prod.prodSt)}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}

                        </div>
                        :
                        type === 'pay' ?
                            // type에 따라 조건화/결제내역
                            <div className="m-a-alarm-list-content-frame">
                                {/* Header */}
                                <div className="m-a-alarm-list-content-header">
                                    <div className="m-a-alarm-list-header-title two-lines">
                                        <p className="font-t-cont font-s-14">타입</p>
                                        <p className="font-t-cont font-s-14">성함</p>
                                    </div>
                                    <div className="m-a-alarm-list-content-text">
                                        <p className="font-t-cont font-s-14">결제금액</p>
                                        <p className="font-t-cont font-s-14">최종금액</p>
                                    </div>
                                    <div className="m-a-alarm-list-content-text">
                                    <p className="font-t-cont font-s-14">결제수단</p>
                                    </div>
                                    <div className="m-a-alarm-list-content-text">
                                        <p className="font-t-cont font-s-14">상태</p>
                                    </div>
                                </div>

                                {/* Body */}
                                {payData && payData.map((pay, i) => (
                                    <div key={i} className="m-a-alarm-list-content-body">
                                        <div className="m-a-alarm-list-content-text two-lines">
                                            <p className="font-t-cont font-s-14">
                                                {pay?.payType === "PT0" ? "상품금액" : pay?.payType === "PT1" ? "해외배송료" :
                                                    pay?.payType === "PT2" ? "기타비용" : pay?.payType === "PT3" ? "통합결제" : null}
                                            </p>
                                            <p className="font-t-cont font-s-14 txt_line">{pay?.modiNm ? pay?.modiNm : pay?.regNm}</p>
                                        </div>
                                        <div className="m-a-alarm-list-header-title two-lines">
                                            <p className="font-t-cont font-s-14">
                                                { pay.payType === "PT0" ?
                                                    pay?.ttProdExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 :
                                                    pay.payType === "PT1" ?
                                                        pay?.ttShipExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 :
                                                        pay.payType === "PT2" ?
                                                            pay?.ttEtcPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0 :
                                                            (pay?.ttProdExpense + pay?.ttShipExpense + pay?.ttEtcPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                                                }
                                            </p>
                                            <p className="font-t-cont font-s-14 m-a-alarm-list-last-for-price">
                                                {pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                            </p>
                                            {/*{pay?.forCurrency ?*/}
                                            <p className="font-t-cont font-s-14">
                                                <a className="m-a-alarm-list-last-for-price">{pay?.lastForPrice}101.25</a>
                                                <a className="m-a-alarm-list-currency">{pay?.forCurrency}USD</a>
                                            </p>
                                            {/*    :*/}
                                            {/*    null*/}
                                            {/*}*/}
                                        </div>
                                        <div className="m-a-alarm-list-content-text">
                                            <p className="font-t-cont font-s-14">
                                            {pay.payWay === "PM00" ? "무통장입금" : pay.payWay === "PM01" ? "Paypal" : pay.payWay === "PM02" ?
                                                    "Wechat" : pay.payWay === "PM03" ? "Alipay" : pay.payWay === "PM04" ? "적립금결제" : pay.payWay === "PM05" ? "예치금결제" : null}
                                            </p>
                                        </div>
                                        <div className="m-a-alarm-list-content-text"
                                             style={{
                                                 color: (pay.payStatus === 'PS0' || pay.payStatus === 'PS1') ? "#98B0D4" :
                                                     (pay.payStatus === 'PS4' || pay.payStatus === 'PS5') ? "#FF8F62" :
                                                         pay.payStatus === "PS2" ? "#FFB378" : "#979696"
                                             }}>
                                            <p className="font-t-cont font-s-14">
                                                {
                                                    pay.payStatus === 'PS0' ? "결제대기" :
                                                        pay.payStatus === 'PS1' ? "결제중" :
                                                            pay.payStatus === 'PS2' ? "결제완료" :
                                                                pay.payStatus === 'PS3' ? "출고완료" :
                                                                    pay.payStatus === 'PS4' ? "반송중" : "취소"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            type === 'deposit' ?
                                // type에 따라 조건화/예치금신청
                                <div className="m-a-alarm-list-content-frame">
                                    {/* Header */}
                                    <div className="m-a-alarm-list-content-header"
                                         style={{gridTemplateColumns: "1fr 2.2fr 1.3fr 0.6fr"}}>
                                        <div className="m-a-alarm-list-header-title two-lines">
                                            <p className="font-t-cont font-s-14">고객명</p>
                                            <p className="font-t-cont font-s-14 ">예금주</p>
                                        </div>
                                        <div className="m-a-alarm-list-content-text">
                                            <p className="font-t-cont font-s-14" style={{width: "140px"}}>계좌</p>
                                        </div>
                                        <div className="m-a-alarm-list-content-text">
                                            <p className="font-t-cont font-s-14">금액</p>
                                        </div>
                                        <div className="m-a-alarm-list-header-title two-lines">
                                            <p className="font-t-cont font-s-14">구분</p>
                                            <p className="font-t-cont font-s-14">상태</p>
                                        </div>
                                    </div>

                                    {/* Body */}
                                    {depositData && depositData.map((deposit, i) => (
                                        <div key={i} className="m-a-alarm-list-content-body"
                                             style={{gridTemplateColumns: "1fr 2.2fr 1.3fr 0.6fr"}}>
                                            <div className="m-a-alarm-list-content-text two-lines">
                                                <p className="font-t-cont font-s-14">{deposit?.userNm}</p>
                                                <p className="font-t-cont font-s-14 fw-bold">{deposit?.name}</p>
                                            </div>
                                            <div className="m-a-alarm-list-content-text">
                                                <p className="font-t-cont font-s-14"
                                                   style={{width: "140px"}}>{deposit?.account}</p>
                                            </div>
                                            <div className="m-a-alarm-list-content-text">
                                                <p className="font-t-cont font-s-14">
                                                    {deposit?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                                </p>
                                            </div>
                                            <div className="m-a-alarm-list-header-title two-lines">
                                                <p className="font-t-cont font-s-14 fw-bold">
                                                    {deposit.chargFl ? "충전" : "환급"}
                                                </p>
                                                <p className="font-t-cont font-s-14"
                                                   style={{color: deposit.status === "0" ? "#7C9EDC" : deposit.status === "2" ? "#EB939D" : undefined}}>
                                                    {
                                                        deposit.status === "0" ? "대기" :
                                                            deposit.status === "1" ? "완료" : "취소"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                type === 'review' ?
                                    // type에 따라 조건화/이용후기
                                    <div className="m-a-alarm-list-content-frame">
                                        {/* Header */}
                                        <div className="m-a-alarm-list-content-header"
                                             style={{gridTemplateColumns: "2.5fr 1fr 1.5fr"}}>
                                            <div className="m-a-alarm-list-content-text">
                                                <p className="font-t-cont font-s-14 txt_line"
                                                   style={{width: "180px"}}>제목</p>
                                            </div>
                                            <div className="m-a-alarm-list-content-text">
                                                <p className="font-t-cont font-s-14 txt_line">작성자</p>
                                            </div>
                                            <div className="m-a-alarm-list-content-text">
                                                <p className="font-t-cont font-s-14">일자</p>
                                            </div>
                                        </div>

                                        {/* Body */}
                                        {reviewData && reviewData.map((review, i) => (
                                            <div key={i} className="m-a-alarm-list-content-body"
                                                 style={{gridTemplateColumns: "2.5fr 1fr 1.5fr"}}>
                                                <div className="m-a-alarm-list-content-text">
                                                    <p className="font-t-cont font-s-14 txt_line"
                                                       style={{width: "180px"}}>{review?.title}</p>
                                                </div>
                                                <div className="m-a-alarm-list-content-text">
                                                    <p className="font-t-cont font-s-14 txt_line">{review?.regNm}</p>
                                                </div>
                                                <div className="m-a-alarm-list-content-text">
                                                    <p className="font-t-cont font-s-14">{formatDate(review?.regDate)}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    null
                    }


                </div>
            </div>
        </div>
    )
}

export default MAdminAlarmListPg
