import React, {useEffect, useState} from 'react';
import maCateImg from "../../assets/img/m-a-cate.png";
import '../../css/web/AdminPageSetPg.css';
import '../../css/DarkMode.css';
import {useNavigate} from "react-router-dom";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import axios from '../../services/axiosInstance';
import commonClose from "../../assets/img/commonClose.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import noImage from "../../assets/img/NoImage.png";

const initialItems = [
    { id: 'header', content: '헤더', width: '500px', height: '55px', isDraggable: false },
    { id: 'mainBanner', content: '배너설정', width: '500px', height: '100px', isDraggable: true },
    { id: 'review', content: '이용후기', width: '500px', height: '120px', isDraggable: true },
    { id: 'board', content: '게시판', width: '500px', height: '160px', isDraggable: true },
    { id: 'footer', content: '푸터', width: '500px', height: '55px', isDraggable: false },
    // { id: 'sideBanner', content: '사이드배너', width: '500px', height: '60px'  },
    // { id: 'userGuide', content: '이용가이드', width: '500px', het: '60px'  },igh
];

function AdminPageSetPg() {

    /** region DataTable */

    const [bannerData, setBannerData] = useState([]);
    const [popupData, setPopupData] = useState([]);
    const [guideData, setGuideData] = useState([]);

    /** endregion */

    /** region Function */

    // region navigate

    const navigate = useNavigate();

    const moveToModifyPg = ((seqNum) => {
        navigate(`/btcorpad/adminbanner-modify/${seqNum}`)
    })

    const moveToPopupDetailPg = (seqNum) => {
        navigate(`/btcorpad/popupSet/${seqNum}`);
    }

    const moveToGuideDetailPg = (seqNum) => {
        navigate(`/btcorpad/guideSet/${seqNum}`);
    }

    //endregion navigate

    //region ScrollPagination

    const [displayLimitBanner, setDisplayLimitBanner] = useState(10);
    const [displayLimitPopup, setDisplayLimitPopup] = useState(10);
    const [displayLimitGuide, setDisplayLimitGuide] = useState(10);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;

        if (bannerData.length > displayLimitBanner && displayLimitBanner < 30) {
            setDisplayLimitBanner(prevLimit => prevLimit + perPg);
        }
        if (popupData.length > displayLimitPopup && displayLimitPopup < 30) {
            setDisplayLimitPopup(prevLimit => prevLimit + perPg);
        }
        if (guideData.length > displayLimitGuide && displayLimitGuide < 30) {
            setDisplayLimitGuide(prevLimit => prevLimit + perPg);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, [
        bannerData.length, displayLimitBanner,
        popupData.length, displayLimitPopup,
        guideData.length, displayLimitGuide,
    ]);

    const loadMoreData = () => {
        setDisplayLimitBanner(prevLimit => prevLimit + perPg);
        setDisplayLimitPopup(prevLimit => prevLimit + perPg);
        setDisplayLimitGuide(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimitBanner(newPerPage);
        setDisplayLimitPopup(newPerPage);
        setDisplayLimitGuide(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimitBanner(perPg);
        setDisplayLimitPopup(perPg);
        setDisplayLimitGuide(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    // const [checkItems, setCheckItems] = useState([]);
    // const [checkedPopup, setCheckedPopup] = useState([]);
    //
    // useEffect(() => {}, [checkItems]);
    // const handleSingleCheck = (checked, seqNum) => {
    //     if (checked) {
    //         setCheckItems(prev => [...prev, seqNum]);
    //     } else {
    //         setCheckItems(checkItems.filter((el) => el !== seqNum));
    //     }
    // };
    // const handleAllCheck = (checked) => {
    //     if(checked) {
    //         const idArray = [];
    //         bannerData.forEach((el) => idArray.push(el.seqNum));
    //         setCheckItems(idArray);
    //     } else {
    //         setCheckItems([]);
    //     }
    // }
    //
    // useEffect(() => {}, [checkedPopup]);
    // const popupHandleSingleCheck = (checked, seqNum) => {
    //     if (checked) {
    //         setCheckedPopup(prev => [...prev, seqNum]);
    //     } else {
    //         setCheckedPopup(checkedPopup.filter((el) => el !== seqNum));
    //     }
    // };
    // const popupHandleAllCheck = (checked) => {
    //     if(checked) {
    //         const idArray = [];
    //         popupData.forEach((el) => idArray.push(el.seqNum));
    //         setCheckedPopup(idArray);
    //     } else {
    //         setCheckedPopup([]);
    //     }
    // }

    // endregion

    // region 탭기능

    const [activeCategory, setActiveCategory] = useState("MainBanner");
    const [layoutItems, setLayoutItems] = useState(initialItems);

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    };

    // endregion

    // region 드래그

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedItems = Array.from(layoutItems);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);
        setLayoutItems(reorderedItems);
    };

    //endregion

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const convertToLang = ((lang) => {
        if (lang === "0") return "한국어"
        if (lang === "1") return "English"
        if (lang === "2") return "日本語"
        if (lang === "3") return "中文語"
        if (lang === "4") return "Indonesian"
    })

    // region Delete Alert

    const [bannerSeqNum, setBannerSeqNum] = useState(0);
    const [popupSeqNum, setPopupSeqNum] = useState(0);
    const [guideSeqNum, setGuideSeqNum] = useState(0);
    const deleteBannerMsg1 = "선택한 배너를 삭제하시겠습니까?"
    const deleteBannerMsg2 = "삭제 후 복구가 불가합니다"
    const deletePopupMsg1 = "선택하신 팝업을 삭제하시겠습니까?"
    const deletePopupMsg2 = "삭제 후 복구가 불가합니다"
    const deleteGuideMsg1 = "선택한 이용가이드 정보를 삭제하시겠습니까?"
    const deleteGuideMsg2 = "삭제 후 복구가 불가합니다"

    const [deleteBannerMsgShow, setDeleteBannerMsgShow] = useState(false);
    const [deletePopupMsgShow, setDeletePopupMsgShow] = useState(false);
    const [deleteGuideMsgShow, setDeleteGuideMsgShow] = useState(false);

    const openDeleteBannerMsgShow = ((seqNum) => {
        setBannerSeqNum(seqNum);
        setDeleteBannerMsgShow(true);
    })
    const closeDeleteBannerMsgShow = (() => {
        setDeleteBannerMsgShow(false);
    })

    const openDeletePopupMsgShow = ((seqNum) => {
        setPopupSeqNum(seqNum);
        setDeletePopupMsgShow(true);
    })
    const closeDeletePopupMsgShow = (() => {
        setDeletePopupMsgShow(false);
    })

    const openDeleteGuideMsgShow = ((guideSeqNum) => {
        setGuideSeqNum(guideSeqNum);
        setDeleteGuideMsgShow(true);
    })
    const closeDeleteGuideMsgShow = (() => {
        setDeleteGuideMsgShow(false);
    })

    // endregion

    /** endregion */

    /** region Event */

    // region Select

    useEffect(() => {
        selectBannerData();
        selectPopupData();
        selectGuideData();
    }, [])

    // const selectFixFirstBannerCondition = () => axios({
    //     method: 'get',
    //     url: '/banner/selectFixFirstCondition/',
    //     params: {
    //         limit: perPg,
    //         type: '0'
    //     }
    // })
    //     .then((response) => {
    //         setBannerData(response.data);
    //     })

    const selectBannerData = () => axios({
        method: 'get',
        url: '/banner/selectBannerAdminList/',
    })
        .then((response) => {
            setBannerData(response.data);
        });

    const selectPopupData = () => axios({
        method: 'get',
        url: '/popup/selectPopupAdminList/',
    })
        .then((response) => {
            setPopupData(response.data);
        });

    const selectGuideData = () => axios({
        method: 'get',
        url: '/guide/selectGuideAdminList/',
        })
        .then((response) => {
            setGuideData(response.data);
        });


    // endregion

    // region Delete

    const deleteBanner = ((seqNum) => {
        axios({
            method: 'post',
            url: '/banner/deleteBanner',
            params: {
                bannerSeqNum: seqNum,
            },
        })
            .then(() => {
                setBannerData(prevData => prevData.filter(banner => banner.seqNum !== seqNum));
                closeDeleteBannerMsgShow();
                navigate("/btcorpad/pageset");
            })
            .catch(() => {
                console.log("banner 삭제실패")
            })
    })

    const deletePopup = ((popupSeqNum) => {
        axios({
            method: 'post',
            url: '/popup/deletePopup',
            params: {
                popupSeqNum: popupSeqNum,
            },
        })
            .then(() => {
                setPopupData(prevData => prevData.filter(popup => popup.seqNum !== popupSeqNum));
                closeDeletePopupMsgShow();
                navigate("/btcorpad/pageset");
            })
            .catch(() => {
                console.log("popup 삭제실패")
            })
    })

    const deleteGuide = ((guideSeqNum) => {
        axios({
            method: 'post',
            url: '/guide/deleteGuide',
            params: {
                guideSeqNum: guideSeqNum,
            },
        })
            .then(() => {
                setGuideData(prevData => prevData.filter(guide => guide.seqNum !== guideSeqNum));
                closeDeleteGuideMsgShow();
                navigate("/btcorpad/pageset");
            })
            .catch(() => {
                console.log("guide 삭제실패")
            })
    })

    // endregion

    /** endregion */

    return (
        <div className="AdminPageSetPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">UI 및 페이지설정</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="pg-set-categories font-t-title font-s-17 m-b-10">
                        {/*<div className={activeCategory === "Layout" ? "pg-set-active-category" : ""} onClick={() => handleCategoryClick("Layout")}>레이아웃</div>*/}
                        <div className={activeCategory === "MainBanner" ? "pg-set-active-category" : ""} onClick={() => handleCategoryClick("MainBanner")}>배너설정</div>
                        {/*<div className={activeCategory === "SideBanner" ? "pg-set-active-category" : ""} onClick={() => handleCategoryClick("SideBanner")}>사이드배너</div>*/}
                        {/*<div className={activeCategory === "Review" ? "pg-set-active-category" : ""} onClick={() => handleCategoryClick("Review")}>이용후기</div>*/}
                        <div className={activeCategory === "Popup" ? "pg-set-active-category" : ""} onClick={() => handleCategoryClick("Popup")}>팝업설정</div>
                        <div className={activeCategory === "Guide" ? "pg-set-active-category" : ""} onClick={() => handleCategoryClick("Guide")}>이용가이드</div>
                    </div>
                    <div className="pg-set-tab-content font-t-cont">
                            {/*{activeTab === "PC" && activeCategory === "Layout" && (*/}
                            {/*    <DragDropContext onDragEnd={onDragEnd}>*/}
                            {/*        <Droppable droppableId="droppable">*/}
                            {/*            {(provided) => (*/}
                            {/*                <div className="drag-layout-frame"*/}
                            {/*                    ref={provided.innerRef}*/}
                            {/*                    {...provided.droppableProps}*/}
                            {/*                >*/}
                            {/*                    {layoutItems.map((item, index) => (*/}
                            {/*                        <Draggable key={item.id} draggableId={item.id} index={index}>*/}
                            {/*                            {(provided) => (*/}
                            {/*                                <div ref={provided.innerRef} className="draggable-item"*/}
                            {/*                                    {...provided.draggableProps}*/}
                            {/*                                    {...provided.dragHandleProps}*/}
                            {/*                                    style={{*/}
                            {/*                                        ...provided.draggableProps.style,*/}
                            {/*                                        width: item.width,*/}
                            {/*                                        height: item.height,*/}
                            {/*                                        backgroundColor: '#d9d9d9',*/}
                            {/*                                        marginBottom: '10px',*/}
                            {/*                                        display: 'flex',*/}
                            {/*                                        alignItems: 'center',*/}
                            {/*                                        justifyContent: 'center',*/}
                            {/*                                        borderRadius: '4px',*/}
                            {/*                                    }}*/}
                            {/*                                >*/}
                            {/*                                    {item.content}*/}
                            {/*                                </div>*/}
                            {/*                            )}*/}
                            {/*                        </Draggable>*/}
                            {/*                    ))}*/}
                            {/*                    {provided.placeholder}*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </Droppable>*/}
                            {/*    </DragDropContext>*/}
                            {/*)}*/}
                            {/*메인배너*/}
                            {activeCategory === "MainBanner" &&
                                <div className="banner-info-container">
                                    <div className="admin-wrap-card">
                                        <div className="admin-event-wrap">
                                            <div className="admin-event-wrap-btn">
                                                <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                            </div>
                                            <div className="admin-event-wrap-btn">
                                                <button onClick={()=> navigate("/btcorpad/adminbanner-write")} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">신규등록</button>
                                            </div>
                                        </div>
                                        <div className="div-tb m-b-10">
                                            <div className="div-tb-head ht-60">
                                                {/*<div className="wd-40">*/}
                                                {/*    <input className="admin-input-chkBox m-0-a" type='checkbox'*/}
                                                {/*           onChange={(e) => handleAllCheck(e.target.checked)}*/}
                                                {/*           checked={checkItems.length === bannerData.length}/>*/}
                                                {/*</div>*/}
                                                <div className="wd-40"><p>번호</p></div>
                                                <div className="wd-300"><p>이미지</p></div>
                                                <div className="wd-200"><p>제목</p></div>
                                                <div className="wd-70"><p>언어</p></div>
                                                <div className="wd-130"><p>작성자</p></div>
                                                <div className="wd-100"><p>작성일자</p></div>
                                                <div className="wd-40"></div>
                                            </div>
                                            {bannerData.filter(data => data.type === '0').map((data, i) => (
                                                <div key={i} className="div-tb-body ht-150">
                                                    {/*<div className="wd-40">*/}
                                                    {/*    <input className="admin-input-chkBox m-0-a" type="checkbox" name={data.seqNum}*/}
                                                    {/*           checked={checkItems.includes(data.seqNum)}*/}
                                                    {/*           onChange={(e) => handleSingleCheck(e.target.checked, data.seqNum)}/>*/}
                                                    {/*</div>*/}
                                                    <div className="wd-40"><p>{i + 1}</p></div>
                                                    <div  className="wd-300" onClick={() => moveToModifyPg(data?.seqNum)} style={{
                                                        height: "100%",
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "20px"
                                                    }}>
                                                        {data?.bannerPhotoDTO?.[0]?.fileNm ?
                                                            <img style={{
                                                                maxWidth: "100%",
                                                                maxHeight: "100%",
                                                                objectFit: "contain",
                                                                textAlign: "center",
                                                            }}
                                                                 src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/bannerImg/" + data?.bannerPhotoDTO?.[0]?.fileNm}
                                                                 alt="bannerImg"/>
                                                            :
                                                            <img style={{
                                                                maxWidth: "100%",
                                                                maxHeight: "100%",
                                                                objectFit: "contain",
                                                                textAlign: "center",
                                                                opacity: "0.5"
                                                            }} src={noImage} alt="noImage"/>
                                                        }
                                                    </div>
                                                    <div className="wd-200"><p>{data?.title}</p></div>
                                                    <div className="wd-70"><p>{convertToLang(data?.lang)}</p></div>
                                                    <div className="wd-130"><p>{data?.regNm}</p></div>
                                                    <div className="wd-100"><p>{formatDate(data?.regDate)}</p></div>
                                                    <div className="wd-40"><img onClick={() => openDeleteBannerMsgShow(data?.seqNum)}
                                                                                      style={{
                                                                                          width: "30px",
                                                                                          height: "30px",
                                                                                          opacity: "0.6",
                                                                                          cursor: "pointer"
                                                                                      }} src={commonClose} alt="close"/></div>
                                                </div>
                                            ))}
                                        </div>
                                        {displayLimitBanner >= 30 && bannerData.length > displayLimitBanner && (
                                            <div className="text-center m-t-20">
                                                <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                                    <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {/*{activeCategory === "SideBanner" &&*/}
                            {/*    <div>*/}
                            {/*        PC 사이드배너(광고 등)*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*{activeCategory === "Review" &&*/}
                            {/*    <div>*/}
                            {/*        PC 이용후기 영역*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*팝업*/}
                            {activeCategory === "Popup" &&
                                <div className="popup-info-container">
                                    <div className="admin-wrap-card">
                                        <div className="admin-event-wrap">
                                            <div className="admin-event-wrap-btn">
                                                <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                            </div>
                                            <div className="admin-event-wrap-btn">
                                                <button onClick={()=> navigate("/btcorpad/popupSet")} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">신규등록</button>
                                            </div>
                                        </div>
                                        <div className="div-tb m-b-10">
                                            <div className="div-tb-head ht-60">
                                                <div className="wd-40"><p>번호</p></div>
                                                <div className="wd-200"><p>제목</p></div>
                                                <div className="wd-250"><p>이미지</p></div>
                                                <div className="wd-120"><p>팝업타입</p></div>
                                                <div className="wd-80"><p>기기타입</p></div>
                                                <div className="wd-100"><p>이미지타입</p></div>
                                                <div className="wd-80"><p>언어</p></div>
                                                <div className="wd-80"><p>등록자</p></div>
                                                <div className="wd-120"><p>등록일자</p></div>
                                                <div className="wd-40"></div>
                                            </div>
                                            {popupData?.slice(0, displayLimitPopup).map((data, i) => (
                                                <div key={i} className="div-tb-body ht-150">
                                                    <div className="wd-40"><p>{i + 1}</p></div>
                                                    <div className="wd-200 c-point" onClick={() => moveToPopupDetailPg(data.seqNum)}><a className="font-b c-point">{data?.ptitle}</a></div>
                                                    <div className="wd-250 ht-120">
                                                        <div className="wd-100pc align-center">
                                                            {data?.bannerPopPhotoDTOS?.length > 0 && data?.bannerPopPhotoDTOS[0]?.fileNm ? (
                                                                <img className="wd-80pc ht-100" style={{objectFit: "contain"}}
                                                                     src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/popupImg/" + data?.bannerPopPhotoDTOS[0]?.fileNm}
                                                                     alt="popupImg"
                                                                />
                                                            ) : (
                                                                <p>이미지없음</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="wd-120"><p>{data?.psizeType === "0" ? "소형(360*480)" : data?.psizeType === "1" ? "중형(480*640)" : "대형(600*800)"}</p></div>
                                                    <div className="wd-80">
                                                        <p className="font-b">{data?.pmachineType === "0" ? "PC" : data?.pmachineType === "1" ? "모바일" : "앱"}</p>
                                                    </div>
                                                    <div className="wd-100"><p className="font-b">{data?.pimgType === "0" ? "일반" : "URL"}</p></div>
                                                    <div className="wd-80"><p className="font-b">{convertToLang(data?.plang)}</p></div>
                                                    <div className="wd-80"><p>{data?.regNm}</p></div>
                                                    <div className="wd-120"><p>{formatDate(data?.regDate)}</p></div>
                                                    <div className="wd-40"><img onClick={()=> openDeletePopupMsgShow(data?.seqNum)} style={{width:"30px", height:"30px", opacity:"0.6", cursor:"pointer"}} src={commonClose} alt="close"/></div>
                                                </div>
                                            ))}
                                        </div>
                                        {displayLimitPopup >= 30 && popupData.length > displayLimitPopup && (
                                            <div className="text-center m-t-20">
                                                <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                                    <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {/*이용가이드*/}
                            {activeCategory === "Guide" &&
                                <div className="guide-info-container">
                                    <div className="admin-wrap-card">
                                        <div className="admin-event-wrap">
                                            <div className="admin-event-wrap-btn">
                                                <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                            </div>
                                            <div className="admin-event-wrap-btn">
                                                <button onClick={()=> navigate("/btcorpad/guideSet")} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">신규등록</button>
                                            </div>
                                        </div>
                                        <div className="div-tb m-b-10">
                                            <div className="div-tb-head ht-60">
                                                <div className="wd-40"><p>번호</p></div>
                                                <div className="wd-150"><p>제목</p></div>
                                                <div className="wd-250"><p>대표이미지</p></div>
                                                <div className="wd-100"><p>서비스구분</p></div>
                                                <div className="wd-80"><p>기기타입</p></div>
                                                <div className="wd-80"><p>언어</p></div>
                                                <div className="wd-80"><p>등록자</p></div>
                                                <div className="wd-120"><p>등록일자</p></div>
                                                <div className="wd-40"></div>
                                            </div>
                                            {guideData?.slice(0, displayLimitGuide).map((data, i) => (
                                                <div key={i} className="div-tb-body ht-120">
                                                    <div className="wd-40"><p>{i + 1}</p></div>
                                                    <div className="wd-150 c-point" onClick={() => moveToGuideDetailPg(data.seqNum)}>
                                                        <a className="font-b c-point">{data?.gtitle}</a>
                                                    </div>
                                                    <div className="wd-250 ht-120">
                                                        <div className="wd-100pc align-center">
                                                            {data?.userGuidePhotoDTOS?.length > 0 && data?.userGuidePhotoDTOS[0]?.fileNm ? (
                                                                <img className="wd-80pc ht-100" style={{objectFit: "contain"}}
                                                                    src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/useGuide/" + data?.userGuidePhotoDTOS[0]?.fileNm}
                                                                    alt="guideImg"
                                                                />
                                                            ) : (
                                                                <p>이미지없음</p>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="wd-100">
                                                        <p>
                                                            {
                                                                data?.gtype === "P00" ? "장바구니" :
                                                                    data?.gtype === "P01" ? "구매대행" :
                                                                        data?.gtype === "P02" ? "오프라인" :
                                                                            data?.gtype === "P03" ? "이체대행" :
                                                                                data?.gtype === "P04" ? "국내배송" :
                                                                                    data?.gtype === "P05" ? "배송대행" : null
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="wd-80">
                                                        <p className="font-b">
                                                            {
                                                                data?.gmachineType === "0" ? "PC" :
                                                                    data?.gmachineType === "1" ? "모바일" : "앱"
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="wd-80"><p className="font-b">{convertToLang(data?.glang)}</p></div>
                                                    <div className="wd-80"><p>{data?.regNm}</p></div>
                                                    <div className="wd-120"><p>{formatDate(data?.regDate)}</p></div>
                                                    <div className="wd-40">
                                                        <img className="c-point wd-30 ht-30 m-a-opc-07" onClick={() => openDeleteGuideMsgShow(data?.seqNum)} src={commonClose} alt="close"/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {displayLimitGuide >= 30 && guideData?.length > displayLimitGuide && (
                                            <div className="text-center m-t-20">
                                                <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                                    <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>

                    {/*{activeTab === "Mobile" && (*/}
                    {/*    <div className="pg-set-tab-content font-t-cont">*/}
                    {/*        {activeCategory === "Layout" &&*/}
                    {/*            <div>*/}
                    {/*                모바일 레이아웃 설정 내용*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        {activeCategory === "MainBanner" &&*/}
                    {/*            <div>*/}
                    {/*                모바일 메인배너 설정 내용*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        {activeCategory === "SideBanner" &&*/}
                    {/*            <div>*/}
                    {/*                모바일 사이드배너 설정 내용*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        {activeCategory === "Review" &&*/}
                    {/*            <div>*/}
                    {/*                모바일 이용후기 설정 내용*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        /!*{activeCategory === "Board" &&*!/*/}
                    {/*        /!*    <div>*!/*/}
                    {/*        /!*        모바일 게시판 설정 내용*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*}*!/*/}
                    {/*        {activeCategory === "Popup" &&*/}
                    {/*            <div>*/}
                    {/*                팝업설정*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        {activeCategory === "Guide" &&*/}
                    {/*            <div>*/}
                    {/*                이용가이드*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </div>
            { deleteBannerMsgShow ?
                <MsgSelectModal deleteBannerMsgShow={deleteBannerMsgShow} deleteBannerMsg1={deleteBannerMsg1} deleteBannerMsg2={deleteBannerMsg2}
                                closeDeleteBannerMsgShow={closeDeleteBannerMsgShow} deleteBanner={deleteBanner} deleteBannerSeqNum={bannerSeqNum}/>
                :
                null
            }
            { deletePopupMsgShow ?
                <MsgSelectModal deleteBannerMsgShow={deletePopupMsgShow} deleteBannerMsg1={deletePopupMsg1} deleteBannerMsg2={deletePopupMsg2}
                                closeDeleteBannerMsgShow={closeDeletePopupMsgShow} deleteBanner={deletePopup} deleteBannerSeqNum={popupSeqNum}/>
                :
                null
            }
            { deleteGuideMsgShow ?
                <MsgSelectModal deleteBannerMsgShow={deleteGuideMsgShow} deleteBannerMsg1={deleteGuideMsg1} deleteBannerMsg2={deleteGuideMsg2}
                                closeDeleteBannerMsgShow={closeDeleteGuideMsgShow} deleteBanner={deleteGuide} deleteBannerSeqNum={guideSeqNum}/>
                :
                null
            }
        </div>
    );
}

export default AdminPageSetPg
