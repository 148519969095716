import React, {useEffect, useState} from 'react';
import axios from '../../services/axiosInstance';
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import maCateImg from "../../assets/img/m-a-cate.png";

function AdminDepositWaitPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');

    const formatDate = (dateStr) => {
        if (dateStr?.length !== 8) return "";
        return `${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (data.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const [selectDepConfirmAllCheckBox, setSelectDepConfirmAllCheckBox] = useState(false);
    const [selectDepConfirmCheckData, setSelectDepConfirmCheckData] = useState([]);

    const handleSelectDepConfirmAllCheckBox = e => {
        setSelectDepConfirmAllCheckBox(e.target.checked);
        if (e.target.checked) {
            const filtered = data.filter(dep => dep.status === "0");
            setSelectDepConfirmCheckData(filtered);
        } else {
            setSelectDepConfirmCheckData([]);
        }
    };

    const handleSelectDepConfirmCheckBox = dep => {
        if (selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)) {
            setSelectDepConfirmCheckData(selectDepConfirmCheckData.filter(item => item.seqNum !== dep.seqNum));
        } else {
            setSelectDepConfirmCheckData([...selectDepConfirmCheckData, dep]);
        }
    };

    // const checkBoxChange = (id) => {
    //     setChecked((prevSelectedData) => {
    //         if (prevSelectedData.includes(id)) {
    //             return prevSelectedData.filter((item) => item !== id);
    //         } else {
    //             return [...prevSelectedData, id];
    //         }
    //     });
    // };
    //
    // const allcheckBoxChange = (e) => {
    //     let visibleData = data.slice(0, displayLimit);
    //     if (e.target.checked) {
    //         const visibleIds = visibleData.map((item) => item.seqNum);
    //         setChecked(visibleIds);
    //     } else {
    //         setChecked([]);
    //     }
    // };

    // const [allChecked, setAllChecked] = useState(false);
    // const [checkedItems, setCheckedItems] = useState([]);
    //
    // const handleCheckAll = (e) => {
    //     const isChecked = e.target.checked;
    //     setAllChecked(isChecked);
    //     setCheckedItems(isChecked ? searchData.map(dep => dep.seqNum) : []);
    // };
    //
    // const handleCheck = (seqNum) => {
    //     const updatedCheckedItems = checkedItems.includes(seqNum)
    //         ? checkedItems.filter(item => item !== seqNum)
    //         : [...checkedItems, seqNum];
    //     setCheckedItems(updatedCheckedItems);
    //     setAllChecked(updatedCheckedItems.length === searchData.length);
    // };

    //endregion

    //region Alert

    //endregion

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        return (
            cData?.userNm?.toLowerCase().includes(searchInput?.toLowerCase()) ||
            cData?.userId?.toLowerCase().includes(searchInput?.toLowerCase())
        );
    });

    /** endregion */

    /** region Event */

    const [depConfirmComplShow, setDepConfirmComplShow] = useState(false);
    const depConfirmComplMsg1 = "예치금을 지급/환급 처리하시겠습니까?"
    const depConfirmComplMsg2 = "처리 후 복구할 수 없습니다"
    const closeDepConfirmCompl = (() => {
        setDepConfirmComplShow(false);
    })

    useEffect(() => {
        select();
    }, [])

    const select = () => {
        axios({
            method: 'get',
            url: '/member/selectDepWaiting',
            params: {
                perPg: perPg
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                console.log("adminErr[00] : ", error)
            });
    };

    const modifyData = () => {
        axios({
            method: 'post',
            url: '/member/modifyDepConfirm',
            data: selectDepConfirmCheckData
        })
            .then((response) => {
                const responseSeqNums = response.data.map((item) => item.seqNum);
                const updatedData = data.filter((item) => !responseSeqNums.includes(item.seqNum));
                setData(updatedData);
                closeDepConfirmCompl();
            })
            .catch((error) => {
                console.log("adminErr[02] : ", error)
            });
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.seqNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                // const paymentStatus = item.payStatus === 'PS1' ? '결제중' : item.payStatus === 'PS0' ? '결제대기' : '결제완료';
                const PayGbn = item.chargFl === true ? '충전' : item.chargFl === false ? '환급' : item.chargFl;
                const accountInfo = item.currency
                    ? `${item.account} - ${item.dollarPrice} ${item.currency}`
                    : item.account;

                return {
                    '번호': index + 1,
                    '입금일자': formatDate(item.sendDate),
                    '고객명': item.userNm,
                    'ID': item.userId,
                    '예금주': item.name,
                    '계좌': accountInfo,
                    '금액': item.price,
                    '구분': PayGbn,
                    '상태': "대기",
                };
            }

            const sheetConfigs = {
                '예치금대기내역': {
                    name1: '예치금대기내역',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '입금일자', key: '입금일자', width: 13, style: { alignment: { horizontal: 'center' }}},
                        {header: '고객명', key: '고객명', width: 23, style: { alignment: { horizontal: 'center' }}},
                        {header: 'ID', key: 'ID', width: 26},
                        {header: '예금주', key: '예금주', width: 21},
                        {header: '계좌', key: '계좌', width: 37, style: { alignment: { horizontal: 'center' }}},
                        {header: '금액', key: '금액', width: 11, style: { alignment: { horizontal: 'center' }}},
                        {header: '구분', key: '구분', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '상태', key: '상태', width: 10, style: { alignment: { horizontal: 'center' }}},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['예치금대기내역'].name, sheetConfigs['예치금대기내역'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `예치금대기내역_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminDepositWaitPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">예치금대기</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={excelDown}>EXCEL</button>
                            <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event" onClick={()=> setDepConfirmComplShow(true)}>예치금확인</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={selectDepConfirmAllCheckBox}
                                       onChange={handleSelectDepConfirmAllCheckBox}/>
                            </div>
                            <div style={{width: "40px"}}><p>번호</p></div>
                            <div style={{width: "100px"}}><p>입금완료일</p></div>
                            <div style={{width: "180px"}}><p>아이디</p></div>
                            <div style={{width: "120px"}}><p>예금주(ID)</p></div>
                            <div style={{width: "450px"}}><p>계좌</p></div>
                            <div style={{width: "100px"}}><p>금액(&#8361;)</p></div>
                            <div style={{width: "80px"}}><p>구분</p></div>
                            <div style={{width: "50px"}}><p>상태</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((dep, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    {dep.status === "0" ?
                                        <input className="admin-input-chkBox m-0-a" type={"checkbox"}
                                               checked={selectDepConfirmCheckData.some(item => item.seqNum === dep.seqNum)}
                                               onChange={() => handleSelectDepConfirmCheckBox(dep)}/> : null
                                    }
                                </div>
                                <div style={{width: "40px"}}><p className="font-b">{i+1}</p></div>
                                <div style={{width: "100px"}}><p className="font-b">{formatDate(dep.regDate)}</p></div>
                                <div style={{width: "180px"}}><p>{dep.userId}</p></div>
                                <div style={{width: "120px"}}><p>{dep.name}</p></div>
                                <div style={{width: "450px", display: "flex", flexDirection: "row", paddingTop: "24px"}}>
                                    {dep.currency ?
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <p>{dep.account} -&nbsp;</p>
                                            <p className="font-b" style={{color: "darkorange"}}>{dep.dollarPrice}</p>
                                            <p style={{fontSize: "11px", marginTop: "3px"}}>&nbsp;{dep.currency}</p>
                                        </div>
                                        :
                                        <p>{dep.account}</p>
                                    }
                                </div>
                                <div style={{width: "100px"}}><p>{dep?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p></div>
                                <div style={{width: "80px"}}>
                                    {dep.payMethod === '0' ? <p>무통장충전</p> :
                                        dep.payMethod === '0' ? <p>무통장환급</p> :
                                            dep.payMethod === '0' ? <p>페이팔충전</p> :
                                                dep.payMethod === '0' ? <p>페이팔환급</p> :
                                                    dep.payMethod === '0' ? <p>위안충전</p> :
                                                        <p>위안환급</p>
                                    }
                                </div>
                                <div style={{width: "50px"}}>
                                    {dep.status === "0" ?
                                <p className="font-b" style={{color: "#7C9EDC"}}>대기</p>
                                :
                                dep.status === "1" ?
                                    <p className="font-b" style={{color: "#A3E98C"}}>완료</p>
                                    :
                                    <p className="font-b" style={{color: "#EB939D"}}>취소</p>
                            }
                                </div>
                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            { depConfirmComplShow ?
            <MsgSelectModal depConfirmComplShow={depConfirmComplShow} depConfirmComplMsg1={depConfirmComplMsg1} depConfirmComplMsg2={depConfirmComplMsg2}
                            closeDepConfirmCompl={closeDepConfirmCompl} modifyDepConfirmCompl={modifyData}/>
                : null
            }
        </div>
    );
}

export default AdminDepositWaitPg
