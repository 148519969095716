import '../../css/mobile/MUserMyPg.css';
import MFooterSection from "../../components/mobile/MFooterSection";
import MHeader from "../../components/mobile/MHeader";
import mypgPayinfo from "../../assets/img/mypgPayinfo.png";
import React, {useEffect, useRef, useState} from "react";
import axios from '../../services/axiosInstance';
import profileImage from '../../assets/img/profileDefaultImg.png'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import commonHeader from "../../assets/img/commonHeader.png";
import mypgOrder from "../../assets/img/mypgOrder.png";
import MyPageProcessNext from "../../assets/img/MyPageProcessNext.png";
import mypgDelivery from "../../assets/img/mypgDelivery.png";
import mypgReturnIcon from "../../assets/img/mypgReturnIcon.png";
import CommonLoading from "../../components/web/modal/CommonLoading";
import IntegDetailPopup from "../../components/web/modal/IntegDetailPopup";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import Expand from "../../assets/img/commonBottom.png";
import mypgPayCardOrder from "../../assets/img/mypgPayCardOrder.png";
import noData from "../../assets/img/noData.png";
import mypgWarn from "../../assets/img/mypgWarn.png";
import commonClose from "../../assets/img/commonClose.png";
import mypgOrderdt from "../../assets/img/mypgOrderdt.png";
import mypgPayCardDelivery from "../../assets/img/mypgPayCardDelivery.png";
import MDeliveryPriceDetail from "../../components/mobile/MDeliveryPriceDetail";
import {useWebSocket} from "../../services/WebsocketContext";
import withAuth from "../../auth/UserRouteAuth";
import i18n from "../../i18n";

const MUserMyPg = () => {
    const subTitle = (title, img) => {

        return (
            <div className='m-a-user-my-page-sub-title'>
                <img className='m-a-user-my-page-sub-title-icon' src={img} alt=''/>
                <div className='m-a-user-my-page-sub-title-text'>{title}</div>
            </div>
        )
    }

    useEffect(() => {
        setOrderTypeSelectCommonValue(t('orderType'))
        setOrderStSelectCommonValue(t(`state`));
        setPeriodSelectCommonValue(t('myPrd1'))
        setPayStSelectCommonValue(t(`state`));
        setPayPeriodSelectCommonValue(t('myPrd1'))
        setDacPeriodSelectCommonValue(t('myPrd1'))
    }, [i18n.language]);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    // 주문서 기간셀렉트
    const [periodSelectShow, setPeriodSelectShow] = useState(false);
    const [periodSelectCommonValue, setPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const periodButtonRef = useRef(null);
    const periodDivRef = useRef(null);

    const periodBtHandle = (newValue) => {
        setPeriodSelectShow(false);
        setPeriodSelectCommonValue(newValue);
    };
    const periodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const periodClickOutside = (event) => {
            if (
                periodButtonRef.current &&
                periodButtonRef.current.contains(event.target)
            ) {
                setPeriodSelectShow(true);
            } else if (
                periodDivRef.current &&
                !periodDivRef.current.contains(event.target)
            ) {
                setPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', periodClickOutside);
        return () => {
            document.removeEventListener('mousedown', periodClickOutside);
        };
    }, []);

    // 주문서 유형셀렉트
    const [orderTypeSelectShow, setOrderTypeSelectShow] = useState(false);
    const [orderTypeSelectCommonValue, setOrderTypeSelectCommonValue] = useState(t(`orderType`));
    const orderTypeButtonRef = useRef(null);
    const orderTypeDivRef = useRef(null);

    const orderTypeBtHandle = (newValue) => {
        setOrderTypeSelectShow(false);
        setOrderTypeSelectCommonValue(newValue);
    };
    const orderTypeDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const orderTypeClickOutside = (event) => {
            if (
                orderTypeButtonRef.current &&
                orderTypeButtonRef.current.contains(event.target)
            ) {
                setOrderTypeSelectShow(true);
            } else if (
                orderTypeDivRef.current &&
                !orderTypeDivRef.current.contains(event.target)
            ) {
                setOrderTypeSelectShow(false);
            }
        };
        document.addEventListener('mousedown', orderTypeClickOutside);
        return () => {
            document.removeEventListener('mousedown', orderTypeClickOutside);
        };
    }, []);

    // 주문서 상태셀렉트
    const [orderStSelectShow, setOrderStSelectShow] = useState(false);
    const [orderStSelectCommonValue, setOrderStSelectCommonValue] = useState(t(`state`));
    const orderStButtonRef = useRef(null);
    const orderStDivRef = useRef(null);

    const myPgContentRef = useRef(null);
    const scrollToMyPgContent = () => {
        if (myPgContentRef.current) {
            myPgContentRef?.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    const orderStBtHandle = (newValue) => {
        setOrderStSelectShow(false);
        setOrderStSelectCommonValue(newValue);
        filterOrderSt(newValue);
        scrollToMyPgContent();
    };
    const orderStDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const orderStClickOutside = (event) => {
            if (
                orderStButtonRef.current &&
                orderStButtonRef.current.contains(event.target)
            ) {
                setOrderStSelectShow(true);
            } else if (
                orderStDivRef.current &&
                !orderStDivRef.current.contains(event.target)
            ) {
                setOrderStSelectShow(false);
            }
        };
        document.addEventListener('mousedown', orderStClickOutside);
        return () => {
            document.removeEventListener('mousedown', orderStClickOutside);
        };
    }, []);
    const [memberInfo, setMemberInfo] = useState([{}]);
    const [memberDto, setMemberDto] = useState([]);
    const selectMember = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                setMemberDto(response.data[0]);
                setProfileName(response.data[0].userName)
                setMemberInfo(response.data)

                if (response.data && response.data[0] && response.data[0].profileDTO && response.data[0].profileDTO.filename) {
                    setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                } else {
                    setProfilePhotoLine(profileImage)
                }
            })
            .catch(() => {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (LCAT || SSAT) {
            selectMember();
        }
    }, [LCAT, SSAT])

    const memberDtoRef = useRef(memberDto);
    useEffect(() => {
        memberDtoRef.current = memberDto;
    }, [memberDto])

    useEffect(() => {
        if (memberDto && memberDto.mbNum) {
            selectTotalUnreadCount(memberDto.mbNum);
        }
    }, [memberDto]);

    const [totalUnreadCount, setTotalUnreadCount] = useState(0);
    const selectTotalUnreadCount = (mbNum) => {
        axios.get(`/order/selectTotalUnreadCount/${mbNum}`, {
            headers: {
                Authorization: SSAT ? SSAT : LCAT
            }
        })
            .then(response => {
                setTotalUnreadCount(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberDto.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(memberDto.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/user/${memberDto.mbNum}/queue/adminMessage`, onMessageReceived);
                subscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`, onChatAdminUpdate);
                subscribe(`/user/${memberDto.mbNum}/queue/adminOrderStUpdate`, receivedOrderStChange);
                subscribe(`/user/${memberDto.mbNum}/queue/adminOrderDivideMerge`, receivedOrderDivideMerge);
                subscribe(`/user/${memberDto.mbNum}/queue/adminUpdateOrder`, updateOrderList);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminMessage`);
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`);
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminOrderStUpdate`);
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminOrderDivideMerge`);
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminUpdateOrder`);
                isSubscribedRef.current = false;
            }
        };

    }, [memberDto.mbNum, isConnected, stompClient]);

    const onMessageReceived = async (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (newMessage.mstMbNum === memberDtoRef.current.mbNum && newMessage.memberDTO.role === '1') {
            selectTotalUnreadCount();
        }
    }

    const onChatAdminUpdate = ((payload) => {
        selectTotalUnreadCount();
    })

    const [orderdtList, setOrderdtList] = useState([{}]);
    const [productsList, setProductsList] = useState([]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);

    const selectTotalOrderList = ((date) => {
        setIsLoading(true);

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(date)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(date)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(date)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(date)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(date)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/selectTotalOrderList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate,
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                // sortedData = sortedData.slice(0, 10);
                setOrderdtList(response.data);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

            })
            .catch(() => {
            })
            .finally(() => {
                setIsLoading(false);
            })
    })

    useEffect(() => {
        selectTotalOrderList(periodSelectCommonValue);
    }, [periodSelectCommonValue]);

    const myOrdPgMove = (ordNum) => () => {
        navigate(`/UserMyOrderPg/${ordNum}`);
    };

    const [paySeqnum, setPaySeqnum] = useState(null);
    const [payShipco, setPayShipco] = useState('');
    const [deliveryDetailSt, setDeliveryDetailSt] = useState(false);
    const deliveryDetailFalseFn = (() => {
        setDeliveryDetailSt(false);
    })
    const deliveryDetailTrueFn = ((seqNum, shipco) => {
        setPaySeqnum(seqNum);
        setPayShipco(shipco);
        setDeliveryDetailSt(true);
    })

    const updateAppRecinfo = (updatedPayinfo) => {
        setFilteredList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) => {
                if (payinfo.appRecDTO && payinfo.appRecDTO.seqnum === updatedPayinfo.seqnum) {
                    return {...payinfo, appRecDTO: updatedPayinfo};
                }
                return payinfo;
            });
        });
    };

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };

    const receivedOrderStChange = ((payload) => {
        let data = JSON.parse(payload.body);
        setFilteredList(prevList => {
            return prevList.map(item => {
                // 조건에 맞는 항목 찾기
                if (item.type === 'orderdt' && item.seqnum === data.orderSeqNum) {
                    // 해당 항목의 orderStatus를 data.orderSt로 업데이트
                    return {
                        ...item,
                        orderStatus: data.orderSt
                    };
                }
                // 다른 항목들은 그대로 유지
                return item;
            });
        });
    });

    const receivedOrderDivideMerge = ((payload) => {
        let data = JSON.parse(payload.body);
        selectTotalOrderList(periodSelectCommonValue);
        selectedAllProcessCount(memberDto.mbNum)
    })

    const updateOrderList = ((payload) => {
        let data = JSON.parse(payload.body);
        selectTotalOrderList(periodSelectCommonValue);
        selectedAllProcessCount(memberDto.mbNum)
    })

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [orderListDisplayLimit, setOrderListDisplayLimit] = useState(20);
    const handleMoreOrderList = () => {
        setOrderListDisplayLimit(prev => prev + 20);
    }

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SEA"
        } else if (dvType === "D04") {
            return "DHL"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else if (dvType === "D15") {
            return "EMSP"
        } else if (dvType === "D16") {
            return "FedEx-E"
        } else if (dvType === "D17") {
            return "UPS-E"
        } else {
            return defalutShipco;
        }
    })

    useEffect(() => {
        const combined = [
            ...orderdtList.map((item) => ({
                type: 'orderdt',
                date: item.modiTime || item.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
            ...payinfoList.map((item) => ({
                type: 'payinfo',
                date: item?.modiTime || item?.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
        ];

        combined.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
        // combined.sort((a, b) => moment(b.date).isAfter(moment(a.date)) ? 1 : -1);

        const filterStatus = (order) => {
            if (orderStSelectCommonValue === t(`state`)) return true;
            switch (orderStSelectCommonValue) {
                case "PS0":
                    return (order.type === 'orderdt' && order.orderStatus === "OS09") ||
                        (order.type === 'payinfo' && order.payStatus === "PS0");
                case "PS1":
                    return (order.type === 'orderdt' && order.orderStatus === "OS10") ||
                        (order.type === 'payinfo' && order.payStatus === "PS1");
                case "PS2":
                    return (order.type === 'orderdt' && order.orderStatus === "OS11") ||
                        (order.type === 'payinfo' && order.payStatus === "PS2");
                case "PS3":
                    return (order.type === 'orderdt' && order.orderStatus === "OS12") ||
                        (order.type === 'payinfo' && order.payStatus === "PS3");
                case "PS4":
                    return (order.type === 'orderdt' && order.orderStatus === "OS13") ||
                        (order.type === 'payinfo' && order.payStatus === "PS4");
                case "PS5":
                    return (order.type === 'orderdt' && order.orderStatus === "OS14") ||
                        (order.type === 'payinfo' && order.payStatus === "PS5");
                default:
                    return (order.type === 'orderdt' && order.orderStatus === orderStSelectCommonValue) ||
                        (order.type === 'payinfo' && order.payStatus === orderStSelectCommonValue);
            }
        }

        const filterOrderType = (order) => {
            if (orderTypeSelectCommonValue === t('orderType')) {
                return true;  // 모든데이터
            }
            if (orderTypeSelectCommonValue === t('myTab1')) {
                return order.type === 'orderdt';
            }
            if (orderTypeSelectCommonValue === t('deliveryOption')) {
                return order.type === 'payinfo';
            }
            return true;
        };

        const newList = combined.filter((order) => {
            if (!filterStatus(order)) return false;
            if (!filterOrderType(order)) return false;
            if (searchQuery === "") return true;

            if (order.type === 'orderdt') {
                return (
                    (order.shipco && order.shipco.toLowerCase().includes(searchQuery)) ||
                    (order.recinfoDTO.recname && order.recinfoDTO.recname.toLowerCase().includes(searchQuery)) ||
                    (order.recinfoDTO.countryDTO && order.recinfoDTO.countryDTO.countryEngNm.toLowerCase().includes(searchQuery)) ||
                    (order.productsDTO && order.productsDTO.some((prod) => prod.name && prod.name.toLowerCase().includes(searchQuery)))
                );
            }
            if (order.type === 'payinfo') {
                return (
                    (order && convertToShipco(order.dvType, order.orderdtDTO?.shipco).toLowerCase().includes(searchQuery)) ||
                    (order.appRecDTO && order.appRecDTO.countryDTO && order.appRecDTO.countryDTO.countryEngNm.toLowerCase().includes(searchQuery)) ||
                    (order.appRecDTO && order.appRecDTO.recname && order.appRecDTO.recname.toLowerCase().includes(searchQuery)) ||
                    (order.packListDTO && order.packListDTO.productsDTO && order.packListDTO.productsDTO.some((prod) => prod.name && prod.name.toLowerCase().includes(searchQuery)))
                );
            }
        });
        setFilteredList(newList);
    }, [orderdtList, payinfoList, searchQuery, orderStSelectCommonValue, orderTypeSelectCommonValue]);

    useEffect(() => {
        if (memberDto.mbNum)
            selectedAllProcessCount(memberDto.mbNum)
    }, [memberDto])

    const [processData, setProcessData] = useState([]);
    const selectedAllProcessCount = ((mbNum) => {
        axios({
            method: 'get',
            url: '/order/selectedAllProcessCount',
            params: {
                mbNum: mbNum
            }
        })
            .then((response) => {
                setProcessData(response.data);
            })
            .catch((error) => {
                console.log("프로세스수량 조회실패 : ", error)
            })
    })

    const selectNoDateTotalOrderList = ((orderSt) => {
        axios({
            method: 'get',
            url: '/order/selectTotalOrderList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                // sortedData = sortedData.slice(0, 10);
                setOrderdtList(response.data);
                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                orderStBtHandle(orderSt);
                setPeriodSelectCommonValue(t('all'))

            })
            .catch((error) => {
                console.log("노데이트 주문조회실패 : ", error)
            })
    })

    const [payPeriodSelectShow, setPayPeriodSelectShow] = useState(false);
    const [payPeriodSelectCommonValue, setPayPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const payPeriodButtonRef = useRef(null);
    const payPeriodDivRef = useRef(null);

    const payPeriodBtHandle = (newValue) => {
        setPayPeriodSelectShow(false);
        setPayPeriodSelectCommonValue(newValue);
    };
    const payPeriodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const payPeriodClickOutside = (event) => {
            if (
                payPeriodButtonRef.current &&
                payPeriodButtonRef.current.contains(event.target)
            ) {
                // Clicked on the duration button
                setPayPeriodSelectShow(true);
            } else if (
                payPeriodDivRef.current &&
                !payPeriodDivRef.current.contains(event.target)
            ) {
                // Clicked outside the div
                setPayPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', payPeriodClickOutside);
        return () => {
            document.removeEventListener('mousedown', payPeriodClickOutside);
        };
    }, []);

    const filterOrderSt = (status) => {

        let viewStatus;
        switch (status) {
            case t(`state`):
                viewStatus = t(`state`);
                break;
            case "OS00":
                viewStatus = t(`prodStLabel1`);
                break;
            case "OS01":
                viewStatus = t(`prodStLabel2`);
                break;
            case "OS02":
                viewStatus = t(`prodStLabel3`);
                break;
            case "OS03":
                viewStatus = t(`prodStLabel4`);
                break;
            case "OS04":
                viewStatus = t(`prodStLabel14`);
                break;
            case "OS05":
                viewStatus = t(`prodStLabel5`);
                break;
            case "OS06":
                viewStatus = t(`prodStLabel15`);
                break;
            case "OS07":
                viewStatus = t(`prodStLabel6`);
                break;
            case "OS08":
                viewStatus = t(`prodStLabel7`);
                break;
            case "PS0":
                viewStatus = t(`prodStLabel8`);
                break;
            case "PS1":
                viewStatus = t(`prodStLabel9`);
                break;
            case "PS2":
                viewStatus = t(`prodStLabel10`);
                break;
            case "PS3":
                viewStatus = t(`prodStLabel11`);
                break;
            case "PS4":
                viewStatus = t(`prodStLabel12`);
                break;
            case "PS5":
                viewStatus = t(`prodStLabel13`);
                break;
        }
        return viewStatus;
    };

    const [payList, setPayList] = useState([]);
    const payHistoryList = ((dateParam) => {

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/payHistoryList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate,
            },
        })
            .then((response) => {
                // payType이 통합결제이거나 자식이 아닌 단독결제건(상품금액/해외배송료/기타비용)만 출력
                const filteredData = response.data.filter(data => data.payType === 'PT3' || !data.childFl);
                setPayList(filteredData);
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const sortedPayList = payList.sort((a, b) => b.seqNum - a.seqNum);
    const [payListDisplayLimit, setPayListDisplayLimit] = useState(20);
    const handleMorePayList = () => {
        setPayListDisplayLimit(prev => prev + 20);
    }

    const [payStSelectShow, setPayStSelectShow] = useState(false);
    const [payStSelectCommonValue, setPayStSelectCommonValue] = useState(t(`state`));
    const payStButtonRef = useRef(null);
    const payStDivRef = useRef(null);

    const payStBtHandle = (newValue) => {
        setPayStSelectShow(false);
        setPayStSelectCommonValue(newValue);
        filterPaySt(newValue);
    };

    useEffect(() => {
        payHistoryList(payPeriodSelectCommonValue);
    }, [payPeriodSelectCommonValue]);

    const payStDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const payStClickOutside = (event) => {
            if (
                payStButtonRef.current &&
                payStButtonRef.current.contains(event.target)
            ) {
                setPayStSelectShow(true);
            } else if (
                payStDivRef.current &&
                !payStDivRef.current.contains(event.target)
            ) {
                setPayStSelectShow(false);
            }
        };
        document.addEventListener('mousedown', payStClickOutside);
        return () => {
            document.removeEventListener('mousedown', payStClickOutside);
        };
    }, []);

    const filterStatus = (pay) => {
        if (payStSelectCommonValue === t(`state`)) return true;
        return pay.payStatus === payStSelectCommonValue;
    }

    const filterPaySt = (status) => {
        let viewStatus;
        switch (status) {
            case t(`state`):
                viewStatus = t(`state`)
                break;
            case "PS0":
                viewStatus = t(`prodStLabel8`)
                break;
            case "PS1":
                viewStatus = t(`prodStLabel9`)
                break;
            case "PS2":
                viewStatus = t(`prodStLabel10`)
                break;
        }
        return viewStatus;
    };

    const [dacPeriodSelectShow, setDacPeriodSelectShow] = useState(false);
    const [dacPeriodSelectCommonValue, setDacPeriodSelectCommonValue] = useState(t(`myPrd1`));
    const dacPeriodButtonRef = useRef(null);
    const dacPeriodDivRef = useRef(null);

    const dacPeriodBtHandle = (newValue) => {
        setDacPeriodSelectShow(false);
        setDacPeriodSelectCommonValue(newValue);
        dacHistoryList(newValue);
    };
    const dacPeriodDivHandle = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const dacPeriodClickOutside = (event) => {
            if (
                dacPeriodButtonRef.current &&
                dacPeriodButtonRef.current.contains(event.target)
            ) {
                // Clicked on the duration button
                setDacPeriodSelectShow(true);
            } else if (
                dacPeriodDivRef.current &&
                !dacPeriodDivRef.current.contains(event.target)
            ) {
                // Clicked outside the div
                setDacPeriodSelectShow(false);
            }
        };
        document.addEventListener('mousedown', dacPeriodClickOutside);
        return () => {
            document.removeEventListener('mousedown', dacPeriodClickOutside);
        };
    }, []);

    const [dacList, setDacList] = useState([]);
    const dacHistoryList = ((dateParam) => {

        let globalDate;
        if (["1개월 전", "1 month ago", "1ヶ月前", "1个月前", "1 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "1개월 전";
        } else if (["3개월 전", "3 months ago", "3ヶ月前", "3个月前", "3 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "3개월 전";
        } else if (["6개월 전", "6 months ago", "6ヶ月前", "6个月前", "6 Bulan yang Lalu"].includes(dateParam)) {
            globalDate = "6개월 전";
        } else if (["1년 전", "1 year ago", "1年前", "1年前", "1 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "1년 전";
        } else if (["2년 전", "2 years ago", "2年前", "2年前", "2 Tahun yang Lalu"].includes(dateParam)) {
            globalDate = "2년 전";
        } else {
            globalDate = null;
        }

        axios({
            method: 'get',
            url: '/order/dacHistoryList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                date: globalDate
            },
        })
            .then((response) => {
                setDacList(response.data)
                // console.log("예적쿠 리스트 : ", response.data)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        dacHistoryList(dacPeriodSelectCommonValue);
    }, [dacPeriodSelectCommonValue]);

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }
        return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`;
    };

    const sortedDacList = dacList.sort((a, b) => b.seqNum - a.seqNum);
    const initialLimit = 10;

    const [dacDisplayLimits, setDacDisplayLimits] = useState({
        deposit: initialLimit,
        point: initialLimit,
        coupon: initialLimit,
    });

    const handleMoreDacList = (type) => {
        setDacDisplayLimits(prevLimits => ({
            ...prevLimits,
            [type]: prevLimits[type] + 10
        }));
    };

    const [downNoSelectSt, setDownNoSelectSt] = useState(false);
    const closeDownNoSelect = (() => {
        setDownNoSelectSt(false);
    })

    const [guidePopupOrderSt, setGuidePopupOrderSt] = useState({});
    const openGuidePopupOrder = (index) => {
        setGuidePopupOrderSt(prevStates => ({...prevStates, [index]: true}));
    }
    const closeGuidePopupOrder = (index) => {
        setGuidePopupOrderSt(prevStates => ({...prevStates, [index]: false}));
    }

    const [guidePopupPaySt, setGuidePopupPaySt] = useState({});
    const openGuidePopupPay = ((index) => {
        setGuidePopupPaySt(prevStates => ({...prevStates, [index]: true}));
    })
    const closeGuidePopupPay = ((index) => {
        setGuidePopupPaySt(prevStates => ({...prevStates, [index]: false}));
    })

    const [integPayDetailPopupSt, setIntegPayDetailPopupSt] = useState(false);
    const [integPaySeqnum, setIntegPaySeqnum] = useState(null);
    const integPayDetail = ((seqnum) => {
        setIntegPaySeqnum(seqnum);
        setIntegPayDetailPopupSt(true)
    })
    const integDetailPopupClose = (() => {
        setIntegPayDetailPopupSt(false);
    })
    useEffect(() => {
        if (integPaySeqnum !== null) {
            setIntegPayDetailPopupSt(true);
        }
    }, [integPaySeqnum]);

    // 국제배송조회 팝업상태
    const selectOsTrackNum = ((shipco, trackNum) => {

        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (shipco === "FedEx" || shipco === "FedEx-E") {
            const url = `https://www.fedex.com/fedextrack/?trknbr=${trackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "UPS" || shipco === "UPS-E") {
            const url = `https://www.ups.com/track?loc=ko_KR&trackNums=${trackNum}&requester=ST/trackdetails`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "DHL") {
            const url = `https://www.dhl.com/kr-ko/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${trackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (shipco === "EMS" || shipco === "SEA" || shipco === "EMSP" || shipco === "K-Packet" || shipco === "Small-Packet") {
            const url = `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=${trackNum}&displayHeader=N`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
    })

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: {color: "#98B0D4", label: t(`prodStLabel1`)},
            OS01: {color: "#98B0D4", label: t(`prodStLabel2`)},
            OS02: {color: "#98B0D4", label: t(`prodStLabel3`)},
            OS03: {color: "#FFB378", label: t(`prodStLabel4`)},
            OS04: {color: "#98B0D4", label: t(`prodStLabel14`)},
            OS05: {color: "#FFB378", label: t(`prodStLabel5`)},
            OS06: {color: "#98B0D4", label: t(`prodStLabel15`)},
            OS07: {color: "#6EDA60", label: t(`prodStLabel6`)},
            OS08: {color: "#98B0D4", label: t(`prodStLabel7`)},
            OS09: {color: "#98B0D4", label: t(`prodStLabel8`)},
            OS10: {color: "#98B0D4", label: t(`prodStLabel9`)},
            OS11: {color: "#FFB378", label: t(`prodStLabel10`)},
            OS12: {color: "#979696", label: t(`prodStLabel11`)},
            OS13: {color: "#FF8F62", label: t(`prodStLabel12`)},
            OS14: {color: "#FF8F62", label: t(`prodStLabel13`)},
        };

        const style = orderStStyles[orderSt] || {color: "black", label: null};
        return <p style={{margin: "0", color: style.color}}>{style.label}</p>;
    };

    const selectedTotalPricePrint = (pay) => {
        if (!pay) return;

        let prodTotal = 0;
        let shipTotal = 0;
        let etcTotal = 0;
        let integTotal = 0;

        // 상품금액 총합 계산
        if (pay.payType === "PT0") {
            const ttEtcFee = pay.ttEtcFee || 0;
            const ttProdExpense = pay.ttProdExpense || 0;
            prodTotal += ttEtcFee + ttProdExpense;
            return prodTotal;

            // 해외배송비 총합 계산
        } else if (pay.payType === "PT1") {
            const prices = [
                pay.ttFedexPrice, pay.ttFedexEcoPrice, pay.ttDhlPrice, pay.ttEmsPrice,
                pay.ttEmsPrePrice, pay.ttUpsPrice, pay.ttUpsEcoPrice, pay.ttKPackPrice,
                pay.ttAirSPrice, pay.ttSeaPrice, pay.ttDomPrice
            ];
            const validPrice = prices.find(price => price > 0) || 0;
            shipTotal += validPrice;
            return shipTotal;

            // 기타비용 총합 계산
        } else if (pay.payType === "PT2") {
            const courierPay = pay.courierPay || 0;
            const returnFee = pay.returnFee || 0;
            const deliveryFee = pay.deliveryFee || 0;
            const repackFee = pay.repackFee || 0;
            const agencyFee = pay.agencyFee || 0;
            const comprFee = pay.comprFee || 0;
            const etcPrice = pay.etcPrice || 0;
            const etcFee = pay.etcFee || 0;
            etcTotal += courierPay + returnFee + deliveryFee + repackFee + agencyFee + comprFee + etcPrice + etcFee;
            return etcTotal;
        } else if (pay.payType === "PT3") {
            const ttProdPrice = pay.ttProdExpense || 0;
            const ttEtcFee = pay.ttEtcFee || 0;
            const ttEtcPrice = pay.ttEtcPrice || 0;
            const ttShipExpense = pay.ttShipExpense || 0;
            integTotal += ttProdPrice + ttEtcFee + ttEtcPrice + ttShipExpense;
            return integTotal;
        }
    };

    // 현재 사용자 브라우저위치
    const pathComponent = window.location.pathname;
    // ===================================User Info

    // 로그아웃 axios
    const logout = () => axios({
        method: 'post',
        url: '/member/logout',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        }
    })
        .then(() => {
            if (SSAT) {
                sessionStorage.removeItem('AToken')
                sessionStorage.removeItem('lastLoginTime')
            } else {
                localStorage.removeItem('AToken');
                localStorage.removeItem('lastLoginTime')
            }
            navigate("/UserLoginPg")
        })
        .catch(() => {
            if (SSAT) {
                sessionStorage.removeItem('AToken')
                sessionStorage.removeItem('lastLoginTime')
            } else {
                localStorage.removeItem('AToken');
                localStorage.removeItem('lastLoginTime')
            }
            navigate("/UserLoginPg")
        })

    //메인메뉴 드롭다운 state
    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);
    const [profileName, setProfileName] = useState("");

    const [dbCoupon, setDbCoupon] = useState('');
    useEffect(() => {
        const activeCouponCount = memberInfo[0] && memberInfo[0].coupons && memberInfo[0].coupons.filter(coupon => coupon && coupon.activeFl === true).length;
        setDbCoupon(activeCouponCount || 0);
    }, [memberInfo]);

    return (
        <div className='m-a-user-my-pg-container font-t-cont'>
            <MHeader title={t(`hdMypg`)} url={"/"}/> {/*마이페이지*/}
            <div className='m-a-user-my-page-user-info m-b-15'>
                <div className={'m-a-user-my-page-user-profile-img-box'} onClick={() => navigate("/UserChatAlarmPg")}>
                    <img className='m-a-user-my-page-profile-img' src={profilePhotoLine} alt=''/>
                    <div className={'m-a-user-my-page-profile-unread-cnt'}>
                        {totalUnreadCount && totalUnreadCount > 0 ? totalUnreadCount : null}
                    </div>
                </div>
                <div className='m-a-user-my-page-user-info-frame'>
                    <div className="font-b">{profileName}</div>
                    <div className="m-a-user-my-page-user-info-row">
                        <div className='m-a-user-my-page-user-info-button'>
                            <div className='m-a-user-my-page-user-info-button-text'
                                 onClick={() => navigate("/UserModiPg")}>{t(`hdBt1`)}</div>
                            <div className='m-a-user-my-page-user-info-vertical-divider'/>
                            <div className='m-a-user-my-page-user-info-button-text' onClick={logout}>{t(`hdBt2`)}</div>
                            {/*로그아웃*/}
                        </div>
                    </div>
                    <div className='m-a-user-my-page-user-info-row'>
                        <div className='m-a-user-my-page-user-info-row-label'>{t(`myOrdPc1`)}</div>
                        {/*사서함번호*/}
                        <div className='m-a-user-my-page-user-info-row-value'>
                            BT <div className='m-a-user-my-page-user-info-row-value-strong'>{memberDto.mbNum}</div>
                        </div>
                    </div>
                    <div className='m-a-user-my-page-user-info-row'>
                        <div className='m-a-user-my-page-user-info-row-label'>{t(`myOrdPc2`)}</div>
                        <div className='m-a-user-my-page-user-info-row-value'>
                            <div className='m-a-user-my-page-user-info-row-value-strong'>
                                {memberDto && memberDto?.deposit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                        </div>
                    </div>
                    <div className='m-a-user-my-page-user-info-row'>
                        <div className='m-a-user-my-page-user-info-row-label'>{t(`myOrdPc3`)}</div>
                        <div className='m-a-user-my-page-user-info-row-value'>
                            <div className='m-a-user-my-page-user-info-row-value-strong'>
                                {memberDto && memberDto?.point?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                        </div>
                    </div>
                    <div className='m-a-user-my-page-user-info-row'>
                        <div className='m-a-user-my-page-user-info-row-label'>My{t(`modSHeader13`)}</div>
                        {/*My쿠폰*/}
                        <div className={'m-a-order-section-payment-discount-label'}>
                            {dbCoupon && dbCoupon > 0 ?
                                <div className={'m-a-order-section highlight'}>{dbCoupon}</div> :
                                <div className={'m-a-order-section'}>0</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='m-a-user-my-page-divider-bold'/>
            {subTitle(t(`myOrdPc`), commonHeader)} {/*주문현황*/}
            <div className="m-a-user-my-page-divider"/>
            <div className="m-a-user-my-page-order-status-frame font-c-default">
                <div className="m-a-user-my-page-order-status">
                    <img className='m-a-user-my-page-order-icon' src={mypgOrder} alt=''/>
                    <div className="m-a-user-my-page-order-title font-c-default"> {t(`myOrdPcHeader1`)}</div>
                    {/*주문*/}
                    {/*입고대기*/}
                    <div className='m-a-user-my-page-order-row'
                         onClick={() => selectNoDateTotalOrderList("OS00")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc1`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS00 > 0 ? "#FFAF34" : "#5b5b5b"}}> {processData.OS00 || 0}</div>
                    </div>
                    {/*결제대기*/}
                    <div className='m-a-user-my-page-order-row'
                         onClick={() => selectNoDateTotalOrderList("OS01")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc2`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS01 > 0 ? "#FFAF34" : "#5b5b5b"}}> {processData.OS01 || 0}</div>
                    </div>
                    {/*결제중*/}
                    <div className='m-a-user-my-page-order-row'
                         onClick={() => selectNoDateTotalOrderList("OS02")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc3`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS02 > 0 ? "#FFAF34" : "#5b5b5b"}}> {processData.OS02 || 0}</div>
                    </div>
                    {/*결제완료*/}
                    <div className='m-a-user-my-page-order-row'
                         onClick={() => selectNoDateTotalOrderList("OS03")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc4`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS03 > 0 ? "#FFAF34" : "#5b5b5b"}}> {processData.OS03 || 0}</div>
                    </div>
                    {/*구매중*/}
                    <div className='m-a-user-my-page-order-row'
                         onClick={() => selectNoDateTotalOrderList("OS04")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc5`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS04 > 0 ? "#FFAF34" : "#5b5b5b"}}> {processData.OS04 || 0}</div>
                    </div>
                    {/*구매완료*/}
                    <div className='m-a-user-my-page-order-row'
                         onClick={() => selectNoDateTotalOrderList("OS05")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc6`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS05 > 0 ? "#FFAF34" : "#5b5b5b"}}> {processData.OS05 || 0}</div>
                    </div>
                </div>
                <img className="m-a-user-my-page-process-icon" src={MyPageProcessNext} alt=''/>
                <div className="m-a-user-my-page-order-status">
                    <img className='m-a-user-my-page-order-icon' src={mypgDelivery} alt=''/>
                    <div className='m-a-user-my-page-order-title'> {t(`myOrdPcHeader2`)}</div>
                    {/*해외배송*/}
                    {/*입고중*/}
                    <div className="m-a-user-my-page-order-row" onClick={() => selectNoDateTotalOrderList("OS06")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc7`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS06 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS06 || 0}</div>
                    </div>
                    {/*센터도착*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("OS07")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc8`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS07 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS07 || 0}</div>
                    </div>
                    {/*패킹중*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("OS08")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc9`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS08 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS08 || 0}</div>
                    </div>
                    {/*결제대기*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("PS0")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc10`)}</div>
                        <div
                            className='m-a-user-my-page-order-row-value'
                            style={{color: processData.OS09 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS09 + processData.PS0 || 0}</div>
                    </div>
                    {/*결제중*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("PS1")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc11`)}</div>
                        <div
                            className='m-a-user-my-page-order-row-value'
                            style={{color: processData.OS10 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS10 + processData.PS1 || 0}</div>
                    </div>
                    {/*결제완료*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("PS2")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc12`)}</div>
                        <div
                            className='m-a-user-my-page-order-row-value'
                            style={{color: processData.OS11 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS11 + processData.PS2 || 0}</div>
                    </div>
                    {/*출고완료*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("PS3")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc13`)}</div>
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS12 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS12 + processData.PS3 || 0}</div>
                    </div>
                </div>
                <img className="m-a-user-my-page-process-icon" src={mypgReturnIcon} alt=''/>
                <div className="m-a-user-my-page-order-status">
                    <img className='m-a-user-my-page-order-icon' src={mypgPayinfo} alt=''/>
                    <div className='m-a-user-my-page-order-title'>{t(`myOrdPcHeader3`)}</div>
                    {/*반송*/}
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("PS4")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc14`)}</div>
                        {/*반송중*/}
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS13 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS13 + processData.PS4 || 0}</div>
                    </div>
                    <div className='m-a-user-my-page-order-row' onClick={() => selectNoDateTotalOrderList("PS5")}>
                        <div className='m-a-user-my-page-order-row-label'>{t(`myPc15`)}</div>
                        {/*취소*/}
                        <div className='m-a-user-my-page-order-row-value'
                             style={{color: processData.OS14 > 0 ? "#FFAF34" : "#5b5b5b"}}>{processData.OS14 + processData.PS5 || 0}</div>
                    </div>
                </div>
            </div>
            <div className='m-a-user-my-page-divider-bold m-t-17'/>
            {subTitle(t(`myTab1`), mypgPayinfo)} {/*주문서*/}
            <div className='m-a-user-my-page-divider'/>
            <div className='m-a-user-my-page-order-sheet-frame'>
                <div className="display-flex flex-column wd-100pc gap8"
                     style={{top: 0, position: "sticky", zIndex: 1, backgroundColor: "#FFF"}}>
                    <div className='m-a-user-my-page-order-sheet-filter'>
                        <div className="m-a-user-my-page-order-sheet-filter-button" ref={periodButtonRef}
                             onClick={() => setPeriodSelectShow(true)}>
                            {periodSelectCommonValue}
                            <img className={'m-a-user-my-page-order-sheet-filter-icon'} src={Expand} alt={''}/>
                            {periodSelectShow &&
                                <div className={'m-a-user-my-page-order-sheet-filter-list'} ref={periodDivRef}
                                     onClick={periodDivHandle}>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => periodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}</div>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => periodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}</div>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => periodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}</div>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => periodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}</div>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => periodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}</div>
                                </div>
                            }
                        </div>
                        <div className="m-a-user-my-page-order-sheet-filter-button" ref={orderTypeButtonRef}
                             onClick={() => setOrderTypeSelectShow(true)}>
                            {orderTypeSelectCommonValue}
                            <img className={'m-a-user-my-page-order-sheet-filter-icon'} src={Expand} alt={''}/>
                            {orderTypeSelectShow &&
                                <div className={'m-a-user-my-page-order-sheet-filter-list'} ref={orderTypeDivRef}
                                     onClick={orderTypeDivHandle}>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => orderTypeBtHandle(t('orderType'))}>{t(`orderType`)}</div>
                                    {/*주문서유형*/}
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => orderTypeBtHandle(t('myTab1'))}>{t(`myTab1`)}</div>
                                    <div className="m-a-user-my-page-order-sheet-filter-item"
                                         onClick={() => orderTypeBtHandle(t('deliveryOption'))}>{t(`deliveryOption`)}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="m-a-user-my-page-order-sheet-filter-button" ref={orderStButtonRef}
                         onClick={() => setOrderStSelectShow(true)}>
                        {filterOrderSt(orderStSelectCommonValue)}
                        <img className={'m-a-user-my-page-order-sheet-filter-icon'} src={Expand} alt={''}/>
                        {orderStSelectShow &&
                            <div className={'m-a-user-my-page-order-sheet-filter-list'} ref={orderStDivRef}
                                 onClick={orderStDivHandle}>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle(t(`state`))}>{t(`state`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS00")}>{t(`prodStLabel1`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS01")}>{t(`prodStLabel2`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS02")}>{t(`prodStLabel3`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS03")}>{t(`prodStLabel4`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS04")}>{t(`prodStLabel14`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS05")}>{t(`prodStLabel5`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS06")}>{t(`prodStLabel15`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS07")}>{t(`prodStLabel6`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("OS08")}>{t(`prodStLabel7`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("PS0")}>{t(`prodStLabel8`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("PS1")}>{t(`prodStLabel9`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("PS2")}>{t(`prodStLabel10`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("PS3")}>{t(`prodStLabel11`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("PS4")}>{t(`prodStLabel12`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => orderStBtHandle("PS5")}>{t(`prodStLabel13`)}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='m-a-user-my-page-order-sheet-search'>
                        <input className='m-a-user-my-page-order-sheet-keyword' placeholder={t(`myPlaceHd`)}
                               spellCheck="false" type="text"
                               value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                    </div>
                    <div className='m-a-user-my-page-divider m-b-17'/>
                </div>
                {orderdtList.length === 0 || filteredList.length === 0 ? (
                    <div className={'m-a-user-my-page-no-data'}><img className={'m-a-user-my-page-no-data-img'} alt=""
                                                                     src={noData}/>No Data</div>
                ) : (
                    <div ref={myPgContentRef}>
                        {filteredList.slice(0, orderListDisplayLimit).map((order, index) => {
                            let data1 = 0, data2 = 0, data3 = 0, data4 = 0;
                            if (order.type === 'orderdt') {
                                // 상품미결제 + 관리자가 등록한 상품누락금 미결제에 추가
                                data1 = order.productsDTO?.reduce((sum, item) => {
                                    return (item.prodSt === "IS01" || item.prodSt === "IS02") && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                }, 0) || 0;
                                data1 += order.payInfoDTO?.reduce((sum, item) => {
                                    return (item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1'
                                    && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                                }, 0) || 0;

                                // 상품총결제 + 관리자가 등록한 상품누락금 결제완료에 추가
                                data2 = order.productsDTO?.reduce((sum, item) => {
                                    return item.prodSt !== "IS01" && item.prodSt !== "IS02" && item.prodSt !== "IS12" && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                                }, 0) || 0;
                                data2 += order.payInfoDTO?.reduce((sum, item) => {
                                    return item.payStatus !== "PS0" && item.payStatus !== "PS1" && item.payClass === '1'
                                    && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                                }, 0) || 0;

                                // 기타미결제
                                data3 = order.payInfoDTO?.reduce((sum, item) => {
                                    if ((item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1') {
                                        // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                        const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                        const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                        return sum + etcPrice + etcFee;
                                    }
                                    return sum;
                                }, 0) || 0;

                                // 기타총결제
                                data4 = order.payInfoDTO?.reduce((sum, item) => {
                                    if ((item.payStatus !== "PS0" && item.payStatus !== "PS1") && item.payClass === '1') {
                                        // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                                        const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                                        const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                                        return sum + etcPrice + etcFee;
                                    }
                                    return sum;
                                }, 0) || 0;
                            }
                            // 주문서
                            if (order.type === 'orderdt') {
                                return (
                                    <div className='m-a-user-my-page-order-sheet'
                                         key={`order-${order.seqnum}-${index}`}>
                                        <div className='m-a-user-my-page-order-sheet-row'
                                             onClick={myOrdPgMove(order.orderNumber)}>
                                            <div className='m-a-user-my-page-order-sheet-row-left'>
                                                <img className='m-a-user-my-page-order-sheet-row-icon'
                                                     src={mypgPayinfo} alt=''/>
                                                <div
                                                    className='m-a-user-my-page-order-sheet-row-title'>
                                                    {order?.orderNumber ? order.orderNumber : order.orderdtDTO?.orderNumber}
                                                </div>
                                            </div>
                                            <div className='m-a-user-my-page-order-sheet-text'>
                                                {order.regDate ? `${order.regDate.substring(0, 4)}-${order.regDate.substring(4, 6)}-${order.regDate.substring(6, 8)}` : ''}
                                            </div>
                                        </div>
                                        <div className='m-a-user-my-page-order-sheet-row'>
                                            <div
                                                className='m-a-user-my-page-order-sheet-text'>{order.recinfoDTO && order.recinfoDTO.recname}</div>
                                            <div
                                                className='m-a-user-my-page-order-sheet-text'>{order.recinfoDTO && order.recinfoDTO.countryDTO.countryEngNm}</div>
                                            <div className='m-a-user-my-page-order-sheet-text'>{order.shipco}</div>
                                        </div>
                                        <div
                                            className='m-a-user-my-page-order-sheet-status'>{orderStPrint(order?.orderStatus)}</div>
                                        {order.productsDTO && order.productsDTO.slice(0, 10).map((prod, prodIndex) => (
                                            <div className='m-a-user-my-page-order-sheet-item wd-100pc'
                                                 key={`product-${prod.seqnum}-${prodIndex}`}>
                                                <div className="admin-tb-text-cut wd-100pc">
                                                    <a className="m-r-4">
                                                        {
                                                            prod.prodTy === "P00" ? t(`myProdTy1`) :
                                                                prod.prodTy === "P01" ? t(`myProdTy2`) :
                                                                    prod.prodTy === "P02" ? t(`myProdTy3`) :
                                                                        prod.prodTy === "P03" ? t(`myProdTy4`) :
                                                                            prod.prodTy === "P04" ? t(`myProdTy5`) :
                                                                                prod.prodTy === "P05" ? t(`myProdTy6`) :
                                                                                    null
                                                        }
                                                    </a>
                                                    <a>{prod.name}</a>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='m-a-user-my-page-order-sheet-receipt'>
                                            <div className='m-a-user-my-page-order-sheet-row'>
                                                <div className='m-a-user-my-page-order-sheet-row-left'>
                                                    <img className='m-a-user-my-page-order-sheet-row-icon'
                                                         src={mypgPayCardOrder} alt=''/>
                                                    <div
                                                        className='m-a-user-my-page-order-sheet-row-title'> {t(`myOrddtCardHeader1`)}</div>
                                                    {/*주문서금액*/}
                                                </div>
                                                <img className='m-a-user-my-page-order-sheet-row-icon lg' src={mypgWarn}
                                                     onClick={() => openGuidePopupOrder(index)} alt=''/>
                                            </div>
                                            {/*상품미결제*/}
                                            <div className='m-a-user-my-page-order-sheet-row'>
                                                <div
                                                    className='m-a-user-my-page-order-receipt-label'>{t(`myOrddtCardHeader2`)}</div>
                                                <div
                                                    className={`m-a-user-my-page-order-receipt-value ${data1 > 0 && 'primary'}`}>
                                                    {data1?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            {/*상품총결제*/}
                                            <div className='m-a-user-my-page-order-sheet-row'>
                                                <div
                                                    className='m-a-user-my-page-order-receipt-label'>{t(`myOrddtCardHeader3`)}</div>
                                                <div
                                                    className='m-a-user-my-page-order-receipt-value'>
                                                    {data2?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            {/*기타미결제*/}
                                            <div className='m-a-user-my-page-order-sheet-row'>
                                                <div
                                                    className='m-a-user-my-page-order-receipt-label'>{t(`myOrddtCardHeader4`)}</div>
                                                <div
                                                    className={`m-a-user-my-page-order-receipt-value ${data3 > 0 && 'primary'}`}>
                                                    {data3?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            {/*기타총결제*/}
                                            <div className='m-a-user-my-page-order-sheet-row'>
                                                <div
                                                    className='m-a-user-my-page-order-receipt-label'>{t(`myOrddtCardHeader`)}</div>
                                                <div
                                                    className='m-a-user-my-page-order-receipt-value'>
                                                    {data4?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            <button className="admin-btn-C font-s-12 wd-80 ht-35"
                                                    onClick={myOrdPgMove(order.orderNumber)}>{t(`myDt`)}</button>
                                            {/*상세 버튼*/}
                                            {guidePopupOrderSt[index] && (
                                                <div className={'m-a-user-my-page-guide-popup'}>
                                                    <div className={'m-a-user-my-page-guide-popup-header'}>
                                                        {t(`myCardHeader`)}
                                                        <img className={'m-a-user-my-page-guide-popup-close'}
                                                             src={commonClose} alt={'close'}
                                                             onClick={() => closeGuidePopupOrder(index)}/>
                                                    </div>
                                                    <div className={'m-a-user-my-page-guide-popup-content'}>
                                                        {t(`myOrddtCont1`)} {t(`myDt`)} {t(`myOrddtCont2`)} {t(`myOrddtCont3`)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='m-a-user-my-page-divider m-a-user-my-pg-margin'/>
                                    </div>
                                )
                            } else if (order.type === 'payinfo' && (order.payType === "PT1")) {
                                // 해외배송비
                                return (
                                    <div className='m-a-user-my-page-order-sheet' key={`pay-${order.seqNum}-${index}`}>
                                        <div className='m-a-user-my-page-order-sheet-row'
                                             onClick={() => deliveryDetailTrueFn(order.seqNum, convertToShipco(order.dvType, order.orderdtDTO.shipco))}>
                                            <div className='m-a-user-my-page-order-sheet-row-left'>
                                                <img className='m-a-user-my-page-order-sheet-row-icon'
                                                     src={mypgOrderdt} alt=''/>
                                                <div
                                                    className='m-a-user-my-page-order-sheet-row-title'>
                                                    {order?.orderNumber ? order.orderNumber : order.orderdtDTO?.orderNumber}
                                                    -{order.shipInfoDTO.boxNum}
                                                </div>
                                            </div>
                                            <div className='m-a-user-my-page-order-sheet-text'>
                                                {order.regDate ? `${order.regDate.substring(0, 4)}-${order.regDate.substring(4, 6)}-${order.regDate.substring(6, 8)}` : ''}                                            </div>
                                        </div>
                                        <div className='m-a-user-my-page-order-sheet-row'>
                                            <div
                                                className='m-a-user-my-page-order-sheet-text'>{order.appRecDTO.recname}</div>
                                            <div
                                                className='m-a-user-my-page-order-sheet-text'>{order.appRecDTO.countryDTO.countryEngNm}</div>
                                            <div className='m-a-user-my-page-order-sheet-text'>
                                                {convertToShipco(order.dvType, order.orderdtDTO.shipco)}
                                            </div>
                                        </div>
                                        <div className='m-a-user-my-page-order-sheet-status'>
                                            {order.payStatus === "PS0" ? t(`prodStLabel8`)
                                                : order.payStatus === "PS1" ? t(`prodStLabel9`)
                                                    : order.payStatus === "PS2" ? t(`prodStLabel10`)
                                                        : order.payStatus === "PS3" ? t(`prodStLabel11`)
                                                            : order.payStatus === "PS4" ? t(`prodStLabel12`)
                                                                : order.payStatus === "PS5" ? t(`prodStLabel13`)
                                                                    : null
                                            }
                                        </div>
                                        {order.packListDTO && order.packListDTO.productsDTO && order.packListDTO.productsDTO.slice(0, 10).map((prod, prodIndex) => (
                                            <div className="m-a-user-my-page-order-sheet-item wd-100pc"
                                                 key={`product-${prod.seqnum}-${prodIndex}`}>
                                                <a className="m-r-4">
                                                    {
                                                        prod.prodTy === "P00" ? t(`myProdTy1`) :
                                                            prod.prodTy === "P01" ? t(`myProdTy2`) :
                                                                prod.prodTy === "P02" ? t(`myProdTy3`) :
                                                                    prod.prodTy === "P03" ? t(`myProdTy4`) :
                                                                        prod.prodTy === "P04" ? t(`myProdTy5`) :
                                                                            prod.prodTy === "P05" ? t(`myProdTy6`) :
                                                                                null
                                                    }
                                                </a>
                                                <a>{prod.name}</a>
                                            </div>
                                        ))}
                                        <div className='m-a-user-my-page-order-sheet-receipt'>
                                            <div className='m-a-user-my-page-order-sheet-row'>
                                                <div className='m-a-user-my-page-order-sheet-row-left'>
                                                    <img className='m-a-user-my-page-order-sheet-row-icon'
                                                         src={mypgPayCardDelivery}
                                                         alt=''/>
                                                    <div className='m-a-user-my-page-order-sheet-row-title'> {t(`modSHeader6`)}</div>
                                                </div>
                                                <img className='m-a-user-my-page-order-sheet-row-icon lg'
                                                     src={mypgWarn}
                                                     onClick={() => openGuidePopupPay(index)}
                                                     alt=''/>
                                            </div>
                                            {order?.ttFedexPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>FedEx</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttFedexPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttFedexPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            {order?.ttFedexEcoPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>FedEx-E</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttFedexEcoPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttFedexEcoPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            {order?.ttUpsPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>UPS</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttUpsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttUpsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            {order?.ttUpsEcoPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>UPS-E</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttUpsEcoPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttUpsEcoPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            {order?.ttEmsPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>EMS</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttEmsPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttEmsPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}                                              </div>
                                            </div>
                                            )}
                                            {order?.ttEmsPrePrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>EMSP</div>
                                                <div
                                                    className={`m-a-user-my-page-order-receipt-value ${order?.ttEmsPrePrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttEmsPrePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}                                              </div>
                                            </div>
                                            )}
                                            {order?.ttSeaPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>SEA</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttSeaPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttSeaPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            {order?.ttKPackPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>K-Packet</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttKPackPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttKPackPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            {order?.ttAirSPrice > 0 && (
                                            <div className='m-a-user-my-page-order-sheet-row receipt'>
                                                <div className='m-a-user-my-page-order-receipt-label'>Small-Packet</div>
                                                <div className={`m-a-user-my-page-order-receipt-value ${order?.ttAirSPrice > 0 && (order.payStatus === "PS0" || order.payStatus === "PS1") && 'primary'}`}>
                                                    {order?.ttAirSPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>
                                            </div>
                                            )}
                                            <div className="display-flex justify-content-between align-center wd-100pc ht-50">
                                            <button className="bt-btn-A font-s-12 wd-50pc ht-35"
                                                    onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}>
                                                {t(`smTrackHeader`)}
                                            </button>
                                            <button className="admin-btn-C font-s-12 wd-50pc ht-35 m-l-10"
                                                    onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}>
                                                {t(`myDt`)}
                                            </button>
                                            </div>
                                            {guidePopupPaySt[index] && (
                                                <div className={'m-a-user-my-page-guide-popup'}>
                                                    <div className={'m-a-user-my-page-guide-popup-header'}>
                                                        {t(`myCardHeader`)}
                                                        <img className={'m-a-user-my-page-guide-popup-close'}
                                                             src={commonClose} alt={'close'}
                                                             onClick={() => closeGuidePopupPay(index)}/>
                                                    </div>
                                                    <div className={'m-a-user-my-page-guide-popup-content'}>
                                                        {t(`myOrddtCont1`)} {t(`myDt`)} {t(`myOrddtCont2`)} {t(`myPayinfoCont`)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='m-a-user-my-page-divider m-a-user-my-pg-margin'/>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}

                        {/*주문서탭 더보기버튼*/}
                        {
                            orderListDisplayLimit && filteredList && filteredList.length > orderListDisplayLimit ?
                                <div className="m-t-30 m-b-100 user-center font-s-17">
                                    <button onClick={handleMoreOrderList}
                                            className="moreBt1 m-r-8 wd-80 ht-35 font-c-default m-r-8 font-s-14">More
                                    </button>
                                </div>
                                :
                                null
                        }

                    </div>
                )}
            </div>
            {subTitle(t(`myTab2`), mypgPayinfo)} {/*결제내역*/}
            <div className="m-a-user-my-page-divider"/>
            <div className="m-a-user-my-page-payment-frame">
                <div className="m-a-user-my-page-order-sheet-filter">
                    <div className="m-a-user-my-page-order-sheet-filter-button" ref={payPeriodButtonRef} onClick={() => setPayPeriodSelectShow(true)}>
                        {payPeriodSelectCommonValue}
                        <img className="m-a-user-my-page-order-sheet-filter-icon" src={Expand} alt={''}/>
                        {payPeriodSelectShow &&
                            <div className="m-a-user-my-page-order-sheet-filter-list" ref={payPeriodDivRef} onClick={payPeriodDivHandle}>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payPeriodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payPeriodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payPeriodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payPeriodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payPeriodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}</div>
                            </div>
                        }
                    </div>
                    <div className='m-a-user-my-page-order-sheet-filter-button' ref={payStButtonRef} onClick={() => setPayStSelectShow(true)}>
                        {filterPaySt(payStSelectCommonValue)}
                        <img className={'m-a-user-my-page-order-sheet-filter-icon'} src={Expand} alt={''}/>
                        {payStSelectShow &&
                            <div className={'m-a-user-my-page-order-sheet-filter-list'} ref={payStDivRef}
                                 onClick={payStDivHandle}>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payStBtHandle(t(`state`))}>{t(`state`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payStBtHandle("PS0")}>{t(`prodStLabel8`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payStBtHandle("PS1")}>{t(`prodStLabel9`)}</div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => payStBtHandle("PS2")}>{t(`prodStLabel10`)}</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="m-a-user-my-page-payment-table-container">
                    <div className="m-a-user-my-page-payment-table label">
                        <div className="m-a-user-my-page-payment-table-item">{t(`myHtDate`)}</div> {/*날짜*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`ttPr`)}</div> {/*총금액*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`myUseDep`)}</div> {/*예치금사용*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`myUsePt`)}</div> {/*적립금사용*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`myUseCp`)}</div> {/*쿠폰사용*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`appSale`)}</div> {/*앱할인*/}
                        <div className="m-a-user-my-page-payment-table-item"/>
                        <div className="m-a-user-my-page-payment-table-item">{t(`myFnPr`)}</div> {/*최종금액*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`myPt`)}</div> {/*결제수단*/}
                        <div className="m-a-user-my-page-payment-table-item">{t(`modPlHeader5`)}</div> {/*상태*/}
                    </div>
                    {sortedPayList.filter(filterStatus).slice(0, payListDisplayLimit).map((pay, index) => (
                        <div className="m-a-user-my-page-payment-table" key={index}>
                            <div className="m-a-user-my-page-payment-table-item">{formatDate(pay?.regDate)}</div>
                            <div className="m-a-user-my-page-payment-table-item">
                                {selectedTotalPricePrint(pay).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                            <div className="m-a-user-my-page-payment-table-item">
                                {pay?.depUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                            <div className="m-a-user-my-page-payment-table-item">
                                {pay?.pointUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                            <div className="m-a-user-my-page-payment-table-item">
                                {pay?.couponUsePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                            <div className="m-a-user-my-page-payment-table-item">
                                {pay?.appSalePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                            </div>
                            <div className="m-a-user-my-page-payment-table-item">
                                {pay.payType === "PT3" && (
                                    <button className='bt-btn-C pd2 wd-70 ht-35 font-s-11'
                                            onClick={() => integPayDetail(pay.seqNum)}>{t(`myHtItBt`)}</button>
                                )}
                            </div>
                            <div className='m-a-user-my-page-payment-history-forPrice-container'>
                                {pay?.lastPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                {pay.payWay === "PM01" ?
                                    <div className="user-center m-l-5 font-s-12">
                                        <p className="m-a-user-my-page-payment-history-forPrice-text">{pay?.lastForPrice}</p>
                                        <p className="m-l-3">{pay?.forCurrency}</p>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='m-a-user-my-page-payment-table-item'>
                                {pay.payWay === "PM00" ? t(`modNoBank`) :
                                    pay.payWay === "PM01" ? "Paypal" :
                                        pay.payWay === "PM02" ? "Wechat" :
                                            pay.payWay === "PM03" ? "Alipay" :
                                                pay.payWay === "PM04" ? "Point" :
                                                    pay.payWay === "PM05" ? "Deposit" : null}
                            </div>
                            <div className='m-a-user-my-page-payment-table-item'>
                                {pay.payStatus === "PS1" ?
                                    <p style={{color: "#98B0D4"}}>{t(`prodStLabel9`)}</p>
                                    : pay.payStatus === "PS5" ? <p style={{color: "#EB939D"}}>{t(`prodStLabel13`)}</p>
                                        : <p style={{color: "#FFB378"}}>{t(`prodStLabel10`)}</p>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/*결제내역탭 더보기버튼*/}
            {
                payListDisplayLimit && sortedPayList && sortedPayList.length > payListDisplayLimit ?
                    <div className="m-t-20 m-b-50 user-center font-s-17">
                        <button onClick={handleMorePayList}
                                className="moreBt1 m-r-8 wd-80 ht-35 font-c-default m-r-8 font-s-13">More
                        </button>
                    </div>
                    :
                    null
            }

            <div className='m-a-user-my-page-divider-bold m-a-user-my-pg-margin'/>
            {subTitle(t(`myTab3`), mypgPayinfo)} {/*예치금&적립금*/}
            <div className='m-a-user-my-page-divider'/>
            <div className='m-a-user-my-page-usage-frame'>
                <div className='m-a-user-my-page-order-sheet-filter'>
                    <div className='m-a-user-my-page-order-sheet-filter-button full'
                         ref={dacPeriodButtonRef}
                         onClick={() => setDacPeriodSelectShow(true)}>
                        {dacPeriodSelectCommonValue}
                        <img className={'m-a-user-my-page-order-sheet-filter-icon'} src={Expand} alt={''}/>
                        {dacPeriodSelectShow &&
                            <div className={'m-a-user-my-page-order-sheet-filter-list'}
                                 ref={dacPeriodDivRef} onClick={dacPeriodDivHandle}>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => dacPeriodBtHandle(t('myPrd1'))}>{t(`myPrd1`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => dacPeriodBtHandle(t('myPrd2'))}>{t(`myPrd2`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => dacPeriodBtHandle(t('myPrd3'))}>{t(`myPrd3`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => dacPeriodBtHandle(t('myPrd4'))}>{t(`myPrd4`)}
                                </div>
                                <div className="m-a-user-my-page-order-sheet-filter-item"
                                     onClick={() => dacPeriodBtHandle(t('myPrd5'))}>{t(`myPrd5`)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="m-a-user-my-page-usage-sub-title"> {t(`myDepHt`)}</div>
                {/*예치금내역*/}
                <div className="m-a-user-my-page-usage-table-frame">
                    <div className="m-a-user-my-page-usage-table label p-t-7">
                        <div className="m-a-user-my-page-usage-table-item">{t(`myHtDate`)}</div>
                        {/*날짜*/}
                        <div className="m-a-user-my-page-usage-table-item-col-3">{t(`myCont`)}</div>
                        {/*내용*/}
                        <div className="m-a-user-my-page-usage-table-item">{t(`myPr`)}</div>
                        <div className="m-a-user-my-page-usage-table-item">{t(`myBal`)}</div>
                    </div>
                    {sortedDacList.filter(dac => dac.depPrice > 0).slice(0, dacDisplayLimits.deposit).map((dac, index) => (
                        <div key={index} className="m-a-user-my-page-usage-table p-t-7">
                            <div className="m-a-user-my-page-usage-table-item">{formatDate(dac?.regDate)}</div>
                            <div className='m-a-user-my-page-usage-table-item-col-3 admin-tb-text-cut'>
                                {dac.content}
                            </div>
                            <div className="m-a-user-my-page-usage-table-item">
                                {(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.depPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                            <div className="m-a-user-my-page-usage-table-item">
                                {dac?.depBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                        </div>
                    ))}
                </div>

                {/*예치금&적립금탭 예치금 더보기버튼*/}
                {
                    dacDisplayLimits["deposit"] && sortedDacList && sortedDacList.filter(dac => dac.depPrice > 0).length > dacDisplayLimits["deposit"] ?
                        <div className="m-t-20 m-b-50 user-center font-s-17">
                            <button onClick={() => handleMoreDacList("deposit")}
                                    className="moreBt1 m-r-8 wd-80 ht-35 font-c-default m-r-8 font-s-13">More
                            </button>
                        </div>
                        :
                        null
                }

            </div>
            {/*적립금내역*/}
            <div className='m-a-user-my-page-usage-frame'>
                <div className='m-a-user-my-page-usage-sub-title'> {t(`myPtHt`)}</div>
                <div className='m-a-user-my-page-usage-table-frame'>
                    <div className='m-a-user-my-page-usage-table label p-t-7'>
                        <div className='m-a-user-my-page-usage-table-item'>{t(`myHtDate`)}</div>
                        <div className='m-a-user-my-page-usage-table-item-col-3'>{t(`myCont`)}</div>
                        <div className='m-a-user-my-page-usage-table-item'>{t(`myPr`)}</div>
                        <div className='m-a-user-my-page-usage-table-item'>{t(`myBal`)}</div>
                    </div>
                    {sortedDacList.filter(dac => dac.ptPrice > 0).slice(0, dacDisplayLimits.point).map((dac, index) => (
                        <div key={index} className='m-a-user-my-page-usage-table p-t-7'>
                            <div className='m-a-user-my-page-usage-table-item'>{formatDate(dac?.regDate)}</div>
                            <div className='m-a-user-my-page-usage-table-item-col-3'>{dac.content}</div>
                            <div className='m-a-user-my-page-usage-table-item'>
                                {(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.ptPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                            <div className='m-a-user-my-page-usage-table-item'>
                                {dac?.ptBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                        </div>
                    ))}
                </div>

                {/*예치금&적립금탭 적립금 더보기버튼*/}
                {
                    dacDisplayLimits["point"] && sortedDacList && sortedDacList.filter(dac => dac.ptPrice > 0).length > dacDisplayLimits["point"] ?
                        <div className="m-t-20 m-b-50 user-center font-s-17">
                            <button onClick={() => handleMoreDacList("point")}
                                    className="moreBt1 m-r-8 wd-80 ht-35 font-c-default m-r-8 font-s-13">More
                            </button>
                        </div>
                        :
                        null
                }

            </div>
            {/*쿠폰내역*/}
            <div className='m-a-user-my-page-usage-frame' style={{marginBottom: '60px'}}>
                <div className='m-a-user-my-page-usage-sub-title'> {t(`myCpHt`)}</div>
                <div className='m-a-user-my-page-usage-table-frame p-t-7'>
                    <div className='m-a-user-my-page-usage-table m-a-user-my-pg-col-6 label m-a-user-my-pg-coupon'>
                        <div className='m-a-user-my-page-usage-table-item'>{t(`myHtDate`)}</div>
                        <div className='m-a-user-my-page-usage-table-item'>{t(`myCpNm`)}</div>
                        <div className='m-a-user-my-page-usage-table-item'>{t(`myPr`)}</div>
                    </div>
                    {sortedDacList.filter(dac => dac.cpPrice > 0).slice(0, dacDisplayLimits.coupon).map((dac, index) => (
                        <div key={index}
                             className='m-a-user-my-page-usage-table m-a-user-my-pg-col-6 m-a-user-my-pg-coupon p-t-7'>
                            <div className='m-a-user-my-page-usage-table-item'>{formatDate(dac?.regDate)}</div>
                            <div className='m-a-user-my-page-usage-table-item'>{dac.cpNm}</div>
                            <div className='m-a-user-my-page-usage-table-item'>
                                {(dac.type === '0' || dac?.type === '2') ? "+" : "-"}{dac?.cpPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>
                        </div>
                    ))}
                </div>

                {/*예치금&적립금탭 쿠폰 더보기버튼*/}
                {
                    dacDisplayLimits["coupon"] && sortedDacList && sortedDacList.filter(dac => dac.cpPrice > 0).length > dacDisplayLimits["coupon"] ?
                        <div className="m-t-20 m-b-50 user-center font-s-17">
                            <button onClick={() => handleMoreDacList("coupon")}
                                    className="moreBt1 m-r-8 wd-80 ht-35 font-c-default m-r-8 font-s-13">More
                            </button>
                        </div>
                        :
                        null
                }

            </div>
            <MFooterSection/>
            {deliveryDetailSt ?
                <MDeliveryPriceDetail deliveryDetailSt={deliveryDetailSt} deliveryDetailFalseFn={deliveryDetailFalseFn}
                                      selectedShip={payShipco}
                                      paySeqnum={paySeqnum} updateAppRecinfo={updateAppRecinfo}
                                      updatePayinfoList={updatePayinfoList} pathComponent={pathComponent}/>
                :
                isLoading ?
                    <CommonLoading/>
                    :
                    integPayDetailPopupSt ?
                        <IntegDetailPopup integDetailPopupClose={integDetailPopupClose}
                                          integPaySeqnum={integPaySeqnum}/>
                        :
                        downNoSelectSt ?
                            <MsgCheckModal downNoSelectSt={downNoSelectSt} closeDownNoSelect={closeDownNoSelect}/>
                            :
                            null
            }
        </div>
    )
}

export default withAuth(MUserMyPg);
